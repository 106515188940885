<mat-card style="width: 100%;" class="zoomUnAvailableCard">
    <div style="display: flex; justify-content: flex-end;">
        <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>
    <mat-card-title class="penaltyWarning">
        <div>
            <mat-icon class="material-icons-filled" style="font-size: 50px !important; margin-right: 35px;">
                error
            </mat-icon>
        </div>
        Zoom Unavailable
    </mat-card-title>
    <mat-card-content>
        <p>Zoom is unable to generate a link for this session! Please contact Tactopus Support to resolve this issue
            immediately.</p>
    </mat-card-content>
    <mat-card-actions>
        <button class="right actionButtons" mat-button color="warn" (click)="contactSupport()">Contact Support</button>
    </mat-card-actions>
</mat-card>