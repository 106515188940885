<div class="wrapper">
  <mat-icon class="right" style="position: relative; top: -12px; left: 12px; cursor: pointer;" matDialogClose>close</mat-icon>

  <h1 *ngIf="data.dialogType === 'cancel'" style="font-weight: 600;">You are about to cancel the following consultation.</h1>
  <h1 *ngIf="data.dialogType === 'attended'" style="font-weight: 600;">You are about to mark the following consultation as attended and completed:</h1>
  <h1 *ngIf="data.dialogType === 'reschedule'" style="font-weight: 600;">You are about to request a reschedule for the following consultation.</h1>
  <h1 *ngIf="data.dialogType === 'noShow-Expert'" style="font-weight: 600;">You are about to report an expert no-show for the following consultation:</h1>
  <h1 *ngIf="data.dialogType === 'noShow-Parent(expert paid)' || data.dialogType === 'noShow-Parent(expert not paid)'"
  style="font-weight: 600;">You are about to report a parent no-show for the following consultation:</h1>

  <mat-card-subtitle>
    <p>(E){{ consultation['sessionExpertName'] }} with (P){{ consultation['sessionParentName'] }}, (C){{ consultation['sessionChildName'] }}</p>
    <p>{{ consultation['serviceType'] }}</p>
    <p>{{ helper.istTime((consultation['sessionDate']).toDate()) | date: "MMMM dd, Y" }}</p>
    <p>{{ consultation['startTime'] }} - {{ consultation['endTime'] }}</p>
    <p style="font-weight: 600;">New Status Details</p>
    <p style="font-weight: 600;" *ngIf="data.dialogType === 'cancel'">Cancelled By {{ data.cancelledBy | titlecase}}(Not charged)</p>
    <p style="font-weight: 600;" *ngIf="data.dialogType === 'attended'">Attended</p>
    <p style="font-weight: 600;" *ngIf="data.dialogType === 'reschedule'">Reschedule requested By {{ data.cancelledBy | titlecase}}</p>
    <p style="font-weight: 600;" *ngIf="data.dialogType === 'noShow-Expert'">No-show By {{ data.noShowBy | titlecase}}</p>
    <p style="font-weight: 600;" *ngIf="data.dialogType === 'noShow-Parent(expert paid)'">No-show By {{ data.noShowBy | titlecase}}(expert paid)</p>
    <p style="font-weight: 600;" *ngIf="data.dialogType === 'noShow-Parent(expert not paid)'" #noShowParentUnpaid>No-show By {{ data.noShowBy | titlecase}}(expert not paid)</p>
  </mat-card-subtitle>
  <div class="btnWrapper">
    <div style="display: inline-flex; justify-content: space-evenly;">
      <button mat-button matDialogClose id="back" color="warn">Back</button>
      <button mat-button *ngIf="data.dialogType === 'cancel'" (click)="close()" class="confirmBtn">Confirm Cancellation</button>
      <button mat-button *ngIf="data.dialogType === 'reschedule'" (click)="close()" class="confirmBtn">Confirm Request</button>
      <button mat-button *ngIf="data.dialogType === 'noShow-Expert'" (click)="close()" class="confirmBtn">Confirm No-Show</button>
      <button mat-button *ngIf="data.dialogType === 'noShow-Parent(expert paid)'" (click)="close()" class="confirmBtn">Confirm No-Show</button>
      <button mat-button *ngIf="data.dialogType === 'noShow-Parent(expert not paid)'" (click)="close()" class="confirmBtn">Confirm No-Show</button>
      <button mat-button *ngIf="data.dialogType === 'attended'" (click)="close()" class="confirmBtn">Confirm</button>
    </div>
  </div>
</div>
