<div *ngIf="helper.renewalPaymentDetails?.viaPaymentPage == true && !showFullPaymentDetails" class="successMessageContainer">
  <div class="successIconContainer">
    <mat-icon class="successIcon">check_circle_outline</mat-icon>
  </div>

  <div style="width: 87%;">
    <div style="display: flex; justify-content: space-between; margin-bottom: 6px; align-items: center;">
      <span style="font-weight: bold; font-size: 17px;">Payment was successful!</span>
      <button class="paymentDetailsBtn" (click)="onPaymentDetailsClicked()"
        style="font-weight: bold; text-decoration: underline; font-size: 13px;">Details</button>
    </div>

    <div style="display: flex; justify-content: space-between;">
      <span style="font-weight: 400; font-size: 13px;">₹{{helper.renewalPaymentDetails.amount}}</span>

      <div style="display: flex; justify-content: space-between;">
        <span style="font-weight: 400; font-size: 13px;">{{helper.renewalPaymentDetails.date | date:'h:mm a
          dd/MM/YYYY'}}</span>
      </div>

    </div>

  </div>
</div>







<div *ngIf="showFullPaymentDetails == true"  class="successPage" >
  <div class="heading">
    <a matButton id="back-button" style="cursor: pointer;" (click)="hidePaymentDetails()">
      <mat-icon>arrow_back</mat-icon>
    </a>
  </div>
  <div class="successDetailsContainer">
    <div class="paymentsuccesspage" style="margin-bottom:25px ;">
      <div style="display: flex; justify-content: center; align-items:center; margin-bottom: 20px; margin-top: 20px;">
        <mat-icon class="successIcon">check_circle_outline</mat-icon>
      </div>

      <div style="font-weight: bold; font-size: 17px; text-align: center;">
        Payment was successful!
      </div>

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between; margin-top: 40px">
        <span class="userDetailName">Name</span>
        <span class="userDetailValue">{{helper.renewalPaymentDetails.name}}</span>
      </div>

      <!-- <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName">Session per week</span>
        <span class="userDetailValue">{{helper.renewalPaymentDetails.sessionPerWeek}}</span>
      </div> -->

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName">Duration</span>
        <span class="userDetailValue">{{helper.renewalPaymentDetails.duration}}</span>
      </div>

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName">Rate Applied</span>
        <span class="userDetailValue">₹{{helper.renewalPaymentDetails.rateApplied}}</span>
      </div>

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName">Total Sessions</span>
        <span class="userDetailValue">{{helper.renewalPaymentDetails.totalSessions}}</span>
      </div>

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName amount" style="font-weight: bold;">Amount</span>
        <span class="userDetailValue amount" style="font-weight: bold;">₹{{helper.renewalPaymentDetails.amount}}</span>
      </div>

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName timeUserDetailsNamePaymentSuccess">Time and date</span>
        <span class="userDetailValue timeandDateDetailsValuePaymentSuccess">{{helper.renewalPaymentDetails.date | date:'h:mm a dd/MM/YYYY'}}</span>
      </div>

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName">Transaction ID </span>
        <span class="userDetailValue">{{helper.renewalPaymentDetails.transactionId}}</span>
      </div>

      <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
        <span class="userDetailName">Kinderpass Invoice Id</span>
        <span class="userDetailValue">{{helper.renewalPaymentDetails.invoiceId}}</span>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content:flex-end; padding-left: 25px; margin: 20px;">
    <button mat-button class="right backbutton" (click)="hidePaymentDetails()">Back</button>
  </div>
</div>












	<!-- session booking starts here -->
  <div *ngIf="!showFullPaymentDetails" class="page-container">
  <div class="heading">
    <a matButton id="back-button" style="cursor: pointer;" (click)="backToSessionPage()">
      <mat-icon>arrow_back</mat-icon>
    </a>
    <h1>Book Sessions</h1>
  </div>

  <div *ngIf="hasTimerStarted" style="display: flex; padding-left: 24px;">
    <mat-icon style="color: #7A7A7A; margin-right: 20px;">info</mat-icon>
    <p style="color: #7A7A7A; text-align: start;">Please confirm your slots within <span
        id="countDown">{{timerMinute}}:{{timerSecond}}</span> minutes. <br> Your selection will
      expire soon</p>
  </div>

  <mat-horizontal-stepper linear #stepper class="multiSessionStepper">
    <!-- first step of the form for basic details -->
    <!-- <mat-step formGroupName="0" stepControl="formArray.get([0])" label="Details"> -->
    <mat-step [stepControl]="firstFormGroup" label="Details">
      <form [formGroup]="firstFormGroup" class="sessionForm">
        <mat-card>
          <mat-card-content>
            <!-- <div class="formFieldHeading">Select Expert</div> -->
            <div class="formFieldHeading">Which expert do you want to book sessions with?</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <!-- <mat-label>{{expertLabel}}</mat-label> -->
              <mat-select (selectionChange)="onExpertSelected($event.value)" formControlName="expert"
                panelClass="myPanelClass" disableOptionCentering placeholder="Select Option">
                <mat-option *ngFor="let expert of experts" [value]="expert">
                  {{ expert.fullName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls.expert.errors">
                Please Make a Selection
              </mat-error>
            </mat-form-field>

            <p *ngIf="!isExpertActive" style="color: #F7524B; text-align: start; margin-top: -25px;">Session booking is
              disabled for {{selectedExpert.fullName}}</p>

            <!-- <div class="formFieldHeading">Select Service</div> -->
            <div class="formFieldHeading">Which service do you want for these sessions?</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <!-- <mat-label>{{serviceLabel}}</mat-label> -->
              <mat-select formControlName="service" (selectionChange)="onServiceSelected($event.value)"
                panelClass="myPanelClass" disableOptionCentering placeholder="Select Option">
                <mat-option *ngFor="let sessionType of sessionTypes" [value]="sessionType">
                  {{sessionType}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.controls.service.errors">
                Please Make a Selection
              </mat-error>
            </mat-form-field>

            <!-- <div class="formFieldHeading">Select Number Of Sessions</div> -->
            <div class="formFieldHeading">How many sessions do you want to book?</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <!-- <mat-label>{{sessionLabel}}</mat-label> -->
              <input matInput [(ngModel)]="noOfSessions" (click)="sessionLabel = ''" type="number"
                formControlName="numberOfSessions" autocomplete="off" min="0"
                oninput="this.value = Math.abs(this.value) > 0 ? Math.abs(this.value) : null">
              <mat-error *ngIf="firstFormGroup.controls.numberOfSessions.errors">
                Total sessions should not exceed your balance({{parentBalance}}). Or If just added topup then refresh and try.
              </mat-error>
            </mat-form-field>

            <!-- <div class="formFieldHeading">Select your preferred slot date</div> -->
            <div class="formFieldHeading">When do you want to start these sessions?</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <!-- <mat-label>{{sessionDateLabel}}</mat-label> -->
              <input matInput (dateChange)="onStartDateSelected($event)" [matDatepicker]="picker"
                formControlName="startDate" autocomplete="off" [min]="minDate" [max]="maxDate" placeholder="DD/MM/YYYY">
              <!-- [disabled]="secondFormGroup.controls.numberOfSessionsPerWeek.errors" -->
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="firstFormGroup.controls.startDate.errors">
                Please Make a Selection
              </mat-error>
            </mat-form-field>
          </mat-card-content>

          <mat-card-actions>
            <a routerLink="/parent/dashboard">
              <button mat-button matStepperPrevious type="button" class="back-btn-normal">Back</button>
            </a>
            <button mat-button [disabled]="!firstFormGroup.valid || !isServiceSelected || !isExpertActive" type="button"
              [ngClass]="!firstFormGroup.valid || !isServiceSelected === true ? 'continue-btn-disabled' : 'continue-btn-normal'"
              (click)="nextFromFirstStep(stepper)">Continue</button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-step>

    <mat-step label="Slots">
      <!-- [stepControl]=" secondFormGroup"  -->
      <!-- <form [formGroup]="secondFormGroup" class="sessionForm"> -->
      <mat-card class="slotCard">
        <mat-card-content>
          <!-- <div *ngIf="noOfSessions !=1" class="formFieldHeading">Number of Sessions per week</div> -->
          <!-- <mat-form-field *ngIf="noOfSessions !=1" class="full-width-field" appearance="outline"> -->
          <!-- <mat-label>{{sessionPerWeekLabel}}</mat-label> -->
          <!-- <input type="number" matInput [(ngModel)]="frequencyPerWeek" numberOfSessionsPerWeekCtrl -->
          <!-- formControlName="numberOfSessionsPerWeek"> -->
          <!-- <mat-select panelClass="dropdown" (selectionChange)="onSessionPerWeekSelected($event.value)"
                numberOfSessionsPerWeekCtrl formControlName="numberOfSessionsPerWeek" disableOptionCentering>
                <mat-option>-</mat-option>
                <mat-option class="expertOptions" *ngFor="let sessionFrequency of sessionPerWeek"
                  [value]="sessionFrequency">
                  <p style="margin-bottom: 0; font-weight: 400;">{{sessionFrequency}}</p>
                </mat-option> 
              </mat-select> -->
          <!-- <mat-error *ngIf="secondFormGroup.controls.numberOfSessionsPerWeek.errors"> -->
          <!-- Please type a valid number -->
          <!-- </mat-error> -->
          <!-- </mat-form-field> -->

          <!-- <div class="formFieldHeading">Select your preferred slot date</div> -->
          <!-- <div class="formFieldHeading">When do you want to start these sessions?</div> -->
          <!-- <mat-form-field class="full-width-field" appearance="outline"> -->
          <!-- <mat-label>{{sessionDateLabel}}</mat-label> -->
          <!-- <input matInput (dateChange)="onStartDateSelected($event)" [matDatepicker]="picker" -->
          <!-- formControlName="startDate" autocomplete="off" [min]="minDate" placeholder="DD/MM/YYYY"> -->
          <!-- [disabled]="secondFormGroup.controls.numberOfSessionsPerWeek.errors" -->
          <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
          <!-- <mat-datepicker #picker></mat-datepicker> -->
          <!-- <mat-error *ngIf="secondFormGroup.controls.startDate.errors"> -->
          <!-- Please Make a Selection -->
          <!-- </mat-error> -->
          <!-- </mat-form-field> -->

          <!-- loading screen starts here -->
          <!-- <div *ngIf="isLoading" class="text-center my-4" style="margin-top: 35px; margin-bottom: 20px;">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <h2 style="color: black;">Loading Slots</h2>
              <h3 style="color: #A5A5A5">Give us a second as we load all the available slots</h3>
            </div>
          </div> -->
          <!-- loading screen ends here -->

          <div *ngIf=showSlots style="border-bottom: 1px solid #DCDCDC ">
            <h2 style="font-size: 18px; font-weight: 600; color: #F7524B;">Choose Your Slots</h2>
            <div
              style="margin: auto; padding: 5px 0px 5px 5px; border: 1px solid #D1D1D1; border-radius: 7px; width: 317px; height: 38px; box-sizing: border-box;">
              <div style="display: flex;">
                <mat-icon style="color:#7A7A7A;font-size:13px; padding-top: 4px;">info</mat-icon>
                <div style="color:#202020; text-align: start; font-size: 12px; font-weight: 500;">
                  We recommend you book {{frequencyPerWeek}} sessions per week
                </div>
              </div>
            </div>
            <div style="text-align: center; font-size: 15px; font-weight: 500; margin: 10px 0px 10px 0px;">
              You have Selected <span style="color: #F7524B;">{{totalSelectedSlots}} slots</span> across {{totalWeeks}}
              weeks.
            </div>
            <!-- <div style="width: 100%; display: table;">
              <div style="display: table-row;">
                <div style="text-align: left;display: table-cell;">
                  <div style="font-family: poppins; font-weight: 500; font-size: 16px;">Selected
                    This Week</div>
                  <div style="color: #7A7A7A;font-family: poppins; font-size: 14px">
                    {{slotSelectedPerWeek}}/{{frequencyPerWeek}} slots selected</div>
                </div>
                <div style="text-align: right; display: table-cell;">
                  <div style="font-family: poppins; font-weight: 500; font-size: 16px;">Total
                    Selected</div>
                  <div style="color: #7A7A7A;font-family: poppins; font-size: 14px">
                    {{totalSelectedSlots}} slots selected</div>
                </div>
              </div>
            </div> -->
            <div *ngIf=isMaximumSlots
              style="margin-top: 10px; margin-bottom: 14px; display: flex; justify-content: center;">
              <mat-icon color="warn" style="font-size:20px;">info</mat-icon>
              <span style="font-size: 16px; color: #F7524B">
                Selected Slots cannot exceed your balance ({{parentBalance}})
              </span>
            </div>
          </div>

          <div *ngIf=isDateRange style="margin-top: 15px; font-size: 16px; font-weight: 550;">
            {{weeklyDateRange}}
          </div>

          <div *ngIf=isExpertOnWeeklyLeave class="expert-leave">
            <div style="display: flex;">
              <mat-icon color="warn" style="font-size:17px;">info</mat-icon>
              <span style=" font-size: 15px;">Expert on Leave</span>
            </div>
            <div style="text-align: left; color:#7A7A7A;font-family: poppins; font-size: 12px;">
              <div>{{selectedExpert.fullName}}</div>
              <div *ngFor="let leave of weeklyLeaves">
                <div>From: {{ helper.istTime(leave.from) | date: 'dd MMM YYYY, h:mm a'}}(IST)</div>
                <div>To: {{ helper.istTime(leave.to) | date: 'dd MMM YYYY, h:mm a'}}(IST)</div>
              </div>
            </div>
          </div>

          <div class="weeklyDivision" style="margin-top: 15px; margin-bottom: 25px;" *ngIf=showSlots>
            <button mat-button (click)="onClickPreviousWeek()" [disabled]="!startIndex">
              <mat-icon>keyboard_arrow_left</mat-icon>Previous week
            </button>
            <button mat-button color="warn" (click)="onClickNextWeek()"
              [disabled]="endIndex >= (slotsOfMultidays.length - 1)">
              Next week<mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
          <div *ngIf=showSlots>
            <div class="multiDateSlot" *ngFor="let multiOpenSlots of weeklySlots">
              <div>
                <div class="multiDates" *ngIf="multiOpenSlots.openSlots.length != 0">
                  <div style="font-size: 21px; font-weight: 550;">
                    {{multiOpenSlots.date.substring(0,2)}}</div>
                  <div style="font-size: 13px;">
                    {{multiOpenSlots.date.substring(2,6)}}</div>
                  <div style="font-size: 13px;">
                    {{multiOpenSlots.date.substring(6)}}</div>
                </div>
                <div class="multiDates" style="color: #7A7A7A;" *ngIf="multiOpenSlots.openSlots.length == 0">
                  <div style="font-size: 23px; font-weight: 550;">
                    {{multiOpenSlots.date.substring(0,2)}}</div>
                  <div style="font-size: 15px;">
                    {{multiOpenSlots.date.substring(2,6)}}</div>
                  <div style="font-size: 15px;">
                    {{multiOpenSlots.date.substring(6)}}</div>
                </div>
              </div>
              <div>
                <div class="multiSlotList" *ngIf="showSlots && multiOpenSlots.openSlots.length != 0">
                  <button class="multiSlot"
                    [ngClass]="{'slot-selected': openSlot.isSelected, 'slot-deselected': !openSlot.isSelected}"
                    *ngFor="let openSlot of multiOpenSlots.openSlots" style="cursor: pointer; outline: none;"
                    (click)="onSelectedMultiSlots(multiOpenSlots, openSlot)"> {{openSlot.label}} </button>
                </div>
                <div *ngIf="showSlots && multiOpenSlots.openSlots.length == 0">
                  <div style="padding: 14px 0px 10px 5px;">
                    <div style="display: flex;">
                      <mat-icon style="color:#7A7A7A;font-size:17px;">info</mat-icon>
                      <div style="color:#7A7A7A; text-align: start;">There are currently no slots available for
                        this date. Please view other dates.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="weeklyDivision" *ngIf=showSlots
            style="margin-top: 15px; margin-bottom: 25px;border-bottom: 1px solid #DCDCDC">
            <button mat-button (click)="onClickPreviousWeek()" [disabled]="!startIndex">
              <mat-icon>keyboard_arrow_left</mat-icon>Previous week
            </button>
            <button mat-button color="warn" (click)="onClickNextWeek()"
              [disabled]="endIndex >= (slotsOfMultidays.length - 1)">
              Next week<mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
          <div *ngIf=showSlots>
            <!-- <div style="font-size: 15px;font-weight: 550;">
              You have selected {{slotSelectedPerWeek}} slots across 1 week
            </div> -->
            <div style="text-align: center; font-size: 15px; font-weight: 500; margin: 10px 0px 10px 0px;">
              You have Selected <span style="color: #F7524B;">{{totalSelectedSlots}} slots</span> in {{totalWeeks}}
              weeks.
            </div>
            <div style="font-size: 12px;color: #7A7A7A;">
              Click “Continue” to finish booking your sessions
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <button mat-button matStepperPrevious type="button" class="back-btn-normal">Back</button>
          <button mat-button
            [disabled]="!dateAndSlots?.length && !singleSlotService.availableSlots.length && !singleSlotService.unavailableSlots.length"
            [ngClass]="(!dateAndSlots?.length && !singleSlotService.availableSlots.length && !singleSlotService.unavailableSlots.length) === true ? 'continue-btn-disabled' : 'continue-btn-normal'"
            type="button" (click)="showLoadingMessage(stepper)">Continue</button>
        </mat-card-actions>
      </mat-card>
      <!-- </form> -->
    </mat-step>

    <!-- third step of the form for booking summary -->
    <mat-step label="Booking Summary">
      <mat-card>

        <h2 style="font-weight: 400; text-align: start;">{{selectedService}} Sessions with
          {{selectedExpert?.fullName}}</h2>
        <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

        <div *ngFor="let availableSlot of singleSlotService.availableSlots" class="availableSlots">
          <div style="display: flex; justify-content: space-between;">
            <mat-icon style="color: #F7524B; margin-right: 20px;">event</mat-icon>
            <span>{{availableSlot.date | date:'fullDate'}}</span>
          </div>
          {{availableSlot.label}}
        </div>
        <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

        <div *ngIf="singleSlotService.unavailableSlots?.length">
          <h2 style="font-weight: 400; text-align: start; margin-top: 15px;">The following slots are
            unavailable and can not be booked</h2>
          <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

          <div *ngFor="let availableSlot of singleSlotService.unavailableSlots" class="unavailableSlots">
            <div style="display: flex; justify-content: space-between;">
              <mat-icon style="color: #686B73; margin-right: 20px;">event</mat-icon>
              <span>{{availableSlot.date | date:'fullDate'}}</span>
            </div>
            {{availableSlot.label}}
          </div>
          <div style="border-bottom: 0.6px solid #DCDCDC;"></div>
        </div>

        <h2 style="font-weight: 400; text-align: start; margin-top: 15px;">Final Transaction Summary:</h2>
        <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

        <div class="transactionDetails">
          <span>Sessions Being Booked</span>
          <span>{{singleSlotService.availableSlots?.length}}</span>
        </div>
        <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

        <div class="transactionDetails">
          <span>Balance as of {{today | date:'longDate'}}</span>
          <span>{{parentsService.balanceDetails?.currentBalance}}</span>
        </div>
        <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

        <div class="transactionDetails">
          <span>Balance used for Session Booking</span>
          <span>- {{singleSlotService.availableSlots?.length}}</span>
        </div>
        <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

        <div class="transactionDetails">
          <span>Net Remaining Balance</span>
          <span>{{parentsService.balanceDetails?.currentBalance -
            singleSlotService.availableSlots?.length}}</span>
        </div>
        <div style="border-bottom: 0.6px solid #DCDCDC;"></div>

        <mat-card-actions style="margin-bottom: 70px; margin-top: 20px;">
          <button mat-button matStepperPrevious (click)="backFromThirdStep()" type="button"
            class="back-btn-normal">Back</button>
          <button mat-button [disabled]="disableBookButton" type="button" (click)="saveSessions()" matStepperNext
            [ngClass]="disableBookButton === true ? 'continue-btn-disabled' : 'continue-btn-normal'">Confirm</button>
        </mat-card-actions>
      </mat-card>
    </mat-step>

  </mat-horizontal-stepper>

</div>