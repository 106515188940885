import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/helper.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelDialog implements OnInit {
  message: string;
  cancelTimeLimit = 240;
  cancelTimeLimitExpert = 720;
  cancellingPerson: any;
  reasonForm:any;
  penalty = 0;

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private toastr: ToastrService,
    private helper: HelperService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<CancelDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.reasonForm = this.fb.group({
      cancellationReason: ['', Validators.required],
      additionalDetails: '',
    })

    this.timeComparison();
  }

  getCancelReasons(){
    if(this.data.cancellingPerson == "Parent"){
      return this.helper.parentCancellationAndReschedulingReason;
    } else if(this.data.cancellingPerson == "Expert"){
      return this.helper.expertCancellationAndReschedulingReason;
    }
  }

  async cancelSession() {
    const cancelledBy = await firebase.auth().currentUser.uid;
    let cancelledByName;
    let cancelledWith;
    let cancelledWithName;

    let reasonForm: any = {};
    reasonForm = this.reasonForm.value

    // if parent is requesting to cancel the session.
    if (cancelledBy == this.data.session.sessionStudentId) {
      cancelledByName = this.data.session.sessionParentName;
      cancelledWith = this.data.session.sessionExpertId;
      cancelledWithName = this.data.session.sessionExpertName;

      // if expert is requesting to cancel the session.
    } else if (cancelledBy == this.data.session.sessionExpertId) {
      cancelledByName = this.data.session.sessionExpertName;
      cancelledWith = this.data.session.sessionStudentId;
      cancelledWithName = this.data.session.sessionParentName;
    }

    let body1: any;
    let body2: any;

    let date = this.helper.istTime((this.data.session.sessionDate.toDate()))
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();


    //when parent cancels the session
    if (cancelledBy == this.data.session.sessionStudentId) {
      this.cancellingPerson = 'parent';

      // notification for parent
      body1 = {
        'userId': this.data.session.parentInteraktId,
        'event': 'session cancelled by parent - Notify the parent',
        'traits': {
          'sessionType': this.data.session.serviceType,
          'date': dateString,
          'time': this.data.session.startTime  + ' IST',
          'expert': this.data.session.sessionExpertName,
          'deductionValue': "-" + this.penalty
        }
      }

      // notification for expert
      body2 = {
        'userId': this.data.session.expertInteraktId,
        'event': 'session cancelled by parent - Notify the expert',
        'traits': {
          'date': dateString,
          'time': this.data.session.startTime  + ' IST',
          'childName': this.data.session.sessionStudentName,
          'reason': reasonForm.cancellationReason,
          'deductionValue': "-" + this.penalty
        }
      }
    }

    //when expert cancels the session
    if (cancelledBy == this.data.session.sessionExpertId) {
      this.cancellingPerson = 'expert';

      if (this.data.session.status == "Session Request") {
        // notification for parent for cancellation of a requested session.
        body1 = {
          'userId': this.data.session.parentInteraktId,
          'event': 'Requested session cancelled by expert - Notify parent',
          'traits': {
            'expertName': this.data.session.sessionExpertName,
            'date': dateString,
            'time': this.data.session.startTime  + ' IST',
            'link': "https://connect.mykinderpass.com/parent/dashboard"
          }
        }
      } else {
        // notification for parent for cancellation of a scheduled session.
        body1 = {
          'userId': this.data.session.parentInteraktId,
          'event': 'session cancelled by the expert - notify parent',
          'traits': {
            'sessionType': this.data.session.serviceType,
            'date': dateString,
            'time': this.data.session.startTime  + ' IST',
            'expertName': this.data.session.sessionExpertName,
            'reason': reasonForm.cancellationReason
          }
        }
      }

      // notification for expert
      body2 = {
        'userId': this.data.session.expertInteraktId,
        'event': 'session cancelled by expert - Notify the expert',
        'traits': {
          'date': dateString,
          'time': this.data.session.startTime  + ' IST',
          'childName': this.data.session.sessionStudentName,
          'deductionValue': "-" + this.penalty

        }
      }
    }

    let status:any;
    if(this.data.session.status == "Scheduled"){
      status = "Cancelled"
    }else if(this.data.session.status == "Session Request"){
      status = "REQUEST CANCELLED"
    }

    let now = firebase.firestore.FieldValue.serverTimestamp();
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.data.session.sessionDate.toDate());
    const docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(this.data.session.id);
    docRef.update({
      'status': status,
      'cancelledBy': cancelledBy,
      'cancellationReason': reasonForm.cancellationReason,
      'additionalCancellationDetails': reasonForm.additionalDetails,
      'cancelledByName': cancelledByName,
      'cancelledOn': now,
      'cancelledWith': cancelledWith,
      'cancelledWithName': cancelledWithName,
      'cancellingPerson': this.cancellingPerson
    }).then(() => {
      this.notificationService.sendWhatsappNotification(body1);
      this.notificationService.sendWhatsappNotification(body2);

      this.onSessionDeleted();
    }).catch(err => console.log(err))
  }

  onSessionDeleted = () => {
    this.dialogRef.close();
    let person:any;
    if(this.data.cancellingPerson == "Parent"){
      person = this.data.session.sessionExpertName;
    }else if(this.data.cancellingPerson == "Expert"){
      person = this.data.session.sessionParentName;
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const d = new Date(this.data.session.sessionDate.toDate());
    let date = d.getDate();
    let month = monthNames[d.getMonth()];
    let year = d.getFullYear();

    let message = "Your session on " + date + " " + month + ", " + year + " at " + this.data.session.startTime + " IST " + "with " + person + " has been cancelled."
    this.toastr.success(message, "Session Cancelled!")
  }

  timeComparison() {
    const now: any = firebase.firestore.Timestamp.now();
    const sessionDate = this.data.session.sessionDate;

    // starting time of the session
    let startTime = this.data.session.startTime.split(":");
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);
    startTime = (startingHour * 60) + startingMinute;

    // This will give the difference in dates basically
    let timeDifference = sessionDate - now;
    timeDifference /= 60;

    // This will give the difference between session start time and cancellation time in minutes
    timeDifference += startTime;

    if (this.data.cancellingPerson == 'Parent') {
      if (timeDifference > this.cancelTimeLimit || this.data.session.status == 'Session Request') {
        this.message = "No Penalty will be charged";
        this.penalty = 0;
      } else if ((timeDifference < this.cancelTimeLimit) && (timeDifference > 0)) {
        this.message = "You will be charged 50% of the session fees AS FINE.";
        this.penalty = (this.data.session.sessionValue / 100) * 50;
      } else if (timeDifference < 0) {
        this.penalty = this.data.session.sessionValue;
        this.message = "You will be charged 100% of the session fees AS FINE.";
      }
    } else if (this.data.cancellingPerson == 'Expert') {
      if (timeDifference > this.cancelTimeLimitExpert || this.data.session.status == 'Session Request') {
        this.penalty = 0;
        this.message = "No Penalty will be charged";
      } else if ((timeDifference < this.cancelTimeLimitExpert) && (timeDifference > 0)) {
        this.penalty = (this.data.session.sessionValue / 100) * 50;
        this.message = "You will be charged 50% of the session fees AS FINE.";
      } else if (timeDifference < 0) {
        this.penalty = this.data.session.sessionValue;
        this.message = "You will be charged 100% of the session fees AS FINE.";
      }
    }

  }

}


// @Component({
//   selector: 'cancellation-confirmation-dialog',
//   template: `
//   <div class="cancellationConfirmationDialogContainer">
//     <div style="display: flex; justify-content: flex-end;">
//       <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
//     </div>

//     <mat-card-title class="penaltyWarning">
//         <div>
//             <mat-icon class="material-icons-filled" style="font-size: 50px !important; margin-right: 35px;">
              
//             </mat-icon>
//         </div>
//         Session Cancelled!
//     </mat-card-title>

//     <p>Your session on {{}} at {{}} IST with {{}} has been cancelled.</p>
//   </div>
// `,
//   styleUrls: ['./cancel.component.css']
// })

// export class cancellationConfirmationDialog implements OnInit {
//   constructor(private dialogRef: MatDialogRef<cancellationConfirmationDialog>,
//     private router: Router,
//     @Inject(MAT_DIALOG_DATA) public data: any) { }

//   ngOnInit() { }

//   ngOnDestroy() { };
// }
