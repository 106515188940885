import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-ups-disable-dialog',
  templateUrl: './pop-ups-disable-dialog.component.html',
  styleUrls: ['./pop-ups-disable-dialog.component.css']
})
export class PopUpsDisableDialogComponent implements OnInit {


  constructor(
    // public dialogRef: MatDialogRef<PopUpsDisableDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  // contactSupport() {
  //   window.open(this.data.supportLink, "_blank");
  // }


}
