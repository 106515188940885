import { Component, OnInit, NgZone  } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  verified:boolean = true;
  role:string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ngZone: NgZone,
              private helper: HelperService,
              public firestore: AngularFirestore,
              public auth: AngularFireAuth) { }


  ngOnInit(): void {
    this.role = this.route.snapshot.paramMap.get('role');

    // this.auth.onAuthStateChanged(user => {
    //   this.checkIfRegisteredUser(user);
    // })
  }

  public checkIfRegisteredUser = (user):Promise<string|void> => {
    if(user){
      var userRef = this.firestore.collection('users').doc(user.uid);
      return userRef.get().toPromise().then(this.onUser).catch(function(error) {
          console.log("Error getting document:", error);
      });
    }
  }

  public onUser = (result):any => {
    if(result.exists){
      var user = result.data();
      console.log('user  ', user)
      // If User is already registered and verified
      if(user['verified'] && user['registered']){
        this.ngZone.run(()=>this.router.navigateByUrl("/" + user["role"] + "/dashboard"));
      }else if(user['verified'] && !user['registered'])
        //User already registered but not verified. Ask them to await verification.
        this.ngZone.run(()=>this.router.navigateByUrl("/" + user["role"] + "/register"));
      return user;
    }else if(this.role == 'parent'){
      // Check if new user is parent. If it is parent then check if it has invitation or not.
      this.checkForInvitation();
      return;
    }else if(this.role == 'support' || this.role == 'expert' || this.role == 'sales-admin')
      this.saveNewUser()   //Save the new user and route to registration
    return user;
  }

  async checkForInvitation(){
    let phoneNumber = await firebase.auth().currentUser.phoneNumber;
    let eMail = await firebase.auth().currentUser.email;
    if(phoneNumber){
      let inviteRef = await this.firestore.collection('invitations', inviteRef => inviteRef.where('verificationNumbers', 'array-contains', phoneNumber));
      await inviteRef.get().subscribe(this.onInvitationResult)
    } else if(eMail){
      let inviteRef = await this.firestore.collection('invitations', inviteRef => inviteRef.where('eMail', '==', eMail));
      await inviteRef.get().subscribe(this.onInvitationResult)
    }
  }

  onInvitationResult = (result) => {
    console.log(result);
    if(!result.empty){
      let docs = [];
      result.forEach((doc) => {
        docs.push(doc.data());
      });
      this.saveNewParent(docs[0]);
    }else{
      console.log('tell the parents to signup with verified number or email');
      this.router.navigateByUrl("/not-found");
    }
  }

  async saveNewParent(result) {
    console.log('save new parent is calling')
    var usersRef = this.firestore.collection("users");
    const id = await firebase.auth().currentUser.uid;
    const name = await firebase.auth().currentUser.displayName;
    const email = await firebase.auth().currentUser.email;
    const phoneNumber = await firebase.auth().currentUser.phoneNumber;
    const createdBy = firebase.auth().currentUser.uid;
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    usersRef.doc(id).set({
                    id:id,
                    interaktId:result.userId,
                    name: name,
                    phoneNumber: phoneNumber,
                    email: email,
                    role:'parent',
                    myExpertsIds:result.myExpertsIds,
                    myExperts:result.educators,
                    sessionPackValue:result.sessionPackValue,
                    numberOfSessions:result.numberOfSessions,
                    countryCode:result.whatsappNumberCountryCode,
                    whatsappNumber:result.whatsappNumber,
                    verified:false,
                    registered:false,
                    createdOn: timestamp,
                    createdBy: createdBy}).then(this.onParentCreated)
    .catch(function(error) {
        console.error("Error creating User: ", error);
    });
    return;
  }

  onParentCreated = async() =>{
    this.router.navigateByUrl( "/" + this.role + "/register");
  }

  async saveNewUser(){
    console.log('save new user is calling')
    var usersRef = this.firestore.collection("users");
    const id = await firebase.auth().currentUser.uid;
    const interaktId = this.helper.uuidv4();
    const name = await firebase.auth().currentUser.displayName;
    const email = await firebase.auth().currentUser.email;
    const phoneNumber = await firebase.auth().currentUser.phoneNumber;
    const createdBy = firebase.auth().currentUser.uid;
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    let verify:boolean;
    let register:boolean;
    if(this.role == 'sales-admin' || this.role == 'support'){
      verify=true
      register=true;
    } else {
      verify = false;
      register=false;
    }
    usersRef.doc(id).set({
                    id:id,
                    name: name,
                    interaktId: interaktId,
                    phoneNumber:phoneNumber,
                    email: email,
                    role:this.role,
                    verified:verify,
                    registered:register,
                    createdOn: timestamp,
                    createdBy: createdBy}).then(this.onUserCreated)
    .catch(function(error) {
        console.error("Error creating User: ", error);
    });
  }

  onUserCreated = async() =>{
    if(this.role == 'sales-admin' || this.role == 'support'){
      this.router.navigateByUrl( "/" + this.role + "/dashboard");
    } else{
      this.router.navigateByUrl( "/" + this.role + "/register");
    }
  }

  async successCallback(event){
    // this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    //Check if user is already registered.
    this.checkIfRegisteredUser(event.authResult.user)
    // .then(user => {
      // if(!user){
      //   //Save the new user and route to registration
      //   this.saveNewUser()
      // }else{
      //   if(user["verified"])
      //     this.router.navigateByUrl( "/" + user["role"] + "/dashboard");
      //   else
      //     this.verified = false;
      // }
    // })
      //if Registered then take them to the respective screen based on user.role
      //else take them to the registeration workflow.
  }

  errorCallback(event){

  }
}
