import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css']
})
export class MeetingComponent implements OnInit, AfterViewInit {
	private peerConnection = null;
	private localStream = null;
	private remoteStream = null;
	private roomDialog = null;
	private roomId = null;

	// Default configuration - Change these if you have a different STUN or TURN server.
	configuration:any = {
	  iceServers: [
	    {
	      urls: [
	        'stun:stun1.l.google.com:19302',
	        'stun:stun2.l.google.com:19302',
	      ],
	    },
	  ],
	  iceCandidatePoolSize: 10,
	};

	@ViewChild('localVideo') localVideo:ElementRef;
	@ViewChild('remoteVideo') remoteVideo:ElementRef;

  id:string;
  constructor(
    public firestore: AngularFirestore,
    private snackBar:MatSnackBar,
    public dialogRef: MatDialogRef< MeetingComponent>,
	    @Inject(MAT_DIALOG_DATA) public data: string) {
    this.roomId = data['id'];
   }

  onNoClick(){
  	this.hangUp()
    this.dialogRef.close();
  }

  ngOnInit(): void {
  	
  }

  ngAfterViewInit(): void{
  	 this.initialize();
  }

  async initialize(){
  	await this.openUserMedia();

  	//Check if room exists for the session
  	const roomRef = await this.firestore.collection('rooms').doc(this.roomId);
  	roomRef.get().subscribe(result=>{
  		if(!result.exists){
  			this.createRoom();
  		}else{
  			this.joinRoom();
  		}
  	})

  	
  }

  async createRoom() {
	  console.log('Create PeerConnection with configuration: ', this.configuration);
	  this.peerConnection = new RTCPeerConnection(this.configuration);

	  this.registerPeerConnectionListeners();

	  this.localStream.getTracks().forEach(track => {
	    this.peerConnection?.addTrack(track, this.localStream);
	  });

	  //Create Room
	  const offer = await this.peerConnection?.createOffer();
		await this.peerConnection?.setLocalDescription(offer);

		const roomRef = this.firestore.collection('rooms').doc(this.roomId); 
		// Code for collecting ICE candidates below
	  const candidatesCollection = roomRef.collection("callerCandidates");
    this.peerConnection?.addEventListener('icecandidate', event => {
        if (event.candidate) {
          const json = event.candidate.toJSON();
          candidatesCollection.add(json);
        }
    });	 
	  // Code for collecting ICE candidates above

		const roomWithOffer = {
				id:this.roomId,
		    offer: {
		        type: offer.type,
		        sdp: offer.sdp
		    }
		}
		roomRef.set(roomWithOffer);

		this.peerConnection?.addEventListener('track', event => {
	    console.log('Got remote track:', event.streams[0]);
	    event.streams[0].getTracks().forEach(track => {
	      console.log('Add a track to the remoteStream:', track);
	      this.remoteStream.addTrack(track);
	    });
	  }); 

	  roomRef.valueChanges().subscribe(async(result)=>{
			const data:any = result;
	    if (!this.peerConnection?.currentRemoteDescription && data?.answer) {
	        console.log('Set remote description: ', data.answer);
	        const answer = new RTCSessionDescription(data.answer)
	        await this.peerConnection?.setRemoteDescription(answer);
	    }
		});	  

		roomRef.collection("calleeCandidates").valueChanges().subscribe((results)=>{
    	if(results.length>0){
    		results.forEach(async(result)=>{
					const data:any = result;
		      const candidate = new RTCIceCandidate(result);
		      await this.peerConnection?.addIceCandidate(candidate);
		    });
	    }
  	}); 
	}

	joinRoom() {
	  const roomRef = this.firestore.collection('rooms').doc(this.roomId);
	  roomRef.get().subscribe(this.onRoomJoined);
	}

	onRoomJoined = async(result) =>{ 
	  if (result.exists) {
	    console.log('Create PeerConnection with configuration: ', this.configuration);
	    this.peerConnection = new RTCPeerConnection(this.configuration);
	    this.registerPeerConnectionListeners();
	    this.localStream.getTracks().forEach(track => {
	      this.peerConnection?.addTrack(track, this.localStream);
	    });

	    const roomRef = this.firestore.collection('rooms').doc(this.roomId);

	    const candidatesCollection = roomRef.collection("calleeCandidates");
	    this.peerConnection?.addEventListener('icecandidate', event => {
	        if (event.candidate) {
	          const json = event.candidate.toJSON();
	          candidatesCollection.add(json);
	        }
	    });	    

	    this.peerConnection?.addEventListener('track', event => {
	      console.log('Got remote track:', event.streams[0]);
	      event.streams[0].getTracks().forEach(track => {
	        console.log('Add a track to the remoteStream:', track);
	        this.remoteStream.addTrack(track);
	      });
	    });

	    
	    const offer = result.data().offer;
			await this.peerConnection?.setRemoteDescription(new RTCSessionDescription(offer));
			const answer = await this.peerConnection?.createAnswer();
			await this.peerConnection?.setLocalDescription(answer);

			const roomWithAnswer = {
			    answer: {
			        type: answer.type,
			        sdp: answer.sdp
			    }
			}
			await roomRef.update(roomWithAnswer);

	    roomRef.collection("callerCandidates").valueChanges().subscribe((results)=>{
	    	if(results.length>0){
	    		results.forEach(async(result)=>{
						const data:any = result;
			      const candidate = new RTCIceCandidate(result);
			      await this.peerConnection?.addIceCandidate(candidate);
			    });
		    }
	  	});

	  }
	}

	async openUserMedia() {
	  const stream = await navigator.mediaDevices.getUserMedia(
	      {video: true, audio: true});
	  this.localVideo.nativeElement.srcObject = stream;
	  this.localStream = stream;
	  this.remoteStream = new MediaStream();
	  this.remoteVideo.nativeElement.srcObject = this.remoteStream;
	}

	async hangUp() {
	  const tracks = this.localVideo.nativeElement.srcObject.getTracks();
	  tracks.forEach(track => {
	    track.stop();
	  });

	  if (this.remoteStream) {
	    this.remoteStream.getTracks().forEach(track => track.stop());
	  }

	  if (this.peerConnection) {
	    this.peerConnection?.close();
	    this.peerConnection = null;
	  }

	  this.localVideo.nativeElement.srcObject = null;
	  this.localVideo.nativeElement.srcObject = null;
	
	  // Delete room on hangup
	  if (this.roomId) {
	    const roomRef = this.firestore.collection('rooms').doc(this.roomId);
	    // const calleeCandidates = await roomRef.collection('calleeCandidates').get();
	    // calleeCandidates.forEach( candidate => {
	    // 	candidate.docs.forEach(async doc=>{
	    // 		await doc.data().delete();
	    // 	})
	    // });
	    // const callerCandidates = await roomRef.collection('callerCandidates').get();
	    // callerCandidates.forEach(candidate => {
	    //   candidate.docs.forEach(async doc=>{
	    // 		await doc.data().delete();
	    // 	})
	    // });
	    await roomRef.delete();
	  }
	  // document.location.reload(true);
	}

	registerPeerConnectionListeners() {
	  this.peerConnection?.addEventListener('icegatheringstatechange', () => {
	    console.log(
	        `ICE gathering state changed: ${this.peerConnection?.iceGatheringState}`);
	  });

	  this.peerConnection?.addEventListener('connectionstatechange', () => {
	  	if(this.peerConnection?.connectionState=='disconnected'){
	  		var message = "Connection has been lost. Please rejoin by clicking on Join."
	      this.snackBar.open(message, "", {
	        duration: 4000,
	      });	
	      this.onNoClick();
	  	}
	    console.log(`Connection state change: ${this.peerConnection?.connectionState}`);
	  });

	  this.peerConnection?.addEventListener('signalingstatechange', () => {
	    console.log(`Signaling state change: ${this.peerConnection?.signalingState}`);
	  });

	  this.peerConnection?.addEventListener('iceconnectionstatechange ', () => {
	    console.log(
	        `ICE connection state change: ${this.peerConnection?.iceConnectionState}`);
	  });
	}
}
