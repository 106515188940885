import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { HelperService } from '../helper.service';
import { InteraktUserService } from '../services/interakt-user.service';
import { MultiExpertSlotsService } from '../services/multi-expert-slots.service';
import { NotificationService } from '../services/notification.service';
import { SessionService } from '../services/session.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'Do MMMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-find-experts',
  templateUrl: './find-experts.component.html',
  styleUrls: ['./find-experts.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})


export class FindExpertsComponent implements OnInit {

  dateFormat = new FormControl(moment());
  isLoading = false;
  loadingSetInterval: any;
  minDate = new Date();
  currentAdmin: any;
  newSessionForm: any;
  selectedSlot: any;
  formData: any = {};
  selectedSessionExpert: any;
  selectedSessionDate: Date;
  selectedDate: Date;
  slotDuration: any = 30;
  phoneNumberCountryCode = '+91';
  expertsMatchingServices: any[];
  expertsMatchingLanguages: any[];
  sessionStartTime: any;
  sessionEndTime: any;
  sessionEndDateTime: any;
  expertsMatchingAgeRange: any[];
  expertsMatchingServicesAndLanguages: any[];
  expertMatchingServicesAndAgeRange: any[];
  expertsMatchingAll: any[];
  finalExpertsList: any[];
  slotSub: Subscription;
  selectedSlotTimeFull: string;
  whatsappNumberCountryCode = '+91';
  expertMatchFailed: boolean = false;
  expertMatchFailedMessage = '';
  newConsultationId: any;
  primaryLanguage: any;
  secondaryLanguages: any[];
  selectedLanguages: any[];
  formFirstPart: any;
  tomorrow: Date = new Date();
  currentUser: any;
  isSlotSelected: boolean = false;
  ageOfChild: any;

  constructor(private fb: FormBuilder,
    public multiExpertSlotsService: MultiExpertSlotsService,
    private helper: HelperService,
    private firestore: AngularFirestore,
    private sessionService: SessionService,
    private auth: AngularFireAuth,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
    private notificationService: NotificationService,
    private newInteraktUserService: InteraktUserService) { }

  async ngOnInit() {
    const today = new Date();
    this.tomorrow.setDate(today.getDate() + 1);

    this.newSessionForm = this.fb.group({
      session: this.fb.array([
        this.formFirstPart = this.fb.group({
          childCondition: ['', Validators.required],
          dateOfBirth: ['', Validators.required],
          primaryLanguage: ['', Validators.required],
          secondaryLanguages: ['', Validators.required],
          serviceType: ['', Validators.required],
          preferredDate: ['', Validators.required],
          preferredTimes: ['', Validators.required],
        })
      ])
    });

    this.auth.currentUser.then(
      (user) => {
        this.firestore.doc(`users/${user.uid}`).valueChanges().pipe(take(1)).subscribe(
          (userDoc: AngularFirestoreDocument) => {
            this.currentUser = userDoc;
            console.log('current admin ', this.currentUser)
          }
        );
      }
    );

  }

  onDateChange(event) {
    this.isLoading = true;
    this.isSlotSelected = false;
    this.dateFormat.setValue(event.value._d); //dateFormat is changed from moment to date

    //initializing date of consultation
    let date = new Date(event.value._d);
    this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
    this.selectedDate = new Date(date);
    this.newSessionForm.value.session[0].preferredDate = date;
    this.finalizeTheExpertList(this.finalExpertsList);
  }

  incrementDate() {
    this.isLoading = true;
    this.isSlotSelected = false;
    let date = this.dateFormat.value;
    date.setDate(date.getDate() + 1);
    this.dateFormat.setValue(date);

    //initializing date of consultation
    this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
    this.selectedDate = new Date(date);
    this.newSessionForm.value.session[0].preferredDate = date;
    this.finalizeTheExpertList(this.finalExpertsList);
  }

  decrementDate() {
    let dateDemo = new Date(this.dateFormat.value._d);
    if ((this.minDate.getDate() == dateDemo.getDate()) && (this.minDate.getMonth() == dateDemo.getMonth())) {
      return;
    } else {
      this.isLoading = true;
      let date = this.dateFormat.value;
      date.setDate(date.getDate() - 1);
      this.dateFormat.setValue(date);

      //initializing date of consultation
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.newSessionForm.value.session[0].preferredDate = date;
      this.finalizeTheExpertList(this.finalExpertsList);
      this.isSlotSelected = false;
    }
  }

  calculateAge(dateOfBirth) {
    let today: any = new Date();
    let birthDate: any = new Date(dateOfBirth);
    const diffTime = Math.abs(today - birthDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    diffDays = diffDays / 365
    // let age = today.getFullYear() - birthDate.getFullYear();
    // let m = today.getMonth() - birthDate.getMonth();
    // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //   age--;
    // }
    let age = diffDays;
    age = Math.round(age * 10) / 10
    return age;
  }

  getConditions() {
    return this.helper.expertConditions;
  }

  getConsultationPreferredTimes() {
    return this.helper.consultationPreferredTimes;
  }

  getGendersTypes() {
    return this.helper.genders;
  }

  getLanguages() {
    return this.helper.languages;
  }

  getSecondaryLanguages() {
    let languages = [];
    this.helper.languages.forEach((language) => {
      if (language != this.newSessionForm.value.session[0].primaryLanguage) {
        languages.push(language);
      }
    });
    return languages;
  }

  getServices() {
    return this.helper.services;
  }

  onPhoneNumberCountryChange(event) {
    this.phoneNumberCountryCode = '+' + event.dialCode;
  }

  onWhatsappNumberCountryChange(event) {
    this.whatsappNumberCountryCode = '';
    this.whatsappNumberCountryCode = '+' + event.dialCode;
  }

  onDateOfBirthSelected(event) {
    this.newSessionForm.value.session[0].dateOfBirth = this.changeStringToDate(event);
    this.ageOfChild = this.calculateAge(this.newSessionForm.value.session[0].dateOfBirth);
    console.log('age of child is ', this.ageOfChild);
    this.newSessionForm.value.session[1].childAge.setValue(this.ageOfChild);
  }

  changeStringToDate(dateString:any) {
    console.log(dateString)
    var dateParts = dateString.split("/");

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    console.log('date ', dateObject)
    return dateObject;
  }

  onPreferredDateSelected(event) {
    console.log("preferred Date Selected: ", event.value._d);
    //changed preferredDate from moment to date format
    let date = this.newSessionForm.value.session[0].preferredDate;
    this.newSessionForm.value.session[0].preferredDate = new Date(date);
    this.isSlotSelected = false;
  }

  // starting function for finding the expert
  findExperts() {
    this.isLoading = true;
    let formData: any = {};
    let session: any = this.newSessionForm.value.session;

    session.forEach(data => {
      formData = { ...formData, ...data }
    })
    this.formData = formData;

    //initializing date of consultation
    let date = new Date(this.formData.preferredDate);
    this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
    this.selectedDate = new Date(date);

    // getting the experts who matches the selected service.
    let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'expert').where('verified', '==', true).where('serviceTypes', 'array-contains', formData.serviceType));
    ref.get().subscribe(this.onExpertMatchingServices)
  }

  // after getting the service matching experts
  onExpertMatchingServices = (results) => {
    if (results.empty) {
      this.expertMatchFailed = true;
    } else {
      this.expertsMatchingServices = [];
      results.forEach(doc => {
        let expert: any = doc.data();
        this.expertsMatchingServices.push(expert);
      })

      // list of all selected languages
      this.selectedLanguages = [];
      this.selectedLanguages.push(this.formData.primaryLanguage);
      this.formData.secondaryLanguages.forEach(language => {
        this.selectedLanguages.push(language);
      });

      // getting the experts who matches the selected languages.
      let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'expert').where('verified', '==', true).where('languages', 'array-contains-any', this.selectedLanguages));
      ref.get().subscribe(this.onExpertMatchingLanguages)
    }

  }

  // after getting the language matching experts
  onExpertMatchingLanguages = (results) => {
    if (results.empty) {
      this.expertMatchFailed = true;
    } else {

      this.expertsMatchingLanguages = [];
      results.forEach(doc => {
        let expert: any = doc.data();
        this.expertsMatchingLanguages.push(expert);
      })

      this.checkExpertsMatchingServiceAndLanguages();
    }
  }

  // to get the common experts matching selected languages and service.
  checkExpertsMatchingServiceAndLanguages() {
    let hashTable: any = {};
    this.expertsMatchingServicesAndLanguages = [];

    // mark the experts who are matching the selected services in hash table.
    this.expertsMatchingServices.forEach(serviceExpert => {
      if (!hashTable[serviceExpert.id]) {
        hashTable[serviceExpert.id] = true;
      }
    })

    // check, if the experts who are matching the selected languages are in the hash table.
    this.expertsMatchingServicesAndLanguages = [];
    this.expertsMatchingLanguages.forEach(languageExpert => {
      if (hashTable[languageExpert.id]) {
        this.expertsMatchingServicesAndLanguages.push(languageExpert)
      }
    })

    // if there are experts matching both the selected service and the languages, then move forward.
    if (this.expertsMatchingServicesAndLanguages.length > 0) {
      this.checkLanguageMatchWeightage();
    } else {
      this.expertMatchFailed = true;
    }

  }

  // to get the language weightage percentage of the experts.
  checkLanguageMatchWeightage() {
    let selectedLanguagesHashTable: any = {};

    // add all the selected languages in hash table.
    this.selectedLanguages.forEach(selectedLanguage => {
      if (!selectedLanguagesHashTable[selectedLanguage]) {
        selectedLanguagesHashTable[selectedLanguage] = true;
      }
    })

    let finalExpertsList = [];
    this.expertsMatchingServicesAndLanguages.forEach(expert => {
      let expertLanguages = [];
      expertLanguages = expert.languages;

      let finalExpert: any = expert;
      finalExpert.languageWeightage = 0;
      let matchingLanguages = [];

      expertLanguages.forEach(language => {
        // if the selected language is matched with expert's language
        if (selectedLanguagesHashTable[language]) {
          matchingLanguages.push(language);

          // check if the matched language is primary or secondary one.
          if ((language == this.formData.primaryLanguage) && (finalExpert.languageWeightage < 60)) {
            finalExpert.languageWeightage += 60;
          } else if ((language != this.formData.primaryLanguage) && (finalExpert.languageWeightage != 40) && (finalExpert.languageWeightage != 100)) {
            finalExpert.languageWeightage += 40;
          }
        }
      })

      finalExpert.matchingLanguages = matchingLanguages;
      finalExpertsList.push(finalExpert);
    })

    this.finalizeTheExpertList(finalExpertsList);
  }

  // to finalize the list of expert to show on second step.
  async finalizeTheExpertList(expertList) {
    let expertsList = [];
    expertsList = expertList;
    this.finalExpertsList = [];

    expertsList.forEach(doc => {
      let expert: any = doc;

      // get the preferred time weightage
      let preferredTimeWeightage = this.checkPreferredTimeWeightage(expert);
      expert.preferredTimeWeightage = preferredTimeWeightage;
      expert.isPreferredTimeMatched = (preferredTimeWeightage > 0);

      // get the age group weightage
      let ageWeightage = this.checkAgeWeightage(expert);
      expert.ageWeightage = ageWeightage;
      expert.isAgeRangeMatched = (ageWeightage > 0);

      // get the area of concern weightage
      let concernWeightage = this.checkConcernWeightage(expert);
      expert.concernWeightage = concernWeightage;
      expert.isConcernMatched = (concernWeightage > 0);

      // calculating the average weightage percentage of the expert.
      let averageWeightage = (expert.languageWeightage + (preferredTimeWeightage + ageWeightage + concernWeightage)) / 2;
      expert.averageWeightage = averageWeightage;

      // add this expert with all the weightage details in the final list.
      this.finalExpertsList.push(expert)
    })
    console.log("Checking finalExpertList: ", this.finalExpertsList);
    // get the slots of the final list experts.
    let date = new Date(this.newSessionForm.value.session[0].preferredDate);
    this.dateFormat.setValue(date);
    this.multiExpertSlotsService.getSlots(this.finalExpertsList, this.formData.preferredTimes, date, this.slotDuration);
    this.onLoadingMessage();
  }

  // to get the preferred time weightage percentage of the expert
  checkPreferredTimeWeightage(expert) {
    let preferredTimeWeightage = 0
    let date = new Date(this.formData.preferredDate);
    let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    let day = days[date.getDay()];

    let workingHoursOfThisDay = [];
    workingHoursOfThisDay = this.sessionService.getWorkingHours(expert, day);

    let selectedTimes = [];
    selectedTimes = this.formData.preferredTimes;

    selectedTimes.forEach(time => {
      let slotStartTime = time.split(":");
      // to get session starting time in minutes
      const startingHour = parseInt(slotStartTime[0], 10);
      slotStartTime = (startingHour * 60);

      let isInsideWorkingHours: boolean = false;
      isInsideWorkingHours = this.sessionService.isInsideWorkingHours(workingHoursOfThisDay, slotStartTime, 120);

      if (isInsideWorkingHours == true) {
        preferredTimeWeightage = 40;
        return preferredTimeWeightage;
      }
    })

    return preferredTimeWeightage;
  }

  // to get the childrange weightage percentage of the expert
  checkAgeWeightage(expert) {
    let age = this.calculateAge(this.formData.dateOfBirth);
    let startingAge = expert?.startingAgeRange;
    let endingAge = expert?.endingAgeRange;
    if (age >= startingAge && age <= endingAge) {
      return 30;
    } else {
      return 0;
    }
  }

  // to get the area of concern weightage percentage of the expert
  checkConcernWeightage(expert: any) {
    let concernWeightage = 0;
    let concerns = [];
    concerns = this.formData.childCondition;
    let concernsHashTable: any = {}

    // add all the selected concerns in hash table.
    concerns.forEach(concern => {
      if (!concernsHashTable[concern]) {
        concernsHashTable[concern] = true;
      }
    })

    let expertConcerns: any[];
    expertConcerns = [];
    expertConcerns = expert?.condition;

    if (expertConcerns) {
      expertConcerns.forEach(expertConcern => {
        // if the selected concern is matched with expert's concerns
        if (concernsHashTable[expertConcern]) {
          concernWeightage = 30;
        }
      })
    }

    return concernWeightage;
  }

  //creating loading message functionality
  onLoadingMessage() {
    this.loadingSetInterval = setInterval(() => {
      let len = Object.keys(this.multiExpertSlotsService.expertListWithSlots).length;
      console.log("Length of expertListWithSlots object: ", len);
      console.log("Length of finalExpertList: ", this.finalExpertsList.length);
      if (len === this.finalExpertsList.length) {
        this.finalExpertsList.sort(this.compareTime);
        clearInterval(this.loadingSetInterval);
        this.isLoading = false;
      }
    }, 1000)
  }

  // function to sort the finalExpertsList array
  compareTime(a, b) {
    if (a.averageWeightage > b.averageWeightage) {
      return -1;
    } else if (a.averageWeightage < b.averageWeightage) {
      return 1;
    } else {
      return 0;
    }
  }

  onSlotSelected(slotValue, expert) {
    console.log("Checking selected slot: ", slotValue);
    this.selectedSessionExpert = expert;
    this.selectedSlot = slotValue;
    this.isSlotSelected = true;
    this.selectedSlotTimeFull = slotValue.label;
    this.setSessionTimings();
  }

  setSessionTimings() {
    let sessionStartTime = this.selectedSlot.split(":");

    // to get session starting time in minutes
    const startingHour = parseInt(sessionStartTime[0], 10);
    const startingMinute = parseInt(sessionStartTime[1], 10);
    sessionStartTime = (startingHour * 60) + startingMinute;
    let slotDuration = this.slotDuration;

    // this is session end time in minutes
    let sessionEndTime = sessionStartTime + slotDuration;

    let endHour = Math.floor(sessionEndTime / 60);
    let endMinute = sessionEndTime % 60;

    // creating a session end timestamp for filter comparison
    let istSessionEndDateTime = this.helper.istTime3(this.selectedDate, endHour, endMinute);
    this.sessionEndDateTime = istSessionEndDateTime;

    if (endMinute == 0) {
      sessionEndTime = endHour + ":" + endMinute + '0';
    } else {
      sessionEndTime = endHour + ":" + endMinute;
    }

    this.sessionStartTime = this.selectedSlot;
    this.sessionEndTime = sessionEndTime;
  }

  dateFilter(d: Date) {
    let currentTime = new Date();
    let currentOffset = currentTime.getTimezoneOffset();
    let ISTOffset = 330;   // IST offset UTC +5:30 
    let now = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    if (
      d.getDate() >= now.getDate() &&
      d.getMonth() == now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (
      d.getMonth() > now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (d.getFullYear() > now.getFullYear()) return true;
    else if (d.getFullYear() < now.getFullYear()) return false;
  }

}
