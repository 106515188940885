import { Component, OnInit, NgZone } from '@angular/core';
import axios from 'axios';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { ParentsService } from '../services/parents.service';

@Component({
  selector: 'app-parent-sso-login',
  templateUrl: './parent-sso-login.component.html',
  styleUrls: ['./parent-sso-login.component.css']
})
export class ParentSsoLoginComponent implements OnInit {
  token: string;

  constructor(private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private ngZone: NgZone,
    private router: Router,
    public parentsService: ParentsService,
    private toastr: ToastrService) { }

  async ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');

    if (this.token) {
      localStorage.setItem('token', this.token);
      const config = { "headers": { "token": this.token } };
      axios.get('https://mykinderpass.com/api/v1/get_custom_token/', config).then(response => {
        firebase.auth().signInWithCustomToken(response.data.responseData.user_token)
          .then((userCredential) => {
            // Signed in
            this.auth.onAuthStateChanged(user => {
              this.checkIfRegisteredUser(user);
              console.log(user, "user");
            })

          })
          .catch((error) => {
            console.log(error)

          });
      }).catch(error => {
        console.log(error.response)
        if (error.response.status === 401) {
          window.location.href = "https://mykinderpass.com/auth/login";
        }
      });
    } else {
      this.auth.onAuthStateChanged(user => {
        if (user) {
          this.checkIfRegisteredUser(user);
        } else {
          window.location.href = "https://mykinderpass.com/auth/login";
        }
      })
    }
  }
  public checkIfRegisteredUser = (user): Promise<string | void> => {
    if (user) {
      var userRef = this.firestore.collection('users').doc(user.uid);
      return userRef.get().toPromise().then(this.onUser).catch(function (error) {
        console.log("Error getting document:", error);
      });
    }
  }

  public onUser = (result): any => {
    if (result.exists) {
      var user = result.data();
      // If parent is already registered and verified
      if (user['verified'] && user['registered']) {
        this.ngZone.run(() => this.router.navigateByUrl("/" + user["role"] + "/dashboard"));
      }
      return user;
    }
  }
  async successCallback(result) {
    // this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    //Check if user is already registered.
    this.checkIfRegisteredUser(result.user);
  }
}
