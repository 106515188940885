import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { InviteDialog } from 'src/app/invite/invite.component';
import { AdminService } from 'src/app/services/admin.service';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { HelperService } from 'src/app/helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-parents-tab',
  templateUrl: './admin-parents-tab.component.html',
  styleUrls: ['./admin-parents-tab.component.css']
})
export class AdminParentsTabComponent implements OnInit, OnDestroy {
  filter = 'Active';
  invitationsSub: Subscription;
  expertSub: Subscription;
  parentSub: Subscription;
  inactiveParentsSub: Subscription;
  invitations: AngularFirestoreDocument[];
  invitationsCopy: AngularFirestoreDocument[];
  experts: AngularFirestoreDocument[];
  parents: AngularFirestoreDocument[];
  parentsCopy: AngularFirestoreDocument[];
  renewalParents: AngularFirestoreDocument[];
  renewalParentsCopy: AngularFirestoreDocument[];
  inactiveParents: AngularFirestoreDocument[];
  inactiveParentsCopy: AngularFirestoreDocument[];
  selectedDate: Date;
  expertSelected: string;
  expertName = '';
  balance: string;
  dropdownExpertHover = false;
  currentUser: any;
  expertClicked = false;
  isExpertClicked = false;

  constructor(public adminService: AdminService, private toastr: ToastrService, private helper: HelperService, private dialog: MatDialog, private router: Router, private firestore: AngularFirestore, private auth: AngularFireAuth) { }

  async ngOnInit() {
    this.auth.currentUser.then(
      (user) => {
        console.log(user?.uid);
        this.firestore.doc(`users/${user?.uid}`).valueChanges().pipe(take(1)).subscribe(
          (userDoc: AngularFirestoreDocument) => {
            this.currentUser = userDoc;
          }
        );
      }
    );

    this.invitationsSub = this.adminService.getFilteredInvitations(undefined, undefined).subscribe(
      result => {
        this.invitations = result;
        this.invitationsCopy = this.invitations;
        this.invitations.sort((a: any, b: any) => {
          let name1 = a.name.toLowerCase();
          let name2 = b.name.toLowerCase();

          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
          return 0;
        });
      }
    );

    this.expertSub = this.adminService.ExpertList.subscribe(
      result => { this.experts = result; } //storing experts list
    );

    this.inactiveParentsSub = this.adminService.getInActiveParentList(undefined, undefined).subscribe(
      result => {
        console.log('inactive parent sub getting callled')
        this.inactiveParents = [];
        this.inactiveParentsCopy = [];

        this.inactiveParents = result;
        this.inactiveParents.sort((a: any, b: any) => {
          let name1 = a.fullName?.toLowerCase();
          let name2 = b.fullName?.toLowerCase();
          
          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
          return 0;
        });
      
        this.inactiveParentsCopy = this.inactiveParents;
      }
    )

    this.parentSub = this.adminService.getFilteredParentList(undefined, undefined).subscribe(
      result => {
        console.log('parents sub getting called')
        // this.parents = []
        // this.parents.length = 0;
        // this.renewalParents = [];
        // this.renewalParents.length = 0;
        // this.renewalParentsCopy = [];
        // this.renewalParentsCopy.length = 0;


        this.parents = result; //storing parents list

        this.parents.sort((a: any, b: any) => {
          let name1 = a.fullName?.toLowerCase();
          let name2 = b.fullName?.toLowerCase();

          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
          return 0;
        });

        // this.getRenewalParentList(this.parents)

        for (let parent of this.parents) {
          this.firestore.doc(`balances/${parent['id']}`).valueChanges().pipe(take(1)).subscribe(
            (result: AngularFirestoreDocument) => {
              if (result) parent['currentBalance'] = result['currentBalance']; //storing parent balance
              else parent['currentBalance'] = "Not Found";
            }
          );
        }
        this.parentsCopy = this.parents;
      }
    );

    await this.adminService.isSupport();
  }

  getRenewalParentList(parentList) {
    let parents: any[];
    parents = [];
    parents = parentList;

    let renewalList: any[];
    let renewalListCopy: any[];
    renewalList = [];
    renewalListCopy = [];

    for (let parent of parents) {
      let renewalCounter = 0;
      let scheduledSessions = 0;

      // getting the sessions of the parent.
      let ref = this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId', '==', parent['id']).orderBy('sessionEndDateTime', 'asc'))
      ref.get().toPromise().then(results => {
        if (!results.empty) {
          let maxDate = new Date(8640000000000000);
          let minDate = new Date(-8640000000000000);
          let now = new Date();
          let previousSessionMinDate = new Date(-8640000000000000);
          const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          results.forEach(doc => {
            let session: any = doc.data();
            console.log(session,"session")
            let endTime: Date = new Date(session.sessionEndDateTime.toDate());

            let sessionDate: Date = new Date(session.sessionDate.toDate());
            let dateString = sessionDate.getDate() + " " + monthNames[sessionDate.getMonth()] + ", " + sessionDate.getFullYear() + "; " + session.startTime + " IST";

            if (session.status == "Scheduled" && endTime > previousSessionMinDate) {
              parent['lastScheduledSession'] = dateString;
              previousSessionMinDate = new Date(endTime);
            }

            if (session.status === "Scheduled" && this.isFutureSessionForRenewalList(session)) {
              renewalCounter += 1;
              scheduledSessions += 1;
            }

            if (endTime.getTime() <= now.getTime() && endTime.getTime() > minDate.getTime()) {
              if (session.status == "Attended") {
                parent['lastAttendedSession'] = dateString;
              }

              parent['lastSession'] = dateString;
              minDate = new Date(endTime);
            } else if (endTime.getTime() > now.getTime() && endTime.getTime() < maxDate.getTime()) {
              parent['nextSession'] = dateString;
              maxDate = new Date(endTime);
            }
          })

          parent['scheduledSessions'] = scheduledSessions;
        }

        this.firestore.doc(`balances/${parent['id']}`).valueChanges().pipe(take(1)).toPromise().then((result: AngularFirestoreDocument) => {
          if (result) {
            parent['currentBalance'] = result['currentBalance']; //storing parent balance
            renewalCounter += result['currentBalance'];;
          } else parent['currentBalance'] = "Not Found";

          // adding parent to renewal list.
          if (renewalCounter <= 3) {
            renewalList.push(parent);
            renewalListCopy.push(parent);
          }
        });
      })
    }

    this.renewalParents = renewalList;
    this.renewalParentsCopy = renewalListCopy;
    console.log('parent list length ', this.parents.length)
    console.log('renewal list length ', this.renewalParents.length)
    console.log('renewal list copy length ', this.renewalParentsCopy.length)
  }

  // function to check if the session is a past session or future session than current time.
  isFutureSessionForRenewalList(session) {
    let sessionDate: Date = session.sessionDate.toDate();
    let exactSessionDate = this.helper.istTime2(sessionDate);

    // startTime is HH:MM
    let startTime = session.startTime.split(":");
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);

    //Convert start time to minutes and add to session date to get exact start date time.
    exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute);

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);

    // if the session start time is greater than the current time.
    if (exactSessionDate.getTime() > now.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  isArray(variable: any) {
    return variable?.constructor === Array
  }

  getParentStatus() {
    return this.helper.parentStatus;
  }

  getParentStatusForProgramRole() {
    return this.helper.parentActivityStatus;
  }

  onFilterSelected(filter: string) {
    this.filter = filter;
    this.selectedDate = undefined;
    this.balance = '';
    if (filter === 'Invited') {
      this.invitations = this.invitationsCopy;
    } else if (filter === 'Active') {
      console.log('parents copy ', this.parentsCopy)
      this.parents = this.parentsCopy;
    } else if (filter === 'Renewal') {
      this.renewalParents = [];
      console.log('renewal copy ', this.renewalParentsCopy)
      this.renewalParents = this.renewalParentsCopy;
    } else if (filter === 'Inactive') {
      this.inactiveParents = [];
      this.inactiveParents = this.inactiveParentsCopy;
    }
  }

  openInteraktChat(parent: any) {
    let number: string = parent.countryCode + parent.whatsappNumber;
    number = number.slice(0, 0) + number.slice(1);
    let link = 'https://app.interakt.ai/inbox?channelPhoneNumber=' + number;
    window.open(link, '_blank') || window.location.replace(link);
  }

  onBalanceChange(event: number) {
    if (this.filter === 'Invited') {
      this.filterInvitations(this.balance, this.selectedDate);
    } else if (this.filter === 'Active') {
      console.log(this.balance + "   " + this.expertSelected); //filtering when only balance is changed
      this.filterParents(this.balance, this.expertSelected);
    } else if (this.filter === 'Renewal') {
      this.filterRenewalParents(this.balance, this.expertSelected);
    }
  }

  onExpertSelected() {
    if (this.filter === 'Active') {
      this.filterParents(this.balance, this.expertSelected);
    } else if (this.filter === 'Renewal') {
      this.filterRenewalParents(this.balance, this.expertSelected);
    }
  }

  onDateChange() {
    if (this.filter === 'Invited') {
      this.filterInvitations(this.balance, this.selectedDate);
    } else if (this.filter === 'Inactive') {
      this.filterInactiveList(this.selectedDate);
    }
  }

  resetPicker() {
    this.invitations = this.invitationsCopy; // to reset all filters on invitations page
  }

  inviteParent() {
    this.dialog.open(InviteDialog, {
      data: { experts: this.experts, admin: this.adminService.adminDoc }
    });
  }

  onView(parent: AngularFirestoreDocument) {
    this.router.navigateByUrl(`/support/parent/${parent['id']}`); //rerouting to parentselected page
  }

  openSessionPackDialog(parent: any) {
    const dialogRef = this.dialog.open(SessionPackDialog2, {
      disableClose: true,
      panelClass: 'sessionPackDialog'
    });

    dialogRef.afterClosed().subscribe(this.addBalance.bind(this, parent));
  }

  addBalance = (parent: any, result) => {
    if (!result) {
      console.log('not getting result')
      return;
    } else if (result.event == 'Yes') {
      let numberOfSessions = parseFloat(result.numberOfSessions);
      let reason = result.reason;
      let currentTimeStamp = firebase.firestore.Timestamp.now();

      // Generating transaction
      let transaction:any = {};
      transaction.userId = parent.id;
      transaction.status = 'Successfull';
      transaction.reason = `Balance Updation by admin -  ${this.currentUser.fullName}`;
      transaction.subReason = reason;
      transaction.transactionValue = numberOfSessions;
      transaction.transactionType = 'byAdmin';
      transaction.adminId = this.currentUser.id;
      transaction.adminName = this.currentUser.fullName;
      transaction.createdAt = currentTimeStamp;

      let ref = this.firestore.collection('transactions').add(transaction).then(() => {
        this.toastr.success('Balance added successfully!', 'Done');
        let parentRef = this.firestore.collection('users').doc(parent.id).update({ activityStatus: 'Active' }).then(() => {
          // if(this.parentSub){
          //   this.parentSub.unsubscribe()
          // }
          console.log('user also marked as active')
        })
      }).catch(err => console.log(err))
    }
  }

  changeParentActivityStatus(parent: any, status: any) {
    const dialogRef = this.dialog.open(ActivityChangeDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
      data: { status: status }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result || result.event == 'No') {
        return
      } else if (result.event == 'Yes') {
        let ref = this.firestore.collection('users').doc(parent.id).update({ activityStatus: status }).then(() => {
          this.toastr.success(`Parent has been marked as ${status}`, 'Status Updated');
        }).catch(err => console.log(err));
      }
    })

  }

  filterInvitations(balance: string, invitedOn: Date) {
    let balanceValidity = !(balance === "" || balance === undefined || balance === null);
    let invitedOnValidity = !(invitedOn === null || invitedOn === undefined);

    let invitedOnTimeStamp: firebase.firestore.Timestamp;
    let invitedDate: Date;
    let invitedOnPlus24Hrs: Date;
    let invitedOnPlus24HrsTimeStamp: firebase.firestore.Timestamp;

    if (invitedOnValidity) {
      invitedDate = new Date(invitedOn.getTime());
      invitedDate.setHours(0, 0, 0);
      invitedOnPlus24Hrs = new Date(invitedOn.getTime() + 86400000);
      invitedOnTimeStamp = firebase.firestore.Timestamp.fromDate(invitedDate);
      invitedOnPlus24HrsTimeStamp = firebase.firestore.Timestamp.fromDate(invitedOnPlus24Hrs);
    }
    this.invitations = this.invitationsCopy;
    //filtering based on balance and to check for a particular date we check if it is between invitedOn and invitedOnPlus24Hrs
    if (!balanceValidity && invitedOnValidity) {
      this.invitations = this.invitations.filter(invitee => (invitee['createdOn'] > invitedOnTimeStamp && invitee['createdOn'] < invitedOnPlus24HrsTimeStamp));
    } else if (balanceValidity && !invitedOnValidity) {
      this.invitations = this.invitations.filter(invitee => invitee['currentBalance'] === balance);
    } else if (!balanceValidity && !invitedOnValidity) {
      this.invitations = this.invitationsCopy;
    } else {
      this.invitations = this.invitations.filter(invitee => ((invitee['createdOn'] > invitedOnTimeStamp && invitee['createdOn'] < invitedOnPlus24HrsTimeStamp) && (invitee => invitee['currentBalance'] === balance)));
    }
  }

  filterInactiveList(createdOn: Date) {
    // let balanceValidity = !(balance === "" || balance === undefined || balance === null);
    let createdOnValidity = !(createdOn === null || createdOn === undefined);

    let createdOnTimeStamp: firebase.firestore.Timestamp;
    let createdDate: Date;
    let createdOnPlus24Hrs: Date;
    let createdOnPlus24HrsTimeStamp: firebase.firestore.Timestamp;

    if (createdOnValidity) {
      createdDate = new Date(createdOn.getTime());
      createdDate.setHours(0, 0, 0);
      createdOnPlus24Hrs = new Date(createdOn.getTime() + 86400000);
      createdOnTimeStamp = firebase.firestore.Timestamp.fromDate(createdDate);
      createdOnPlus24HrsTimeStamp = firebase.firestore.Timestamp.fromDate(createdOnPlus24Hrs);
    }
    this.inactiveParents = this.inactiveParentsCopy;
    //filtering based on balance and to check for a particular date we check if it is between invitedOn and invitedOnPlus24Hrs
    if (createdOnValidity) {
      this.inactiveParents = this.inactiveParents.filter(parent => (parent['createdOn'] > createdOnTimeStamp && parent['createdOn'] < createdOnPlus24HrsTimeStamp));
    } else if (!createdOnValidity) {
      this.inactiveParents = this.inactiveParentsCopy;
    } else {
      this.inactiveParents = this.inactiveParents.filter(parent => ((parent['createdOn'] > createdOnTimeStamp && parent['createdOn'] < createdOnPlus24HrsTimeStamp)));
    }
  }

  filterParents(balance: string, educatorId: string) {
    let balanceValidity = !(balance === "" || balance === undefined || balance === null);
    let educatorIdValidity = !(educatorId === undefined || educatorId === ""); //checking balance and educator filter fields validity

    this.parents = this.parentsCopy;
    if (!balanceValidity && educatorIdValidity) {
     
      this.parents = this.parents.filter(parent => { //filtering parents based on balance and expertIds assigned to the parent
        if (parent['myExpertsIds']) return parent['myExpertsIds'].includes(educatorId);
        else return false;
      });
    } else if (balanceValidity && !educatorIdValidity) {
      this.parents = this.parents.filter(parent => parent['currentBalance'] === balance);
    } else if (!balanceValidity && !educatorIdValidity) {
      this.parents = this.parentsCopy;
    } else {
      this.parents = this.parents.filter(parent => {
        if (parent['myExpertsIds']) return (parent['myExpertsIds'].includes(educatorId) && parent['currentBalance'] === balance);
        else return false;
      });
    }
  }

  filterRenewalParents(balance: string, educatorId: string) {
    let balanceValidity = !(balance === "" || balance === undefined || balance === null);
    let educatorIdValidity = !(educatorId === undefined || educatorId === ""); //checking balance and educator filter fields validity

    this.renewalParents = this.renewalParentsCopy;
    if (!balanceValidity && educatorIdValidity) {
      console.log(this.renewalParents);
      this.renewalParents = this.renewalParents.filter(parent => { //filtering parents based on balance and expertIds assigned to the parent
        if (parent['myExpertsIds']) return parent['myExpertsIds'].includes(educatorId);
        else return false;
      });
    } else if (balanceValidity && !educatorIdValidity) {
      this.renewalParents = this.renewalParents.filter(parent => parent['currentBalance'] === balance);
    } else if (!balanceValidity && !educatorIdValidity) {
      this.renewalParents = this.renewalParentsCopy;
    } else {
      this.renewalParents = this.renewalParents.filter(parent => {
        if (parent['myExpertsIds']) return (parent['myExpertsIds'].includes(educatorId) && parent['currentBalance'] === balance);
        else return false;
      });
    }
  }

  resetExpertInput(event: string) { //function to reset the expert filter if the expert input is empty
    this.expertClicked = false;
    if (event === '') {
      this.expertSelected = "";
      this.onExpertSelected();
    }
  }

  onExpertFocus() {
    // console.log("Testing Expert focus");
    this.isExpertClicked = true;
  }

  onExpertBlur() {
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isExpertClicked = false;
      if (!this.expertClicked) {
        // console.log("Testing Expert Blur");
        this.expertName = '';
        this.expertSelected = "";
        this.onExpertSelected();
        // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
      }
    }, 200);
  }

  onExpertClick(expert: AngularFirestoreDocument) {
    this.expertClicked = true;
    this.expertName = expert['fullName'];
    this.dropdownExpertHover = false; //when expert is clicked from dropdown
    this.expertSelected = expert['id'];
    this.onExpertSelected();
  }



  ngOnDestroy() {
    if (this.invitationsSub)
      this.invitationsSub.unsubscribe();
    if (this.parentSub)
      this.parentSub.unsubscribe();
    if (this.expertSub)
      this.expertSub.unsubscribe();
    if (this.inactiveParentsSub)
      this.inactiveParentsSub.unsubscribe();
  }

}

@Component({
  selector: 'app-session-pack-dialog2',
  template: `
  <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important;">Update Balance</mat-card-title>
      <mat-form-field style="width: 100%">
        <mat-label>Reason</mat-label>
        <input type="text" matInput #reason autocomplete="off">
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <mat-label>Number of Sessions</mat-label>
        <input type="number" matInput #numberOfSessions autocomplete="off">
      </mat-form-field>
      <button mat-button id="addSessionPackBtn" class="right" (click)="addSessionPack(reason.value, numberOfSessions.value)">Add</button>
    </div>
  `,
  styleUrls: ['./admin-parents-tab.component.css']
})
export class SessionPackDialog2 implements OnInit, OnDestroy {

  constructor(private dialogRef: MatDialogRef<SessionPackDialog2>) { }

  ngOnInit() { }

  addSessionPack(reason: string, numberOfSessions: number) {
    this.dialogRef.close({ event: 'Yes', reason: reason, numberOfSessions: numberOfSessions });
  }

  ngOnDestroy() { }
}

@Component({
  selector: 'app-activity-change-dialog',
  template: `
  <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important; color: #F7524B;">You're about to change the status of this user</mat-card-title>

      <p *ngIf="data.status == 'Active'" style="display: flex; align-items: center">
        <span style="color: #F7524B; font-weight: 500;">Inactive</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">Active</span>
      </p>

      <p *ngIf="data.status == 'Inactive'" style="display: flex; align-items: center">
        <span style="color: #F7524B; font-weight: 500;">Active</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">Inactive</span>
      </p>

      <button mat-button id="addSessionPackBtn" style="background: #FCFCFC; color: #F7524B" class="right" (click)="changeActivity()">Yes</button>
      <button mat-button id="addSessionPackBtn" style="background: #F7524B; color: white; margin-right: 15px" class="right" (click)="dontChangeActivity()">No</button>
    </div>
  `,
  styleUrls: ['./admin-parents-tab.component.css']
})

export class ActivityChangeDialog implements OnInit, OnDestroy {

  constructor(
    private dialogRef: MatDialogRef<ActivityChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log(this.data);
  }

  changeActivity() {
    this.dialogRef.close({ event: 'Yes' });
  }
  dontChangeActivity() {
    this.dialogRef.close({ event: 'No' });
  }

  ngOnDestroy() { }
}
