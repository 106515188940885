export const environment = {
	production: true,
	firebase : {
		apiKey: "AIzaSyAlsAhyobc3tWK5bjH4HbXs8ylOLlWxcNY",
		authDomain: "tactopus-connect-staging.firebaseapp.com",
		projectId: "tactopus-connect-staging",
		storageBucket: "tactopus-connect-staging.appspot.com",
		messagingSenderId: "853995550916",
		appId: "1:853995550916:web:d22e16006a28f249187191",
		measurementId: "G-64V3M54FQ6"
	  },  
	cloudFunctions : {
		createOrder: 'https://us-central1-tactopus-connect-staging.cloudfunctions.net/createOrder',
		capturePayment: 'https://us-central1-tactopus-connect-staging.cloudfunctions.net/capturePayments'
	},

	RAZORPAY_KEY_ID: 'rzp_test_XGIQx4grO6ksSF'
  };
