import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { HelperService } from '../helper.service';
import { InteraktUserService } from '../services/interakt-user.service';
import { MultiExpertSlotsService } from '../services/multi-expert-slots.service';
import { NotificationService } from '../services/notification.service';
import { SessionService } from '../services/session.service';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { ConsultationBlockedSlotService } from '../services/consultation-blocked-slot.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'Do MMMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-sales-admin-new-session-request',
  templateUrl: './sales-admin-new-session-request.component.html',
  styleUrls: ['./sales-admin-new-session-request.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class SalesAdminNewSessionRequestComponent implements OnInit {
  dateFormat = new FormControl(moment());
  isLoading = false;
  loadingSetInterval: any;
  minDate = new Date();
  currentAdmin: any;
  newSessionForm: any;
  selectedSlot: any;
  formData: any = {};
  selectedSessionExpert: any;
  selectedSessionDate: Date;
  selectedDate: Date;
  slotDuration: any = 30;
  phoneNumberCountryCode = '+91';
  expertsMatchingServices: any[];
  expertsMatchingLanguages: any[];
  sessionStartTime: any;
  sessionEndTime: any;
  sessionEndDateTime: any;
  expertsMatchingAgeRange: any[];
  expertsMatchingServicesAndLanguages: any[];
  expertMatchingServicesAndAgeRange: any[];
  expertsMatchingAll: any[];
  finalExpertsList: any[];
  slotSub: Subscription;
  selectedSlotTimeFull: string;
  whatsappNumberCountryCode = '+91';
  expertMatchFailed: boolean = false;
  expertMatchFailedMessage = '';
  newConsultationId: any;
  primaryLanguage: any;
  secondaryLanguages: any[];
  selectedLanguages: any[];
  formFirstPart: any;
  tomorrow: Date = new Date();
  currentUser: any;
  isSlotSelected: boolean = false;
  ageOfChild: any;
  dateAndSlots: any[];
  hasTimerStarted: boolean = false;
  timerMinute: any;
  timerSecond: any;
  timerId: any;
  blockedByOtherAdmin: boolean = false;

  constructor(private fb: FormBuilder,
    public multiExpertSlotsService: MultiExpertSlotsService,
    private helper: HelperService,
    private firestore: AngularFirestore,
    private sessionService: SessionService,
    private auth: AngularFireAuth,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
    private notificationService: NotificationService,
    private newInteraktUserService: InteraktUserService,
    public blockSlotService: ConsultationBlockedSlotService,
    private ngZone: NgZone) { }

  async ngOnInit() {
    const today = new Date();
    this.tomorrow.setDate(today.getDate() + 1);

    this.newSessionForm = this.fb.group({
      session: this.fb.array([
        this.formFirstPart = this.fb.group({
          childCondition: ['', Validators.required],
          dateOfBirth: ['', Validators.required],
          primaryLanguage: ['', Validators.required],
          secondaryLanguages: ['', Validators.required],
          serviceType: ['', Validators.required],
          preferredDate: ['', Validators.required],
          preferredTimes: ['', Validators.required],
        }),
        this.fb.group({
          meetingLink: ['', Validators.required],
          sessionParentName: ['', Validators.required],
          parentPhoneNumber: ['', Validators.required],
          parentWhatsappNumber: ['', Validators.required],
          parentEmail: ['', Validators.email],
          sessionStudentName: ['', Validators.required],
          // childAge: [''],
          childGender: ['', Validators.required],
          childConcern: ['', Validators.required],
          diagnosis: ['']
        }),
      ])
    });

    this.auth.currentUser.then(
      (user) => {
        this.firestore.doc(`users/${user.uid}`).valueChanges().pipe(take(1)).subscribe(
          (userDoc: AngularFirestoreDocument) => {
            this.currentUser = userDoc;
            console.log('current admin ', this.currentUser)
          }
        );
      }
    );

  }

  // function to start timer at the third step
  startTimer() {
    if (this.hasTimerStarted == false) {
      this.hasTimerStarted = true;
      let timerDuration = 180

      // interval to show timer to the user
      setInterval(() => {
        let minute = Math.floor(timerDuration / 60);
        let second = timerDuration % 60;
        this.timerMinute = minute;
        if (second >= 10) {
          this.timerSecond = second;
        } else {
          this.timerSecond = '0' + second;
        }
        timerDuration--
      }, 1000)

      // timeout function to unblock all the blocked slots when it gets over
      this.timerId = setTimeout(() => {
        let availableSlots = [];
        availableSlots = this.blockSlotService.availableSlots;

        availableSlots.forEach((doc) => {
          let delRef = this.firestore.collection('blockedslots').doc(doc.blockSlotId).delete().then(() => {
            console.log('slot deleted successfully')
          })
        })

        const dialogRef = this.dialog.open(consultationTimerRunoutDialog, {
          disableClose: true,
          panelClass: 'timer-runout-dialog',
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (!result) {
            return;
          } else if (result.event == 'Yes') {
            console.log('yayyyy')
          }
        });

      }, 180 * 1000);

    }

  }

  onDateChange(event) {
    this.isLoading = true;
    this.isSlotSelected = false;
    this.dateFormat.setValue(event.value._d); //dateFormat is changed from moment to date

    //initializing date of consultation
    let date = new Date(event.value._d);
    this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
    this.selectedDate = new Date(date);
    this.newSessionForm.value.session[0].preferredDate = date;
    this.finalizeTheExpertList(this.finalExpertsList);
  }

  incrementDate() {
    this.isLoading = true;
    this.isSlotSelected = false;
    let date = this.dateFormat.value;
    date.setDate(date.getDate() + 1);
    this.dateFormat.setValue(date);

    //initializing date of consultation
    this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
    this.selectedDate = new Date(date);
    this.newSessionForm.value.session[0].preferredDate = date;
    this.finalizeTheExpertList(this.finalExpertsList);
  }

  decrementDate() {
    let dateDemo = new Date(this.dateFormat.value._d);
    if ((this.minDate.getDate() == dateDemo.getDate()) && (this.minDate.getMonth() == dateDemo.getMonth())) {
      return;
    } else {
      this.isLoading = true;
      let date = this.dateFormat.value;
      date.setDate(date.getDate() - 1);
      this.dateFormat.setValue(date);

      //initializing date of consultation
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.newSessionForm.value.session[0].preferredDate = date;
      this.finalizeTheExpertList(this.finalExpertsList);
      this.isSlotSelected = false;
    }
  }

  calculateAge(dateOfBirth) {
    let today: any = new Date();
    let birthDate: any = new Date(dateOfBirth);
    const diffTime = Math.abs(today - birthDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    diffDays = diffDays / 365
    // let age = today.getFullYear() - birthDate.getFullYear();
    // let m = today.getMonth() - birthDate.getMonth();
    // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //   age--;
    // }
    let age = diffDays;
    age = Math.round(age * 10) / 10
    return age;
  }

  backToSessionPage() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    this.ngZone.run(() => this.router.navigateByUrl("sales-admin/dashboard"))
  }

  getConditions() {
    return this.helper.expertConditions;
  }

  getConsultationPreferredTimes() {
    return this.helper.consultationPreferredTimes;
  }

  getGendersTypes() {
    return this.helper.genders;
  }

  getLanguages() {
    return this.helper.languages;
  }

  getSecondaryLanguages() {
    let languages = [];
    this.helper.languages.forEach((language) => {
      if (language != this.newSessionForm.value.session[0].primaryLanguage) {
        languages.push(language);
      }
    });
    languages.push("-");
    return languages;
  }

  getServices() {
    return this.helper.services;
  }

  onPhoneNumberCountryChange(event) {
    this.phoneNumberCountryCode = '+' + event.dialCode;
  }

  onWhatsappNumberCountryChange(event) {
    this.whatsappNumberCountryCode = '';
    this.whatsappNumberCountryCode = '+' + event.dialCode;
  }

  onDateOfBirthSelected(event) {
    // console.log("DateOfBirth Selected: ", event.value._d);
    // //changed dateOfBirth from moment to date format
    // let date = this.newSessionForm.value.session[0].dateOfBirth;
    // this.newSessionForm.value.session[0].dateOfBirth = new Date(date);

    this.newSessionForm.value.session[0].dateOfBirth = this.changeStringToDate(event);
    this.ageOfChild = this.calculateAge(this.newSessionForm.value.session[0].dateOfBirth);
    console.log('age of child is ', this.ageOfChild);
    this.newSessionForm.value.session[1].childAge.setValue(this.ageOfChild);
  }

  changeStringToDate(dateString: any) {
    console.log(dateString)
    var dateParts = dateString.split("/");

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    console.log('date ', dateObject)
    return dateObject;
  }

  onPreferredDateSelected(event) {
    console.log("preferred Date Selected: ", event.value._d);
    //changed preferredDate from moment to date format
    let date = this.newSessionForm.value.session[0].preferredDate;
    this.newSessionForm.value.session[0].preferredDate = new Date(date);
    this.isSlotSelected = false;
  }

  // starting function for finding the expert
  findExperts() {
    this.isLoading = true;
    let formData: any = {};
    let session: any = this.newSessionForm.value.session;

    session.forEach(data => {
      formData = { ...formData, ...data }
    })
    this.formData = formData;

    //initializing date of consultation
    let date = new Date(this.formData.preferredDate);
    this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
    this.selectedDate = new Date(date);

    // getting the experts who matches the selected service.
    let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'expert').where('verified', '==', true).where('serviceTypes', 'array-contains', formData.serviceType));
    ref.get().subscribe(this.onExpertMatchingServices)
  }

  // after getting the service matching experts
  onExpertMatchingServices = (results) => {
    if (results.empty) {
      this.expertMatchFailed = true;
    } else {
      this.expertsMatchingServices = [];
      results.forEach(doc => {
        let expert: any = doc.data();
        if (expert.activityStatus != "Session & Consultation Disabled" && expert.activityStatus != "Consultation Disabled") {
          this.expertsMatchingServices.push(expert);
        }
      })

      // list of all selected languages
      this.selectedLanguages = [];
      this.selectedLanguages.push(this.formData.primaryLanguage);
      this.formData.secondaryLanguages.forEach(language => {
        this.selectedLanguages.push(language);
      });

      // getting the experts who matches the selected languages.
      let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'expert').where('verified', '==', true).where('languages', 'array-contains-any', this.selectedLanguages));
      ref.get().subscribe(this.onExpertMatchingLanguages)
    }

  }

  // after getting the language matching experts
  onExpertMatchingLanguages = (results) => {
    if (results.empty) {
      this.expertMatchFailed = true;
    } else {

      this.expertsMatchingLanguages = [];
      results.forEach(doc => {
        let expert: any = doc.data();
        this.expertsMatchingLanguages.push(expert);
      })

      this.checkExpertsMatchingServiceAndLanguages();
    }
  }

  // to get the common experts matching selected languages and service.
  checkExpertsMatchingServiceAndLanguages() {
    let hashTable: any = {};
    this.expertsMatchingServicesAndLanguages = [];

    // mark the experts who are matching the selected services in hash table.
    this.expertsMatchingServices.forEach(serviceExpert => {
      if (!hashTable[serviceExpert.id]) {
        hashTable[serviceExpert.id] = true;
      }
    })

    // check, if the experts who are matching the selected languages are in the hash table.
    this.expertsMatchingServicesAndLanguages = [];
    this.expertsMatchingLanguages.forEach(languageExpert => {
      if (hashTable[languageExpert.id]) {
        this.expertsMatchingServicesAndLanguages.push(languageExpert)
      }
    })

    // if there are experts matching both the selected service and the languages, then move forward.
    if (this.expertsMatchingServicesAndLanguages.length > 0) {
      this.checkLanguageMatchWeightage();
    } else {
      this.expertMatchFailed = true;
    }

  }

  // to get the language weightage percentage of the experts.
  checkLanguageMatchWeightage() {
    let selectedLanguagesHashTable: any = {};

    // add all the selected languages in hash table.
    this.selectedLanguages.forEach(selectedLanguage => {
      if (!selectedLanguagesHashTable[selectedLanguage]) {
        selectedLanguagesHashTable[selectedLanguage] = true;
      }
    })

    let finalExpertsList = [];
    this.expertsMatchingServicesAndLanguages.forEach(expert => {
      let expertLanguages = [];
      expertLanguages = expert.languages;

      let finalExpert: any = expert;
      finalExpert.languageWeightage = 0;
      let matchingLanguages = [];

      expertLanguages.forEach(language => {
        // if the selected language is matched with expert's language
        if (selectedLanguagesHashTable[language]) {
          matchingLanguages.push(language);

          // check if the matched language is primary or secondary one.
          if ((language == this.formData.primaryLanguage) && (finalExpert.languageWeightage < 60)) {
            finalExpert.languageWeightage += 60;
          } else if ((language != this.formData.primaryLanguage) && (finalExpert.languageWeightage != 40) && (finalExpert.languageWeightage != 100)) {
            finalExpert.languageWeightage += 40;
          }
        }
      })

      finalExpert.matchingLanguages = matchingLanguages;
      finalExpertsList.push(finalExpert);
    })

    this.finalizeTheExpertList(finalExpertsList);
  }

  // to finalize the list of expert to show on second step.
  async finalizeTheExpertList(expertList) {
    let expertsList = [];
    expertsList = expertList;
    this.finalExpertsList = [];

    expertsList.forEach(doc => {
      let expert: any = doc;

      // get the preferred time weightage
      let preferredTimeWeightage = this.checkPreferredTimeWeightage(expert);
      expert.preferredTimeWeightage = preferredTimeWeightage;
      expert.isPreferredTimeMatched = (preferredTimeWeightage > 0);

      // get the age group weightage
      let ageWeightage = this.checkAgeWeightage(expert);
      expert.ageWeightage = ageWeightage;
      expert.isAgeRangeMatched = (ageWeightage > 0);

      // get the area of concern weightage
      let concernWeightage = this.checkConcernWeightage(expert);
      expert.concernWeightage = concernWeightage;
      expert.isConcernMatched = (concernWeightage > 0);

      // calculating the average weightage percentage of the expert.
      let averageWeightage = (expert.languageWeightage + (preferredTimeWeightage + ageWeightage + concernWeightage)) / 2;
      expert.averageWeightage = averageWeightage;

      // add this expert with all the weightage details in the final list.
      this.finalExpertsList.push(expert)
    })
    // console.log("Checking finalExpertList: ", this.finalExpertsList);
    // get the slots of the final list experts.
    let date = new Date(this.newSessionForm.value.session[0].preferredDate);
    this.dateFormat.setValue(date);
    this.multiExpertSlotsService.getSlots(this.finalExpertsList, this.formData.preferredTimes, date, this.slotDuration);
    this.onLoadingMessage();
  }

  // to get the preferred time weightage percentage of the expert
  checkPreferredTimeWeightage(expert) {
    let preferredTimeWeightage = 0
    let date = new Date(this.formData.preferredDate);
    let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    let day = days[date.getDay()];

    let workingHoursOfThisDay = [];
    workingHoursOfThisDay = this.sessionService.getWorkingHours(expert, day);

    let selectedTimes = [];
    selectedTimes = this.formData.preferredTimes;

    selectedTimes.forEach(time => {
      let slotStartTime = time.split(":");
      // to get session starting time in minutes
      const startingHour = parseInt(slotStartTime[0], 10);
      slotStartTime = (startingHour * 60);

      let isInsideWorkingHours: boolean = false;
      isInsideWorkingHours = this.sessionService.isInsideWorkingHours(workingHoursOfThisDay, slotStartTime, 120);

      if (isInsideWorkingHours == true) {
        preferredTimeWeightage = 40;
        return preferredTimeWeightage;
      }
    })

    return preferredTimeWeightage;
  }

  // to get the childrange weightage percentage of the expert
  checkAgeWeightage(expert) {
    let age = this.calculateAge(this.formData.dateOfBirth);
    let startingAge = expert?.startingAgeRange;
    let endingAge = expert?.endingAgeRange;
    if (age >= startingAge && age <= endingAge) {
      return 30;
    } else {
      return 0;
    }
  }

  // to get the area of concern weightage percentage of the expert
  checkConcernWeightage(expert: any) {
    let concernWeightage = 0;
    let concerns = [];
    concerns = this.formData.childCondition;
    let concernsHashTable: any = {}

    // add all the selected concerns in hash table.
    concerns.forEach(concern => {
      if (!concernsHashTable[concern]) {
        concernsHashTable[concern] = true;
      }
    })

    let expertConcerns: any[];
    expertConcerns = [];
    expertConcerns = expert?.condition;

    if (expertConcerns) {
      expertConcerns.forEach(expertConcern => {
        // if the selected concern is matched with expert's concerns
        if (concernsHashTable[expertConcern]) {
          concernWeightage = 30;
        }
      })
    }

    return concernWeightage;
  }

  //creating loading message functionality
  onLoadingMessage() {
    this.loadingSetInterval = setInterval(() => {
      let len = Object.keys(this.multiExpertSlotsService.expertListWithSlots).length;
      if (len === this.finalExpertsList.length) {
        this.finalExpertsList.sort(this.compareTime);
        clearInterval(this.loadingSetInterval);
        this.isLoading = false;
      }
    }, 1000)
  }

  // function to sort the finalExpertsList array
  compareTime(a, b) {
    if (a.averageWeightage > b.averageWeightage) {
      return -1;
    } else if (a.averageWeightage < b.averageWeightage) {
      return 1;
    } else {
      return 0;
    }
  }

  onSlotSelected(slotValue: any, expert) {
    console.log("Checking selected slot: ", slotValue);
    this.selectedSessionExpert = expert;
    this.selectedSlot = slotValue;
    this.isSlotSelected = true;
    this.blockedByOtherAdmin = false;
    this.selectedSlotTimeFull = slotValue.label;
    this.setSessionTimings();

    let slot = slotValue.split(":");
    let slotStartTime = (parseInt(slot[0], 10) * 60) + (parseInt(slot[1], 10));
    let selectedSlots = [{ slotStartTime: slotStartTime, slotDuration: 30 }];
    this.dateAndSlots = [];
    this.dateAndSlots.length = 0;
    this.dateAndSlots.push({ date: this.selectedDate, slotsToBeChecked: selectedSlots });

  }

  evaluateSelectedSlot() {
    this.startTimer();
    this.blockSlotService.availableSlotForMultiDates(this.selectedSessionExpert.id, this.dateAndSlots, this.currentUser.id)
  }

  setSessionTimings() {
    let sessionStartTime = this.selectedSlot.split(":");

    // to get session starting time in minutes
    const startingHour = parseInt(sessionStartTime[0], 10);
    const startingMinute = parseInt(sessionStartTime[1], 10);
    sessionStartTime = (startingHour * 60) + startingMinute;
    let slotDuration = this.slotDuration;

    // this is session end time in minutes
    let sessionEndTime = sessionStartTime + slotDuration;

    let endHour = Math.floor(sessionEndTime / 60);
    let endMinute = sessionEndTime % 60;

    // creating a session end timestamp for filter comparison
    let istSessionEndDateTime = this.helper.istTime3(this.selectedDate, endHour, endMinute);
    this.sessionEndDateTime = istSessionEndDateTime;

    if (endMinute == 0) {
      sessionEndTime = endHour + ":" + endMinute + '0';
    } else {
      sessionEndTime = endHour + ":" + endMinute;
    }

    this.sessionStartTime = this.selectedSlot;
    this.sessionEndTime = sessionEndTime;
  }

  openConsultationConfirmationDialog() {
    let blockRef = this.firestore.collection('blockedslots').doc(this.blockSlotService.blockedSlotId);
    blockRef.get().subscribe((result) => {
      if (!result.exists) {
        this.blockedByOtherAdmin = true;
        return;
      }

      let blockSlot: any = result.data();
      if (blockSlot.blockedBy != this.currentUser.id) {
        this.blockedByOtherAdmin = true;
        return;
      }


      let session = {};
      let sessionData = this.newSessionForm.value.session;
      sessionData.forEach(data => {
        session = { ...session, ...data }
      });

      const dialogRef = this.dialog.open(consultationConfirmationDialog, {
        disableClose: true,
        panelClass: 'consultation-confirmation-dialog',
        data: { session: session, expertName: this.selectedSessionExpert.fullName, sessionDate: this.selectedSessionDate, startTime: this.sessionStartTime, ageOfChild: this.ageOfChild }
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (!result) {
          return;
        } else if (result.event == 'Yes') {
          this.saveSession();
        }
      });
    })
  }

  saveSession() {
    //changed dateOfBirth from moment to date format
    // let date1 = this.newSessionForm.value.session[0].dateOfBirth;
    // this.newSessionForm.value.session[0].dateOfBirth = new Date(date1);
    let date2 = this.newSessionForm.value.session[0].preferredDate;
    this.newSessionForm.value.session[0].preferredDate = new Date(date2);

    let session: any = {};
    let sessionData = this.newSessionForm.value.session;
    sessionData.forEach(data => {
      session = { ...session, ...data }
    })

    // first checking if parent has an existing interakt account or not.
    this.checkForInteraktAccount(session);
  }

  checkForInteraktAccount(session) {
    let interaktAccountQueryNumber = this.whatsappNumberCountryCode + session['parentWhatsappNumber'];

    let interaktAccountRef = this.firestore.collection('interaktaccounts', ref => ref.where('interaktAccountQueryNumber', '==', interaktAccountQueryNumber));
    interaktAccountRef.get().toPromise().then((documents) => {
      // if the parent is new and has not been registered to interakt yet.
      if (documents.empty) {
        let interaktId = this.helper.uuidv4();
        let interaktBody = {
          interaktId: interaktId,
          whatsappNumber: session['parentWhatsappNumber'],
          whatsappNumberCountryCode: this.whatsappNumberCountryCode,
          parentName: session['sessionParentName'],
          parentEmail: session['parentEmail'],
        }

        let newInteraktAccountRef = this.firestore.collection('interaktaccounts')
          .add({
            interaktAccountQueryNumber: interaktAccountQueryNumber,
            interaktId: interaktId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: firebase.auth().currentUser.uid,
            createdWhile: 'Booking New Consultation',
            whatsappNumber: session['parentWhatsappNumber'],
            whatsappNumberCountryCode: this.whatsappNumberCountryCode,
            parentName: session['sessionParentName'],
            parentEmail: session['parentEmail'],
          }).then(() => {
            // Creating new interakt user
            this.newInteraktUserService.createNewParent(interaktBody).then(() => {
              // FUNCTION FOR SAVING THE CONSULTATION
              this.saveConsultation(session, interaktId)

            }).catch(err => console.log('error creating new interakt user ', err));
          }).catch(err => console.log('error adding interakt account document ', err))
      } else {
        let docs = [];
        documents.forEach((document) => {
          docs.push(document.data());
        });
        let interaktAccount: any = docs[0];

        // FUNCTION FOR SAVING THE CONSULTATION
        this.saveConsultation(session, interaktAccount.interaktId);
      }
    })
  }

  saveConsultation(session: any, interaktId) {
    let docRef;
    let sessionValue = 0.5;
    let sessionDuration = 30;

    // setting the details for new consultation
    this.newConsultationId = this.helper.uuidv4();

    let newDate=new Date(this.selectedSessionDate);
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(newDate);
     docRef = this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(this.newConsultationId);

    // docRef = this.firestore.collection("consultations").doc(this.newConsultationId);
    const createdOn = firebase.firestore.FieldValue.serverTimestamp();

    session['id'] = this.newConsultationId;
    session['sessionValue'] = sessionValue;
    session['sessionType'] = 'Free Consultation';

    let date = new Date(this.selectedSessionDate);
    let timestamp = firebase.firestore.Timestamp.fromDate(date);

    session['createdOn'] = createdOn;
    session['sessionDate'] = timestamp;
    session['sessionEndDateTime'] = this.sessionEndDateTime;
    session['startTime'] = this.sessionStartTime;
    session['endTime'] = this.sessionEndTime;
    session['sessionDuration'] = sessionDuration;

    session['createdBy'] = this.currentUser.id;
    session['createdByName'] = this.currentUser.fullName;
    session['bookedBy'] = firebase.firestore.FieldValue.arrayUnion(this.currentUser.id);

    session['attendedBy'] = { parent: false, expert: false, admin: false }

    session['sessionExpertId'] = this.selectedSessionExpert.id;
    session['sessionExpertName'] = this.selectedSessionExpert.fullName;
    session['expertInteraktId'] = this.selectedSessionExpert.interaktId;
    session['parentInteraktId'] = interaktId;
    session['childAge'] = this.ageOfChild;

    session['status'] = 'Scheduled';

    docRef.set(session).then(async () => {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      await this.sendParentConsultationBookedNotification(session, interaktId);
      await this.sendExpertConsultationBookedNotification(session);

      this.onSessionUpdated();
    }).catch(error => console.error("Error booking consultation: ", error));
  }

  async sendParentConsultationBookedNotification(session, interaktId) {
    let date = new Date(this.selectedDate);
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    
    let parentNotificationBody = {
      'userId':interaktId,
      'event': 'KP - new consultation booked notification - for parent',
      'traits': {
        'date': dateString,
        'time': this.sessionStartTime + ' IST',
        'service': session['serviceType'],
        'meeting_link': session['meetingLink']
      }
    }
    let response = await this.notificationService.sendWhatsappNotification(parentNotificationBody);
    return response;
  }

  async sendExpertConsultationBookedNotification(session) {
    let languages = [];
    languages = session['secondaryLanguages'];
    languages.push(session['primaryLanguage'])
    let languageString = '';
    for (let i = 0; i < languages.length; i++) {
      let language = languages[i];
      languageString += language;
      if (i < (languages.length - 1)) {
        languageString += ','
      }
    }

    let date = new Date(this.selectedDate);
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    let expertNotificationBody = {
      'userId': this.selectedSessionExpert.interaktId,
      'event': 'new consultation booked reminder - Notify expert',
      'traits': {
        'sessionType': session['serviceType'],
        'date': dateString,
        'time': this.sessionStartTime + ' IST',
        'parentName': session['sessionParentName'],
        'childName': session['sessionStudentName'],
        'gender': session['childGender'],
        'age': this.ageOfChild,
        'languages': languageString,
        'concern': session['childConcern'],
      }
    }

    let response = await this.notificationService.sendWhatsappNotification(expertNotificationBody);
    return response;
  }

  onSessionUpdated = () => {
    var message = "New Consultation booked"
    this.toastr.success(message, 'Consultation!');
    this.router.navigate(['/sales-admin/dashboard'])
  }

  dateFilter(d: Date) {
    let currentTime = new Date();
    let currentOffset = currentTime.getTimezoneOffset();
    let ISTOffset = 330;   // IST offset UTC +5:30 
    let now = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    if (
      d.getDate() >= now.getDate() &&
      d.getMonth() == now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (
      d.getMonth() > now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (d.getFullYear() > now.getFullYear()) return true;
    else if (d.getFullYear() < now.getFullYear()) return false;
  }
}


@Component({
  selector: 'consultation-confirmation-dialog',
  template: `
  <div class="consultationConfirmationDialogContainer">
    <div style="display: flex; justify-content: flex-end;">
      <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>
    <h2 style="line-height: 21px;">Do you want to send the following consultation invite ?</h2>
    <h3 style="font-weight: 500; margin: 0px; margin-bottom: -2px; margin-top: 18px;">Send to parent:</h3>
    <p style="margin: 0px; margin-bottom: -2px;">{{data.session.sessionParentName}}</p>
    <p style="margin: 0px; margin-bottom: -2px;">{{data.session.parentPhoneNumber}}</p>
    <h3 style="font-weight: 500; margin: 0px; margin-bottom: -2px; margin-top: 18px;">Child's Details:</h3>
    <p style="margin: 0px; margin-bottom: -2px;">{{data.session.sessionStudentName}}</p>
    <p style="margin: 0px; margin-bottom: -2px;">{{data.ageOfChild}} Years Old</p>
    <p style="margin: 0px; margin-bottom: -2px;">{{data.session.childGender}}</p>
    <p style="margin: 0px; margin-bottom: -2px;">{{data.session.childConcern}}</p>
    <h3 style="font-weight: 500; margin: 0px; margin-bottom: -2px; margin-top: 18px;">Consultation:</h3>
    <p style="margin: 0px; margin-bottom: -2px;">{{data.session.serviceType}}</p>
    <p style="margin: 0px; margin-bottom: -2px;">With: {{data.expertName}}</p>
    <p style="margin: 0px; margin-bottom: -2px;">On: {{data.sessionDate | date:'longDate'}}</p>
    <p style="margin: 0px; margin-bottom: -2px;">At: {{data.startTime}}</p>
  <div style="display: flex; justify-content: space-between; margin-top: 20px; margin-bottom: 6px;">
    <button style="background-color: #F9F9F9; color: #F7524B; height: 35px; border-radius: 10px;" mat-dialog-close mat-button>Back</button>
    <button style="background-color: #F9F9F9; color: #F7524B; height: 35px; border-radius: 10px;" (click)="bookConsultation()" mat-button>Send</button>
  </div>
    
  </div>
`,
  styleUrls: ['./sales-admin-new-session-request.component.css']
})

export class consultationConfirmationDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<consultationConfirmationDialog>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }

  bookConsultation() {
    this.dialogRef.close({ event: "Yes" });
  }

  ngOnDestroy() { };
}


@Component({
  selector: 'consultation-timer-runout-dialog',
  template: `
  <div style="float: right; z-index:1; cursor: pointer; margin-right: 5px; margin-top: 5px"><mat-icon (click)="closeDialog()">close</mat-icon></div>
  <div class="timerRunOutDialogContainer">
    <mat-icon style="color: #F7524B; margin-bottom: -44px; font-size: 45px; margin-right: 39px">timelapse</mat-icon>
    <mat-icon style="color: #F7524B; margin-left: -4px; font-size: 45px;">warning_amber</mat-icon>
    <h2 style="text-align: center; font-weight: 400;">Uh Oh.</h2>
    <p style="text-align: center; color: #686B73;">Your slot selection has expired. <br> Please book again.</p>
    <button style="background-color: #F7524B; color: white; width: 115px; height: 35px; border-radius: 10px;" (click)="bookAgain()" mat-button>Book Again</button>
  </div>
`,
  styleUrls: ['./sales-admin-new-session-request.component.css']
})

export class consultationTimerRunoutDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<consultationTimerRunoutDialog>,
    private router: Router,
    private ngZone: NgZone) { }

  ngOnInit() { }

  bookAgain() {
    this.ngZone.run(() => this.router.navigateByUrl("sales-admin/dashboard")).then(() => {
      this.dialogRef.close();
    });
  }

  closeDialog() {
    this.ngZone.run(() => this.router.navigateByUrl("sales-admin/dashboard")).then(() => {
      this.dialogRef.close();
    });
  }

  ngOnDestroy() { };
}
