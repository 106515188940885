import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-invitation-dialog',
  templateUrl: './confirm-invitation-dialog.component.html',
  styleUrls: ['./confirm-invitation-dialog.component.css']
})
export class ConfirmInvitationDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ConfirmInvitationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {
  }

  send() {
    this.dialogRef.close({ event: 'Yes'});
  }
  
  doNotSend() {
    this.dialogRef.close({ event: 'No'});
  }

}
