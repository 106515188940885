import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {

  students:any[];
	expert:string;

	@Input()
	set id(value){
		this.expert = value;
	}

  constructor(    
  	public auth: AngularFireAuth,
  	public firestore: AngularFirestore,
    private snackBar:MatSnackBar    
  ) { 

  }

  ngOnInit(): void {
  	this.getStudents();
  }

  async getStudents(){
  	var currentUser = await this.auth.currentUser;  	
  	if(currentUser){
	  	var expert = currentUser.uid;    
		    var ref = this.firestore.collection("users", ref => ref.where('role', '==', 'parent').where('myExpertsIds', 'array-contains', expert).orderBy('createdOn','desc'));
	    ref.get().subscribe(this.onStudentResult);     
	  }
  }

  onStudentResult = (results) => {
    if(!results.empty){
      this.students = [];
      results.forEach(result=>{
        this.students.push(result.data())
      })      
    }else{
    	this.students = [];
    }
  }

  viewGoals(studentId){

  }


}
