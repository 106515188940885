import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject, Observable, from, of, pipe } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild('sidenav') public sidenav: MatSidenav;

  loggedIn: boolean = false
  title = 'Tactopus Connect';
  user: any;
  role: string;

  constructor(private router: Router,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
  ) {
  }

  ngOnInit() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
        this.getUser();
        //this.router.navigate('home');
      } else {
        this.loggedIn = false;
        // this.router.navigateByUrl("/signin");
      }
    });
    this.isLoggedIn().pipe(
      tap(user => {
        if (user) {
          // this.checkIfRegisteredUser(user).then(result=>{
          //   //console.log(result);        
          //     if(result)
          //       this.ngZone.run(() => this.router.navigateByUrl(result));
          // }).catch(error=>{
          //   var message = "Error while contacting server. Please try after sometime."
          //   // this.snackBar.open(message, "", {
          //   //   duration: 2000,
          //   // });
          //   console.log(error);
          // });
        }
      }),
      // catchError(err => this.errorHandler)
    ).subscribe();
  }

  isLoggedIn() {
    return this.auth.authState.pipe(first());
  }

  async getUser() {
    var user = await this.auth.currentUser;
    if (user) {
      var userRef = this.firestore.collection('users').doc(user.uid);
      return userRef.get().toPromise().then(this.onUser).catch(function (error) {
        console.log("Error getting user:", error);
      });
    }
  }

  public onUser = (result): any => {
    if (result.exists) {
      //User is already registered and verified
      var user = result.data();
      this.user = user;
      this.role = this.user.role;
      return user;
    }
  }

  async goToRoleHome() {
    if (this.user['verified'])
      this.router.navigateByUrl("/" + this.user["role"] + "/dashboard");
  }

  async logout() {
    await this.auth.signOut();
    this.user = null;
    this.role = '';
    this.router.navigateByUrl("/");
  }

  onClickFAQ() {
    this.sidenav.open();
  }

}
