import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../helper.service'
import { ParentsService } from '../services/parents.service';
import { SessionService } from '../services/session.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../services/notification.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SessionDialog implements OnInit {
  id: string;
  isSaveEnabled: boolean = false;
  selectedSessionDate: any;
  sessionForm: FormGroup;
  isLessThanSlotRange: boolean;
  selectedSlot: any;
  sessionEndDateTime: any;
  sessionStartTime: any;
  sessionEndTime: any;
  experts: any[];
  freeSlots = [];
  showSlots: boolean = false;
  selectedExpertName: any;
  checkForRescheduling: boolean = false;
  getSlotsEnabled: boolean;
  twelveHoursBeforeSessionStartTime: any;
  slotDurations = [
    { label: 'Regular Intervention Session (45 Minutes)', value: 60 },
    { label: 'Consultation Session (30 Minutes)', value: 30 }
  ];
  minDate = new Date();
  maxDate:any;

  constructor(
    private parentsService: ParentsService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<SessionDialog>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.id = data['id'];
    if (this.id) {
      this.checkForRescheduling = true;
    }
     console.log(data,"data");

    // this.getSession();
    // this.getExperts();
    // if(data['conditions'] && data['serviceTypes'])
    //   this.getExperts(data['conditions'],data['serviceTypes']);
  }

  ngOnInit(): void {
    this.sessionForm = this.fb.group({
      sessionDate: '',
    });
   
    this.maxDate=this.parentsService.currentUserDetails.gracePeriod.toDate();

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValid() {
    return true;
  }

  getServices() {
    return this.helper.services;
  }

  getAgeGroups() {
    return this.helper.ageGroups;
  }

  getConditions() {
    return this.helper.conditions;
  }


  // getSession(){
  //   var docRef = this.firestore.collection("sessions").doc(this.id);
  //   docRef.get().subscribe(this.onSessionResult);
  // }

  // onSessionResult = (result) => {
  //   if(result.exists){
  //     var session = result.data();
  //     this.sessionForm.setValue({sessionDate: this.helper.istTime(session.sessionDate.toDate()), startTime:session.startTime, endTime:session.endTime, repeat:session.repeat});
  //   }
  // }

  // getExperts(){
  //   var docRef = this.firestore.collection('users', ref => ref.where('role', '==', 'expert'));
  //   docRef.get().subscribe(this.onExpertsResult);
  // }

  // onExpertsResult = (results) => {
  //   if(results){
  //     this.experts = [];
  //     results.forEach(result=>{
  //       this.experts.push(result.data())
  //     })
  //   }
  // }

  // getExpert(id){
  //   var result;
  //   this.experts.forEach(expert=>{
  //     if(expert.id == id)
  //       result = expert;
  //   })
  //   return result;
  // }



  onDateSelection() {
    this.isSaveEnabled = false;
    this.showSlots = false;
  }

  enableSlotButton(event) {
    let selectedDate = new Date(event.value)
    console.log('selected date ', selectedDate)

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);
    console.log('now ', now)

    if (selectedDate.getDate() >= now.getDate()) {
      console.log('yes')
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;
      return;
    } else if (selectedDate.getMonth() > now.getMonth()) {
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;
      return;
    } else if (selectedDate.getFullYear() > now.getFullYear()) {
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;
      return;
    } else {
      this.getSlotsEnabled = false;
      this.isLessThanSlotRange = true;
    }
  }

  getFreeSlots() {
    this.showSlots = true;
    this.getSlotsEnabled = false;
    var session = {};
    var sessionData = this.sessionForm.value;
    session = sessionData;

    this.selectedSessionDate = session['sessionDate']

    let id;
    if (!this.checkForRescheduling) {

    } else {
      id = this.data['rescheduledSession'].sessionExpertId;
    }
    let date = session['sessionDate'];
    let slotDuration = this.data['rescheduledSession'].slotDuration;
    slotDuration = parseInt(slotDuration, 10);

    this.sessionService.checkForSlots(id, date, slotDuration);
  }

  onSlotSelected(slotValue) {
    this.isSaveEnabled = true;
    this.selectedSlot = slotValue;
    this.setSessionTimings();
  }

  setSessionTimings() {
    var session = {};
    var sessionData = this.sessionForm.value;
    session = sessionData;
    let sessiondate = new Date(session['sessionDate'])

    let sessionStartTime = this.selectedSlot.split(":");

    // to get session starting time in minutes
    const startingHour = parseInt(sessionStartTime[0], 10);
    const startingMinute = parseInt(sessionStartTime[1], 10);
    sessionStartTime = (startingHour * 60) + startingMinute;

    let slotDuration = this.data['rescheduledSession'].slotDuration;
    slotDuration = parseInt(slotDuration, 10);

    // this is session end time in minutes
    let sessionEndTime = sessionStartTime + slotDuration;

    let endHour = Math.floor(sessionEndTime / 60);
    let endMinute = sessionEndTime % 60;

    // creating a session end timestamp for filter comparison
    let istSessionEndDateTime = this.helper.istTime3(sessiondate, endHour, endMinute);
    this.sessionEndDateTime = istSessionEndDateTime;

    // creating date 12 hours earlier for session expiration
    let istSessionStartTime = this.helper.istTime3(sessiondate, startingHour, startingMinute);
    istSessionStartTime.setHours(istSessionStartTime.getHours() - 12);
    this.twelveHoursBeforeSessionStartTime = istSessionStartTime;

    if (endMinute == 0) {
      sessionEndTime = endHour + ":" + endMinute + '0';
    } else {
      sessionEndTime = endHour + ":" + endMinute;
    }

    this.sessionStartTime = this.selectedSlot;
    this.sessionEndTime = sessionEndTime;
  }



  // saving the new session.
  saveSession() {
    this.isSaveEnabled = false;
    let isNewSession = false;
    let docRef;
    let session = {};
    let sessionData = this.sessionForm.value;
    session = sessionData;
    console.log(this.data.reschedulingPerson);

    if (!this.checkForRescheduling) {
      console.log('we have new flow for new session request')
    } else {
      // rescheduling with a new session
      isNewSession = true;
      let id = this.helper.uuidv4();
      let newDate1 = new Date(this.selectedSessionDate)
      console.log(session['sessionDate'].toDate(),"session['sessionDate']",newDate1)
      let istSessionDate:Date = this.helper.istTime3(session['sessionDate'],0,0)
      console.log(istSessionDate,"istSessionDate")
      let dateStringForId = newDate1.getDate() + '-' + (newDate1.getMonth() + 1) + '-' + newDate1.getFullYear();
      let slotId = dateStringForId + this.sessionStartTime + '-' + this.sessionEndTime + this.data['rescheduledSession'].sessionExpertId; 
      let topicID = this.helper.uuidv4();
      // docRef = this.firestore.collection("sessions").doc(id);
      const sessiondate=new Date(session['sessionDate']);
      const timeinMiliSec = this.helper.getMondayInMiliSeconds(sessiondate);
      docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(id);

      const createdOn = firebase.firestore.FieldValue.serverTimestamp();
      const createdBy = this.data['reschedulingDetails'].rescheduledBy;
      const createdByName = this.data['reschedulingDetails'].rescheduledByName;


      session['id'] = id;
      session['slotId'] = slotId;
      session['topicID'] = topicID;
      session['sessionValue'] = this.data['rescheduledSession'].sessionValue;

      session['createdOn'] = createdOn;
      session['sessionDate'] = istSessionDate;
      session['sessionEndDateTime'] = this.sessionEndDateTime;
      session['startTime'] = this.sessionStartTime;
      session['endTime'] = this.sessionEndTime;
      session['slotDuration'] = this.data['rescheduledSession'].slotDuration;

      session['createdBy'] = createdBy;
      session['createdByName'] = createdByName;
      session['bookedBy'] = firebase.firestore.FieldValue.arrayUnion(createdBy);

      session['createdWith'] = this.data['reschedulingDetails'].rescheduledWith;
      session['createdWithName'] = this.data['reschedulingDetails'].rescheduledWithName;

      session['sessionExpertId'] = this.data['rescheduledSession'].sessionExpertId;
      session['sessionExpertName'] = this.data['rescheduledSession'].sessionExpertName;

      session['sessionStudentId'] = this.data['rescheduledSession'].sessionStudentId;
      session['sessionStudentName'] = this.data['rescheduledSession'].sessionStudentName;
      session['sessionParentName'] = this.data['rescheduledSession'].sessionParentName;

      session['parentInteraktId'] = this.data.rescheduledSession.parentInteraktId;
      session['expertInteraktId'] = this.data.rescheduledSession.expertInteraktId;

      session['serviceType'] = this.data.rescheduledSession.serviceType;

      session['attendedBy'] = { parent: false, expert: false, admin: false }
      session['expertPaid'] = false;

      let body1: any;
      let body2: any;

      let previousDate = new Date(this.helper.istTime(this.data.rescheduledSession.sessionDate.toDate()))
      let previousDateString = previousDate.getDate() + '/' + (previousDate.getMonth() + 1) + '/' + previousDate.getFullYear();

      let newDate = new Date(this.selectedSessionDate)
      let newDateString = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();

      if (this.data.reschedulingPerson == 'parent' || this.data.reschedulingPerson == 'Parent') {
        session['status'] = 'Scheduled';

        // notification for parent about rescheduled session.
        body1 = {
          'userId': this.data.rescheduledSession.parentInteraktId,
          'event': 'session rescheduled by parent - Notify parent',
          'traits': {
            'sessionType': this.data.rescheduledSession.serviceType,
            'expertName': this.data.rescheduledSession.sessionExpertName,
            'previousDate': previousDateString,
            'previousTime': this.data.rescheduledSession.startTime,
            'newDate': newDateString,
            'newTime': this.sessionStartTime
          }
        }

        // notification for expert about rescheduled session.
        body2 = {
          'userId': this.data.rescheduledSession.expertInteraktId,
          'event': 'session rescheduled by parent - Notify the expert',
          'traits': {
            'previousDate': previousDateString,
            'previousTime': this.data.rescheduledSession.startTime + ' IST',
            'childName': this.data.rescheduledSession.sessionStudentName,
            'newDate': newDateString,
            'newTime': this.sessionStartTime + ' IST',
            'reason': this.data.reschedulingDetails.reschedulingReason,
          }
        }

      } else if (this.data.reschedulingPerson == 'expert' || this.data.reschedulingPerson == 'Expert') {
        session['status'] = 'Session Request';

        let currentDateTime = new Date();
        let currentIstDateTime = new Date(currentDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }))
        let hoursLeftForExpiration = Math.abs(this.twelveHoursBeforeSessionStartTime.getTime() - currentIstDateTime.getTime());
        hoursLeftForExpiration /= 3600000;
        hoursLeftForExpiration = Math.floor(hoursLeftForExpiration);
        if (hoursLeftForExpiration > 12) {
          hoursLeftForExpiration = 12
        }

        if (this.data.rescheduledSession.status == "Session Request") {
          // notification for parent about rescheduled session for a Requested session.
          body1 = {
            'userId': this.data.rescheduledSession.parentInteraktId,
            'event': 'Requested session rescheduled by expert - Notify parent',
            'traits': {
              'expertName': this.data.rescheduledSession.sessionExpertName,
              'previousDate': previousDateString,
              'previousTime': this.data.rescheduledSession.startTime + ' IST',
              'newDate': newDateString,
              'newTime': this.sessionStartTime + ' IST',
              "link": "https://connect.mykinderpass.com/parent/dashboard"
            }
          }
        } else {
          // notification for parent about rescheduled session for a Scheduled session.
          body1 = {
            'userId': this.data.rescheduledSession.parentInteraktId,
            'event': 'session rescheduled by expert - Notify the parent',
            'traits': {
              'expertName': this.data.rescheduledSession.sessionExpertName,
              'sessionType': this.data.rescheduledSession.serviceType,
              'previousDate': previousDateString,
              'previousTime': this.data.rescheduledSession.startTime + ' IST',
              'reason': this.data.reschedulingDetails.reschedulingReason,
              'newDate': newDateString,
              'newTime': this.sessionStartTime + ' IST',
              'hoursLeftForExpiration': hoursLeftForExpiration,
              'link': 'https://connect.mykinderpass.com/parent/dashboard'
            }
          }
        }

      } else if (this.data.reschedulingPerson == "admin" || this.data.reschedulingPerson == 'Admin') {
        session['status'] = "Scheduled";

        // notification for parent about rescheduled session.
        body1 = {
          'userId': this.data.rescheduledSession.parentInteraktId,
          'event': 'session rescheduled by admin - notify parent',
          'traits': {
            'sessionType': this.data.rescheduledSession.serviceType,
            'expertName': this.data.rescheduledSession.sessionExpertName,
            'previousDate': previousDateString,
            'previousTime': this.data.rescheduledSession.startTime,
            'newDate': newDateString,
            'newTime': this.sessionStartTime
          }
        }

        // notification for expert about rescheduled session.
        body2 = {
          'userId': this.data.rescheduledSession.expertInteraktId,
          'event': 'session rescheduled by admin - notify expert',
          'traits': {
            'sessionType': this.data.rescheduledSession.serviceType,
            'childName': this.data.rescheduledSession.sessionStudentName,
            'previousDate': previousDateString,
            'previousTime': this.data.rescheduledSession.startTime,
            'newDate': newDateString,
            'newTime': this.sessionStartTime
          }
        }
      }

      docRef.set(session).then(() => {
        if (this.data.reschedulingPerson == 'parent' || this.data.reschedulingPerson == 'Parent') {
          this.notificationService.sendWhatsappNotification(body1);
          this.notificationService.sendWhatsappNotification(body2);
        } else if (this.data.reschedulingPerson == 'expert' || this.data.reschedulingPerson == 'Expert') {
          this.notificationService.sendWhatsappNotification(body1);
        } else if (this.data.reschedulingPerson == 'admin' || this.data.reschedulingPerson == 'Admin') {
          this.notificationService.sendWhatsappNotification(body1);
          this.notificationService.sendWhatsappNotification(body2);
        }
        this.onSessionUpdated(session);
      })
        .catch(function (error) {
          console.error("Error creating Question: ", error);
        });

    }
  }

  onSessionUpdated = (session:any) => {
    this.dialogRef.close({ event: 'Yes', newBookedSession: session,  twelveHoursBeforeSessionStartTime:this.twelveHoursBeforeSessionStartTime});

    let person:any;
    if(this.data.reschedulingPerson == "Parent" || this.data.reschedulingPerson == "parent"){
      person = this.data.rescheduledSession.sessionExpertName;
    }else if(this.data.reschedulingPerson == "Expert" || this.data.reschedulingPerson == "expert"){
      person = this.data.rescheduledSession.sessionParentName;
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const d1 = new Date(this.data.rescheduledSession.sessionDate.toDate());
    let date1 = d1.getDate();
    let month1 = monthNames[d1.getMonth()];
    let year1 = d1.getFullYear();

    let d2 = new Date(this.selectedSessionDate);
    let date2 = d2.getDate();
    let month2 = monthNames[d2.getMonth()];
    let year2 = d2.getFullYear();

    let message = "Your session on " + date1 + " " + month1 + ", " + year1 + " at " + this.data.rescheduledSession.startTime + " IST " + "with " + person + " has been rescheduled to " + date2 + " " + month2 + ", " + year2 + " at " + this.sessionStartTime + " IST."
    this.toastr.success(message, 'Reschedule Confirmed!');
  }
}
