import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { CheckboxControlValueAccessor, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, interval, Subscription } from 'rxjs';
import firebase from 'firebase/app';


import { SessionDialog } from './session.component'
import { MeetingComponent } from '../meeting/meeting.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../helper.service';
import { ExpertsService } from '../services/experts.service';
import { RescheduleDialog } from '../reschedule-session/reschedule/reschedule.component';
import { CancelDialog } from '../cancel-session/cancel/cancel.component';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../services/admin.service';
import { ZoomIdUnavailableDialogComponent } from '../zoom-id-unavailable-dialog/zoom-id-unavailable-dialog.component';
import { NotificationService } from '../services/notification.service';
import { PopUpsDisableDialogComponent } from '../pop-ups-disable-dialog/pop-ups-disable-dialog.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-expert-dashboard',
  templateUrl: './expert-dashboard.component.html',
  styleUrls: ['./expert-dashboard.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ExpertDashboardComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  sessions: any[];
  filter: string = "Upcoming";
  dateRange: any;
  toRescheduleSession: any;
  id: string;
  transactions: AngularFirestoreDocument[] = [];
  transactionsByDate = {};
  sortedDates: Date[] = [];
  transactionSub: Subscription;
  reschedulingDetails: {
    reschedulingReason: any,
    additionalReschedulingDetails: any,
    rescheduledOn: any,
    rescheduledBy: any,
    rescheduledByName: any,
    rescheduledWith: any,
    rescheduledWithName: any
  }

  parentList: AngularFirestoreDocument[] = [];
  parentSub: Subscription;
  parentName = '';
  parentSelected = '';
  statusName = '';
  statusSelected = '';
  sessionsCopy: any[];

  parentClicked = false;
  isParentClicked = false;
  isStatusClicked = false;
  statusClicked = false;
  statusArray: object[];

  chosenStartDate: Date;
  chosenEndDate: Date;
  dateHeadingArray = new Array();

  selectedTabIndex:any;

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService,
    public auth: AngularFireAuth,
    private firestore: AngularFirestore,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public helper: HelperService,
    public expertsService: ExpertsService,
    private toastr: ToastrService,
    private adminService: AdminService,
    private ngZone: NgZone) { }


  async ngOnInit() {
    // let start = new Date();
    // start.setHours(0, 0, 0, 0);

    // let end = new Date();
    // end.setHours(23, 59, 59, 999);
    // this.dateRange = new FormGroup({
    //   start: new FormControl(start),
    //   end: new FormControl(end)
    // });
    // await this.getSessions();
    // await this.expertsService.getCurrentUserDetails();
    // this.statusArray = this.helper.sessionTypesObject;

    // this.transactionSub = this.adminService.getTransactions(this.expertsService.currentUserId).subscribe(
    //   (transactionList: AngularFirestoreDocument[]) => { this.transactions = transactionList; this.sortTransactions(this.transactions); }
    // );


    // this.parentSub = this.adminService.ParentList.subscribe((result) => {
    //   let myParentList = result;
    //   this.parentList = [];
    //   myParentList.forEach(element => {
    //     element.myExpertsIds.forEach(eid => {
    //       if (eid === this.expertsService.currentUserId) {
    //         this.parentList.push(element);
    //       }
    //     });
    //   });

    //   this.parentList.sort(function (x, y) {
    //     return x['fullName'] > y['fullName'] ? 1 : -1;
    //   })
    // });

     //redirecting to new thrive expert dashboard
    window.location.href = "https://thrive-staging.mykinderpass.com/expert/signin";
  }

  changeTab(index){
    console.log('index value is ', index)
    this.selectedTabIndex = index;
  }

  onFilterChanged(event) {
    this.filter = event;
    this.getSessions();
  }

  onDateRangeChange() {
    if (this.dateRange.value.start && this.dateRange.value.end) {
      let start = new Date(this.dateRange.value.start);
      start.setHours(0, 0, 0, 0);

      let end = new Date(this.dateRange.value.end);
      end.setHours(23, 59, 59, 999);

      this.dateRange = new FormGroup({
        start: new FormControl(start),
        end: new FormControl(end)
      });

      this.getSessions();
    }
  }

  back() {
    let diff = Math.abs(this.dateRange.value.end.getTime() - this.dateRange.value.start.getTime());

    let start = new Date(this.dateRange.value.start.getTime() - diff);
    start.setHours(0, 0, 0, 0);

    let end = new Date(this.dateRange.value.start.getTime() - diff);
    end.setHours(23, 59, 59, 999);

    this.dateRange = new FormGroup({
      start: new FormControl(start),
      end: new FormControl(end)
    });

    this.getSessions();
  }

  forward() {
    let diff = Math.abs(this.dateRange.value.end.getTime() - this.dateRange.value.start.getTime());

    let start = new Date(this.dateRange.value.start.getTime() + diff + 1);
    start.setHours(0, 0, 0, 0);

    let end = new Date(this.dateRange.value.start.getTime() + diff + 1);
    end.setHours(23, 59, 59, 999);

    this.dateRange = new FormGroup({
      start: new FormControl(start),
      end: new FormControl(end)
    });

    this.getSessions();
  }

  goToSessionBooking(){
    if(this.expertsService.currentUserDetails.activityStatus == 'Session Disabled' || this.expertsService.currentUserDetails.activityStatus == 'Session & Consultation Disabled'){
      return;
    } else {
      this.ngZone.run(() => this.router.navigateByUrl("expert/expertNewSessionRequest"));
    }
  }

  async getSessions() {
    let currentDate = new Date();
    // let now = this.helper.istTime(currentDate);
    // let filterTime = firebase.firestore.Timestamp.fromDate(now)
    let filterTime = firebase.firestore.Timestamp.now()
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('expert/signin');
      return;
    }

    let bookedBy = currentUser.uid;
    this.id = bookedBy;

    if (this.filter == 'Upcoming') {
      let ref = this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', this.id).where('sessionEndDateTime', '>', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onSessionResults);
    } else if (this.filter == 'Past') {
      let ref = this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', this.id).where('sessionEndDateTime', '<=', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onSessionResults);
    }
  }

  onSessionResults = (results) => {
    this.sessions = [];
    if (!results.empty) {
      results.forEach((doc) => {
        this.sessions.push(doc.data());
      });
    }

    this.getConsultations();
  }

  async getConsultations() {
    let filterTime = firebase.firestore.Timestamp.now()
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('expert/signin');
      return;
    }

    let bookedBy = currentUser.uid;
    this.id = bookedBy;

    if (this.filter == 'Upcoming') {
      let ref = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', this.id).where('sessionEndDateTime', '>', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onConsultationResults);
    } else if (this.filter == 'Past') {
      let ref = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', this.id).where('sessionEndDateTime', '<=', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onConsultationResults);
    }
  }

  onConsultationResults = (results) => {
    if (!results.empty) {
      results.forEach((doc) => {
        console.log('consultation  ', doc.data())
        this.sessions.push(doc.data());
      });
    }

    this.sessions.sort(function (x, y) {
      return x.sessionEndDateTime - y.sessionEndDateTime
    })

    if (this.filter == 'Past' || this.filter == 'past') {
      this.sessions.reverse();
    }

    this.sessionsCopy = this.sessions;
    this.filterCombinedList(this.parentSelected, this.statusSelected);


    //Created for DateHeadings of the sessions
    let arr = new Array();
    let dateHead: Date;
    dateHead = this.sessions[0].sessionDate.toDate();
    for (let i = 0; i < this.sessions.length; i++) {
      if (dateHead < this.sessions[i].sessionDate.toDate()) { //for Upcoming sessions as they are in ascending in order of sessionDate
        arr.push(i);
        dateHead = this.sessions[i].sessionDate.toDate();
      } else if (dateHead > this.sessions[i].sessionDate.toDate()) { //for Past sessions as they are in descending in order of sessionDate
        arr.push(i);
        dateHead = this.sessions[i].sessionDate.toDate();
      }
    }
    this.dateHeadingArray = arr;

  }


  filterCombinedList(parentId: string = undefined, sortStatus: string = undefined) {

    let startDate = this.chosenStartDate;
    let endDate = this.chosenEndDate;

    let parentValidity = !(parentId === "" || parentId == undefined || parentId === null);
    let statusValidity = !(sortStatus === "" || sortStatus == undefined || sortStatus == null);

    let dateValidity = !(startDate == undefined || startDate == null || endDate == undefined || endDate == null);


    this.sessions = this.sessionsCopy;

    if (!statusValidity && parentValidity && !dateValidity) {
      this.sessions = this.sessions.filter(session => session['sessionStudentId'] === parentId);
    } else if (statusValidity && !parentValidity && !dateValidity) {
      this.sessions = this.sessions.filter(session => session['status'] === sortStatus);
    } else if (!statusValidity && !parentValidity && !dateValidity) {
    } else if (statusValidity && parentValidity && !dateValidity) {
      this.sessions = this.sessions.filter(session => (session['sessionStudentId'] === parentId) && (session['status'] === sortStatus));
    } else if (!statusValidity && parentValidity && dateValidity) {
      this.sessions = this.sessions.filter(session => (session['sessionStudentId'] === parentId) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    } else if (statusValidity && !parentValidity && dateValidity) {
      this.sessions = this.sessions.filter(session => (session['status'] === sortStatus) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    } else if (!statusValidity && !parentValidity && dateValidity) {
      this.sessions = this.sessions.filter(session => ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    }
    else {
      this.sessions = this.sessions.filter(session => (session['sessionStudentId'] === parentId) && (session['status'] === sortStatus) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    }

  }

  sessionCancelAndRescheduleBtnVisibility(session: any) {
    // if((session.status == 'Rejected Session Request') || (session.status == 'Cancelled') || (session.status == 'Rescheduled') || (session.status == 'noShow-Expert') || (session.status == 'noShow-Parent') || (session.status == 'Attended') || (session.status == 'Session Request Expired') || (session.status == 'REQUEST CANCELLED') || (session.status == 'Parent Joined') || (session.status == 'Expert Joined') || (session.status == 'Ongoing')){
    if((session.status == 'Scheduled') || (session.status == 'Session Request')){
      // let sessionDate: Date = session.sessionDate.toDate();
      // let exactSessionDate = this.helper.istTime2(sessionDate);

      // // startTime is HH:MM
      // let startTime = session.startTime.split(":");
      // const startingHour = parseInt(startTime[0], 10);
      // const startingMinute = parseInt(startTime[1], 10);

      // //Convert start time to minutes and add to session date to get exact start date time.
      // exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 720);

      // //Check if now is less than to session start date time
      // let currentTime = new Date();
      // let now = this.helper.istTime(currentTime);

      // if (now.getTime() < exactSessionDate.getTime()) {
      //   //session start time hasn't reached
      //   return true;
      // }

      return true;
    }

    return false;
  }

  joinConsultation(consultation) {
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
    const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
    // this.firestore
    //   .doc(`consultations/${consultation['id']}`)
    docRef
      .update({
        status: 'Ongoing',
        'attendedBy.expert': true,
      })
      .then(() => {
        let url = consultation.meetingLink;
        if (!url.match(/^https?:\/\//i))
          url = 'https://' + url;
        window.open(url, '_blank');
      });

    this.getSessions();
  }

  // Expert editing the session
  async editSession(sessionId: any) {
    console.log(sessionId);
    // getting this session details
    let docref = await this.firestore.collectionGroup('sessions', ref => ref.where("id","==",sessionId)).get().subscribe(this.onEditSession);
  }

  onEditSession = (result) => {
    console.log(result,"result");
    let session: any;
    session = result.docs[0].data();

    const dialogRef = this.dialog.open(RescheduleDialog, {
      panelClass: 'edit-session-dialog',
      disableClose: true,
      data: { session: session, reschedulingPerson: 'Expert' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        this.reschedulingDetails = {
          reschedulingReason: result.reason,
          additionalReschedulingDetails: result.additionalDetails,
          rescheduledOn: result.rescheduledOn,
          rescheduledBy: result.rescheduledBy,
          rescheduledByName: result.rescheduledByName,
          rescheduledWith: result.rescheduledWith,
          rescheduledWithName: result.rescheduledWithName
        }
        this.rescheduleSession(result.session, this.reschedulingDetails, result.reschedulingPerson);
      }
    });
  }

  async rescheduleSession(rescheduledSession, reschedulingDetails, reschedulingPerson: string) {
    let user = await this.auth.currentUser;
    this.toRescheduleSession = rescheduledSession;
    const dialogRef = this.dialog.open(SessionDialog, {
      disableClose: true,
      panelClass: 'rescheduleDialog',
      data: {
        requesting: 'expert',
        rescheduledSession: rescheduledSession,
        reschedulingDetails: reschedulingDetails,
        id: rescheduledSession.id,
        reschedulingPerson: reschedulingPerson
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        let status: any;
        if (this.toRescheduleSession.status == "Scheduled") {
          status = "Rescheduled"
        } else if (this.toRescheduleSession.status == "Session Request") {
          status = 'REQUEST CANCELLED'
        }
        let now = firebase.firestore.FieldValue.serverTimestamp()

        const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.toRescheduleSession.sessionDate.toDate());
        const docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(this.toRescheduleSession.id);

        await docRef.update({
          'status': status,
          'rescheduledOn': now,
          'reschedulingReason': this.reschedulingDetails.reschedulingReason,
          'additionalReschedulingDetails': this.reschedulingDetails.additionalReschedulingDetails,
          'rescheduledBy': this.reschedulingDetails.rescheduledBy,
          'rescheduledByName': this.reschedulingDetails.rescheduledByName,
          'rescheduledWith': this.reschedulingDetails.rescheduledWith,
          'rescheduledWithName': this.reschedulingDetails.rescheduledWithName,
          'reschedulingPerson': 'expert'
        })

        // getting all sessions
        this.getSessions();
      }
    });
  }

  async cancelMeeting(sessionId: any) {
    // getting this session details
    let docref = await this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionId)).get().subscribe(this.cancelSession);
  }

  cancelSession = (result) => {
    let session: any;
    session = result.docs[0].data();

    const dialogRef = this.dialog.open(CancelDialog, {
      panelClass: 'cancel-session-dialog',
      disableClose: true,
      data: { session: session, cancellingPerson: 'Expert' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSessions();
      if (!result) {
        return;
      }
    });
  }

  hasSessionStarted(session) {
    if (session.status == 'Scheduled' || session.status == 'Started' || session.status == 'Starting') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() >= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  joinButtonVisibility(session: any) {
    if (!session.attendedBy.expert && (session.status == "Scheduled" || session.status == "Parent Joined")) {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // console.log('exact session date ',exactSessionDate)

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      // console.log('join btn start time ', exactSessionDate);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);
      // console.log('join btn end time ', exactJoinEndTime);

      //Check if now is greater or equal to session start date time
      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);
      // console.log('current time  ', now)

      if ((now.getTime() >= exactSessionDate.getTime()) && (now.getTime() <= exactJoinEndTime.getTime())) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rejoinBtnVisibility(session: any) {
    if (session.attendedBy.expert &&
      (session['status'] == 'Expert Joined' ||
        session['status'] == 'Parent Joined' ||
        session['status'] == 'Ongoing')) {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let endTime = session.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (endingHour * 60) + endingMinute);

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if ("session.sessionType != 'Free Consultation' && session.sessionType != 'Paid Consultation'") {
        if (now.getTime() <= (exactSessionDate.getTime() - 10 * 60 * 1000)) {
          //session end time has not crossed
          return true;
        }
      } else {
        if (now.getTime() <= exactSessionDate.getTime()) {
          //session end time has not crossed
          return true;
        }
      }
    }
    return false;
  }

  consultationJoinButtonVisibility(session) {
    if (session.status == 'Scheduled' || session.status == 'Ongoing' && !session.attendedBy.expert) {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);;

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if ((now.getTime() >= exactSessionDate.getTime()) && (now.getTime() <= exactJoinEndTime.getTime())) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rejoinConsultation(consultation) {
    console.log(consultation,"rejoin");
    let url = consultation.meetingLink;
    if (!url.match(/^https?:\/\//i))
      url = 'https://' + url;
    window.open(url, '_blank');
  }

  consultationCancelButtonVisibility(session) {
    if (session.status == 'Scheduled') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);;

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() <= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  joinMeeting(id) {
    const dialogRef = this.dialog.open(MeetingComponent, {
      disableClose: true,
      width: '100vw',
      height: '100vh',
      data: { id: id }
    });
  }


  startMeeting(sessionDoc) {
    let docRef = this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionDoc.id))
    docRef.get().subscribe(result => {
      let session: any = result.docs[0].data();

      // expert is creating the meeting.
      if (session.status == 'Scheduled') {
        const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
        // let ref = this.firestore.collection('sessions').doc(session.id);
        let sessionData = session;

        // first check if there is a free zoom license available or not.
        let zoomRef = this.firestore.collection('zoomids', zoomRef => zoomRef.where('beingUsed', '==', false));
        zoomRef.get().subscribe((result) => {
          let size = result.size;
          if (size == 0) {
            const zoomIdUnavailableRef = this.dialog.open(ZoomIdUnavailableDialogComponent, {
              disableClose: false,
              data: { supportLink: "https://wa.me/+919606719826" },
              // panelClass: "partyConfirmationDialog"
            });
            zoomIdUnavailableRef.afterClosed().subscribe(result => {
              if (!result) {
                return;
              }
            });

            // Getting all admin documents.
            let adminRef = this.firestore.collection('users', adminRef => adminRef.where('role', '==', 'support'));
            adminRef.get().subscribe((adminResult) => {
              if (adminResult.empty) {
                console.log('no admin found')
                return;
              }

              adminResult.forEach(async (doc) => {
                let adminDoc: any;
                adminDoc = doc.data();

                // reminders for admins of this issue
                let body: any = {
                  'userId': adminDoc.interaktId,
                  'event': 'all zoom ids occupied - Notify admin',
                  'traits': {
                    'role': 'Expert',
                    'name': session.sessionExpertName,
                  },
                }
                this.notificationService.sendWhatsappNotification(body);
              })
            })
            return;

            // if we have any free zoom license
          } else {
            sessionData.attendedBy.expert = true;
            sessionData.status = 'Starting'
            ref.update(sessionData).then(() => {
              let message = "Your Session has started."
              this.toastr.success(message, "Join Now")

              const startedSession = firebase.firestore().collectionGroup('sessions').where("id","==",session.id);
              const observer = startedSession.onSnapshot(docSnapshot => {
                let data = docSnapshot.docs[0].data();

                if (data['zoomJoinUrl']) {
                  console.log("Rajan Expert Testing-1");
                  var newWin = window.open(data['zoomJoinUrl'], "_blank");
                  // code to show dialog if pop-ups is blocked
                  if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                    console.log("Testing->Pop-Ups is Blocked");
                    const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                      disableClose: false,
                      // data: { supportLink: "https://wa.me/+919606719826" },
                      panelClass: 'pop-ups-disable-dialog',
                    });
                    popUpsDisableRef.afterClosed().subscribe(result => {
                      if (!result) {
                        return;
                      }
                    });
                  }

                }
              })
            });
          }
        })

        // if meeting has already created and parent is in the meeting.
      } else if (session.status == 'Parent Joined') {

        // if expert is joining the meeting first time.
        if (!session.attendedBy.expert) {
          const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
          const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
          // let ref = this.firestore.collection('sessions').doc(session.id);
          let sessionData: any = {};
          sessionData = session;
          sessionData.status = 'Ongoing';
          sessionData.attendedBy.expert = true;
          ref.update(sessionData).then(() => {
            console.log("Rajan Expert pop-ups Testing-2");
            var newWin = window.open(session['zoomJoinUrl'], "_blank");
            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              console.log("Testing->Pop-Ups is Blocked");
              const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                disableClose: false,
                // data: { supportLink: "https://wa.me/+919606719826" },
                panelClass: 'pop-ups-disable-dialog',
              });
              popUpsDisableRef.afterClosed().subscribe(result => {
                if (!result) {
                  return;
                }
              });
            }

          }).catch(err => console.log('error  ', err))

          // if expert is joining the meeting again.
        } else {
          const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
          const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
          // let ref = this.firestore.collection('sessions').doc(session.id);
          ref.update({ status: 'Ongoing' }).then(() => {
            console.log("Rajan Expert Testing-3");
            var newWin = window.open(session['zoomJoinUrl'], "_blank");
            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              console.log("Testing->Pop-Ups is Blocked");
              const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                disableClose: false,
                // data: { supportLink: "https://wa.me/+919606719826" },
                panelClass: 'pop-ups-disable-dialog',
              });
              popUpsDisableRef.afterClosed().subscribe(result => {
                if (!result) {
                  return;
                }
              });
            }

          }).catch(err => console.log('error  ', err))
        }

        // if meeting already ended once and expert is rejoining the meeting.
      } else if (session.status == 'Partially Attended' || session.status == 'Attended' || session.status == 'Expert Joined') {
        const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
        // let ref = this.firestore.collection('sessions').doc(session.id);
        ref.update({ status: 'Expert Joined' }).then(() => {
          console.log("Rajan Expert Testing-4");
          var newWin = window.open(session['zoomJoinUrl'], "_blank");
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            console.log("Testing->Pop-Ups is Blocked");
            const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
              disableClose: false,
              // data: { supportLink: "https://wa.me/+919606719826" },
              panelClass: 'pop-ups-disable-dialog',
            });
            popUpsDisableRef.afterClosed().subscribe(result => {
              if (!result) {
                return;
              }
            });
          }

        }).catch(err => console.log('error  ', err))
      } else if (session.status == 'Ongoing') {
        console.log("Rajan Expert Testing-5");
        var newWin = window.open(session['zoomJoinUrl'], "_blank");
        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
          console.log("Testing->Pop-Ups is Blocked");
          const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
            disableClose: false,
            // data: { supportLink: "https://wa.me/+919606719826" },
            panelClass: 'pop-ups-disable-dialog',
          });
          popUpsDisableRef.afterClosed().subscribe(result => {
            if (!result) {
              return;
            }
          });
        }

      }
    })
  }

  sortTransactions(unfilteredTransactions: AngularFirestoreDocument[]) {
    let transactionsByDate = {};

    let transactionsList: AngularFirestoreDocument[] = unfilteredTransactions.filter(
      (transaction: AngularFirestoreDocument) => !(transaction['transactionType'] === 'byAdmin' && transaction['hideTranscation'] === true)
    );

    let transactions: AngularFirestoreDocument[] = transactionsList.filter(
      (transaction: AngularFirestoreDocument) => !(transaction['returnedSessionValue'] === 0 && transaction['transactionValue'] === 0)
    );

    for (let transaction of transactions) {
      let createdDate: Date = this.helper.istTime2(transaction['createdAt'].toDate());
      let createdAt = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate(), 0, 0, 0);
      if (transactionsByDate[createdAt.toString()])
        transactionsByDate[createdAt.toString()].push(transaction);
      else {
        transactionsByDate[createdAt.toString()] = [];
        transactionsByDate[createdAt.toString()].push(transaction);
      }
    }
    let dates: Date[] = [];
    for (let i in transactionsByDate)
      dates.push(new Date(i));

    this.transactionsByDate = transactionsByDate;
    this.sortedDates = dates;
  }

  getFinalTransactionValue(transaction: AngularFirestoreDocument): number {
    let transactionVal: number = transaction['transactionValue'];
    let returnedSessionVal: number = transaction['returnedSessionValue'];

    if (transactionVal === 0 && returnedSessionVal !== 0)
      return returnedSessionVal;
    else if (transactionVal !== 0 && (returnedSessionVal === 0 || !returnedSessionVal))
      return transactionVal;
    else if (transactionVal !== 0 && returnedSessionVal !== 0)
      return (transactionVal + returnedSessionVal);
    else if (transactionVal === 0 && returnedSessionVal === 0)
      return 0;
  }


  //searching based on one parameter like parent name we are using 4 methods (resetParentInput,onParentFocus,onParentBlur,onParentClick )
  resetParentInput(event: string) { //function to reset the parent filter if the expert input is empty
    this.parentClicked = false;
    this.isParentClicked = true;
    if (event === '') {
      this.parentSelected = '';
      this.filterCombinedList(this.parentSelected, this.statusSelected);
    }
  }

  onParentFocus() {
    // console.log("Testing Expert focus");
    this.isParentClicked = true;
  }

  onParentBlur() {
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isParentClicked = false;
      if (!this.parentClicked) {
        // console.log("Testing Expert Blur");
        this.parentName = '';
        this.parentSelected = "";
        this.filterCombinedList(this.parentSelected, this.statusSelected);
      }
    }, 200);
  }

  onParentClick(parent: AngularFirestoreDocument) {
    this.parentClicked = true;
    this.parentName = parent['fullName'];
    // this.dropdownParentHover = false;
    this.parentSelected = parent['id'];
    this.filterCombinedList(this.parentSelected, this.statusSelected); //filtering session list whenever a parent is clicked from the dropdown list
  }

  resetStatusInput(event: string) { //function to reset the status filter if the status input is empty
    this.statusClicked = false;
    this.isStatusClicked = true;
    if (event === '') {
      this.statusSelected = "";
      this.filterCombinedList(this.parentSelected, this.statusSelected);
    }
  }

  onStatusFocus() {
    this.isStatusClicked = true;
  }

  onStatusBlur() {
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isStatusClicked = false;
      if (!this.statusClicked) {
        this.statusName = '';
        this.statusSelected = "";
        this.filterCombinedList(this.parentSelected, this.statusSelected);
      }
    }, 200);
  }

  onStatusClick(status) {
    this.statusClicked = true;
    // console.log("Testing status Click", status);
    this.statusName = status['name'];
    // this.dropdownExpertHover = false;
    this.statusSelected = this.statusName;
    this.filterCombinedList(this.parentSelected, this.statusSelected); //filtering session list whenever an expert is clicked from the dropdown list
  }


  //These are for the methods for filtering the sessions based on date
  onStartDateSelected(event) {
    this.chosenStartDate = event.value;
    console.log("Start Date: ", this.chosenStartDate);
    this.onMultiDatesSelected();
  }
  onEndtDateSelected(event) {
    this.chosenEndDate = event.value;
    console.log("End Date: ", this.chosenEndDate);
    this.onMultiDatesSelected();
  }
  onMultiDatesSelected() {
    console.log("Testing for date filter");
    this.filterCombinedList(this.parentSelected, this.statusSelected);
  }
  resetForm() {
    this.chosenStartDate = undefined;
    this.chosenEndDate = undefined;
    this.filterCombinedList(this.parentSelected, this.statusSelected);
  }

  ngOnDestroy() {
    if (this.parentSub) this.parentSub.unsubscribe();
  }


}
