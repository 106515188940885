import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../helper.service';
import { ExpertsService } from '../services/experts.service';
import { NotificationService } from '../services/notification.service';
import { SessionService } from '../services/session.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import data1 from 'src/app/services/dummyData1.json';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-expert-new-session',
  templateUrl: './expert-new-session.component.html',
  styleUrls: ['./expert-new-session.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ExpertNewSessionComponent implements OnInit {
  id: string;
  students: any[];
  showLeaves: boolean = false;
  parentSubscriptionDetails: boolean = false;
  expertsLeaves: any[];
  isInsufficientBalance: boolean;
  isLessThanSlotRange: boolean;
  isStudentSelected: boolean;
  isSessionTypeSelected = false;
  showSlots: boolean = false;
  selectedStudent: any;
  slotDuration = 60;
  twelveHoursBeforeSessionStartTime: any
  sessionEndDateTime: any;
  sessionTypes: any;
  selectedSessionType: string;
  selectedSlot: any;
  selectedDate: any;
  sessionStartTime: any;
  sessionEndTime: any;
  selectedSessionDate: any;
  getSlotsEnabled: boolean = false;
  chosenDate: Date;
  showBookSession = false;

  constructor(private auth: AngularFireAuth,
    private router: Router,
    private firestore: AngularFirestore,
    private notificationService: NotificationService,
    private sessionService: SessionService,
    private toastr: ToastrService,
    public helper: HelperService,
    public expertsService: ExpertsService) { }

  ngOnInit(): void {
    this.getStudents();
    this.getLeaves();
  }

  async getLeaves() {
    this.expertsLeaves = [];
    var currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('expert/signin');
      return;
    }
    let id = currentUser.uid;

    let leaveStartTimeStamp = firebase.firestore.Timestamp.now();
    let seconds = leaveStartTimeStamp.seconds;
    let leaveEndSeconds = seconds + 2592000;
    let leaveEndTimeStamp = firebase.firestore.Timestamp.fromMillis(leaveEndSeconds * 1000);

    let leaveRef = this.firestore.collection('leaves', leaveRef => leaveRef.where('leaveBy', '==', id).where('leaveStartDateTime', '>=', leaveStartTimeStamp).where('leaveStartDateTime', '<=', leaveEndTimeStamp));
    leaveRef.get().subscribe((results) => {
      if (results.empty) {
        return;
      } else {
        results.forEach((doc) => {
          let leave: any = doc.data();
          console.log(leave)
          this.expertsLeaves.push(leave);
        })
      }
    })
  }

  async getStudents() {
    var currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('expert/signin');
      return;
    }
    let id = currentUser.uid;
    this.id = id;

    let ref = this.firestore.collection('users', ref => ref.where('myExpertsIds', 'array-contains', this.id));
    ref.get().subscribe(this.onStudentResult)
  }

  onStudentResult = (results) => {
    if (!results.empty) {
      this.students = [];
      results.forEach((doc) => {
        this.students.push(doc.data());
      });
    } else {
      this.students = null;
    }
  }

  onStudentSelected(student) {
    this.isInsufficientBalance = false;
    this.isStudentSelected = true;
    this.showSlots = false;
    this.selectedStudent = student;

    let balanceRef = this.firestore.collection('balances').doc(student.id);
    balanceRef.get().subscribe((result) => {
      if (!result.exists) {
        console.log('balance document is missing for this parent');
      } else {
        let balanceDoc: any = result.data();
        if (balanceDoc.currentBalance == 0) {
          this.isInsufficientBalance = true;
        }
      }
    });

    //created for serviceTypes
    for (let i = 0; i < this.selectedStudent.myExperts.length; i++) {
      if (this.id == this.selectedStudent.myExperts[i].educatorId) {
        this.sessionTypes = this.selectedStudent.myExperts[i].service;
        break;
      }
    }

  }

  onSessionTypeSelected(event: any) {
    this.isSessionTypeSelected = true;
    this.selectedSessionType = event;
  }

  onDateSelected(d) {
    this.showBookSession = false;
    let date = new Date(d);
    this.showSlots = false;
    this.chosenDate = date;
    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);
    if (date.getDate() > now.getDate()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else if (date.getMonth() > now.getMonth()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else if (date.getFullYear() > now.getFullYear()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else {
      this.getSlotsEnabled = false;
      this.isLessThanSlotRange = true;
    }

    this.getFreeSlots();
  }

  async getFreeSlots() {
    this.getSlotsEnabled = false;
    this.showSlots = true;
    let id = this.id;
    let date = new Date(this.chosenDate);
    let slotDuration = this.slotDuration;
    this.sessionService.checkForSlots(id, date, slotDuration);
  }

  onSlotSelected(slotValue) {
    //(<HTMLInputElement> document.getElementById("sessionRequestBtn")).disabled = false;
    this.showBookSession = true;
    this.selectedSlot = slotValue;
    this.setSessionTimings();
  }

  setSessionTimings() {
    let sessionStartTime = this.selectedSlot.split(":");

    // to get session starting time in minutes
    const startingHour = parseInt(sessionStartTime[0], 10);
    const startingMinute = parseInt(sessionStartTime[1], 10);
    sessionStartTime = (startingHour * 60) + startingMinute;
    let slotDuration = this.slotDuration;

    // this is session end time in minutes
    let sessionEndTime = sessionStartTime + slotDuration;

    let endHour = Math.floor(sessionEndTime / 60);
    let endMinute = sessionEndTime % 60;

    // creating a session end timestamp for filter comparison
    let istSessionEndDateTime = this.helper.istTime3(this.selectedDate, endHour, endMinute);
    this.sessionEndDateTime = istSessionEndDateTime;

    // creating date 12 hours earlier for session expiration
    let istSessionStartTime = this.helper.istTime3(this.selectedDate, startingHour, startingMinute);
    istSessionStartTime.setHours(istSessionStartTime.getHours() - 12);
    this.twelveHoursBeforeSessionStartTime = istSessionStartTime;


    if (endMinute == 0) {
      sessionEndTime = endHour + ":" + endMinute + '0';
    } else {
      sessionEndTime = endHour + ":" + endMinute;
    }

    this.sessionStartTime = this.selectedSlot;
    this.sessionEndTime = sessionEndTime;
  }

  saveSession() {
    this.showBookSession = false;
    if (this.isInsufficientBalance) {
      console.log('insufficient balance of parent')
      return;
    }

    var docRef;
    let sessionValue = 1.00;
    let session = {};

    // creating the unique id for the session
    let dateStringForId = this.selectedSessionDate.getDate() + '-' + (this.selectedSessionDate.getMonth() + 1) + '-' + this.selectedSessionDate.getFullYear();
    let slotId = dateStringForId + this.sessionStartTime + '-' + this.sessionEndTime + this.id;
    let id = this.helper.uuidv4();

    let topicID = this.helper.uuidv4();

    
    // docRef = this.firestore.collection("sessions").doc(id);

    const sessiondate=new Date(this.selectedSessionDate);
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(sessiondate);
    docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(id);
    
    const createdOn = firebase.firestore.FieldValue.serverTimestamp();
    const createdBy = firebase.auth().currentUser.uid;
    const createdByName = this.expertsService.currentUserDetails.fullName;

    session['id'] = id;
    session['slotId'] = slotId;
    session['topicID'] = topicID;
    session['sessionValue'] = sessionValue;

    session['createdOn'] = createdOn;
    session['sessionEndDateTime'] = this.sessionEndDateTime;
    session['sessionDate'] = this.selectedSessionDate;
    session['startTime'] = this.sessionStartTime;
    session['endTime'] = this.sessionEndTime;
    session['slotDuration'] = this.slotDuration;

    session['createdBy'] = createdBy;
    session['createdByName'] = createdByName;
    session['bookedBy'] = firebase.firestore.FieldValue.arrayUnion(createdBy);

    session['attendedBy'] = { parent: false, expert: false, admin: false }

    session['createdWith'] = this.selectedStudent.id;
    session['createdWithName'] = this.selectedStudent.fullName;

    session['sessionExpertId'] = this.id;
    session['sessionExpertName'] = createdByName;

    session['sessionStudentId'] = this.selectedStudent.id;
    session['sessionStudentName'] = this.selectedStudent.childName
    session['sessionParentName'] = this.selectedStudent.fullName;

    session['parentInteraktId'] = this.selectedStudent.interaktId;
    session['expertInteraktId'] = this.expertsService.currentUserDetails.interaktId;

    session['status'] = 'Session Request';
    session['expertPaid'] = false;

    // let service: any;
    // let selectedParentEducators = this.selectedStudent.myExperts;
    // for (let i = 0; i < selectedParentEducators.length; i++) {
    //   if (createdBy == this.selectedStudent.myExperts[i].educatorId) {
    //     service = this.selectedStudent.myExperts[i].service;
    //     break;
    //   }
    // }

    session['serviceType'] = this.selectedSessionType;

    let date = new Date(this.selectedDate);
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    docRef.set(session).then(() => {
      let currentDateTime = new Date();
      let currentIstDateTime = new Date(currentDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }))

      let hoursLeftForExpiration = Math.abs(this.twelveHoursBeforeSessionStartTime.getTime() - currentIstDateTime.getTime());
      hoursLeftForExpiration /= 3600000;
      hoursLeftForExpiration = Math.floor(hoursLeftForExpiration);

      if (hoursLeftForExpiration > 12) {
        hoursLeftForExpiration = 12
      }

      let body = {
        'userId': this.selectedStudent.interaktId,
        'event': 'new session requested by expert - notify parent',
        'traits': {
          'expertName': createdByName,
          'sessionType': this.selectedSessionType,
          'childName': this.selectedStudent.childName,
          'date': dateString,
          'time': this.sessionStartTime + ' IST',
          'hoursLeftForExpiration': hoursLeftForExpiration,
          'link': 'https://connect.mykinderpass.com/parent/dashboard'
        }
      }

      this.notificationService.sendWhatsappNotification(body);
      this.onSessionUpdated();
    }).catch(function (error) {
      console.error("Error creating Question: ", error);
    });
  }

  onSessionUpdated = () => {
    this.isStudentSelected = false;
    this.showSlots = false;
    this.showBookSession = false;
    // this.isMultipleSession = null;
    (<HTMLInputElement>document.getElementById("sessionRequestBtn")).disabled = true;
    var message = "New Session request has been sent to the educator"
    this.toastr.success(message, 'Session Requested!');
    this.router.navigate(['/expert/dashboard'])
  }

  dateFilter(date: Date) {
    let d = new Date(date);
    let currentTime = new Date();
    let currentOffset = currentTime.getTimezoneOffset();
    let ISTOffset = 330;   // IST offset UTC +5:30 
    let now = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    if (
      d.getDate() > now.getDate() &&
      d.getMonth() == now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (
      d.getMonth() > now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (d.getFullYear() > now.getFullYear()) return true;
    else if (d.getFullYear() < now.getFullYear()) return false;
  }
}
