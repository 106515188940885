import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentService } from '../payment.service';
import { Router } from '@angular/router';
import { ParentsService } from '../services/parents.service';
import { environment } from './../../environments/environment';
import $ = require('jquery');
import * as CryptoJS from 'crypto-js';
import { HelperService } from '../helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  isPaymentProcessed: boolean = false;
  isPaymentSuccess: boolean = false;
  isPaymentFailure: boolean = false;
  isLoading:boolean=false;

  recommendedFrequency = 3;
  numberOfSessionsPerWeek = null;
  totalNumberOfSessions: any;
  numberOfWeeks = null;
  manuallyEnteredWeeks = null;

  perSessionRate = 799;
  isDiscountApplied: boolean = false;
  discountApplied = 0;
  enablePayButton: boolean = false;

  processingPayment: boolean;
  payableAmount: any;
  paymentResponse: any = {};
  WindowRef: any;
  parent: any;
  orderResponse: any;
  paymentResolvedData: any;
  showFullPaymentDetails: boolean = false;
  paymentId: any;
  invoiceId: any;
  duration: any;
  paymentTime: any;
  pricingPlans:any=[];
  selectedPlan:any={};



  constructor(
    private changeRef: ChangeDetectorRef,
    private paymentService: PaymentService,
    public parentsService: ParentsService,
    private dialog: MatDialog,
    private helper: HelperService,
    private firestore: AngularFirestore,
    private ngZone: NgZone,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchPricingPlans();
    this.WindowRef = this.paymentService.WindowRef;
    this.parentsService.getCurrentUserDetails().then(() => {
      this.parent = this.parentsService.currentUserDetails;
      this.getFrequency();
      console.log('parent ', this.parentsService.currentUserDetails)
    });

    if(this.helper.renewalPaymentDetails){ 
      this.numberOfSessionsPerWeek = this.helper.renewalPaymentDetails.sessionPerWeek;
      this.numberOfWeeks ? this.numberOfWeeks : this.manuallyEnteredWeeks = this.helper.renewalPaymentDetails.duration;
      this.perSessionRate = this.helper.renewalPaymentDetails.rateApplied;
      this.totalNumberOfSessions = this.helper.renewalPaymentDetails.totalSessions;
      this.payableAmount = this.helper.renewalPaymentDetails.amount;
      this.enablePayButton = true;
    }
  }

  fetchPricingPlans(){
     // Getting all plans documents.
     let plansRef = this.firestore.collection('plans',ref=>ref.orderBy("orderBy")).get().subscribe((plansResult) => {
       if (plansResult.empty) {
         console.log('no plans found')
         return;
       }

       plansResult.forEach(async (doc) => {
         let planDoc: any;
         planDoc = doc.data();
        this.pricingPlans.push(planDoc);
       })
       this.isLoading=false;
     })
    
  }

  cancelPayment() {
    this.ngZone.run(() => this.router.navigateByUrl("parent/dashboard"));
  }

  getFrequency() {
    let experts = [];
    experts = this.parent.myExperts;

    if(this.numberOfSessionsPerWeek == null){
      experts.forEach(doc => {
        let expert: any = doc;
        let frequencyPerExpert = expert.reccomendedFrequency;
        this.numberOfSessionsPerWeek = this.numberOfSessionsPerWeek + frequencyPerExpert;
      })
    }
  }

  onManualWeekInputFocus() {
    console.log("Testing Expert focus");
    $(document).ready(function () {
      $(".check-premium").click(function () {

      });

      $("#premium").prop("checked", true).trigger("click");

    });
  }



  changeNumberOfSessionsPerWeek(event: any) { //function to reset the number of sessions per week from input
    this.numberOfSessionsPerWeek = event;
    this.totalNumberOfSessions = this.numberOfSessionsPerWeek * (this.numberOfWeeks ? this.numberOfWeeks : this.manuallyEnteredWeeks)
    console.log('total number os sessions ', this.totalNumberOfSessions)
    this.setPerSessionRate();
  }

  setWeekDuration(week) { // function when a default week number is selected
    this.manuallyEnteredWeeks = null
    this.numberOfWeeks = week;
    this.totalNumberOfSessions = this.numberOfSessionsPerWeek * this.numberOfWeeks
    console.log('total number os sessions ', this.totalNumberOfSessions)
    this.setPerSessionRate();
  }

  addManualWeekNumber(event: any) { //function to reset the the numbers of week from input
    this.numberOfWeeks = undefined;
    this.manuallyEnteredWeeks = event;
    this.totalNumberOfSessions = this.numberOfSessionsPerWeek * this.manuallyEnteredWeeks;
    console.log('total number os sessions ', this.totalNumberOfSessions)
    this.setPerSessionRate();
  }

  setPerSessionRate() {
    if (this.totalNumberOfSessions >= 8 && this.totalNumberOfSessions < 16) {
      this.perSessionRate = 749;
      this.discountApplied = (this.totalNumberOfSessions * 799) - (this.totalNumberOfSessions * 749);
      this.isDiscountApplied = true;
    } else if (this.totalNumberOfSessions >= 16 && this.totalNumberOfSessions < 24) {
      this.perSessionRate = 699;
      this.discountApplied = (this.totalNumberOfSessions * 799) - (this.totalNumberOfSessions * 699);
      this.isDiscountApplied = true;
    } else if (this.totalNumberOfSessions >= 24) {
      this.perSessionRate = 649;
      this.discountApplied = (this.totalNumberOfSessions * 799) - (this.totalNumberOfSessions * 649);
      this.isDiscountApplied = true;
    } else if (this.totalNumberOfSessions < 8) {
      this.perSessionRate = 799;
      this.isDiscountApplied = false;
    }

    if (this.totalNumberOfSessions != 0) {
      this.setTotalPayableAmount();
    } else {
      this.enablePayButton = false;
      this.payableAmount = null;
    }
  }

  openRateCardDialog() {
    const dialogRef = this.dialog.open(SessionRateDialog, {
      disableClose: false,
      panelClass: 'sessionPackDialog'
    });
  }

  setTotalPayableAmount() {
    this.payableAmount = this.totalNumberOfSessions * this.perSessionRate;
    this.enablePayButton = true;
  }

  proceedToPay($event,plan) {
    this.isPaymentProcessed = false;
    this.isPaymentFailure = false;

    console.log('event ', $event)
    this.enablePayButton = false;
    this.processingPayment = true;
    this.selectedPlan=plan;
    this.initiatePaymentModal($event);
  }

  initiatePaymentModal(event) {
    let receiptNumber = `Receipt#${Math.floor(Math.random() * 5123 * 43) + 10}`;

    // dummy details for checking the function for now.
    let orderDetails = {
      amount: this.selectedPlan.discountedPrice,
      // amount: 1,
      receipt: receiptNumber
    }

    // calling the payment service which will trigger firebase cloud function.
    this.paymentService.createOrder(orderDetails)
      .subscribe(order => {
        console.log("TCL: CheckoutComponent -> initiatePaymentModal -> order", order)
        this.orderResponse = order;
        console.log(this.orderResponse.order)

        let rzp1 = new this.WindowRef.Razorpay(this.preparePaymentDetails(this.orderResponse.order));
        this.processingPayment = false;
        rzp1.open();

        rzp1.on('payment.failed', this.onPaymentFailed)

        event.preventDefault();
      }, error => {
        console.log("TCL: CheckoutComponent -> initiatePaymentModal -> error", error)
      })
  }


  preparePaymentDetails(order) {
    var ref = this;
    let imageRef = './../../assets/kinderpass-logo.png'
    return {
      "key": environment.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      // "amount": 100, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      "amount": this.selectedPlan.discountedPrice, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      "name": 'Kinderpass Payment',
      "currency": order.currency,
      "order_id": order.id,//This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
      // "image": 'https://angular.io/assets/images/logos/angular/angular.png',
      "image": imageRef,
      "send_sms_hash":true,
      // "redirect":true,
      // "callback_url":this.ngZone.run(() => this.router.navigateByUrl("parent/failure-payment")),
      "handler": function (response) {
        ref.handlePayment(response);
      },
      "retry":{
        "enabled":false
      },
      "modal": {
        escape: false,
        "ondismiss": async function () {
          ref.processingPayment = false;
          // await ref.sendFailurePaymentNotification();
          // ref.ngZone.run(() => ref.router.navigateByUrl("parent/failure-payment"));
        }
      },
      "notes": {
        "type": "sessionPayments"
      },
      "notify": {
        "sms": true,
        "email": true
      },
      "prefill": {
        "name": this.parent.fullName,
        "email":this.parent.email,
        "contact":this.parent.phoneNumber

      },
      "theme": {
        "color": "#2874f0"
      },
      // method: {
      //   netbanking: true,
      //   card: true,
      //   upi: true ,
      //   wallet:true,
      //   paylater:false,
      //   emi:false
      // }
       
    };
  }


  handlePayment(response) {
    console.log('main response ', response)
    this.isPaymentProcessed = true;
    this.processingPayment = true;
    this.paymentResponse = response;

    let testString = this.orderResponse.order.id + "|" + response.razorpay_payment_id;
    const sha256 = CryptoJS.HmacSHA256(testString, this.orderResponse.keySecret).toString(CryptoJS.enc.Hex)

    let isSignatureValid = sha256 == response.razorpay_signature;
    if (isSignatureValid == true) {
      let transactionId = this.helper.uuidv4();

      // storing the transaction in database
      let ref = this.firestore.collection('transactions').doc(transactionId).set({
        transactionType: "renewalTransaction",
        id: transactionId,
        paymentId: this.paymentResponse.razorpay_payment_id,
        orderId: this.paymentResponse.razorpay_order_id,
        paymentStatus: "Successfull",
        userId: this.parent.id,
        amount: this.selectedPlan.discountedPrice,
        sessions: this.selectedPlan.sessionCount,
        validity:this.selectedPlan.validity,
        createdAt: new Date()
      }).then(() => {
        this.paymentResolvedData = {}

        let date = new Date();
        this.paymentResolvedData.name = this.parent.fullName;
        // this.paymentResolvedData.sessionPerWeek = this.numberOfSessionsPerWeek
        this.paymentResolvedData.duration =this.selectedPlan.validity;
        this.paymentResolvedData.rateApplied = this.selectedPlan.pricePerSession;
        this.paymentResolvedData.totalSessions = this.selectedPlan.sessionCount;
        this.paymentResolvedData.amount = this.selectedPlan.discountedPrice;
        this.paymentResolvedData.date = date;
        this.paymentResolvedData.transactionId = this.paymentResponse.razorpay_payment_id
        this.paymentResolvedData.invoiceId = transactionId;
        this.paymentResolvedData.viaPaymentPage = true;

        this.paymentId = this.paymentResponse.razorpay_payment_id;
        this.invoiceId = transactionId;
        this.duration = this.selectedPlan.validity;
        this.paymentTime = date;

        this.helper.renewalPaymentDetails = this.paymentResolvedData

        this.sendSuccessfullPaymentNotification();

        this.isPaymentSuccess = true;
        this.changeRef.detectChanges();
        this.processingPayment = false;
        this.ngZone.run(() => this.router.navigateByUrl("parent/multiple-session"));
        // setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
        
        // }, 2000);
       

      }).catch(error=>{
        console.log("error",error);
        this.processingPayment = false;
      })
    }
  }


  onPaymentFailed = (response) => {
    this.processingPayment = true;
    console.log('failure response ', response)
    this.paymentResponse = response;
    this.paymentResolvedData = {}

    let transactionId = this.helper.uuidv4();

    // storing the transaction in database
    this.firestore.collection('transactions').doc(transactionId).set({
      transactionType: "renewalTransaction",
      id: transactionId,
      paymentId: this.paymentResponse.error.metadata.payment_id,
      orderId: this.paymentResponse.error.metadata.order_id,
      paymentStatus: "failure",
      failureReason: this.paymentResponse.error.reason,
      userId: this.parent.id,
      amount: this.selectedPlan.discountedPrice,
      sessions: this.selectedPlan.sessionCount,
      validity:this.selectedPlan.validity,
      createdAt: new Date()
    }).then(() => {
      let date = new Date();
      this.paymentResolvedData.name = this.parent.fullName;
      // this.paymentResolvedData.sessionPerWeek = this.numberOfSessionsPerWeek
      this.paymentResolvedData.duration = this.selectedPlan.validity;
      this.paymentResolvedData.rateApplied = this.selectedPlan.pricePerSession;
      this.paymentResolvedData.totalSessions = this.selectedPlan.sessionCount;
      this.paymentResolvedData.amount = this.selectedPlan.discountedPrice;
      this.paymentResolvedData.date = date;
      this.paymentResolvedData.transactionId = this.paymentResponse.error.metadata.payment_id;
      this.paymentResolvedData.errorReason = this.paymentResponse.error.reason;
      this.paymentResolvedData.invoiceId = transactionId;
      this.paymentResolvedData.viaPaymentPage = true;

      this.helper.renewalPaymentDetails = this.paymentResolvedData;
      this.isPaymentProcessed = true;
      this.isPaymentFailure = true;
      this.processingPayment = false;
      this.sendFailurePaymentNotification()
      this.ngZone.run(() => this.router.navigateByUrl("parent/failure-payment"));
    }).catch(error=>{
      console.log("error",error);
      this.processingPayment = false;
    })
  }

  async sendFailurePaymentNotification(){
    let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'support'));
    return ref.get().toPromise().then(results => {
      if (!results.empty) {
        const promises: Promise<any>[] = [];

        results.forEach(async doc => {
          let admin: any = doc.data();

          // notification for support admin for failure payment
          let body = {
            'userId': admin.interaktId,
            'event': 'renewal payment failed - notify support',
            'traits': {
              'name': this.parent.fullName,
              'reason': this.paymentResponse.error.reason,
              'amount': this.selectedPlan.discountedPrice,
            }
          }

          promises.push(this.notificationService.sendWhatsappNotification(body));

        })

        return Promise.all(promises).then(() => {
          console.log('all operations done!')
        })
      }
    })
  }

  async sendSuccessfullPaymentNotification() {
    let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'support'));
    ref.get().subscribe(results => {
      if (!results.empty) {
        results.forEach(async doc => {
          let admin: any = doc.data();

          // notification for support admin for successfully payment
          let body = {
            'userId': admin.interaktId,
            'event': 'renewal payment successfull - notify parent',
            'traits': {
              'name': this.parent.fullName,
              'amount': this.selectedPlan.discountedPrice,
              'totalSessions': this.selectedPlan.sessionCount,
              'duration': this.selectedPlan.validity,

            }
          }

          await this.notificationService.sendWhatsappNotification(body);

        })
      }
    })
  }


  // onPaymentDetailsClicked() {
  //   console.log('getting called')
  //   this.showFullPaymentDetails = true;
  //   this.changeRef.detectChanges();
  // }

  // hidePaymentDetails() {
  //   this.showFullPaymentDetails = false;
  //   this.changeRef.detectChanges();
  // }

}


@Component({
  selector: 'app-session-rate-dialog',
  template: `
  <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
    <div class="sessionRateDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important;">Session Rate Card</mat-card-title>

      <div class="rateDetailsCard">
      <p class="amountOnRateCard">INR 799/session</p>
      <p class="sessionOnRateCard">1 session</p>
      </div>

      <mat-card-title style="margin-bottom: 20px !important;">Discount</mat-card-title>

      <div class="rateDetailsCard">
        <p class="amountOnRateCard"><span class="showDiscount">799</span> 749/session</p>
        <p class="sessionOnRateCard"><span style="color: #F7524B">8</span> or more sessions</p>
      </div>

      <div class="rateDetailsCard">
        <p class="amountOnRateCard"><span class="showDiscount">799</span> 699/session</p>
        <p class="sessionOnRateCard"><span style="color: #F7524B">16</span> or more sessions</p>
      </div>

      <div class="rateDetailsCard">
        <p class="amountOnRateCard"><span class="showDiscount">799</span> 649/session</p>
        <p class="sessionOnRateCard"><span style="color: #F7524B">24</span> or more sessions</p>
      </div>

    </div>
  `,
  styleUrls: ['./payment.component.css']
})

export class SessionRateDialog implements OnInit {

  constructor(private dialogRef: MatDialogRef<SessionRateDialog>) { }

  ngOnInit() { }
}
