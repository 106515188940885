import { Component, ElementRef, Input, Inject, NgZone, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { take } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { element } from 'protractor';
import { HelperService } from '../helper.service';
import { NotificationService } from '../services/notification.service';
import { ParentsService } from '../services/parents.service';
import { SessionService } from '../services/session.service';
import { SingleSlotAvailabilityService } from '../services/single-slot-availability.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
// import { Input } from 'hammerjs';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-multi-session',
  templateUrl: './multi-session.component.html',
  styleUrls: ['./multi-session.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MultiSessionComponent implements OnInit {
  // @Input()
  // set viaPaymentFlow(value) {
  //   this.isViaPaymentFlow = value;
  //   console.log('isViaPaymentFlow ', this.isViaPaymentFlow)
  // }

  // @Input()
  // set paymentData(value) {
  //   this.renewalPaymentDetails = value;
  //   console.log('renewalPaymentDetails ', this.renewalPaymentDetails)
  // }

  date = new Date();
  isViaPaymentFlow: boolean = false;
  renewalPaymentDetails: any;
  timerId: any;
  hasTimerStarted: boolean = false;
  disableBookButton: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  today = new Date();
  currentUserId: any;
  timerMinute: any;
  timerSecond: any;
  selectedService: any;
  expertsLeaves: any[];
  isExpertSelected: boolean;
  selectedExpert: any;
  isExpertOnLeave: boolean = false;
  sessionTypes: any;
  experts: any[];
  expertLabel = "Select Option";
  serviceLabel = "Select Option";
  sessionLabel = "Enter Number";
  sessionPerWeekLabel = "Select Option";
  sessionDateLabel = "DD/MM/YYYY";

  chosenStartDate: Date;
  chosenEndDate: Date;
  showSlots = false;
  slotsOfMultidays: any[];
  laggingDates: any[];
  weeklySlots: any[];
  startIndex: number = 0;
  endIndex: number = 6;
  minDate = new Date();
  maxDate:any;
  unScheduledSlots: any[];
  scheduledSlots: any[];
  scheduledSessionIds: any[];
  scheduledSessionDetails: any[];
  totalScheduledSessions: any;

  dateAndSlots: dateAndSlotsForMultiSession[];
  tempDateAndSlots: dateAndSlotsForMultiSession[];
  slotsOfSingleDate: slotForMultiSession[];
  indexArray = [];
  totalSelectedSlots: number = 0;
  totalWeeks: number = 0;
  slotSelectedPerWeek: number = 0;
  noOfSessions: number;
  sessionPerWeek: number[] = [1, 2, 3, 4, 5, 6, 7];
  frequencyPerWeek: number;
  startDateOfWeek: string;
  endDateOfWeek: string;
  parentBalance: number = 0;
  isMaximumSlots: boolean = false;
  weeklyLeaves = [];
  isExpertOnWeeklyLeave: boolean = false;
  weeklyDateRange: string;
  isDateRange = false;
  isLoading = false;
  setIntervalDemo: any;
  stepChangeSetInterval: any;
  multiSlotSetInterval: any;
  isServiceSelected: boolean = false;
  isExpertActive: boolean = true;
  showFullPaymentDetails: boolean = false;
  isBookingForFirstTime: boolean = false;

  constructor(private fb: FormBuilder,
    private ngZone: NgZone,
    private router: Router,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    public parentsService: ParentsService,
    public helper: HelperService,
    public sessionService: SessionService,
    public singleSlotService: SingleSlotAvailabilityService,
    private changeRef: ChangeDetectorRef,
    private _Activatedroute:ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // this.sessionForm = this.fb.group({
    //   formArray: this.fb.array([
    //     this.fb.group({
    //       expert: ['', Validators.required],
    //       service: ['', Validators.required],
    //       numberOfSessions: ['', Validators.required],
    //     }),
    //     this.fb.group({
    //       numberOfSessionsPerWeek: ['', Validators.required],
    //       startDate: ['', Validators.required],
    //     })
    //   ])
    // });
   

    this.parentBalance = this.parentsService.balanceDetails.currentBalance;
    console.log("Parent Balance inside ngOnInit", this.parentBalance);
    // this.parentBalance = 5;

    this.firstFormGroup = this.fb.group({
      expert: ['', Validators.required],
      service: ['', Validators.required],
      numberOfSessions: ['', [Validators.required, Validators.min(1), Validators.max(this.parentBalance)]],
      startDate: ['', Validators.required],
    });
    // this.secondFormGroup = this.fb.group({
    // numberOfSessionsPerWeek: ['', Validators.required],
    // startDate: ['', Validators.required],
    // });

    this.parentsService.getCurrentUserDetails();
    this.parentsService.getCurrentUsersBalanceDetails();
    this.getExperts();
    // this.getInvitationDetails();
    console.log(this.parentsService.balanceDetails.currentBalance,"this.parentsService.balanceDetails.currentBalance");
    this.maxDate=this.parentsService.currentUserDetails.validTill.toDate();
  }

  backToSessionPage() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    this.ngZone.run(() => this.router.navigateByUrl("parent/dashboard"))
  }

  // for any error during input
  // hasErrors(section, formControlName) {
  //   var result = false;
  //   if (formControlName in this.formArray.get([section])['controls'])
  //     result = this.formArray.get([section])['controls'][formControlName].invalid;
  //   else
  //     console.log(formControlName);
  //   return result;
  // }
  // get formArray() {
  //   return this.sessionForm.get('formArray') as FormArray;
  // }
  // getErrorMessage(section, formControlName) {
  //   var errors = this.formArray.get([section])['controls'][formControlName].errors;
  //   var errorString;
  //   if (errors['required'])
  //     errorString = "Required";
  //   return errorString;
  // }

  getExperts() {
    this.experts = this.parentsService.myExperts;
  }

  // getInvitationDetails() {
  //   let parentInvitionId = this.parentsService.currentUserDetails.interaktId;
  //   if (parentInvitionId) {
  //     let docRef = this.firestore.collection('invitations', ref => ref.where('userId', '==', parentInvitionId)).valueChanges();
  //     docRef.pipe(take(1)).subscribe(result => {
  //       let data = result[0];
  //       if (data['reccomendedFrequency']) {
  //         this.frequencyPerWeek = data['reccomendedFrequency'];
  //         console.log("Checking Frequency getInviationDetails: ", this.frequencyPerWeek);
  //       } else {
  //         this.frequencyPerWeek = 2;
  //       }
  //     });
  //   } else {
  //     this.frequencyPerWeek = 2;
  //   }
  // }


  // when expert is selected
  onExpertSelected(expert) {
    this.expertLabel = "";
    this.serviceLabel = "Select Option";

    this.expertsLeaves = [];
    let leaveStartTimeStamp = firebase.firestore.Timestamp.now();
    this.isExpertSelected = true;
    this.selectedExpert = expert;
    this.isServiceSelected = false;

    // checking the activity status of expert
    if (expert.activityStatus == "Session Disabled" || expert.activityStatus == "Session & Consultation Disabled") {
      this.isExpertActive = false;
      return;
    }

    this.isExpertActive = true;

    let leaveRef = this.firestore.collection('leaves', leaveRef => leaveRef.where('leaveBy', '==', expert.id).where('leaveStartDateTime', '>=', leaveStartTimeStamp));
    leaveRef.get().subscribe((results) => {
      if (results.empty) {
        return;
      } else {
        results.forEach((doc) => {
          let leave: any = doc.data();
          // console.log(leave);
          this.expertsLeaves.push(leave);
        })
        this.isExpertOnLeave = true;
      }
    });
   
    for (let i = 0; i < this.parentsService.myExpertsDetails.length; i++) {
      if (this.selectedExpert.id == this.parentsService.myExpertsDetails[i].educatorId) {
        this.sessionTypes = this.parentsService.myExpertsDetails[i].service;
        if (this.parentsService.myExpertsDetails[i].reccomendedFrequency) {
          this.frequencyPerWeek = this.parentsService.myExpertsDetails[i].reccomendedFrequency;
          // console.log("Used maped-frequency is: ", this.frequencyPerWeek);
        } else {
          this.frequencyPerWeek = 2;
          // console.log("Used default-frequency is: ", this.frequencyPerWeek);
        }
        break;
      }
    }
  }

  onServiceSelected(service) {
    this.isServiceSelected = true;
    this.selectedService = service;
    this.serviceLabel = '';
  }

  nextFromFirstStep(stepper: MatStepper) {
    if(this.helper.renewalPaymentDetails?.viaPaymentPage){
      this.helper.renewalPaymentDetails.viaPaymentPage=false;
    }
    this.dateAndSlots = [];
    this.dateAndSlots.length = 0;

    this.showSlots = false;
    this.isExpertOnWeeklyLeave = false;
    this.isDateRange = false;
    this.isLoading = true;
    this.slotsOfSingleDate = [];
    this.totalSelectedSlots = 0;
    this.slotSelectedPerWeek = 0;
  
    const dialogRef = this.dialog.open(stepChangeMessageDialog, {
      disableClose: true,
      panelClass: 'step-change-message-dialog',
    });
    this.calculateEndDate();
   
    this.stepChangeSetInterval = setInterval(() => {

      if (this.isLoading == false) {
        clearInterval(this.stepChangeSetInterval);
        dialogRef.close();
        stepper.next();
      }
    }, 1000);

  }

  // onSessionPerWeekSelected(event) {
  //   this.frequencyPerWeek = event;
  // }

  //Weekly pagination of the slots
  onClickPreviousWeek() {
    this.weeklySlots = [];
    if (this.startIndex > 0) {
      this.endIndex = this.startIndex - 1;
      this.startIndex = this.endIndex - 6;
    }
    this.slotsOfMultidays.forEach(slot => {
      if ((this.startIndex <= this.slotsOfMultidays.indexOf(slot)) && (this.slotsOfMultidays.indexOf(slot) <= this.endIndex)) {
        // console.log("inserted index ", this.slotsOfMultidays.indexOf(slot));
        this.weeklySlots.push(slot);
      }
    });
    this.showSelectedSlots();
    this.onWeeklyExpertLeave();
    this.onWeeklyDateRangeHeading();
  }
  onClickNextWeek() {
    // console.log('length ', this.slotsOfMultidays.length)
    this.weeklySlots = [];
    if (this.endIndex < this.slotsOfMultidays.length - 1) {
      this.startIndex = this.endIndex + 1;
      this.endIndex = this.startIndex + 6;
    }
    this.slotsOfMultidays.forEach(slot => {
      if ((this.startIndex <= this.slotsOfMultidays.indexOf(slot)) && (this.slotsOfMultidays.indexOf(slot) <= this.endIndex)) {
        // console.log("inserted index ", this.slotsOfMultidays.indexOf(slot));
        this.weeklySlots.push(slot);
      }
    });
    this.showSelectedSlots();
    this.onWeeklyExpertLeave();
    this.onWeeklyDateRangeHeading();
  }

  // For Slot Sections
  onStartDateSelected(event) {
    // this.showSlots = false;
    // this.isExpertOnWeeklyLeave = false;
    // this.isDateRange = false;
    // this.isLoading = true;
    // this.dateAndSlots = [];
    // this.slotsOfSingleDate = [];
    // this.totalSelectedSlots = 0;
    // this.slotSelectedPerWeek = 0;
    // this.chosenStartDate = event.value;
    this.chosenStartDate = new Date(event.value);
    // console.log("Start Date For Multiple Sessions Booking: ", this.chosenStartDate);
    // this.calculateEndDate();
  }
  calculateEndDate() {
    this.laggingDates = [];
    let totalSessions = this.noOfSessions;
    let frequency = this.frequencyPerWeek;
    // console.log("Checking Frequency inside calculateEndDate: ", frequency);
    // console.log("Checking Total no of sessions: ", totalSessions);
    let now = new Date();
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    let tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
    //for getting exact startDate
    if (this.chosenStartDate.getDate() === today.getDate()) {
      let startDate = new Date(this.chosenStartDate);
      let startDay = this.getDayName(this.chosenStartDate, "en");
      this.chosenStartDate.setDate(this.chosenStartDate.getDate() + 1);
      while (startDay != 'Monday') {
        const month = startDate.toLocaleString('default', { month: 'long' });
        let requiredDateFormat = startDate.getDate() + " " + month.substring(0, 3) + " " + startDay.substring(0, 3);
        this.laggingDates.push({ date: requiredDateFormat, openSlots: [] });
        startDate.setDate(startDate.getDate() - 1);
        startDay = this.getDayName(startDate, "en");
      }
      if (startDay == 'Monday') {
        const month = startDate.toLocaleString('default', { month: 'long' });
        let requiredDateFormat = startDate.getDate() + " " + month.substring(0, 3) + " " + startDay.substring(0, 3);
        this.laggingDates.push({ date: requiredDateFormat, openSlots: [] });
      }
    } else {
      let startDate = new Date(this.chosenStartDate);
      let startDay = this.getDayName(this.chosenStartDate, "en");
      if (startDay != 'Monday') {
        while ((this.chosenStartDate > tomorrow) && (this.getDayName(this.chosenStartDate, "en") != 'Monday')) {
          this.chosenStartDate.setDate(this.chosenStartDate.getDate() - 1);
          startDate.setDate(startDate.getDate() - 1);
          startDay = this.getDayName(startDate, "en");
        }
        while (startDay != 'Monday') {
          startDate.setDate(startDate.getDate() - 1);
          startDay = this.getDayName(startDate, "en");
          const month = startDate.toLocaleString('default', { month: 'long' });
          let requiredDateFormat = startDate.getDate() + " " + month.substring(0, 3) + " " + startDay.substring(0, 3);
          this.laggingDates.push({ date: requiredDateFormat, openSlots: [] });
        }
      }
    }
    // console.log("days after finding Monday=> ", this.laggingDates);
    //Getting EndDate
    if(this.maxDate){
      this.chosenEndDate = this.maxDate;
    }else{
      let dateCount = (Math.ceil((totalSessions / frequency) * 7) + 7); //(minimum dates we need to cover all the sessions + one more week)
      this.chosenEndDate = new Date(this.chosenStartDate);
      this.chosenEndDate.setDate(this.chosenEndDate.getDate() + dateCount);
     
      let endDay = this.getDayName(this.chosenEndDate, "en");
      while (endDay != 'Sunday') {
        this.chosenEndDate.setDate(this.chosenEndDate.getDate() + 1);
        endDay = this.getDayName(this.chosenEndDate, "en");
      }
      // creating chosenEndDate to show slots for minimum 3 weeks 
      let counter = 0;
      for (let i = this.chosenStartDate.getTime(); i <= this.chosenEndDate.getTime(); i += 86400000) {
        counter++;
      }
      if (counter <= 14) {
        this.chosenEndDate.setDate(this.chosenEndDate.getDate() + 7);
        // console.log("Changed chosenDate due to maintain minimum 3 weeks");
      }
    }
  //   if(this.maxDate<=this.chosenEndDate){
  //     this.chosenEndDate = this.maxDate;   
  // }
    this.onMultiDatesSelected();
  }

  getDayName(dateStr, locale) {
    return dateStr.toLocaleDateString(locale, { weekday: 'long' });
  }

  onMultiDatesSelected() {
    this.slotsOfMultidays = [];
    this.weeklySlots = [];
    // let id = "wFK3BfLKNCcxH9lxsdn4xs2cdbB3";
    let slotDuration = 60;
    if (this.chosenStartDate != null && this.chosenEndDate != null) {
      // console.log("both date selected ", this.chosenStartDate, this.chosenEndDate);
      this.sessionService.onCheckingSlotsForMultidays(this.selectedExpert.id, this.chosenStartDate, this.chosenEndDate, slotDuration);
      // this.slotsOfMultidays = await this.sessionService.onCheckingSlotsForMultidays(id, this.chosenStartDate, this.chosenEndDate, slotDuration);
      this.slotsOfMultidays = this.sessionService.openSlotsOfMultidays;
    }

    this.laggingDates.forEach(slots => {
      this.slotsOfMultidays.unshift(slots);
    });
   
    this.multiSlotSetInterval = setInterval(() => {
      let totalDates = this.sessionService.multiDaysCounter;
      // console.log("slotOfMultiDays size created for calling sessionService: ", (this.slotsOfMultidays.length - this.laggingDates.length));
      // console.log("Total dates inside onMuliDatesSelected: ", totalDates);
      if (totalDates == (this.slotsOfMultidays.length - this.laggingDates.length)) {
        clearInterval(this.multiSlotSetInterval);
        this.slotsOfMultidays.forEach(slot => {
          //inserting an additional field isSelected for select-deselect ui
          slot.openSlots.forEach(function (element) {
            element.isSelected = false;
          });
          //inserting data for a week
          if ((this.startIndex <= this.slotsOfMultidays.indexOf(slot)) && (this.slotsOfMultidays.indexOf(slot) <= this.endIndex)) {
            // console.log("inserted index ", this.slotsOfMultidays.indexOf(slot));
            this.weeklySlots.push(slot);
          }
        });
       
        this.showSlots = true;
        this.isLoading = false;
        this.onWeeklyExpertLeave(); //to get weeklyLeaves of the selected expert
        this.onWeeklyDateRangeHeading();
      }
    }, 1000)

  }


  onSelectedMultiSlots(multiOpenSlots, openSlot) {
    let slotsDateString = multiOpenSlots.date;
    let date = this.stringToDateConvert(slotsDateString,this.chosenStartDate);
    let slot = openSlot.value.split(":");
    let slotStartTime = (parseInt(slot[0], 10) * 60) + (parseInt(slot[1], 10));
    let slotDuration = 60;

    this.parentBalance = this.parentsService.balanceDetails.currentBalance;
    let balance = this.parentBalance;
    let totalSlotsSelected = this.totalSelectedSlots;
    this.isMaximumSlots = false;
    let maxSlots = this.isMaximumSlots;
    // console.log("Parent's Current Balance: ", this.parentBalance);

    // creating the unique id for blocking the slot
    let slotEndTime = slotStartTime + slotDuration;
    let slotStartTimeString = this.createTimeLabel(slotStartTime);
    let slotEndTimeString = this.createTimeLabel(slotEndTime);
    const dateString = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
    let blockSlotId = dateString + slotStartTimeString + '-' + slotEndTimeString + this.selectedExpert.id;
    // console.log("Checking blockSlotId inside onSelectedMultiSlots: ", blockSlotId);


    //for slot inserting and removing
    let FOUND = this.dateAndSlots.findIndex(function (slot, indexValue) {
      if ((slot.date.getMonth() == date.getMonth()) && (slot.date.getDate() == date.getDate())) {
        let slotIndex = slot.slotsToBeChecked.findIndex(function (time, index) {
          if (time.slotStartTime == slotStartTime) {
            console.log("Index of the found element: ", index);
            slot.slotsToBeChecked.splice(index, 1);
            maxSlots = false;
            console.log("one slot is deleted from index: ", index);
            return true;
          }
        });
        if (slotIndex == -1) {
          if (totalSlotsSelected == balance) {
            console.log("Your Balance is not sufficient to Book sessions more than ", totalSlotsSelected);
            maxSlots = true;
          } else {
            slot.slotsToBeChecked.push({ slotStartTime: slotStartTime, slotDuration: slotDuration, blockSlotId: blockSlotId });
            maxSlots = false;
            console.log("Slot is pushed");
          }
        }
        return true;
      }
    });
    if (FOUND == -1) {
      if (totalSlotsSelected == balance) {
        console.log("Your Balance is not sufficient to Book sessions more than ", totalSlotsSelected);
        maxSlots = true;
      } else {
        let demo = [{ slotStartTime: slotStartTime, slotDuration: slotDuration, blockSlotId: blockSlotId }];
        this.dateAndSlots.push({ date: date, slotsToBeChecked: demo });
        maxSlots = false;
        // console.log("Date and Slot is pushed");
      }
    }

    //for select-deselect ui where ngClass is used
    this.isMaximumSlots = maxSlots;
    if (this.isMaximumSlots == false) {
      if (openSlot.isSelected == false) {
        this.weeklySlots.forEach(a => {
          if (a.date == slotsDateString) {
            a.openSlots.forEach(element => {
              if (element.value == openSlot.value) {
                element.isSelected = true;
              }
            });
          }
        });
      } else {
        this.weeklySlots.forEach(a => {
          if (a.date == slotsDateString) {
            a.openSlots.forEach(element => {
              if (element.value == openSlot.value) {
                element.isSelected = false;
              }
            });
          }
        });
      }
    }

    this.showSelectedSlots();
  }

  stringToDateConvert(dateString, choosenDate) {
    let slotDate = dateString.split(" ");
    let now = new Date();
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let month = months.indexOf(slotDate[1]);

    let date = new Date(choosenDate.getFullYear(), month, slotDate[0], 0, 0, 0);

    if (date < now) {
      console.log("Selected date is in the past");
      date=new Date(choosenDate.getFullYear()+1, month, slotDate[0], 0, 0, 0);
    }
      return date;   
  }
  // function to create time label from minutes
  createTimeLabel(slot) {
    let slotTimeString = slot;
    let slotStartHour = Math.floor(slot / 60);
    let slotStartMinute = (slot % 60);
    if (slotStartMinute == 0) {
      slotTimeString = slotStartHour + ":" + slotStartMinute + '0';
    } else {
      slotTimeString = slotStartHour + ":" + slotStartMinute;
    }
    return slotTimeString;
  }

  showSelectedSlots() {
    // console.log("Slected Slots: ", this.dateAndSlots);
    let totalSlots = 0;
    let slotPerWeek = 0;
    let monthsOfAWeek = [];
    let datesOfAWeek = [];
    this.totalSelectedSlots = 0;
    this.slotSelectedPerWeek = 0;
    let weekObject = new Object();
    this.totalWeeks = 0;

    //counting total selected slots
    this.dateAndSlots.forEach(a => {
      totalSlots = totalSlots + a.slotsToBeChecked.length;
    });
    this.totalSelectedSlots = totalSlots;

    //counting total weeks across which the slots are selected
    this.dateAndSlots.forEach(a => {
      if (a.slotsToBeChecked.length > 0) {
        let month = a.date.toLocaleString('default', { month: 'long' });
        let Day = a.date.toLocaleDateString('locale', { weekday: 'long' });
        let requiredDateFormat = a.date.getDate() + " " + month.substring(0, 3) + " " + Day.substring(0, 3);
        let index = this.slotsOfMultidays.findIndex(x => x.date === requiredDateFormat);
        let weekval = Math.floor(index / 7); // getting start date index of that week
        if (weekObject[weekval]) {
        } else {
          weekObject[weekval] = 1;
        }
      }
    });
    this.totalWeeks = Object.keys(weekObject).length;

    //counting selected slots per week 
    this.weeklySlots.forEach(a => {
      let slotsDateString = a.date;
      let slotDate = slotsDateString.split(" ");
      datesOfAWeek.push(parseInt(slotDate[0]));
      let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let month = months.indexOf(slotDate[1]);
      monthsOfAWeek.push(month);
    });
    this.dateAndSlots.forEach(a => {
      if (datesOfAWeek.includes(a.date.getDate()) && monthsOfAWeek.includes(a.date.getMonth())) {
        slotPerWeek = slotPerWeek + a.slotsToBeChecked.length;
      }
    });
    this.slotSelectedPerWeek = slotPerWeek;
  }

  // function for weekly expert leave
  onWeeklyExpertLeave() {
    this.weeklyLeaves = [];
    let lastWeekDay = this.weeklySlots[6];
    let firstWeekDay = this.weeklySlots[0];
    let firstWeekDate = this.stringToDateConvert(firstWeekDay.date,this.chosenStartDate);
    let lastWeekDate = this.stringToDateConvert(lastWeekDay.date,this.chosenStartDate);
    let firstWeekDateStamp = firebase.firestore.Timestamp.fromDate(firstWeekDate);
    let lastWeekDateStamp = firebase.firestore.Timestamp.fromDate(lastWeekDate);
    this.isExpertOnWeeklyLeave = false;
    this.expertsLeaves.forEach(a => {
      if ((a.leaveStartDateTime >= firstWeekDateStamp) && (a.leaveStartDateTime <= lastWeekDateStamp)) {
        this.weeklyLeaves.push({ from: a.leaveStartDateTime.toDate(), to: a.leaveEndDateTime.toDate() });
        this.isExpertOnWeeklyLeave = true;
      }
    });
  }
  // to get weekly date range heading
  onWeeklyDateRangeHeading() {
    let firstDay = this.weeklySlots[0];
    let lastDay = this.weeklySlots[6];
    let firstDate = this.stringToDateConvert(firstDay.date,this.chosenStartDate);
    let lastDate = this.stringToDateConvert(lastDay.date,this.chosenStartDate);
    let firstDateString = this.getOrdinalDate(firstDate.getDate());
    let lastDateString = this.getOrdinalDate(lastDate.getDate());
    let firstMonth = firstDate.toLocaleString('default', { month: 'long' });
    let secondMonth = lastDate.toLocaleString('default', { month: 'long' });
    this.weeklyDateRange = firstDateString + " " + firstMonth.substring(0, 3) + "-" + lastDateString + " " + secondMonth.substring(0, 3);
    this.isDateRange = true;
  }
  getOrdinalDate(dateValue) {
    if ((dateValue == 11) || (dateValue == 12) || (dateValue == 13)) {
      return (dateValue + "th");
    }
    switch (dateValue % 10) {
      case 1: return (dateValue + "st");
      case 2: return (dateValue + "nd");
      case 3: return (dateValue + "rd");
      default: return (dateValue + "th");
    }
  }


  moveToThirdStep() {
    if (this.dateAndSlots.length) {
      return true;
    } else {
      return false;
    }
    return false
  }

  // function to start timer at the third step
  startTimer() {
    if (this.hasTimerStarted == false) {
      this.hasTimerStarted = true;
      let timerDuration = 180

      // interval to show timer to the user
      setInterval(() => {
        let minute = Math.floor(timerDuration / 60);
        let second = timerDuration % 60;
        this.timerMinute = minute;
        if (second >= 10) {
          this.timerSecond = second;
        } else {
          this.timerSecond = '0' + second;
        }
        timerDuration--
      }, 1000)

      // timeout function to unblock all the blocked slots when it gets over
      this.timerId = setTimeout(() => {
        let availableSlots = [];
        availableSlots = this.singleSlotService.availableSlots;

        availableSlots.forEach((doc) => {
          let delRef = this.firestore.collection('blockedslots').doc(doc.blockSlotId).delete().then(() => {
            console.log('slot deleted successfully')
          })
        })

        const dialogRef = this.dialog.open(timerRunoutDialog, {
          disableClose: true,
          panelClass: 'timer-runout-dialog',
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (!result) {
            return;
          } else if (result.event == 'Yes') {
            console.log('yayyyy')
          }
        });

      }, 180 * 1000);

    }

    // checking the availability of selected slots and blocking the available ones.
    // let parentId = this.parentsService.currentUserDetails.id;
    // this.singleSlotService.availableSlotForMultiDates(this.selectedExpert.id, this.dateAndSlots, "Yes", parentId, parentId)
  }


  // function to delete a selected slot on booking summary page
  deleteSlot(date, slotStartTimeString, slotEndTimeString) {
    const dateString = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
    let id = dateString + slotStartTimeString + '-' + slotEndTimeString + this.selectedExpert.id;

    let ref = this.firestore.collection('blockedslots').doc(id).delete();
  }


  backFromThirdStep() {
    this.tempDateAndSlots = [];
    this.tempDateAndSlots.length = 0;
  }

  // function to save sessions.
  async saveSessions() {
    // clearing the timer function
    clearTimeout(this.timerId);
    this.disableBookButton = true;
    this.unScheduledSlots = [];
    this.scheduledSlots = [];
    this.scheduledSessionIds = [];
    this.scheduledSessionDetails = [];
    this.totalScheduledSessions = 0;

    let parentBalance = this.parentsService.balanceDetails.currentBalance;
    let availableSlots = [];
    availableSlots = this.singleSlotService.availableSlots;
    let multiSessionBatchId = this.helper.uuidv4();
    let slotsLeftToBeOperatedOn = availableSlots.length;

    if (parentBalance < availableSlots.length) {
      console.log('insufficient balance');
      return;
    }

    this.checkFirstSessionBooking();

    console.log(this.isBookingForFirstTime,"isBookingForFirstTime");

    let sessionValue = 1.00;
    let session = {};

    const createdOn = firebase.firestore.FieldValue.serverTimestamp();
    const createdBy = firebase.auth().currentUser.uid;
    const createdByName = this.parentsService.currentUserDetails.fullName;
    const childName = this.parentsService.currentUserDetails.childName;


    availableSlots.forEach(async (doc) => {
      let slotId = doc.blockSlotId;
      let topicID = this.helper.uuidv4();
      let id = doc.id;

      // running scheduler function
      await firebase.firestore().runTransaction(async (transaction) => {
        // reference to the scheduled session for this slot.

        let sessionRef = firebase.firestore().collectionGroup('sessions');
        const queryRef = sessionRef.where('slotId', '==', slotId).where('status', 'in', ['Scheduled', 'Session Request']).orderBy('sessionEndDateTime', 'asc').limit(1)

        await queryRef.get().then((sessionDoc) => {
          // if the session for same slot is not booked already.
          if (sessionDoc.empty) {
            session['id'] = id;
            session['slotId'] = slotId;
            session['topicID'] = topicID;
            session['sessionValue'] = sessionValue;

            session['createdOn'] = createdOn;
            session['sessionEndDateTime'] = doc.sessionEndDateTime;
            session['sessionDate'] = doc.sessionDate;
            session['startTime'] = doc.startTime;
            session['endTime'] = doc.endTime;
            session['slotDuration'] = doc.slotDuration;

            session['createdBy'] = createdBy;
            session['createdByName'] = createdByName;
            session['bookedBy'] = firebase.firestore.FieldValue.arrayUnion(createdBy);

            session['attendedBy'] = { parent: false, expert: false, admin: false }

            session['createdWith'] = this.selectedExpert.id;
            session['createdWithName'] = this.selectedExpert.fullName;

            session['sessionExpertId'] = this.selectedExpert.id;
            session['sessionExpertName'] = this.selectedExpert.fullName;

            session['sessionStudentId'] = createdBy;
            session['sessionStudentName'] = childName;
            session['sessionParentName'] = createdByName;

            session['parentInteraktId'] = this.parentsService.currentUserDetails.interaktId;
            session['expertInteraktId'] = this.selectedExpert.interaktId;

            session['status'] = 'Scheduled';
            session['expertPaid'] = false;

            session['serviceType'] = this.selectedService;
            session['bookedWithMultipleSessions'] = true;
            session['multiSessionBatchId'] = multiSessionBatchId;

            console.log(doc.sessionDate,"doc.sessionDate");
            const newDate=doc.sessionDate;
            const timeinMiliSec = this.helper.getMondayInMiliSeconds(newDate);
            const scheduledRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(id);
            // let scheduledRef = firebase.firestore().collection('sessions');
            transaction.set(scheduledRef.ref, session);

            this.totalScheduledSessions += 1;
            this.scheduledSlots.push(doc);
            this.scheduledSessionIds.push(doc.id);
            this.scheduledSessionDetails.push({ date: doc.sessionDate, startTime: doc.startTime, endTime: doc.endTime })
          } else {
            this.unScheduledSlots.push(doc);
          }
        })
      }).then(() => {
        console.log('session created successfully');
        slotsLeftToBeOperatedOn -= 1;
        if (slotsLeftToBeOperatedOn == 0) {
          this.clearData(availableSlots);
          this.onSessionsBooked();
        }
      }).catch((error) => {
        console.error("Error creating session ", error);
        slotsLeftToBeOperatedOn -= 1;
        if (slotsLeftToBeOperatedOn == 0) {
          this.clearData(availableSlots);
          this.onSessionsBooked();
        }
      });

    })

  }

  // function to clear the blocked and local variables.
  clearData(availableSlotsArray: any) {
    let avaiableSlots: any[];
    avaiableSlots = [];
    avaiableSlots = availableSlotsArray;

    // deleting the blocked slots
    avaiableSlots.forEach((doc) => {
      let ref = this.firestore.collection('blockedslots').doc(doc.blockSlotId).delete().then(() => {
        console.log(doc.blockSlotId, 'deleted successfully')
      }).catch(err => console.log('error deleting blocked slot for ', doc.blockSlotId, err))
    })

    // clearing all the local storage data
    this.dateAndSlots = [];
    this.dateAndSlots.length = 0;
    this.tempDateAndSlots = [];
    this.tempDateAndSlots.length = 0;
    this.indexArray = [];
    this.indexArray.length = 0;
    this.singleSlotService.availableSlots = [];
    this.singleSlotService.availableSlots.length = 0;
    this.singleSlotService.unavailableSlots = [];
    this.singleSlotService.unavailableSlots.length = 0;
  }

  // function after sessions get booked
  async onSessionsBooked() {
    // creating a transaction for total booked sessions
    let data: any = {};
    const currentTimeStamp: any = firebase.firestore.Timestamp.now();
    data.userId = this.parentsService.currentUserDetails.id;
    data.reason = 'Scheduled by Parent';
    data.status = 'Successfull';
    data.sessionId = this.scheduledSessionIds;
    data.scheduledSessionsDetails = this.scheduledSessionDetails;
    data.parentId = this.parentsService.currentUserDetails.id;
    data.returnedSessionValue = 0;
    data.sessionExpertName = this.selectedExpert.fullName
    data.transactionType = 'session';
    data.transactionValue = -(this.totalScheduledSessions);
    data.cretedViaMultipleBookings = true;
    data.createdAt = currentTimeStamp;
    await this.firestore.collection('transactions').add(data).then(() => {
      console.log('transaction created successfully');
      var message = this.totalScheduledSessions +' sessions has been scheduled successfully.';
      this.toastr.success(message, 'New Session Confirmed!');
    }).catch(err => {
      console.log('error creating the transaction ', err);
    });

    if(this.isBookingForFirstTime){
      this.addPackValidity();
    }

    this.scheduledSlots.sort(function (x, y) {
      return x.sessionEndDateTime - y.sessionEndDateTime
    });


    // calling the notification function
    this.sendNotifications();
   
  }

 checkFirstSessionBooking(){
    let sessionRef = firebase.firestore().collectionGroup('sessions');
    const queryRef = sessionRef.where('sessionStudentId', '==', this.parentsService.currentUserDetails.id);
    queryRef.get().then(result=>{
        console.log(result.size,"size");
        if(result.size<1){
          this.isBookingForFirstTime= true;
        }
    }) 
  }
  addPackValidity(){
      let validity;
      let date=new Date();
      if(this.parentsService.currentUserDetails.numberOfSessions===24){ 
        validity=180;
        console.log(validity,"validity");
        let validTill=moment(date).add(validity, 'days').toDate();
        let gracePeriod=moment(validTill).add(30, 'days').toDate();
        console.log(validTill,"validTill",gracePeriod);
        let userRef=this.firestore.collection("users").doc(this.parentsService.currentUserDetails.id).update({"validTill":validTill,"gracePeriod":gracePeriod}).then(result=>{
          console.log('valid till added successfully');
        }).catch(err => {
          console.log("error",err);
        })
      }else if(this.parentsService.currentUserDetails.numberOfSessions===36){
        validity=270;
        console.log(validity,"validity");
        let validTill=moment(date).add(validity, 'days').toDate();
        let gracePeriod=moment(validTill).add(30, 'days').toDate();
        console.log(validTill,"validTill",gracePeriod);
        let userRef=this.firestore.collection("users").doc(this.parentsService.currentUserDetails.id).update({"validTill":validTill,"gracePeriod":gracePeriod}).then(result=>{
          console.log('valid till added successfully');
        }).catch(err => {
          console.log("error",err);
        })
      }else if(this.parentsService.currentUserDetails.numberOfSessions===48){
        validity=360;
        console.log(validity,"validity"); 
        let validTill=moment(date).add(validity, 'days').toDate();
        let gracePeriod=moment(validTill).add(30, 'days').toDate();
        console.log(validTill,"validTill",gracePeriod);
        let userRef=this.firestore.collection("users").doc(this.parentsService.currentUserDetails.id).update({"validTill":validTill,"gracePeriod":gracePeriod}).then(result=>{
          console.log('valid till added successfully');
        }).catch(err => {
          console.log("error",err);
        })
      }else{
        let inviteRef = this.firestore.collection('invitations', leaveRef => leaveRef.where('eMail', '==', this.parentsService.currentUserDetails.email));
        inviteRef.get().subscribe((results) => {
          if (results.empty) {
            return;
          } else {
            results.forEach((doc) => {
              let invite: any = doc.data();
              console.log(invite.validity);
              validity=invite.validity;
              console.log(validity,"validity");
              let validTill=moment(date).add(validity, 'days').toDate();
              let gracePeriod=moment(validTill).add(30, 'days').toDate();
              console.log(validTill,"validTill",gracePeriod);
              let userRef=this.firestore.collection("users").doc(this.parentsService.currentUserDetails.id).update({"validTill":validTill,"gracePeriod":gracePeriod}).then(result=>{
                console.log('valid till added successfully');
              }).catch(err => {
                console.log("error",err);
              } )
            })
          }
        });
      }
      
  }
  async sendNotifications() {
    let firstSession: any = this.scheduledSlots[0];
    let date = new Date(firstSession.date);
    console.log('date during ntification ', date)
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    let body1: any = {};

    // notification for experts for successfully booked sessions
    let body2 = {
      'userId': this.selectedExpert.interaktId,
      'event': 'multi sessions booked by parent - notify expert',
      'traits': {
        'service': this.selectedService,
        'childName': this.parentsService.currentUserDetails.childName,
        'totalSessions': this.totalScheduledSessions,
        'date': dateString,
        'time': firstSession.startTime + ' IST',
        'link': 'https://connect.mykinderpass.com/expert/dashboard',
      }
    }

    if (this.unScheduledSlots.length == 0 && this.scheduledSlots.length != 0) {
      // notification for parents for successfully booked sessions
      body1 = {
        'userId': this.parentsService.currentUserDetails.interaktId,
        'event': 'multi sessions booked by parent - notify parent',
        'traits': {
          'totalSessions': this.totalScheduledSessions,
          'service': this.selectedService,
          'expertName': this.selectedExpert.fullName,
          'date': dateString,
          'time': firstSession.startTime + ' IST',
          'link': 'https://connect.mykinderpass.com/parent/dashboard',
        }
      }

      // sending the notification to parent.
      await this.notificationService.sendWhatsappNotification(body1);

      // sending the notification to expert.
      await this.notificationService.sendWhatsappNotification(body2);

    } else if (this.unScheduledSlots.length != 0 && this.scheduledSlots.length != 0) {
      // notification for parents for successfully booked sessions and unbooked slots.
      body1 = {
        'userId': this.parentsService.currentUserDetails.interaktId,
        'event': 'multi sessions booked with some unavailable slots by parent - notify parent',
        'traits': {
          'totalSessions': this.totalScheduledSessions,
          'service': this.selectedService,
          'expertName': this.selectedExpert.fullName,
          'date': dateString,
          'time': firstSession.startTime + ' IST',
          'unavailableSlots': this.unScheduledSlots.length,
          'link': 'https://connect.mykinderpass.com/parent/dashboard',
        }
      }

      // sending the notification to parent.
      await this.notificationService.sendWhatsappNotification(body1);

      // sending the notification to expert.
      await this.notificationService.sendWhatsappNotification(body2);
    }

    // taking user back to dashboard
    this.backToDashboard();
  }

  // function for taking user back to dashboard
  backToDashboard() {
    if (this.unScheduledSlots.length == 0) {
      // back to parent dashboard
      this.ngZone.run(() => this.router.navigateByUrl("parent/dashboard"));
    } else {
      const dialogRef = this.dialog.open(sessionNotBookedDialog, {
        disableClose: true,
        panelClass: 'timer-runout-dialog',
        data: { unScheduledSlots: this.unScheduledSlots, totalScheduledSessions: this.totalScheduledSessions }
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (!result) {
          return;
        } else if (result.event == 'Yes') {
          // back to parent dashboard
          this.ngZone.run(() => this.router.navigateByUrl("parent/dashboard"));
        }
      });
    }
  }

  showLoadingMessage(stepper: MatStepper) {
    const dialogRef = this.dialog.open(loadingMessageDialog, {
      disableClose: true,
      panelClass: 'loading-message-dialog',
    });

    //Creating temporary dateAndSlot Array for exact slots to send in 3rd step
    this.tempDateAndSlots = [];
    let tempDateAndSlot = this.tempDateAndSlots;
    let avaiableSlotsOfSummary = [];
    avaiableSlotsOfSummary = this.singleSlotService.availableSlots;
    let unavaiableSlotsOfSummary = [];
    unavaiableSlotsOfSummary = this.singleSlotService.unavailableSlots;
    this.dateAndSlots.forEach((val) => {
      let slotIndex = val.slotsToBeChecked.findIndex(function (time, index) {
        let foundATime = avaiableSlotsOfSummary.findIndex(function (a, aIndex) {
          if (time.blockSlotId == a.blockSlotId) {
            return true;
          }
        });
        if (foundATime == -1) {
          let foundBTime = unavaiableSlotsOfSummary.findIndex(function (b, bIndex) {
            if (time.blockSlotId == b.blockSlotId) {
              return true;
            }
          });
          if (foundBTime == -1) {
            //here inserting slots in temporary dateAndSlot array which are not present in available and unavailable array
            let FOUND = tempDateAndSlot.findIndex(function (slot, indexValue) {
              if ((slot.date.getMonth() == val.date.getMonth()) && (slot.date.getDate() == val.date.getDate())) {
                slot.slotsToBeChecked.push(time);
                console.log("Slot is pushed inside tempDateAndSlot");
                return true;
              }
            });
            if (FOUND == -1) {
              console.log('slot value ', time.slotStartTime, time.slotDuration);
              let demo = [{ slotStartTime: time.slotStartTime, slotDuration: time.slotDuration, blockSlotId: time.blockSlotId }];
              tempDateAndSlot.push({ date: val.date, slotsToBeChecked: demo });
              // console.log("Date and Slot is pushed inside tempDateAndSlot");
            }
          }
        }
      });
    });
    // console.log("Checking dateAndSlots: ", this.dateAndSlots);
    // console.log("Checking tempDateAndSlots: ", tempDateAndSlot);
    //deleting Deselected slots from blockSlots collection in database and available and unavailable array
    //deleting from availableSlots array and blockSlots collection
    let dateAndSlot = [];
    dateAndSlot = this.dateAndSlots;
    let indexArray = [];
    indexArray = this.indexArray;
    let slotIndex = this.singleSlotService.availableSlots.findIndex(function (time, index) {
      let indexVal = dateAndSlot.findIndex(function (a, insideIndex) {
        let indexReturn = a.slotsToBeChecked.findIndex((b, indexCount) => {
          if (time.blockSlotId == b.blockSlotId) {
            return true;
          }
        });
        if (indexReturn != -1) {
          return true;
        }
      });
      if (indexVal == -1) {
        indexArray.push({ index: index, id: time.blockSlotId });
      }
    });
    indexArray.forEach((element) => {
      let indexReturn = this.singleSlotService.availableSlots.findIndex((a, indexCount) => {
        if (element.id == a.blockSlotId) {
          this.singleSlotService.availableSlots.splice(indexCount, 1);
          console.log("One deselected slot is deleted from AvailableSlots Array where index is: ", indexCount);
          let ref = this.firestore.collection('blockedslots').doc(a.blockSlotId).delete();
          console.log("That deselected slot is also deleted from blockedSlots collections");
          return true;
        }
      });
    });
    //deleting element from unavailableSltos array
    let slotIndexs = this.singleSlotService.unavailableSlots.findIndex(function (time, index) {
      let indexVal = dateAndSlot.findIndex(function (a, insideIndex) {
        let indexReturn = a.slotsToBeChecked.findIndex((b, indexCount) => {
          if (time.blockSlotId == b.blockSlotId) {
            return true;
          }
        });
        if (indexReturn != -1) {
          return true;
        }
      });
      if (indexVal == -1) {
        indexArray.push({ index: index, id: time.blockSlotId });
      }
    });
    indexArray.forEach((element) => {
      let indexReturn = this.singleSlotService.unavailableSlots.findIndex((a, indexCount) => {
        if (element.id == a.blockSlotId) {
          this.singleSlotService.unavailableSlots.splice(indexCount, 1);
          console.log("One deselected slot is deleted from UnavailableSlots Array where index is: ", indexCount);
          return true;
        }
      });
    });
    // checking the availability of selected slots and blocking the available ones.
    let parentId = this.parentsService.currentUserDetails.id;
    this.singleSlotService.availableSlotForMultiDates(this.selectedExpert.id, tempDateAndSlot, "Yes", parentId, parentId)
    //counting total no of slots in dateAndSlots Array
    let totalSltos = 0;
    this.dateAndSlots.forEach((a) => {
      totalSltos = totalSltos + a.slotsToBeChecked.length;
    });
    //Checking if available and unavailable array is ready to go next stepper(3rd section)
    this.setIntervalDemo = setInterval(() => {
      let dateAndSlots = [];
      dateAndSlots = this.dateAndSlots;
      let avaiableSlots = [];
      avaiableSlots = this.singleSlotService.availableSlots;
      let unavailableSlots = [];
      unavailableSlots = this.singleSlotService.unavailableSlots;
      // console.log("Total Slots inside showLoadingMessage: ", totalSltos);
      // console.log("Total available and unavailable Slots inside showLoadingMessage: ", (avaiableSlots.length + unavailableSlots.length));
      if (totalSltos == (avaiableSlots.length + unavailableSlots.length)) {
        clearInterval(this.setIntervalDemo);
        dialogRef.close();
        stepper.next();
        this.startTimer();
      }
    }, 1000)
  }

  onPaymentDetailsClicked(){
    console.log('getting called')
    this.showFullPaymentDetails = true;
    this.changeRef.detectChanges();
  }

  hidePaymentDetails(){
    this.showFullPaymentDetails = false;
    this.changeRef.detectChanges();
  }

}



@Component({
  selector: 'timer-runout-dialog',
  template: `
  <div style="float: right; z-index:1; cursor: pointer; margin-right: 5px; margin-top: 5px"><mat-icon (click)="closeDialog()">close</mat-icon></div>
  <div class="timerRunOutDialogContainer">
    <mat-icon style="color: #F7524B; margin-bottom: -44px; font-size: 45px; margin-right: 39px">timelapse</mat-icon>
    <mat-icon style="color: #F7524B; margin-left: -4px; font-size: 45px;">warning_amber</mat-icon>
    <h2 style="text-align: center; font-weight: 400;">Uh Oh.</h2>
    <p style="text-align: center; color: #686B73;">Your slot selection has expired. <br> Please book again.</p>
    <button style="background-color: #F7524B; color: white; width: 115px; height: 35px; border-radius: 10px;" (click)="bookAgain()" mat-button>Book Again</button>
  </div>
`,
  styleUrls: ['./multi-session.component.css']
})

export class timerRunoutDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<timerRunoutDialog>,
    private router: Router,
    private ngZone: NgZone,
    private singleSlotService: SingleSlotAvailabilityService) { }

  ngOnInit() { }

  bookAgain() {
    this.singleSlotService.availableSlots = [];
    this.singleSlotService.availableSlots.length = 0;
    this.singleSlotService.unavailableSlots = [];
    this.singleSlotService.unavailableSlots.length = 0;
    this.ngZone.run(() => this.router.navigateByUrl("parent/dashboard")).then(() => this.router.navigateByUrl("parent/multiple-session")).then(() => this.dialogRef.close({ event: "Yes" }));
  }

  closeDialog() {
    this.singleSlotService.availableSlots = [];
    this.singleSlotService.availableSlots.length = 0;
    this.singleSlotService.unavailableSlots = [];
    this.singleSlotService.unavailableSlots.length = 0;
    this.ngZone.run(() => this.router.navigateByUrl("parent/dashboard")).then(() => this.dialogRef.close({ event: "No" }));
  }

  ngOnDestroy() { };
}



@Component({
  selector: 'session-not-booked-dialog',
  template: `
  <div class="sessionNotBookedDialogContainer">
    <mat-icon style="color: #F7524B; height: 75px; width: 60px; font-size: 56px;">event_busy</mat-icon>
    <h2 style="text-align: center; font-weight: 450; line-height: 22px;">Some selected slots could not be booked.</h2>
    <p style="text-align: center; font-weight: bold; color: #686B73; margin-bottom: 0px;">We have booked {{data.totalScheduledSessions}} slots.</p>
    <p style="text-align: center; color: #686B73;">However the following slots were unavailable at the time and could not be booked.</p>
    <div style="border-bottom: 0.6px solid #DCDCDC; width: 90%;"></div>

		<div style="color: #686B73;" *ngFor="let unScheduledSlot of data.unScheduledSlots" class="sessionNotBookedSlots">
      <div style="display: flex; justify-content: space-between; width: 100%;">
			  <span style="color: #686B73;">{{unScheduledSlot.date | date:'fullDate'}}</span>
			  {{unScheduledSlot.label}}
      </div>
    </div>
    <div style="border-bottom: 0.6px solid #DCDCDC; width: 90%;"></div>

    <button style="background-color: #F9F9F9; color: #F7524B; width: 169px; height: 35px; border-radius: 10px; margin-top: 30px;" (click)="backToDashboard()" mat-button>Back To Dashboard</button>
  </div>
`,
  styleUrls: ['./multi-session.component.css']
})

export class sessionNotBookedDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<sessionNotBookedDialog>,
    private router: Router,
    private ngZone: NgZone,
    private singleSlotService: SingleSlotAvailabilityService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }

  backToDashboard() {
    this.dialogRef.close({ event: "Yes" })
  }

  ngOnDestroy() { };
}


@Component({
  selector: 'loading-message-dialog',
  template: `
  <div class="text-center my-4" style="margin-top: 35px; margin-bottom: 20px;">
							<div class="lds-spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
							<div>
								<h3 style="color: #A5A5A5">
                We are checking the availability<br>of the slots you have selected.<br>Please give us a minute.
                </h3>
							</div>
						</div>
`,
  styleUrls: ['./multi-session.component.css']
})

export class loadingMessageDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<loadingMessageDialog>) { }

  ngOnInit() { }

  ngOnDestroy() { };
}


@Component({
  selector: 'step-change-message-dialog',
  template: `
  <div class="text-center my-4" style="margin-top: 35px; margin-bottom: 20px;">
							<div class="lds-spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
							<div>
								<h3 style="color: #A5A5A5">
                Please wait..<br>Checking your available slots as<br>per your requirement.
                </h3>
							</div>
						</div>
`,
  styleUrls: ['./multi-session.component.css']
})

export class stepChangeMessageDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<stepChangeMessageDialog>) { }

  ngOnInit() { }

  ngOnDestroy() { };
}


interface dateAndSlotsForMultiSession {
  date: Date;
  slotsToBeChecked: slotForMultiSession[];
}

interface slotForMultiSession {
  slotStartTime: number;
  slotDuration: number;
  blockSlotId: string;
}