<div class="wrapper">
  <!-- <div class="display-2" *ngIf="verified===false">
        <span>You have already registered and verification is ongoing. Kindly wait for the verification email.</span>
    </div> -->

  <!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center"> -->
  <!-- <mat-card class="content-container" *ngIf="!verified">
            <mat-card-header>
                <div mat-card-avatar></div>
                <mat-card-title>Awaiting Verification</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                You are already registered but awaiting verification.
            </mat-card-content>
        </mat-card> -->
  <!-- </div> -->
  <div class="signin-left-side">
    <div class="imgDiv">
      <!-- <img src="/assets/lookingparent.png" alt="" id="lookingParent"> -->
      <div class="left-side-content">
        <h1 id="left-side-content-header">Tactopus is now KinderPass!</h1>
        <div class="left-side-content-text">
          <p>Start scheduling your child’s sessions with your KinderPass experts by signing-in with your <b
              id="boldText">invited phone number</b>.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="signin-right-side">
    <!-- <firebase-ui (signInSuccessWithAuthResult)="successCallback($event)" (signInFailure)="errorCallback($event)">
          </firebase-ui> -->
    <div class="authContainer">
      <div class="authHeading" *ngIf="!OTPSent">
        <mat-card-title>
          <h1 style="font-weight: 600; color: rgba(0, 0, 0, 0.72); font-size: 21px;">Please enter your invited phone
            number</h1>
        </mat-card-title>
        <p style="color:rgba(0, 0, 0, 0.6); font-size: 16px;"> You would have received an invite on WhatsApp from our Team
          detailing your invited credentials.</p>
      </div>
      <div class="authHeading" *ngIf="OTPSent">
        <mat-card-title>
          <h1 style="font-weight: 600; color: rgba(0, 0, 0, 0.72); font-size: 21px;">An OTP has been sent to your number
          </h1>
        </mat-card-title>
        <p style="color:rgba(0, 0, 0, 0.6); font-size: 16px;">Please enter the 6 digit code sent to the number: {{
          phoneNo }}</p>
      </div>
      <br>
      <div class="authContent" *ngIf="!OTPSent">
        <mat-form-field>
          <input matInput ng2TelInput (countryChange)="onPhoneNumberCountryChange($event)"
            [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
            autocomplete="off" #phoneNumberCtrl>
        </mat-form-field>
        <br>
        <button mat-button color="warn" id="continueBtn" (click)="sendOTP()">CONTINUE</button>
      </div>
      <div class="authContent" *ngIf="OTPSent">
        <!-- <input type="number" matInput #otp id="OTPField"> -->
        <ng-otp-input [config]="{length: 6, allowNumbersOnly: true}" (onInputChange)="checkOTP($event)"></ng-otp-input>
        <br>
        <button mat-button color="warn" id="continueBtn" (click)="verifyOTP(OTP)"
          [disabled]="OTP?.length !== 6">CONTINUE</button>

        <button mat-button color="warn" (click)="OTPSent = false;">Back</button>
        <br>
        <a (click)="resendOTP()" id="resendBtn">Resend Code</a>
      </div>
    </div>
  </div>
</div>

<div id="sign-in-recaptcha"></div>