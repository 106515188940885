import { Component, OnInit } from '@angular/core';
import { FeedbackCompletedComponent } from '../feedback-completed/feedback-completed.component';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.css']
})
export class FeedbackPageComponent implements OnInit {
  // dialog: any;
  isLoading = true;
  feedback: {};
  comment: string;
  improvement_fields: string[] = [];;
  expertId: string;
  parentId: string;
  sessionId: string;
  session: any;
  feedbackFlag = 0;
  formValidationCheck = 0;
  dialogRef: any;
  timeSlotFlag = 0;
  servicesFlag = 0;
  punctualityFlag = 0;
  communicationFlag = 0;
  transparencyFlag = 0;
  todayDate = new Date();
  constructor(
    private firestore: AngularFirestore,
    private adminService: AdminService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    public helper: HelperService,
    private notificationService: NotificationService
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.sessionId = this.route.snapshot.params['sessionId'],
      this.expertId = this.route.snapshot.params['expertId'],
      this.parentId = this.route.snapshot.params['parentId']

    await this.getSession();
  }

  async getSession() {
    let ref = this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",this.sessionId));
    ref.get().subscribe(this.onSessionResults);
  }
  onSessionResults = (results) => {
    this.session = [];
    if (!results.empty) {
      this.session.push(results.doc[0].data());
    }
    this.isLoading = false;
    if ((this.parentId && this.session[0].feedback.parent.feedback != '')||(this.todayDate>(this.session[0].feedback.parent.expiryTime).toDate())) {
      this.router.navigateByUrl('/parent/dashboard');
    }
    else if ((this.expertId && this.session[0].feedback.expert.feedback != '')||(this.todayDate>(this.session[0].feedback.expert.expiryTime).toDate())) {
      this.router.navigateByUrl('/expert/dashboard');
    }
  }

  Setfeedback(value: string, id) {
    if (this.feedbackFlag == 1) {
      var btns = document.getElementsByClassName("smiley");
      for (var i = 0; i < btns.length; i++) {
        btns[i].classList.remove("active");
      }
    }
    var btn = document.getElementById(id);
    btn.classList.add("active");
    this.feedbackFlag = 1;
    this.formValidationCheck = 0;
    this.feedback = value;
  }

  setImprovementField(value: string, id) {
    var btn = document.getElementById(id);
    if (value == 'Time Slots' && this.timeSlotFlag == 0) {
      this.timeSlotFlag = 1;
      this.improvement_fields.push(value);
      btn.classList.add("active-checkbox");

    }
    else if (value == 'Time Slots' && this.timeSlotFlag == 1) {
      btn.classList.remove("active-checkbox");
      this.timeSlotFlag = 0;
      this.improvement_fields = this.improvement_fields.filter(function (item) {
        return item !== value;
      })
    }
    else if (value == 'Services' && this.servicesFlag == 0) {
      btn.classList.add("active-checkbox");
      this.servicesFlag = 1;
      this.improvement_fields.push(value);
    }
    else if (value == 'Services' && this.servicesFlag == 1) {
      btn.classList.remove("active-checkbox");
      this.servicesFlag = 0;
      this.improvement_fields = this.improvement_fields.filter(function (item) {
        return item !== value;
      })
    }
    else if (value == 'Punctuality' && this.punctualityFlag == 0) {
      btn.classList.add("active-checkbox");
      this.punctualityFlag = 1;
      this.improvement_fields.push(value);
    }
    else if (value == 'Punctuality' && this.punctualityFlag == 1) {
      btn.classList.remove("active-checkbox");
      this.punctualityFlag = 0;
      this.improvement_fields = this.improvement_fields.filter(function (item) {
        return item !== value;
      })
    }
    else if (value == 'Communication' && this.communicationFlag == 0) {
      btn.classList.add("active-checkbox");
      this.communicationFlag = 1;
      this.improvement_fields.push(value);
    }
    else if (value == 'Communication' && this.communicationFlag == 1) {
      btn.classList.remove("active-checkbox");
      this.communicationFlag = 0;
      this.improvement_fields = this.improvement_fields.filter(function (item) {
        return item !== value;
      })
    }
    else if (value == 'Transparency' && this.transparencyFlag == 0) {
      btn.classList.add("active-checkbox");
      this.transparencyFlag = 1;
      this.improvement_fields.push(value);
    }
    else if (value == 'Transparency' && this.transparencyFlag == 1) {
      btn.classList.remove("active-checkbox");
      this.transparencyFlag = 0;
      this.improvement_fields = this.improvement_fields.filter(function (item) {
        return item !== value;
      })
    }
  }

  submit(Comment: string) {
    if (this.feedbackFlag == 0) {
      this.formValidationCheck = 1;
      return;
    }
    this.comment = Comment;
    let expertId = this.expertId;
    let parentId = this.parentId;
    let sessionId = this.sessionId;
;
    if (expertId) {
      const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.session.sessionDate.toDate());
      const docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(sessionId);
      docRef.update({
        "feedback.expert.feedback": this.feedback,
        "feedback.expert.comment": this.comment,
        "feedback.expert.improvement_fields": this.improvement_fields,
      }).then(() => {
        const dialogRef = this.dialog.open(FeedbackCompletedComponent, { panelClass: 'custom-dialog-container' });
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigateByUrl("/expert/dashboard");
        })
      }).catch(err => console.log(err))
    }
    else if (parentId) {
      const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.session.sessionDate.toDate());
      const docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(sessionId);
      docRef.update({
        "feedback.parent.feedback": this.feedback,
        "feedback.parent.comment": this.comment,
        "feedback.parent.improvement_fields": this.improvement_fields,
      }).then(() => {
        if (this.feedback == '1 star' || this.feedback == '2 star') {
          let rating: string;
          if(this.feedback=='1 star')
            rating="1";
          else 
            rating="2";
          let expertRef = this.firestore.collection('users', expertRef => expertRef.where('role', '==', 'support'));
          expertRef.get().subscribe((snapshot) => {
            if (snapshot.empty) {
                // console.log('no Ongoing sessions found')
                return;
            }
            const promises: Promise<any>[] = [];
            snapshot.forEach(async (doc) => {
                let user: any = doc.data();
                if (user.interaktId && user.interaktId !== '') {
                    let body1: any = {
                        'userId': user.interaktId,
                        'event': 'Bad rating report to support',
                        'traits': {
                          'parent_name': this.session[0].sessionParentName,
                          'service': this.session[0].serviceType,
                          'expert_name': this.session[0].sessionExpertName,
                          'rating': rating,
                          'comment_entered_by_parent': this.comment
                        },
                    }
                    this.notificationService.sendWhatsappNotification(body1);

                }
            })
        })
        }
        const dialogRef = this.dialog.open(FeedbackCompletedComponent, {
          panelClass: 'custom-dialog-container'
        });
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigateByUrl("/parent/dashboard");
        })
      }).catch(err => console.log(err))
    }
    else {
      console.log("Neither expert nor parent");
    }

  }



}
