<div class="heading">
  <a matButton id="back-button" (click)="dialogRef.close()">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <h1 mat-dialog-title>Invite new Parent</h1>
</div>

<mat-dialog-content class="mat-typography dialog-content">
  <form [formGroup]="invitationForm">
    <mat-card class="inviteCard">
      <mat-form-field class="full-width-field">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" autocomplete="off" required #nameCtrl>
      </mat-form-field>

      <mat-form-field class="full-width-field">
        <mat-label>Email Id</mat-label>
        <input matInput formControlName="eMail" autocomplete="off" required #eMailCtrl>
      </mat-form-field>

      <mat-form-field class="full-width-field">
        <mat-label class="contactLabel">Phone Number</mat-label>
        <input matInput ng2TelInput (countryChange)="onPhoneNumberCountryChange($event)"
          [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
          formControlName='phoneNumber' type="text" pattern="^([1-9]{1}[0-9]{7}|[1-9]{1}[0-9]{8}|[1-9]{1}[0-9]{9})$"
          required autocomplete="off" #phoneNumberCtrl>
      </mat-form-field>

      <mat-form-field class="full-width-field">
        <mat-label class="contactLabel">Whatsapp Number</mat-label>
        <input matInput ng2TelInput (countryChange)="onWhatsappNumberCountryChange($event)"
          [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
          formControlName='whatsappNumber' type="text" pattern="^([1-9]{1}[0-9]{7}|[1-9]{1}[0-9]{8}|[1-9]{1}[0-9]{9})$"
          required autocomplete="off" #whatsappNumberCtrl>
      </mat-form-field>

      <!-- adding expert details -->
      <mat-card-title style="padding-bottom: 10px !important; padding-top: 10px !important; margin: 0 !important;">
        <h2 style="margin: 0;">Experts</h2>
      </mat-card-title>
      <div [formGroupName]="'educators'">
        <div *ngFor="let educator of educators.controls; let i=index" formArrayName="{{i}}">
          <mat-card class="educatorCard">
            <mat-card-subtitle>
              <span class="left">Expert {{i + 1}}</span>
              <span class="right">
                <button mat-button type="button" (click)="deleteEducator(i)" color="warn">Delete</button>
              </span>
            </mat-card-subtitle>
            <mat-form-field class="full-width-field">
              <mat-label>Expert ID</mat-label>
              <mat-select matInput #educatorIdCtrl formControlName="educatorId" required>
                <mat-option *ngFor="let expert of experts" value="{{expert.id}}"
                  (click)="onExpertIdSelected(expert.id, i)">{{expert.fullName}}-{{expert.id}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width-field">
              <mat-label>Expert Name</mat-label>
              <!-- <mat-select matInput #educatorNameCtrl formControlName="educatorName" disabled>
                                      <mat-option *ngFor="let expert of experts" value="{{expert.fullName}}">{{expert.fullName}}</mat-option>
                                  </mat-select> -->
              <input type="text" matInput formControlName="educatorName" readonly>
            </mat-form-field>
            <mat-form-field class="full-width-field">
              <mat-label>Service</mat-label>
              <mat-select matInput #serviceCtrl formControlName="service" required multiple>
                <mat-option *ngFor="let service of services[i]" value="{{service}}">{{service}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width-field">
              <mat-label>Reccomended Frequency (sessions/week)</mat-label>
              <input matInput type="number" formControlName='reccomendedFrequency' autocomplete="off" required
                #reccomendedFrequencyCtrl>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="addEducatorBtnDiv">
          <button mat-button type="button" (click)="addEducators()" id="addEducatorBtn">Add Another Expert</button>
        </div>
      </div>

      <!-- adding session details -->
      <mat-card-title style="padding-bottom: 10px !important; padding-top: 10px !important; margin: 0 !important;">
        <h2 style="margin: 0;">Session Details</h2>
      </mat-card-title>
      <!-- <div style="margin-bottom: 10px;">Choose pack for user</div> -->
      <div>
        <span class="radioInput">
          <input style="margin-right: 4px" type="radio" name="validity" value="24" (change)="changePlan($event)"/>
          <mat-label>180 Days(24 sessions)</mat-label>
        </span>
        <span class="radioInput">
          <input style="margin-right: 4px" type="radio" name="validity" value="36" (change)="changePlan($event)"/>
          <mat-label>270 Days(36 sessions)</mat-label>
        </span>
      </div>
      <div>
        <span class="radioInput">
          <input style="margin-right: 4px" type="radio" name="validity" value="48" (change)="changePlan($event)"/>
          <mat-label>360 Days(48 sessions)</mat-label>
        </span>
        <span class="radioInput">
          <input style="margin-right: 4px" type="radio" name="validity" value="custom" (change)="changePlan($event)"/>
          <mat-label>Custom</mat-label>
        </span>
      </div>
      <div *ngIf="customSessionDetails">
      <mat-form-field class="full-width-field">
        <mat-label>Session pack value</mat-label>
        <input matInput type="number" [min]="0" formControlName='sessionPackValue' autocomplete="off" required #sessionPackValueCtrl> 
       </mat-form-field> 
       <mat-form-field class="full-width-field">
        <mat-label>No. of sessions</mat-label>
        <input matInput type="number" [min]="0" formControlName='numberOfSessions' autocomplete="off" required #numberOfSessionsCtrl>
      </mat-form-field>
      <mat-form-field class="full-width-field">
        <mat-label>Validity(in days)</mat-label>
        <input matInput type="number" formControlName='validity' autocomplete="off" [min]="0" required #validityCtrl>
      </mat-form-field>
      </div>
        

   

      <!-- <mat-form-field class="full-width-field">
                    <mat-label>Educators</mat-label>
                    <mat-select matInput #educatorsCtrl formControlName="educators" multiple required>
                        <mat-option *ngFor="let expert of experts" value="{{expert.id}}">{{expert.fullName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width-field">
                    <mat-label>Type of session</mat-label>
                    <mat-select matInput #sessionTypeCtrl formControlName="sessionType" autocomplete="off" required>
                        <mat-option *ngFor="let sessionType of sessionTypes" value="{{sessionType.value}}">{{sessionType.label}}</mat-option>
                    </mat-select>
                </mat-form-field> -->



      <mat-card-actions>
        <button mat-button color="warn" mat-dialog-close>Cancel</button>
        <button mat-button type="submit" color="warn" class="right saveBtn"
          [disabled]="invitationForm.invalid || !educators.length || isSendDisabled" (click)="inviteConfirmation()">Send
          Invite</button>
      </mat-card-actions>
    </mat-card>
  </form>
</mat-dialog-content>

<!-- [disabled]="invitationForm.invalid" -->