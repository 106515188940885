import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private interaktNotificationURL = ' https://api.interakt.ai/v1/public/track/events/';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,  'Authorization': 'Basic S196Z21ZZVBycnFWRldiaHh1WXdTZ1JmX2NOSVQxRHB3aHVYWGl3aFRsUTo=' })
  };

  constructor(private http: HttpClient,
              private snackBar: MatSnackBar) { }


  // function to be called for every notification event.
  sendWhatsappNotification(body) {
    console.log(body,"body");
    return this.http.post(this.interaktNotificationURL, body, this.httpOptions).toPromise().then(res=>console.log(res,"res")).catch(err => console.log(err));
  }

  onNotificationSent = (event) => {
    console.log(event)
    let message = 'Notification sent Successfully'
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }
}
