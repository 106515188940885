import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FinalConfirmationDialogComponent } from '../sales-admin-dialogs/final-confirmation-dialog/final-confirmation-dialog.component';
import { NoShowConfirmationDialogComponent } from '../sales-admin-dialogs/no-show-confirmation-dialog/no-show-confirmation-dialog.component';
import { PartyConfirmationDialogComponent } from '../sales-admin-dialogs/party-confirmation-dialog/party-confirmation-dialog.component';
import { SuccessDialogComponent } from '../sales-admin-dialogs/success-dialog/success-dialog.component';
import { AdminService } from '../services/admin.service';
import firebase from 'firebase/app';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../helper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NotificationService } from '../services/notification.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-sales-admin-dashboard',
  templateUrl: './sales-admin-dashboard.component.html',
  styleUrls: ['./sales-admin-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SalesAdminDashboardComponent implements OnInit, OnDestroy {
  pageOfItems: Array<any>;
  filter = 'upcoming';
  expertSelected = '';
  salesPocSelected = '';
  expertList: AngularFirestoreDocument[] = [];
  salesPocList: AngularFirestoreDocument[] = [];
  expertSub: Subscription;
  salesPocSub: Subscription;
  consultationList: AngularFirestoreDocument[] = [];
  consultationSub: Subscription;
  salesPocAssigned = [];
  chosenStartDate: Date;
  chosenEndDate: Date;
  dateHeadingArray = new Array();
  isLoading = true;
  consultationCollection:any
  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 300;
  isScrolling=true;
  lastVisible:any;

  constructor(
    public helper: HelperService,
    public adminService: AdminService,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private notificationService: NotificationService
  ) { }

   onChangePage(pageOfItems) {
    // update current page of items
    this.pageOfItems = pageOfItems;
    //Created for DateHeadings of the sessions
    let arr = new Array();
    let dateHead: Date;
    dateHead = this.pageOfItems[0].sessionDate.toDate();
    for (let i = 0; i < this.pageOfItems.length; i++) {
      if (dateHead < this.pageOfItems[i].sessionDate.toDate()) { //for Upcoming sessions as they are in ascending in order of sessionDate
        arr.push(i);
        dateHead = pageOfItems[i].sessionDate.toDate();
      } else if (dateHead > this.pageOfItems[i].sessionDate.toDate()) { //for Past sessions as they are in descending in order of sessionDate
        arr.push(i);
        dateHead = pageOfItems[i].sessionDate.toDate();
      }
    }
    this.dateHeadingArray = arr;
    
  }
  onScroll() {
    console.log("scrolled!!");
    this.filterConsultations(
      this.expertSelected,
      this.salesPocSelected,
      this.filter,
      true
    );
  }
  onScrollUp() {
    console.log("scrolled up!!");
  }

  async ngOnInit() {
    console.log('deployed successfully')
    await this.adminService.isSales();

    this.expertSub = this.adminService.ExpertList.subscribe((result) => {
      this.expertList = result;
    });

    this.salesPocSub = this.adminService.SalesPocList.subscribe((result) => {
      this.salesPocList = result;
      // console.log(this.salesPocList);
    });

    this.filterConsultations('', '', this.filter,false);
 

  }

  // checkIfSessionFinished(consultation: any) {
  //   let ref = this.firestore.collection('consultations').doc(consultation.id).get().subscribe((doc) => {
  //     let consultation:any = doc.data();
  //     let currentTime = new Date();
  //     let now = this.helper.istTime(currentTime);

  //     let sessionEndDateTime = consultation['sessionEndDateTime'].toDate();
  //     let exactSessionEndTime = this.helper.istTime2(sessionEndDateTime);

  //     let endTime = consultation['endTime'].split(':');
  //     const endingHour = parseInt(endTime[0], 10);
  //     const endingMinute = parseInt(endTime[1], 10);

  //     //Convert end time to minutes and add to session date to get exact end date time.
  //     exactSessionEndTime.setMinutes(
  //       exactSessionEndTime.getMinutes() + endingHour * 60 + endingMinute
  //     );

  //     if (consultation['status'] === 'Scheduled' || consultation['status'] === 'Ongoing') {
  //       if (now > exactSessionEndTime) { //needs to be moved to the backend
  //         this.firestore.doc(`consultations/${consultation['id']}`).update({ status: 'Update Status' })
  //       }
  //     }
  //   })
  // }

  onFilterSelected(filter: string) {
    this.filter = filter;
    this.isLoading=true;
    this.filterConsultations(
      this.expertSelected,
      this.salesPocSelected,
      this.filter,
      false
    );
  }

  filterConsultations(
    expertSelected: string,
    salesPocSelected: string,
    filter: string,
    isPagination:boolean
  ) {
    // this.isLoading=true;
    let filterTime = firebase.firestore.Timestamp.now();
   if(!isPagination){
    this.consultationList=[];
   }
  
    if (this.consultationSub) this.consultationSub.unsubscribe();
    this.consultationSub = this.adminService
      .getFilteredConsultations(expertSelected, salesPocSelected, this.chosenStartDate, this.chosenEndDate, filter,filterTime,isPagination,this.lastVisible)
      .subscribe((result) => {
        if (!result.length) {
          console.log("No Data Available");
          this.isLoading=false;
          return false;
        }
       
        for (let item of result) {
          this.consultationList.push(item.payload.doc.data());
        }
        // this.consultationList = this.consultationList.concat(result) ;
        this.lastVisible = result[result.length - 1].payload.doc

        //filtering for 'past' and 'upcoming' and sorting for ascending and descending is done here as firebase query does not allow 
        // comparison with more than one field at a time. 
        this.consultationList.sort(function (x, y) { //sorting the final filtered list based on end date time
          return x['sessionEndDateTime'] - y['sessionEndDateTime'];
        })
        if (filter === 'past') {
          this.consultationList = this.consultationList.filter(consultation => consultation['sessionEndDateTime'] <= filterTime);
          this.consultationList.reverse()
        } else if (filter === 'upcoming') {
          this.consultationList = this.consultationList.filter(consultation => consultation['sessionEndDateTime'] > filterTime);
        }
        //Created for DateHeadings of the sessions
        let arr = new Array();
        let dateHead: Date;
        dateHead = this.consultationList[0]["sessionDate"].toDate();
        for (let i = 0; i < this.consultationList.length; i++) {
          if (dateHead < this.consultationList[i]["sessionDate"].toDate()) { //for Upcoming sessions as they are in ascending in order of sessionDate
            arr.push(i);
            dateHead = this.consultationList[i]["sessionDate"].toDate();
          } else if (dateHead > this.consultationList[i]["sessionDate"].toDate()) { //for Past sessions as they are in descending in order of sessionDate
            arr.push(i);
            dateHead = this.consultationList[i]["sessionDate"].toDate();
          }
        }
        this.dateHeadingArray = arr;

        this.consultationList= this.consultationList.filter((item, index, self) => {
          return self.findIndex((t) => {
            return t['id'] === item['id'] 
            // && t['status'] === item['status']
          }) === index
        });

        this.isLoading=false;
        // console.log(this.consultationList);
        // for (let consultation of this.consultationList)
        //   this.checkIfSessionFinished(consultation);
      });
  }

  // These methods are created for filtering the sessions based on date
  onStartDateSelected(event) {
    this.chosenStartDate = event.value;
    // console.log("Start Date: ", this.chosenStartDate);
    this.onMultiDatesSelected();
  }
  onEndtDateSelected(event) {
    this.chosenEndDate = event.value;
    // console.log("End Date: ", this.chosenEndDate);
    this.onMultiDatesSelected();
  }
  onMultiDatesSelected() {
    console.log("Testing for date filter");
    let startdate = new Date(this.chosenStartDate);
    let startTimestamp = firebase.firestore.Timestamp.fromDate(startdate)
    console.log('start timestamp ', startTimestamp)
    let endDate = new Date(this.chosenEndDate)
    let endTimestamp = firebase.firestore.Timestamp.fromDate(endDate)
    console.log('end timestamp ', endTimestamp)
    this.filterConsultations(this.expertSelected, this.salesPocSelected, this.filter,false);

  }

  resetForm() {
    this.chosenStartDate = undefined;
    this.chosenEndDate = undefined;
    this.filterConsultations(this.expertSelected, this.salesPocSelected, this.filter,false);
  }

  reschedule(consultation: AngularFirestoreDocument) {
    const partyConfirmationRef = this.dialog.open(
      PartyConfirmationDialogComponent,
      {
        disableClose: false,
        data: { dialogType: 'reschedule' },
        panelClass: 'partyConfirmationDialog',
      }
    );
    
    partyConfirmationRef.afterClosed().subscribe((role) => {
      if (role) {
        const finalConfirmationRef = this.dialog.open(
          FinalConfirmationDialogComponent,
          {
            disableClose: false,
            data: {
              cancelledBy: role,
              dialogType: 'reschedule',
              consultation: consultation,
            },
            panelClass: 'finalConfirmationDialog',
          }
        );

        finalConfirmationRef.afterClosed().subscribe((data) => {
          if (data) {
            const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
            const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
            // this.firestore
            //   .doc(`consultations/${consultation['id']}`)
            docRef
              .update({
                status: 'Reschedule requested',
                statusDetails: data.statusDetails,
              })
              .then(() => {
                this.sendConsultationRescheduledNotification(role, consultation)
                const successRef = this.dialog.open(SuccessDialogComponent, {
                  disableClose: false,
                  data: 'reschedule',
                  panelClass: 'successDialog',
                });
              });
          }
        });
      }
    });
  }

  sendConsultationRescheduledNotification(role: any, consultation: any) {
    let date = this.helper.istTime2((consultation.sessionDate.toDate()));
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    let body: any = {};

    if (role == "parent") {
      // when parent reschedules the consultation.
      body = {
        'userId': consultation.expertInteraktId,
        'event': 'consultation rescheduled by parent - Notify expert',
        'traits': {
          'date': dateString,
          'time': consultation.startTime + ' IST',
          'childName': consultation.sessionStudentName,
        },
      }
    }

    this.notificationService.sendWhatsappNotification(body);
  }

  cancel(consultation: AngularFirestoreDocument) {
    const partyConfirmationRef = this.dialog.open(
      PartyConfirmationDialogComponent,
      {
        disableClose: false,
        data: { dialogType: 'cancel' },
        panelClass: 'partyConfirmationDialog',
      }
    );

    partyConfirmationRef.afterClosed().subscribe((role) => {
      if (role) {
        const finalConfirmationRef = this.dialog.open(
          FinalConfirmationDialogComponent,
          {
            disableClose: false,
            data: {
              cancelledBy: role,
              dialogType: 'cancel',
              consultation: consultation,
            },
            panelClass: 'finalConfirmationDialog',
          }
        );

        finalConfirmationRef.afterClosed().subscribe((data) => {
          if (data) {
            const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
            const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
            // this.firestore
            //   .doc(`consultations/${consultation['id']}`)
            docRef
              .update({
                status: 'Cancelled',
                statusDetails: data.statusDetails,
              })
              .then(() => {
                this.sendConsultationCancellationNotification(role, consultation);
                const successRef = this.dialog.open(SuccessDialogComponent, {
                  disableClose: false,
                  data: 'cancel',
                  panelClass: 'successDialog',
                });
              });
          }
        });
      }
    });
  }

  sendConsultationCancellationNotification(role: any, consultation: any) {
    let date = this.helper.istTime2((consultation.sessionDate.toDate()));
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    let body: any = {};

    if (role == "expert") {
      // when expert cancels the consultation.
      body = {
        'userId': consultation.expertInteraktId,
        'event': 'consultation cancelled by expert - Notify expert',
        'traits': {
          'date': dateString,
          'time': consultation.startTime + ' IST',
          'childName': consultation.sessionStudentName,
        },
      }
    } else if (role == "parent") {
      // when parent cancels the consultation.
      body = {
        'userId': consultation.expertInteraktId,
        'event': 'consultation cancelled by parent - Notify expert',
        'traits': {
          'date': dateString,
          'time': consultation.startTime + ' IST',
        },
      }
    }

    this.notificationService.sendWhatsappNotification(body);
  }

  noShow(consultation: AngularFirestoreDocument) {
  
    const noShowRef = this.dialog.open(NoShowConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'noShowConfirmationDialog',
    });
   
    noShowRef.afterClosed().subscribe((data) => {
      if (data) {
        const finalConfirmationRef = this.dialog.open(
          FinalConfirmationDialogComponent,
          {
            disableClose: false,
            panelClass: 'finalConfirmationDialog',
            data: {
              noShowBy: data.noShowBy,
              dialogType: data.dialogType,
              consultation: consultation,
            },
          }
        );

        finalConfirmationRef.afterClosed().subscribe((data) => {
          if (data) {
            let generateTransactionForExpert: any;
            let lastUpdated = new Date();
            let status = '';
            if (data.dialogType === 'noShow-Expert') {
              status = 'No-show-by-Expert';
              generateTransactionForExpert = 'No';
            } else if (data.dialogType === 'noShow-Parent(expert not paid)') {
              status = 'No-show-by-Parent';
              generateTransactionForExpert = 'No';
            } else if (data.dialogType === 'noShow-Parent(expert paid)') {
              status = 'No-show-by-Parent';
              generateTransactionForExpert = 'Yes';
            }
          
            // this.firestore
            //   .doc(`consultations/${consultation['id']}`)
            const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
            const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
              docRef.update({
                generateTransactionForExpert: generateTransactionForExpert,
                status: status,
                statusDetails: data.statusDetails,
                lastUpdated: lastUpdated,
              })
              .then(() => {
                const successRef = this.dialog.open(SuccessDialogComponent, {
                  disableClose: false,
                  data: 'noShow',
                  panelClass: 'successDialog',
                });
              });
          }
        });
      }
    });
  }

  attend(consultation: AngularFirestoreDocument) {
    const finalConfirmationRef = this.dialog.open(
      FinalConfirmationDialogComponent,
      {
        disableClose: false,
        panelClass: 'finalConfirmationDialog',
        data: { dialogType: 'attended', consultation: consultation },
      }
    );

    finalConfirmationRef.afterClosed().subscribe((data) => {
      if (data) {
        let lastUpdated = new Date();

        const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
        const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);

        // this.firestore
        //   .doc(`consultations/${consultation['id']}`)
        docRef
          .update({
            status: 'Attended',
            statusDetails: data.statusDetails,
            'attendedBy.parent': true,
            'attendedBy.expert': true,
            lastUpdated: lastUpdated,
            generateTransactionForExpert: 'Yes'
          })
          .then(() => {
            const successRef = this.dialog.open(SuccessDialogComponent, {
              disableClose: false,
              data: 'attended',
              panelClass: 'successDialog',
            });
          });
      }
    });
  }

  joinAndNoShowButtonVisibility(session) {
    if (session.status == 'Scheduled') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      var startTime = session.startTime.split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);


      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  async join(consultation: any) {
    let url = consultation.meetingLink;
    if (!url.match(/^https?:\/\//i))
      url = 'https://' + url;

    let currentUser = await firebase.auth().currentUser;
    let id: any;
    if (currentUser) {
      id = currentUser.uid;
    }
    let ref = this.firestore.collection('users').doc(id);
    ref.get().subscribe((result) => {
      let admin: any = result.data();
      let attendedBySalesperson: any = admin.fullName;

      const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
      const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);

      // this.firestore
      //   .doc(`consultations/${consultation['id']}`)
      docRef
        .update({
          attendedBySalesperson: attendedBySalesperson,
          status: 'Ongoing',
          'attendedBy.admin': true,
        })
        .then(() => {
          let meetingLink = consultation.meetingLink;
          console.log('meeting link  ', meetingLink);
          window.open(url, '_blank');
        });
    });
  }

  joinBtnVisibility(consultation: AngularFirestoreDocument) {
    if (!consultation['attendedBy'].admin &&
      (consultation['status'] == 'Scheduled' ||
        consultation['status'] == 'Started' ||
        consultation['status'] == 'Starting' ||
        consultation['status'] == 'Expert Joined' ||
        consultation['status'] == 'Parent Joined' ||
        consultation['status'] == 'Partially Attended' ||
        consultation['status'] == 'Attended' ||
        consultation['status'] == 'Ongoing')
    ) {
      let sessionDate: Date = consultation['sessionDate'].toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      var startTime = consultation['startTime'].split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);
      let endTime = consultation['endTime'].split(':');
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rejoinBtnVisibility(consultation: AngularFirestoreDocument) {
    if (consultation['attendedBy'].admin &&
      (consultation['status'] == 'Scheduled' ||
        consultation['status'] == 'Started' ||
        consultation['status'] == 'Starting' ||
        consultation['status'] == 'Expert Joined' ||
        consultation['status'] == 'Parent Joined' ||
        consultation['status'] == 'Partially Attended' ||
        consultation['status'] == 'Ongoing')) {
      let sessionDate: Date = consultation['sessionDate'].toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      var startTime = consultation['startTime'].split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);
      let endTime = consultation['endTime'].split(':');
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rejoin(consultation: AngularFirestoreDocument) {
    let meetingLink = consultation['meetingLink'];
    console.log('meeting link  ', meetingLink);
    window.open('//' + meetingLink, '_blank');
  }

  attendedButtonVisibility(session) {
    if (session.status == 'Update Status' || session.status == 'Ongoing') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      var startTime = session.startTime.split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() > exactJoinEndTime.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  noShowButtonVisibility(session) {
    let sessionDate: Date = session.sessionDate.toDate();
    let exactSessionDate = this.helper.istTime2(sessionDate);

    // startTime is HH:MM
    var startTime = session.startTime.split(':');
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);

    //Convert start time to minutes and add to session date to get exact start date time.
    exactSessionDate.setMinutes(
      exactSessionDate.getMinutes() + startingHour * 60 + startingMinute
    );

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);

    //Check if now is greater or equal to session start date time
    if (now.getTime() > exactSessionDate.getTime()) {
      //session start time has crossed
      return true;
    } else {
      return false;
    }

  }

  cancelBtnVisbility(session) {
    if (
      session['status'] == 'Scheduled' ||
      session['status'] == 'Started' ||
      session['status'] == 'Starting' ||
      session['status'] == 'Expert Joined' ||
      session['status'] == 'Parent Joined' ||
      session['status'] == 'Partially Attended' ||
      session['status'] == 'Attended' ||
      session['status'] == 'Ongoing'
    ) {
      var sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      var startTime = session.startTime.split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() <= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rescheduleBtnVisibility(session): boolean {
    if (
      session['status'] == 'Scheduled' ||
      session['status'] == 'Started' ||
      session['status'] == 'Starting' ||
      session['status'] == 'Expert Joined' ||
      session['status'] == 'Parent Joined' ||
      session['status'] == 'Partially Attended' ||
      session['status'] == 'Attended' ||
      session['status'] == 'Ongoing'
    ) {
      var sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      var startTime = session.startTime.split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      if (now.getTime() <= exactSessionDate.getTime())
        return true;
    }
    return false;
  }

  copyLink(consultation: AngularFirestoreDocument) {
    this.clipboard.copy(consultation['meetingLink']);
    let snackBarRef = this.snackBar.open('Copied successfully!', 'Undo', { duration: 3000 });

    snackBarRef.onAction().subscribe(() => { this.clipboard.copy(' '); });
  }

  consultationDataDownload() {
    const dialogRef = this.dialog.open(consultationDataDownloadDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        console.log('result ', result)
      }
    });
  }

  ngOnDestroy() {
    if (this.consultationSub) this.consultationSub.unsubscribe();
  }
}


@Component({
  selector: 'consultation-data-download-dialog',
  template: `
    <div *ngIf="!workingOnData" style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
  
    <div *ngIf="!workingOnData" class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important; color: #F7524B;">Download</mat-card-title>
      <p>Load consultations data into excel sheets</p>
      
      <mat-form-field class="full-width-field" appearance="outline">
          <input matInput (dateChange)="onStartDateSelected($event)" 
            [matDatepicker]="startDatePicker" autocomplete="off" placeholder="From date DD/MM/YYY">

          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="full-width-field" appearance="outline">
        <input matInput (dateChange)="onEndDateSelected($event)" 
            [matDatepicker]="endDatePicker" autocomplete="off" placeholder="To date DD/MM/YYY">

        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <button mat-button id="addSessionPackBtn" style="background: #FCFCFC; color: #F7524B" class="right" (click)="getConsultationDetails()">Yes</button>
      <button mat-dialog-close mat-button id="addSessionPackBtn" style="background: #F7524B !important; color: white !important; margin-right: 15px" class="right" >No</button>
    </div>


    <div *ngIf="workingOnData" class="text-center my-4" style="margin-top: 35px; margin-bottom: 20px;">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div>
      <h3 style="color: #A5A5A5">
      We are getting the requested data.<br>Please wait for a moment.<br>
      </h3>
    </div>
  </div>
  `,
  styleUrls: ['./sales-admin-dashboard.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})


export class consultationDataDownloadDialog implements OnInit {
  workbook = new Workbook();
  worksheet: any;
  monthlyConsultations: any[];
  monthlyConsultationsWithAllData: any
  workingOnData: boolean = false;
  startDate: any;
  endDate: any;

  constructor(
    private dialogRef: MatDialogRef<consultationDataDownloadDialog>,
    private firestore: AngularFirestore,
    private helper: HelperService
  ) { }

  ngOnInit() { }

  onStartDateSelected(event) {
    let date = event.value._d;
    this.startDate = new Date(date);
    this.startDate.setHours(0, 0, 0, 0);
    console.log('start date ', this.startDate)
  }

  onEndDateSelected(event) {
    let date = event.value._d;
    this.endDate = new Date(date);
    this.endDate.setHours(0, 0, 0, 0);
    console.log('end date ', this.endDate)
  }


  async getConsultationDetails() {
    this.workingOnData = true;
    this.monthlyConsultations = [];
    this.worksheet = this.workbook.addWorksheet('example-consultation')

    this.worksheet.columns = [
      { header: 'sessionDate', key: 'sessionDate' },
      { header: 'sessionParentName', key: 'sessionParentName' },
      { header: 'sessionStudentName', key: 'sessionStudentName' },
      { header: 'phoneNumber', key: 'phoneNumber' },
      { header: 'expertTransactionValue', key: 'expertTransactionValue' },
      { header: 'startTime', key: 'startTime' },
      { header: 'sessionExpertName', key: 'sessionExpertName' },
      { header: 'sessionId', key: 'sessionId' },
      { header: 'status', key: 'status' },
      { header: 'POC', key: 'POC' },
      { header: 'expertPaidForNoShow', key: 'expertPaidForNoShow' },
      { header: 'bookedBy', key: 'bookedBy' },
      { header: 'serviceType', key: 'serviceType' }
    ]
    this.worksheet.getRow(1).font = { bold: true }

    let startingDate = new Date(this.startDate);
    let endingDate = new Date(this.endDate);
    let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
    let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)

    const snapshot = this.firestore.collectionGroup('consultations', ref => ref.where('sessionDate', ">=", startingTimeStamp).where('sessionDate', "<=", endingTimeStamp).orderBy('sessionDate', 'asc').orderBy('sessionEndDateTime', 'asc'))
      .get().toPromise().then(results => {
        console.log('total sessions ', results.size);
        let count = 1;

        results.forEach(doc => {
          let session: any = doc.data();
          console.log(count, 'session ', session.id)
          this.monthlyConsultations.push(session);
          count++;
        })

        this.addTransactionValue();
      })
  }


  addTransactionValue() {
    this.monthlyConsultationsWithAllData = [];
    let count = 0;

    this.monthlyConsultations.forEach(async doc => {
      let session: any = doc;
      await this.getSessionRelatedTransactions(session);
      count++;

      console.log('count ', count)
      if (count == this.monthlyConsultations.length) {
        console.log('count finally ', count)
        this.addDataToExcelSheet();
      }
    })
  }


  async getSessionRelatedTransactions(session: any) {
    // getting the transaction related to the session.
    return this.firestore.collection('transactions', ref => ref.where('consultationId', '==', session.id)).get().toPromise().then(documents => {
      let expertTransactionBalance = 0;

      if (documents.empty && session.status == "No-show-by-Parent") {
        session.expertPaidForNoShow = "No";
      } else if(!documents.empty && session.status == "No-show-by-Parent"){
        session.expertPaidForNoShow = "Yes";
      } else if(session.status != "No-show-by-Parent"){
        session.expertPaidForNoShow = "-";
      }

      documents.forEach(document => {
        let transaction: any = document.data();
        if (transaction.userId == session.sessionExpertId) {
          expertTransactionBalance += transaction.transactionValue;
        }
      })

      session.expertTransactionValue = expertTransactionBalance;
      this.monthlyConsultationsWithAllData.push(session);
      return;
    })
  }


  addDataToExcelSheet() {
    this.monthlyConsultationsWithAllData.sort(function (x, y) {
      return x.sessionEndDateTime - y.sessionEndDateTime
    })

    this.monthlyConsultationsWithAllData.forEach(doc => {
      let session: any = doc;

      let excelData: any = {};
      let date = new Date(session.sessionDate.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })

      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      // filling the excel sheet
      excelData.sessionDate = onlyDateString;
      excelData.sessionParentName = session.sessionParentName;
      excelData.sessionStudentName = session.sessionStudentName;
      excelData.phoneNumber = session.parentPhoneNumber;
      excelData.startTime = session.startTime;
      excelData.sessionExpertName = session.sessionExpertName;
      excelData.bookedBy = session.createdByName
      excelData.POC = session.salesPocId
      excelData.sessionId = session.id;
      excelData.status = session.status;
      excelData.serviceType = session.serviceType;
      excelData.expertPaidForNoShow = session.expertPaidForNoShow;
      excelData.expertTransactionValue = session.expertTransactionValue;

      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.addFile();
  }

  addFile() {
    let fName = "consultation-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')

      this.dialogRef.close();
    });
  }

  ngOnDestroy() { }
}