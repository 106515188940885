<div class="container">
    <!-- loading screen starts here -->
    <div *ngIf="isLoading" class="text-center my-4" style="margin-top: 50px;">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div>
            <h2 style="color: black;">Loading Sessions</h2>
            <h3 style="color: #A5A5A5">Give us a second as we load your sessions</h3>
        </div>
    </div>
    <!-- loading screen ends here -->
    <img src="../../assets/u-feedback-bg.svg" class="u-feedback-bg" alt="">
    <img src="../../assets/splash-feedback-bg.svg" class="splash-feedback-bg" alt="">
    <div *ngIf="(!isLoading)" class="outer-box">
        <div class="feedback-form">
            <form action="">
                <div class="upper-wrapper">
                    <h3 class="heading-text">Rate your last session *</h3>
                    <div class="heading-content">Speech Therapy with {{session[0]?.sessionExpertName}} on
                        {{session[0]?.sessionDate.toDate().getDate()}}/{{session[0]?.sessionDate.toDate().getMonth()+1}}/{{session[0]?.sessionDate.toDate().getFullYear()}}
                        at
                        {{session[0]?.startTime}}</div>
                    <div class="emoji-row">
                        <img class="smiley" id="1star" src="../../assets/emoji _disappointed face_.svg" alt="" srcset=""
                            value="worst" (click)="Setfeedback('1 star',smiley1.id)" #smiley1>
                        <img class="smiley" id="2star" src="../../assets/emoji _worried face_.svg" alt="" srcset=""
                            value="" (click)="Setfeedback('2 star',smiley2.id)" #smiley2>
                        <img class="smiley" id="3star" src="../../assets/emoji _slightly smiling face_.svg" alt=""
                            srcset="" (click)="Setfeedback('3 star',smiley3.id)" #smiley3>
                        <img class="smiley" id="4star" src="../../assets/emoji _smiling face with open mouth_.svg"
                            alt="" srcset="" (click)="Setfeedback('4 star',smiley4.id)" #smiley4>
                        <img class="smiley" id="5star"
                            src="../../assets/emoji _smiling face with heart-shaped eyes_.svg" alt="" srcset=""
                            (click)="Setfeedback('5 star',smiley5.id)" #smiley5>
                    </div>
                    <div *ngIf="formValidationCheck" class="formValidationText">This field is required </div>
                </div>
                <h3 class="subheading">Tell us what can be improved?</h3>
                <div class="improved-upperlist">
                    <p class="list-item1 list-item" id="uniqueItem1"
                        (click)="setImprovementField('Time Slots',timeSlot.id)" #timeSlot>Time slots</p>
                    <p class="list-item2 list-item" id="uniqueItem2"
                        (click)="setImprovementField('Services',services.id)" #services>Services</p>
                    <p class="list-item3 list-item" id="uniqueItem3"
                        (click)="setImprovementField('Punctuality',punctuality.id)" #punctuality>Punctuality</p>
                </div>
                <div class="improved-lowerlist">
                    <p class="list-item4 list-item" id="uniqueItem4"
                        (click)="setImprovementField('Communication',communication.id) " #communication>Communication
                    </p>
                    <p class="list-item5 list-item" id="uniqueItem5"
                        (click)="setImprovementField('Transparency',transparency.id) " #transparency>Transparency</p>
                </div>
                <!-- <input type="text"  placeholder="Tell us how can we improve..." class="feedback-input"> -->
                <textarea name="" id="" rows="7" placeholder="Tell us how can we improve..." class="feedback-input"
                    name="comment" maxlength="1000" #comment></textarea>
                <button type="submit" value="Submit" class="submit-button" id="mySubmit"
                    (click)="submit(comment.value)">Submit</button>
                <!-- <input type="button" value="Submit"  class="submit-button" (click)="submit(comment.value)" > -->

            </form>
        </div>
    </div>
</div>