<mat-card style="width: 100%;" class="zoomUnAvailableCard">
    <div style="display: flex; justify-content: flex-end;">
        <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>
    <div style="display: flex; justify-content: flex-start">
        <p style="color: #03A9F4;">POP-UPS BLOCKED</p>
    </div>
    <mat-card-title class="penaltyWarning">
        Kindly turn off "Block Pop-Ups" Setting on your browser
    </mat-card-title>
    <mat-card-content>
        <p>Please go to the settings for your browser and ensure Pop-ups are allowed and/or your "Block Pop-up" setting
            is turned off</p>
    </mat-card-content>
    <mat-card-actions>
        <p style=" margin-left: 8px; margin-right: 5px; float: left;">Need our help?</p>
        <a style="color: #03A9F4;"
            href="https://wa.me/+919606719826?text=Hi!%20I%20am%20having%20trouble%20joining%20my%20session%20due%20to%20a%20blocked%20pop-ups%20setting%20error.%20Please%20help%20me%20resolve%20this%20issue">Contact
            Support</a>
        <!-- <button class="right actionButtons" mat-button color="warn" (click)="contactSupport()">Contact Support</button> -->
    </mat-card-actions>
</mat-card>