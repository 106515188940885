<div class="outer-box">
    <img class="bigStar" src="../../assets/bigStar.svg" alt="">
    <img class="smallStar" src="../../assets/smallStar.svg" alt="">
    <img src="../../assets/cross.svg" class="cross" alt="" srcset="" style="cursor: poimter;"  mat-dialog-close>
    <div class="feedback-form">
        <h1 class="form-heading">Rate your last session</h1>
        <div class="content-bg">
            <div class="content">Speech Therapy with {{session?.sessionExpertName}} on {{session?.sessionDate.toDate().getDate()}}/{{session?.sessionDate.toDate().getMonth()+1}}/{{session?.sessionDate.toDate().getFullYear()}} at {{session?.startTime}}</div>
        </div>
        <div class="emoji-row">
            <img class="smiley" id="1star" src="../../assets/emoji _disappointed face_.svg" alt="" srcset="" (click)="Setfeedback('1 star',smiley1.id)" #smiley1>
                <img class="smiley" id="2star" src="../../assets/emoji _worried face_.svg" alt="" srcset="" (click)="Setfeedback('2 star',smiley2.id)"#smiley2>
                <img class="smiley" id="3star" src="../../assets/emoji _slightly smiling face_.svg" alt="" srcset="" (click)="Setfeedback('3 star',smiley3.id)"#smiley3>
                <img class="smiley" id="4star" src="../../assets/emoji _smiling face with open mouth_.svg" alt="" srcset="" (click)="Setfeedback('4 star',smiley4.id)"#smiley4>
                <img class="smiley" id="5star" src="../../assets/emoji _smiling face with heart-shaped eyes_.svg" alt="" srcset="" (click)="Setfeedback('5 star',smiley5.id)"#smiley5>
            </div>
        <div *ngIf="formValidationCheck" class="formValidationText">This field is required </div>
    </div>
    <input type="submit" class="submit-button" id="mySubmit" value='Submit' (click)="submit()" />
    <!-- <button class="submit-button"  value="Submit" (click)="submit()" mat-dialog-close >
        Submit
    </button> -->

</div>