import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-sales-admin-signin',
  templateUrl: './sales-admin-signin.component.html',
  styleUrls: ['./sales-admin-signin.component.css']
})
export class SalesAdminSigninComponent implements OnInit {
  verified:boolean = true;

  constructor(private auth: AngularFireAuth,
              private route: ActivatedRoute,
              private firestore: AngularFirestore,
              private ngZone: NgZone,
              private router: Router,
              private helper: HelperService) { }

  ngOnInit(): void {
    this.auth.onAuthStateChanged(user => {
      this.checkIfRegisteredUser(user);
    })
  }

  public checkIfRegisteredUser = (user):Promise<string|void> => {
    if(user){
      var userRef = this.firestore.collection('users').doc(user.uid);
      return userRef.get().toPromise().then(this.onUser).catch(function(error) {
          console.log("Error getting document:", error);
      });
    }
  }

  public onUser = (result):any => {
    if(result.exists){
      var user = result.data();
      console.log('user  ', user)
      // If User is already registered and verified
      if(user['verified'] && user['registered']){
        this.ngZone.run(()=>this.router.navigateByUrl("/" + user["role"] + "/dashboard"));

      //User already registered but not verified. Ask them to await verification.
      }else if(!user['verified'] && user['registered']){
        this.verified = false;
      } else if(!user['verified'] && !user['registered']) {
        
        // if user already signed up but did not register
        if(user['authenticated']){
          this.ngZone.run(() => this.router.navigateByUrl("/sales/register"));

        // if user is signing up for the first time. 
        } else {
          this.saveNewAdmin();
        }
      }
      return user;

      // New admin. Create account
    }else{
      this.saveNewAdmin()
    }
  }

  async saveNewAdmin(){
    console.log('save new admin is calling')
    var usersRef = this.firestore.collection("users");
    const id = await firebase.auth().currentUser.uid;
    const interaktId = this.helper.uuidv4();
    const name = await firebase.auth().currentUser.displayName;
    const email = await firebase.auth().currentUser.email;
    const phoneNumber = await firebase.auth().currentUser.phoneNumber;
    const createdBy = firebase.auth().currentUser.uid;
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    usersRef.doc(id).set({
                    id:id,
                    name: name,
                    interaktId: interaktId,
                    phoneNumber:phoneNumber,
                    email: email,
                    role: 'sales-admin',
                    verified:false,
                    registered:false,
                    authenticated: true,
                    createdOn: timestamp,
                    createdBy: createdBy}).then(this.onUserCreated)
    .catch(function(error) {
        console.error("Error creating User: ", error);
    });
  }

  onUserCreated = async() =>{
    this.router.navigateByUrl("/sales/register");
  }

  async successCallback(event){
    // this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    //Check if user is already registered.
    this.checkIfRegisteredUser(event.authResult.user)
  }

  errorCallback(event){

  }

}
