<!-- For Uploading the csv to leaves collection in firebase -->
<!-- <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file" accept=".xlsx">
<button type="button" class="btn btn-info" (click)="Upload()" >Upload</button> -->

<!-- For checking the feedback functionality -->
<!-- <button type="button" (click) = "Feedback()">Feedback</button> -->

<div class="parent-dashboard-page-container">
  <mat-tab-group>
    <mat-tab label="Sessions">
      <div class="pendingContainer" *ngIf="(parentsService.currentUserDetails?.isRenewalParent == true||parentsService.currentUserDetails?.currentBalance<=3)&&(!isLoading)">
        <div class="renewalContainer">
          <mat-icon class="notificationicon">notifications </mat-icon>
          <div class="wrapper">
            <span class="renewalpending">
                   Your renewal is pending <br/>
                    <a type="button" class="topup" (click)="topUp()">
                        Top up here
                    </a>
            </span>
          </div>
          <div class="renewalpending"></div>
        </div>
      </div>

      <div>
        <div class="optionHolder">
          <span class="sessionFilterContainer" *ngIf="filter == 'Upcoming'">
            <button mat-button class='sessionFilterButton' (click)="onFilterChanged('Past')">Past</button>
            <button mat-button class='sessionFilterButton' style="background-color: #DBEF91;"
              (click)="onFilterChanged('Upcoming')"><span style="margin-left: -0.5vw;">Upcoming</span></button>
          </span>
          <span class="sessionFilterContainer" *ngIf="filter == 'Past'">
            <button mat-button class='sessionFilterButton' style="background-color: #DBEF91;"
              (click)="onFilterChanged('Past')">Past</button>
            <button mat-button class='sessionFilterButton' (click)="onFilterChanged('Upcoming')"><span
                style="margin-left: -0.5vw;">Upcoming</span></button>
          </span>
          <span>
            <a mat-button class="newSessionButton" (click)="requestSession()">
              <mat-icon class="addSymbol">add</mat-icon>
              New Session
            </a>
          </span>
        </div>


        <!-- <div id="sortBy">Sort By:</div>
				<div class="sortingContainer">
					<input matInput [matDatepicker]="picker" class="dateFilter" autocomplete="off" (click)="picker.open()" placeholder="Date">
					<mat-datepicker-toggle matSuffix [for]="picker" (click)="onDateSelection()"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-select class="serviceFilter" placeholder="Service Type">
						<mat-option *ngFor="let service of getServices()" value="{{service}}" (click)="setServiceFilter(service)">{{service}}</mat-option>
					</mat-select>
				</div> -->

        <!-- <div class="dateContainer">{{todayDate | date:'EEEE, dd MMM'}}</div>
				<mat-select class="serviceFilter" placeholder="Speech Therapy">
					<mat-option *ngFor="let service of getServices()" value="{{service}}" (click)="setServiceFilter(service)">{{service}}</mat-option>
				</mat-select>	 -->



        <h1 style="font-weight: 600;">Sort by:</h1>
        <div class="sortHolder">
          <mat-form-field style="margin-right: 10px;">
            <mat-label>Expert</mat-label>
            <!-- <mat-select disableOptionCentering panelClass="dropdown" [(value)]="expertSelected"
							(selectionChange)="filterCombinedList(expertSelected, statusSelected)">
							<mat-option>-</mat-option>
							<mat-option [value]="expert.id" *ngFor="let expert of expertList">{{ expert.fullName }}
							</mat-option>
						</mat-select> -->
            <input type="text" matInput [(ngModel)]="expertName" placeholder="Expert Name" #educatorField="matInput"
              (ngModelChange)="resetExpertInput($event)" (blur)="onExpertBlur()" (focus)="onExpertFocus()">
            <div class="expertDropdown" *ngIf="isExpertClicked">
              <mat-list id="expertList">
                <mat-list-item *ngFor="let expert of expertList | filter: expertName" (click)="onExpertClick(expert)"
                  class="expertListItem">
                  {{ expert.fullName }}
                </mat-list-item>
              </mat-list>
            </div>
          </mat-form-field>
          <!-- sorting based on status -->
          <mat-form-field>
            <mat-label>Status</mat-label>
            <!-- <mat-select disableOptionCentering panelClass="dropdown" [(value)]="statusSelected"
							(selectionChange)="filterCombinedList(expertSelected, statusSelected)">
							<mat-option>-</mat-option>
							<mat-option [value]="status" *ngFor="let status of helper.sessionTypes">{{status}}
							</mat-option>
						</mat-select> -->
            <input type="text" matInput [(ngModel)]="statusName" placeholder="Status Type" #statusField="matInput"
              (ngModelChange)="resetStatusInput($event)" (blur)="onStatusBlur()" (focus)="onStatusFocus()">
            <div class="expertDropdown" *ngIf="isStatusClicked">
              <mat-list id="expertList">
                <mat-list-item *ngFor="let status of statusArray | filter: statusName" (click)="onStatusClick(status)"
                  class="expertListItem">
                  {{ status.name }}
                </mat-list-item>
              </mat-list>
            </div>
          </mat-form-field>
        </div>


        <div>
          <!-- loading screen starts here -->
          <div *ngIf="isLoading" class="text-center my-4" style="margin-top: 50px;">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <h2 style="color: black;">Loading Sessions</h2>
              <h3 style="color: #A5A5A5">Give us a second as we load your '{{filter}}' sessions</h3>
            </div>
          </div>
          <!-- loading screen ends here -->

          <!-- this is for upcoming sessions -->
          <div *ngIf="(sessions?.length === 0) && (!isLoading) && (filter == 'Upcoming')" class="text-center my-4"
            style="margin-top: 50px;">
            <h2 style="color: black;">No Sessions</h2>
            <h3 style="color: #A5A5A5">Currently, you have no upcoming sessions. 
              <br>Click the "+ New Session" to schedule your next session! 
              <br/>You have {{parentsService.currentUserDetails?.numberOfSessions}} session(s) in your kitty that are valid upto {{validity}} days from booking of your first session.

            </h3>
          </div>

          <!-- this is for past sessions -->
          <div *ngIf="(sessions?.length === 0) && (!isLoading) && (filter == 'Past')" class="text-center my-4"
            style="margin-top: 50px;">
            <h2 style="color: black;">No Sessions</h2>
            <h3 style="color: #A5A5A5">Currently, you have no past sessions. <br>Once sessions are past
              their scheduled end-time, they will appear here.
            </h3>
          </div>

          <!-- session and consultation card view starts here -->
          <div *ngIf="!isLoading">
            <div *ngFor="let session of sessions; let i = index;">

              <div *ngIf="(dateHeadingArray.indexOf(i) > -1) || (i===0)" style="margin-top: 20px;">
                <h1 style="font-weight: 600; color: #000; font-family: Poppins">
                  {{helper.istTime(session.sessionDate.toDate()) | date:'MMMM dd, YYYY'}}
                </h1>
              </div>

              <mat-card class="sessionCard">
                <!-- consultation card -->
                <div *ngIf="session.sessionType == 'Free Consultation' || session.sessionType == 'Paid Consultation'">
                  <div>
                    <p style="margin: 0">{{ session.status | uppercase}}</p>
                  </div>
                  <mat-card-header>
                    <mat-card-title>
                      <h1 style="font-weight: 500;">
                        (E){{ session.sessionExpertName }} with (P){{ session.sessionParentName }}, (C){{
                        session.sessionStudentName }}
                      </h1>
                    </mat-card-title>
                    <mat-card-subtitle style="font-weight: 500;">
                      <p style="font-weight: 600;">{{ session.serviceType }}</p>
                      <p>{{helper.istTime(session.sessionDate.toDate()) | date: "MMMM dd, Y" }}</p>
                      <p>{{ session.startTime }} - {{ session.endTime }}</p>
                      <p>{{ session.meetingLink }}</p>
                    </mat-card-subtitle>
                  </mat-card-header>
                  <mat-card-actions>
                    <button mat-button *ngIf="consultationJoinButtonVisibility(session) && filter != 'past'"
                      color="warn" class="right joinBtn" (click)="joinConsultation(session)">Join</button>
                    <button mat-button *ngIf="rejoinBtnVisibility(session) && filter != 'Past'" color="warn"
                      class="right joinBtn" (click)="rejoinConsultation(session)">Rejoin</button>
                    <button mat-button color="warn" class="right"
                      *ngIf="consultationCancelButtonVisibility(session)">Cancel</button>
                  </mat-card-actions>
                </div>

                <!-- session card -->
                <div *ngIf="session.sessionType != 'Free Consultation' && session.sessionType != 'Paid Consultation'">
                  <mat-card-header>
                    <mat-card-subtitle>
                      <span class="left" *ngIf="session.status == 'Ongoing'"
                        style="color:#7ACFFF">{{session.status}}</span>
                      <span class="left" *ngIf="session.status == 'Scheduled'"
                        style="color:#67CA5F">{{session.status}}</span>
                      <span class="left" *ngIf="session.status == 'Rescheduled'"
                        style="color: #FF6D6D">{{session.status}}</span>
                      <span class="left" *ngIf="session.status == 'Cancelled'"
                        style="color: #FF6D6D">{{session.status}}</span>
                      <span class="left" *ngIf="session.status == 'Session Request'"
                        style="color: #F77E46">{{session.status}}</span>
                      <span class="left" *ngIf="session.status == 'Attended'"
                        style="color: #96ED8F">{{session.status}}</span>
                      <span class="left" *ngIf="session.status == 'No Show'"
                        style="color: #787878">{{session.status}}</span>
                      <span class="left"
                        *ngIf="session.status != 'noShow-Expert' && session.status != 'Attended' && session.status != 'Session Request' && session.status != 'Cancelled' && session.status != 'Rescheduled' && session.status != 'Scheduled' && session.status != 'Ongoing'"
                        style="color: #252935">{{ session.status }}</span>
                    </mat-card-subtitle>
                    <mat-card-title *ngIf="session.status == 'Session Request'; else mainHeading">
                      <span>{{session.serviceType}} session with {{session.sessionExpertName}}</span>
                    </mat-card-title>
                    <ng-template #mainHeading>
                      <mat-card-title>
                        <span>{{session.serviceType}} with {{session.sessionExpertName}}</span>
                      </mat-card-title>
                    </ng-template>
                  </mat-card-header>

                  <mat-card-content class="card-content">
                    <div class="full-width">
                      <p></p>
                      <div class="sessionTimings">
                        {{helper.istTime(session.sessionDate.toDate()) | date:'MMMM dd, YYYY'}}
                      </div>
                      <div class="sessionTimings">
                        {{session.startTime}} - {{helper.getEndTime(session)}} IST</div>
                    </div>
                  </mat-card-content>

                  <mat-card-actions *ngIf="session.status == 'Session Request'; else mainActions">
                    <button mat-button class="right actionButtons mat-small" color="warn"
                      (click)="confirmSession(session)">Confirm</button>
                    <button mat-button class="right actionButtons mat-small" color="warn"
                      (click)="rejectSession(session)">Reject</button>
                    <button mat-button class="right actionButtons mat-small" color="warn"
                      (click)="onSessionDenied(session)">Contact Support</button>
                  </mat-card-actions>
                  <ng-template #mainActions>
                    <mat-card-actions align="end"
                      *ngIf="session.status != 'Rescheduled' && session.status != 'Cancelled' && session.status != 'REQUEST CANCELLED' && session.status != 'Session Request Expired' && session.status != 'Rejected Session Request'">
                      <button mat-button *ngIf="joinButtonVisibility(session)" class="right actionButtons mat-small"
                        (click)="startMeeting(session)" color="warn">Join Session</button>
                      <button mat-button *ngIf="rejoinBtnVisibility(session)" class="right actionButtons mat-small"
                        (click)="startMeeting(session)" color="warn">ReJoin Session</button>
                      <button mat-button *ngIf="session.status == 'Scheduled'" class="right actionButtons mat-small"
                        (click)="cancelMeeting(session.id)" color="warn">Cancel Session</button>
                      <button mat-button *ngIf="session.status == 'Scheduled'" class="right actionButtons mat-small"
                        (click)="editSession(session.id)" color="warn">Reschedule</button>
                    </mat-card-actions>
                  </ng-template>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="My Programs" *ngIf="parentsService.currentUserDetails?.homePlanEnabled == true">
      <!-- <iframe src="https://trusting-traffic-d0c.notion.site/What-s-Missing-bd88253562664160b05457374632245e" title="W3Schools Free Online Web Tutorials"></iframe> -->


      <iframe [src]="parentsService.notionLink"></iframe>  <style>  iframe { width: 100%; height: 850px; border: 0px solid #ccc; border-radius: 10px; padding: none; }  </style>

    </mat-tab>

    <mat-tab label="My Experts">
      <!-- <ng-template mat-tab-label>
				<mat-icon class="material-icons-outlined">
					{{relationIcon}}
				</mat-icon>
				My Experts
			</ng-template> -->
      <div class="profilePageContainer">
        <h2 style="text-align: initial; position: relative; left: 10px; margin-top: 3vh;">My Experts</h2>
        <mat-card class="profileCard" *ngFor="let expert of parentsService.myExperts">
          <mat-card-header>
            <mat-card-subtitle style="color: #4391C1; text-align: initial;">{{expert.serviceTypes}}
            </mat-card-subtitle>
            <mat-card-title style="text-align: start;">{{expert.fullName}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="profileDetails">
              <span class="labelFields">Age Range</span>
              <span class="detailFields">{{expert.ageGroups}}</span>
            </div>
            <div class="profileDetails">
              <span class="labelFields">Language(s)</span>
              <span class="detailFields">{{expert.languages}}</span>
            </div>
            <div class="profileDetails">
              <span class="labelFields">Experience</span>
              <span class="detailFields">{{expert.yearsOfExperience}} yrs</span>
            </div>
            <div class="profileDetails">
              <span class="labelFields">Qualification</span>
              <span class="detailFields" *ngFor="let qualification of expert.qualifications">{{qualification.degree}} -
                {{qualification.fieldOfStudy}}</span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <!-- <mat-tab label="Goal Sheet">
			<mat-card class="box-shadow-none background-color-whitesmoke">
				<app-goals [id]="id"></app-goals>
			</mat-card>
		</mat-tab> -->
    <mat-tab label="Profile">
      <!-- <ng-template mat-tab-label>
				<mat-icon class="material-icons-outlined">
					{{profileIcon}}
				</mat-icon>
				Profile
			</ng-template> -->
      <mat-card class="box-shadow-none background-color-whitesmoke parentDetailCard">
        <app-parent-profile [balanceDetails]="balanceDetails" [id]="id" [isEditing]="false" *ngIf="id">
        </app-parent-profile>
      </mat-card>

      <!-- <div class="currentBalance">
        	<h1 style="margin: 10px;">Current Balance: {{ this.currentBalance?this.currentBalance:"Balance Document does not exist" }}</h1>
        	<button mat-button class="addButton right" (click)="openSessionPackDialog()">+ Add</button>
        	</div> -->

      <div class="balanceWrapper">
        <div style="display: flex; align-items: center; justify-content: space-between; padding: 0px 8px; margin-bottom: 10px;">
          <h1 style="margin-bottom: 0px; margin-right: 5px;">Transaction History: </h1>
          <button class="topupBtn" mat-button  (click)="topUp()">+ Top Up</button>
        </div>
        <mat-card class="balanceCard">
          <div class="transactionFilterContainer" style="margin-top: 8px; margin-bottom: 20px; display: flex; justify-content: flex-end;">
            <mat-select placeholder="All Transactions" style="border: 1px solid #7A7A7A; width: 151px; border-radius: 5px; padding-left: 10px;" [(value)]="transactionFilter" (selectionChange)="onTransactionFilterSelected($event.value)">
                <mat-option value="allTransaction">All Transactions</mat-option>
                <mat-option value="renewalTransaction">Payments</mat-option>
            </mat-select>
          </div>

          <mat-card-content>
            <div class="transactionByDate" *ngFor="let date of sortedDates">
              <div class="transactionDate">
                <h3 style="margin-bottom: 30px;">{{ date | date: 'd MMMM y'}}</h3>
              </div>
              <mat-list style="padding-top: 0;">
                <div class="transaction" *ngFor="let transaction of transactionsByDate[date]">
                  <mat-list-item class="transactionListItem" style="height: auto;" >
                    <div class="fullTransaction">

                      <!-- <div
                        [ngStyle]="{'color': getFinalTransactionValue(transaction.transaction)>0?'#B1CA57':'#F7524B'}">
                        <b class="transactionVal">
                          {{ getFinalTransactionValue(transaction.transaction)>0?"+":""
                          }}{{getFinalTransactionValue(transaction.transaction)}}
                        </b>
                      </div> -->

                      <div
                      [ngStyle]="{'color': getFinalTransactionValue(transaction.transaction)>0?'#B1CA57':'#F7524B'}">
                      <b style="color: #B1CA57;" class="transactionVal"
                        *ngIf="transaction.transaction.transactionType == 'renewalTransaction' && transaction.transaction.paymentStatus == 'Successfull'; else nonRenewal">
                        +{{transaction.transaction.sessions}}
                      </b>

                      <ng-template #nonRenewal>
                        <b class="transactionVal">
                          {{ getFinalTransactionValue(transaction.transaction)>0?"+":""
                          }}{{getFinalTransactionValue(transaction.transaction)}}
                        </b>
                      </ng-template>
                    </div>


                      <!-- <div class="transactionDetailsWrapper">
                        <div class="transactionType">{{ transaction.transaction['reason'] }}</div>
                        <div *ngIf="transaction.transaction.transactionType == 'session' && !transaction.transaction.cretedViaMultipleBookings">
                          <div class="transactionExtraDetails">Expert: {{ transaction.transaction.session?.sessionExpertName }}</div>
                          <div class="transactionExtraDetails">Date: {{ transaction.transaction.session?.sessionDate.toDate() | date:'dd MMMM, YYYY' }}</div>
                          <div class="transactionExtraDetails">Time: {{ transaction.transaction.session?.startTime }} - {{ transaction.transaction.session?.endTime }} IST</div>
                        </div>
                        <div *ngIf="transaction.transaction.transactionType == 'consultation'">
                          <div class="transactionExtraDetails">Expert: {{ transaction.transaction.session?.sessionExpertName }}</div>
                          <div class="transactionExtraDetails">Date: {{ transaction.transaction.session?.sessionDate.toDate() | date:'dd MMMM, YYYY' }}</div>
                          <div class="transactionExtraDetails">Time: {{ transaction.transaction.session?.startTime }} - {{ transaction.transaction.session?.endTime }} IST</div>
                        </div>

                        <div *ngIf="transaction.transaction.transactionType == 'byAdmin'">
                          <div class="transactionExtraDetails">{{ transaction.transaction.subReason }}</div>
                        </div>

                        <div *ngIf="transaction.transaction.transactionType == 'session' && transaction.transaction.cretedViaMultipleBookings">
                          <div class="transactionExtraDetails">Expert: {{ transaction.transaction?.sessionExpertName }}</div>

                          <button *ngIf="!transaction.transaction.showMultipleSessionDetails" class="expansionBtn" (click)="transaction.transaction.showMultipleSessionDetails = true"><a style="text-decoration: underline;"> More Details </a> <mat-icon>keyboard_arrow_down_icon</mat-icon></button>
                          <button *ngIf="transaction.transaction.showMultipleSessionDetails" class="expansionBtn" (click)="transaction.transaction.showMultipleSessionDetails = false"><a style="text-decoration: underline;"> More Details </a> <mat-icon>keyboard_arrow_up_icon</mat-icon></button>
                          <div class="multiSessionDetailsContainer" *ngIf="transaction.transaction.showMultipleSessionDetails">
                            <div style="display: flex; margin-bottom: 15px;" *ngFor="let details of transaction.transaction.scheduledSessionsDetails">
                              <div class="transactionVal" style="color: #F7524B; margin-right: 10px; margin-left: 5px;">-01</div>
                              <div>
                                <div class="transactionExtraDetails">Date: {{details.date.toDate() | date:'dd MMMM, YYYY'}}</div>
                                <div class="transactionExtraDetails">Time: {{details.startTime}} - {{details.endTime}}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div> -->

                      <div class="transactionDetailsWrapper">
                        <div style="margin-bottom: 8px;" *ngIf="transaction.transaction.transactionType != 'renewalTransaction'" class="transactionType">{{ transaction.transaction['reason'] }}</div>
                        <div style="margin-bottom: 8px;" *ngIf="transaction.transaction.transactionType == 'renewalTransaction'" class="transactionType">Top up by parent</div>

                        <div
                          *ngIf="transaction.transaction.transactionType == 'session' && !transaction.transaction.cretedViaMultipleBookings">
                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                            *ngIf="transaction.transaction.serviceType">
                            {{transaction.transaction['serviceType']}} with
                            {{transaction.transaction['sessionExpertName']}}
                          </p>

                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                            *ngIf="transaction.transaction.sessionDate">
                            {{transaction.transaction.session?.sessionDate.toDate() | date:'dd/MM'}}
                            {{transaction.transaction.sessionStartTime}}-{{transaction.transaction.sessionEndTime}} IST
                          </p>
                        </div>

                        <div *ngIf="transaction.transaction.transactionType == 'consultation'">
                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                            *ngIf="transaction.transaction.serviceType">
                            {{transaction.transaction['serviceType']}} with
                            {{transaction.transaction['sessionExpertName']}}
                          </p>

                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                            *ngIf="transaction.transaction.sessionDate">
                            {{transaction.transaction.session?.sessionDate.toDate() | date:'dd/MM'}}
                            {{transaction.transaction.sessionStartTime}}-{{transaction.transaction.sessionEndTime}} IST
                          </p>

                        </div>

                        <div
                          *ngIf="transaction.transaction.transactionType == 'session' && (transaction.transaction.cretedViaMultipleBookings  || transaction.transaction.createdWithMultipleSessions)">
                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                            {{transaction.transaction['serviceType']}} with
                            {{transaction.transaction['sessionExpertName']}}
                          </p>

                          <button *ngIf="!transaction.transaction.showMultipleSessionDetails" class="expansionBtn"
                            (click)="transaction.transaction.showMultipleSessionDetails = true"><a
                              style="text-decoration: underline;"> More Details </a>
                            <mat-icon>keyboard_arrow_down_icon</mat-icon>
                          </button>
                          <button *ngIf="transaction.transaction.showMultipleSessionDetails" class="expansionBtn"
                            (click)="transaction.transaction.showMultipleSessionDetails = false"><a
                              style="text-decoration: underline;"> More Details </a>
                            <mat-icon>keyboard_arrow_up_icon</mat-icon>
                          </button>
                          <div class="multiSessionDetailsContainer"
                            *ngIf="transaction.transaction.showMultipleSessionDetails">

                            <div style="display: flex; margin-bottom: 15px;"
                              *ngFor="let details of transaction.transaction.scheduledSessionsDetails; let i = index">

                              <div class="transactionVal" style="color: #F7524B; margin-right: 10px; margin-left: 5px;">
                                -01</div>
                              <div>
                                <div class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                                  {{details.date.toDate() | date:'dd/MM'}} {{details.startTime}}-{{details.endTime}} IST
                                </div>

                                <div class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: black;">
                                  {{transaction.transaction.sessionId[i]}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <!-- for renewal transactions -->
                        <div
                          *ngIf="transaction.transaction.transactionType == 'renewalTransaction' && transaction.transaction.paymentStatus == 'Successfull'">

                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                            Amount: {{transaction.transaction.amount}} INR
                          </p>

                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                            Number of Sessions: {{transaction.transaction.sessions}}
                          </p>

                          <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: black;">
                            Transaction ID: {{transaction.transaction.id}}
                          </p>

                        </div>


                      </div>

                      <div class="transactionTime">
                        {{transaction.transaction.createdAt.toDate() | date:"HH:mm"}} IST
                      </div>

                    </div>
                  </mat-list-item>
                  <hr style="margin: 30px 0px;">
                </div>
              </mat-list>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </mat-tab>
  </mat-tab-group>
  <router-outlet></router-outlet>
</div>
