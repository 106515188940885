import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/helper.service';
import firebase from 'firebase/app';
import { AdminService } from 'src/app/services/admin.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-admin-expert-tab',
  templateUrl: './admin-expert-tab.component.html',
  styleUrls: ['./admin-expert-tab.component.css']
})
export class AdminExpertTabComponent implements OnInit, OnDestroy {
  filter = "registered";
  services: string[] = [];
  balances = {};
  experts: AngularFirestoreDocument[] = [];
  expertsCopy: AngularFirestoreDocument[] = [];
  expertSub: Subscription;
  balanceSub: Subscription;
  selectedBalance: string;
  selectedServices: string[];
  newExpert = false;
  currentUser: AngularFirestoreDocument;

  constructor(private adminService: AdminService, private notificationService: NotificationService, private helper: HelperService, private firestore: AngularFirestore, private router: Router, private auth: AngularFireAuth) { }

  async ngOnInit() {
    this.services = this.helper.services;
    this.expertSub = this.adminService.AllExperts.subscribe(
      (result => {
        this.experts = result;
        this.expertsCopy = this.experts;
        for (let expert of this.experts)
          expert = this.getBalance(expert);
      })
    );

    this.auth.currentUser.then(
      (user) => {
        console.log(user?.uid);
        this.firestore.doc(`users/${user?.uid}`).valueChanges().pipe(take(1)).subscribe( //getting current admin user details and storing them in a document
          (userDoc: AngularFirestoreDocument) => {
            this.currentUser = userDoc;
          }
        );
      }
    );

  }

  onFilterSelected(filter: string) {
    this.filter = filter;
  }

  addExpert() {
    this.newExpert = true;
  }

  verify(expert:any) {
    this.firestore.doc(`users/${expert.id}`)
      .update({ verified: true, activityStatus:"Session & Consultation Disabled"})
      .then(() => {
        console.log("Verified"); //verifying unverified experts and then sending a notification
        let body1 = {
          'userId': this.currentUser['interaktId'],
          'event': 'expert verified by admin - notify admin',
          'traits': {
            'expertName': expert.fullName,
          }
        }
        let body2 = {
          'userId': expert.interaktId,
          'event': 'expert verified by admin - notify expert',
          'traits': {
            'expertName': expert.fullName,
          }
        }
        this.notificationService.sendWhatsappNotification(body1);
        this.notificationService.sendWhatsappNotification(body2);
      }).catch((error) => { console.log(error) });
  }

  refresh() {
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(`${this.currentUser['role']}/dashboard`); //refresh page hack without using window object
    });
  }

  onBalanceChange(balance: string) {
    this.selectedBalance = balance;
    this.filterExperts(this.selectedBalance, this.selectedServices); //filtering on balance change
  }

  onServicesChange(services: string[]) {
    this.selectedServices = services;
    this.filterExperts(this.selectedBalance, this.selectedServices); //filtering on services selected
  }

  filterExperts(balance: string = undefined, services: string[] = undefined) {
    let balanceValidity = !(balance === undefined || balance === "");
    let servicesValidity = !(services === undefined || services.length === 0); //checking balance and service filter validity
    this.experts = this.expertsCopy;
    if (!balanceValidity && servicesValidity) {
      this.experts = this.experts.filter(expert => {
        if (expert['serviceTypes']) return services.every(service => expert['serviceTypes'].includes(service))
        else return false;
      });
    } else if (balanceValidity && !servicesValidity) {
      this.experts = this.experts.filter(expert => expert['currentBalance'] === balance)
                                      //handling each case of balance/services filters being defined/undefined and filtering accordingly
    } else if (!balanceValidity && !servicesValidity) {
      this.experts = this.expertsCopy;
    } else {
      this.experts = this.experts.filter(expert => {
        if (expert['serviceTypes']) return (expert['currentBalance'] === balance && services.every(service => expert['serviceTypes'].includes(service)));
        else return false;
      });
    }
  }

  findExperts(){
    this.router.navigateByUrl("/support/find-expert");
  }

  getBalance(expert: AngularFirestoreDocument): AngularFirestoreDocument {
    this.firestore.collection('balances').doc(expert['id']).valueChanges().pipe(take(1)).subscribe(
      result => {
        if (result)
          expert['currentBalance'] = `${result['currentBalance']}`; //getting balance doc of an expert
        else
          expert['currentBalance'] = "No balance data exists";
        return expert;
      }
    );
    return;
  }

  onView(expertId: string) {
    this.router.navigateByUrl(`/support/expert/${expertId}`);
  }

  ngOnDestroy() {
    if (this.expertSub)
      this.expertSub.unsubscribe();
  }

}
