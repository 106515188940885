<div style="float: right; z-index:1; cursor: pointer;">
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div class="sessionDialogWrapper">
    <mat-card-title style="margin-bottom: 20px !important; line-height: 27px;">Do you want to send the following invite ?</mat-card-title>

    <h3>To:</h3>
    <p>{{data.invitation.name}}</p>
    <p>{{data.invitation.phoneNumber}}</p>

    <h3>Sessions:</h3>
    <p>Balance: {{data.invitation.numberOfSessions}}</p>
    <!-- <p>Frequency per week: {{data.invitation.reccomendedFrequency}}</p> -->

    <h3>Experts:</h3>
    <p *ngFor="let expert of data.invitation.educators">{{expert.educatorName}} ({{expert.service}}) - Frequency: {{expert.reccomendedFrequency}}</p>


    <button mat-button id="addSessionPackBtn" class="right" (click)="send()">Send</button>
    <button mat-button id="addSessionPackBtn" class="right" (click)="doNotSend()">Back</button>
</div>