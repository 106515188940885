<mat-select *ngIf="currentUser?.role == 'support' || currentUser?.role == 'sales-admin'" style="margin: 15px 0px;" disableOptionCentering [(value)]="filter"
  (selectionChange)="onFilterSelected($event.value)" panelClass="dropdown3" class="activitySelectBox"
  placeholder="Select">
  <mat-option class="filterOptions" *ngFor="let status of getParentStatus()" value="{{ status }}">
    {{status}}
  </mat-option>
</mat-select>

<mat-select *ngIf="currentUser?.role == 'program'" style="margin: 15px 0px;" disableOptionCentering [(value)]="filter"
  (selectionChange)="onFilterSelected($event.value)" panelClass="dropdown3" class="activitySelectBox"
  placeholder="Select">
  <mat-option class="filterOptions" *ngFor="let status of getParentStatusForProgramRole()" value="{{ status }}">
    {{status}}
  </mat-option>
</mat-select>

<h1 style="margin-bottom: 0px;"><b>Sort by: </b></h1>

<!-- list of invited parents -->
<div class="invited" *ngIf="filter==='Invited'">
  <div class="inviteOperations">
    <div class="sortHolder">
      <mat-form-field style="margin-right: 10px">
        <mat-label>Balance</mat-label>
        <input type="number" matInput (change)="onBalanceChange(balanceVal.value);" #balanceVal [(ngModel)]="balance">
      </mat-form-field>
      <span>
        <mat-form-field>
          <mat-label>Invited On</mat-label>
          <input matInput [(ngModel)]="selectedDate" [matDatepicker]="picker" (dateChange)="onDateChange()"
            id="invitedOn">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-icon id="clearIcon" (click)="resetPicker()">clear</mat-icon>
      </span>
    </div>

    <span>
      <a mat-button class="inviteButton" (click)="inviteParent()">
        New Invite
      </a>
    </span>
  </div>

  <div>
    <mat-card *ngFor="let invitee of invitations" class="details-container-1">
      <div class="profileDetails">
        <span class="labelFields">Parent</span>
        <span class="detailFields">{{invitee?.name ? invitee?.name : "-"}}</span>
      </div>
      <div class="profileDetails">
        <span class="labelFields">Phone</span>
        <span class="detailFields">{{ isArray(invitee?.verificationNumbers) ? (invitee?.verificationNumbers).toString()
          : "-" }}</span>
      </div>
      <div class="profileDetails">
        <span class="labelFields">Email Id</span>
        <span class="detailFields">{{invitee?.eMail ? invitee?.eMail : "-"}}</span>
      </div>
      <div class="profileDetails">
        <span class="labelFields">Balance</span>
        <span class="detailFields">{{invitee?.numberOfSessions ? invitee?.numberOfSessions : "-"}}</span>
      </div>
      <div class="profileDetails">
        <span class="labelFields">Expert</span>
        <span class="detailFields" style="display: inline-flex;">
          <p *ngFor="let educator of invitee.educators" style="margin-right: 7px;" class="expertLink">
            <a style="color: black; text-decoration: none;" [routerLink]="'/support/expert/'+educator.educatorId">{{
              educator.educatorName }}</a>
          </p>
        </span>
      </div>
    </mat-card>
  </div>
</div>

<!-- list of active parents -->
<div class="registered" *ngIf="filter==='Active'">
  <div class="sortHolder">
    <mat-form-field style="margin-right: 10px">
      <mat-label>Balance</mat-label>
      <input type="number" matInput (change)="onBalanceChange(balanceVal.value);" #balanceVal [(ngModel)]="balance">
    </mat-form-field>
    <span>
      <mat-form-field id="educatorMatFormField">
        <mat-label>Educators</mat-label>
        <input type="text" matInput [(ngModel)]="expertName" placeholder="Expert Name" #educatorField="matInput"
          (ngModelChange)="resetExpertInput($event)" (blur)="onExpertBlur()" (focus)="onExpertFocus()">
        <div class="expertDropdown" *ngIf="isExpertClicked">
          <mat-list id="expertList">
            <mat-list-item *ngFor="let expert of experts | filter: expertName" (click)="onExpertClick(expert)"
              class="expertListItem">
              {{ expert.fullName }}
            </mat-list-item>
          </mat-list>
        </div>
      </mat-form-field>
    </span>
  </div>

  <mat-card *ngFor="let parent of parents" class="details-container-1">
    <div class="profileDetails">
      <span class="labelFields">Parent</span>
      <button (click)="onView(parent)"
        style="font-weight: 600; color: #F7524B; border: none; background: none; padding: 0px;" class="detailFields"><a
          style="color: #F7524B; border-bottom: 2px solid #F7524B; cursor: pointer;">{{parent?.fullName ?
          parent?.fullName : "-"}}</a></button>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Child</span>
      <span class="detailFields" style="font-weight: 600;">{{parent?.childName ? parent?.childName : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Balance</span>
      <span class="detailFields">{{parent?.currentBalance}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Id</span>
      <span class="detailFields">{{parent?.id ? parent?.id : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields" style="width: 30%;">Last Session</span>
      <span class="detailFields">{{parent?.lastSession ? parent?.lastSession : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields" style="width: 30%;">Next Session</span>
      <span class="detailFields">{{parent?.nextSession ? parent?.nextSession : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Expert</span>
      <span style="display: inline-flex;" class="detailFields">
        <p *ngFor="let expert of parent.myExperts" style="margin-right: 7px;" class="expertLink">
          <a style="color: black; text-decoration: none;" [routerLink]="'/support/expert/'+expert.educatorId">{{
            expert.educatorName }}</a>
        </p>
      </span>
    </div>

    <div class="profileDetails">
      <span class="labelFields"></span>
      <span class="detailFields" style="display: flex; justify-content: end; margin-top: 10px;"><a
          (click)="openInteraktChat(parent)" style="cursor: pointer; margin-right: 20px;" target="_blank"><img id="help"
            src="../../../assets/get_help.svg"></a></span>
    </div>
  </mat-card>
</div>

<!-- list of renewal parents -->
<div class="registered" *ngIf="filter==='Renewal'">
  <div class="sortHolder">
    <mat-form-field style="margin-right: 10px">
      <mat-label>Balance</mat-label>
      <input type="number" matInput (change)="onBalanceChange(balanceVal.value);" #balanceVal [(ngModel)]="balance">
    </mat-form-field>
    <span>
      <mat-form-field id="educatorMatFormField">
        <mat-label>Educators</mat-label>
        <input type="text" matInput [(ngModel)]="expertName" placeholder="Expert Name" #educatorField="matInput"
          (ngModelChange)="resetExpertInput($event)" (blur)="onExpertBlur()" (focus)="onExpertFocus()">
        <div class="expertDropdown" *ngIf="isExpertClicked">
          <mat-list id="expertList">
            <mat-list-item *ngFor="let expert of experts | filter: expertName" (click)="onExpertClick(expert)"
              class="expertListItem">
              {{ expert.fullName }}
            </mat-list-item>
          </mat-list>
        </div>
      </mat-form-field>
    </span>
  </div>

  <mat-card *ngFor="let parent of renewalParents" class="details-container-1">
    <div class="profileDetails">
      <span class="labelFields">Parent</span>
      <button (click)="onView(parent)"
        style="font-weight: 600; color: #F7524B; border: none; background: none; padding: 0px;" class="detailFields"><a
          style="color: #F7524B; border-bottom: 2px solid #F7524B; cursor: pointer;">{{parent?.fullName ?
          parent?.fullName : "-"}}</a></button>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Child</span>
      <span class="detailFields" style="font-weight: 600;">{{parent?.childName ? parent?.childName : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Balance</span>
      <span class="detailFields">{{parent?.currentBalance}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Scheduled Sessions</span>
      <span class="detailFields">{{parent?.numberOfScheduledSessions ? parent?.numberOfScheduledSessions : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields" style="width: 30%;">Last Attended Session</span>
      <span class="detailFields">{{parent?.lastAttendedSession ? parent?.lastAttendedSession : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields" style="width: 30%;">Last Scheduled Session</span>
      <span class="detailFields">{{parent?.lastScheduledSession ? parent?.lastScheduledSession : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Expert</span>
      <span style="display: inline-flex;" class="detailFields">
        <p *ngFor="let expert of parent.myExperts" style="margin-right: 7px;" class="expertLink">
          <a style="color: black; text-decoration: none;" [routerLink]="'/support/expert/'+expert.educatorId">{{
            expert.educatorName }}</a>
        </p>
      </span>
    </div>

    <div style="display: flex; flex-direction: column; align-items: end; padding-right: 15px;">
      <span>
        <a mat-button class="inviteButton" style="margin: 5px 0px;" (click)="openSessionPackDialog(parent)">
          Add Balance
        </a>
      </span>
      <span>
        <a mat-button class="inviteButton" style="color: #F7524B; margin: 10px 0px; background: rgba(255, 92, 92, 0.1);;" (click)="changeParentActivityStatus(parent, 'Inactive')">
          Not Interested
        </a>
      </span>
    </div>
  </mat-card>
</div>


<!-- list of inactive parents -->
<div class="registered" *ngIf="filter==='Inactive'">
  <div class="sortHolder">
    <span>
      <mat-form-field>
        <mat-label>Created On</mat-label>
        <input matInput [(ngModel)]="selectedDate" [matDatepicker]="picker" (dateChange)="onDateChange()"
          id="invitedOn">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-icon id="clearIcon" (click)="resetPicker()">clear</mat-icon>
    </span>
  </div>

  <mat-card *ngFor="let parent of inactiveParents" class="details-container-1">
    <div class="profileDetails">
      <span class="labelFields">Parent</span>
      <button (click)="onView(parent)"
        style="font-weight: 600; color: #F7524B; border: none; background: none; padding: 0px;" class="detailFields"><a
          style="color: #F7524B; border-bottom: 2px solid #F7524B; cursor: pointer;">{{parent?.fullName ?
          parent?.fullName : "-"}}</a></button>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Child</span>
      <span class="detailFields" style="font-weight: 600;">{{parent?.childName ? parent?.childName : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Contact</span>
      <span class="detailFields">{{parent?.phoneNumber ? parent?.phoneNumber : ""}}, {{parent?.whatsappNumber ? parent?.whatsappNumber : ""}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Id</span>
      <span class="detailFields">{{parent?.id ? parent?.id : "-"}}</span>
    </div>

    <div class="profileDetails">
      <span class="labelFields">Expert</span>
      <span style="display: inline-flex;" class="detailFields">
        <p *ngFor="let expert of parent.myExperts" style="margin-right: 7px;" class="expertLink">
          <a style="color: black; text-decoration: none;" [routerLink]="'/support/expert/'+expert.educatorId">{{
            expert.educatorName }}</a>
        </p>
      </span>
    </div>

    <div style="display: flex; flex-direction: column; align-items: end; padding-right: 15px;">
      <span>
        <a mat-button class="inviteButton" style="margin: 5px 0px;" (click)="openSessionPackDialog(parent)">
          Add Balance
        </a>
      </span>
    </div>
  </mat-card>

</div>