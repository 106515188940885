import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FeedbackCompletedComponent } from '../feedback-completed/feedback-completed.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { HelperService } from '../helper.service';



@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})
export class FeedbackDialogComponent implements OnInit {
  sessionId: string;
  session:any;
  token:any;
  feedback: {};
  parentId: string;
  formValidationCheck = 0;

  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private auth: AngularFireAuth,
    public dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationService,
    public helper: HelperService,
    public dialogRef: MatDialogRef<FeedbackDialogComponent>

    
  ) { }

  ngOnInit(): void {
    this.auth.onAuthStateChanged(user => {
      if(user){
        this.parentId = user.uid;
        this.adminService.getStudentLastSession(user.uid).subscribe(
          (sessionList: AngularFirestoreDocument[]) => {
          this.session = sessionList[0];
        })
      }
    })
  }
  feedbackFlag = 0;

  Setfeedback(value: string, id) {
    if (this.feedbackFlag == 1) {
      var btns = document.getElementsByClassName("smiley");
      for (var i = 0; i < btns.length; i++) {
        btns[i].classList.remove("active");
      }
    }
    var btn = document.getElementById(id);
    btn.classList.add("active");
    this.feedbackFlag = 1;
    this.formValidationCheck = 0;
    this.feedback = value;
  }
  

  submit(){
    if (this.feedbackFlag == 0) {
      this.formValidationCheck = 1;
      return;
    }
    if(this.session.id){
      const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.session.sessionDate.toDate());
      const docRef =  this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(this.session.id);
      docRef.update({
        "feedback.parent.feedback": this.feedback
      }).then(() => {
        console.log("Successful feedback");
        this.dialogRef.close(true);
        const dialogRef = this.dialog.open(FeedbackCompletedComponent, { panelClass: 'custom-dialog-container' });
        if (this.feedback == '1 star' || this.feedback == '2 star') {
          let rating: string;
          if(this.feedback=='1 star')
            rating="1";
          else 
            rating="2";
          let expertRef = this.firestore.collection('users', expertRef => expertRef.where('role', '==', 'support'));
          expertRef.get().subscribe((snapshot) => {
            if (snapshot.empty) {
                console.log('no Ongoing sessions found')
                return;
            }
            const promises: Promise<any>[] = [];
            snapshot.forEach(async (doc) => {
                let user: any = doc.data()
                if (user.interaktId && user.interaktId !== '') {
                    let body1: any = {
                        'userId': user.interaktId,
                        'event': 'Bad rating report to support',
                        'traits': {
                          'parent_name': this.session.sessionParentName,
                          'service': this.session.serviceType,
                          'expert_name': this.session.sessionExpertName,
                          'rating': rating,
                          'comment_entered_by_parent': "No Comment"
                        },
                    }
                    this.notificationService.sendWhatsappNotification(body1);

                }
            })
        })
        }
      }).catch(err => console.log(err))
    }
  }

}
