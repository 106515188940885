import { AfterContentInit, AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';

var grecaptcha: any;
@Component({
  selector: 'app-parent-signin',
  templateUrl: './parent-signin.component.html',
  styleUrls: ['./parent-signin.component.css']
})
export class ParentSigninComponent implements OnInit, AfterViewInit {
  phoneNumberCountryCode = '+91';
  @ViewChild('phoneNumberCtrl', { static: false }) phoneNumber: ElementRef;
  OTPSent = false;
  phoneNo: string;
  OTPPromise: Promise<any>
  OTP: string;
  invitationDoc: any;

  constructor(private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private ngZone: NgZone,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.auth.onAuthStateChanged(user => {
      this.checkIfRegisteredUser(user);
    })
  }

  ngAfterViewInit() {
    this.setWindowRecaptchaVerifier();
  }

  setWindowRecaptchaVerifier(){
    window['recaptchaVerifier'] = new firebase.auth.RecaptchaVerifier('sign-in-recaptcha', { //initializing recaptcha to the window
      'size': 'invisible',
      'callback': () => { this.sendOTP(); }
    });
  }

  sendOTP() {
    console.log(this.phoneNumberCountryCode + this.phoneNumber.nativeElement.value);
    const phoneNo = this.phoneNumberCountryCode + this.phoneNumber.nativeElement.value;
    this.phoneNo = phoneNo;
    const appVerifier: firebase.auth.ApplicationVerifier = window['recaptchaVerifier'];

    //sending otp to given number
    this.OTPPromise = this.auth.signInWithPhoneNumber(phoneNo, appVerifier).catch(async (error) => {
      console.log(error);

      // logging the error in errors collection
      let ref = await this.firestore.collection('errors').add({
        event: 'parent signin',
        type: 'OTP error',
        error: error,
        parentPhoneNumber: this.phoneNo
      })

      if (error?.code == 'auth/too-many-requests')
      this.toastr.error(error?.message, "Too many requests"); //showing otp/auth errors when applicable
      else
      this.toastr.error(error?.message, "Authentication Error");
      
      this.OTPSent = false;
    });
    this.OTPSent = true;
  }

  //changed for converting to string 
  checkOTP(event) {
    this.OTP = event;
  }

  resendOTP() {
    // window['recaptchaVerifier'].reset(); //doesnt work, supposed to resent otp, refreshes recaptcha but otp is not resent
    // this.sendOTP();

    window['recaptchaVerifier'].reset();
    this.setWindowRecaptchaVerifier();

  }

  verifyOTP(OTP: string) {
    console.log(OTP);

    this.OTPPromise
      .then((confirmationResult) => {
        console.log(confirmationResult);

        var credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, OTP);
        return firebase.auth().signInWithCredential(credential);

        // return confirmationResult.confirm(OTP);
      })
      .then(result => {
        this.successCallback(result);
      })
      .catch((error) => {
        console.log(error);
        if (error?.code == 'auth/invalid-verification-code')
          this.toastr.error("Please enter the correct one time password sent to " + this.phoneNo, "Authentication Error");
        else
          this.toastr.error(error?.message, "Authentication Error");
      });
  }

  async successCallback(result) {
    console.log(result);
    // this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    //Check if user is already registered.
    this.checkIfRegisteredUser(result.user);
  }

  public checkIfRegisteredUser = (user): Promise<string | void> => {
    if (user) {
      var userRef = this.firestore.collection('users').doc(user.uid);
      return userRef.get().toPromise().then(this.onUser).catch(function (error) {
        console.log("Error getting document:", error);
      });
    }
  }

  public onUser = (result): any => {
    if (result.exists) {
      var user = result.data();
      console.log('user  ', user)
      // If parent is already registered and verified
      if (user['verified'] && user['registered']) {
        this.ngZone.run(() => this.router.navigateByUrl("/" + user["role"] + "/dashboard"));

        // It is a verified parent but have not registered yet.
      } else if (user['verified'] && !user['registered']) {
        this.ngZone.run(() => this.router.navigateByUrl("/" + user["role"] + "/register"));
      } else if (!user['verified'] && !user['registered']) {

        // if user already signed up but did not register
        if (user['authenticated']) {
          this.ngZone.run(() => this.router.navigateByUrl("/" + user["role"] + "/register"));

          // if user is signing up for the first time. 
        } else {
          this.checkForInvitation();
        }

      } else this.checkForInvitation();
      return user;

      // It is a new parent, check if they are invited or not
    } else {
      this.checkForInvitation();
    }
  }

  async checkForInvitation() {
    let phoneNumber = await firebase.auth().currentUser.phoneNumber;
    let eMail = await firebase.auth().currentUser.email;
    if (phoneNumber) {
      let inviteRef = await this.firestore.collection('invitations', inviteRef => inviteRef.where('verificationNumbers', 'array-contains', phoneNumber));
      await inviteRef.get().subscribe(this.onInvitationResult)
    } else if (eMail) {
      let inviteRef = await this.firestore.collection('invitations', inviteRef => inviteRef.where('eMail', '==', eMail));
      await inviteRef.get().subscribe(this.onInvitationResult)
    }
  }

  onInvitationResult = (result) => {
    console.log(result);
    if (!result.empty) {
      let docs = [];
      result.forEach((doc) => {
        docs.push(doc.data());
      });

      let invitation: any = docs[0]

      if(!invitation.registered){
        this.saveNewParent(docs[0]);
      } else {
        console.log('i will return from here...')
        // this.router.navigateByUrl("/not-found");
      }
    } else {
      console.log('not getting invite document tell the parents to signup with verified number or email');
      // this.router.navigateByUrl("/parent/invalid-login");
      this.ngZone.run(() => this.router.navigateByUrl("/parent/invalid-login"));
    }
  }

  // this will be called for the new parent
  async saveNewParent(result) {
    this.invitationDoc = result;
    console.log('save new parent is calling');
    var usersRef = this.firestore.collection("users");
    const id = await firebase.auth().currentUser.uid;
    const name = await firebase.auth().currentUser.displayName;
    const email = this.invitationDoc.eMail;
    const phoneNumber = await firebase.auth().currentUser.phoneNumber;
    const createdBy = firebase.auth().currentUser.uid;
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    usersRef.doc(id).set({
      id: id,
      interaktId: result.userId,
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      role: 'parent',
      myExpertsIds: result.myExpertsIds,
      myExperts: result.educators,
      sessionPackValue: result.sessionPackValue,
      numberOfSessions: result.numberOfSessions,
      countryCode: result.whatsappNumberCountryCode,
      whatsappNumber: result.whatsappNumber,
      verified: false,
      registered: false,
      createdOn: timestamp,
      authenticated: true,
      createdBy: createdBy
    }).then(this.onParentCreated)
      .catch(function (error) {
        console.error("Error creating User: ", error);
      });
    return;
  }

  onParentCreated = async () => {
    await this.firestore.collection('invitations').doc(this.invitationDoc.id).update({ registered: true }).then().catch(err => console.log('error is ', err))
    this.router.navigateByUrl("/parent/register");
  }

  errorCallback(event) { }

  onPhoneNumberCountryChange(event) {
    this.phoneNumberCountryCode = '+' + event.dialCode;
  }

}
