import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private firestore: AngularFirestore, private http: HttpClient) { }

  get WindowRef() {
    return window;
  }

  // triggering the cloud function.
  createOrder(orderDetails) {
    return this.http.post(environment.cloudFunctions.createOrder, orderDetails);
  }

  capturePayment(paymemntDetails) {
    return this.http.post(environment.cloudFunctions.capturePayment, paymemntDetails);
  }
}
