<!-- loading screen starts here -->
<div *ngIf="true" class="text-center my-4" style="margin: 70px 0px 120px 0px;">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div>
      <h2 style="color: black;">Please wait... <br></h2>
    </div>
  </div>
  <!-- loading screen ends here -->