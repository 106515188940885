import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HelperService } from '../helper.service';
import firebase from 'firebase/app';
import { SessionService } from '../services/session.service';
import { ParentsService } from '../services/parents.service';
import { ToastrService } from 'ngx-toastr';
import { timestamp } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-new-session-request',
  templateUrl: './new-session-request.component.html',
  styleUrls: ['./new-session-request.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class NewSessionRequestComponent implements OnInit {
  experts: any[];
  expertsLeaves: any[];
  isExpertSelected: boolean;
  isSessionTypeSelected = false;
  showSlots: boolean = false;
  isExpertOnLeave: boolean = false;
  isMultipleSession: boolean;
  isLessThanSlotRange: boolean;
  slotDuration = 60;
  selectedSlot: any;
  selectedDate: any;
  sessionTypes: any;
  selectedSessionType: string;
  sessionPerWeek = 1;
  sessionStartTime: any;
  sessionEndTime: any;
  sessionEndDateTime: any;
  selectedExpert: any;
  selectedSessionDate: any;
  getSlotsEnabled: boolean = false;
  showBookSession = false;
  chosenExpert: any;
  chosenDate: Date;
  disableBookButton = false;
  isExpertActive: boolean = true;

  constructor(
    private firestore: AngularFirestore,
    public helper: HelperService,
    public sessionService: SessionService,
    private notificationService: NotificationService,
    public parentsService: ParentsService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.getExperts();
    this.parentsService.getCurrentUserDetails();
    this.parentsService.getCurrentUsersBalanceDetails();
  }

  getNumbers() {
    return this.helper.numbers;
  }

  getExperts() {
    this.experts = this.parentsService.myExperts
  }


  // onExpertsResult = (results) => {
  //   if(results){
  //     this.experts = [];
  //     results.forEach(result=>{
  //       this.experts.push(result.data())
  //     })
  //   }
  // }

  onExpertSelected(expert) {
    // checking the activity status of expert
    if(expert.activityStatus == "Session Disabled" || expert.activityStatus == "Session & Consultation Disabled"){
      this.isExpertActive = false;
      return;
    }

    this.isExpertActive = true;
    this.expertsLeaves = [];
    let leaveStartTimeStamp = firebase.firestore.Timestamp.now();
    let seconds = leaveStartTimeStamp.seconds;
    let leaveEndSeconds = seconds + 2592000;
    let leaveEndTimeStamp = firebase.firestore.Timestamp.fromMillis(leaveEndSeconds * 1000);
    this.isExpertSelected = true;
    this.selectedExpert = expert;
    this.showSlots = false;

    let leaveRef = this.firestore.collection('leaves', leaveRef => leaveRef.where('leaveBy', '==', expert.id).where('leaveStartDateTime', '>=', leaveStartTimeStamp).where('leaveStartDateTime', '<=', leaveEndTimeStamp));
    leaveRef.get().subscribe((results) => {
      if (results.empty) {
        return;
      } else {
        results.forEach((doc) => {
          let leave: any = doc.data();
          console.log(leave)
          this.expertsLeaves.push(leave);
        })
        this.isExpertOnLeave = true;
      }
    });

    for (let i = 0; i < this.parentsService.myExpertsDetails.length; i++) {
      if (this.selectedExpert.id == this.parentsService.myExpertsDetails[i].educatorId) {
        this.sessionTypes = this.parentsService.myExpertsDetails[i].service;
        break;
      }
    }

  }

  onSessionTypeSelected(event: any) {
    this.isSessionTypeSelected = true;
    this.selectedSessionType = event;
  }

  selectedNumberOfSession(number) {
    if (number > 1) {
      // this.isMultipleSession = true;
      console.log('we will come here later')
    } else {
      this.isMultipleSession = false;
    }
  }

  onSessionPerWeekInc() {
    this.sessionPerWeek += 1;
  }
  onSessionPerWeekDec() {
    this.sessionPerWeek -= 1;
  }


  onDateSelected(d: Date) {
    let date = new Date(d);
    this.showBookSession = false;
    this.showSlots = false;
    this.chosenDate = date;
    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);
    if (date.getDate() > now.getDate()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else if (date.getMonth() > now.getMonth()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else if (date.getFullYear() > now.getFullYear()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else {
      this.getSlotsEnabled = false;
      this.isLessThanSlotRange = true;
    }

    this.getFreeSlots();
  }

  async getFreeSlots() {
    this.getSlotsEnabled = false;
    this.showSlots = true;
    let id = this.selectedExpert.id;
    let date = new Date(this.chosenDate);
    let slotDuration = this.slotDuration;
    this.sessionService.checkForSlots(id, date, slotDuration);
  }

  onSlotSelected(slotValue) {
    this.showBookSession = true;
    this.selectedSlot = slotValue;
    this.setSessionTimings();
  }

  setSessionTimings() {
    let sessionStartTime = this.selectedSlot.split(":");

    // to get session starting time in minutes
    const startingHour = parseInt(sessionStartTime[0], 10);
    const startingMinute = parseInt(sessionStartTime[1], 10);
    sessionStartTime = (startingHour * 60) + startingMinute;
    let slotDuration = this.slotDuration;

    // this is session end time in minutes
    let sessionEndTime = sessionStartTime + slotDuration;

    let endHour = Math.floor(sessionEndTime / 60);
    let endMinute = sessionEndTime % 60;

    // creating a session end timestamp for filter comparison
    // this.sessionEndDateTime = this.selectedDate;
    // this.sessionEndDateTime.setHours(endHour, endMinute, 0, 0);

    let istSessionEndDateTime = this.helper.istTime3(this.selectedDate, endHour, endMinute);
    this.sessionEndDateTime = istSessionEndDateTime;


    if (endMinute == 0) {
      sessionEndTime = endHour + ":" + endMinute + '0';
    } else {
      sessionEndTime = endHour + ":" + endMinute;
    }

    this.sessionStartTime = this.selectedSlot;
    this.sessionEndTime = sessionEndTime;
  }

  saveSession() {

    if (this.parentsService.balanceDetails.currentBalance < 1) {
      let body: any = {
        'userId': this.parentsService.currentUserDetails.interaktId,
        'event': 'zero sessions left - notify parent',
        'traits': {
          'Name': this.parentsService.currentUserDetails.fullName,
        }
      }
      this.notificationService.sendWhatsappNotification(body);
      console.log('insufficient balance')
      return;
    }

    this.disableBookButton = true;
    var docRef;
    let sessionValue = 1.00;
    let session = {};

    // creating the unique id for the session
    let dateStringForId = this.selectedSessionDate.getDate() + '-' + (this.selectedSessionDate.getMonth() + 1) + '-' + this.selectedSessionDate.getFullYear();
    let id = dateStringForId + this.sessionStartTime + '-' + this.sessionEndTime + this.selectedExpert.id;

    let topicID = this.helper.uuidv4();
    docRef = this.firestore.collection("sessions").doc(id);
    const createdOn = firebase.firestore.FieldValue.serverTimestamp();
    const createdBy = firebase.auth().currentUser.uid;
    const createdByName = this.parentsService.currentUserDetails.fullName;

    session['id'] = id;
    session['topicID'] = topicID;
    session['sessionValue'] = sessionValue;

    session['createdOn'] = createdOn;
    session['sessionEndDateTime'] = this.sessionEndDateTime;
    session['sessionDate'] = this.selectedSessionDate;
    session['startTime'] = this.sessionStartTime;
    session['endTime'] = this.sessionEndTime;
    session['slotDuration'] = this.slotDuration;

    session['createdBy'] = createdBy;
    session['createdByName'] = createdByName;
    session['bookedBy'] = firebase.firestore.FieldValue.arrayUnion(createdBy);

    session['attendedBy'] = { parent: false, expert: false, admin: false }

    session['createdWith'] = this.selectedExpert.id;
    session['createdWithName'] = this.selectedExpert.fullName;

    session['sessionExpertId'] = this.selectedExpert.id;
    session['sessionExpertName'] = this.selectedExpert.fullName;

    session['sessionStudentId'] = createdBy;
    session['sessionStudentName'] = this.parentsService.currentUserDetails.childName;
    session['sessionParentName'] = this.parentsService.currentUserDetails.fullName;

    session['parentInteraktId'] = this.parentsService.currentUserDetails.interaktId;
    session['expertInteraktId'] = this.selectedExpert.interaktId;

    session['status'] = 'Scheduled';
    session['expertPaid'] = false;

    // let service: any;
    // for (let i = 0; i < this.parentsService.myExpertsDetails.length; i++) {
    //   if (this.selectedExpert.id == this.parentsService.myExpertsDetails[i].educatorId) {
    //     service = this.parentsService.myExpertsDetails[i].service;
    //     break;
    //   }
    // }

    session['serviceType'] = this.selectedSessionType;

    let date = new Date(this.selectedDate);
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    let body = {
      'userId': this.selectedExpert.interaktId,
      'event': 'new session booked by parent',
      'traits': {
        'sessionType': this.selectedSessionType,
        'childName': this.parentsService.currentUserDetails.childName,
        'parentName': createdByName,
        'date': dateString,
        'time': this.sessionStartTime + ' IST'
      }
    }

    docRef.set(session).then(() => {
      this.notificationService.sendWhatsappNotification(body);
      this.onSessionUpdated();
    }).catch(function (error) {
      console.error("Error creating Question: ", error);
    });
  }

  onSessionUpdated = () => {
    this.isExpertSelected = false;
    this.showSlots = false;
    this.isMultipleSession = null;
    //(<HTMLInputElement> document.getElementById("sessionRequestBtn")).disabled = true;
    this.showBookSession = false;
    var message = "New Session request has been sent to the educator"
    this.toastr.success(message, 'Session Requested!');
    this.router.navigate(['/parent/dashboard'])
  }

  dateFilter(date: Date) {
    let d = new Date(date)
    let currentTime = new Date();
    let currentOffset = currentTime.getTimezoneOffset();
    let ISTOffset = 330;   // IST offset UTC +5:30 
    let now = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);

    if (
      d.getDate() > now.getDate() &&
      d.getMonth() == now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (
      d.getMonth() > now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (d.getFullYear() > now.getFullYear()) return true;
    else if (d.getFullYear() < now.getFullYear()) return false;
  }

}
