<div class="page-container" *ngIf="isEditing && expert">
  <form [formGroup]="expertForm">
    <mat-horizontal-stepper formArrayName="profile" linear>
      <mat-step label="Welcome" *ngIf="profile.serviceTypes">
        <mat-card style="margin:20px" class="background-color-whitesmoke">
          <mat-card-header>
            <mat-card-title>Welcome</mat-card-title>
          </mat-card-header>
          <img mat-card-image class="card-image" src="assets/welcomeexpert.svg">
          <mat-card-content>
            <p>You are now a few steps away from completing your profile for improved reach. Please tell us
              a little
              more yourself.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button matStepperNext color="warn">Next</button>
          </mat-card-actions>
        </mat-card>
      </mat-step>

      <mat-step formGroupName="0" [stepControl]="profile.get([0])" label="Personal">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Personal Information</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field class="full-width-field">
              <mat-label>Full Name</mat-label>
              <input matInput formControlName="fullName" [required]="!isEditing">
              <mat-error *ngIf="hasErrors(0,'fullName')">{{getErrorMessage(0,'fullName')}}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field">
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="picker" #dateOfBirthCtrl formControlName="dateOfBirth"
                [required]="!isEditing">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="hasErrors(0,'dateOfBirth')">{{getErrorMessage(0,'dateOfBirth')}}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-field">
              <mat-label>Languages</mat-label>
              <mat-select panelClass="dropdown" formControlName="languages" multiple [required]="!isEditing"
                disableOptionCentering style="width: 100%;">
                <mat-option *ngFor="let language of getLanguages()" value="{{ language }}">{{ language
                  }}</mat-option>
              </mat-select>
              <mat-error *ngIf="hasErrors(0,'languages')">{{getErrorMessage(0,'languages')}}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-field">
              <mat-label>Email</mat-label>
              <input matInput placeholder="abc@email.com" formControlName="email" [required]="!isEditing">
              <mat-error *ngIf="hasErrors(0,'email')">{{getErrorMessage(0,'email')}}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-field">
              <mat-label class="contactLabel">Phone Number</mat-label>
              <input matInput ng2TelInput (countryChange)="onPhoneNumberCountryChange($event)" [required]="!isEditing"
                [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
                formControlName='phoneNumber' autocomplete="off" #phoneNumberCtrl type="text"
                pattern="^([1-9]{1}[0-9]{7}|[1-9]{1}[0-9]{8}|[1-9]{1}[0-9]{9})$">
              <mat-error *ngIf="hasErrors(0,'phoneNumber')">{{getErrorMessage(0,'phoneNumber')}}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button matStepperPrevious type="button" color="warn" (click)="isEditing = false"
              *ngIf="!fromAdmin">Back</button>
            <button mat-button matStepperPrevious type="button" color="warn" (click)="backToSupport()"
              *ngIf="fromAdmin">Back</button>
            <button mat-button matStepperNext type="button" color="warn">Next</button>
          </mat-card-actions>
        </mat-card>
      </mat-step>

      <mat-step formGroupName="1" stepControl="profile.get([1])" label="Services">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Your Services</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field class="full-width-field">
              <mat-label>Service Type</mat-label>
              <mat-select panelClass="dropdown serviceDropdown" #serviceTypeCtrl formControlName="serviceTypes" multiple
                [required]="!isEditing">
                <mat-option *ngFor="let service of getServices()" value="{{service}}">{{service}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasErrors(1,'serviceTypes')">{{getErrorMessage(1,'serviceTypes')}}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-field">
              <mat-label>Starting Age Range (youngest age) of clients</mat-label>
              <input matInput formControlName="startingAgeRange" type="number" [required]="!isEditing" min="0">
              <mat-error *ngIf="hasErrors(1,'startingAgeRange')">
                {{getErrorMessage(1,'startingAgeRange')}}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-field">
              <mat-label>Ending Age Range (oldest age) of clients</mat-label>
              <input matInput formControlName="endingAgeRange" type="number" [required]="!isEditing" min="0">
              <mat-error *ngIf="hasErrors(1,'endingAgeRange')">
                {{getErrorMessage(1,'endingAgeRange')}}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-field">
              <mat-label>Condition</mat-label>
              <mat-select panelClass="dropdown serviceDropdown" #conditionCtrl formControlName="condition" multiple
                [required]="!isEditing">
                <mat-option *ngFor="let condition of getConditions()" value="{{condition}}">
                  {{condition}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasErrors(1,'condition')">{{getErrorMessage(1,'condition')}}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-field">
              <mat-label>Years of Experience</mat-label>
              <input matInput formControlName="yearsOfExperience" type="number" [required]="!isEditing" min="0">
              <mat-error *ngIf="hasErrors(1,'yearsOfExperience')">
                {{getErrorMessage(1,'yearsOfExperience')}}</mat-error>
            </mat-form-field>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button matStepperPrevious type="button" color="warn">Back</button>
            <button mat-button matStepperNext type="button" color="warn">Next</button>
          </mat-card-actions>
        </mat-card>
      </mat-step>
      <mat-step formGroupName="2" stepControl="profile.get([2])" label="Qualifications">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Qualification</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div [formGroupName]="'qualifications'">
              <div *ngFor="let qualification of qualifications.controls; let i=index" formArrayName="{{i}}">
                <mat-card>
                  <mat-card-subtitle>
                    <span class="left">Qualification {{i + 1}}</span>
                    <span class="right">
                      <button mat-button type="button" (click)="deleteQualification(i)" color="warn">Delete</button>
                    </span>
                  </mat-card-subtitle>
                  <mat-form-field class="full-width-field">
                    <input matInput placeholder="Degree/Certificate" formControlName="degree" [required]="!isEditing">
                  </mat-form-field>
                  <mat-form-field class="full-width-field">
                    <input matInput placeholder="Field of Study" formControlName="fieldOfStudy" [required]="!isEditing">
                    <mat-hint>Specialisation</mat-hint>
                  </mat-form-field>
                  <mat-form-field class="full-width-field">
                    <input matInput placeholder="Certifying Organisation" formControlName="certifyingOrganisation"
                      [required]="!isEditing">
                    <mat-hint>Name of the Institute</mat-hint>
                  </mat-form-field>
                </mat-card>
              </div>
              <button mat-button type="button" (click)="addQualification()">+ Add Qualification</button>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button matStepperPrevious type="button" color="warn">Back</button>
            <button *ngIf="showWorkingHours"  mat-button matStepperNext type="button" color="warn">Next</button>
            <button *ngIf="!showWorkingHours" mat-button type="submit" color="warn" [disabled]="expertForm.invalid" (click)="save()">Save</button>
          </mat-card-actions>
        </mat-card>
      </mat-step>

      <mat-step *ngIf="showWorkingHours" formGroupName="3" stepControl="profile.get([3])" label="Working Hours">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Working Hours</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field class="width300">
              <mat-label>Time Zone</mat-label>
              <mat-select panelClass="dropdown timeDropdown" formControlName="timeZone" [required]="!isEditing"
                disableOptionCentering>
                <mat-option *ngFor="let timeZone of getTimeZones()" [value]="timeZone">{{timeZone}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasErrors(3,'timeZone')">{{getErrorMessage(3,'timeZone')}}</mat-error>
            </mat-form-field>
            <div class="note-card-wrapper">
              <mat-card class="note-card">
                <div class="left-blue-bar"></div>
                <div class="content">
                  <mat-card-content style="position: relative; top: 10px;">
                    Please Note : You can add the same day multiple times for multiple timings. For
                    eg - Mon 9:00AM - 12:00 PM, Mon 1:00PM - 4:00PM and so on.
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div *ngIf="isOverLapped"
              style="margin-top: 10px; margin-bottom: 14px; display: flex; justify-content: center;">
              <mat-icon color="warn" style="font-size:20px;">info</mat-icon>
              <span style="font-size: 16px; color: #F7524B">
                Please enter your working hours for a day without any overlap in timings.
              </span>
            </div>

            <div [formGroupName]="'workingHours'" style="position: relative; left: 12px;">
              <div *ngFor="let workingHour of workingHours.controls; let i=index" formArrayName="{{i}}">
                <mat-form-field class="width100">
                  <mat-select panelClass="dropdown" formControlName="weekday" placeholder="Weekday">
                    <mat-option value="mon">Mon</mat-option>
                    <mat-option value="tue">Tue</mat-option>
                    <mat-option value="wed">Wed</mat-option>
                    <mat-option value="thu">Thu</mat-option>
                    <mat-option value="fri">Fri</mat-option>
                    <mat-option value="sat">Sat</mat-option>
                    <mat-option value="sun">Sun</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="width100" style="transform: translateY(1px);">
                  <input matInput placeholder="Start Time" formControlName="startTime" type="time">
                </mat-form-field>
                <mat-form-field class="width100" style="transform: translateY(1px);">
                  <input matInput placeholder="End Time" formControlName="endTime" type="time">
                </mat-form-field>
                <mat-icon class="material-icons-outlined" style="position: relative; top: 7px; cursor: pointer;"
                  (click)="deleteWorkingHour(i)">
                  clear
                </mat-icon>
              </div>
            </div>
            <button mat-button type="button" (click)="addWorkingHour()">+ Add Working Hour</button>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button matStepperPrevious type="button" color="warn">Back</button>
            <button mat-button type="submit" color="warn" [disabled]="expertForm.invalid" (click)="save()">Save</button>
          </mat-card-actions>
        </mat-card>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>


<div class="page-container profilePageContainer" *ngIf="!isEditing && expert && !isLeaveFormEnabled">
  <h1 id="sessionsEarned" *ngIf="!isEditing && expert" style="text-align: start; font-weight: 500; margin-bottom: 3px;">
    Sessions earned this month : {{ balance }}</h1>
  <h1 id="sessionsEarned" *ngIf="!isEditing && expert" style="text-align: start; font-weight: 500; margin-bottom: 3px;">
    Consultations earned this month : {{ consultationBalance }}</h1>
  <div style="display: flex; justify-content: start; margin-bottom: 30px;">
    <button mat-button color="warn" class="cardActionBtn">View Details</button>
  </div>
  <mat-card class="profileCard">
    <mat-card-header>
      <!-- <mat-card-subtitle style="color: #4391C1; text-align: initial; margin-bottom: 5px;"><b>Remedial
					Therapist</b></mat-card-subtitle> -->
      <div>
        <mat-card-actions align="end">
          <button mat-button color="warn" class="cardActionBtn" (click)="editProfile()">Edit</button>
        </mat-card-actions>
      </div>
      <mat-card-subtitle style="color: #4391C1;text-align: start;" *ngFor="let serviceType of expert.serviceTypes"> <b>
          {{serviceType | uppercase}} </b>
      </mat-card-subtitle>
      <mat-card-title style="text-align: start;">{{expert.fullName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="profileDetails">
        <span class="labelFields">Age Range</span>
        <span class="detailFields">{{expert.startingAgeRange}}-{{expert.endingAgeRange}} yrs</span>
      </div>
      <div class="profileDetails">
        <span class="labelFields">Language(s)</span>
        <span class="detailFields">{{expert.languages}}</span>
      </div>
      <div class="profileDetails">
        <span class="labelFields">Working Hrs</span>
        <div class="workingHoursContainer">
          <div *ngFor="let day of workHour" style="display: flex; margin-bottom: 10px;">
            <div style="margin-right: 25px; width: 60px;" *ngIf="day.workSlots.length > 0">
              <b> {{day.weekday | titlecase}} </b>
            </div>
            <div>
              <p *ngFor="let slot of day.workSlots" style="color: #5A5A5A; margin-bottom: 2px;">{{slot}}
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="workingHoursContainer">
					<div *ngFor="let workingHour of expert.workingHours">
						<div class="weekDays" style="margin-right: 10px;">{{workingHour.weekday | titlecase}}</div>
						<span style="color: #5A5A5A;">{{workingHour.startTime}} - {{workingHour.endTime}}</span>
					</div>
				</div> -->
      </div>
      <div class="phoneDetails">
        <span class="material-icons">
          phone
        </span>
        <span>{{expert.phoneNumber}}</span>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="leavesCard">
    <mat-card-header style="height: auto">
      <mat-card-title style="text-align: start">My Leaves :</mat-card-title>
      <!-- <mat-card-subtitle style="color: #4391C1; text-align: initial; margin-top: 30px; margin-bottom: 2px;">PAST
				LEAVES</mat-card-subtitle> -->
      <div>
        <mat-card-actions align="end">
          <button mat-button color="warn" class="cardActionBtn" (click)="isLeaveFormEnabled = true">Apply for
            leave</button>
        </mat-card-actions>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="leaveDetails">
        <div *ngIf="upComingLeaves.length > 0" style="margin-bottom: 8px;">
          <h3 style=" margin-bottom: 4px; color: #4391C1;"> <b>UPCOMING LEAVES</b></h3>
          <div *ngFor="let leave of upComingLeaves">
            <ul style="list-style-type:disc; padding-left:1em;">
              <li>{{ helper.istTime(leave.leaveStartDateTime.toDate()) | date: 'dd MMM YYYY, h:mm a'}}(IST) - {{
                helper.istTime(leave.leaveEndDateTime.toDate()) | date: 'dd MMM YYYY, h:mm a'}}(IST)
                <span>
                  <!-- <button mat-button style="float: right; height: 26px;" (click)="cancelLeave(leave.id)">
                    <mat-icon color="warn" style="font-size: 20px;">delete</mat-icon>
                  </button> -->
                </span>
              </li>
            </ul>
            <hr style="margin-top: 10px; margin-bottom: 10px;">
          </div>
        </div>
        <div *ngIf="pastLeaves.length > 0">
          <h3 style=" margin-bottom: 4px; margin-top: 10px; color: #4391C1;"> <b>PAST LEAVES</b></h3>
          <div *ngFor="let leave of pastLeaves">
            <ul style="list-style-type: disc; padding-left:1em;">
              <li>{{ helper.istTime(leave.leaveStartDateTime.toDate()) | date: 'dd MMM YYYY, h:mm
                a'}}(IST) -
                {{ helper.istTime(leave.leaveEndDateTime.toDate()) | date: 'dd MMM YYYY, h:mm a'}}(IST)
              </li>
            </ul>
            <hr style="margin-top: 10px; margin-bottom: 10px;">
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isLeaveFormEnabled">
  <form [formGroup]="leaveForm" (ngSubmit)="applyForLeave()">
    <mat-card style="padding-bottom: 70px !important; margin-left: 2px !important; margin-top: 22px;">
      <mat-card-header>
        <mat-card-title style="display: flex;">
          <a style="cursor: pointer;" matButton id="expert-back-button" (click)="isLeaveFormEnabled = false">
            <mat-icon style="margin-right: 8px;">arrow_back</mat-icon>
          </a>
          Leave Notice
        </mat-card-title>
      </mat-card-header>

      <div
        style="display: flex; margin-bottom: 10px; padding: 5px; align-items: center; border: 1px solid #DCDCDC; background: #FCFCFC; border-radius: 7px;">
        <mat-icon style="margin-right: 7px; color: #7A7A7A;">info</mat-icon>
        <p style="margin-bottom: 0px; line-height: 18px;">Applying for leaves does not automatically cancel your
          sessions during your leaves time period.</p>
      </div>

      <mat-card-content>
        <mat-form-field class="full-width-field">
          <mat-label>Reason</mat-label>
          <input matInput formControlName="leaveReason" autocomplete="off" [required]="!isEditing"
            placeholder="Please specify leave reason" type="text">
        </mat-form-field>

        <mat-form-field class="full-width-field">
          <mat-label>Leave From</mat-label>
          <input matInput [owlDateTime]="dt1" formControlName="leaveStartDateTime" autocomplete="off"
            [owlDateTimeTrigger]="dt1" [required]="!isEditing">
          <mat-datepicker-toggle matSuffix [for]="dt1"></mat-datepicker-toggle>
          <owl-date-time #dt1></owl-date-time>
        </mat-form-field>

        <mat-form-field class="full-width-field">
          <mat-label>Leave To</mat-label>
          <input matInput [owlDateTime]="dt2" formControlName="leaveEndDateTime" autocomplete="off"
            [owlDateTimeTrigger]="dt2" [required]="!isEditing">
          <mat-datepicker-toggle matSuffix [for]="dt2"></mat-datepicker-toggle>
          <owl-date-time #dt2></owl-date-time>
        </mat-form-field>
        <p style="font-size: 14px; font-weight: 500; margin-top: 0px;">Read the leave policy <a
            href="https://drive.google.com/file/d/165OsCHM0qK_LnBUKCO7v7x43ExqlXJBO/view?usp=sharing" target="_blank"
            style="color: #F7524B;">here</a></p>
      </mat-card-content>

      <mat-card-actions>
        <button class="right" mat-button type="submit" color="warn" [disabled]="leaveForm.invalid"
          style="background: #F7524B; color: #FFFFFF; box-shadow: 0px 4px 8px rgba(220, 220, 220, 0.3); border: 1px solid #F1F1F1; border-radius: 10px;">Apply</button>
        <button class="right" mat-button type="button" color="warn" (click)="isLeaveFormEnabled = false"
          style="background: #FFFFFF; color: #F7524B; box-shadow: 0px 4px 8px rgba(220, 220, 220, 0.3); border: 1px solid #F1F1F1; border-radius: 10px;">Back</button>
      </mat-card-actions>

    </mat-card>
  </form>
</div>
