import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import firebase from 'firebase/app';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { HelperService } from 'src/app/helper.service';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-selected-parent',
  templateUrl: './admin-selected-parent.component.html',
  styleUrls: ['./admin-selected-parent.component.css'],
})
export class AdminSelectedParentComponent implements OnInit, OnDestroy {
  selectedParent: any;
  experts = [];
  filter = 'balance';
  transactions: AngularFirestoreDocument[] = [];
  transactionsByDate: Object = {};
  sortedDates: Date[] = [];
  transactionSub: Subscription;
  lastSessionSub: Subscription;
  nextSessionSub: Subscription;
  recentSessionSub: Subscription;
  recentSessions = {};
  leaves = {};
  isEditing = false;
  expertForm: FormGroup;
  // expertDocs: AngularFirestoreDocument[] = [];
  expertList: AngularFirestoreDocument[] = [];
  services = [];
  expertListAsObj = {};
  balanceSub: Subscription;
  balanceDocument: AngularFirestoreDocument;
  currentBalance: string;
  currentUser: any;
  parentStatus: any;
  newParentStatus: any;

  constructor(
    private adminService: AdminService,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    public helper: HelperService
  ) {}

  async ngOnInit() {
    await this.adminService.isSupport();

    this.auth.currentUser.then((user) => {
      this.firestore
        .doc(`users/${user.uid}`)
        .valueChanges()
        .pipe(take(1))
        .subscribe((userDoc: AngularFirestoreDocument) => {
          this.currentUser = userDoc;
          console.log('current admin ', this.currentUser);
        });
    });

    this.selectedParent = this.route.snapshot.data['parent'];
    this.experts = this.selectedParent['myExperts']
      ? this.selectedParent['myExperts']
      : [];

    // for(let expert of this.experts) {
    //   this.adminService.getExpert(expert['educatorId']).pipe(take(1))
    //   .subscribe(
    //     result => { this.expertDocs.push(result[0]);
    //        //console.log(this.expertDocs)
    //     }
    //   );
    // }

    if (this.selectedParent) {
      this.parentStatus = this.selectedParent.activityStatus;
      this.newParentStatus = this.selectedParent.activityStatus;
      this.transactionSub = this.adminService
        .getTransactions(this.selectedParent.id)
        .subscribe((transactions: AngularFirestoreDocument[]) => {
          this.transactions = transactions;
          this.sortTransactions(this.transactions);
        });
    }
    this.getRecentSessions();
    this.nextLeave();
    this.balanceSub = this.firestore
      .doc(`balances/${this.selectedParent.id}`)
      .valueChanges()
      .subscribe((result: AngularFirestoreDocument) => {
        if (result) {
          this.balanceDocument = result;
          this.currentBalance = result['currentBalance'];
        }
      });
    this.expertForm = this.fb.group({
      educators: this.fb.array([]),
    });

    // for(let [i, expert] of this.experts.entries()) {
    // this.educators.push(this.fb.group({
    //   'educatorId':[expert.educatorId, Validators.required],
    //   'educatorName':[expert.educatorName, Validators.required],
    //   'service':['', Validators.required]
    // }));
    // this.educators.at(i).get('service').setValue((this.expertDocs[i])['serviceTypes']);
    // this.addEducators(expert.educatorId, expert.educatorName, [...expert.service], i);
    // }
    this.adminService.ExpertList.pipe(take(1)).subscribe((result) => {
      this.expertList = result;
      for (let expert of this.expertList) {
        this.expertListAsObj[expert['id']] = {};
        this.expertListAsObj[expert['id']] = expert;
      }
    });
    console.log(this.selectedParent,"selectedParent");
  }

  getParentStatus() {
    return this.helper.parentActivityStatus;
  }

  copyWhatsappNumber() {
    let number =
      this.selectedParent.countryCode + this.selectedParent.whatsappNumber;
    this.clipboard.copy(number);
  }

  openChat() {
    let number: string =
      this.selectedParent.countryCode + this.selectedParent.whatsappNumber;
    number = number.slice(0, 0) + number.slice(1);
    let link = 'https://app.interakt.ai/inbox?channelPhoneNumber=' + number;
    window.open(link, '_blank') || window.location.replace(link);
  }

  copyPhoneNumber() {
    this.clipboard.copy(this.selectedParent.phoneNumber);
  }

  onActivityStatusChanged(activity: any) {
    console.log('new parent status ', this.newParentStatus);
    if (activity.value == this.newParentStatus) {
      console.log(
        'no need to change the status as the selected option is already set for parent.'
      );
    } else {
      const dialogRef = this.dialog.open(ActivityChangeDialog2, {
        disableClose: true,
        panelClass: 'sessionPackDialog',
        data: { status: activity.value },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          return;
        } else if (result.event == 'No') {
          this.parentStatus = this.newParentStatus;
        } else if (result.event == 'Yes') {
          let ref = this.firestore
            .collection('users')
            .doc(this.selectedParent.id)
            .update({ activityStatus: activity.value })
            .then(() => {
              this.toastr.success(
                `Parent has been marked as ${activity.value}`,
                'Status Updated'
              );
              this.newParentStatus = activity.value;
            })
            .catch((err) => console.log(err));
        }
      });
    }
  }

  onEditPairings() {
    this.isEditing = !this.isEditing;
    this.educators.clear();
    if (this.experts.length !== 0) {
      for (let [i, expert] of this.experts.entries()) { //adding all pre existing experts to the edit experts form
        if (this.isArray(expert.service)) {
          this.addEducators(
            expert.educatorId,
            expert.educatorName,
            expert.service,
            expert.reccomendedFrequency,
            i
          ); //if services is an array directly sending it to addEducators
      
        } else {
          let services = []; //if not, converting it to an array and then sending to addEducators
          services.push(expert.service);
          this.addEducators(
            expert.educatorId,
            expert.educatorName,
            services,
            expert.reccomendedFrequency,
            i
          );
        }
      }
    } else this.addEducators('', '', [], '', 0); //if no experts exist on a parent doc, adding an empty expert form group
  }

  get educators() {
    return this.expertForm.controls['educators'] as FormArray;
  }

  onExpertIdSelected(id, index: number) {
    //this.myExpertsIds.push(id);
    //console.log(this.educators);
    this.educators
      .at(index)
      .get('educatorName')
      .patchValue(this.expertListAsObj[id]['fullName']);
    this.pushService(id, index);
  }

  addEducators(
    id = '',
    name = '',
    services: string[] = [],
    frequency = '',
    index: number
  ) {
    this.educators.push(
      this.fb.group({
        educatorId: [id, Validators.required],
        educatorName: [name, Validators.required],
        service: [services, Validators.required],
        reccomendedFrequency: <any>[
          frequency,
          [Validators.required, Validators.min(1), Validators.max(7)],
        ],
      })
    );
    if (services.length !== 0) this.pushService(id, index);
  }

  pushService(id: string, index?: number) {
    // this.adminService.ExpertList.pipe(take(1)).subscribe(
    // result => {
    //   this.expertList = result;
    //   for(let expert of this.expertList)
    //   {
    //     this.expertListAsObj[expert['id']] = {};
    //     this.expertListAsObj[expert['id']] = expert;
    //   }
    if (this.expertListAsObj)
      this.services[index] = this.expertListAsObj[id].serviceTypes;
    //  }
    // );
  }

  deleteEducator(index: number) {
    this.educators.removeAt(index);
  }

  async sortTransactions(unfilteredTransactions: AngularFirestoreDocument[]) {
    let transactionsByDate = {};

    let transactionFilteredList: AngularFirestoreDocument[] = unfilteredTransactions.filter(
      (transaction: AngularFirestoreDocument) => !((transaction['transactionType'] === 'renewalTransaction' && transaction['paymentStatus'] === 'failure' ))
    );

    let transactions: AngularFirestoreDocument[] =
      transactionFilteredList.filter(
        (transaction: AngularFirestoreDocument) =>
          !(
            transaction['returnedSessionValue'] === 0 &&
            transaction['transactionValue'] === 0
          )
      );

    transactions = await this.getSessionData(transactions);

    for (let transaction of transactions) {
      let createdDate: Date = this.helper.istTime(
        transaction['createdAt'].toDate()
      );
      let createdAt = new Date(
        createdDate.getFullYear(),
        createdDate.getMonth(),
        createdDate.getDate(),
        0,
        0,
        0
      );
      if (transactionsByDate[createdAt.toString()])
        transactionsByDate[createdAt.toString()].push(transaction);
      //storing transactions chronologically against their date as date:transaction doc
      else {
        transactionsByDate[createdAt.toString()] = [];
        transactionsByDate[createdAt.toString()].push(transaction);
      }
    }
    let dates: Date[] = [];
    for (let i in transactionsByDate) dates.push(new Date(i));

    this.transactionsByDate = transactionsByDate;
    this.sortedDates = dates;
    console.log(
      'Checkint transactionByDate inside sortTransaction ',
      this.transactionsByDate
    );
  }

  onTransactionFilterSelected(event){
    let transactionsByDate = {};
    if(event == 'allTransaction'){
      this.sortTransactions(this.transactions);
    }else {
      let allTransactions = [];
      allTransactions = this.transactions;
      let renewalTransactions: AngularFirestoreDocument[] = allTransactions.filter(
        (transaction: AngularFirestoreDocument) => !(transaction['transactionType'] != 'renewalTransaction' )
      ); 
      let transactions: AngularFirestoreDocument[] = renewalTransactions.filter(
        (transaction: AngularFirestoreDocument) => !(transaction['paymentStatus'] === 'failure')
      );
      for (let transaction of transactions) {
        let createdDate: Date = this.helper.istTime(
          transaction['createdAt'].toDate()
        );
        let createdAt = new Date(
          createdDate.getFullYear(),
          createdDate.getMonth(),
          createdDate.getDate(),
          0,
          0,
          0
        );
        if (transactionsByDate[createdAt.toString()])
          transactionsByDate[createdAt.toString()].push(transaction);
        //storing transactions chronologically against their date as date:transaction doc
        else {
          transactionsByDate[createdAt.toString()] = [];
          transactionsByDate[createdAt.toString()].push(transaction);
        }
      }
      let dates: Date[] = [];
      for (let i in transactionsByDate) dates.push(new Date(i));
  
      this.transactionsByDate = transactionsByDate;
      this.sortedDates = dates;
    }
  }
  compareTime(a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    } else if (a.createdAt > b.createdAt) {
      return -1;
    } else {
      return 0;
    }
  }


  getSessionData(transactions): AngularFirestoreDocument[] {
    //session data corresponding to each transaction

    for (let transaction of transactions) {
        console.log(transaction,"transaction");
        // const timeinMiliSec = this.helper.getMondayInMiliSeconds(transaction.sessionDate.toDate());
        // collection("new sessions").doc(`${timeinMiliSec}`).
        if(transaction.transactionType==="session" ){
          const docRef =  this.firestore.collectionGroup("sessions",ref=>ref.where("id","==",transaction.sessionId));
          docRef
          .get()
          .toPromise()
          .then((results) => {
           
            if (!results.empty) {
              results.forEach(doc => {
                let session: any = doc.data();
                transaction.session = session;
              })
             
            }
          });
        }
        
    }
    console.log('Checking Transaction inside getSessionData ', transactions);
    return transactions;
  }

  getRecentSessions() {
    let nextSession: Date;
    let lastSession: Date;
    for (let expert of this.experts) {
      this.recentSessionSub = this.adminService
        .getFilteredSessionList(expert['educatorId'], undefined, 'upcoming')
        .pipe(
          take(1),
          tap((result) => {
            if (result.length != 0) {
              let timestamp: firebase.firestore.Timestamp =
                result[0]['sessionEndDateTime']; //assuming first session to also be chronologically first
              nextSession = this.helper.istTime(timestamp.toDate());
            } else {
              nextSession = null;
            }
            this.recentSessions[expert['educatorId']] = {};
            this.recentSessions[expert['educatorId']]['totalScheduled'] = result
              ? result.length
              : 0;
            this.recentSessions[expert['educatorId']]['nextSession'] =
              nextSession; //storing first session as next upcoming session
          }),
          switchMap(() =>
            this.adminService.getFilteredSessionList(
              expert['educatorId'],
              undefined,
              'past'
            )
          ),
          take(1),
          tap((result) => {
            if (result.length != 0) {
              let timestamp: firebase.firestore.Timestamp =
                result[result.length - 1]['sessionEndDateTime']; //assuming last session to be chronologically last and thus most recent past session
              lastSession = this.helper.istTime(timestamp.toDate());
            } else {
              lastSession = null; //same as above but for past sessions
            }
            this.recentSessions[expert['educatorId']]['totalPast'] = result
              ? result.length
              : 0;
            this.recentSessions[expert['educatorId']]['lastSession'] =
              lastSession;
          })
        )
        .subscribe();
    }
  }

  getFinalTransactionValue(transaction: AngularFirestoreDocument): number {
    let transactionVal: number = transaction['transactionValue'];
    let returnedSessionVal: number = transaction['returnedSessionValue'];

    if (transactionVal === 0 && returnedSessionVal !== 0)
      return Math.round((returnedSessionVal + Number.EPSILON) * 100) / 100;
    else if (
      transactionVal !== 0 &&
      (returnedSessionVal === 0 || !returnedSessionVal)
    )
      return Math.round((transactionVal + Number.EPSILON) * 100) / 100;
    else if (transactionVal !== 0 && returnedSessionVal !== 0)
      return Math.round((transactionVal + Number.EPSILON) * 100) / 100;
    else if (transactionVal === 0 && returnedSessionVal === 0) return 0;
  }

  isArray(variable: any) {
    return variable?.constructor === Array;
  }

  onFilterSelected(filter: string) {
    this.filter = filter;
  }

  openSessionPackDialog() {
    const dialogRef = this.dialog.open(SessionPackDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
    });

    dialogRef.afterClosed().subscribe(this.addBalance);
  }

  addBalance = (result) => {
    console.log(result,"result");
    if (!result) {
      console.log('not getting result');
      return;
    } else if (result.event == 'Yes') {
      let numberOfSessions = parseFloat(result.numberOfSessions);
      let reason = result.reason;
      let validity=result.validity;
      console.log(numberOfSessions,"numberOfSessiosn",validity);
      if (!numberOfSessions) {
        this.toastr.error("No of sessions are required", 'Bad Request');
        return;
      }
      if (!reason) {
        this.toastr.error("Reason required", 'Bad Request');
        return;
      }
      if(validity){
        console.log(this.selectedParent.id)
        let transactionId = this.helper.uuidv4();
        let ref = this.firestore.collection('transactions').doc(transactionId).set({
          transactionType: "renewalTransaction",
          id: transactionId,
          paymentStatus: "Successfull",
          userId:this.selectedParent.id,
          amount: result.sessionPackValue,
          sessions: result.numberOfSessions,
          reason :`Renewal Updation by admin -  ${this.currentUser.fullName}`,
          subReason : reason,
          validity:result.validity,
          createdAt: new Date(),
          adminId:this.currentUser.id,
          adminName : this.currentUser.fullName
        }).then(() => {
          console.log('transaction added successfully');
          this.toastr.success("Topup balance has been added successfully.", 'Renewal Balance added!');
        }).catch(error=>{
          console.log("error",error);
         
        })
      }else{
        let currentTimeStamp = firebase.firestore.Timestamp.now();
      
        // Generating transaction 
        let transaction: any = {};
        transaction.userId = this.selectedParent.id;
        transaction.status = 'Successfull';
        transaction.reason = `Balance Updation by admin -  ${this.currentUser.fullName}`;
        transaction.subReason = reason;
        transaction.transactionValue = numberOfSessions;
        transaction.transactionType = 'byAdmin';
        transaction.adminId = this.currentUser.id;
        transaction.adminName = this.currentUser.fullName;
        transaction.createdAt = currentTimeStamp;
        transaction.hideTranscation = result.hideTranscation;
  
        let ref = this.firestore
          .collection('transactions')
          .add(transaction)
          .then(() => {
            console.log('transaction added successfully');
            this.toastr.success("Balance has been added successfully.", 'Balance added!');
          })
          .catch((err) => console.log(err));
      }  
    }else if(result.event == "renewal"){
      console.log(this.selectedParent.id)
      let transactionId = this.helper.uuidv4();
      let ref = this.firestore.collection('transactions').doc(transactionId).set({
        transactionType: "renewalTransaction",
        id: transactionId,
        paymentStatus: "Successfull",
        userId:this.selectedParent.id,
        amount: result.sessionPackValue,
        sessions: result.numberOfSessions,
        validity:result.validity,
        createdAt: new Date(),
        adminId:this.currentUser.id,
        adminName : this.currentUser.fullName,
        reason :`Renewal Updation by admin -  ${this.currentUser.fullName}`    
      }).then(() => {
        console.log('transaction added successfully');
        this.toastr.success("Topup balance has been added successfully.", 'Renewal Balance added!');
      }).catch(error=>{
        console.log("error",error);
       
      })
    }
  };

  nextLeave() {
    for (let expert of this.experts) {
      this.adminService
        .leaves(expert['educatorId'])
        .pipe(take(1))
        .subscribe((result) => {
       
          this.leaves[expert['educatorId']] = {};
          if (result.length !== 0) {
            this.leaves[expert['educatorId']]['startDate'] =
              this.helper.istTime(result[0]['leaveStartDateTime'].toDate()); //calculating next leave by assuming first leave in array is also chronologically first
            this.leaves[expert['educatorId']]['endDate'] = this.helper.istTime(
              result[0]['leaveEndDateTime'].toDate()
            );
          
          } else {
            this.leaves[expert['educatorId']]['startDate'] = null;
            this.leaves[expert['educatorId']]['endDate'] = null;
          }
        });
    }
  }

  onDone() {
    this.isEditing = false;
    let expertIds = [];
    for (let expert of this.expertForm.value.educators) {
      expertIds.push(expert.educatorId);
    }

    this.saveEdits(this.expertForm.value.educators, expertIds);
  }

  saveEdits(formValue, expertIds: string[]) {
    this.experts = formValue;
    this.firestore
      .doc(`users/${this.selectedParent.id}`)
      .update({ myExperts: formValue, myExpertsIds: expertIds })
      .then(() => {
        console.log('Updated');
      });
  }

  ngOnDestroy() {
    if (this.transactionSub) this.transactionSub.unsubscribe();
    if (this.recentSessionSub) this.recentSessionSub.unsubscribe();
    if (this.balanceSub) this.balanceSub.unsubscribe();
  }
}

@Component({
  selector: 'app-session-pack-dialog',
  template: `
    <div style="float: right; z-index:1; cursor: pointer;">
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>

    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important;"
        >Update Balance</mat-card-title
      >
      <div>
        <div class="radioInput">
          <input style="margin-right: 4px" type="radio" name="validity" value="24" (change)="changePlan($event)"/>
          <mat-label>180 Days(24 sessions)</mat-label>
        </div>
        <div class="radioInput">
          <input style="margin-right: 4px" type="radio" name="validity" value="36" (change)="changePlan($event)"/>
          <mat-label>270 Days(36 sessions)</mat-label>
        </div>
        <div class="radioInput">
          <input style="margin-right: 4px" type="radio" name="validity" value="48" (change)="changePlan($event)"/>
          <mat-label>360 Days(48 sessions)</mat-label>
        </div>
         <div class="radioInput">
        <input style="margin-right: 4px" type="radio" name="validity" value="custom" (change)="changePlan($event)"/>
        <mat-label>Custom</mat-label>
      </div>
      </div>
      <div *ngIf="customSessionDetails">
        <mat-form-field style="width: 100%">
          <mat-label>Reason</mat-label>
          <input type="text" matInput  autocomplete="off" [(ngModel)]="reason" (ngModelChange)="changeInput($event)"/>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
          <mat-label>Number of Sessions</mat-label>
          <input type="number" matInput  [min]="0" autocomplete="off" [(ngModel)]="numberOfSessions" (ngModelChange)="changeInput($event)" #numberOfSessionsCtrl/>
          </mat-form-field>
          <mat-form-field style="width: 100%;">
          <mat-label>Validity(in days)</mat-label>
          <input type="number" matInput [min]="0" autocomplete="off" [(ngModel)]="validity" (ngModelChange)="changeInput($event)" #validityCtrl/>
          </mat-form-field>
          <mat-form-field style="width: 100%;">
          <mat-label>Session Pack</mat-label>
          <input type="number" matInput [min]="0" autocomplete="off" [(ngModel)]="sessionPackValue" (ngModelChange)="changeInput($event)" #sessionPackValueCtrl/>
          </mat-form-field>
          <div style="font-size:10px; margin-bottom:10px; color:red">Add validity and session pack only if you are adding custom renewal pack</div>
        <input
          type="checkbox"
          [checked]="hideTranscation"
          (change)="hideTranscation = !hideTranscation"
        />
        <label  style="margin-left:7px; font-size:14px;">Hide transcations from user</label>
      </div>
      <button
        mat-button
        id="addSessionPackBtn"
        class="right"
        [disabled]="!numberOfSessions"
        (click)="
          addSessionPack()
        "
      >
        Add
      </button>
    </div>
  `,
  styleUrls: ['./admin-selected-parent.component.css'],
})
export class SessionPackDialog implements OnInit, OnDestroy {
  hideTranscation: boolean = false;
  customSessionDetails:boolean=false;
  sessionCount:number;
  sessionPackValue:number;
  validity:number;
  reason:string;
  numberOfSessions:number;

  constructor(private dialogRef: MatDialogRef<SessionPackDialog>) {}

  ngOnInit() {}

  addSessionPack() {
    this.dialogRef.close({
      event: this.customSessionDetails?'Yes':"renewal",
      reason: this.reason,
      numberOfSessions: this.numberOfSessions,
      hideTranscation:this.hideTranscation,
      sessionPackValue:this.sessionPackValue,
      validity:this.validity
    });
  }

  changePlan(e) {
    console.log(e.target.value);
    this.numberOfSessions=parseInt(e.target.value);
    if(e.target.value==="24"){ 
      this.sessionPackValue=18000;
      this.validity=180;
      this.customSessionDetails=false;
    }else if(e.target.value==="36"){
      this.sessionPackValue=25500;
      this.validity=270;
      this.customSessionDetails=false;
    }else if(e.target.value==="48"){
      this.sessionPackValue=32000;
      this.validity=360;
      this.customSessionDetails=false;
    }else if(e.target.value==="custom"){
      this.customSessionDetails=true;
    }  
  }

  changeInput(e){
    console.log(e);
    // this.reason=e;
  }

  ngOnDestroy() {}
}

@Component({
  selector: 'app-activity-change-dialog2',
  template: `
    <div style="float: right; z-index:1; cursor: pointer;">
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important; color: #F7524B;"
        >You're about to change the status of this user</mat-card-title
      >

      <p
        *ngIf="data.status == 'Active'"
        style="display: flex; align-items: center"
      >
        <span style="color: #F7524B; font-weight: 500;">Inactive</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">Active</span>
      </p>

      <p
        *ngIf="data.status == 'Inactive'"
        style="display: flex; align-items: center"
      >
        <span style="color: #F7524B; font-weight: 500;">Active</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">Inactive</span>
      </p>

      <button
        mat-button
        id="addSessionPackBtn"
        style="background: #FCFCFC; color: #F7524B"
        class="right"
        (click)="changeActivity()"
      >
        Yes
      </button>
      <button
        mat-button
        id="addSessionPackBtn"
        style="background: #F7524B !important; color: white !important; margin-right: 15px"
        class="right"
        (click)="dontChangeActivity()"
      >
        No
      </button>
    </div>
  `,
  styleUrls: ['./admin-selected-parent.component.css'],
})
export class ActivityChangeDialog2 implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<ActivityChangeDialog2>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);
  }

  changeActivity() {
    this.dialogRef.close({ event: 'Yes' });
  }
  dontChangeActivity() {
    this.dialogRef.close({ event: 'No' });
  }

  ngOnDestroy() {}
}
