import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { HelperService } from 'src/app/helper.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-admin-cancel-session',
  templateUrl: './admin-cancel-session.component.html',
  styleUrls: ['./admin-cancel-session.component.css'],
})
export class AdminCancelSessionComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AdminCancelSessionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  nextStep(cancelledBy: string) {
    this.dialogRef.close({ cancelledBy: cancelledBy, dialogType: this.data.dialogType});
  }
}

@Component({
  selector: 'app-admin-cancel-confirmation',
  templateUrl: './admin-cancel-confirmation.component.html',
  styleUrls: ['./admin-cancel-session.component.css'],
})
export class AdminCancelConfirmationComponent implements OnInit {
  message: string;
  isParentCancelling:boolean = false;
  isExpertCancelling:boolean = false;
  isAdminCancelling:boolean = false;
  cancelTimeLimit = 240;
  cancelTimeLimitExpert = 720;
  reasonForm:any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AdminCancelConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { session: any, cancelledBy: string, dialogType: string, adminDoc:any },
    private firestore: AngularFirestore,
    private toastr: ToastrService,
    private helper: HelperService,
    private notificationService: NotificationService) {console.log('admin is  ', this.data.adminDoc)}

  ngOnInit(): void {
    this.reasonForm = this.fb.group({
      cancellationReason: ['', Validators.required],
      additionalDetails: '',
    })

    this.timeComparison();
  }

  getCancelAndRescheduleReasons(){
    if(this.data.cancelledBy == "Parent"){
      return this.helper.parentCancellationAndReschedulingReason;
    } else if(this.data.cancelledBy == "Expert"){
      return this.helper.expertCancellationAndReschedulingReason;
    } else if(this.data.cancelledBy == "Admin"){
      let reason = ['Requested to Admin']
      return reason;
    }
  }

  async rescheduleSession() {
    let rescheduledByName: string;
    let rescheduledBy: string;
    let rescheduledWithName: string;
    let rescheduledWith: string;
    let createdBy: string = this.data.session['createdBy'];
    let createdWith: string = this.data.session['createdWith'];
    let parentName: string;
    if(createdBy == this.data.session['sessionStudentId'])
      parentName = this.data.session['createdByName'];
    else
      parentName = this.data.session['createdWithName'];

    // if parent is requesting to reschedule the session.
    if(this.data.cancelledBy === 'Parent')
    {
      rescheduledBy = this.data.session['sessionStudentId'];
      rescheduledByName = this.data.session['sessionParentName'];
      rescheduledWith = this.data.session['sessionExpertId'];
      rescheduledWithName = this.data.session['sessionExpertName'];

      // if expert is requesting to reschedule the session.
    } else if (this.data.cancelledBy === 'Expert') {
      rescheduledBy = this.data.session['sessionExpertId'];
      rescheduledByName = this.data.session['sessionExpertName'];
      rescheduledWith = this.data.session['sessionStudentId'];
      rescheduledWithName = this.data.session['sessionParentName'];

      // if admin is requesting to reschedule the session.
    } else if(this.data.cancelledBy === 'Admin') {
      rescheduledBy = this.data.adminDoc.id;
      rescheduledByName = this.data.adminDoc.fullName;
      rescheduledWith = "Unknown"
      rescheduledWithName = "Unknown"
    }

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);

    let reasonForm: any = {};
    reasonForm = this.reasonForm.value;
    let reason = reasonForm.cancellationReason;
    let additionalDetails = reasonForm.additionalDetails;
    
    this.dialogRef.close({reason: reason, additionalDetails: additionalDetails, session: this.data.session, reschedulingPerson: this.data.cancelledBy, rescheduledBy: rescheduledBy, rescheduledOn: now, rescheduledWith: rescheduledWith, rescheduledByName: rescheduledByName, rescheduledWithName: rescheduledWithName});
  }

  timeComparison() {
    const now: any = firebase.firestore.Timestamp.now();
    const sessionDate = this.data.session['sessionDate'];

    // starting time of the session
    let startTime = this.data.session['startTime'].split(":");
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);
    startTime = (startingHour * 60) + startingMinute;

    // This will give the difference in dates basically
    let timeDifference = sessionDate - now;
    timeDifference /= 60;

    // This will give the difference between session start time and cancellation time in minutes
    timeDifference += startTime;

    if(this.data.cancelledBy === 'Admin' || this.data.cancelledBy === 'admin'){
      this.message = "No Penalty will be charged";
      this.isAdminCancelling = true;
    } else if(this.data.cancelledBy === 'Parent' || this.data.cancelledBy === 'parent'){
      this.isParentCancelling = true;
      if (timeDifference > this.cancelTimeLimit || this.data.session['status'] == 'Session Request') {
        this.message = "No Penalty will be charged";
      } else if ((timeDifference < this.cancelTimeLimit) && (timeDifference > 0)) {
        this.message = "You will be charged 50% of the session fees AS FINE.";
      } else if (timeDifference < 0) {
        this.message = "You will be charged 100% of the session fees AS FINE.";
      }
    } else if(this.data.cancelledBy === 'Expert' || this.data.cancelledBy === 'expert'){
      this.isExpertCancelling = true;
      if (timeDifference > this.cancelTimeLimitExpert || this.data.session['status'] == 'Session Request') {
        this.message = "No Penalty will be charged";
      } else if ((timeDifference < this.cancelTimeLimitExpert) && (timeDifference > 0)) {
        this.message = "You will be charged 50% of the session fees AS FINE.";
      } else if (timeDifference < 0) {
        this.message = "You will be charged 100% of the session fees AS FINE.";
      }
    } 
  }

  async cancelSession() {
    let cancelledByName: string;
    let cancelledBy: string;
    let cancelledWith: string;
    let cancelledWithName: string;
    console.log(this.data.session,"this.data.session");
    let createdBy: string = this.data.session['createdBy'];
    let createdWith: string = this.data.session['createdWith'];
    let parentName: string;
    if(createdBy == this.data.session['sessionStudentId'])
      parentName = this.data.session['createdByName'];
    else
      parentName = this.data.session['createdWithName'];

      // if admin is requesting to cancel the session.
    if(this.data.cancelledBy === "Admin") {
      cancelledByName = this.data.adminDoc.fullName;
      cancelledBy = this.data.adminDoc.id;
      cancelledWith = "Unknown"
      cancelledWithName = "Unknown";

      // if parent is requesting to cancel the session.
    } else if(this.data.cancelledBy === "Parent") {
      cancelledByName = this.data.session['sessionParentName'];
      cancelledBy = this.data.session['sessionStudentId'];
      cancelledWith = this.data.session['sessionExpertId'];
      cancelledWithName =  this.data.session['sessionExpertName'];

      // if expert is requesting to cancel the session.
    } else if(this.data.cancelledBy === "Expert") {
      cancelledBy = this.data.session['sessionExpertId'];
      cancelledByName = this.data.session['sessionExpertName'];
      cancelledWith = this.data.session['sessionStudentId'];
      cancelledWithName = this.data.session['sessionParentName'];
    }

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);

    let date = new Date(this.data.session['sessionDate'].toDate());
    let exactDate = this.helper.istTime(date);
    let dateString = exactDate.getDate() + '/' + (exactDate.getMonth() + 1) + '/' + exactDate.getFullYear();

    let body = {
      'userId': this.data.session['parentInteraktId'],
      'event': 'session cancelled by admin - notify parent',
      'traits': {
        'sessionType': this.data.session['serviceType'],
        'date': dateString + ' IST',
      }
    }

    let reasonForm: any = {};
    reasonForm = this.reasonForm.value

    const timeinMiliSec =this.helper.getMondayInMiliSeconds(date);
    const docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(this.data.session['id']);
    docRef.update({
      'status':'Cancelled',
      'cancelledBy':cancelledBy,
      'cancellationReason': reasonForm.cancellationReason,
      'additionalCancellationDetails': reasonForm.additionalDetails,
      'cancelledByName':cancelledByName,
      'cancelledOn':now,
      'cancelledWith':cancelledWith,
      'cancelledWithName':cancelledWithName,
      'cancellingPerson': this.data.cancelledBy
    }).then(()=>{
      this.notificationService.sendWhatsappNotification(body);
      this.onSessionDeleted();
    }).catch(err => console.log(err))
}

onSessionDeleted = () => {
  this.dialogRef.close();

  let message = "Your session has been cancelled."
   this.toastr.success(message,"Session Cancelled!")
}
}
