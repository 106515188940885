<div class="expertNewSessionMainContainer">
    <div class="heading">
        <a matButton routerLink="/expert/dashboard" id="expert-back-button" (click)="showBookSession = false">
            <mat-icon>arrow_back</mat-icon>
        </a>
        <h2>Schedule new session</h2>
    </div>


    <div>
        <p style="text-align: start; margin-top: 20px;">Select student</p>
        <div>
            <mat-form-field color="warn">
                <mat-select placeholder="Child Name/Parent Name" (selectionChange)="onStudentSelected($event.value)">
                    <mat-option *ngFor="let student of students" [value]="student">{{student.childName}}/{{
                        student.fullName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- <div>
        <p style="text-align: start; margin-top: 20px;">Select student</p>
        <mat-select placeholder="Child Name/Parent Name">
            <mat-option *ngFor="let student of students" value="{{student}}" (click)="onStudentSelected(student)">
                {{student.childName}}</mat-option>
        </mat-select>
    </div> -->


    <div *ngIf="isStudentSelected">
        <p>Select Session Type(Service)</p>
        <div class="sessionTypeList">
            <mat-form-field color="warn">
                <mat-select placeholder="Session Type" (selectionChange)="onSessionTypeSelected($event.value)">
                    <mat-option *ngFor="let sessionType of sessionTypes" [value]="sessionType">{{sessionType }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>


    <div class="expertLeaves" *ngIf="isInsufficientBalance" style="margin-bottom: 35px; margin-top: -17px;">
        <div class="headingContainer">
            <h2 style="margin-bottom: 0px !important;" class="messageHeading">Parent Subscription Over</h2>
            <button style="border: none; background-color: white;" *ngIf="!parentSubscriptionDetails"
                (click)="parentSubscriptionDetails = true">
                <mat-icon class="material-icons-filled">
                    keyboard_arrow_down
                </mat-icon>
            </button>
            <button style="border: none; background-color: white;" *ngIf="parentSubscriptionDetails"
                (click)="parentSubscriptionDetails = false">
                <mat-icon class="material-icons-filled">
                    keyboard_arrow_up
                </mat-icon>
            </button>
        </div>
        <div *ngIf="parentSubscriptionDetails" style="color: #A5A5A5;">
            This parent currently has a balance of zero (0). You will be able to request a session once they renew.
        </div>
    </div>

    <!-- <div class="numberOfSessionsContainer" *ngIf="isExpertSelected">
        <p style="text-align: start;margin: 8px 0;">Total number of sessions you want to schedule</p>
        <mat-select class="numberOfSessions" placeholder="Select">
            <mat-option *ngFor="let number of getNumbers()" value="{{number}}" (click)="selectedNumberOfSession(number)">{{number}}</mat-option>
        </mat-select>
    </div> -->

    <!-- Multiple session booking view -->
    <!-- <div class="multipleSessionContainer" *ngIf="isMultipleSession">
        <p>Number of sessions you want to take per week</p>
        <span class="sessionPerWeekContainer">
            <button class="sessionPerWeekBtn" (click)="onSessionPerWeekDec()">-</button>
            <h3 style="margin: 0px 15px; display: inherit;">{{sessionPerWeek}}</h3>
            <button class="sessionPerWeekBtn" (click)="onSessionPerWeekInc()">+</button>
        </span>
    </div> -->

    <!-- Single session booking view -->
    <div class="singleSessionContainer" *ngIf="isSessionTypeSelected">
        <p>Select your preferred date and slot:</p>
        <p style="font-size: 14px; font-weight: 400; font-style: italic;">Please Note - slots within the next 24 hours
            cannot be booked on short notice.</p>

        <div class="expertLeaves">
            <div class="headingContainer">
                <h2 style="margin-bottom: 0px !important;" class="messageHeading">See Upcoming Leaves</h2>
                <button style="border: none; background-color: white;" *ngIf="!showLeaves" (click)="showLeaves = true">
                    <mat-icon class="material-icons-filled">
                        keyboard_arrow_down
                    </mat-icon>
                </button>
                <button style="border: none; background-color: white;" *ngIf="showLeaves" (click)="showLeaves = false">
                    <mat-icon class="material-icons-filled">
                        keyboard_arrow_up
                    </mat-icon>
                </button>
            </div>
            <div *ngIf="showLeaves">
                <div *ngFor="let leave of expertsLeaves; let i = index">
                    <h3 style="margin-bottom: -5px !important; margin-top: 6px !important;">Leave: {{i+1}}</h3>
                    <div style="color: #A5A5A5;">From: {{ helper.istTime(leave.leaveStartDateTime.toDate()) | date: 'MMM d, y, h:mm a'}} IST</div>
                    <div style="color: #A5A5A5;">To: {{ helper.istTime(leave.leaveEndDateTime.toDate()) | date: 'MMM d, y, h:mm a'}} IST</div>
                </div>
            </div>
        </div>

        <mat-calendar (selectedChange)="onDateSelected($event)" [selected]="chosenDate" [dateFilter]="dateFilter"
            class="dateSelector"></mat-calendar>

        <p *ngIf="isLessThanSlotRange">Slots are available after 24 hours from now on.</p>

        <!-- <button mat-button class="getSlotBtn" *ngIf="getSlotsEnabled" (click)="getFreeSlots()">Get Available slots</button> -->

        <div class="slotList" *ngIf="showSlots">

            <p *ngIf="sessionService.openSlotsAfter24Hours.length; else notInRange">Available time slots on
                {{chosenDate | date:'EEEE, dd MMM'}} of {{expertsService.currentUserDetails.fullName}}</p>

            <!-- <p>Available time slots on {{selectedSessionDate | date:'EEEE, dd MMM'}} of {{expertsService.currentUserDetails.fullName}}</p> -->
            <div class="slotsContainer">
                <button id="slot" *ngFor="let openSlot of sessionService.openSlotsAfter24Hours"
                    (click)="onSlotSelected(openSlot.value)">{{openSlot.label}}</button>
            </div>
        </div>
        <ng-template #notInRange>
            <div class="noSlotsAvailable">
                <h2 style="margin-bottom: 10px !important;" class="messageHeading">No Available Slots<br>Please select
                    another date.</h2>
                <div style="color: #A5A5A5;">All of your slots for this date have been booked OR your are on leave.
                </div>
            </div>
        </ng-template>
    </div>
    <div id="submitBtnContainer">
        <!-- <button mat-button id="sessionCancelBtn" routerLink="/expert/dashboard">
            CANCEL
        </button> -->
        <button mat-button id="sessionRequestBtn" *ngIf="showBookSession" (click)="saveSession()">Book Session</button>
    </div>
</div>