<mat-card style="width: 100%;" class="rescheduleCard2">
  <div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
    <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
  </div>

  <mat-card-title>
    {{ this.data.dialogType === "cancel" ? "Cancel Session" : "Reschedule Session" }}
  </mat-card-title>

  <mat-card-subtitle style="color: #59AADD;" class="penaltyWarning">{{message}}</mat-card-subtitle>

  <mat-card-content>
    <a href="https://mykinderpass.com/terms-and-conditions" target="_blank" style="color: gray;"
      *ngIf="isParentCancelling || isAdminCancelling">View our cancellation and
      rescheduling policy.</a>
    <a href="/assets/Tactopus Leave, Rescheduling & Cancellations Policy.pdf" target="_blank" style="color: gray;"
      *ngIf="isExpertCancelling">View our cancellation and
      rescheduling policy.</a>
  </mat-card-content>

  <form [formGroup]="reasonForm">
    <div class="formFieldHeading">Reason for cancellation*:</div>
    <mat-form-field class="full-width-field" appearance="outline">
      <mat-select disableOptionCentering formControlName="cancellationReason" panelClass="dropdown"
        placeholder="Select a reason">
        <mat-option *ngFor="let cancelReason of getCancelAndRescheduleReasons()" value="{{ cancelReason }}">
          {{ cancelReason }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="formFieldHeading">Additional Details (Optional)</div>
    <mat-form-field class="full-width-field" floatLabel="never">
      <input matInput formControlName="additionalDetails" placeholder="Enter Here" autocomplete="off">
    </mat-form-field>
  </form>

  <mat-card-actions>
    <button [disabled]="reasonForm.invalid" class="right actionButtons2" mat-button color="warn"
      (click)="this.data.dialogType === 'cancel'? cancelSession() : rescheduleSession()">Confirm</button>
    <button class="right actionButtons" mat-button mat-dialog-close color="warn">Back</button>
  </mat-card-actions>
</mat-card>