<div class="admin-dashboard-page-container">
  <mat-tab-group>
    <mat-tab label="Sessions">
      <div class="optionHolder">
        <span class="sessionContainer">
          <button mat-button class="sessionButton" (click)="onFilterSelected('past')"
            [ngClass]="{'selectedSessionButton': filter==='past'}">Past</button>

          <button mat-button class="sessionButton" (click)="onFilterSelected('upcoming')"
            [ngClass]="{'selectedSessionButton': filter==='upcoming'}">Upcoming</button>
        </span>
        <span>
          <a mat-button class="newSessionButton" routerLink="/support/new-session">
            <mat-icon class="addSymbol">add</mat-icon>
            New Session
          </a>
          <!-- <a mat-button class="newSessionButton" routerLink="/support/multiple-session">
            <mat-icon class="addSymbol">add</mat-icon>
            New Session
          </a> -->
        </span>
      </div>

      <!-- testing button -->
      <button (click)="checkActiveUsers()">test it</button>

      <!-- <button (click)="getParentDetails()">get parents</button>  -->
      <!-- <button (click)="tempFunction1()">Temp 1</button> -->
      <!-- <button (click)="tempFunction2()">Temp 2</button> -->
      <!-- <button (click)="tempFunction3()">Temp 3</button> -->
      <!-- <button (click)="addFile()">Add File</button>   -->
      <!-- <button (click)="sendNotification()">Send Notification</button>  
      <button (click)="secondFunction()">second</button>  
      <button (click)="thirdFunction()">third</button>   -->


      <h1 style="font-weight: 600; display: flex; justify-content: space-between; width: auto;">
        <span>
          Sort by:
        </span> 
        <span>
          <button (click)="sessionDataDownload()" style="border: none; background: none; cursor: pointer;">
            <mat-icon style="color: #F7524B; font-size: 30px;">
              download
            </mat-icon>
          </button>
        </span>
      </h1>
      <div class="sortHolder">
        <mat-form-field style="margin-right: 10px;">
          <mat-label>Educator</mat-label>
          <!-- <mat-select disableOptionCentering panelClass="dropdown" [(value)]="expertSelected"
            (selectionChange)="filterCombinedList(expertSelected, parentSelected, statusSelected, filter)">
            <mat-option>-</mat-option>
            <mat-option [value]="expert.id" *ngFor="let expert of expertList">{{ expert.fullName }}</mat-option>
          </mat-select> -->
          <input type="text" matInput [(ngModel)]="expertName" placeholder="Expert Name" #educatorField="matInput"
            (ngModelChange)="resetExpertInput($event)" (blur)="onExpertBlur()" (focus)="onExpertFocus()">
          <div class="expertDropdown" *ngIf="isExpertClicked">
            <mat-list id="expertList">
              <mat-list-item *ngFor="let expert of expertList | filter: expertName" (click)="onExpertClick(expert)"
                class="expertListItem">
                {{ expert.fullName }}
              </mat-list-item>
            </mat-list>
          </div>
        </mat-form-field>
        <!-- educatorField.focused || dropdownExpertHover -->
        <!-- (mouseenter)="dropdownExpertHover=true" (mouseleave)="dropdownExpertHover=false" -->

        <mat-form-field style="margin-right: 10px;">
          <mat-label>Parent</mat-label>
          <!-- <mat-select disableOptionCentering panelClass="dropdown" [(value)]="parentSelected"
            (selectionChange)="filterCombinedList(expertSelected, parentSelected, statusSelected, filter)">
            <mat-option>-</mat-option>
            <mat-option [value]="parent.id" *ngFor="let parent of parentList">{{ parent.fullName }}</mat-option>
          </mat-select> -->
          <input type="text" matInput [(ngModel)]="parentName" placeHolder="Parent Name" #parentField="matInput"
            (ngModelChange)="resetParentInput($event)" (blur)="onParentBlur()" (focus)="onParentFocus()">
          <div class="parentDropdown" *ngIf="isParentClicked">
            <mat-list id="parentList">
              <mat-list-item *ngFor="let parent of parentList | filter: parentName" (click)="onParentClick(parent)"
                class="parentListItem">
                {{ parent.fullName }}
              </mat-list-item>
            </mat-list>
          </div>
        </mat-form-field>

        <!-- sorting based on status -->
        <mat-form-field>
          <mat-label>Status</mat-label>
          <!-- <mat-select disableOptionCentering panelClass="dropdown" [(value)]="statusSelected"
            (selectionChange)="filterCombinedList(expertSelected, parentSelected, statusSelected, filter)">
            <mat-option>-</mat-option>
            <mat-option [value]="status" *ngFor="let status of helper.sessionTypes">{{status}}
            </mat-option>
          </mat-select> -->
          <input type="text" matInput [(ngModel)]="statusName" placeholder="Status Type" #statusField="matInput"
            (ngModelChange)="resetStatusInput($event)" (blur)="onStatusBlur()" (focus)="onStatusFocus()">
          <div class="expertDropdown" *ngIf="isStatusClicked">
            <mat-list id="expertList">
              <mat-list-item *ngFor="let status of statusArray | filter: statusName" (click)="onStatusClick(status)"
                class="expertListItem">
                {{ status.name }}
              </mat-list-item>
            </mat-list>
          </div>
        </mat-form-field>
        <br>

        <!-- sorting based on date -->
        <div class="dateFilter">
          <div class="filterText">
            <p style="font-family: poppins;">Filter By Date</p>
          </div>
          <mat-form-field appearance="legacy" style="margin-right: 10px">
            <mat-label>Start Day</mat-label>
            <input [(ngModel)]="chosenStartDate" matInput [matDatepicker]="picker" readonly
              (dateChange)="onStartDateSelected($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="legacy" style=" margin-right: 10px;">
            <mat-label>End Day</mat-label>
            <input [(ngModel)]="chosenEndDate" matInput [matDatepicker]="picker1"
              (dateChange)="onEndtDateSelected($event)" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <div style="padding: 5px;">
            <button style="font-weight: 400; font-family: poppins; background-color:rgb(250, 238, 224);" mat-button
              color="warn" (click)="resetForm()" class="filterReset">Reset Date</button>
          </div>
        </div>
      </div>
       <!-- loading screen starts here -->
       <div *ngIf="isLoading" class="text-center my-4" style="margin-top: 50px;">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div>
            <h2 style="color: black;">Loading Sessions</h2>
            <h3 style="color: #A5A5A5">Give us a second as we load your sessions</h3>
        </div>
      </div>
      <!-- loading screen ends here -->

      <div *ngIf="(!isLoading)" 
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
      [scrollWindow]="false" class="box-shadow-none background-color-whitesmoke sessionsCardHolder search-results">
        <span class="left noSession" *ngIf="(combinedList.length === 0) && (!isLoading)">
          You have no '{{filter}}' sessions currently. Request for a new session.
        </span>
        <!-- <div *ngIf="(!isLoading)" 
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="3"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        > -->
        <div class="sessionPlusConsultationHolder" *ngFor="let session of combinedList; let i = index;">
          <div *ngIf="(dateHeadingArray.indexOf(i) > -1) || (i===0)" style="margin-top: 20px;">
            <h1 style="font-weight: 600; color: #000; font-family: Poppins">
              {{helper.istTime(session.sessionDate.toDate()) | date:'MMMM dd, YYYY'}}
            </h1>
          </div>

          <div *ngIf="session.sessionType != 'Free Consultation' && session.sessionType != 'Paid Consultation'">
            <mat-card class="sessionCard">
              <mat-card-header>
                <mat-card-subtitle>
                  <span class="left" *ngIf="session.status == 'Ongoing'" style="color:#7ACFFF">{{session.status}}</span>
                  <span class="left" *ngIf="session.status == 'Scheduled'"
                    style="color:#67CA5F">{{session.status}}</span>
                  <span class="left" *ngIf="session.status == 'Rescheduled'"
                    style="color: #FF6D6D">{{session.status}}</span>
                  <span class="left" *ngIf="session.status == 'Cancelled'"
                    style="color: #FF6D6D">{{session.status}}</span>
                  <span class="left" *ngIf="session.status == 'Session Request'"
                    style="color: #F77E46">{{session.status}}</span>
                  <span class="left" *ngIf="session.status == 'Attended'"
                    style="color: #96ED8F">{{session.status}}</span>
                  <span class="left" *ngIf="session.status == 'noShow-Expert'" style="color: #787878">No Show</span>
                  <span class="left"
                    *ngIf="session.status != 'noShow-Expert' && session.status != 'Attended' && session.status != 'Session Request' && session.status != 'Cancelled' && session.status != 'Rescheduled' && session.status != 'Scheduled' && session.status != 'Ongoing'"
                    style="color: #252935">{{ session.status }}</span>
                </mat-card-subtitle>
                <mat-card-title *ngIf="session.status == 'Session Request'; else mainHeading">
                  <span>
                    <h2 style="font-weight: 600;">{{session.serviceType}}: Request by {{session.sessionExpertName}} for
                      {{session.sessionParentName}} </h2>
                  </span>
                </mat-card-title>
                <ng-template #mainHeading>
                  <mat-card-title>
                    <span>
                      <h2 style="font-weight: 500;">
                        <a class="infoLink"
                          [routerLink]="'/support/expert/'+session.sessionExpertId">{{session.serviceType }}:
                          (E){{session.sessionExpertName }}</a> with (C){{ session.sessionStudentName }} and
                        <a class="infoLink"
                          [routerLink]="'/support/parent/'+session.sessionStudentId">(P){{session.sessionParentName}}</a>
                        <!-- ? session.sessionParentName : (session.sessionStudentId ===
                          session.createdBy ? session.createdByName : session.createdWithName)  -->
                        <!-- Just incase any current sessions still dont have sessionParentNameField -->
                      </h2>
                    </span>
                  </mat-card-title>
                </ng-template>
              </mat-card-header>
              <mat-card-content class="card-content">
                <div class="full-width">
                  <p>Id: {{session.id}}</p>
                  <div class="sessionTimings">{{helper.istTime(session.sessionDate.toDate()) | date:'MMMM dd, YYYY'}}
                  </div>
                  <div class="sessionTimings">{{session.startTime}} - {{helper.getEndTime(session)}} IST</div>
                  <div class="sessionTimings" *ngIf="session.cancelledOn">Cancelled on {{ session.cancelledOn.toDate() |
                    date:'MMMM dd, YYYY' }} by {{ session.cancellingPerson | titlecase }}</div>
                  <div class="sessionTimings" *ngIf="session.rescheduledOn">Rescheduled on {{
                    session.rescheduledOn.toDate() | date:'MMMM dd, YYYY' }} by {{ session.reschedulingPerson |
                    titlecase }}</div>

                  <div *ngIf="showUrl(session)" class="sessionUrl">
                    <div>
                      <span class="left" style="margin: 0; padding: 0; color: #59AADD; width: 50%;">{{
                        session.zoomJoinUrl
                        }}</span>
                    </div>
                    <!-- <mat-icon class="right">content_copy</mat-icon> -->
                  </div>

                  <div *ngIf="session.zoomJoinUrl">
                    <span>Zoom Email: {{session.meetingHostEmail}}</span>
                    <div style="display: flex;">
                      <span>Zoom Id Released: </span>
                      <span *ngIf="session.zoomLicenseReleased" style="color:#67CA5F; font-weight: bold;">YES</span>
                      <span *ngIf="!session.zoomLicenseReleased" style="color: #FF6D6D; font-weight: bold;">NO</span>
                    </div>
                  </div>

                </div>
              </mat-card-content>
              <!-- <mat-card-actions *ngIf="session.status == 'Session Request'; else mainActions">
                <button mat-button class="right actionButtons mat-small"
                   color="warn" (click)="confirmSession(session.id)">Confirm</button>
                <button mat-button class="right actionButtons mat-small"
                   color="warn">Contact Support</button>
              </mat-card-actions>
              <ng-template #mainActions> -->
              <mat-card-actions align="end"
                style="display: flex; flex-direction: column;">

                <div>
                  <button mat-button *ngIf="joinButtonVisibility(session)" class="right actionButtons mat-small"
                    (click)="startMeeting(session)" color="warn">Join Session</button>
                  <button mat-button *ngIf="rejoinBtnVisibility(session)" class="right actionButtons mat-small"
                    (click)="startMeeting(session)" color="warn">ReJoin Session</button>
                  <button mat-button *ngIf="session.status == 'Scheduled'"
                    class="right actionButtons mat-small" (click)="cancelMeeting(session.id)" color="warn">Cancel
                    Session</button>
                  <button mat-button *ngIf="session.status == 'Scheduled'" class="right actionButtons mat-small"
                    (click)="editMeeting(session.id)" color="warn">Reschedule</button>

                  <button mat-button *ngIf="setOngoingForParentVisibility(session)" class="right actionButtons mat-small"
                    (click)="setParent(session)" color="warn">Set Ongoing</button>

                  <button mat-button *ngIf="setOngoingForExpertVisibility(session)" class="right actionButtons mat-small"
                    (click)="setExpert(session)" color="warn">Set Ongoing</button>

                  <button mat-button *ngIf="showUrl(session)" class="right actionButtons mat-small"
                    (click)="copySessionLink(session)">Copy Link</button>
                </div>

                <div *ngIf="markAsPartiallyAttendedVisibility(session)" style="margin-top: 15px;">
                  <button mat-button class="right actionButtons mat-small"
                    (click)="openPartiallyAttendedDialog(session)">Mark as Partially Attended</button>
                </div>

              </mat-card-actions>
              <!-- </ng-template> -->
            </mat-card>
          </div>
          <div *ngIf="session.sessionType == 'Free Consultation' || session.sessionType == 'Paid Consultation'">
            <mat-card class="consultationCard">
              <div class="statusTag" [ngClass]="adminService.checkStatus(session.status)">
                <p style="margin: 0">{{ session.status | uppercase}}</p>
              </div>
              <mat-expansion-panel class="mat-elevation-z0 consultationExpansion">
                <mat-expansion-panel-header class="consultationExpansionHeader" style="height: fit-content;">
                  <mat-panel-title style="margin: 5px; display: block;">
                    <mat-card class="consultationHeaderCard">
                      <mat-card-header>
                        <mat-card-title>
                          <h1 style="font-weight: 500;">
                            <a class="infoLink" [routerLink]="'/support/expert/'+session.sessionExpertId">(E){{
                              session.sessionExpertName }}</a> with (P){{ session.sessionParentName }}, (C){{
                            session.sessionStudentName }}
                          </h1>
                        </mat-card-title>
                        <mat-card-subtitle style="font-weight: 500;">
                          <p style="font-weight: 600;">{{ session.serviceType }}</p>
                          <p>Id: {{session.id}}</p>
                          <p>{{helper.istTime(session.sessionDate.toDate()) | date: "MMMM dd, Y" }}</p>
                          <p>{{ session.startTime }} - {{ session.endTime }} IST</p>
                          <p>{{ session.meetingLink }}</p>
                          <p><span>Id: </span><span style="color: #F7524B;">{{ session.id }}</span></p>
                        </mat-card-subtitle>
                      </mat-card-header>
                    </mat-card>
                  </mat-panel-title>
                  <mat-panel-description style="width: 0 !important; margin: 0 !important;"></mat-panel-description>
                </mat-expansion-panel-header>
                <mat-card class="consultationContentCard">
                  <mat-card-subtitle style="font-weight: 500;">
                    <p style="margin-bottom: 5px;">Scheduled By:</p>
                    <p>{{ session.createdByName }}</p>
                    <br>
                    <mat-label>Sales POC: </mat-label>
                    <br>
                    <mat-form-field style="position: relative; top: -15px !important;">
                      <mat-select disableOptionCentering panelClass="dropdown"
                        [value]="session.salesPocId?session.salesPocId:''"
                        (selectionChange)="adminService.assignSalesPoc($event.value, session.id,session.sessionDate)">
                        <mat-option>-</mat-option>
                        <mat-option [value]="salesPoc.id" *ngFor="let salesPoc of salesPocList">{{ salesPoc.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div *ngIf="session.attendedBySalesperson">
                      <p style="margin-bottom: 0;">Joined by salesperson: </p>
                      <p>{{ session.attendedBySalesperson }}</p>
                    </div>
                    <p>Status Details:</p>
                    <p>{{ session.statusDetails?session.statusDetails:'-' }}</p>
                  </mat-card-subtitle>
                </mat-card>
              </mat-expansion-panel>
              <mat-card-actions style="height: 40px; padding-top: 0; padding-right: 16px;">
                <div *ngIf="session.status==='Scheduled' && filter!='past'">
                  <button mat-button color="warn" class="right rescheduleOrCancel"
                    (click)="reschedule(session)">Reschedule</button>
                  <button mat-button color="warn" class="right rescheduleOrCancel"
                    (click)="cancel(session)">Cancel</button>
                </div>
                <div *ngIf="filter!='past'">
                  <button mat-button color="warn" class="right joinBtn" *ngIf="joinButtonVisibility(session)"
                    (click)="joinConsultation(session)">Join</button>
                </div>

                <div *ngIf="attendedButtonVisibility(session)">
                  <button mat-button color="warn" class="right rescheduleOrCancel"
                    (click)="attend(session)">Attended</button>
                </div>

                <div *ngIf="noShowButtonVisibility(session)">
                  <button mat-button color="warn" class="right rescheduleOrCancel" (click)="noShow(session)">Report
                    No-Show</button>
                </div>

              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <!-- </div> -->
        <!-- <div class="card-footer pb-0 pt-3"> -->
         <!-- <app-jw-pagination-component [items]="combinedList" (changePage)="onChangePage($event)"></app-jw-pagination-component> -->
          <!-- <jw-pagination [items]="combinedList" (changePage)="onChangePage($event)"></jw-pagination> -->
        <!-- </div> -->
      </div>
    </mat-tab>
    <mat-tab label="Parents/Students">
      <app-admin-parents-tab>
        <!-- <router-outlet></router-outlet> -->
      </app-admin-parents-tab>
    </mat-tab>
    <mat-tab label="Educators">
      <app-admin-expert-tab></app-admin-expert-tab>
    </mat-tab>

    <mat-tab label="Data">
      <mat-card>
        <mat-card-content>
          <div style="display: flex; flex-direction:column; justify-content:center; align-items:center">
            <button style="margin: 20px 0px;" (click)="getParentsDbData()">Parents DB</button>
            <button style="margin: 20px 0px;" (click)="getExpertsDbData()">Experts DB</button>
            <button style="margin: 20px 0px;" (click)="getSessionsDbData()">Sessions DB</button>
            <button style="margin: 20px 0px;" (click)="getParentSpecificData()">Parent Balance</button>
            <button style="margin: 20px 0px;" (click)="getActiveParentsData()">Active Parents(had session in 3 months)</button>
            <button style="margin: 20px 0px;" (click)="getInActiveParentsData()">In Active Parents(active but not had session in 3 months)</button>
            <button style="margin: 20px 0px;" (click)="getAllInActiveParentsData()">All In Active Parents</button>
            <!-- <button style="margin: 20px 0px;" (click)="getExpertsUtilizationDbData()">Experts Hours Utilization DB</button> -->
            <button style="margin: 20px 0px;" (click)="getExpertsWorkingHoursDbData()">Expert Working Hours</button>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>