  <div class="invalid-card-container">
    <mat-card>
      <mat-card-title class="error-title" id="error-title1">Oops!</mat-card-title>
      <mat-card-title class="error-title" id="error-title1">We don't have these credentials on our invite list.</mat-card-title>
      <p class="message" id="message1">It looks like you have used an unregistered phone number or email ID to sign up on the Tactopus portal.</p>

      <div id="details-img">
        <img src="../../assets/invited-details-01 1.png" id="whatsapp-img">
      </div>

      <div class="large-screen-details">
        <mat-card-title class="error-title" id="error-title2">Oops!</mat-card-title>
        <mat-card-title class="error-title" id="error-title2">We don't have these credentials on our invite list.</mat-card-title>
        <p class="message" id="message2">It looks like you have used an unregistered phone number or email ID to sign up on the Tactopus portal.</p>
        <p class="message"> To sign up successfully, please use the phone number/ email ID that you've been invited with. You will find these details in your invite message on WhatsApp.</p>
        <p class="message" style="margin-top: 15px;"><a (click)="logout()" style="color: #4391C1; text-decoration-line: underline; cursor: pointer;">Try again here</a> or contact support at +91 9606719826</p>
      </div>
    </mat-card>
  </div>

