import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { Observable, interval, Subscription, observable, timer } from 'rxjs';
import { take, tap, switchMap, map } from 'rxjs/operators';
import { SessionDialog } from 'src/app/expert-dashboard/session.component';
import { HelperService } from 'src/app/helper.service';
import { FinalConfirmationDialogComponent } from 'src/app/sales-admin-dialogs/final-confirmation-dialog/final-confirmation-dialog.component';
import { NoShowConfirmationDialogComponent } from 'src/app/sales-admin-dialogs/no-show-confirmation-dialog/no-show-confirmation-dialog.component';
import { PartyConfirmationDialogComponent } from 'src/app/sales-admin-dialogs/party-confirmation-dialog/party-confirmation-dialog.component';
import { SuccessDialogComponent } from 'src/app/sales-admin-dialogs/success-dialog/success-dialog.component';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AdminCancelConfirmationComponent, AdminCancelSessionComponent } from '../admin-cancel-session/admin-cancel-session.component';
import data1 from 'src/app/services/dummyData1.json';
import data2 from 'src/app/services/dummyData2.json';
import { InteraktUserService } from 'src/app/services/interakt-user.service';
import { PartiallyAttendedDialogComponent } from 'src/app/partially-attended-dialog/partially-attended-dialog.component';
import { PopUpsDisableDialogComponent } from 'src/app/pop-ups-disable-dialog/pop-ups-disable-dialog.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatEndDate } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { WhereFilterOp } from '@firebase/firestore-types';

// modules for exporting the data from firestore to excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Timestamp } from '@google-cloud/firestore';
import { DataDownloadService } from 'src/app/services/data-download.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  pageOfItems: Array<any>;
  filter = 'upcoming';
  expertList: AngularFirestoreDocument[] = [];
  parentList: AngularFirestoreDocument[] = [];
  sessionList: AngularFirestoreDocument[] = [];
  notificationSub: Subscription;
  expertSub: Subscription;
  parentSub: Subscription;
  sessionSub: Subscription;
  navSub: Subscription;
  reschedulingPerson: any;
  date: any;
  hours: any;
  minute: any;
  expertSelected = '';
  expertName = '';
  parentSelected = '';
  parentName = '';
  statusSelected = '';
  statusName = '';
  reschedulingDetails: {
    reschedulingReason: any,
    additionalReschedulingDetails: any,
    rescheduledOn: any;
    rescheduledBy: string;
    rescheduledByName: string;
    rescheduledWith: string;
    rescheduledWithName: string;
  };
  toRescheduleSession: AngularFirestoreDocument;
  combinedList: AngularFirestoreDocument[] = [];
  combinedListCopy: AngularFirestoreDocument[] = [];
  consultationList: AngularFirestoreDocument[] = [];
  salesPocList: AngularFirestoreDocument[] = [];
  dropdownExpertHover = false;
  dropdownParentHover = false;

  expertClicked = false;
  isExpertClicked = false;
  parentClicked = false;
  isParentClicked = false;
  isStatusClicked = false;
  statusClicked = false;
  statusArray: object[];

  chosenStartDate: Date;
  chosenEndDate: Date;
  dateHeadingArray = new Array();

  workbook = new Workbook();
  worksheet: any;
  parents: any[];
  parentsWithBalance: any[];
  parentsWithAllData: any[];
  isLoading = true;
  lastVisibleSession:any;
  lastVisibleConsultation:any;

  constructor(
    private http: HttpClient,
    public adminService: AdminService,
    private dialog: MatDialog,
    private newInteraktUserService: InteraktUserService,
    private firestore: AngularFirestore,
    private toastr: ToastrService,
    private auth: AngularFireAuth,
    private router: Router,
    private notificationService: NotificationService,
    public helper: HelperService,
    private clipboard: Clipboard,
    private snackbar: MatSnackBar,
    private dataService: DataDownloadService
  ) { }

  getParentsDbData() {
    this.dataService.getParentDbData();
  }

  getExpertsDbData() {
    this.dataService.getExpertDbData();
  }

  getExpertsUtilizationDbData() {
    this.dataService.getExpertUtilizationDbData();
  }

  getExpertsWorkingHoursDbData() {
    this.dataService.getExpertWorkingHrsDbData();
  }

  getSessionsDbData() {
    this.dataService.getSessionDatabase();
  }

  getParentSpecificData() {
    this.dataService.getParentBalanceSnapshotDetails();
  }

  getExpertSpecificData() {
    this.dataService.getExpertsBalanceSnapshotDetails();
  }
  getActiveParentsData() {
    this.dataService.getActiveParentDbData();
  }
  
  getInActiveParentsData() {
    this.dataService.getInActiveParentDbData();
    // this.dataService.getAllActiveparents();
  }

  getAllInActiveParentsData() {
    this.dataService.getAllInActiveParentDbData();
  }

  onScroll() {
    console.log("scrolled!!");
    this.getAllSessions(true) ;
  }
  onScrollUp() {
    console.log("scrolled up!!");
  }
  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;

    //Created for DateHeadings of the sessions
    let arr = new Array();
    let dateHead: Date;
    console.log("checking date for sessions: ", this.pageOfItems[0].sessionDate.toDate());
    dateHead = this.pageOfItems[0].sessionDate.toDate();
    for (let i = 0; i < this.pageOfItems.length; i++) {
      if (dateHead < this.pageOfItems[i].sessionDate.toDate()) { //for Upcoming sessions as they are in ascending in order of sessionDate
        arr.push(i);
        dateHead = pageOfItems[i].sessionDate.toDate();
      } else if (dateHead > this.pageOfItems[i].sessionDate.toDate()) { //for Past sessions as they are in descending in order of sessionDate
        arr.push(i);
        dateHead = pageOfItems[i].sessionDate.toDate();
      }
    }
    this.dateHeadingArray = arr;
  }

  // function to update expert balance of whole month.
  async expertMonthlyBalanceUpdate() {
    let user = await this.auth.currentUser;
    let date = new Date();
    let matchingMonth = date.getMonth();
    let matchingYear = date.getFullYear();

    if (user) {
      let id = user.uid;
      if (id == "TxZAULFDg8dCy9KE98FWJyg2cDu2" || id == "vctFitF82fhWwyB5B4DWDTXkrC23" || id == "OHWj6beBLJf1o9eHQlJ1gytPFe53" || id == "FVrol27OopTfVr1sP4psBqBjFvW2") {
        let expertRef = this.firestore.collection('users', expertRef => expertRef.where('role', '==', 'expert'));
        expertRef.get().subscribe((results) => {
          if (!results.empty) {
            results.forEach((doc) => {
              let expert: any = doc.data();
              let sessionBalance = 0;
              let consultationBalance = 0;

              let transactionRef = this.firestore.collection('transactions', transactionRef => transactionRef.where('userId', '==', expert.id));
              transactionRef.get().subscribe((results) => {
                if (!results.empty) {

                  results.forEach((doc) => {
                    let transaction: any = doc.data();
                    let transactionDate = new Date(transaction.createdAt.toDate());
                    let month = transactionDate.getMonth();
                    let year = transactionDate.getFullYear();

                    if (month == matchingMonth && year == matchingYear) {
                      if (transaction.transactionType == 'consultation') {
                        consultationBalance += transaction.transactionValue;
                      } else {
                        sessionBalance += transaction.transactionValue;
                      }
                    }
                  })

                  let newBalanceRef = this.firestore.collection('balances').doc(expert.id);

                  let balanceDoc: any = {};
                  balanceDoc['id'] = expert.id;
                  balanceDoc['userId'] = expert.id;
                  balanceDoc['role'] = 'expert';
                  balanceDoc['consultationBalance'] = consultationBalance;
                  balanceDoc['currentBalance'] = sessionBalance;
                  balanceDoc['fullName'] = expert.fullName;
                  balanceDoc['lastUpdated'] = firebase.firestore.Timestamp.now();

                  newBalanceRef.set(balanceDoc)
                    .then(() => console.log('balance doc created for expert ', expert.fullName))
                    .catch(err => console.log('error creating balance for expert ', expert.fullName, err));
                }
              })
            })
          }
        })
      }
    }
  }
   // function to check active users with atleast 1 session scheduled.
   async checkActiveUsers() {
    let chosenStartDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date("01/01/2023"));
    let chosenEndDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date("02/01/2023"));
   
    let userRef = this.firestore.collectionGroup('sessions', sessionRef => sessionRef.where('sessionEndDateTime', '>=',chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where("status","in",["Attended","Rescheduled"]));
    userRef.snapshotChanges().subscribe((results) => {
      let visited = Array.from({length: results.length}, (_, i) => false);
      // results.forEach((doc) => {
       console.log(results.length)
        // Traverse through array elements and
        // count frequencies
        if (!results.length) {
          console.log("No Data Available");
          return false;
        }
        let usersCount=1;
        for (let i=0;i<results.length;i++) {
          let count = 1;
          // console.log(count,"count");

          let session: any =results[i].payload.doc.data();
            // Skip this element if already processed
            if (visited[i] == true)
                continue;
          
          usersCount++;

         

            // Count frequency
            for (let j = i + 1; j < results.length; j++) {
              let nextSession: any =results[j].payload.doc.data();
              // console.log(session.sessionStudentId === nextSession.sessionStudentId);
                if (session.sessionStudentId === nextSession.sessionStudentId) {
                  // console.log(nextSession.sessionStudentId,count);
                    visited[j] = true;
                    count++;
                }
            }
            // console.log(session.sessionStudentId + " " + count);
        }
        console.log(usersCount,"usersCount");
       
      // })
    });
  }

  async ngOnInit() {
    console.log('deployed successfully')
    await this.adminService.getAdminDetails();
    this.statusArray = this.helper.sessionTypesObject;

    this.expertSub = this.adminService.ExpertList.subscribe((result) => {
      this.expertList = result;
      this.expertList.sort(function (x, y) {  //sorting expert dropdown list alphabetically
        return x['fullName'] > y['fullName'] ? 1 : -1;
      })
    });

    this.parentSub = this.adminService.ParentList.subscribe((result) => {
      this.parentList = result;
      this.parentList.sort(function (x, y) {
        return x['fullName'] > y['fullName'] ? 1 : -1; //sorting parent dropdown list alphabetically
      })
    });

    this.getAllSessions(false);

    this.navSub = this.adminService.navigateToInvite.subscribe(() => {
      this.router.navigateByUrl('/support/invite-parent');
    });

    this.adminService.SalesPocList.pipe(take(1)).subscribe(
      result => { this.salesPocList = result; }
    );

    await this.adminService.isSupport(); //checking if current logged in user has role === support
  }

  getAllSessions(isPagination) {
    if(!isPagination){
      this.combinedList=[];
      this.combinedListCopy=[];
      this.consultationList=[];
      this.sessionList=[];
    }

    this.sessionSub = this.adminService
      .filterSessionListPagination( this.expertSelected,this.parentSelected, this.statusSelected, this.chosenStartDate,this.chosenEndDate, this.filter,isPagination, this.lastVisibleSession)
      .pipe(
        tap((result: any) => {
         
          if (!result.length) {
          this.isLoading=false;
          this.mergeSort(this.sessionList, this.consultationList);
          return false;
        }else{
          for (let item of result) {
            this.sessionList.push(item.payload.doc.data());
          }
          this.lastVisibleSession = result[result.length - 1].payload.doc
        }
       
       }),
        switchMap(() => this.adminService.filterConsulatationListPagination( this.expertSelected,this.parentSelected, this.statusSelected, this.chosenStartDate,this.chosenEndDate, this.filter,isPagination,this.lastVisibleConsultation))
      ,take(1)) 
      .subscribe((result: any) => {
        if (!result.length) {
          this.isLoading=false;
          this.mergeSort(this.sessionList, this.consultationList);
          return false;
        }else{
          for (let item of result) {
            this.consultationList.push(item.payload.doc.data());
          } 
          this.lastVisibleConsultation = result[result.length - 1].payload.doc
        }
        
        this.mergeSort(this.sessionList, this.consultationList);
        this.isLoading = false;
      });
      
  }

  mergeSort(sessionList: AngularFirestoreDocument[], consultationList: AngularFirestoreDocument[]) {
    this.combinedList = [];
    this.combinedList = [...consultationList, ...sessionList];    //combining sessions and consultations into a singular list
    this.combinedListCopy = this.combinedList;
    this.filterCombinedList(this.parentSelected, this.expertSelected, this.statusSelected, this.filter);
  }

  editMeeting(sessionId: string) {
    this.adminService.getSession(sessionId).subscribe(this.editSession);
  }

  editSession = (result) => {
    let session: AngularFirestoreDocument;
    session = result.docs[0].data();
    console.log(session,"session");
    const dialogRef = this.dialog.open(AdminCancelSessionComponent, {  // reschedule flow for sessions
      disableClose: true,
      panelClass: 'cancel-session-dialog',
      data: { dialogType: 'reschedule' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const confirmationRef = this.dialog.open(
          AdminCancelConfirmationComponent,
          {
            panelClass: 'cancel-session-dialog',
            data: {
              session: session,
              cancelledBy: result.cancelledBy,
              dialogType: result.dialogType,
              adminDoc: this.adminService.adminDoc
            },
          }
        );

        confirmationRef.afterClosed().subscribe((result) => {
          this.reschedulingDetails = {
            reschedulingReason: result.reason,
            additionalReschedulingDetails: result.additionalDetails,
            rescheduledOn: result.rescheduledOn,
            rescheduledBy: result.rescheduledBy,
            rescheduledByName: result.rescheduledByName,
            rescheduledWith: result.rescheduledWith,
            rescheduledWithName: result.rescheduledWithName,
          };
          this.toRescheduleSession = result.session;
          this.reschedulingPerson = result.reschedulingPerson;
          const sessionRef = this.dialog.open(SessionDialog, {
            panelClass: 'rescheduleSessionDialog',
            data: {
              reschedulingPerson: result.reschedulingPerson,
              reschedulingDetails: this.reschedulingDetails,
              rescheduledSession: result.session,
              id: result.session.id,
              fromAdminAccount: true
            },
          });

          sessionRef.afterClosed().subscribe(async (result) => {
            if (result.event == 'Yes') {
              let now = firebase.firestore.FieldValue.serverTimestamp()
              const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.toRescheduleSession['sessionDate'].toDate());
              console.log(timeinMiliSec,"timeinMiliSec");
              await this.firestore
              .collection("new sessions")
              .doc(`${timeinMiliSec}`)
              .collection("sessions")
              .doc(this.toRescheduleSession['id'])  
                .update({
                  status: 'Rescheduled',
                  rescheduledOn: now,
                  reschedulingReason: this.reschedulingDetails.reschedulingReason,
                  additionalReschedulingDetails: this.reschedulingDetails.additionalReschedulingDetails,
                  rescheduledBy: this.reschedulingDetails.rescheduledBy,
                  rescheduledByName: this.reschedulingDetails.rescheduledByName,
                  rescheduledWith: this.reschedulingDetails.rescheduledWith,
                  rescheduledWithName: this.reschedulingDetails.rescheduledWithName,
                  reschedulingPerson: this.reschedulingPerson
                }).then().catch(err => console.log('error is ', err));
            }
          });
        });
      }
    });
  };

  cancelMeeting(sessionId: string) {
    // getting this session details
    this.adminService.getSession(sessionId).subscribe(this.cancelSession);
  }

  cancelSession = (result) => {
    let session: AngularFirestoreDocument;
    session = result.docs[0].data();

    const dialogRef = this.dialog.open(AdminCancelSessionComponent, {
      disableClose: true,
      panelClass: 'cancel-session-dialog',    //cancel session flow
      data: { dialogType: 'cancel' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialog.open(AdminCancelConfirmationComponent, {
          panelClass: 'cancel-session-dialog',
          data: {
            session: session,
            cancelledBy: result.cancelledBy,
            dialogType: result.dialogType,
            adminDoc: this.adminService.adminDoc
          },
        });
      }
    });
  };


  openPartiallyAttendedDialog(session: any) {
    const dialogRef = this.dialog.open(PartiallyAttendedDialogComponent, {
      disableClose: true,
      panelClass: 'partially-attended-dialog',
      data: { session: session },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == "successfull") {
        let message = 'Marked as partially attended';
        this.toastr.success(message, 'Successfull');
      }
    });
  }


  startMeeting(sessionDoc) {
    let docRef = this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionDoc.id)); 
    docRef.get().subscribe(result => {
      let session: any = result.docs[0].data();
      if (session['status'] == 'Scheduled') {
        const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session['id']);
        // let ref = this.firestore.collection('sessions').doc(session['id']);
        let sessionData = session;
        sessionData['attendedBy'].admin = true;
        sessionData['status'] = 'Starting';  //setting required backend fields to their correct values
        ref.update(sessionData).then(() => {
          let message = 'Your Session has started.';
          this.toastr.success(message, 'Join Now');

          //Set session status to started.
          // session['status'] = 'Starting';

          const startedSession = firebase
            .firestore()
            .collectionGroup('sessions')
            .where("id","==",session['id']);
          const observer = startedSession.onSnapshot((docSnapshot) => {
            let data = docSnapshot.docs[0].data();
            if (data['zoomJoinUrl']) {
              console.log("Rajan Admin Testing zoom-1");
              var newWin = window.open(data['zoomJoinUrl'], "_blank");
              // code to show dialog if pop-ups is blocked
              if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                console.log("Testing->Pop-Ups is Blocked");
                const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                  disableClose: false,
                  // data: { supportLink: "https://wa.me/+919606719826" },
                  panelClass: 'pop-ups-disable-dialog',
                });
                popUpsDisableRef.afterClosed().subscribe(result => {
                  if (!result) {
                    return;
                  }
                });
              }

            }
          });
        });
      } else if (session.status == 'Expert Joined' || session.status == 'Parent Joined' || session.status == 'Ongoing') {
        //Reopening the session as it has already started

        if (!session.attendedBy.admin) {
          const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
          // let ref = this.firestore.collection('sessions').doc(session.id);
          let sessionData: any = {};
          sessionData = session;
          // sessionData.status = 'Ongoing';
          sessionData.attendedBy.admin = true;
          ref.update(sessionData).then(() => {
            console.log("Rajan Admin Testing zoom-2");
            var newWin = window.open(session['zoomJoinUrl'], "_blank");
            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              console.log("Testing->Pop-Ups is Blocked");
              const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                disableClose: false,
                // data: { supportLink: "https://wa.me/+919606719826" },
                panelClass: 'pop-ups-disable-dialog',
              });
              popUpsDisableRef.afterClosed().subscribe(result => {
                if (!result) {
                  return;
                }
              });
            }

          }).catch(err => console.log('error  ', err))
        }
      } else {
        if (!session.attendedBy.admin) {
          const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
          // let ref = this.firestore.collection('sessions').doc(session.id);
          let sessionData: any = {};
          sessionData = session;
          sessionData.attendedBy.admin = true;
          ref.update(sessionData).then(() => {
            console.log("Admin Testing zoom-3");
            var newWin = window.open(session['zoomJoinUrl'], "_blank");
            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              console.log("Testing->Pop-Ups is Blocked");
              const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                disableClose: false,
                // data: { supportLink: "https://wa.me/+919606719826" },
                panelClass: 'pop-ups-disable-dialog',
              });
              popUpsDisableRef.afterClosed().subscribe(result => {
                if (!result) {
                  return;
                }
              });
            }

          }).catch(err => console.log('error  ', err))
        } else {
          console.log("Rajan Admin Testing zoom-4");
          var newWin = window.open(session['zoomJoinUrl'], "_blank");
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            console.log("Testing->Pop-Ups is Blocked");
            const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
              disableClose: false,
              // data: { supportLink: "https://wa.me/+919606719826" },
              panelClass: 'pop-ups-disable-dialog',
            });
            popUpsDisableRef.afterClosed().subscribe(result => {
              if (!result) {
                return;
              }
            });
          }

        }
      }

    });
  }

  onFilterSelected(filter: string) {
    // if(filter !== this.filter)
    //   this.combinedList = this.combinedList.reverse();
    this.filter = filter;
    this.getAllSessions(false);
    // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
  }

  joinButtonVisibility(session: any) {
    if (!session.attendedBy.admin &&
      (session['status'] == 'Expert Joined' ||
        session['status'] == 'Parent Joined' ||
        session['status'] == 'Ongoing')) {
      let sessionDate: Date = session['sessionDate'].toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session['startTime'].split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);
      let endTime = session['endTime'].split(':');
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + endingHour * 60 + endingMinute);

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rejoinBtnVisibility(session: any) {
    if (session.attendedBy.admin &&
      (session['status'] == 'Expert Joined' ||
        session['status'] == 'Parent Joined' ||
        session['status'] == 'Ongoing')) {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let endTime = session.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (endingHour * 60) + endingMinute);

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if ("session.sessionType != 'Free Consultation' && session.sessionType != 'Paid Consultation'") {
        if (now.getTime() <= (exactSessionDate.getTime() - 10 * 60 * 1000)) {
          //session end time has not crossed
          return true;
        }
      } else {
        if (now.getTime() <= exactSessionDate.getTime()) {
          //session end time has not crossed
          return true;
        }
      }
    }
    return false;
  }

  attendedButtonVisibility(session) {
    if (session.status == 'Update Status' || session.status == 'Ongoing') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      var startTime = session.startTime.split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() > exactJoinEndTime.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  noShowButtonVisibility(session) {
    let sessionDate: Date = session.sessionDate.toDate();
    let exactSessionDate = this.helper.istTime2(sessionDate);

    // startTime is HH:MM
    var startTime = session.startTime.split(':');
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);

    //Convert start time to minutes and add to session date to get exact start date time.
    exactSessionDate.setMinutes(
      exactSessionDate.getMinutes() + startingHour * 60 + startingMinute
    );

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);

    //Check if now is greater or equal to session start date time
    if (now.getTime() > exactSessionDate.getTime()) {
      //session start time has crossed
      return true;
    } else {
      return false;
    }

  }

  hasSessionStarted(session: AngularFirestoreDocument) {
    if (
      session['status'] == 'Scheduled' ||
      session['status'] == 'Started' ||
      session['status'] == 'Starting' ||
      session['status'] == 'Expert Joined' ||
      session['status'] == 'Parent Joined' ||
      session['status'] == 'Partially Attended' ||
      session['status'] == 'Attended'
    ) {
      let sessionDate: Date = session['sessionDate'].toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let startTime = session['startTime'].split(':');
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );

      let currentDate = new Date();
      let now = this.helper.istTime(currentDate);

      //Check if now is greater or equal to session start date time
      if (now.getTime() >= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  reschedule(consultation: AngularFirestoreDocument) {
    const partyConfirmationRef = this.dialog.open(PartyConfirmationDialogComponent, {
      disableClose: false,
      data: { dialogType: "reschedule" }, //reschedule consultation flow
      panelClass: "partyConfirmationDialog"
    });

    partyConfirmationRef.afterClosed().subscribe(
      data => {
        if (data) {
          const finalConfirmationRef = this.dialog.open(FinalConfirmationDialogComponent, {
            disableClose: false,
            data: { cancelledBy: data, dialogType: "reschedule", consultation: consultation }, //getting back cancelledBy from the user
            panelClass: "finalConfirmationDialog"
          })

          finalConfirmationRef.afterClosed().subscribe(
            data => {
              if (data) {
                const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
                const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
                // this.firestore.doc(`consultations/${consultation['id']}`)
                docRef
                  .update({ status: "Reschedule requested", statusDetails: data.statusDetails }) //setting appropriate backend fields on closing of final confirmation dialog
                  .then(() => {
                    const successRef = this.dialog.open(SuccessDialogComponent, {
                      disableClose: false,
                      data: "reschedule",
                      panelClass: "successDialog"
                    });
                  });
              }
            }
          );
        }
      }
    );
  }

  cancel(consultation: AngularFirestoreDocument) {
    const partyConfirmationRef = this.dialog.open(PartyConfirmationDialogComponent, {
      disableClose: false,
      data: { dialogType: "cancel" },
      panelClass: "partyConfirmationDialog"      //cancel consultation flow
    });

    partyConfirmationRef.afterClosed().subscribe(
      data => {
        if (data) {
          const finalConfirmationRef = this.dialog.open(FinalConfirmationDialogComponent, {
            disableClose: false,
            data: { cancelledBy: data, dialogType: "cancel", consultation: consultation }, //getting cancelledBy from user Input
            panelClass: "finalConfirmationDialog"
          });

          finalConfirmationRef.afterClosed().subscribe(
            data => {
              if (data) {
                const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
                const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
                // this.firestore.doc(`consultations/${consultation['id']}`)
                docRef
                  .update({ status: "Cancelled", statusDetails: data.statusDetails }) //setting appropriate backend fields on closing of final confirmation dialog
                  .then(() => {
                    const successRef = this.dialog.open(SuccessDialogComponent, {
                      disableClose: false,
                      data: "cancel",
                      panelClass: "successDialog"
                    });
                  });
              }
            }
          );
        }
      }
    );
  }

  noShow(consultation: AngularFirestoreDocument) {
    const noShowRef = this.dialog.open(NoShowConfirmationDialogComponent, {
      disableClose: false,
      panelClass: "noShowConfirmationDialog"    //noShow consultation flow
    })

    noShowRef.afterClosed().subscribe(
      data => {
        if (data) {
          const finalConfirmationRef = this.dialog.open(FinalConfirmationDialogComponent, {
            disableClose: false,
            panelClass: 'finalConfirmationDialog',
            data: { noShowBy: data.noShowBy, dialogType: data.dialogType, consultation: consultation }  //getting noShowBy from user Input
          });

          finalConfirmationRef.afterClosed().subscribe(
            data => {
              if (data) {
                let generateTransactionForExpert: any;
                let lastUpdated = new Date();
                let status = '';
                if (data.dialogType === 'noShow-Expert') {
                  status = 'No-show-by-Expert';
                  generateTransactionForExpert = 'No';
                } else if (data.dialogType === 'noShow-Parent(expert not paid)') {
                  status = 'No-show-by-Parent';
                  generateTransactionForExpert = 'No';
                } else if (data.dialogType === 'noShow-Parent(expert paid)') {
                  status = 'No-show-by-Parent';
                  generateTransactionForExpert = 'Yes';
                }

                const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
                const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
                // this.firestore.doc(`consultations/${consultation['id']}`)
                docRef
                  .update({
                    generateTransactionForExpert: generateTransactionForExpert,
                    status: status,
                    statusDetails: data.statusDetails,
                    lastUpdated: lastUpdated,
                  })
                  .then(() => { //setting appropriate backend fields on closing of final confirmation dialog
                    const successRef = this.dialog.open(SuccessDialogComponent, {
                      disableClose: false,
                      data: "noShow",
                      panelClass: "successDialog"
                    });
                  });
              }
            }
          );
        }
      }
    );
  }

  attend(consultation: AngularFirestoreDocument) {
    const finalConfirmationRef = this.dialog.open(FinalConfirmationDialogComponent, {
      disableClose: false,
      panelClass: "finalConfirmationDialog",
      data: { dialogType: "attended", consultation: consultation }  //attended consultation flow
    });

    finalConfirmationRef.afterClosed().subscribe(
      data => {
        if (data) {
          let lastUpdated = new Date();
          const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
          const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
          // this.firestore
          //   .doc(`consultations/${consultation['id']}`)
          docRef
            .update({
              status: 'Attended',
              statusDetails: data.statusDetails,
              'attendedBy.parent': true,
              'attendedBy.expert': true,
              lastUpdated: lastUpdated,
              generateTransactionForExpert: 'Yes'
            }).then(() => {
              const successRef = this.dialog.open(SuccessDialogComponent, {
                disableClose: false,
                data: "attended",
                panelClass: "successDialog"
              });
            });
        }
      }
    );
  }

  filterCombinedList(expertId: string = undefined, parentId: string = undefined, sortStatus: string = undefined, filter: string = "upcoming") {
    this.isLoading = true;
    let startDate = this.chosenStartDate;
    let endDate = this.chosenEndDate;

    let filterTime = firebase.firestore.Timestamp.now();
    let expertValidity = !(expertId === "" || expertId == undefined || expertId === null);
    let parentValidity = !(parentId === "" || parentId == undefined || parentId === null);
    let statusValidity = !(sortStatus === "" || sortStatus == undefined || sortStatus == null);

    let dateValidity = !(startDate == undefined || startDate == null || endDate == undefined || endDate == null);

    this.combinedList = this.combinedListCopy;

    if (filter === "upcoming") { //initial filtering based on past and upcoming
      this.combinedList = this.combinedList.filter(session => session['sessionEndDateTime'] >= filterTime);
    } else if (filter === "past") {
      this.combinedList = this.combinedList.filter(session => session['sessionEndDateTime'] < filterTime);
    }


    // if (!expertValidity && parentValidity && !statusValidity && !dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => session['sessionStudentId'] === parentId);
    // } else if (!expertValidity && parentValidity && statusValidity && !dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionStudentId'] === parentId) && (session['status'] === sortStatus));
    // } else if (expertValidity && !parentValidity && !statusValidity && !dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => session['sessionExpertId'] === expertId);
    // } else if (expertValidity && !parentValidity && statusValidity && !dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionExpertId'] === expertId) && (session['status'] === sortStatus));
    // } else if (!expertValidity && !parentValidity && statusValidity && !dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => session['status'] === sortStatus)
    // } else if (!expertValidity && !parentValidity && !statusValidity && !dateValidity) {
    // } else if (expertValidity && parentValidity && !statusValidity && !dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionStudentId'] === parentId) && (session['sessionExpertId'] === expertId));
    // } else if (expertValidity && parentValidity && statusValidity && !dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionStudentId'] === parentId) && (session['sessionExpertId'] === expertId) && (session['status'] === sortStatus));
    // } else if (!expertValidity && parentValidity && !statusValidity && dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionStudentId'] === parentId) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    // } else if (!expertValidity && parentValidity && statusValidity && dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionStudentId'] === parentId) && (session['status'] === sortStatus) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    // } else if (expertValidity && !parentValidity && !statusValidity && dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionExpertId'] === expertId) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    // } else if (expertValidity && !parentValidity && statusValidity && dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionExpertId'] === expertId) && (session['status'] === sortStatus) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    // } else if (!expertValidity && !parentValidity && statusValidity && dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['status'] === sortStatus) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    // } else if (!expertValidity && !parentValidity && !statusValidity && dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate));
    // } else if (expertValidity && parentValidity && !statusValidity && dateValidity) {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionStudentId'] === parentId) && (session['sessionExpertId'] === expertId) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    // }
    // else {
    //   this.combinedList = this.combinedList.filter(session => (session['sessionStudentId'] === parentId) && (session['sessionExpertId'] === expertId) && (session['status'] === sortStatus) && ((session['sessionDate'].toDate() >= this.chosenStartDate) && (session['sessionDate'].toDate() <= this.chosenEndDate)));
    // }




    this.combinedList.sort(function (x, y) { //sorting the final filtered list based on end date time
      return x['sessionEndDateTime'] - y['sessionEndDateTime'];
    })

    if (filter == 'past') //past sessions need to be in descending order
      this.combinedList.reverse();


    //Created for DateHeadings of the sessions
    let arr = new Array();
    let dateHead: Date;

    if(this.combinedList.length>0){
      dateHead = this.combinedList[0]["sessionDate"].toDate();
      for (let i = 0; i < this.combinedList.length; i++) {
        if (dateHead < this.combinedList[i]["sessionDate"].toDate()) { //for Upcoming sessions as they are in ascending in order of sessionDate
          arr.push(i);
          dateHead = this.combinedList[i]["sessionDate"].toDate();
        } else if (dateHead > this.combinedList[i]["sessionDate"].toDate()) { //for Past sessions as they are in descending in order of sessionDate
          arr.push(i);
          dateHead = this.combinedList[i]["sessionDate"].toDate();
        }
      }
      this.dateHeadingArray = arr;
    }
    
    this.combinedList= this.combinedList.filter((item, index, self) => {
      return self.findIndex((t) => {
        return t['id'] === item['id'] 
        // && t['status'] === item['status']
      }) === index
    });
    

    this.isLoading = false;
  }
  

  showCancel(session) {
    if (session['status'] == 'Scheduled' ||
      session['status'] == 'Started' ||
      session['status'] == 'Starting' ||
      session['status'] == 'Expert Joined' ||
      session['status'] == 'Parent Joined' ||
      session['status'] == 'Partially Attended' ||
      session['status'] == 'Attended') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute + 15);

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() <= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  copySessionLink(session: any) { //if zoom link exists on a session, copy it to clipboard
    this.clipboard.copy(session.zoomJoinUrl);
    let snackBarRef = this.snackbar.open('Copied successfully!', 'Undo', { duration: 3000 });

    snackBarRef.onAction().subscribe(() => { this.clipboard.copy(' '); });
  }

  joinConsultation(session) {
    let url = session.meetingLink;
    if (!url.match(/^https?:\/\//i)) //checking if the url matches regex with https and if not, adding https to the url
      url = 'https://' + url;

    window.open(url, '_blank');
  }

  // async showAttendedMarker(session:any){
  //   let ref = this.firestore.collection('sessions').doc(session.id);
  //   ref.get().subscribe((result)=>{
  //     if(result.exists){
  //       let session:any = result.data();

  //       if(session.status === "Exper")
  //     }
  //   })
  // }

  setParent(session: any) {
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
    const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
    // let ref = this.firestore.collection('sessions').doc(session.id);
    let sessionData: any = {};
    sessionData = session;
    sessionData.status = 'Ongoing';
    sessionData.attendedBy.parent = true;

    ref.update(sessionData).then().catch(err => console.log('error is ', err))
  }

  setExpert(session: any) {
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
    const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
    // let ref = this.firestore.collection('sessions').doc(session.id);
    let sessionData: any = {};
    sessionData = session;
    sessionData.status = 'Ongoing';
    sessionData.attendedBy.expert = true;

    ref.update(sessionData).then().catch(err => console.log('error is ', err))
  }

  showUrl(session: any) { //showing session url if it exists and if now <= sessionEndDateTime
    if (session['status'] == 'Expert Joined' ||
      session['status'] == 'Parent Joined' ||
      session['status'] == 'Ongoing') {
      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      let sessionEndDateTime = session.sessionDate.toDate();
      let exactSessionEndTime = this.helper.istTime2(sessionEndDateTime);

      let endTime = session['endTime'].split(':');
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionEndTime.setMinutes(
        exactSessionEndTime.getMinutes() + endingHour * 60 + endingMinute - 10
      );

      if (session.zoomJoinUrl && now <= exactSessionEndTime)
        return true;

    }
    return false;
  }

  setOngoingForParentVisibility(session: any) {
    if (session.status == "Expert Joined") {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);;

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if ((now.getTime() >= exactSessionDate.getTime()) && (now.getTime() <= exactJoinEndTime.getTime())) {
        //session start time has crossed
        return true;
      }
    }

    return false;
  }

  setOngoingForExpertVisibility(session: any) {
    if (session.status == "Parent Joined") {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);;

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if ((now.getTime() >= exactSessionDate.getTime()) && (now.getTime() <= exactJoinEndTime.getTime())) {
        //session start time has crossed
        return true;
      }
    }

    return false;
  }

  markAsPartiallyAttendedVisibility(session: any) {
    if (session.status === 'Ongoing' || session.status === 'Attended') {
      // let sessionDate: Date = session.sessionDate.toDate();
      // let exactSessionDate = this.helper.istTime2(sessionDate);

      // let joinBtnEndTime = new Date(sessionDate);
      // let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // // startTime is HH:MM
      // let startTime = session.startTime.split(":");
      // const startingHour = parseInt(startTime[0], 10);
      // const startingMinute = parseInt(startTime[1], 10);

      // //Convert start time to minutes and add to session date to get exact start date time.
      // exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      // exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 20);;

      // let currentTime = new Date();
      // let now = this.helper.istTime(currentTime);

      // //Check if now is greater or equal to session start date time
      // if ((now.getTime() >= exactSessionDate.getTime()) && (now.getTime() <= exactJoinEndTime.getTime())) {
      //   //session start time has crossed
      //   return true;
      // }

      return true;
    }

    return false;
  }

  //searching based on one parameter like Expert Name we are using 4 methods (resetExpertInput,onExpertFocus,onExpertBlur,onExpertClick )
  resetExpertInput(event: string) { //function to reset the expert filter if the expert input is empty
    // console.log("Testing Reset Expert");
    this.expertClicked = false;
    this.isExpertClicked = true;
    if (event === '') {
      this.expertSelected = "";
      this.getAllSessions(false);
      // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
    }
  }

  onExpertFocus() {
    console.log("Testing Expert focus");
    this.isExpertClicked = true;
  }

  onExpertBlur() {
    console.log("Testing Expert Blur");
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isExpertClicked = false;
      if (!this.expertClicked) {
        // console.log("Testing Expert Blur");
        this.expertName = '';
        this.expertSelected = "";
        this.getAllSessions(false);
        // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
      }
    }, 200);
  }

  onExpertClick(expert: AngularFirestoreDocument) {
    this.expertClicked = true;
    // console.log("Testing Expert Click", expert['id']);
    this.expertName = expert['fullName'];
    this.dropdownExpertHover = false;
    this.expertSelected = expert['id'];
    this.getAllSessions(false);
    // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter); //filtering session list whenever an expert is clicked from the dropdown list
  }


  resetParentInput(event: string) { //function to reset the parent filter if the expert input is empty
    this.parentClicked = false;
    this.isParentClicked = true;
    if (event === '') {
      this.parentSelected = '';
      this.getAllSessions(false);
      // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
    }
  }

  onParentFocus() {
    // console.log("Testing Expert focus");
    this.isParentClicked = true;
  }

  onParentBlur() {
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isParentClicked = false;
      if (!this.parentClicked) {
        // console.log("Testing Expert Blur");
        this.parentName = '';
        this.parentSelected = "";
        this.getAllSessions(false);
        // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
      }
    }, 200);
  }

  onParentClick(parent: AngularFirestoreDocument) {
    this.parentClicked = true;
    this.parentName = parent['fullName'];
    this.dropdownParentHover = false;
    this.parentSelected = parent['id'];
    this.getAllSessions(false);
    // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter); //filtering session list whenever a parent is clicked from the dropdown list
  }


  resetStatusInput(event: string) { //function to reset the status filter if the status input is empty
    this.statusClicked = false;
    this.isStatusClicked = true;
    if (event === '') {
      this.statusSelected = "";
      this.getAllSessions(false);
      // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
    }
  }

  onStatusFocus() {
    this.isStatusClicked = true;
  }

  onStatusBlur() {
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isStatusClicked = false;
      if (!this.statusClicked) {
        this.statusName = '';
        this.statusSelected = "";
        this.getAllSessions(false);
        // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
      }
    }, 200);
  }

  onStatusClick(status) {
    this.statusClicked = true;
    // console.log("Testing status Click", status);
    this.statusName = status['name'];
    this.dropdownExpertHover = false;
    this.statusSelected = this.statusName;
    this.getAllSessions(false);
    // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter); //filtering session list whenever an expert is clicked from the dropdown list
  }



  // These methods are created for filtering the sessions based on date
  onStartDateSelected(event) {
    this.chosenStartDate = event.value;
    console.log("Start Date: ", this.chosenStartDate);
    this.onMultiDatesSelected();
  }
  onEndtDateSelected(event) {
    this.chosenEndDate = event.value;
    console.log("End Date: ", this.chosenEndDate);
    this.onMultiDatesSelected();
  }
  onMultiDatesSelected() {
    console.log("Testing for date filter");
    this.getAllSessions(false);
    // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
  }
  resetForm() {
    this.chosenStartDate = undefined;
    this.chosenEndDate = undefined;
    this.getAllSessions(false);
    // this.filterCombinedList(this.expertSelected, this.parentSelected, this.statusSelected, this.filter);
  }



  ngOnDestroy() {
    if (this.navSub) this.navSub.unsubscribe();
    if (this.expertSub) this.expertSub.unsubscribe();
    if (this.parentSub) this.parentSub.unsubscribe();
    if (this.sessionSub) this.sessionSub.unsubscribe();
    // if(this.notificationSub){
    // console.log('unsibscibing succesfully')
    this.notificationSub && this.notificationSub.unsubscribe();
    // }
  }

  sessionDataDownload() {
    const dialogRef = this.dialog.open(sessionDataDownloadDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        console.log('result ', result)
      }
    });
  }
}


@Component({
  selector: 'session-data-download-dialog',
  template: `
    <div *ngIf="!workingOnData" style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
  
    <div *ngIf="!workingOnData" class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important; color: #F7524B;">Download</mat-card-title>
      <p>Load sessions data into excel sheets</p>
      
      <mat-form-field class="full-width-field" appearance="outline">
          <input matInput (dateChange)="onStartDateSelected($event)" 
            [matDatepicker]="startDatePicker" autocomplete="off" placeholder="From date DD/MM/YYY">

          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="full-width-field" appearance="outline">
        <input matInput (dateChange)="onEndDateSelected($event)" 
            [matDatepicker]="endDatePicker" autocomplete="off" placeholder="To date DD/MM/YYY">

        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <button mat-button id="addSessionPackBtn" style="background: #FCFCFC; color: #F7524B" class="right" (click)="getSessionDetails()">Yes</button>
      <button mat-dialog-close mat-button id="addSessionPackBtn" style="background: #F7524B !important; color: white !important; margin-right: 15px" class="right" >No</button>
    </div>

    <div *ngIf="workingOnData" class="text-center my-4" style="margin-top: 35px; margin-bottom: 20px;">
							<div class="lds-spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
							<div>
								<h3 style="color: #A5A5A5">
                We are getting the requested data.<br>Please wait for a moment.<br>
                </h3>
							</div>
						</div>
  `,
  styleUrls: ['./admin-dashboard.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})


export class sessionDataDownloadDialog implements OnInit {
  workbook = new Workbook();
  worksheet: any;
  monthlySessions: any[];
  monthlySessionsWithAllData: any
  workingOnData: boolean = false;

  startDate: any;
  endDate: any;

  constructor(
    private dialogRef: MatDialogRef<sessionDataDownloadDialog>,
    private firestore: AngularFirestore,
    private helper: HelperService
  ) { }

  ngOnInit() { }

  onStartDateSelected(event) {
    let date = event.value._d;
    this.startDate = new Date(date);
    this.startDate.setHours(0, 0, 0, 0);
  }

  onEndDateSelected(event) {
    let date = event.value._d;
    this.endDate = new Date(date);
    this.endDate.setHours(0, 0, 0, 0);
  }

  async getSessionDetails() {
    this.workingOnData = true;
    this.monthlySessions = [];
    this.worksheet = this.workbook.addWorksheet('example-session')

    this.worksheet.columns = [
      { header: 'sessionDate', key: 'sessionDate' },
      { header: 'sessionParentName', key: 'sessionParentName' },
      { header: 'sessionStudentName', key: 'sessionStudentName' },
      { header: 'sessionStudentId', key: 'sessionStudentId' },
      { header: 'parentTransactionValue', key: 'parentTransactionValue' },
      { header: 'expertTransactionValue', key: 'expertTransactionValue' },
      { header: 'startTime', key: 'startTime' },
      { header: 'slotDuration', key: 'slotDuration' },
      { header: 'sessionExpertName', key: 'sessionExpertName' },
      { header: 'sessionId', key: 'sessionId' },
      { header: 'status', key: 'status' },
      { header: 'serviceType', key: 'serviceType' }
    ]
    this.worksheet.getRow(1).font = { bold: true }

    let startingDate = new Date(this.startDate);
    let endingDate = new Date(this.endDate);
    let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
    let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)

    const snapshot = this.firestore.collectionGroup('sessions', ref => ref.where('sessionDate', ">=", startingTimeStamp).where('sessionDate', "<=", endingTimeStamp).orderBy('sessionDate', 'asc').orderBy('sessionEndDateTime', 'asc'))
      .get().toPromise().then(async (results) => {
        console.log('total sessions ', results.size);
        let count = 1;

        results.forEach(async (doc) => {
          let session: any = doc.data();
          console.log(count, 'session ', session.id)
          this.monthlySessions.push(session);
          count++;
        })

        this.addTransactionValue();
      })

  }

  addTransactionValue() {
    this.monthlySessionsWithAllData = [];
    let count = 0;

    this.monthlySessions.forEach(async doc => {
      let session: any = doc;
      await this.getSessionRelatedTransactions(session);
      count++;

      console.log('count ', count)
      if (count == this.monthlySessions.length) {
        console.log('count finally ', count)
        this.addDataToExcelSheet();
      }
    })

  }

  async getSessionRelatedTransactions(session: any) {
    // getting the transaction related to the session.
    return this.firestore.collection('transactions', ref => ref.where('sessionId', '==', session.id)).get().toPromise().then(documents => {
      let parentTransactionBalance = 0;
      let expertTransactionBalance = 0;

      documents.forEach(document => {
        let transaction: any = document.data();
        if (transaction.userId == session.sessionStudentId) {
          parentTransactionBalance += transaction.transactionValue;
          parentTransactionBalance += transaction.returnedSessionValue;
        } else if (transaction.userId == session.sessionExpertId) {
          expertTransactionBalance += transaction.transactionValue;
        }
      })

      session.parentTransactionValue = parentTransactionBalance;
      session.expertTransactionValue = expertTransactionBalance;

      this.monthlySessionsWithAllData.push(session);
      return;
    })
  }

  addDataToExcelSheet() {
    this.monthlySessionsWithAllData.sort(function (x, y) {
      return x.sessionEndDateTime - y.sessionEndDateTime
    })

    this.monthlySessionsWithAllData.forEach(doc => {
      let session: any = doc;

      let excelData: any = {};
      let date = new Date(session.sessionDate.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })

      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      excelData.sessionDate = onlyDateString;
      excelData.sessionParentName = session.sessionParentName;
      excelData.sessionStudentName = session.sessionStudentName;
      excelData.sessionStudentId = session.sessionStudentId;
      excelData.startTime = session.startTime;
      excelData.slotDuration = session.slotDuration;
      excelData.sessionExpertName = session.sessionExpertName;
      excelData.sessionId = session.id;
      excelData.status = session.status;
      excelData.serviceType = session.serviceType;
      excelData.parentTransactionValue = session.parentTransactionValue;
      excelData.expertTransactionValue = session.expertTransactionValue;

      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.addFile();
  }

  addFile() {
    let fName = "session-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')

      this.dialogRef.close();
    });
  }

  ngOnDestroy() { }
}
