import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from 'src/app/helper.service';

@Component({
  selector: 'app-final-confirmation-dialog',
  templateUrl: './final-confirmation-dialog.component.html',
  styleUrls: ['./final-confirmation-dialog.component.css']
})
export class FinalConfirmationDialogComponent implements OnInit {
  consultation: AngularFirestoreDocument
  constructor(private dialogRef: MatDialogRef<FinalConfirmationDialogComponent>,
    @Inject (MAT_DIALOG_DATA) public data: { cancelledBy?: string, consultation: AngularFirestoreDocument, dialogType: string, noShowBy?: string },
    private titleCasePipe: TitleCasePipe,
    public helper: HelperService)
    { }

  ngOnInit(): void {
    this.consultation = this.data.consultation;
  }

  close() {
    let statusDetails = "";

    if(this.data.dialogType === 'cancel') {
      statusDetails = `Cancelled By ${this.titleCasePipe.transform(this.data.cancelledBy)} (Not charged)`;
    } else if(this.data.dialogType === 'reschedule') {
      statusDetails = `Reschedule requested By ${this.titleCasePipe.transform(this.data.cancelledBy)}`;
    } else if(this.data.dialogType === 'noShow-Expert') {
      statusDetails = `No-show By ${this.titleCasePipe.transform(this.data.noShowBy)}`;
    } else if(this.data.dialogType === 'noShow-Parent(expert paid)') {
      statusDetails = `No-show By ${this.titleCasePipe.transform(this.data.noShowBy)}(expert paid)`;
    } else if(this.data.dialogType === 'noShow-Parent(expert not paid)') {
      statusDetails = `No-show By ${this.titleCasePipe.transform(this.data.noShowBy)}(expert not paid)`;
    } else if(this.data.dialogType === 'attended') {
      statusDetails = "Attended";
    }

    this.dialogRef.close({ dialogType: this.data.dialogType, statusDetails: statusDetails });
  }
}
