<div class="expert-dashboard-page-container">
  <mat-tab-group [selectedIndex]="selectedTabIndex">
    <mat-tab label="Sessions">
      <div>
        <div class="optionHolder">
          <span class="sessionFilterContainer" *ngIf="filter == 'Upcoming'">
            <button mat-button class='sessionFilterButton' (click)="onFilterChanged('Past')">Past</button>
            <button mat-button class='sessionFilterButton' style="background-color: #DBEF91;"
              (click)="onFilterChanged('Upcoming')"><span style="margin-left: -0.5vw;">Upcoming</span></button>
          </span>
          <span class="sessionFilterContainer" *ngIf="filter == 'Past'">
            <button mat-button class='sessionFilterButton' style="background-color: #DBEF91;"
              (click)="onFilterChanged('Past')">Past</button>
            <button mat-button class='sessionFilterButton' (click)="onFilterChanged('Upcoming')"><span
                style="margin-left: -0.5vw;">Upcoming</span></button>
          </span>
          <span>
            <a mat-button (click)="goToSessionBooking()" [disabled]="expertsService.currentUserDetails?.activityStatus == 'Session Disabled' || expertsService.currentUserDetails?.activityStatus == 'Session & Consultation Disabled'" class="newSessionButton">
              <mat-icon class="addSymbol">add</mat-icon>
              New Session
            </a>
          </span>
        </div>

        <h1 style="font-weight: 600;">Sort by:</h1>
        <div class="sortHolder">
          <mat-form-field style="margin-right: 10px;">
            <mat-label>Parent</mat-label>
            <!-- <mat-select disableOptionCentering [(value)]="parentSelected"
							(selectionChange)="filterCombinedList(parentSelected, statusSelected)">
							<mat-option>-</mat-option>
							<mat-option [value]="parent.id" *ngFor="let parent of parentList">{{ parent.fullName }}
							</mat-option>
						</mat-select> -->
            <input type="text" matInput [(ngModel)]="parentName" placeHolder="Parent Name" #parentField="matInput"
              (ngModelChange)="resetParentInput($event)" (blur)="onParentBlur()" (focus)="onParentFocus()">
            <div class="parentDropdown" *ngIf="isParentClicked">
              <mat-list id="parentList">
                <mat-list-item *ngFor="let parent of parentList | filter: parentName" (click)="onParentClick(parent)"
                  class="parentListItem">
                  {{ parent.fullName }}
                </mat-list-item>
              </mat-list>
            </div>
          </mat-form-field>
          <!-- sorting based on status -->
          <mat-form-field>
            <mat-label>Status</mat-label>
            <!-- <mat-select disableOptionCentering [(value)]="statusSelected"
							(selectionChange)="filterCombinedList(parentSelected, statusSelected)">
							<mat-option>-</mat-option>
							<mat-option [value]="status" *ngFor="let status of helper.sessionTypes">{{status}}
							</mat-option>
						</mat-select> -->
            <input type="text" matInput [(ngModel)]="statusName" placeholder="Status Type" #statusField="matInput"
              (ngModelChange)="resetStatusInput($event)" (blur)="onStatusBlur()" (focus)="onStatusFocus()">
            <div class="expertDropdown" *ngIf="isStatusClicked">
              <mat-list id="expertList">
                <mat-list-item *ngFor="let status of statusArray | filter: statusName" (click)="onStatusClick(status)"
                  class="expertListItem">
                  {{ status.name }}
                </mat-list-item>
              </mat-list>
            </div>
          </mat-form-field>

          <div class="sessionDateFilter">
            <div class="filterText">
              <p style="font-family: poppins;">Filter By Date</p>
            </div>
            <mat-form-field appearance="legacy" style="margin-right: 10px">
              <mat-label>Start Day</mat-label>
              <input [(ngModel)]="chosenStartDate" matInput [matDatepicker]="picker"
                (dateChange)="onStartDateSelected($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="legacy">
              <mat-label>End Day</mat-label>
              <input [(ngModel)]="chosenEndDate" matInput [matDatepicker]="picker1"
                (dateChange)="onEndtDateSelected($event)">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <div style="padding: 5px;">
              <button style="font-weight: 400; font-family: poppins; background-color:rgb(250, 238, 224)" mat-button
                color="warn" (click)="resetForm()" class="right">Reset Date</button>
            </div>
          </div>
        </div>

        <!-- session and consultation card holder -->
        <div>
          <!-- <mat-card class="box-shadow-none background-color-whitesmoke sessionsCardHolder"> -->
          <span class="left noSession" *ngIf="sessions?.length === 0">
            You have no '{{filter}}' sessions currently. Request for a new session.
          </span>

          <div *ngFor="let session of sessions; let i = index;">

            <div *ngIf="(dateHeadingArray.indexOf(i) > -1) || (i===0)" style="margin-top: 20px;">
              <h1 style="font-weight: 600; color: #000; font-family: Poppins">
                {{helper.istTime(session.sessionDate.toDate()) | date:'MMMM dd, YYYY'}}
              </h1>
            </div>

            <mat-card class=" sessionCard">
              <!-- consultation cards -->
              <div *ngIf="session.sessionType == 'Free Consultation' || session.sessionType == 'Paid Consultation'">
                <div>
                  <p style="margin: 0">{{ session.status | uppercase}}</p>
                </div>
                <mat-card-header>
                  <mat-card-title>
                    <h1 style="font-weight: 500;">
                      (E){{ session.sessionExpertName }} with (P){{
                      session.sessionParentName
                      }}, (C){{ session.sessionStudentName }}
                    </h1>
                  </mat-card-title>
                  <mat-card-subtitle style="font-weight: 500;">
                    <p style="font-weight: 600;">{{ session.serviceType }}</p>
                    <p>{{helper.istTime(session.sessionDate.toDate()) | date: "MMMM dd, Y" }}
                    </p>
                    <p>{{ session.startTime }} - {{helper.getEndTime(session)}} IST</p>
                    <p>{{ session.meetingLink }}</p>
                  </mat-card-subtitle>
                </mat-card-header>

                <mat-card-actions>
                  <button mat-button *ngIf="consultationJoinButtonVisibility(session) && filter != 'Past'" color="warn"
                    class="right joinBtn" (click)="joinConsultation(session)">Join</button>
                  <button mat-button *ngIf="rejoinBtnVisibility(session) && filter != 'Past'" color="warn"
                    class="right joinBtn" (click)="rejoinConsultation(session)">Rejoin</button>
                  <!-- <button mat-button color="warn" class="right joinBtn"
									*ngIf="consultationCancelButtonVisibility(session)">Cancel</button> -->
                </mat-card-actions>
              </div>

              <!-- session cards -->
              <div *ngIf="session.sessionType != 'Free Consultation' && session.sessionType != 'Paid Consultation'">

                <mat-card-header>
                  <mat-card-subtitle>
                    <span class="left" *ngIf="session.status == 'Ongoing'"
                      style="color:#7ACFFF">{{session.status}}</span>
                    <span class="left" *ngIf="session.status == 'Scheduled'"
                      style="color:#67CA5F">{{session.status}}</span>
                    <span class="left" *ngIf="session.status == 'Rescheduled'"
                      style="color: #FF6D6D">{{session.status}}</span>
                    <span class="left" *ngIf="session.status == 'Cancelled'"
                      style="color: #FF6D6D">{{session.status}}</span>
                    <span class="left" *ngIf="session.status == 'Session Request'"
                      style="color: #F77E46">{{session.status}}ed</span>
                    <span class="left" *ngIf="session.status == 'Attended'"
                      style="color: #96ED8F">{{session.status}}</span>
                    <span class="left" *ngIf="session.status == 'No Show'"
                      style="color: #787878">{{session.status}}</span>
                    <span class="left"
                      *ngIf="session.status != 'noShow-Expert' && session.status != 'Attended' && session.status != 'Session Request' && session.status != 'Cancelled' && session.status != 'Rescheduled' && session.status != 'Scheduled' && session.status != 'Ongoing'"
                      style="color: #252935">{{ session.status }}</span>
                  </mat-card-subtitle>
                  <mat-card-title>
                    <span>{{session.serviceType}} with {{session.sessionStudentName}}</span>
                  </mat-card-title>
                </mat-card-header>

                <mat-card-content class="card-content">
                  <div class="full-width">
                    <p></p>
                    <div class="sessionTimings">{{helper.istTime(session.sessionDate.toDate()) |
                      date:'MMMM dd,YYYY'}}
                    </div>
                    <div class="sessionTimings">{{session.startTime}} -
                      {{helper.getEndTime(session)}}
                      IST
                    </div>
                  </div>
                </mat-card-content>

                <mat-card-actions align="end"
                  *ngIf="session.status != 'Rescheduled' && session.status != 'Cancelled' && session.status != 'REQUEST CANCELLED' && session.status != 'Session Request Expired'">
                  <button mat-button *ngIf="joinButtonVisibility(session)" class="right actionButtons mat-small"
                    (click)="startMeeting(session)" color="warn">Join
                    Session</button>
                  <button mat-button *ngIf="rejoinBtnVisibility(session)" class="right actionButtons mat-small"
                    (click)="startMeeting(session)" color="warn">ReJoin Session</button>
                  <button mat-button
                    *ngIf="sessionCancelAndRescheduleBtnVisibility(session)"
                    class="right actionButtons mat-small" (click)="cancelMeeting(session.id)" color="warn">Cancel
                    Session</button>
                  <button mat-button
                    *ngIf="sessionCancelAndRescheduleBtnVisibility(session)"
                    class="right actionButtons mat-small" (click)="editSession(session.id)"
                    color="warn">Reschedule</button>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="My Students">
      <mat-card class="box-shadow-none background-color-whitesmoke">
        <app-students></app-students>
      </mat-card>
    </mat-tab>
    <mat-tab label="Profile">
      <app-expert-profile (leaveConfirmed)='changeTab($event)' [id]="id" [isEditing]="false" *ngIf="id"></app-expert-profile>

      <div class="balanceWrapper">
        <h1>Transaction History: </h1>
        <mat-card class="balanceCard">
          <mat-card-content>
            <div class="transactionByDate" *ngFor="let date of sortedDates">
              <div class="transactionDate">
                <h3 style="margin-bottom: 0;">{{ date | date: 'd MMMM y'}}</h3>
              </div>
              <mat-list style="padding-top: 0;">
                <div class="transaction" *ngFor="let transaction of transactionsByDate[date]">
                  <mat-list-item class="fullTransaction">
                    <div [ngStyle]="{'color': getFinalTransactionValue(transaction)>0?'#B1CA57':'#F7524B'}"
                      class="transactionVal">
                      <b>
                        {{ getFinalTransactionValue(transaction)>0?"+":"" }}{{
                        getFinalTransactionValue(transaction) }}
                      </b>
                    </div>
                    <div class="transactionDetailsWrapper">
                      <div class="transactionType">{{ transaction['reason'] }}</div>
                    </div>
                  </mat-list-item>
                  <hr>
                </div>
              </mat-list>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
  <router-outlet></router-outlet>
</div>
