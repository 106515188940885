import { Injectable } from "@angular/core";
import { AngularFirestoreDocument } from "@angular/fire/firestore";
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { AdminService } from "src/app/services/admin.service";

@Injectable({providedIn: 'root'})
export class ParentDataResolver implements Resolve<AngularFirestoreDocument> {

  constructor(private adminService: AdminService){}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.params['id'];
    return this.adminService.getExpert(id)
     .pipe(
       take(1),
       map((parentArray: AngularFirestoreDocument[]) => parentArray[0] ) //make sure parent data is loaded before page is shown
     );
  }
}
