<div class="page-container">
	<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">
		<mat-card class="content-container">
			<mat-card-header>
				<div mat-card-avatar></div>
				<mat-card-title>Hello Parents!</mat-card-title>
				<mat-card-subtitle>Find advice, solutions and a specialist at the click of a button.</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image class="card-image" src="assets/welcomeparents.svg">
			<mat-card-content>
			</mat-card-content>
			<mat-card-actions routerLink="/parent/signin" align="end">
				<a mat-button>SignIn or Register</a>
			</mat-card-actions>
		</mat-card>
		<mat-card class="content-container">
			<mat-card-header>
				<div mat-card-avatar></div>
				<mat-card-title>Hello Experts!</mat-card-title>
				<mat-card-subtitle>Find advice, solutions and a specialist at the click of a button.</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image class="card-image" src="assets/welcomeexpert.svg">
			<mat-card-content>
			</mat-card-content>
			<mat-card-actions align="end">
				<a mat-button routerLink="/expert/signin">SignIn or Register</a>
			</mat-card-actions>
		</mat-card>

		<mat-card class="content-container">
			<mat-card-actions align="end">
				<a mat-button routerLink="/sales/signin">SignIn or Register as sales admin</a>
			</mat-card-actions>
		</mat-card>
	</div>
</div>
