import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultationBlockedSlotService {
  checkingPersonId: any;
  selectedExpert: any;
  availableSlots = [];
  unavailableSlots = [];
  blockedSlotId:any;

  constructor(private firestore: AngularFirestore, private helper: HelperService) { }


  // function to create time label from minutes
  createTimeLabel(slot) {
    let slotTimeString = slot;
    let slotStartHour = Math.floor(slot / 60);
    let slotStartMinute = (slot % 60);
    if (slotStartMinute == 0) {
      slotTimeString = slotStartHour + ":" + slotStartMinute + '0';
    } else {
      slotTimeString = slotStartHour + ":" + slotStartMinute;
    }

    return slotTimeString;
  }

  availableSlotForMultiDates(id, datesAndSlots: any, checkingPersonId) {
    this.checkingPersonId = checkingPersonId;

    this.availableSlots.length = 0;
    this.availableSlots = [];
    this.unavailableSlots.length = 0;
    this.unavailableSlots = [];

    // getting expert's document
    this.firestore.collection('users').doc(id).get().subscribe((result) => {
      this.selectedExpert = result.data();
      this.getExpertBlockedSlots(datesAndSlots);
    });
  }

  // getting expert's blocked slots
  getExpertBlockedSlots(datesAndSlots) {
    let blockRef = this.firestore.collection('blockedslots', ref => ref.where('expertId', '==', this.selectedExpert.id));
    blockRef.get().subscribe(this.onBlockedSlotsResult.bind(this, datesAndSlots))
  }

  // After getting blocked slots documents.
  onBlockedSlotsResult = (datesAndSlots, results) => {
    let selectedExpertBlockedSlots = [];
    if (!results.empty) {
      results.forEach(result => {
        selectedExpertBlockedSlots.push(result.data())
      })
    }

    // getting expert's leaves.
    let docRef = this.firestore.collection('leaves', ref => ref.where('leaveBy', '==', this.selectedExpert.id));
    docRef.get().subscribe(this.onLeavesResult.bind(this, datesAndSlots, selectedExpertBlockedSlots))
  }

  // After getting leaves documents.
  onLeavesResult = (datesAndSlots, selectedExpertBlockedSlots, results) => {
    let selectedExpertLeaves = [];
    if (!results.empty) {
      results.forEach(result => {
        selectedExpertLeaves.push(result.data())
      })
    };

    let docRef = this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', this.selectedExpert.id));
    docRef.get().subscribe(this.onSessionResult.bind(this, datesAndSlots, selectedExpertBlockedSlots, selectedExpertLeaves))
  }

  // After getting scheduled sessions documents
  onSessionResult = (datesAndSlots, selectedExpertBlockedSlots, selectedExpertLeaves, results) => {
    let selectedExpertSessions = [];

    if (!results.empty) {
      results.forEach(result => {
        let session = result.data();
        selectedExpertSessions.push(session);
      })
    }

    // To get scheduled consultations of the teacher.
    let docRef = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', this.selectedExpert.id).where('status', '==', 'Scheduled'));
    docRef.get().subscribe(this.onConsultationsResult.bind(this, datesAndSlots, selectedExpertBlockedSlots, selectedExpertLeaves, selectedExpertSessions));
  }

  // After getting scheduled consultation of the teacher
  onConsultationsResult = (datesAndSlots, selectedExpertBlockedSlots, selectedExpertLeaves, selectedExpertSessions, results) => {
    let selectedExpertConsultations = [];

    if (!results.empty) {
      results.forEach(result => {
        let consultation = result.data();
        selectedExpertConsultations.push(consultation);
      })
    }

    // looping starts here for multiple dates and slots.
    for (let i = 0; i < datesAndSlots.length; i++) {
      let scheduledSessions = [];
      let scheduledConsultations = [];
      let date = new Date(datesAndSlots[i].date);
      let dateStamp = firebase.firestore.Timestamp.fromDate(date)
      let slotsToBeChecked = [];
      slotsToBeChecked = datesAndSlots[i].slotsToBeChecked;

      let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      let day = days[date.getDay()];

      // funneling and storing the sessions of this date.
      for (let m = 0; m < selectedExpertSessions.length; m++) {
        let session: any = selectedExpertSessions[m];

        let sessionDate: Date = session['sessionDate'].toDate();
        let exactSessionDate = this.helper.istTime2(sessionDate);

        let exactSessionTimeStamp = firebase.firestore.Timestamp.fromDate(exactSessionDate);

        if ((session.status == 'Scheduled' || session.status == "Session Request") && (exactSessionTimeStamp.seconds == dateStamp.seconds)) {
          let startTime = session.startTime.split(":");
          const startingHour = parseInt(startTime[0], 10);
          const startingMinute = parseInt(startTime[1], 10);
          startTime = (startingHour * 60) + startingMinute;

          let endTime = session.endTime.split(":");
          const endingHour = parseInt(endTime[0], 10);
          const endingMinute = parseInt(endTime[1], 10);
          endTime = (endingHour * 60) + endingMinute;
          scheduledSessions.push({ startTime, endTime });
        }
      }

      // funneling and storing the consultations of this date.
      for (let n = 0; n < selectedExpertConsultations.length; n++) {
        let consultation: any = selectedExpertConsultations[n];

        let sessionDate: Date = consultation['sessionDate'].toDate();
        let exactSessionDate = this.helper.istTime2(sessionDate);
        let exactSessionTimeStamp = firebase.firestore.Timestamp.fromDate(exactSessionDate);

        if (exactSessionTimeStamp.seconds == dateStamp.seconds) {
          let startTime = consultation.startTime.split(":");
          const startingHour = parseInt(startTime[0], 10);
          const startingMinute = parseInt(startTime[1], 10);
          startTime = (startingHour * 60) + startingMinute;

          let endTime = consultation.endTime.split(":");
          const endingHour = parseInt(endTime[0], 10);
          const endingMinute = parseInt(endTime[1], 10);
          endTime = (endingHour * 60) + endingMinute;
          scheduledConsultations.push({ startTime, endTime })
        }
      }

      // Calling getOpenSlots function to get avaiable slots.
      this.getOpenSlots(this.selectedExpert, day, slotsToBeChecked, date, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, scheduledConsultations);
    }
  }

  // To generate open slots from given details
  async getOpenSlots(teacher, day, slotsToBeChecked, date, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, scheduledConsultations) {
    let workingHoursOfThisDay = this.getWorkingHours(teacher, day);

    for (let j = 0; j < slotsToBeChecked.length; j++) {
      let slotEndTime = slotsToBeChecked[j].slotStartTime + slotsToBeChecked[j].slotDuration;

      // creating label to store as slots
      let slotStartTimeString = this.createTimeLabel(slotsToBeChecked[j].slotStartTime);
      let slotEndTimeString = this.createTimeLabel(slotEndTime);

      // creating session end date time
      let sessionEndDate = new Date(date);
      let sessionEndDateTime = this.helper.istTime3(sessionEndDate, Math.floor(slotEndTime / 60), slotEndTime % 60);

      // creating the unique id for blocking the slot
      const dateString = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
      let blockSlotId = dateString + slotStartTimeString + '-' + slotEndTimeString + this.selectedExpert.id;
      this.blockedSlotId = blockSlotId;

      // check slot to be inside of expert's working hours range
      let isInsideWorkingHours: boolean = this.isInsideWorkingHours(workingHoursOfThisDay, slotsToBeChecked[j].slotStartTime, slotsToBeChecked[j].slotDuration);
      if (isInsideWorkingHours) {
        // check slot to be out of blocked slots
        let outOfBlockedSlots = this.isOutOfBlockedSlots(blockSlotId, selectedExpertBlockedSlots);

        if (outOfBlockedSlots) {
          // check slot to be out of leave hours range
          let outOfLeaveRange = this.isOutOfLeaveRange(date, slotsToBeChecked[j].slotStartTime, slotsToBeChecked[j].slotDuration, selectedExpertLeaves);

          if (outOfLeaveRange) {
            // check slot to be out of pre scheduled sessions
            let outOfScheduledHours = this.isOutOfScheduledHours(slotsToBeChecked[j].slotStartTime, slotsToBeChecked[j].slotDuration, scheduledSessions);

            if (outOfScheduledHours) {
              // Check slot to be out of pre scheduled consultations
              let outOfScheduledConsultation = this.isOutOfScheduledConsultations(slotsToBeChecked[j].slotStartTime, slotsToBeChecked[j].slotDuration, scheduledConsultations);

              if (outOfScheduledConsultation) {
                let blockDate = this.helper.istTime3(date, 0, 0)

                // running scheduler function
                await firebase.firestore().runTransaction(async (transaction) => {

                  // reference to the blocked slot document.
                  let blockRef = firebase.firestore().collection('blockedslots').doc(blockSlotId);
                  await transaction.get(blockRef).then(async (doc) => {

                    // if slot is not blocked already
                    if (!doc.exists) {
                      // blocking the available slot for multiple session booking flow
                      let blockSlotObject = {};
                      blockSlotObject['id'] = blockSlotId;
                      blockSlotObject['blockedAt'] = firebase.firestore.FieldValue.serverTimestamp();
                      blockSlotObject['blockedBy'] = this.checkingPersonId;
                      blockSlotObject['blockDate'] = blockDate;
                      blockSlotObject['expertId'] = this.selectedExpert.id;

                      // blocking the slot
                      transaction.set(blockRef, blockSlotObject)

                    }

                  })
                }).then(() => {
                  // storing the available slots details
                  console.log('date is ', date)
                  console.log('block date is ', blockDate)
                  this.availableSlots.push({
                    id: this.helper.uuidv4(),
                    date: date,
                    label: slotStartTimeString + '-' + slotEndTimeString + " IST",
                    blockSlotId: blockSlotId,
                    sessionDate: blockDate,
                    startTime: slotStartTimeString,
                    endTime: slotEndTimeString,
                    slotDuration: slotsToBeChecked[j].slotDuration,
                    sessionEndDateTime: sessionEndDateTime
                  })
                }).catch((err) => {
                  console.log('error is ', err)
                  this.unavailableSlots.push({ date: date, label: slotStartTimeString + '-' + slotEndTimeString + " IST", blockSlotId: blockSlotId })
                })

              } else {
                console.log('push 1')
                this.unavailableSlots.push({ date: date, label: slotStartTimeString + '-' + slotEndTimeString + " IST", })
              }
            } else {
              console.log('push 2')
              this.unavailableSlots.push({ date: date, label: slotStartTimeString + '-' + slotEndTimeString + " IST", })
            }
          } else {
            console.log('push 3')
            this.unavailableSlots.push({ date: date, label: slotStartTimeString + '-' + slotEndTimeString + " IST", })
          }
        } else {
          console.log('push 4')
          this.unavailableSlots.push({ date: date, label: slotStartTimeString + '-' + slotEndTimeString + " IST", })
        }
      } else {
        console.log('push 5')
        this.unavailableSlots.push({ date: date, label: slotStartTimeString + '-' + slotEndTimeString + " IST", })
      }
    }

    // console.log("Rajan Checking unavailable slots: ", this.unavailableSlots);
    this.availableSlots.sort(this.sortAvailableSlots);
  }

  // to check slot to be inside of expert's working hours range
  isInsideWorkingHours(workingHoursOfThisDay, slotStartTime, slotDuration) {
    let isInsideWorkingHours: boolean = false;

    let slotEndTime = slotStartTime + slotDuration;
    workingHoursOfThisDay.forEach(workingHours => {

      if (slotStartTime >= workingHours.startTime && slotEndTime <= workingHours.endTime) {
        isInsideWorkingHours = true;
        return isInsideWorkingHours;
      }
    });
    return isInsideWorkingHours;
  }

  // To check if slot is out of blocked slots.
  isOutOfBlockedSlots(blockSlotId, selectedExpertBlockedSlots) {
    let outOfBlockedSlots: Boolean = true;
    if (selectedExpertBlockedSlots.length == 0) {
      return outOfBlockedSlots;
    }

    for (let i = 0; i < selectedExpertBlockedSlots.length; i++) {
      let id = selectedExpertBlockedSlots[i].id;
      let blockedBy = selectedExpertBlockedSlots[i].blockedBy;
      if (blockSlotId == id && blockedBy != this.checkingPersonId) {
        outOfBlockedSlots = false;
        break;
      }
    }

    return outOfBlockedSlots;
  }

  // To check if the slot is out of leave range or not.
  isOutOfLeaveRange(date, slot, slotDuration, selectedExpertLeaves) {
    let outOfLeaveRange: Boolean = true;
    if (selectedExpertLeaves.length == 0) {
      return outOfLeaveRange;
    }

    let dateNumber = date.getDate();
    let dateMonth = date.getMonth();
    let dateYear = date.getFullYear();
    let dateHour = Math.floor(slot / 60);
    let dateMinute = slot % 60;
    dateMinute += 2;

    let slotStartTime = new Date(dateYear, dateMonth, dateNumber, dateHour, dateMinute);
    let slotStartTimeStamp = firebase.firestore.Timestamp.fromDate(slotStartTime);

    let slotEnd = slot + slotDuration;
    dateHour = Math.floor(slotEnd / 60);
    dateMinute = slotEnd % 60;

    let slotEndTime = new Date(dateYear, dateMonth, dateNumber, dateHour, dateMinute);
    let slotEndTimeStamp = firebase.firestore.Timestamp.fromDate(slotEndTime);


    for (let k = 0; k < selectedExpertLeaves.length; k++) {

      let leaveStartDateTime: any = this.helper.istTime(selectedExpertLeaves[k].leaveStartDateTime.toDate())
      let leaveStartDateTimeStamp = firebase.firestore.Timestamp.fromDate(leaveStartDateTime);

      let leaveEndDateTime: any = this.helper.istTime(selectedExpertLeaves[k].leaveEndDateTime.toDate())
      let leaveEndDateTimeStamp = firebase.firestore.Timestamp.fromDate(leaveEndDateTime);

      if ((slotEndTimeStamp <= leaveStartDateTimeStamp) || (slotStartTimeStamp >= leaveEndDateTimeStamp)) {
        outOfLeaveRange = true;
      } else {
        outOfLeaveRange = false;
        break;
      }
    }
    return outOfLeaveRange;
  }

  // To check if slot is out of pre scheduled hours or not.
  isOutOfScheduledHours(slot, slotDuration, scheduledSessions) {
    let outOfScheduledHours: boolean = true;
    let slotEnd = slot + slotDuration;

    for (let m = 0; m < scheduledSessions.length; m++) {
      if ((slotEnd <= scheduledSessions[m].startTime) || (slot >= scheduledSessions[m].endTime)) {
        outOfScheduledHours = true;
      } else {
        outOfScheduledHours = false;
        break;
      }
    }
    return outOfScheduledHours;
  }

  // To check if slot is out of pre scheduled consultations or not.
  isOutOfScheduledConsultations(slot, slotDuration, scheduledConsultations) {
    let outOfScheduledConsultation: boolean = true;
    let slotEnd = slot + slotDuration;

    for (let m = 0; m < scheduledConsultations.length; m++) {
      if ((slotEnd <= scheduledConsultations[m].startTime) || (slot >= scheduledConsultations[m].endTime)) {
        outOfScheduledConsultation = true;
      } else {
        outOfScheduledConsultation = false;
        break;
      }
    }
    return outOfScheduledConsultation;
  }

  // To store the working hours of the teacher in our array as minute.
  getWorkingHours = (teacher, day) => {
    let workingHoursOfTheWeek = teacher.workingHours;
    let workingHoursOfThisDay = [];
    workingHoursOfTheWeek.forEach((workingHourOfTheDay) => {
      if (workingHourOfTheDay.weekday == day) {
        let startTime = workingHourOfTheDay.startTime.split(":");
        const startingHour = parseInt(startTime[0], 10);
        const startingMinute = parseInt(startTime[1], 10);
        startTime = (startingHour * 60) + startingMinute;

        let endTime = workingHourOfTheDay.endTime.split(":");
        const endingHour = parseInt(endTime[0], 10);
        const endingMinute = parseInt(endTime[1], 10);
        endTime = (endingHour * 60) + endingMinute;
        workingHoursOfThisDay.push({ startTime: startTime, endTime: endTime });
      }
    })
    workingHoursOfThisDay.sort(this.compareTime)
    return workingHoursOfThisDay;
  }

  // function to sort the workingHours array
  compareTime(a, b) {
    if (a.startTime < b.startTime) {
      return -1;
    } else if (a.startTime > b.startTime) {
      return 1;
    } else {
      return 0;
    }
  }
  //function to sort availableSlots array
  sortAvailableSlots(a, b) {
    if (a.sessionEndDateTime < b.sessionEndDateTime) {
      return -1;
    } else if (a.sessionEndDateTime > b.sessionEndDateTime) {
      return 1;
    } else {
      return 0;
    }
  }
}
