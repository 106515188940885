<div class="outer-box">
    <div class="hero">      
        <img src="../../assets/feedback_thankyou_image.png" class="hero-img" alt="">
        <img src="../../assets/cross.svg" class="cross" alt=""  mat-dialog-close>
    </div>
    <div class="content">
        <div class="content-heading">Thank You!</div>
        <div class="content-text"> By making your voice heard you made us improve KinderPass.</div>
    </div>
</div>
