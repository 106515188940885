<div class="page-container" *ngIf="isEditing && parent && parentForm">
	<div class="heading">
		<span class="material-icons" [ngStyle]="{'color': '#686B73', 'font-size': 'x-large'}"
			(click)="isEditing=false">arrow_back_ios_new</span>
		<span style="margin-left: 0.6vw;">
			<h1><b>Edit Profile</b></h1>
		</span>
	</div>
	<form [formGroup]="parentForm">
		<mat-horizontal-stepper formArrayName="profile" linear>
			<mat-step label="Welcome" *ngIf="profile.serviceTypes">
				<mat-card style="margin:20px">
					<mat-card-header>
						<mat-card-title>Welcome</mat-card-title>
						<div class="welcome-title-image"><img src="assets/welcomeparents.svg"></div>
					</mat-card-header>
					<mat-card-content>
						<p>You are now a few steps away from finding the perfect match for your child's needs. Please
							tell us a little more about you and your child.
						</p>
					</mat-card-content>
					<mat-card-actions>
						<button mat-button color="warn" matStepperNext (click)="pageNo=pageNo+1;">Next</button>
					</mat-card-actions>
				</mat-card>
			</mat-step>
			<mat-step formGroupName="0" stepControl="profile.get([0])" label="Personal">
				<mat-card>
					<mat-card-header>
						<mat-card-title>Tell us about you</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<mat-form-field class="full-width-field">
							<mat-label>Full Name</mat-label>
							<input matInput formControlName="fullName" [required]="!isEditing">
						</mat-form-field>

						<mat-form-field class="full-width-field">
							<mat-label>Email</mat-label>
							<input style="color: grey;" [readonly]="true" matInput formControlName="email" type="text" [required]="!isEditing">
						</mat-form-field>

						<mat-form-field class="full-width-field">
							<mat-label>Phone Number</mat-label>
							<input style="color: grey;" [readonly]="true" matInput formControlName="phoneNumber" type="text" [required]="!isEditing">
						</mat-form-field>

						<mat-form-field class="full-width-field">
							<mat-label>Whatsapp Number</mat-label>
							<input style="color: grey;" [readonly]="true" matInput formControlName="whatsappNumber" type="text" [required]="!isEditing">
						</mat-form-field>

						<mat-form-field class="full-width-field">
							<mat-label>Relation with child</mat-label>
							<mat-select formControlName="relationWithChild" [required]="!isEditing"
								disableOptionCentering panelClass="dropdown">
								<mat-option value="Father">Father</mat-option>
								<mat-option value="Mother">Mother</mat-option>
								<mat-option value="Other">Other</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field class="full-width-field">
							<mat-label>Child's Name</mat-label>
							<input #childNameCtrl matInput formControlName="childName" [required]="!isEditing">
						</mat-form-field>
						<mat-form-field class="full-width-field">
							<mat-label>Child's Gender</mat-label>
							<mat-select formControlName="childGender" [required]="!isEditing"
								disableOptionCentering panelClass="dropdown">
								<mat-option value="female">Girl</mat-option>
								<mat-option value="male">Boy</mat-option>
							</mat-select>
						</mat-form-field>
						
						<mat-form-field class="full-width-field">
							<mat-label>Child's Date Of Birth</mat-label>
						  <input matInput (dateChange)="onStartDateSelected($event)" [matDatepicker]="picker" readonly
							formControlName="childDob" autocomplete="off" [max]="maxDate" placeholder="DD/MM/YYYY">
						  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						  <mat-datepicker #picker></mat-datepicker>
						  <!-- <mat-error *ngIf="parentForm.controls.childDob.errors">
							Please Make a Selection
						  </mat-error> -->
						</mat-form-field>

						<mat-form-field class="full-width-field">
							<mat-label>Born at less than 37 weeks?</mat-label>
							<mat-select #premieCtrl formControlName="preemie" [required]="!isEditing" 
								disableOptionCentering panelClass="dropdown">
								<mat-option [value]="true">Yes</mat-option>
								<mat-option [value]="false">No</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="full-width-field" *ngIf="isPremieChecked(premieCtrl.value)">
							<mat-label>Born Weeks</mat-label>
							<input matInput formControlName="bornWeeks"  [required]="!isEditing">
						</mat-form-field>

						<mat-form-field class="full-width-field">
							<mat-label>Location</mat-label>
							<input #locationCtrl matInput formControlName="location" [required]="!isEditing">
						</mat-form-field>
					</mat-card-content>
					<mat-card-actions>
						<button mat-button matStepperPrevious (click)="isEditing = false" disabled
							color="warn">Back</button>
						<button mat-button color="warn" matStepperNext>Next</button>
					</mat-card-actions>
				</mat-card>
			</mat-step>
			<mat-step formGroupName="1" stepControl="profile.get([1])" label="Preferences">
				<mat-card>
					<mat-card-header>
						<mat-card-title>Service Preferences</mat-card-title>
						<mat-card-subtitle></mat-card-subtitle>
					</mat-card-header>
					<mat-card-content>
						<mat-form-field class="full-width-field">
							<mat-label>Child's Concern</mat-label>
							<mat-select #conditionsCtrl formControlName="conditions" multiple [required]="!isEditing"
								disableOptionCentering panelClass="dropdownPg2">
								<mat-option *ngFor="let condition of getConditions()" [value]="condition">{{condition}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field class="full-width-field" *ngIf="isOtherChecked(conditionsCtrl.value)">
							<mat-label>Specify Other Conditions</mat-label>
							<input matInput placeholder="e.g. - Condition 1, Condition 2"
								formControlName="otherConditions">
						</mat-form-field>
						<!-- <mat-form-field class="full-width-field">
							<mat-label>Service Type</mat-label>
							<mat-select #serviceTypeCtrl formControlName="serviceTypes" multiple [required]="!isEditing" disableOptionCentering panelClass="dropdownPg2">
								<mat-option *ngFor="let service of getServices()" value="{{service}}">{{service}}</mat-option>
							</mat-select>
						</mat-form-field> -->
						<mat-form-field class="full-width-field">
							<mat-label>Child's Age Range</mat-label>
							<mat-select #ageRangeCtrl formControlName="ageRange" [required]="!isEditing"
								disableOptionCentering panelClass="dropdownPg2 dropdownExtra">
								<mat-option *ngFor="let ageGroup of getAgeGroups()" value="{{ageGroup}}">{{ageGroup}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field class="full-width-field">
							<mat-label>Languages</mat-label>
							<mat-select #languageCtrl formControlName="languages" multiple [required]="!isEditing"
								disableOptionCentering panelClass="dropdownPg2">
								<mat-option *ngFor="let language of getLanguages()" value="{{language}}">{{language}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</mat-card-content>
					<mat-card-actions>
						<button mat-button matStepperPrevious type="button" color="warn">Back</button>
						<button mat-button type="submit" color="warn" [disabled]="parentForm.invalid"
							(click)="save();">Save</button>
					</mat-card-actions>
				</mat-card>
			</mat-step>
		</mat-horizontal-stepper>
	</form>
</div>
<div class="page-container profilePageContainer" *ngIf="!isEditing && parent && parentForm">
	<mat-card class="profileCard">
		<mat-card-header>
			<mat-card-subtitle style="color: #4391C1; text-align: initial;">{{parent.relationWithChild}}
			</mat-card-subtitle>
			<mat-card-title>{{parent.fullName}}</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div class="profileDetails">
				<span class="labelFields">Child's Name</span>
				<span class="detailFields">{{parent.childName}}</span>
			</div>
			<div class="profileDetails">
				<span class="labelFields">Child's Gender</span>
				<span class="detailFields">{{parent.childGender}}</span>
			</div>
			<div class="profileDetails">
				<span class="labelFields">Child's Date Of Birth</span>
				<span class="detailFields">{{parent.childDob?.toDate() | date: 'dd/MM/YYYY'}}</span>
			</div>
			<div class="profileDetails">
				<span class="labelFields">Concern</span>
				<span class="detailFields">{{parent.conditions}}</span>
			</div>
			<div class="profileDetails">
				<span class="labelFields">Language</span>
				<span class="detailFields">{{parent.languages}}</span>
			</div>
			<div class="profileDetails">
				<span class="labelFields">Age</span>
				<span class="detailFields">{{parent.ageRange}}</span>
			</div>
			<div class="profileDetails">
				<span class="labelFields">Balance</span>
				<span class="detailFields">{{balance?.currentBalance}}</span>
			</div>
			<div class="profileDetails"  *ngIf="parent?.validTill">
				<span class="labelFields">Valid Till</span>
				<span class="detailFields">{{parent?.validTill?.toDate() | date: 'dd/MM/YYYY'}}</span>
			</div>
			<div class="profileDetails"  *ngIf="parent?.gracePeriod">
				<span class="labelFields">Grace Period</span>
				<span class="detailFields">{{parent?.gracePeriod?.toDate() | date: 'dd/MM/YYYY'}}</span>
			</div>
			<div class="phoneDetails">
				<span class="material-icons">
					phone
				</span>
				<span>{{parent.phoneNumber}}</span>
				
			</div>
			<div class="phoneDetails">
			<span class="material-icons">
				email
			</span>
			<span>{{parent.email}}</span>
		</div>
		</mat-card-content>
		<mat-card-actions align="end">
			<button mat-button color="warn" class="cardActionBtn" (click)="editProfile()">Edit</button>
		</mat-card-actions>
	</mat-card>
</div>