<div style="width: 100%; display: flex; justify-content: center; padding-top: 10%;">
    <mat-card style="width: 30%">
        <mat-card-header style="display: flex; justify-content: center;">
            <mat-card-title>Sign up Form</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field class="formField">
                <mat-label>Name</mat-label>
                <input type="text" matInput #name>
            </mat-form-field>
            <br>
            <mat-form-field class="formField">
                <mat-label>Email</mat-label>
                <input type="email" matInput #email email>
            </mat-form-field>
            <br>
            <mat-form-field class="formField">
                <mat-label>Phone Number</mat-label>
                <input matInput ng2TelInput (countryChange)="onPhoneNumberCountryChange($event)"
                    [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
                    autocomplete="off" #phoneNumber>
            </mat-form-field>
            <br>
            <mat-form-field class="formField">
                <mat-label>Whatsapp Number</mat-label>
                <input matInput ng2TelInput (countryChange)="onWhatsappNumberCountryChange($event)"
                    [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
                    autocomplete="off" #whatsappNumber>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button color="warn" class="right"
                (click)="onSave(name.value, email.value, phoneNumber.value, whatsappNumber.value)">Save</button>
        </mat-card-actions>
    </mat-card>
</div>