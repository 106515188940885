<h2 style="text-align: initial; position: relative; left: 10px;">My Students :</h2>
<mat-card class="studentCard" *ngFor="let student of students">
	<mat-card-header>
		<mat-card-title>
				<span>{{student.childName}} - {{student.ageRange}} yrs</span>
		</mat-card-title>
		<mat-card-subtitle style="color: #4391C1; text-align: initial; font-weight: 700;">{{student.fullName}} ({{student.relationWithChild}})</mat-card-subtitle>					  		
	</mat-card-header>
	<mat-card-content>
		<div class="profileDetails">
			<span class="labelFields">Service Type</span>
			<span class="detailFields">{{student.serviceTypes}}</span>
		</div>
		<div class="profileDetails">
			<span class="labelFields">Concern</span>
			<span class="detailFields">{{student.conditions}}</span>
		</div>
		<div class="profileDetails">
			<span class="labelFields">Languages</span>
			<span class="detailFields">{{student.languages}}</span>
		</div>
	</mat-card-content>
	<!-- <mat-card-actions align="end">
		<button mat-button class="right mat-small" (click)="viewGoals(student.id)">View Goals</button>
	</mat-card-actions> -->
</mat-card>

