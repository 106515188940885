import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import firebase from 'firebase/app';
import { Subject } from 'rxjs';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  scheduledSessions: any[];
  selectedExpert: any;
  openSlots: any[];
  openSlotsAfter24Hours: any[];
  openSlotsOfMultidays: slotForMultiDays[]; //Array is created for multidays
  multiDaysCounter = 0;

  constructor(private firestore: AngularFirestore, private helper: HelperService) {
    this.openSlotsOfMultidays = [];
  }

  // this is the starting point of the function
  checkForSlots(id, chosenDate, slotDuration) {
    console.log('selected local date ', chosenDate);
    let date = new Date(chosenDate);
    this.openSlotsAfter24Hours = [];
    this.openSlotsAfter24Hours.length = 0;
    this.firestore.collection('users').doc(id).get().subscribe(this.onCheckResult.bind(this, date, slotDuration));
  }

  // After getting teacher's document
  onCheckResult = (date, slotDuration, result) => {
    this.selectedExpert = result.data();

    let blockRef = this.firestore.collection('blockedslots', ref => ref.where('expertId', '==', this.selectedExpert.id));
    blockRef.get().subscribe(this.onBlockedSlotsResult.bind(this, date, slotDuration))
  }

  // After getting blocked slots documents.
  onBlockedSlotsResult = (date, slotDuration, results) => {
    let selectedExpertBlockedSlots = [];
    if (!results.empty) {
      results.forEach(result => {
        selectedExpertBlockedSlots.push(result.data())
      })
    }

    let docRef = this.firestore.collection('leaves', ref => ref.where('leaveBy', '==', this.selectedExpert.id));
    docRef.get().subscribe(this.onLeavesResult.bind(this, date, slotDuration, selectedExpertBlockedSlots))

  }

  // After getting leaves documents.
  onLeavesResult = (date, slotDuration, selectedExpertBlockedSlots, results) => {
    let selectedExpertLeaves = [];
    if (!results.empty) {
      results.forEach(result => {
        selectedExpertLeaves.push(result.data())
      })
    }


    // Convert the input date to the desired timezone (GMT+0530)
    const currentOffset = date.getTimezoneOffset(); // Get the current timezone offset in minutes
    console.log(currentOffset,"offset");
    const targetOffset = 330; // Offset for GMT+0530 is -330 minutes (5 hours 30 minutes)

    // Calculate the total difference in minutes
    const totalOffset = -(targetOffset + currentOffset); // Difference between the target and current offsets
    console.log(totalOffset,"totalOffset");

    // Calculate the milliseconds to adjust
    const adjustedTime = date.getTime() + (totalOffset * 60000);
    console.log(adjustedTime,"adjustedTime");

    // Calculate the time by adding the difference in offsets
    const convertedDate = new Date(adjustedTime);
    console.log(convertedDate,"convertedDate");

    
    let dateStamp =firebase.firestore.Timestamp.fromDate(convertedDate);
   
    let docRef = this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', this.selectedExpert.id).where('status',"in",["Scheduled","Session Request"]).where('sessionDate','==',dateStamp));
    docRef.get().subscribe(this.onSessionResult.bind(this, date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves,convertedDate))
  }

  // After getting scheduled sessions documents
  onSessionResult = (date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves,convertedDate, results) => {
    let dateStamp = firebase.firestore.Timestamp.fromDate(convertedDate)
    let scheduledSessions = [];

    if (!results.empty) {
      results.forEach(result => {
        let session = result.data();

        let sessionDate: Date = session['sessionDate'].toDate();
        let exactSessionDate = this.helper.istTime2(sessionDate);
      
        let exactSessionTimeStamp = firebase.firestore.Timestamp.fromDate(sessionDate);
        // if ((session.status == 'Scheduled' || session.status == "Session Request") && (exactSessionTimeStamp.seconds == dateStamp.seconds)) {
        if (exactSessionTimeStamp.seconds == dateStamp.seconds) {
          let startTime = session.startTime.split(":");
          const startingHour = parseInt(startTime[0], 10);
          const startingMinute = parseInt(startTime[1], 10);
          startTime = (startingHour * 60) + startingMinute;

          let endTime = session.endTime.split(":");
          const endingHour = parseInt(endTime[0], 10);
          const endingMinute = parseInt(endTime[1], 10);
          endTime = (endingHour * 60) + endingMinute;
          scheduledSessions.push({ startTime, endTime });

        }
      })
    }

    //initializing global variable scheduledSessions for booking a slot
    this.scheduledSessions = scheduledSessions;


    // To get scheduled consultations of the teacher.
    let docRef = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', this.selectedExpert.id).where('status', '==', 'Scheduled'));
    docRef.get().subscribe(this.onConsultationsResult.bind(this, date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions));
  }


  onConsultationsResult = (date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, results) => {
    let scheduledConsultations = [];
    let dateStamp = firebase.firestore.Timestamp.fromDate(date)

    if (!results.empty) {
      results.forEach(result => {
        let consultation = result.data();

        let sessionDate: Date = consultation['sessionDate'].toDate();
        let exactSessionDate = this.helper.istTime2(sessionDate);

        let exactSessionTimeStamp = firebase.firestore.Timestamp.fromDate(exactSessionDate);

        if (exactSessionTimeStamp.seconds == dateStamp.seconds) {
          // console.log('yes consultation')
          let startTime = consultation.startTime.split(":");
          const startingHour = parseInt(startTime[0], 10);
          const startingMinute = parseInt(startTime[1], 10);
          startTime = (startingHour * 60) + startingMinute;

          let endTime = consultation.endTime.split(":");
          const endingHour = parseInt(endTime[0], 10);
          const endingMinute = parseInt(endTime[1], 10);
          endTime = (endingHour * 60) + endingMinute;
          scheduledConsultations.push({ startTime, endTime })
        }
      })
    }

    let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    let day = days[date.getDay()];

    // Calling getOpenSlots function to get avaiable slots.
    this.getOpenSlots(this.selectedExpert, day, slotDuration, date, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, scheduledConsultations);
  }

  // To generate open slots from given details
  getOpenSlots = (teacher, day, slotDuration, date, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, scheduledConsultations) => {
    let now = new Date();
    let workingHoursOfThisDay = this.getWorkingHours(teacher, day);
    let openSlots = [];
    this.openSlots = [];
    this.openSlotsAfter24Hours = [];

    workingHoursOfThisDay.forEach(a => {
      for (let slot = a.startTime; slot <= a.endTime - slotDuration; slot += slotDuration) {
        // check slot to be out blocked slots
        let outOfBlockedSlots = this.isOutOfBlockedSlots(date, slot, slotDuration, selectedExpertBlockedSlots);
        if (!outOfBlockedSlots) {
          continue
        } else {
          // check slot to be out of leave hours range
          let outOfLeaveRange = this.isOutOfLeaveRange(date, slot, slotDuration, selectedExpertLeaves);
          if (!outOfLeaveRange) {
            continue;
          } else {
            // check slot to be out of pre scheduled sessions
            let outOfScheduledHours = this.isOutOfScheduledHours(slot, slotDuration, scheduledSessions);
            if (!outOfScheduledHours) {
              continue;
            } else {
              // Check slot to be out of pre scheduled consultations
              let outOfScheduledConsultation = this.isOutOfScheduledConsultations(slot, slotDuration, scheduledConsultations);
              if (!outOfScheduledConsultation) {
                continue;
              } else {
                let slotStartTime;
                let slotEndTime = slot + slotDuration;

                let slotStartHour = Math.floor(slot / 60);
                let slotStartMinute = (slot % 60);
                if (slotStartMinute == 0) {
                  slotStartTime = slotStartHour + ":" + slotStartMinute + '0';
                } else {
                  slotStartTime = slotStartHour + ":" + slotStartMinute;
                }

                let slotEndHour = Math.floor(slotEndTime / 60);
                let slotEndMinute = (slotEndTime % 60);
                if (slotEndMinute == 0) {
                  slotEndTime = slotEndHour + ":" + slotEndMinute + '0';
                } else {
                  slotEndTime = slotEndHour + ":" + slotEndMinute;
                }
                openSlots.push({ label: slotStartTime + '-' + slotEndTime + " IST", value: slotStartTime });

                let slotDate = new Date(date)
                slotDate.setHours(slotStartHour, slotStartMinute, 0, 0)
                let diff = Math.round(Math.abs(now.getTime() - slotDate.getTime()) / 60000);
                if (diff >= 1440) {
                  this.openSlotsAfter24Hours.push({ label: slotStartTime + '-' + slotEndTime + " IST", value: slotStartTime });
                }
              }
            }
          }
        }
      }

    });

    //initializing global variable openslots for booking a slot
    this.openSlots = openSlots;

    //converting to required date and pushing slots into array for multidays
    let month = date.toLocaleString('default', { month: 'long' });
    let Day = date.toLocaleDateString('locale', { weekday: 'long' });
    let requiredDateFormat = date.getDate() + " " + month.substring(0, 3) + " " + Day.substring(0, 3);
    // console.log(date.getDate() + " " + month.substring(0, 3));
    this.openSlotsOfMultidays.push({ date: requiredDateFormat, openSlots: this.openSlotsAfter24Hours });
  }

  get openSlotsAfterADay() {
    return this.openSlotsAfter24Hours;
  }

  // To store the working hours of the teacher in our array as minute.
  getWorkingHours = (teacher, day) => {
    let workingHoursOfTheWeek = teacher.workingHours;
    let workingHoursOfThisDay = [];
    workingHoursOfTheWeek.forEach((workingHourOfTheDay) => {
      if (workingHourOfTheDay.weekday == day) {
        let startTime = workingHourOfTheDay.startTime.split(":");
        const startingHour = parseInt(startTime[0], 10);
        const startingMinute = parseInt(startTime[1], 10);
        startTime = (startingHour * 60) + startingMinute;

        let endTime = workingHourOfTheDay.endTime.split(":");
        const endingHour = parseInt(endTime[0], 10);
        const endingMinute = parseInt(endTime[1], 10);
        endTime = (endingHour * 60) + endingMinute;
        workingHoursOfThisDay.push({ startTime: startTime, endTime: endTime });
      }
    })
    workingHoursOfThisDay.sort(this.compareTime)
    // this.workingHoursOfThisDay.sort(this.compareTime)
    return workingHoursOfThisDay;
  }

  // To check if slot is out of blocked slots.
  isOutOfBlockedSlots(date, slot, slotDuration, selectedExpertBlockedSlots) {
    let outOfBlockedSlots: Boolean = true;
    if (selectedExpertBlockedSlots.length == 0) {
      return outOfBlockedSlots;
    }
    let slotEndTime = slot + slotDuration;

    const dateString = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();

    let slotStartTimeString: any;
    let slotStartHour = Math.floor(slot / 60);
    let slotStartMinute = (slot % 60);
    if (slotStartMinute == 0) {
      slotStartTimeString = slotStartHour + ":" + slotStartMinute + '0';
    } else {
      slotStartTimeString = slotStartHour + ":" + slotStartMinute;
    }

    let slotEndTimeString: any;
    let slotEndHour = Math.floor(slotEndTime / 60);
    let slotEndMinute = (slotEndTime % 60);
    if (slotEndMinute == 0) {
      slotEndTimeString = slotEndHour + ":" + slotEndMinute + '0';
    } else {
      slotEndTimeString = slotEndHour + ":" + slotEndMinute;
    }

    let blockSlotId = dateString + slotStartTimeString + '-' + slotEndTimeString + this.selectedExpert.id;

    for (let i = 0; i < selectedExpertBlockedSlots.length; i++) {
      let id = selectedExpertBlockedSlots[i].id;
      if (blockSlotId == id) {
        console.log(blockSlotId, 'is already blocked')
        outOfBlockedSlots = false;
        break;
      }
    }

    return outOfBlockedSlots;
  }
  
  // to check slot to be inside of expert's working hours range
  isInsideWorkingHours(workingHoursOfThisDay, slotStartTime, slotDuration) {
    let isInsideWorkingHours: boolean = false;

    let slotEndTime = slotStartTime + slotDuration;
    workingHoursOfThisDay.forEach(workingHours => {

      if (slotStartTime >= workingHours.startTime && slotEndTime <= workingHours.endTime) {
        isInsideWorkingHours = true;
        return isInsideWorkingHours;
      }
    });
    return isInsideWorkingHours;
  }

  // To check if the slot is out of leave range or not.
  isOutOfLeaveRange(date, slot, slotDuration, selectedExpertLeaves) {
    let outOfLeaveRange: Boolean = true;
    if (selectedExpertLeaves.length == 0) {
      return outOfLeaveRange;
    }

    let dateNumber = date.getDate();
    let dateMonth = date.getMonth();
    let dateYear = date.getFullYear();
    let dateHour = Math.floor(slot / 60);
    let dateMinute = slot % 60;
    dateMinute += 2;

    let slotStartTime = new Date(dateYear, dateMonth, dateNumber, dateHour, dateMinute);
    let slotStartTimeStamp = firebase.firestore.Timestamp.fromDate(slotStartTime);

    let slotEnd = slot + slotDuration;
    dateHour = Math.floor(slotEnd / 60);
    dateMinute = slotEnd % 60;

    let slotEndTime = new Date(dateYear, dateMonth, dateNumber, dateHour, dateMinute);
    let slotEndTimeStamp = firebase.firestore.Timestamp.fromDate(slotEndTime);


    for (let k = 0; k < selectedExpertLeaves.length; k++) {
      let leaveStartDateTime: any = this.helper.istTime(selectedExpertLeaves[k].leaveStartDateTime.toDate())
      let leaveStartDateTimeStamp = firebase.firestore.Timestamp.fromDate(leaveStartDateTime);

      let leaveEndDateTime: any = this.helper.istTime(selectedExpertLeaves[k].leaveEndDateTime.toDate())
      let leaveEndDateTimeStamp = firebase.firestore.Timestamp.fromDate(leaveEndDateTime);

      if ((slotEndTimeStamp <= leaveStartDateTimeStamp) || (slotStartTimeStamp >= leaveEndDateTimeStamp)) {
        outOfLeaveRange = true;
      } else {
        outOfLeaveRange = false;
        break;
      }
    }
    return outOfLeaveRange;
  }

  // To check if slot is out of pre scheduled hours or not.
  isOutOfScheduledHours(slot, slotDuration, scheduledSessions) {
    let outOfScheduledHours: boolean = true;
    let slotEnd = slot + slotDuration;

    for (let m = 0; m < scheduledSessions.length; m++) {
      if ((slotEnd <= scheduledSessions[m].startTime) || (slot >= scheduledSessions[m].endTime)) {
        outOfScheduledHours = true;
      } else {
        outOfScheduledHours = false;
        break;
      }
    }
    return outOfScheduledHours;
  }

  // To check if slot is out of pre scheduled consultations or not.
  isOutOfScheduledConsultations(slot, slotDuration, scheduledConsultations) {
    let outOfScheduledConsultation: boolean = true;
    let slotEnd = slot + slotDuration;

    for (let m = 0; m < scheduledConsultations.length; m++) {
      if ((slotEnd <= scheduledConsultations[m].startTime) || (slot >= scheduledConsultations[m].endTime)) {
        outOfScheduledConsultation = true;
      } else {
        outOfScheduledConsultation = false;
        break;
      }
    }
    return outOfScheduledConsultation;
  }

  // function to sort the workingHours array
  compareTime(a, b) {
    if (a.startTime < b.startTime) {
      return -1;
    } else if (a.startTime > b.startTime) {
      return 1;
    } else {
      return 0;
    }
  }

  //Starts from here for checking slots for multidays
  onCheckingSlotsForMultidays(id, startingDate, endingDate, slotDuration) {
    this.openSlotsOfMultidays = [];
    this.multiDaysCounter = 0;
    for (let i = startingDate.getTime(); i <= endingDate.getTime(); i += 86400000) {
      let date = new Date(i);
      this.multiDaysCounter++;
      this.checkForSlots(id, date, slotDuration);
    }
  }

  //For booking a session manually
  isOutOfScheduledHoursForBooking(slot, slotDuration) {
    let outOfScheduledHours: boolean = true;
    let slotEnd = slot + slotDuration;
    for (let m = 0; m < this.scheduledSessions.length; m++) {
      if ((slotEnd <= this.scheduledSessions[m].startTime) || (slot >= this.scheduledSessions[m].endTime)) {
        outOfScheduledHours = true;
      } else {
        outOfScheduledHours = false;
        break;
      }
    }
    return outOfScheduledHours;
  }


}

interface slotForMultiDays {
  date: string;
  openSlots: any[]
}

interface workingHourOfThisDay {
  startTime: number,
  endTime: number
}