<div class="newSessionMainContainer">
    <div class="heading">
        <a matButton routerLink="/parent/dashboard" id="back-button" (click)="showBookSession = false">
            <mat-icon>arrow_back</mat-icon>
        </a>
        <h2>Schedule new session</h2>
    </div>
    <p style="text-align: start; margin-top: 20px;">Your classes will be setup according to actual availability of the
        educator</p>
    <p>Select your expert</p>
    <div class="teachersList">
        <!-- <button mat-button *ngFor="let expert of experts" class="teacherListItem" (click)="onExpertSelected(expert)">{{expert.fullName}}</button> -->
        <mat-form-field color="warn">
            <mat-select (selectionChange)="onExpertSelected($event.value)" placeholder="Expert" disableOptionCentering>
                <mat-option class="expertOptions" *ngFor="let expert of experts" [value]="expert">
                    <p style="margin-bottom: 0; font-weight: 400;">{{ expert.fullName }}</p>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <p *ngIf="!isExpertActive" style="color: #F7524B;">Session booking is disabled for {{selectedExpert.fullName}}</p>

    <div class="expertLeaves" *ngIf="isExpertOnLeave">
        <h2 style="margin-bottom: 0px !important;" class="messageHeading">Expert On Leave</h2>
        <div *ngFor="let leave of expertsLeaves; let i = index">
            <h3 style="margin-bottom: -5px !important; margin-top: 6px !important;">Leave: {{i+1}}</h3>
            <div style="color: #A5A5A5;">From: {{helper.istTime(leave.leaveStartDateTime.toDate()) | date: 'MMM d, y, h:mm a'}} IST</div>
            <div style="color: #A5A5A5;">To: {{helper.istTime(leave.leaveEndDateTime.toDate()) | date: 'MMM d, y, h:mm a'}} IST</div>
        </div>
    </div>


    <div *ngIf="isExpertSelected">
        <p>Select Session Type(Service)</p>
        <div class="sessionTypeList">
            <mat-form-field color="warn">
                <mat-select placeholder="Session Type" (selectionChange)="onSessionTypeSelected($event.value)">
                    <mat-option *ngFor="let sessionType of sessionTypes" [value]="sessionType">{{sessionType }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>



    <!-- <div class="numberOfSessionsContainer" *ngIf="isExpertSelected">
        <p style="text-align: start;margin: 8px 0;">Total number of sessions you want to schedule</p>
        <mat-select class="numberOfSessions" placeholder="Select">
            <mat-option *ngFor="let number of getNumbers()" value="{{number}}" (click)="selectedNumberOfSession(number)">{{number}}</mat-option>
        </mat-select>
    </div> -->

    <!-- Multiple session booking view -->
    <div class="multipleSessionContainer" *ngIf="isMultipleSession">
        <p>Number of sessions you want to take per week</p>
        <span class="sessionPerWeekContainer">
            <button class="sessionPerWeekBtn" (click)="onSessionPerWeekDec()">-</button>
            <h3 style="margin: 0px 15px; display: inherit;">{{sessionPerWeek}}</h3>
            <button class="sessionPerWeekBtn" (click)="onSessionPerWeekInc()">+</button>
        </span>
    </div>

    <!-- Single session booking view -->
    <div class="singleSessionContainer" *ngIf="isSessionTypeSelected">
        <p>Select your preferred date and slot:</p>
        <p style="font-size: 14px; font-weight: 400; font-style: italic;">Please Note - slots within the next 24 hours
            cannot be booked on short notice.</p>
        <mat-calendar #calendar [selected]="chosenDate" (selectedChange)="onDateSelected($event)" class="dateSelector"
            [dateFilter]="dateFilter" [dateClass] color="primary"></mat-calendar>

        <p *ngIf="isLessThanSlotRange">Slots are available after 24 hours from now on.</p>

        <!-- <button mat-raised-button class="getSlotBtn" *ngIf="getSlotsEnabled" (click)="getFreeSlots()">View Available slots</button> -->

        <div class="slotList" *ngIf="showSlots">
            <p *ngIf="sessionService.openSlotsAfter24Hours.length; else notInRange">Available time slots on
                {{chosenDate | date:'EEEE, dd MMM'}} of {{selectedExpert.fullName}}</p>
            <div class="slotsContainer">
                <button id="slot" *ngFor="let openSlot of sessionService.openSlotsAfter24Hours"
                    (click)="onSlotSelected(openSlot.value)" style="cursor: pointer;">{{openSlot.label}}</button>
            </div>
        </div>
        <ng-template #notInRange>
            <div class="noSlotsAvailable">
                <h2 style="margin-bottom: 10px !important;" class="messageHeading">No Available Slots<br>Please select
                    another date.</h2>
                <div style="color: #A5A5A5;">All of your expert's slots for this date have been booked OR your expert is
                    on leave.</div>
            </div>
        </ng-template>
    </div>
    <div id="submitBtnContainer">
        <!-- <button mat-button id="sessionCancelBtn" routerLink="/parent/dashboard">
            CANCEL
        </button> -->
        <button mat-button id="sessionRequestBtn" *ngIf="showBookSession" (click)="saveSession()"
            [disabled]="disableBookButton">Book Session</button>
        <!-- <button mat-button id="sessionRequestBtn" disabled (click)=saveSession()>BOOK SESSION</button> -->
    </div>
</div>