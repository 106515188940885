<div class="container">
  <!-- <mat-card id="containerCard">
    <div class="heading">
      <a matButton [routerLink]="'/'+currentUser?.role+'/dashboard'" id="back-button">
        <mat-icon>arrow_back</mat-icon>
      </a>
      <h1>{{ selectedExpert?.name ? selectedExpert?.name : selectedExpert?.fullName }}</h1>
    </div>
  </mat-card> -->


  <mat-expansion-panel class="mat-elevation-z0" [expanded]="true"
    style="margin-top: 20px !important; background: #FCFCFC !important">
    <mat-expansion-panel-header style="max-height: 45px; display: flex !important; align-items: center !important;">
      <p style="margin-bottom: 0px;"><b>Basic Details</b></p>
    </mat-expansion-panel-header>

    <div style="margin-top: 0px;">
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Name</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.fullName}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Id</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.id}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">DOB</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.dateOfBirth.toDate() | date: 'dd/MM/YYYY'}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Language</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{isArray(selectedExpert?.languages)?(selectedExpert?.languages).toString():selectedExpert?.languages}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Phone Number</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.phoneNumber ? selectedExpert?.phoneNumber : '-'}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Whatsapp Number</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.whatsappNumber ? selectedExpert?.whatsappNumber : '-'}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Email Id</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.email ? selectedExpert?.email : '-'}}
          </span>
        </div>
      </div>
    </div>
  </mat-expansion-panel>


  <mat-expansion-panel class="mat-elevation-z0" [expanded]="true"
    style="margin-top: 20px !important; background: #FCFCFC !important">
    <mat-expansion-panel-header style="max-height: 45px; display: flex !important; align-items: center !important;">
      <!-- <mat-panel-title><b>{{ selectedExpert?.phoneNumber }}</b></mat-panel-title> -->
      <!-- <mat-panel-title><b>{{ selectedExpert?.email }}</b></mat-panel-title> -->
      <!-- <div></div> -->
      <p style="margin-bottom: 0px;"><b>Working hrs</b></p>
    </mat-expansion-panel-header>
    <!--<h3>{{ isArray(selectedExpert?.languages) ? (selectedExpert?.languages).toString() : "-" }}</h3>
      <h3>{{ degrees?.toString() }}</h3>
      <h3>{{ (selectedExpert?.serviceTypes)?.toString() }}</h3>
      <mat-panel-title><b>{{ selectedExpert?.id }}</b></mat-panel-title> -->
    <!-- <button mat-button color="warn" class="right editButton">Edit</button> -->



    <div style="margin-top: 0px;">
      <div class="workingHoursContainer">
        <div *ngFor="let day of workHour" style="display: flex; height: 19px; align-items: center;">
          <div class="profileDetails" style="width: 100%;">
            <span class="labelFields" *ngIf="day.workSlots.length > 0">{{day.weekday | titlecase}}</span>
            <div class="slotDetailsWrapper">
              <span class="detailFields" *ngFor="let slot of day.workSlots">
                {{slot}}
              </span>
            </div>
          </div>
        </div>

        <!-- Upcoming Leaves -->
        <div style="margin-top: 25px;" class="profileDetails">
          <span class="labelFields">Upcoming Leaves</span>
          <div class="upcomingLeavesWrapper">
            <div *ngIf="upComingLeaves?.length == 0">No Upcoming Leaves</div>
            <div style="display: flex; align-items: center;" *ngFor="let leave of upComingLeaves">
              <span class="leaveDates">
                {{ helper.istTime(leave.leaveStartDateTime.toDate()) | date:'dd/MM/YYYY h:mm a'}}
              </span>
              <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
              <span class="leaveDates">
                {{ helper.istTime(leave.leaveEndDateTime.toDate()) | date:'dd/MM/YYYY h:mm a'}}
              </span>

              <button mat-button *ngIf="currentUser?.role == 'program'"
                style="float: right; height: 26px; display: flex; align-items: center; justify-content: center;"
                (click)="cancelLeave(leave.id)">
                <mat-icon color="warn" style="font-size: 20px;">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- Past Leaves -->
        <div style="margin-top: 25px;" class="profileDetails">
          <span class="labelFields">Past Leaves</span>
          <div class="upcomingLeavesWrapper">
            <div *ngIf="pastLeaves?.length == 0">No Past Leaves</div>
            <div style="display: flex; align-items: center;" *ngFor="let leave of pastLeaves">
              <span class="leaveDates">
                {{ helper.istTime(leave.leaveStartDateTime.toDate()) | date:'dd/MM/YYYY h:mm a'}}
              </span>
              <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
              <span class="leaveDates">
                {{ helper.istTime(leave.leaveEndDateTime.toDate()) | date:'dd/MM/YYYY h:mm a'}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" [expanded]="true"
    style="margin-top: 20px !important; background: #FCFCFC !important">
    <mat-expansion-panel-header style="max-height: 45px; display: flex !important; align-items: center !important;">
      <p style="margin-bottom: 0px;"><b>Services</b></p>
    </mat-expansion-panel-header>

    <div style="margin-top: 0px;">
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Age Range</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.startingAgeRange}} - {{selectedExpert?.endingAgeRange}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Service Type</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{isArray(selectedExpert?.serviceTypes)?(selectedExpert?.serviceTypes).toString():selectedExpert?.serviceTypes}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Condition</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{isArray(selectedExpert?.condition)?(selectedExpert?.condition).toString():selectedExpert?.condition}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Experience (yrs)</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{selectedExpert?.yearsOfExperience ? selectedExpert?.yearsOfExperience : '-'}}
          </span>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" [expanded]="true"
    style="margin-top: 20px !important; background: #FCFCFC !important">
    <mat-expansion-panel-header style="max-height: 45px; display: flex !important; align-items: center !important;">
      <p style="margin-bottom: 0px;"><b>Qualifications</b></p>
    </mat-expansion-panel-header>

    <div style="margin-top: 0px;" *ngFor="let qualification of selectedExpert?.qualifications">
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Qualification</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{qualification.degree}}
          </span>
        </div>
      </div>
      <div class="profileDetails" style="width: 100%;">
        <span class="labelFields">Specialisation</span>
        <div class="slotDetailsWrapper">
          <span class="detailFields">
            {{qualification.fieldOfStudy}}
          </span>
        </div>
      </div>
    </div>
  </mat-expansion-panel>






  <!-- <mat-card class="leavesCard">
    <mat-card-header style="height: auto">
      <mat-card-title style="text-align: start">My Leaves :</mat-card-title>
      <div>
				<mat-card-actions align="end">
					<button mat-button color="warn" class="cardActionBtn" (click)="isLeaveFormEnabled = true">Apply for
						leave</button>
				</mat-card-actions>
			</div>
    </mat-card-header>
    <mat-card-content>
      <div class="leaveDetails">
        <div *ngIf="upComingLeaves.length > 0" style="margin-bottom: 8px;">
          <h3 style=" margin-bottom: 4px; color: #4391C1;"> <b>UPCOMING LEAVES</b></h3>
          <div *ngFor="let leave of upComingLeaves">
            <ul style="list-style-type:disc; padding-left:1em;">
              <li>{{ helper.istTime(leave.leaveStartDateTime.toDate()) | date: 'dd MMM YYYY, h:mm a'}}(IST) -
                {{ helper.istTime(leave.leaveEndDateTime.toDate()) | date: 'dd MMM YYYY, h:mm a'}}(IST)
                <span>
                  <button mat-button
                    style="float: right; height: 26px; display: flex; align-items: center; justify-content: center;"
                    (click)="cancelLeave(leave.id)">
                    <mat-icon color="warn" style="font-size: 20px;">delete</mat-icon>
                  </button>
                </span>
              </li>
            </ul>
            <hr style="margin-top: 10px; margin-bottom: 10px;">
          </div>
        </div>
        <div *ngIf="pastLeaves.length > 0">
          <h3 style=" margin-bottom: 4px; margin-top: 10px; color: #4391C1;"> <b>PAST LEAVES</b></h3>
          <div *ngFor="let leave of pastLeaves">
            <ul style="list-style-type: disc; padding-left:1em;">
              <li>{{ helper.istTime(leave.leaveStartDateTime.toDate()) | date: 'dd MMM YYYY, h:mm a'}}(IST) -
                {{ helper.istTime(leave.leaveEndDateTime.toDate()) | date: 'dd MMM YYYY, h:mm a'}}(IST)
              </li>
            </ul>
            <hr style="margin-top: 10px; margin-bottom: 10px;">
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card> -->


  <div class="optionWrapper">
    <div class="optionHolder">
      <span class="sessionContainer">
        <button mat-button class="sessionButton" (click)="onFilterSelected('balance')"
          [ngClass]="{'selectedSessionButton': filter==='balance'}">Balance</button>
        <button mat-button class="sessionButton" (click)="onFilterSelected('students')"
          [ngClass]="{'selectedSessionButton': filter==='students'}">Students</button>
        <button mat-button class="sessionButton" (click)="onFilterSelected('slots')"
          [ngClass]="{'selectedSessionButton': filter==='slots'}">Slots</button>
      </span>
    </div>
  </div>

  <div class="balanceWrapper" *ngIf="filter==='balance'">
    <div class="currentBalance">
      <h1 style="margin: 10px;">Sessions Earned: {{ balance?balance:"" }}</h1>
      <h1 style="margin: 10px;">Consultations Earned: {{ consultationBalance?consultationBalance:"" }}</h1>
      <button mat-button class="addButton right" (click)="openSessionPackDialog()">+ Add</button>
     
    </div>
    <mat-card class="balanceCard">
      <mat-card-content>
        <div class="transactionByDate" *ngFor="let date of sortedDates">
          <div class="transactionDate" style=" margin-bottom: 8px; margin-top: 18px;">
            <h3 style="margin-bottom: 0;">{{ date | date: 'd MMMM y'}}</h3>
          </div>
          <mat-list style="padding-top: 0;">
            <div class="transaction" *ngFor="let transaction of transactionsByDate[date]; let i=index">
              <mat-list-item class="fullTransaction" style="margin: 20px 0px;">
                <div [ngStyle]="{'color': transaction['transactionValue']>0?'#B1CA57':'#F7524B'}"
                  class="transactionVal">
                  <b>
                    {{ transaction['transactionValue']>0?"+":"" }}{{ transaction['transactionValue'] }}
                  </b>
                </div>
                <div class="transactionDetailsWrapper">
                  <div class="transactionType">{{ transaction['reason'] }}</div>
                  <div *ngIf="transaction.sessionId">
                    <span style="color: #F7524B;">Session Id: </span>
                    <span style="color: #7ACFFF;">{{transaction.sessionId}}</span>
                  </div>
                  <div *ngIf="transaction.consultationId">
                    <span style="color: #F7524B;">Consultation Id: </span>
                    <span style="color: #7ACFFF;">{{transaction.consultationId}}</span>
                  </div>
                  <p class="textLimiter">
                    <span *ngIf="transaction.transactionType == 'byAdmin'"
                      style="text-align: start; width: 80%; margin: 0px;">{{transaction.subReason}}</span>
                    <span *ngIf="transaction.transactionType == 'session'"
                      style="text-align: start; width: 80%; margin: 0px;">{{transaction.sessionDetails}}</span>








                    <span style="width: 20%; margin-left: 12px; color: rgb(217, 178, 8);">{{
                      helper.istTime(transaction.createdAt.toDate()) | date:'h:mm
                      a'}}</span>
                  </p>
                </div>
              </mat-list-item>
              <hr *ngIf="transactionsByDate[date].length >i+1" style=" margin-bottom: 20px; margin-top: 20px;">
            </div>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="studentWrapper" *ngIf="filter==='students'">
    <mat-card class="studentsCards" *ngFor="let student of students ">
      <mat-card-title>{{ student.childName }}</mat-card-title>
      <mat-card-subtitle>{{ isArray(student.serviceTypes)?(student.serviceTypes).toString():student.serviceTypes }}
      </mat-card-subtitle>
      <mat-card-content>
        <p>Last Session: {{ (recentSessions[student.id])?.lastSession | date: 'dd MMMM y' }}</p>
        <p>Total Attended: {{ (recentSessions[student.id])?.totalPast }}</p>
        <br>
        <p>Next Session: {{ (recentSessions[student.id])?.nextSession | date: 'dd MMMM y' }}</p>
        <p>Scheduled: {{ (recentSessions[student.id])?.totalScheduled }}</p>
        <br>
        <!-- <p>Next Leave: {{ (leaves[student.educatorId])?.startDate | date: 'dd MMMM y'}} - {{ (leaves[student.educatorId])?.endDate | date: 'dd MMMM y' }}</p> -->
      </mat-card-content>
    </mat-card>
  </div>

  <div class="freeSlotsWrapper" *ngIf="filter==='slots'">
    <div class="slotHeader">
      <p align="center">Select your preferred dates to check expert availability</p>
    </div>
    <div class="datesHolder">
      <mat-form-field class="example-full-width" appearance="legacy" style="margin-right: 20px">
        <mat-label>Start Day</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="onStartDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
      <span>
        <mat-form-field class="example-full-width" appearance="legacy">
          <mat-label>End Day</mat-label>
          <input matInput [matDatepicker]="picker1" (dateChange)="onEndtDateSelected($event)">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker1></mat-datepicker>
        </mat-form-field>
      </span>
    </div>

    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true" *ngIf=!showSlots style="margin-bottom: 50px">
      <mat-expansion-panel-header style="max-height: auto">
        <mat-panel-title><b style="color:#F7524B">Please select a date to view available slots</b></mat-panel-title>
      </mat-expansion-panel-header>
      <p style="color:#A5A5A5">Select from a range of dates to expert availability during the selected range</p>
    </mat-expansion-panel>



    <div class="multiCenter" *ngIf=showSlots>
      <div class="multiDateSlot" *ngFor="let multiOpenSlots of sessionService.openSlotsOfMultidays">
        <div class="multiDates">
          <p>{{multiOpenSlots.date}}</p>
        </div>
        <div class="multiSlotList" *ngIf="showSlots && multiOpenSlots.openSlots.length != 0">
          <!-- <div class="multiSlotsContainer"> -->
          <button id="multiSlot" *ngFor="let openSlot of multiOpenSlots.openSlots"
            style="cursor: pointer;">{{openSlot.label}}</button>
          <!-- </div> -->
        </div>
        <div class="multiSlotList" *ngIf="showSlots && multiOpenSlots.openSlots.length == 0">
          <p id="multiSlot" style="color:#F7524B; text-align: center; font-weight: 600;">No Available Slots</p>
        </div>
        <hr>
      </div>

    </div>
    <!-- <div class="center" *ngIf="showSlots && sessionService.openSlotsOfMultidays.length == 0">
      <p>No Slots available</p>
    </div> -->






    <!-- <div class="calendarWrapper">
      <mat-calendar #calendar [selected]="chosenDate" (selectedChange)="onDateSelected($event)" class="dateSelector"
        [dateFilter]="dateFilter" [dateClass] color="primary"></mat-calendar>
    </div>
    start_comment <div class="buttonWrapper">
      <button mat-button color="warn" class="editButton" id="freeSlotBtn" style="margin: auto" *ngIf="dateSelected"
        (click)="checkSlots()">Check Free Slots</button>
    </div> end_comment
    <div class="center">
      <div class="slotList" *ngIf="showSlots && sessionService.openSlots.length != 0">
        start_comment <p *ngIf="sessionService.openSlotsAfter24Hours.length; else notInRange">Available time slots on
          {{chosenDate | date:'EEEE, dd MMM'}} of {{expertSelected.fullName}}</p>
        <ng-template #notInRange>Slots are available after 24 hours from now on.</ng-template> end_comment
        <div class="slotsContainer">
          <button id="slot" *ngFor="let openSlot of sessionService.openSlots"
            style="cursor: pointer;">{{openSlot.label}}</button>
        </div>
      </div>
    </div>
    <div *ngIf="showSlots && sessionService.openSlots.length == 0" class="center">
      <p>No Slots available</p>
    </div>
  </div> -->


  </div>




  <div class="sessionactivation" *ngIf="selectedExpert?.verified && currentUser?.role == 'program'">

    <div style="display: flex; justify-content: space-around;">
      <span class="status">Session activation status</span>

      <mat-select [(value)]="expertNewStatus" (selectionChange)="onSessionActivationStatusSelected($event.value) "panelClass="dropdownn" placeholder="Select" class="select">
        <mat-option class="emptyy-select" *ngFor="let options of sessionActivationStatus()" [value] = "options">
          {{options}}</mat-option>
      </mat-select>
    </div>

  </div>


  <div class="sessionactivation" *ngIf="!selectedExpert?.verified && currentUser?.role == 'program'">

    <div style="display: flex;">
      <span class="status">Verification status</span>
      <button class="verifybutton"(click)="verifyExpert()">Verify</button>

    </div>

    <div style="display:flex;">
      <span class="choosetoverify">Choose to verify the expert or not </span>

  </div>

  </div>
