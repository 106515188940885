<!-- <div class="sales-admin-dashboard-page-container">
  <mat-tab-group>
    <mat-tab label="Consultations">
      <a
        mat-raised-button
        color="accent"
        routerLink="/sales-admin/newSessionRequest"
        >Book new session</a
      >
    </mat-tab>
  </mat-tab-group>
</div> -->

<div class="sales-admin-dashboard-page-container" >
  <mat-tab-group>
    <mat-tab label="Consultations">
      <div class="optionHolder" >
        <span class="sessionContainer">
          <button mat-button class="sessionButton" (click)="onFilterSelected('past')"
            [ngClass]="{ selectedSessionButton: filter === 'past' }">
            Past
          </button>

          <button mat-button class="sessionButton" (click)="onFilterSelected('upcoming')"
            [ngClass]="{ selectedSessionButton: filter === 'upcoming' }">
            Upcoming
          </button>
        </span>
        <span>
          <a mat-button class="newSessionButton" routerLink="/sales-admin/newSessionRequest">
            <mat-icon class="addSymbol">add</mat-icon>
            New
          </a>
        </span>
      </div>

      <!-- <button (click)="tempFunction()">test it</button> -->

      <h1 style="font-weight: 600; display: flex; justify-content: space-between; width: auto;">
        <span>
          Sort by:
        </span> 
        <span>
          <button (click)="consultationDataDownload()" style="border: none; background: none; cursor: pointer;">
            <mat-icon style="color: #F7524B; font-size: 30px;">
              download
            </mat-icon>
          </button>
        </span>
      </h1>
      <div class="sortHolder">
        <mat-form-field style="margin-right: 10px">
          <mat-label>Educator</mat-label>
          <mat-select disableOptionCentering panelClass="dropdown" [(value)]="expertSelected"
            (selectionChange)="filterConsultations(expertSelected, salesPocSelected, filter,false)">
            <mat-option>-</mat-option>
            <mat-option [value]="expert.id" *ngFor="let expert of expertList">{{expert.fullName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Sales POC</mat-label>
          <mat-select disableOptionCentering panelClass="dropdown dropdown2" [(value)]="salesPocSelected"
            (selectionChange)="filterConsultations(expertSelected, salesPocSelected, filter,false)">
            <mat-option>-</mat-option>
            <mat-option [value]="salesPoc.id" *ngFor="let salesPoc of salesPocList">{{ salesPoc.fullName }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="dateFilter">
          <div class="filterText">
            <p style="font-family: poppins;">Filter By Date</p>
          </div>
          <mat-form-field appearance="legacy" style="margin-right: 10px">
            <mat-label>Start Day</mat-label>
            <input [(ngModel)]="chosenStartDate" matInput [matDatepicker]="picker"
              (dateChange)="onStartDateSelected($event)" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="legacy" style="margin-right: 10px">
            <mat-label>End Day</mat-label>
            <input [(ngModel)]="chosenEndDate" matInput [matDatepicker]="picker1"
              (dateChange)="onEndtDateSelected($event)" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <div style="padding: 5px;">
            <button style="font-weight: 400; font-family: poppins; background-color:rgb(250, 238, 224);" mat-button
              color="warn" (click)="resetForm()" class="right actionButtons">Reset Date</button>
          </div>
        </div>
      </div>
      <!-- loading screen starts here -->
      <div *ngIf="isLoading" class="text-center my-4" style="margin-top: 50px;">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div>
            <h2 style="color: black;">Loading Consultations</h2>
            <h3 style="color: #A5A5A5">Give us a second as we load your sessions</h3>
        </div>
      </div>
      <!-- loading screen ends here -->
      <!-- this is for upcoming sessions -->
      <div *ngIf="(consultationList.length === 0) && (!isLoading) && (filter == 'upcoming')" class="text-center my-4"
      style="margin-top: 50px;">
      <h2 style="color: black;">No Consultations</h2>
      <h3 style="color: #A5A5A5">Currently, you have no upcoming consultations.
      </h3>
      </div>

      <!-- this is for past sessions -->
      <div *ngIf="(consultationList.length === 0) && (!isLoading) && (filter == 'past')" class="text-center my-4"
      style="margin-top: 50px;">
      <h2 style="color: black;">No Consultations</h2>
      <h3 style="color: #A5A5A5">Currently, you have no past consultation. <br>Once consultation are past
        their scheduled end-time, they will appear here.
      </h3>
      </div>
      <div *ngIf="(!isLoading)" 
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
      >

      <div *ngFor="let consultation of consultationList; let i = index;">
        <div *ngIf="(dateHeadingArray.indexOf(i) > -1) || (i===0)" style="margin-top: 20px;">
          <h1 style="font-weight: 600; color: #000; font-family: Poppins">
            {{helper.istTime(consultation.sessionDate.toDate()) | date:'MMMM dd, YYYY'}}
          </h1>
        </div>

        <mat-card class="consultationCard">
          <div class="statusTag" [ngClass]="adminService.checkStatus(consultation.status)">
            <p style="margin: 0">{{ consultation.status | uppercase }}</p>
          </div>
          <mat-expansion-panel class="mat-elevation-z0 consultationExpansion">
            <mat-expansion-panel-header class="consultationExpansionHeader" style="height: fit-content;">
              <mat-panel-title style="margin: 5px; display: block;">
                <mat-card class="consultationHeaderCard">
                  <mat-card-header>
                    <mat-card-title>
                      <h1 style="font-weight: 500;">
                        (E){{ consultation.sessionExpertName }} with (P){{ consultation.sessionParentName }}, (C){{
                        consultation.sessionStudentName }}
                      </h1>
                    </mat-card-title>
                    <mat-card-subtitle style="font-weight: 500;">
                      <p style="font-weight: 600;">{{ consultation.serviceType }}</p>
                      <p>{{helper.istTime(consultation.sessionDate.toDate()) | date: "MMMM dd, Y" }}</p>
                      <p>{{ consultation.startTime }} - {{ consultation.endTime }}</p>
                      <p>{{ consultation.meetingLink }}</p>
                      <p><span>Id: </span><span style="color: #F7524B;">{{ consultation.id }}</span></p>
                      <p>Parent Phone - {{ consultation.parentPhoneNumber }}</p>
                      <p>Parent Whatsapp - {{ consultation.parentWhatsappNumber }}</p>
                    </mat-card-subtitle>
                  </mat-card-header>
                </mat-card>
              </mat-panel-title>
              <mat-panel-description style="width: 0 !important; margin: 0 !important;"></mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card class="consultationContentCard">
              <mat-card-subtitle style="font-weight: 500;">
                <p style="margin-bottom: 5px;">Scheduled By:</p>
                <p>{{ consultation.createdByName }}</p>
                <br>
                <mat-label>Sales POC: </mat-label>
                <br>
                <mat-form-field style="position: relative; top: -15px !important;">
                  <mat-select disableOptionCentering panelClass="dropdown"
                    [value]="consultation.salesPocId?consultation.salesPocId:''"
                    (selectionChange)="adminService.assignSalesPoc($event.value, consultation.id,consultation.sessionDate)">
                    <mat-option>-</mat-option>
                    <mat-option [value]="salesPoc.id" *ngFor="let salesPoc of salesPocList">{{ salesPoc.fullName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="consultation.attendedBySalesperson">
                  <p style="margin-bottom: 0;">Joined by salesperson: </p>
                  <p>{{ consultation.attendedBySalesperson }}</p>
                </div>
                <p>Status Details:</p>
                <p>{{ consultation.statusDetails?consultation.statusDetails:'-' }}</p>
              </mat-card-subtitle>
            </mat-card>
          </mat-expansion-panel>
          <mat-card-actions style="height: 40px; padding-top: 0; padding-right: 16px;">
            <button mat-button color="warn" class="right rescheduleOrCancel" (click)="reschedule(consultation)"
              *ngIf="rescheduleBtnVisibility(consultation) && filter != 'past'">Reschedule</button>
            <button mat-button color="warn" class="right rescheduleOrCancel" (click)="cancel(consultation)"
              *ngIf="cancelBtnVisbility(consultation) && filter!='past'">Cancel</button>
            <!-- <div *ngIf="consultation.status==='Scheduled' && filter!='past' && !joinAndNoShowButtonVisibility(consultation) && !attendedAndNoShowButtonVisibility(consultation)">
              <button mat-button color="warn" class="right rescheduleOrCancel" (click)="reschedule(consultation)">Reschedule</button>
              <button mat-button color="warn" class="right rescheduleOrCancel" (click)="cancel(consultation)">Cancel</button>
            </div> -->
            <div *ngIf="filter!='past'">
              <button mat-button color="warn" class="right joinBtn" *ngIf="joinBtnVisibility(consultation)"
                (click)="join(consultation)">Join</button>
              <!-- <button mat-button color="warn" class="right rescheduleOrCancel" (click)="noShow(consultation)">Report No-Show</button> -->
              <button mat-button color="warn" class="right joinBtn" *ngIf="rejoinBtnVisibility(consultation)"
                (click)="rejoin(consultation)">Rejoin</button>
              <button mat-button color="warn" class="right"
                *ngIf="consultation.status === 'Scheduled' || consultation.status === 'Ongoing' || consultation.status === 'Started'"
                (click)="copyLink(consultation)">Copy Link</button>
            </div>
            <div *ngIf="attendedButtonVisibility(consultation)">
              <button mat-button color="warn" class="right rescheduleOrCancel" (click)="attend(consultation)">
                Attended
              </button>
            </div>

            <div *ngIf="noShowButtonVisibility(consultation)">
              <button mat-button color="warn" class="right rescheduleOrCancel" (click)="noShow(consultation)">
                Report No-Show
              </button>
            </div>

          </mat-card-actions>
        </mat-card>
      </div>
      <div class="card-footer pb-0 pt-3">
        <!-- <app-jw-pagination-component [items]="consultationList" (changePage)="onChangePage($event)"></app-jw-pagination-component> -->
        <!-- <jw-pagination [items]="consultationList" (changePage)="onChangePage($event)"></jw-pagination> -->
      </div>
    </div>
    </mat-tab>
    <mat-tab label="Parents">
      <app-admin-parents-tab></app-admin-parents-tab>
    </mat-tab>
    <mat-tab label="Educators">
      <app-admin-expert-tab></app-admin-expert-tab>
    </mat-tab>
  </mat-tab-group>
</div>