<mat-card class="cancelCard">
    <mat-icon class="right" style="position: relative; top: -12px; left: 12px; cursor: pointer;" matDialogClose>close</mat-icon>
    <mat-card-title>
        Cancel Session
    </mat-card-title>
    <!-- <mat-card-title *ngIf="data.session.status === 'Session Request'">
        Are you sure you want to cancel this request ?
    </mat-card-title> -->

    <mat-card-subtitle style="color: #59AADD;" class="penaltyWarning">{{message}}</mat-card-subtitle>
    <!-- <mat-card-subtitle style="color: #59AADD;" *ngIf="data.session.status === 'Session Request'">
        <p style="margin-top: 10px;">Cancelling this request will make this slot available for other bookings.</p>
    </mat-card-subtitle> -->

    <mat-card-content *ngIf="data.session.status !== 'Session Request'">
        <a href="https://mykinderpass.com/terms-and-conditions" target="_blank" style="color: gray;"
            *ngIf="data.cancellingPerson == 'Parent'">View our cancellation and rescheduling policy.</a>
        <a href="/assets/Tactopus Leave, Rescheduling & Cancellations Policy.pdf" target="_blank" style="color: gray;"
            *ngIf="data.cancellingPerson == 'Expert'">View our cancellation and rescheduling policy.</a>
    </mat-card-content>

    <form [formGroup]="reasonForm">
        <div class="formFieldHeading">Reason for cancellation*:</div>
        <mat-form-field class="full-width-field" appearance="outline">
            <mat-select disableOptionCentering formControlName="cancellationReason" panelClass="dropdown" placeholder="Select a reason">
                <mat-option *ngFor="let cancelReason of getCancelReasons()" value="{{ cancelReason }}">
                    {{ cancelReason }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="formFieldHeading">Additional Details (Optional)</div>
        <mat-form-field class="full-width-field" floatLabel="never">
            <input matInput formControlName="additionalDetails" placeholder="Enter Here" autocomplete="off">
        </mat-form-field>
    </form>

    <mat-card-actions>
        <button [disabled]="reasonForm.invalid" class="right actionButtons2" mat-button color="warn" (click)="cancelSession()">Confirm</button>
        <button class="right actionButtons" mat-button mat-dialog-close color="warn">Back</button>
    </mat-card-actions>
</mat-card>


