import { Component, Inject, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeedbackPageComponent } from '../feedback-page/feedback-page.component';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-feedback-completed',
  templateUrl: './feedback-completed.component.html',
  styleUrls: ['./feedback-completed.component.css']
})
export class FeedbackCompletedComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<any>();
  constructor(
    public dialogRef:MatDialogRef<FeedbackPageComponent>,
    private router: Router, 
    private route: ActivatedRoute
    ) { }

    expertId: string;
  parentId: string;
  ngOnInit(): void {
    this.expertId = this.route.snapshot.params['expertId'],
      this.parentId = this.route.snapshot.params['parentId']
  }

}
