import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-no-show-confirmation-dialog',
  templateUrl: './no-show-confirmation-dialog.component.html',
  styleUrls: ['./no-show-confirmation-dialog.component.css']
})
export class NoShowConfirmationDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NoShowConfirmationDialogComponent>, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  partySelected(party: string){
    if(party === 'expert')
    {
      this.dialogRef.close({ noShowBy: 'expert', dialogType: "noShow-Expert" });
    } else if(party === 'parent') {
      this.dialogRef.addPanelClass('hidden');
      const pt2 = this.dialog.open(NoShowConfirmationDialogParentComponent, {
        disableClose: false,
        panelClass: "pt2"
      });

      pt2.afterClosed().subscribe(
        expertWaited => {
          if(expertWaited != undefined || expertWaited != null) {
            if(expertWaited)
            this.dialogRef.close({ noShowBy: 'parent', dialogType: 'noShow-Parent(expert paid)' });
          else
            this.dialogRef.close({ noShowBy: 'parent', dialogType: 'noShow-Parent(expert not paid)' });
          } else {
            this.dialogRef.close();
          }
        }
      );
    }
  }

}

@Component({
  selector: 'app-no-show-confirmation-dialog-parent',
  template: `
  <mat-icon style="position: relative; top: -12px; left: 12px; cursor: pointer;" class="right" matDialogClose>close</mat-icon>
  <h1>Did the expert wait for the parent for more than 5 minutes?</h1>
  <div style="display: flex; justify-content: center;">
  <div style="display: inline-flex; justify-content: space-evenly;">
    <button mat-button (click)="close(true)">Yes</button>
    <button mat-button (click)="close(false)">No</button>
  </div>
</div>
  `,
  styleUrls: ['./no-show-confirmation-dialog.component.css']
})

export class NoShowConfirmationDialogParentComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NoShowConfirmationDialogParentComponent>) {}

  ngOnInit() {}

  close(expertWaited: boolean) {
    this.dialogRef.close(expertWaited);
  }

}
