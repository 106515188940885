import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit {
	goals:any[];
	student:string;
	curentUserId:string;

	@Input()
	set id(value){
		this.student = value;
	}

  constructor(    
  	public firestore: AngularFirestore,
    public auth:AngularFireAuth,
    private snackBar:MatSnackBar    
  ) { }

  ngOnInit(): void {
  	this.getGoals();
  }

  async getGoals(){
    var currentUser = await this.auth.currentUser;
    if(currentUser){
    	this.curentUserId = currentUser.uid;
	  	var parent = currentUser.uid;        
	    var docRef = this.firestore.collection("goals", ref => ref.where('parent', '==', parent))
	    docRef.get().subscribe(this.onGoalsResult);
	  }
  }

  onGoalsResult = (results) => {
    if(results){
      this.goals = [];
      results.forEach(result=>{
        this.goals.push(result.data())
      })      
    }
  }

  isExpert(goal){
  	if(this.curentUserId == goal.expert){
  		return true;
  	}
  	return false;
  }

}
