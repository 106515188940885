<mat-card class="box-shadow-none background-color-whitesmoke">
  <mat-card-content>		    
  	<span class="left" *ngIf="!goals">
  		No goals have been set by any expert.
  	</span>
  	<mat-card *ngFor="let goal of goals">
  		<mat-card-header>
  			<mat-card-title>
	  				<span>{{goal.description}}</span>
	  		</mat-card-title>					  		
	  		<mat-card-subtitle>
	  			<span class="left">Set By : {{goal.expertName}}</span>
	  		</mat-card-subtitle>
  		</mat-card-header>
  		<mat-card-content class="card-content">	
	  		<div class="full-width">
	  		</div>
	  	</mat-card-content>
	  	<mat-card-actions align="end" *ngIf="isExpert(goal)">
	  		<button mat-button class="right mat-small" color="warn">Update</button>
	  	</mat-card-actions>
	  </mat-card>
  </mat-card-content>
<!-- 				  <mat-card-actions align="end">
    <button mat-button color="warn" (click)="addSession()">Add Session</button>
  </mat-card-actions>
-->				
</mat-card>
