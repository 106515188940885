import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { InteraktUserService } from '../services/interakt-user.service';

@Component({
  selector: 'app-support-admin-register',
  templateUrl: './support-admin-register.component.html',
  styleUrls: ['./support-admin-register.component.css']
})
export class SupportAdminRegisterComponent implements OnInit {
  phoneNumber: string;
  phoneNumberCountryCode = '+91';
  whatsappNumberCountryCode = '+91';
  user: any;
  constructor(private auth: AngularFireAuth, private newInteraktUserService: InteraktUserService, private firestore: AngularFirestore, private router: Router) { }

  async ngOnInit() {
    this.user = await this.auth.currentUser;
  }

  onPhoneNumberCountryChange(event) {
    this.phoneNumberCountryCode = '';
    this.phoneNumberCountryCode = '+' + event.dialCode;
  }

  onWhatsappNumberCountryChange(event) {
    this.whatsappNumberCountryCode = '';
    this.whatsappNumberCountryCode = '+' + event.dialCode;
  }

  onSave(name: string, email: string, phoneNo: string, whatsappNo: string) {
    const docRef = this.firestore.doc(`users/${this.user.uid}`);
    docRef.get().subscribe(async (result) => {
      let admin:any = result.data();
      if(!admin.verified){
        // Creating new interakt user account for support admin.
        await this.newInteraktUserService.createSupportAdminAccount(admin);
      }

      docRef.update({
        name: name,
        fullName: name,
        email: email,
        phoneNumber: phoneNo,
        phoneNumberCountryCode: this.phoneNumberCountryCode,
        whatsappNumber: whatsappNo,
        whatsappNumberCountryCode: this.whatsappNumberCountryCode,
        registered: true,
        verified: true
      }).then(()=>{
        this.router.navigateByUrl('support/dashboard');
      }).catch(() => { console.error("User doesn't exist"); });
    }) 
  }
}
