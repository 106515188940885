import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { Observable, Subject } from "rxjs";
import { WhereFilterOp, OrderByDirection } from '@firebase/firestore-types';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { HelperService } from "../helper.service";
import moment from 'moment';


@Injectable({ providedIn: 'root' })
export class AdminService {
  navigateToInvite = new Subject();
  currentUser: AngularFirestoreDocument;
  adminDoc: any;
  currentUserId: string;

  constructor(private firestore: AngularFirestore, private auth: AngularFireAuth, private router: Router, private helper: HelperService) { }

  // function to get current admin details.
  async getAdminDetails() {
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('support/signin');
      return;
    }
    this.currentUserId = currentUser.uid;

    let ref = this.firestore.collection('users').doc(this.currentUserId)
    ref.get().subscribe(result => {
      if (result.exists) {
        let admin: any = result.data()
        this.adminDoc = admin;
      }
    })
  }

  // getting only verified experts
  get ExpertList(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('role', '==', 'expert').where('verified', '==', true)).valueChanges();
  }

  // getting all experts
  get AllExperts(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('role', '==', 'expert')).valueChanges();
  }

  // getting verified parents
  get ParentList(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('verified', '==', true)).valueChanges();
  }

  // get renewalList(): Observable<any> {
  //   return this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('verified', '==', true)).valueChanges();
  // }

  // getting sales-admin list
  get SalesPocList(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('role', '==', 'sales-admin').where('verified', '==', true)).valueChanges();
  }

  getFilteredStudentList(id: string): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('myExpertsIds', 'array-contains', id)).valueChanges();
  }

  // to get a single session
  getSession(sessionId: string): Observable<any> {
    return this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionId)).get();
  }
  getStudentLastSession(id:string):Observable<any> {
    return this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId', '==', id).where('status','in',['Attended', 'Partially Attended']).orderBy('sessionEndDateTime', 'desc').limit(1)).valueChanges();
    
  }

  getFilteredInvitations(balance: string, invitedOn: Date): Observable<any> {
    let balanceValidity = !(balance === "" || balance === undefined);
    let invitedOnValidity = !(invitedOn === null || invitedOn === undefined);

    let invitedOnTimeStamp: firebase.firestore.Timestamp;
    let invitedDate: Date;
    let invitedOnPlus24Hrs: Date;
    let invitedOnPlus24HrsTimeStamp: firebase.firestore.Timestamp;

    if (invitedOnValidity) {
      invitedDate = new Date(invitedOn.getTime());
      invitedDate.setHours(0, 0, 0);
      invitedOnPlus24Hrs = new Date(invitedOn.getTime() + 86400000);
      invitedOnTimeStamp = firebase.firestore.Timestamp.fromDate(invitedDate);
      invitedOnPlus24HrsTimeStamp = firebase.firestore.Timestamp.fromDate(invitedOnPlus24Hrs);
    }
    let filteredInvitationCollection: AngularFirestoreCollection;
    if (!balanceValidity && invitedOnValidity) {
      filteredInvitationCollection = this.firestore.collection('invitations', ref => { return ref.where('registered', '==', false).where('createdOn', '>', invitedOnTimeStamp).where('createdOn', '<', invitedOnPlus24HrsTimeStamp) });
    } else if (balanceValidity && !invitedOnValidity) {
      filteredInvitationCollection = this.firestore.collection('invitations', ref => ref.where('registered', '==', false).where('numberOfSessions', '==', parseInt(balance)));
    } else if (!balanceValidity && !invitedOnValidity) {
      filteredInvitationCollection = this.firestore.collection('invitations', ref => ref.where('registered', '==', false));
    } else {
      filteredInvitationCollection = this.firestore.collection('invitations', ref => ref.where('registered', '==', false).where('numberOfSessions', '==', parseInt(balance)).where('createdOn', '>', invitedOnTimeStamp).where('createdOn', '<', invitedOnPlus24HrsTimeStamp));
    }

    return filteredInvitationCollection.valueChanges();
  }

  getFilteredParentList(balance: string, educatorId: string): Observable<any> {
    let balanceValidity = !(balance === "" || balance === undefined);
    let educatorIdValidity = !(educatorId === undefined || educatorId === "");

    let filteredParentCollection: AngularFirestoreCollection;
    if (!balanceValidity && educatorIdValidity) {
      filteredParentCollection = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('activityStatus', '==', "Active").where('myExpertsIds', 'array-contains', educatorId));
    } else if (balanceValidity && !educatorIdValidity) {
      filteredParentCollection = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('activityStatus', '==', "Active").where('numberOfSessions', '==', balance));
    } else if (!balanceValidity && !educatorIdValidity) {
      filteredParentCollection = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('activityStatus', '==', "Active"));
    } else {
      filteredParentCollection = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('activityStatus', '==', "Active").where('myExpertsIds', 'array-contains', educatorId).where('numberOfSessions', '==', balance));
    }

    return filteredParentCollection.valueChanges();
  }


  getInActiveParentList(balance: string, invitedOn: Date): Observable<any> {
    let balanceValidity = !(balance === "" || balance === undefined);
    let invitedOnValidity = !(invitedOn === null || invitedOn === undefined);

    let invitedOnTimeStamp: firebase.firestore.Timestamp;
    let invitedDate: Date;
    let invitedOnPlus24Hrs: Date;
    let invitedOnPlus24HrsTimeStamp: firebase.firestore.Timestamp;

    if (invitedOnValidity) {
      invitedDate = new Date(invitedOn.getTime());
      invitedDate.setHours(0, 0, 0);
      invitedOnPlus24Hrs = new Date(invitedOn.getTime() + 86400000);
      invitedOnTimeStamp = firebase.firestore.Timestamp.fromDate(invitedDate);
      invitedOnPlus24HrsTimeStamp = firebase.firestore.Timestamp.fromDate(invitedOnPlus24Hrs);
    }
    let filteredInActiveCollection: AngularFirestoreCollection;
    if (!balanceValidity && invitedOnValidity) {
      filteredInActiveCollection = this.firestore.collection('users', ref => { return ref.where('role', '==', 'parent').where('activityStatus', '==', 'Inactive').where('createdOn', '>', invitedOnTimeStamp).where('createdOn', '<', invitedOnPlus24HrsTimeStamp) });
    } else if (balanceValidity && !invitedOnValidity) {
      filteredInActiveCollection = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('activityStatus', '==', 'Inactive').where('numberOfSessions', '==', parseInt(balance)));
    } else if (!balanceValidity && !invitedOnValidity) {
      filteredInActiveCollection = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('activityStatus', '==', 'Inactive'));
    } else {
      filteredInActiveCollection = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').where('activityStatus', '==', 'Inactive').where('numberOfSessions', '==', parseInt(balance)).where('createdOn', '>', invitedOnTimeStamp).where('createdOn', '<', invitedOnPlus24HrsTimeStamp));
    }

    return filteredInActiveCollection.valueChanges();
  }

  // getFilteredExperts(balance: string, serviceType: string[]): Observable<any> {
  //   let balanceValidity = !(balance === "" || balance === undefined);
  //   let serviceTypeValidity = !(serviceType === undefined || serviceType === null || serviceType.length === 0);

  //   let filteredExpertCollection: AngularFirestoreCollection;
  //   if(!balanceValidity && serviceTypeValidity) {

  //   } else if()

  //   return filteredExpertCollection.valueChanges();
  // }

  getExpert(id: string): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('id', '==', id)).valueChanges();
  }

  navigateToInvitePage() {
    this.navigateToInvite.next();
  }

  async isSupport() {
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('support/signin');
      return;
    } else if (currentUser) {
      let id = currentUser.uid;
      let ref = this.firestore.collection('users').doc(id).get().toPromise().then((doc) => {
        if (!doc.exists) {
          this.router.navigateByUrl('support/signin');
          return;
        } else {
          let user: any = doc.data();
          if (user.role !== 'support' && user.role !== 'program' && user.role !== 'sales-admin') {
            this.router.navigateByUrl('support/signin');
            return;
          }
        }
      })
    }
  }

  async isSales() {
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('sales/signin');
      return;
    } else if (currentUser) {
      let id = currentUser.uid;
      let ref = this.firestore.collection('users').doc(id).get().toPromise().then((doc) => {
        if (!doc.exists) {
          this.router.navigateByUrl('sales/signin');
          return;
        } else {
          let user: any = doc.data();
          if (user.role !== 'sales-admin') {
            this.router.navigateByUrl('sales/signin');
            return;
          }
        }
      })
    }
  }

  getTransactions(id: string): Observable<any> {
    return this.firestore.collection('transactions', ref => ref.where('userId', '==', id).orderBy('createdAt', 'desc')).valueChanges();
  }

  leaves(expertId: string): Observable<any> {
    return this.firestore.collection('leaves', ref => ref.where('leaveBy', '==', expertId).orderBy('leaveStartDateTime', 'asc').limit(1)).valueChanges();
  }

  checkStatus(status: string): string {
    if (status === "Scheduled")
      return "scheduled";
    else if (status === "Ongoing")
      return "ongoing";
    else if (status === "Update Status")
      return "updateStatus";
    else if (status === "No-show by Expert" || status === "No-show by Parent")
      return "noShow";
    else if (status === "Attended")
      return "attended";
    else if (status === "Cancelled")
      return "cancelled";
    else if (status === "Reschedule requested")
      return "rescheduleRequested"
  }

  assignSalesPoc(salesPoc: AngularFirestoreDocument, consultationId: string,consultationDate) {
    console.log(salesPoc);
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultationDate.toDate());
    const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultationId);
    // this.firestore.doc(`consultations/${consultationId}`)
    docRef
      .update({ salesPocId: salesPoc })
      .then(() => { console.log("Sales POC Updated") });
  }

  async joinConsultation(consultation: AngularFirestoreDocument) {
    const currentUserName = await firebase.auth().currentUser.displayName;

    const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
    const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);

    // this.firestore.doc(`consultations/${consultation['id']}`)
    docRef
      .update({ attendedBySalesperson: currentUserName })
      .then(() => { console.log('Updated salesperson attendance') });
  }

  // getting all sessions
  // getAllSessions() {
  //   let filterTime = firebase.firestore.Timestamp.now();
  //   return this.firestore.collection('sessions',ref=>ref.where('sessionEndDateTime', ">=", filterTime).orderBy('sessionEndDateTime', "asc").limit(10)).snapshotChanges();
  // }


  // get past 24 hours parent Session
  getAllNoFeedbackParentSessions(){
    let filterTime = new Date();
    let startTime = new Date();
    startTime.setHours(0,0,0,0);
    let filteredSessionCollection: any;

    filteredSessionCollection = this.firestore.collectionGroup('sessions', ref => ref.where('sessionDate','>=', startTime).where('sessionDate','<=', filterTime));
    return filteredSessionCollection.valueChanges();


  }

  // getting sessions according to the applied filters
  getFilteredSessionList(expertId: string, studentId: string, filter: string): Observable<any> {
    let currentDate = new Date();
    let now = this.helper.istTime(currentDate);
    let filterTime = firebase.firestore.Timestamp.fromDate(now)
    // let filterTime = firebase.firestore.Timestamp.now();

    let expertValidity = !(expertId === "" || expertId == undefined);
    let studentValidity = !(studentId === "" || studentId == undefined);

    let filteredSessionCollection;
    let comparison: WhereFilterOp;

    if (filter === 'past')
      comparison = '<';
    else if (filter === 'upcoming')
      comparison = '>=';

    if (!expertValidity && studentValidity) {
      filteredSessionCollection = this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId', '==', studentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', 'asc'));
    }
    else if (expertValidity && !studentValidity) {
      filteredSessionCollection = this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', 'asc'));
    }
    else if (!expertValidity && !studentValidity) {
      filteredSessionCollection = this.firestore.collectionGroup('sessions', ref => ref.where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', 'asc'));
    }
    else {
      filteredSessionCollection = this.firestore.collectionGroup('sessions',
        ref => ref.where('sessionExpertId', '==', expertId).where('sessionStudentId', '==', studentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', 'asc'));
    }

    return filteredSessionCollection.valueChanges();
  }

  // getting all consultations
  getAllConsultations() {
    let filterTime = firebase.firestore.Timestamp.now();
    return this.firestore.collectionGroup('consultations',ref=>ref.where('sessionEndDateTime', ">=", filterTime).orderBy('sessionEndDateTime', "asc").limit(10)).snapshotChanges();
  }

  // getting consultations according to the applied filters
  getFilteredConsultations(expertId: string, salesPocId: string, chosenStartDate: Date, chosenEndDate: Date, filter: string,filterTime,isPagination,lastVisible): Observable<any> {

    let startDate = chosenStartDate;
    let endDate;
    if(chosenEndDate){
      endDate=moment(chosenEndDate).add({h:23,m:59,s:59}).toDate();
    }
    
    let chosenStartDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDate));
    let chosenEndDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDate));
    console.log(startDate,chosenStartDateTimestamp);
    console.log(endDate,chosenEndDateTimestamp,"chosenEndDateTimestamp");
    let comparison: WhereFilterOp;
    let order: any;

    let expertValidity = !(expertId === "" || expertId == undefined);
    let salesPocValidity = !(salesPocId === "" || salesPocId == undefined);

    let dateValidity = !(startDate == undefined || startDate == null || endDate == undefined || endDate == null);

    let filteredConsultationCollection;

    if (filter === 'past') {
      comparison = '<';
      order = 'desc';
    } else if (filter === 'upcoming') {
      comparison = '>=';
      order = 'asc'
    }
    if(isPagination){ 
      try{
      if (!expertValidity && salesPocValidity && !dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && !salesPocValidity && !dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && !salesPocValidity && !dateValidity) {  
          filteredConsultationCollection = this.firestore.collectionGroup('consultations',ref=>ref.where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && salesPocValidity && !dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionExpertId', '==', expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && salesPocValidity && dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && !salesPocValidity && dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', expertId).where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && !salesPocValidity && dateValidity) {
        console.log("Checking Before Date Filter in Sales");
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
        console.log("Checking After Date Filter in Sales");
      }else {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionExpertId', '==', expertId).where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      }
    }catch (err) {
      console.log(err);
   }
    }else{
      if (!expertValidity && salesPocValidity && !dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && !salesPocValidity && !dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && !salesPocValidity && !dateValidity) {  
          filteredConsultationCollection = this.firestore.collectionGroup('consultations',ref=>ref.where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && salesPocValidity && !dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionExpertId', '==', expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && salesPocValidity && dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && !salesPocValidity && dateValidity) {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', expertId).where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && !salesPocValidity && dateValidity) {
        console.log("Checking Before Date Filter in Sales");
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
        console.log("Checking After Date Filter in Sales");
      }else {
        filteredConsultationCollection = this.firestore.collectionGroup('consultations', ref => ref.where('salesPocId', '==', salesPocId).where('sessionExpertId', '==', expertId).where('sessionEndDateTime', '>=', chosenStartDateTimestamp).where('sessionEndDateTime', '<=', chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      }
    }
    
    return filteredConsultationCollection.snapshotChanges();
  }

  filterSessionListPagination(expertId: string = undefined, parentId: string = undefined, sortStatus: string = undefined, chosenStartDate: Date, chosenEndDate: Date,filter,isPagination,lastVisible) {
    let startDate = chosenStartDate;
    let endDate;
    if(chosenEndDate){
      endDate=moment(chosenEndDate).add({h:23,m:59,s:59}).toDate();
    }

    let chosenStartDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDate));
    let chosenEndDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDate));

    let comparison: WhereFilterOp;
    let order: any;

    let filterTime = firebase.firestore.Timestamp.now();
    let expertValidity = !(expertId === "" || expertId == undefined || expertId === null);
    let parentValidity = !(parentId === "" || parentId == undefined || parentId === null);
    let statusValidity = !(sortStatus === "" || sortStatus == undefined || sortStatus == null);

    let dateValidity = !(startDate == undefined || startDate == null || endDate == undefined || endDate == null);
    
    let filteredSessionCollection;
   
    if (filter === 'past') {
      comparison = '<';
      order = 'desc';
    } else if (filter === 'upcoming') {
      comparison = '>=';
      order = 'asc'
    }
   
    if(isPagination && lastVisible){
        if (!expertValidity && parentValidity && !statusValidity && !dateValidity) {
            filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (!expertValidity && parentValidity && statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (expertValidity && !parentValidity && !statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (expertValidity && !parentValidity && statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (!expertValidity && !parentValidity && statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (!expertValidity && !parentValidity && !statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (expertValidity && parentValidity && !statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (expertValidity && parentValidity && statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (!expertValidity && parentValidity && !statusValidity && dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (!expertValidity && parentValidity && statusValidity && dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (expertValidity && !parentValidity && !statusValidity && dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (expertValidity && !parentValidity && statusValidity && dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (!expertValidity && !parentValidity && statusValidity && dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (!expertValidity && !parentValidity && !statusValidity && dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        } else if (expertValidity && parentValidity && !statusValidity && dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        }
        else {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(20));
        }
    }else{
      if (!expertValidity && parentValidity && !statusValidity && !dateValidity) {
          filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (!expertValidity && parentValidity && statusValidity && !dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (expertValidity && !parentValidity && !statusValidity && !dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (expertValidity && !parentValidity && statusValidity && !dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (!expertValidity && !parentValidity && statusValidity && !dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (!expertValidity && !parentValidity && !statusValidity && !dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (expertValidity && parentValidity && !statusValidity && !dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (expertValidity && parentValidity && statusValidity && !dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (!expertValidity && parentValidity && !statusValidity && dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (!expertValidity && parentValidity && statusValidity && dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (expertValidity && !parentValidity && !statusValidity && dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (expertValidity && !parentValidity && statusValidity && dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (!expertValidity && !parentValidity && statusValidity && dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (!expertValidity && !parentValidity && !statusValidity && dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      } else if (expertValidity && parentValidity && !statusValidity && dateValidity) {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      }
      else {
        filteredSessionCollection= this.firestore.collectionGroup('sessions',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(20));
      }
    }
    return filteredSessionCollection.snapshotChanges();
  }
  filterConsulatationListPagination(expertId: string = undefined, parentId: string = undefined, sortStatus: string = undefined, chosenStartDate: Date, chosenEndDate: Date,filter,isPagination,lastVisible) {

    let startDate = chosenStartDate;
    let endDate;
    if(chosenEndDate){
      endDate=moment(chosenEndDate).add({h:23,m:59,s:59}).toDate();
    }
  
    let chosenStartDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDate));
    let chosenEndDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDate));

    let comparison: WhereFilterOp;
    let order: any;

    let filterTime = firebase.firestore.Timestamp.now();
    let expertValidity = !(expertId === "" || expertId == undefined || expertId === null);
    let parentValidity = !(parentId === "" || parentId == undefined || parentId === null);
    let statusValidity = !(sortStatus === "" || sortStatus == undefined || sortStatus == null);

    let dateValidity = !(startDate == undefined || startDate == null || endDate == undefined || endDate == null);
    
    let filteredConsultationCollection;
   
    if (filter === 'past') {
      comparison = '<';
      order = 'desc';
    } else if (filter === 'upcoming') {
      comparison = '>=';
      order = 'asc'
    }
    if(isPagination && lastVisible){
      if (!expertValidity && parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && !parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && !parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && !parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && !parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && parentValidity && statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && !parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && !parentValidity && statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && !parentValidity && statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (!expertValidity && !parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      } else if (expertValidity && parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      }
      else {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).startAfter(lastVisible).limit(10));
      }
    }else{
      if (!expertValidity && parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && !parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && !parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && !parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && !parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && parentValidity && !statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && parentValidity && statusValidity && !dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("sessionStudentId","==",parentId).where("status","==",sortStatus).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && parentValidity && statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && !parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && !parentValidity && statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && !parentValidity && statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (!expertValidity && !parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      } else if (expertValidity && parentValidity && !statusValidity && dateValidity) {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      }
      else {
        filteredConsultationCollection= this.firestore.collectionGroup('consultations',ref=>ref.where("sessionStudentId","==",parentId).where("sessionExpertId","==",expertId).where("status","==",sortStatus).where("sessionEndDateTime",">=",chosenStartDateTimestamp).where("sessionEndDateTime","<=",chosenEndDateTimestamp).where('sessionEndDateTime', comparison, filterTime).orderBy('sessionEndDateTime', order).limit(10));
      }
    }
    return filteredConsultationCollection.snapshotChanges();
  }
}
