import { Component, OnDestroy, OnInit, ViewEncapsulation,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/app';

import { SessionDialog } from '../expert-dashboard/session.component';
import { MeetingComponent } from '../meeting/meeting.component';
import { ParentsService } from '../services/parents.service';
import { CancelDialog } from '../cancel-session/cancel/cancel.component';
import { RescheduleDialog } from '../reschedule-session/reschedule/reschedule.component';
import { SessionService } from '../services/session.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HelperService } from '../helper.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { AdminService } from '../services/admin.service';
import { ZoomIdUnavailableDialogComponent } from '../zoom-id-unavailable-dialog/zoom-id-unavailable-dialog.component';
import { PopUpsDisableDialogComponent } from '../pop-ups-disable-dialog/pop-ups-disable-dialog.component';
import { SingleSlotAvailabilityService } from '../services/single-slot-availability.service';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { FeedbackCompletedComponent } from '../feedback-completed/feedback-completed.component';
import * as XLSX from 'xlsx';
import moment from 'moment';


@Component({
  selector: 'app-parent-dashboard',
  templateUrl: './parent-dashboard.component.html',
  styleUrls: ['./parent-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ParentDashboardComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  todayDate: any;
  balanceDetails: any;
  currentBalance: any; //balanceDetails copy
  sessions: any[];
  filter: string = "Upcoming";
  dateRange: any;
  id: string;
  toRescheduleSession: any;
  session: any;
  lastSession: any;
  transactions: AngularFirestoreDocument[] = [];
  transactionsByDate = {};
  sortedDates: Date[] = [];
  transactionSub: Subscription;
  reschedulingDetails: {
    reschedulingReason: any,
    additionalReschedulingDetails: any,
    rescheduledOn: any,
    rescheduledBy: any,
    rescheduledByName: any,
    rescheduledWith: any,
    rescheduledWithName: any
  }

  expertList: AngularFirestoreDocument[] = [];
  expertSub: Subscription;
  expertName = '';
  expertSelected = '';
  statusName = '';
  statusSelected = '';
  sessionsCopy: any[];

  expertClicked = false;
  isExpertClicked = false;
  isStatusClicked = false;
  statusClicked = false;
  statusArray: object[];

  dateHeadingArray = new Array();
  isLoading = true;
  showMultipleSessionDetails: boolean = false;
  transactionFilter = "allTransactions";
  validity:any;


  constructor(private router: Router,
    private toastr: ToastrService,
    public auth: AngularFireAuth,
    private notificationService: NotificationService,
    private firestore: AngularFirestore,
    public dialog: MatDialog,
    public parentsService: ParentsService,
    private sessionService: SessionService,
    public helper: HelperService,
    private adminService: AdminService,
    // private singleSlotService: SingleSlotAvailabilityService
  ) { }

  async ngOnInit() {
    this.transactionFilter = "allTransactions";

    this.parentsService.getCurrentUsersBalanceDetails();
    this.todayDate = new Date();
    let start = new Date();
    start.setHours(0, 0, 0, 0);

    let end = new Date();
    end.setHours(23, 59, 59, 999);
    this.dateRange = new FormGroup({
      start: new FormControl(start),
      end: new FormControl(end)
    });
    await this.parentsService.getCurrentUserDetails();
    await this.getSessions();
    await this.checkBalance();

    this.statusArray = this.helper.sessionTypesObject;

    this.transactionSub = this.adminService.getTransactions(this.parentsService?.currentUserId).subscribe(
      (transactionList: AngularFirestoreDocument[]) => { this.transactions = transactionList; this.sortTransactions(this.transactions); }
    );

    this.expertSub = this.adminService.ExpertList.subscribe((result) => {
      let myExpertList = result;
      this.expertList = [];
      this.parentsService.currentUserDetails.myExpertsIds.forEach(element => {
        myExpertList.forEach(expert => {
          if (expert['id'] === element) {
            this.expertList.push(expert);
          }
        });
      });
      this.expertList.sort(function (x, y) {
        return x['fullName'] > y['fullName'] ? 1 : -1;
      });
    });
    this.getValidity();
  }

  async checkBalance() {
    let currentUser = await this.auth.currentUser;
    let id: any;
    if (currentUser) {
      id = currentUser.uid;
    }
    let ref = this.firestore.collection('balances').doc(id)
    ref.get().subscribe(result => {
      let balanceData: any = result.data();
      this.balanceDetails = balanceData;
    })
  }

  getServices() {
    return this.helper.services;
  }
  getValidity(){
    if(this.parentsService.currentUserDetails?.numberOfSessions===24){ 
      this.validity=180;
    }else if(this.parentsService.currentUserDetails?.numberOfSessions===36){
      this.validity=270;
    }else if(this.parentsService.currentUserDetails?.numberOfSessions===48){
      this.validity=360;
    }
  }

  setServiceFilter(service) {
    console.log(service);
  }

  onFilterChanged(event) {
    this.filter = event;
    this.getSessions();
  }

  onDateRangeChange() {
    if (this.dateRange.value.start && this.dateRange.value.end) {
      let start = new Date(this.dateRange.value.start);
      start.setHours(0, 0, 0, 0);

      let end = new Date(this.dateRange.value.end);
      end.setHours(23, 59, 59, 999);

      this.dateRange = new FormGroup({
        start: new FormControl(start),
        end: new FormControl(end)
      });

      this.getSessions();
    }
  }

  back() {
    let diff = Math.abs(this.dateRange.value.end.getTime() - this.dateRange.value.start.getTime());

    let start = new Date(this.dateRange.value.start.getTime() - diff);
    start.setHours(0, 0, 0, 0);

    let end = new Date(this.dateRange.value.start.getTime() - diff);
    end.setHours(23, 59, 59, 999);

    this.dateRange = new FormGroup({
      start: new FormControl(start),
      end: new FormControl(end)
    });

    this.getSessions();
  }

  forward() {
    let diff = Math.abs(this.dateRange.value.end.getTime() - this.dateRange.value.start.getTime());

    let start = new Date(this.dateRange.value.start.getTime() + diff + 1);
    start.setHours(0, 0, 0, 0);

    let end = new Date(this.dateRange.value.start.getTime() + diff + 1);
    end.setHours(23, 59, 59, 999);

    this.dateRange = new FormGroup({
      start: new FormControl(start),
      end: new FormControl(end)
    });

    this.getSessions();
  }

  async getSessions() {

    this.isLoading = true;

    let filterTime = firebase.firestore.Timestamp.now()
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('parent/signin');
      return;
    }

    let bookedBy = currentUser.uid;
    this.id = bookedBy;
    
    if (this.filter == 'Upcoming') {
      let ref = this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId', '==', this.id).where('sessionEndDateTime', '>', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onSessionResults);
    } else if (this.filter == 'Past') {
      let ref = this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId', '==', this.id).where('sessionEndDateTime', '<=', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onSessionResults);
    }

    // let ref = this.firestore.collection('sessions', ref => ref.where('bookedBy', 'array-contains', bookedBy).where('status', '==', this.filter).where('sessionDate', '>=', this.dateRange.value.start)
    // .where('sessionDate', '<=', this.dateRange.value.end).orderBy('sessionDate','asc'));
    // ref.get().subscribe(this.onSessionResults);

  }




  //For hiding the Transaction
  //   showTransaction(transaction:any){

  // let transactionDetails:any=transaction.transaction


  //     if(transactionDetails.transactionType != 'byAdmin'){

  //       return true

  //     }else if(transactionDetails.transactionType == 'byAdmin'  && transactionDetails.hideTransaction == false){

  //        return true
  //     }

  //     return false
  //   }




  onSessionResults = (results) => {
    this.sessions = [];

    if (!results.empty) {
      results.forEach((doc) => {
        this.sessions.push(doc.data());
      });
    }

    //for loading message
    this.isLoading = false;

    // Showing Popup for feedback
    this.lastSession = this.adminService.getStudentLastSession(this.id).subscribe(
      (sessionList: AngularFirestoreDocument[]) => {
        this.lastSession = sessionList[0];
        if(this.lastSession.feedback&&this.lastSession.feedback.parent.feedback==""){
        const dialogRef = this.dialog.open(FeedbackDialogComponent, { panelClass: 'custom-dialog-container' });
      }
      });
    this.getConsultations();
  }

  async getConsultations() {
    let filterTime = firebase.firestore.Timestamp.now()
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('parent/signin');
      return;
    }

    let bookedBy = currentUser.uid;
    this.id = bookedBy;

    if (this.filter == 'Upcoming') {
      let ref = this.firestore.collectionGroup('consultations', ref => ref.where('sessionStudentId', '==', this.id).where('sessionEndDateTime', '>', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onConsultationResults);
    } else if (this.filter == 'Past') {
      let ref = this.firestore.collectionGroup('consultations', ref => ref.where('sessionStudentId', '==', this.id).where('sessionEndDateTime', '<=', filterTime)
        .orderBy('sessionEndDateTime', 'asc'));

      ref.get().subscribe(this.onConsultationResults);
    }
  }

  onConsultationResults = (results) => {
    if (!results.empty) {
      results.forEach((doc) => {
        this.sessions.push(doc.data());
      });
    }

    //for loading message
    this.isLoading = false;

    this.sessions.sort(function (x, y) {
      return x.sessionEndDateTime - y.sessionEndDateTime
    });

    if (this.filter == 'Past' || this.filter == 'past') {
      this.sessions.reverse();
    }

    this.sessionsCopy = this.sessions;
    this.filterCombinedList(this.expertSelected, this.statusSelected);


    //Created for DateHeadings of the sessions
    let arr = new Array();
    let dateHead: Date;
    dateHead = this.sessions[0]?.sessionDate?.toDate();
    for (let i = 0; i < this.sessions.length; i++) {
      if (dateHead < this.sessions[i].sessionDate.toDate()) { //for Upcoming sessions as they are in ascending in order of sessionDate
        arr.push(i);
        dateHead = this.sessions[i].sessionDate.toDate();
      } else if (dateHead > this.sessions[i].sessionDate.toDate()) { //for Past sessions as they are in descending in order of sessionDate
        arr.push(i);
        dateHead = this.sessions[i].sessionDate.toDate();
      }
    }
    this.dateHeadingArray = arr;

    //for loading message
    this.isLoading = false;

  }


  filterCombinedList(expertId: string = undefined, sortStatus: string = undefined) {

    let expertValidity = !(expertId === "" || expertId == undefined || expertId === null);
    let statusValidity = !(sortStatus === "" || sortStatus == undefined || sortStatus == null);
    this.sessions = this.sessionsCopy;

    if (!statusValidity && expertValidity) {
      this.sessions = this.sessions.filter(session => session['sessionExpertId'] === expertId);
    } else if (statusValidity && !expertValidity) {
      this.sessions = this.sessions.filter(session => session['status'] === sortStatus);
    } else if (!statusValidity && !expertValidity) {
    }
    else {
      this.sessions = this.sessions.filter(session => (session['sessionExpertId'] === expertId) && (session['status'] === sortStatus));
    }

  }

  sessionCancelAndRescheduleBtnVisibility(session) {
    if ((session.status == 'Rejected Session Request') || (session.status == 'Cancelled') || (session.status == 'Rescheduled') || (session.status == 'noShow-Expert') || (session.status == 'noShow-Parent') || (session.status == 'Attended') || (session.status == 'Session Request Expired') || (session.status == 'REQUEST CANCELLED')) {
      return false;
    } else {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);

      //Check if now is less than to session start date time
      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      if (now.getTime() < exactSessionDate.getTime()) {
        //session start time hasn't reached
        return true;
      }
    }

    return false;
  }

  // Parent requesting for session
  async requestSession() {
    console.log(this.parentsService.currentUserDetails.validTill);
   
    let filterTime = firebase.firestore.Timestamp.now();
    if (this.balanceDetails?.currentBalance <= 0) {
      const dialogRef = this.dialog.open(InsufficientBalanceDialog, {
        disableClose: true,
        panelClass: 'zoom-id-unavailable-dialog',
      })
    } else if(this.parentsService.currentUserDetails.validTill<filterTime){
        const dialogRef = this.dialog.open(ValidityExpiredDialog, {
          disableClose: true,
          panelClass: 'zoom-id-unavailable-dialog',
          data: { balance: this.parentsService.currentUserDetails.currentBalance,isGracePeriod:this.parentsService.currentUserDetails.gracePeriod>=filterTime },
        }) 
    }else {
      // this.router.navigateByUrl("/parent/newSessionRequest");
      this.router.navigateByUrl("/parent/multiple-session");
    }
  }

  //Prent denied the session request from expert
  onSessionDenied(session) {
    let date = this.helper.istTime2((session.sessionDate.toDate()));
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    let body = {
      'userId': session.expertInteraktId,
      'event': 'new session request denied by parent',
      'traits': {
        'date': dateString,
        'time': session.startTime,
        'parentName': session.createdWithName,
      }
    }
    this.notificationService.sendWhatsappNotification(body);
  }

  //Parent accepting the session requested by experts
  async confirmSession(session) {
    let acceptedAt = firebase.firestore.FieldValue.serverTimestamp();

    const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
    const docRef =  this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
   
    let ref = await docRef.update({ status: 'Scheduled', acceptedAt: acceptedAt }).then(() => {
      let message = "Your session has been scheduled successfully."
      this.toastr.success(message, 'New Session Confirmed!');

      let date = this.helper.istTime2((session.sessionDate.toDate()));
      let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

      // notification for experts.
      let body1 = {
        'userId': session.expertInteraktId,
        'event': 'new session request accepted by parent - notify expert',
        'traits': {
          'parentName': session.createdWithName,
          'childName': session.sessionStudentName,
          'date': dateString,
          'time': session.startTime,
        }
      }
      this.notificationService.sendWhatsappNotification(body1);

      let numberOfSessions = 1
      // notification for parents.
      let body2 = {
        'userId': session.parentInteraktId,
        'event': 'new session request accepted by parent - notify parent',
        'traits': {
          'expertName': session.sessionExpertName,
          'numberOfSessions': numberOfSessions,
          'childName': session.sessionStudentName,
          'date': dateString,
          'time': session.startTime,
        }
      }
      this.notificationService.sendWhatsappNotification(body2);
    });
    this.getSessions();
  }

  async rejectSession(session) {
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
    const docRef =  this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
   
    let ref = await docRef.update({ status: 'Rejected Session Request' })
      .then(() => {
        let message = "Session has been rejected."
        this.toastr.error(message, 'Session Rejected');

        let sessionDate: any = session.sessionDate.toDate();
        let exactSessionDate = this.helper.istTime2(sessionDate);
        let dateString = exactSessionDate.getDate() + '/' + (exactSessionDate.getMonth() + 1) + '/' + exactSessionDate.getFullYear();


        // notification for expert
        let body: any = {
          'userId': session.expertInteraktId,
          'event': 'session request rejected by parent - Notify expert',
          'traits': {
            'date': dateString,
            'time': session.startTime + ' IST',
            'name': session.sessionParentName,
          },
        }
        this.notificationService.sendWhatsappNotification(body);

        this.getSessions();
      });
  }

  // Parent editing the session
  async editSession(sessionId: any) {
    // getting this session details
    let docref = await this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionId)).get().subscribe(this.onEditSession);
  }

  onEditSession = (result) => {
    let session: any;
    session = result.docs[0].data();

    const dialogRef = this.dialog.open(RescheduleDialog, {
      panelClass: 'edit-session-dialog',
      disableClose: true,
      data: { session: session, reschedulingPerson: 'Parent' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        console.log(result)
        this.reschedulingDetails = {
          reschedulingReason: result.reason,
          additionalReschedulingDetails: result.additionalDetails,
          rescheduledOn: result.rescheduledOn,
          rescheduledBy: result.rescheduledBy,
          rescheduledByName: result.rescheduledByName,
          rescheduledWith: result.rescheduledWith,
          rescheduledWithName: result.rescheduledWithName
        }
        this.rescheduleSession(result.session, this.reschedulingDetails, result.reschedulingPerson);
      }
    });
  }

  async rescheduleSession(rescheduledSession, reschedulingDetails, reschedulingPerson: string) {
    let user = await this.auth.currentUser;
    this.toRescheduleSession = rescheduledSession;
    const dialogRef = this.dialog.open(SessionDialog, {
      disableClose: true,
      panelClass: 'rescheduleDialog',
      data: {
        requesting: 'parent',
        rescheduledSession: rescheduledSession,
        reschedulingDetails: reschedulingDetails,
        id: rescheduledSession.id,
        reschedulingPerson: reschedulingPerson
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {

        let now = firebase.firestore.FieldValue.serverTimestamp();

        const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.toRescheduleSession.sessionDate.toDate());
        const docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(this.toRescheduleSession.id);

        await docRef.update({
          'status': 'Rescheduled',
          'rescheduledOn': now,
          'reschedulingReason': this.reschedulingDetails.reschedulingReason,
          'additionalReschedulingDetails': this.reschedulingDetails.additionalReschedulingDetails,
          'rescheduledBy': this.reschedulingDetails.rescheduledBy,
          'rescheduledByName': this.reschedulingDetails.rescheduledByName,
          'rescheduledWith': this.reschedulingDetails.rescheduledWith,
          'rescheduledWithName': this.reschedulingDetails.rescheduledWithName,
          'reschedulingPerson': 'parent'
        })

        // getting all sessions
        this.getSessions();


      }
    });
  }

  hasSessionStarted(session) {
    if (session.status == 'Scheduled' || session.status == 'Started' || session.status == 'Starting') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() >= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rejoinConsultation(consultation) {
    let meetingLink = consultation['meetingLink'];
    console.log('meeting link  ', meetingLink);
    window.open('//' + meetingLink, '_blank');
  }

  joinButtonVisibility(session) {
    if (!session.attendedBy.parent && (session['status'] == 'Scheduled' || session['status'] == 'Expert Joined')) {

      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      // console.log('join btn start time ', exactSessionDate);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);
      // console.log('join btn end time ', exactJoinEndTime);


      //Check if now is greater or equal to session start date time
      // let currentTime = new Date();
      // let now = this.helper.istTime(currentTime);

      // let nowTimeStamp = firebase.firestore.Timestamp.now()
      // let now = new Date(nowTimeStamp.toDate())

      let nowTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let now = new Date(nowTime)
      // console.log('current time  ', now)


      if ((now.getTime() >= exactSessionDate.getTime()) && (now.getTime() <= exactJoinEndTime.getTime())) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  rejoinBtnVisibility(session: any) {
    if (session.attendedBy.parent &&
      (session['status'] == 'Expert Joined' ||
        session['status'] == 'Parent Joined' ||
        session['status'] == 'Ongoing')) {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      // startTime is HH:MM
      let endTime = session.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (endingHour * 60) + endingMinute);

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if ("session.sessionType != 'Free Consultation' && session.sessionType != 'Paid Consultation'") {
        if (now.getTime() <= (exactSessionDate.getTime() - 10 * 60 * 1000)) {
          //session end time has not crossed
          return true;
        }
      } else {
        if (now.getTime() <= exactSessionDate.getTime()) {
          //session end time has not crossed
          return true;
        }
      }
    }
    return false;
  }

  consultationJoinButtonVisibility(session) {
    if (session.status == 'Scheduled' || session.status == 'Ongoing') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);;

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);


      //Check if now is greater or equal to session start date time
      if ((now.getTime() >= exactSessionDate.getTime()) && (now.getTime() <= exactJoinEndTime.getTime())) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  consultationCancelButtonVisibility(session) {
    if (session.status == 'Scheduled') {
      let sessionDate: Date = session.sessionDate.toDate();
      let exactSessionDate = this.helper.istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = this.helper.istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute - 5);
      exactJoinEndTime.setMinutes(exactJoinEndTime.getMinutes() + (startingHour * 60) + startingMinute + 15);;

      let currentTime = new Date();
      let now = this.helper.istTime(currentTime);


      //Check if now is greater or equal to session start date time
      if (now.getTime() <= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  }

  joinMeeting(id) {
    const dialogRef = this.dialog.open(MeetingComponent, {
      disableClose: true,
      width: '100vw',
      height: '100vh',
      data: { id: id }
    });
  }

  startMeeting(sessionDoc) {
    let docRef = this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionDoc.id));
    docRef.get().subscribe(result => {
      let session: any = result.docs[0].data();
      // let zoomDocs:any;

      // parent is creating the meeting.
      if (session.status == 'Scheduled') {
        const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
        // let ref = this.firestore.collection('sessions').doc(session.id);
        let sessionData = session;

        // first check if there is a free zoom license available or not.
        let zoomRef = this.firestore.collection('zoomids', zoomRef => zoomRef.where('beingUsed', '==', false));
        zoomRef.get().subscribe((result) => {
          let size = result.size;

          // if all zoom license are busy
          if (size == 0) {
            const zoomIdUnavailableRef = this.dialog.open(ZoomIdUnavailableDialogComponent, {
              disableClose: false,
              data: { supportLink: "https://wa.me/+919606719826" },
              panelClass: 'zoom-id-unavailable-dialog',
            });
            zoomIdUnavailableRef.afterClosed().subscribe(result => {
              if (!result) {
                return;
              }
            });

            // Getting all admin documents.
            let adminRef = this.firestore.collection('users', adminRef => adminRef.where('role', '==', 'support'));
            adminRef.get().subscribe((adminResult) => {
              if (adminResult.empty) {
                console.log('no admin found')
                return;
              }

              adminResult.forEach(async (doc) => {
                let adminDoc: any;
                adminDoc = doc.data();

                // reminders for admins of this issue
                let body: any = {
                  'userId': adminDoc.interaktId,
                  'event': 'all zoom ids occupied - Notify admin',
                  'traits': {
                    'role': 'Parent',
                    'name': session.sessionParentName,
                  },
                }
                this.notificationService.sendWhatsappNotification(body);
              })
            })
            return;

            // if we have any free zoom license
          } else {
            sessionData.attendedBy.parent = true;
            sessionData.status = 'Starting'
            ref.update(sessionData).then(() => {
              let message = "Your Session has started."
              this.toastr.success(message, "Join Now")

              const startedSession = firebase.firestore().collectionGroup('sessions').where("id","==",session.id);
              const observer = startedSession.onSnapshot(docSnapshot => {
                let data = docSnapshot.docs[0].data();

                if (data['zoomJoinUrl']) {
                  console.log("Parent pop-ups Testing-1");
                  var newWin = window.open(data['zoomJoinUrl'], "_blank");
                  // code to show dialog if pop-ups is blocked
                  if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                    console.log("Testing->Pop-Ups is Blocked");
                    const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                      disableClose: false,
                      // data: { supportLink: "https://wa.me/+919606719826" },
                      panelClass: 'pop-ups-disable-dialog',
                    });
                    popUpsDisableRef.afterClosed().subscribe(result => {
                      if (!result) {
                        return;
                      }
                    });
                  }

                }
              })
            });
          }
        })

        // if meeting has already created and expert is in the meeting.
      } else if (session.status == 'Expert Joined') {

        // if parent is joining the meeting first time.
        if (!session.attendedBy.parent) {
          const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
          // let ref = this.firestore.collection('sessions').doc(session.id);
          let sessionData: any = {};
          sessionData = session;
          sessionData.status = 'Ongoing'
          sessionData.attendedBy.parent = true;
          ref.update(sessionData).then(() => {
            console.log("Rajan Parent Testing-2");
            var newWin = window.open(session['zoomJoinUrl'], "_blank");

            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              console.log("Testing->Pop-Ups is Blocked");
              const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                disableClose: false,
                // data: { supportLink: "https://wa.me/+919606719826" },
                panelClass: 'pop-ups-disable-dialog',
              });
              popUpsDisableRef.afterClosed().subscribe(result => {
                if (!result) {
                  return;
                }
              });
            }

          }).catch(err => console.log('error  ', err))

          // if parent is joining the meeting again.
        } else {
          const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
          // let ref = this.firestore.collection('sessions').doc(session.id);
          ref.update({ status: 'Ongoing' }).then(() => {
            console.log("Parent pop-ups Testing-3");
            var newWin = window.open(session['zoomJoinUrl'], "_blank");

            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              console.log("Testing->Pop-Ups is Blocked");
              const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
                disableClose: false,
                // data: { supportLink: "https://wa.me/+919606719826" },
                panelClass: 'pop-ups-disable-dialog',
              });
              popUpsDisableRef.afterClosed().subscribe(result => {
                if (!result) {
                  return;
                }
              });
            }

          }).catch(err => console.log('error  ', err))
        }

        // if meeting already ended once and parent is rejoining the meeting.
      } else if (session.status == 'Partially Attended' || session.status == 'Attended' || session.status == 'Parent Joined') {
        console.log('rejoin flow is working')
        const timeinMiliSec = this.helper.getMondayInMiliSeconds(session.sessionDate.toDate());
        const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(session.id);
        // let ref = this.firestore.collection('sessions').doc(session.id);
        ref.update({ status: 'Parent Joined' }).then(() => {
          console.log("Rajan Parent Testing-4");

          var newWin = window.open(session['zoomJoinUrl'], "_blank");
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            console.log("Testing->Pop-Ups is Blocked");
            const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
              disableClose: false,
              // data: { supportLink: "https://wa.me/+919606719826" },
              panelClass: 'pop-ups-disable-dialog',
            });
            popUpsDisableRef.afterClosed().subscribe(result => {
              if (!result) {
                return;
              }
            });
          }

        }).catch(err => console.log('error  ', err))
      } else if (session.status == 'Ongoing') {
        console.log("Rajan Parent Testing-5");
        var newWin = window.open(session['zoomJoinUrl'], "_blank");

        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
          console.log("Testing->Pop-Ups is Blocked");
          const popUpsDisableRef = this.dialog.open(PopUpsDisableDialogComponent, {
            disableClose: false,
            // data: { supportLink: "https://wa.me/+919606719826" },
            panelClass: 'pop-ups-disable-dialog',
          });
          popUpsDisableRef.afterClosed().subscribe(result => {
            if (!result) {
              return;
            }
          });
        }

      }
    })
  }

  joinConsultation(consultation) {
    const timeinMiliSec = this.helper.getMondayInMiliSeconds(consultation['sessionDate'].toDate());
    const docRef =this.firestore.collection("new consultations").doc(`${timeinMiliSec}`).collection("consultations").doc(consultation['id']);
    // this.firestore
    //   .doc(`consultations/${consultation['id']}`)
    docRef
      .update({
        status: 'Ongoing',
        'attendedBy.parent': true,
      })
      .then(() => {
        let meetingLink = consultation.meetingLink;
        console.log('meeting link  ', meetingLink);
        window.open(meetingLink, '_blank');
      });
  }

  async cancelMeeting(sessionId: any) {
    // getting this session details
    let docref = await this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionId)).get().subscribe(this.cancelSession);
  }

  cancelSession = (result) => {
    let session: any;
    session = result.docs[0].data();

    const dialogRef = this.dialog.open(CancelDialog, {
      panelClass: 'cancel-session-dialog',
      disableClose: true,
      data: { session: session, cancellingPerson: 'Parent' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSessions();
      if (!result) {
        return;
      }
    });
  }

  topUp() {
    this.router.navigateByUrl("/parent/payment-page");
  }

  async sortTransactions(unfilteredTransactions: AngularFirestoreDocument[]) {
    let transactionsByDate = {};
    let multiTransactionsByDate = {};

    let transactionsList: AngularFirestoreDocument[] = unfilteredTransactions.filter(
      (transaction: AngularFirestoreDocument) => !(transaction['transactionType'] === 'byAdmin' && transaction['hideTranscation'] === true)
    );

    let transactionFilteredList: AngularFirestoreDocument[] = transactionsList.filter(
      (transaction: AngularFirestoreDocument) => !(transaction['transactionType'] === 'renewalTransaction' && transaction['paymentStatus'] === 'failure')
    );

    let transactions: AngularFirestoreDocument[] = transactionFilteredList.filter(
      (transaction: AngularFirestoreDocument) => !(transaction['returnedSessionValue'] === 0 && transaction['transactionValue'] === 0)
    );


    transactions = await this.getSessionData(transactions);
    //Creating transactionByDate for all the transaction which are not created with multipleSession Booking
    //Creating multiTransactionsByDate which have the trandactions created with only multipleSession Booking
    for (let transaction of transactions) {
      let createdDate: Date = this.helper.istTime2(transaction['createdAt'].toDate());
      let createdAt = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate(), 0, 0, 0);
      // let multiSessionBatchId: string;
      // if (transaction['createdWithMultipleSessions']) {
      //   multiSessionBatchId = transaction['multiSessionBatchId'];
      // }
      if (transactionsByDate[createdAt.toString()]) {
        // if (transaction['createdWithMultipleSessions']) {
        //   if (multiTransactionsByDate[createdAt.toString()]) {
        //     if (multiTransactionsByDate[createdAt.toString()][multiSessionBatchId]) {
        //       multiTransactionsByDate[createdAt.toString()][multiSessionBatchId].push(transaction);
        //     } else {
        //       multiTransactionsByDate[createdAt.toString()][multiSessionBatchId] = [];
        //       multiTransactionsByDate[createdAt.toString()][multiSessionBatchId].push(transaction);
        //     }
        //   } else {
        //     multiTransactionsByDate[createdAt.toString()] = {};
        //     multiTransactionsByDate[createdAt.toString()][multiSessionBatchId] = [];
        //     multiTransactionsByDate[createdAt.toString()][multiSessionBatchId].push(transaction);
        //   }
        // } else {
        transactionsByDate[createdAt.toString()].push({
          isMultipleTransaction: false,
          transaction: transaction,
          createdAt: transaction['createdAt'].toDate(),
          multipleTransaction: null
        });
        // }
      }
      else {
        transactionsByDate[createdAt.toString()] = [];
        // if (transaction['createdWithMultipleSessions']) {
        //   multiTransactionsByDate[createdAt.toString()] = {};
        //   multiTransactionsByDate[createdAt.toString()][multiSessionBatchId] = [];
        //   multiTransactionsByDate[createdAt.toString()][multiSessionBatchId].push(transaction);
        // } else {
        transactionsByDate[createdAt.toString()].push({
          isMultipleSession: false,
          transaction: transaction,
          createdAt: transaction['createdAt'].toDate(),
          multipleTransaction: null
        });
        // }
      }
    }
    //creating dates array for sortedDates array
    let dates: Date[] = [];
    for (let i in transactionsByDate) {
      dates.push(new Date(i));
    }
    //merging multiTransactionsByDate into transactionsByDate
    // for (let data in multiTransactionsByDate) {
    //   for (let i in multiTransactionsByDate[data]) {
    //     transactionsByDate[data].push({
    //       isMultipleSession: true,
    //       transaction: null,
    //       createdAt: multiTransactionsByDate[data][i][0]['createdAt'].toDate(),
    //       // expert: multiTransactionsByDate[data][i][0]['session'].sessionExpertName,
    //       multipleTransaction: multiTransactionsByDate[data][i]
    //     });
    //   }
    // }
    // sorting transactionsByDate based on CreatedAt field
    for (let j in transactionsByDate) {
      transactionsByDate[j].sort(this.compareTime);
    }

    this.transactionsByDate = transactionsByDate;
    this.sortedDates = dates;
  }

  onTransactionFilterSelected(event){
    let transactionsByDate = {};
    if(event == 'allTransaction'){
      this.sortTransactions(this.transactions);
    }else {
      let allTransactions = [];
      allTransactions = this.transactions;
      let renewalTransactions: AngularFirestoreDocument[] = allTransactions.filter(
        (transaction: AngularFirestoreDocument) => !(transaction['transactionType'] != 'renewalTransaction')
      ); 
      let transactions: AngularFirestoreDocument[] = renewalTransactions.filter(
        (transaction: AngularFirestoreDocument) => !(transaction['paymentStatus'] === 'failure')
      );
      for (let transaction of transactions) {
        let createdDate: Date = this.helper.istTime2(transaction['createdAt'].toDate());
        let createdAt = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate(), 0, 0, 0);
        if (transactionsByDate[createdAt.toString()]) {
          transactionsByDate[createdAt.toString()].push({
            isMultipleTransaction: false,
            transaction: transaction,
            createdAt: transaction['createdAt'].toDate(),
            multipleTransaction: null
          });
        }
        else {
          transactionsByDate[createdAt.toString()] = [];
          transactionsByDate[createdAt.toString()].push({
            isMultipleSession: false,
            transaction: transaction,
            createdAt: transaction['createdAt'].toDate(),
            multipleTransaction: null
          });
        }
      }
      //creating dates array for sortedDates array
      let dates: Date[] = [];
      for (let i in transactionsByDate) {
        dates.push(new Date(i));
      }
  
      // sorting transactionsByDate based on CreatedAt field
      for (let j in transactionsByDate) {
        transactionsByDate[j].sort(this.compareTime);
      }
  
      this.transactionsByDate = transactionsByDate;
      this.sortedDates = dates;
    }
  }
  

  getSessionData(transactions): AngularFirestoreDocument[] { //session data corresponding to each transaction
    for (let transaction of transactions) {
      if (transaction.transactionType == 'session' && !transaction.session && !transaction.cretedViaMultipleBookings) {
          const docRef =  this.firestore.collectionGroup("sessions",ref=>ref.where("id","==",transaction.sessionId));
          docRef
          .get()
          .toPromise()
          .then((results) => {
           
            if (!results.empty) {
              results.forEach(doc => {
                let session: any = doc.data();
                transaction.session = session;
              })
             
            }
          });
        
      } else if (transaction.transactionType == 'session' && transaction.cretedViaMultipleBookings) {
        transaction['showMultipleSessionDetails'] = false;
      } else if (transaction.transactionType == 'consultation') {
        // this.firestore.doc(`consultations/${transaction.consultationId}`)
        // const timeinMiliSec = this.helper.getMondayInMiliSeconds(transaction.sessionDate.toDate());
        const docRef =this.firestore.collectionGroup("consultations",ref=>ref.where("id","==",transaction.consultationId));
        // docRef.valueChanges().pipe(take(1)).subscribe(
        //   (consultationDoc: AngularFirestoreDocument) => {
        //     if (consultationDoc) {
        //       transaction.session = consultationDoc;
        //     }
        //   }
        // );
        docRef
        .get()
        .toPromise()
        .then((results) => {
         
          if (!results.empty) {
            results.forEach(doc => {
              let consultation: any = doc.data();
              transaction.session = consultation;
            })
           
          }
        });
      }

    }
    return transactions;
  }

  compareTime(a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    } else if (a.createdAt > b.createdAt) {
      return -1;
    } else {
      return 0;
    }
  }

  getFinalTransactionValue(transaction: AngularFirestoreDocument): number {
    let transactionVal: number = transaction['transactionValue'];
    let returnedSessionVal: number = transaction['returnedSessionValue'];

    if (transactionVal === 0 && returnedSessionVal !== 0)
      //  return returnedSessionVal;
      return Math.round((returnedSessionVal + Number.EPSILON) * 100) / 100;
    else if (transactionVal !== 0 && (returnedSessionVal === 0 || !returnedSessionVal))
      // return transactionVal;
       return Math.round((transactionVal + Number.EPSILON) * 100) / 100;
    else if (transactionVal !== 0 && returnedSessionVal !== 0)
      //  return (transactionVal + returnedSessionVal);
      return Math.round((transactionVal + Number.EPSILON) * 100) / 100;
    else if (transactionVal === 0 && returnedSessionVal === 0)
      return 0;
  }

  //searching based on one parameter like Expert Name we are using 4 methods (resetExpertInput,onExpertFocus,onExpertBlur,onExpertClick )
  resetExpertInput(event: string) { //function to reset the expert filter if the expert input is empty
    // console.log("Testing Reset Expert");
    this.expertClicked = false;
    this.isExpertClicked = true;
    if (event === '') {
      this.expertSelected = "";
      this.filterCombinedList(this.expertSelected, this.statusSelected);
    }
  }

  onExpertFocus() {
    // console.log("Testing Expert focus");
    this.isExpertClicked = true;
  }

  onExpertBlur() {
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isExpertClicked = false;
      if (!this.expertClicked) {
        // console.log("Testing Expert Blur");
        this.expertName = '';
        this.expertSelected = "";
        this.filterCombinedList(this.expertSelected, this.statusSelected);
      }
    }, 200);
  }

  onExpertClick(expert: AngularFirestoreDocument) {
    this.expertClicked = true;
    // console.log("Testing Expert Click", expert['id']);
    this.expertName = expert['fullName'];
    // this.dropdownExpertHover = false;
    this.expertSelected = expert['id'];
    this.filterCombinedList(this.expertSelected, this.statusSelected); //filtering session list whenever an expert is clicked from the dropdown list
  }

  resetStatusInput(event: string) { //function to reset the status filter if the status input is empty
    this.statusClicked = false;
    this.isStatusClicked = true;
    if (event === '') {
      this.statusSelected = "";
      this.filterCombinedList(this.expertSelected, this.statusSelected);
    }
  }

  onStatusFocus() {
    this.isStatusClicked = true;
  }

  onStatusBlur() {
    setTimeout(() => { //setTimeout is used to make blur slower than onExpertClick
      this.isStatusClicked = false;
      if (!this.statusClicked) {
        this.statusName = '';
        this.statusSelected = "";
        this.filterCombinedList(this.expertSelected, this.statusSelected);
      }
    }, 200);
  }

  onStatusClick(status) {
    this.statusClicked = true;
    // console.log("Testing status Click", status);
    this.statusName = status['name'];
    // this.dropdownExpertHover = false;
    this.statusSelected = this.statusName;
    this.filterCombinedList(this.expertSelected, this.statusSelected); //filtering session list whenever an expert is clicked from the dropdown list
  }

  arrayBuffer: any;
  file: File;
  ws: any;
  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    this.ws = [];
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.ws = (XLSX.utils.sheet_to_json(worksheet, { raw: true }));

      console.log(this.ws);

      this.ws.forEach((element, index, array) => {      
        let renewalDate=new Date(Math.round((this.ws[index]['Date'] - 25569) * 86400 * 1000));
        console.log(renewalDate);
        let period;      
        let expiryDate;
        let compDate=new Date("01/01/2023");
        let todayDate=new Date();
        console.log(compDate,"compDate",todayDate);
        console.log(renewalDate>=compDate);
        // let gracePeriod=expiryDate;
        // gracePeriod.add("month",1);
        
        let ref = this.firestore.collection('users', ref => ref.where('email', '==',this.ws[index]['email']).where("activityStatus","==","Active"))
        ref.get().subscribe(results => {
          if (!results.empty) {
            results.forEach((doc) => {
              let usersData: any =  doc.data()
              console.log(usersData,"usersData");
              if(renewalDate>=compDate){
                period=this.ws[index]['Sessions']/4; 
                expiryDate=moment(renewalDate).add("month",period); 
                console.log(period,"period",renewalDate,"renewalDate",expiryDate,"expiryDate");
                this.firestore.collection('users').doc(usersData.id).update({validTill:expiryDate.toDate(),gracePeriod:expiryDate.add("month",1).toDate()}).then(() => {
                  console.log("Successful",usersData.email);
                }).catch(err => console.log(err,usersData.email))
              }else{
                let ref = this.firestore.collection('balances', ref => ref.where('userId', '==',usersData.id))
                ref.get().subscribe(results => {
                  if (!results.empty) {
                    results.forEach((doc) => {
                      let balanceData: any =  doc.data()
                      console.log(balanceData,"balanceData");
                      if(balanceData.currentBalance>0){
                        period= balanceData.currentBalance/4;
                        expiryDate=moment(todayDate).add("month",period); 
                      }else{
                        expiryDate=moment(todayDate);
                      }
                      console.log(period,"period",renewalDate,"renewalDate",expiryDate,"expiryDate");
                      this.firestore.collection('users').doc(usersData.id).update({validTill:expiryDate.toDate(),gracePeriod:expiryDate.add("month",1).toDate()}).then(() => {
                        console.log("Successful",usersData.email);
                      }).catch(err => console.log(err,usersData.email))
                      
                    });
                  }
                  
                })
      
              }
             
            });
          }
          
        })
      //   var createdOn = new Date(Math.round((this.ws[index]['createdOn'] - 25569) * 86400 * 1000));
      //   var leaveEndDateTime = new Date(Math.round((this.ws[index]['leaveEndDateTime'] - 25569) * 86400 * 1000));
      //   var leaveStartDateTime = new Date(Math.round((this.ws[index]['leaveStartDateTime'] - 25569) * 86400 * 1000));
      //   var id= this.helper.uuidv4();
      //   if (this.ws.length > 0) {
      //     this.firestore.collection('leaves').doc(id).set({
      //       'createdOn': createdOn,
      //       'id': id,
      //       // 'leaveBy': firebase.auth().currentUser.uid,
      //       'leaveBy': this.ws[index]['leaveBy'],
      //       'leaveEndDateTime': leaveEndDateTime,
      //       'leaveReason': this.ws[index]['leaveReason'],
      //       'leaveStartDateTime': leaveStartDateTime,
      //     }).then(() => {
      //       console.log("Successful");
      //     }).catch(err => console.log(err))
      //   }
      });
    }
    fileReader.readAsArrayBuffer(this.file);
  }


    ngOnDestroy() {
    if (this.expertSub) this.expertSub.unsubscribe();
  }

}

@Component({
  selector: 'app-insufficient-balance-dialog',
  template: `
  <mat-card style="width: 100%;" class="zoomUnAvailableCard">
    <div style="display: flex; justify-content: flex-end;">
        <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>
    <mat-card-title class="penaltyWarning">
        <div>
            <mat-icon class="material-icons-filled" style="font-size: 50px !important; margin-right: 35px;">
              error
            </mat-icon>
        </div>
        No Balance
    </mat-card-title>
    <mat-card-content>
        <p style="color: #A5A5A5;">Unfortunately, you have exhausted your session balance. If you wish to continue taking sessions with your KinderPass Experts, please purchase more sessions.</p>
    </mat-card-content>
    <mat-card-actions>
        <button class="right actionButtons" mat-button color="warn" (click)="topUpBalance()">Top Up Balance</button>
    </mat-card-actions>
</mat-card>
  `,
  styleUrls: ['../zoom-id-unavailable-dialog/zoom-id-unavailable-dialog.component.css']
})
export class InsufficientBalanceDialog implements OnInit {
  balanceRechargeLink = "https://wa.me/+919606719826?text=I'm%20interested%20in%20renewing%20my%20session%20balance"

  constructor(private dialogRef: MatDialogRef<InsufficientBalanceDialog>,private router: Router) { }

  ngOnInit() { }

  topUpBalance() {
    this.router.navigateByUrl("/parent/payment-page");
    this.dialogRef.close();
  }

  ngOnDestroy() { }
}

@Component({
  selector: 'app-validity-expired-dialog',
  template: `
      <mat-card style="width: 100%;" class="zoomUnAvailableCard">
        <div style="display: flex; justify-content: flex-end;">
            <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
        </div>
        <mat-card-title class="penaltyWarning">
            <div>
                <mat-icon class="material-icons-filled" style="font-size: 50px !important; margin-right: 35px;">
                  error
                </mat-icon>
            </div>
           Pack Validity Expired!
        </mat-card-title>
        <mat-card-content>
            <p style="color: #A5A5A5;">Unfortunately, your plan is expired. If you wish to continue taking sessions with your KinderPass Experts, please purchase more sessions.</p>
            <p style="color: #A5A5A5;" *ngIf="this.data.isGracePeriod" >You are in a grace period.Your remaining balnace is {{this.data.balance}}</p>
            </mat-card-content>
        <mat-card-actions>
            <button class="right actionButtons" mat-button color="warn" (click)="topUpBalance()">Top Up Balance</button>
        </mat-card-actions>
    </mat-card>
  `,
  styleUrls: ['../zoom-id-unavailable-dialog/zoom-id-unavailable-dialog.component.css']
})
export class ValidityExpiredDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<ValidityExpiredDialog>,private router: Router,@Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit() { 
    console.log(this.data);
   
  }

  topUpBalance() {
    this.router.navigateByUrl("/parent/payment-page");
    this.dialogRef.close();
  }

  ngOnDestroy() { }
}