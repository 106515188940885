<div style="float: right; z-index:1; cursor: pointer;">
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div>  
    <form [formGroup]="partiallyAttendeForm">

        <mat-horizontal-stepper formArrayName="formPartition" linear>
            <mat-step formGroupName="0" stepControl="formPartition.get([0])" label="Basic Details">
                <mat-card-title style="margin-bottom: 20px !important; line-height: 26px;">Enter reason for this session being
                    partially attended</mat-card-title>

                <mat-form-field class="full-width-field">
                    <mat-label>Partially Attended User</mat-label>
                    <mat-select formControlName="partiallyAttendedBy" disableOptionCentering panelClass="dropdown">
                        <mat-option value="parent">Parent</mat-option>
                        <mat-option value="expert">Expert</mat-option>
                        <mat-option value="admin">Tactopus Admin</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Reason</mat-label>
                    <input type="text" formControlName="partiallyAttendedreason" matInput>
                </mat-form-field>

                <mat-card-actions>
                    <button mat-button style="background-color: gainsboro;" class="right" color="warn" matStepperNext>Next</button>
                </mat-card-actions>
            </mat-step>

            <mat-step formGroupName="1" stepControl="formPartition.get([1])" label="Compensation Details">
                <mat-card-subtitle style="color:#F7524B; font-weight: bold;">{{compensationSubHeading}}</mat-card-subtitle>
                <mat-card-title style="margin-bottom: 20px !important; line-height: 26px;">{{compensationHeading}}</mat-card-title>

                <mat-form-field style="width: 100%">
                    <mat-label>Compensation to expert</mat-label>
                    <input type="number" formControlName="expertCompensation" matInput>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Compensation to parent</mat-label>
                    <input type="number" formControlName="parentCompensation" matInput>
                </mat-form-field>

                <mat-card-actions>
                    <button mat-button style="background-color: gainsboro;" class="right" type="submit" color="warn" [disabled]="partiallyAttendeForm.invalid"
                    (click)="save();">Save</button>
                    <button mat-button style="background-color: gainsboro;" class="right" matStepperPrevious type="button" color="warn">Back</button>
                </mat-card-actions>
            </mat-step>
        </mat-horizontal-stepper>

    </form>
</div>