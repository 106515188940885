import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class InteraktUserService {
  private interaktUserURL =  'https://api.interakt.ai/v1/public/track/users/';
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,  'Authorization': 'Basic S196Z21ZZVBycnFWRldiaHh1WXdTZ1JmX2NOSVQxRHB3aHVYWGl3aFRsUTo=' })
  };

  

  constructor(private helper: HelperService,
              private http: HttpClient,
              private snackBar: MatSnackBar) { }

  // for creating new parent interakt account
  createUser = (result) => {
    let userBody = {
      'userId': result.interaktId,
      'phoneNumber': result.whatsappNumber,
      'countryCode': result.whatsappNumberCountryCode,
      'traits': {
        'name': result.parentName,
        'email': result.parentEmail,
        'createdVia': 'Sending New Invitation',      
      }   
    }

    return this.http.post(this.interaktUserURL, userBody, this.httpOptions).toPromise().then(this.onUserCreated).catch(err => console.log(err));
  }

  onUserCreated = (event) => {
    let message = 'User Created Successfully'
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }


  // for creating new expert interakt account
  createExpertAccount = (expert) => {
    let expertBody = {
      'userId': expert.interaktId,
      'phoneNumber': expert.phoneNumber,
      'countryCode': expert.countryCode,
      'traits': {
        'name': expert.fullName,
        'email': expert.email,      
      }   
    }

    return this.http.post(this.interaktUserURL, expertBody, this.httpOptions).toPromise().then(this.onExpertCreated).catch(err => console.log(err));
  }

  onExpertCreated = (event) => {
    let message = 'Expert Created Successfully'
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }


  // for creating new support-admin interakt account
  createSupportAdminAccount = (admin) => {
    let adminBody = {
      'userId': admin.interaktId,
      'phoneNumber': admin.whatsappNumber,
      'countryCode': admin.whatsappNumberCountryCode,
      'traits': {
        'name': admin.fullName,
        'email': admin.email,      
      }   
    }

    return this.http.post(this.interaktUserURL, adminBody, this.httpOptions).toPromise().then(this.onAdminCreated).catch(err => console.log(err));
  }

  onAdminCreated = (event) => {
    let message = 'Admin Created Successfully'
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }


  // for creating new parent interakt account via consultation flow.
  createNewParent = (result) => {
    let userBody = {
      'userId': result.interaktId,
      'phoneNumber': result.whatsappNumber,
      'countryCode': result.whatsappNumberCountryCode,
      'traits': {
        'name': result.parentName,
        'email': result.parentEmail,
        'createdVia': 'Booking New Consultation',     
      }   
    }

    return this.http.post(this.interaktUserURL, userBody, this.httpOptions).toPromise().then(this.onParentCreated).catch(err => console.log(err));
  }

  onParentCreated = (event) => {
    let message = 'User Created Successfully'
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

}
