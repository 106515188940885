<mat-card style="width: 100%;" class="rescheduleCard" style="padding: 16px !important;">
  <div style="display: flex; justify-content: flex-end;">
      <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
  </div>
  <mat-card-subtitle style="color: #59AADD;" class="penaltyWarning"></mat-card-subtitle>
  <mat-card-title>
    <h1 style="font-weight: 600;">
      {{ this.data.dialogType === "cancel" ? "Which party is cancelling this session?" : "Which party is requesting a reschedule for this session?"}}
    </h1>
  </mat-card-title>
  <mat-card-actions>
      <div style="display: flex; justify-content: space-evenly; margin-bottom: 25px; margin-top: -10px;">
        <button class="left actionButtons" mat-button color="warn" (click)="nextStep('Parent')">Parent</button>
        <button class="left actionButtons" mat-button color="warn" (click)="nextStep('Expert')">Expert</button>
        <button class="left actionButtons" mat-button color="warn" (click)="nextStep('Admin')">Admin</button>
      </div>
  </mat-card-actions>
</mat-card>
