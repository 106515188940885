import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { InteraktUserService } from '../services/interakt-user.service';

@Component({
  selector: 'app-sales-admin-register',
  templateUrl: './sales-admin-register.component.html',
  styleUrls: ['./sales-admin-register.component.css']
})
export class SalesAdminRegisterComponent implements OnInit {
  phoneNumber: string;
  phoneNumberCountryCode = '+91';
  whatsappNumberCountryCode = '+91';
  // user: any;
  constructor(private auth: AngularFireAuth, private firestore: AngularFirestore, private router: Router, private newInteraktUserService: InteraktUserService) { }

  async ngOnInit() {
    // this.user = await this.auth.currentUser;
  }

  onPhoneNumberCountryChange(event) {
    this.phoneNumberCountryCode = '';
    this.phoneNumberCountryCode = '+' + event.dialCode;
  }

  onWhatsappNumberCountryChange(event) {
    this.whatsappNumberCountryCode = '';
    this.whatsappNumberCountryCode = '+' + event.dialCode;
  }

  onSave(name: string, email: string, phoneNo: string, whatsappNo: string) {

    this.auth.currentUser.then(
      (user) => {
        console.log(user.uid);

        const docRef = this.firestore.doc(`users/${user.uid}`);
        docRef.get().subscribe(async (result) => {
          let admin:any = result.data();
          if(!admin.verified){
            // Creating new interakt user account for support admin.
            await this.newInteraktUserService.createSupportAdminAccount(admin);
          }
    
          docRef.update({
            name: name,
            fullName: name,
            email: email,
            phoneNumber: phoneNo,
            phoneNumberCountryCode: this.phoneNumberCountryCode,
            whatsappNumber: whatsappNo,
            whatsappNumberCountryCode: this.whatsappNumberCountryCode,
            registered: true,
            verified: true
          }).then(()=>{
            this.router.navigateByUrl('sales-admin/dashboard');
          }).catch(() => { console.error("User doesn't exist"); });
        }) 
      }
    );
  }
}
