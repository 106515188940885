<div class="page-container">
	<mat-tab-group>
		<mat-tab label="Sessions">
			<div>
				<mat-card>
					<mat-card-header>
						<mat-button-toggle-group class="button-toggle-group" name="filter" aria-label="Filter"
							(change)="onFilterChanged($event)">
							<mat-button-toggle class="button-toggle" value="All">All</mat-button-toggle>
							<mat-button-toggle class="button-toggle" value="Booked">Booked</mat-button-toggle>
						</mat-button-toggle-group>
						<span class="spacer"></span>
						<mat-form-field class="right">
							<mat-label>Enter a date range</mat-label>
							<mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
								<input matStartDate placeholder="Start date" formControlName="start">
								<input matEndDate placeholder="End date" formControlName="end"
									(dateChange)="onDateRangeChange()">
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
						<button mat-icon-button aria-label="Previous" (click)="back()">
							<mat-icon>arrow_back</mat-icon>
						</button>
						<button mat-icon-button aria-label="Next" (click)="forward()">
							<mat-icon>arrow_forward</mat-icon>
						</button>
					</mat-card-header>
					
					<mat-card-content>
						<mat-card *ngFor="let session of sessions">
							<mat-card-header>
								<mat-card-title class="full-width">
									<span>{{session.sessionByName}}</span>
									<span *ngIf="session.status=='Booked'">{{session.parentName}} -
										{{session.childName}}</span>
                  <div>Yo</div>
								</mat-card-title>
							</mat-card-header>
							<mat-card-content class="card-content">
								<span>{{session.sessionDate.toDate() | date}}</span> {{session.startTime}} -
								{{session.endTime}}
							</mat-card-content>
							<mat-card-actions align="end">
								<button mat-button color="warn" color="warn"
									(click)="viewSessionDetails(session.id)">View Details</button>
							</mat-card-actions>
						</mat-card>
					</mat-card-content>
					<!-- <mat-card-actions align="end">
				    <button mat-button color="warn" (click)="addSession()">Add Session</button>
				  </mat-card-actions> -->
				</mat-card>
			</div>
		</mat-tab>
		<mat-tab label="Experts">
			<div>
				<mat-card>
					<mat-card-content>
						<mat-card *ngFor="let expert of experts">
							<mat-card-header>
								<mat-card-title>
									<span>{{expert.fullName}}</span>

								</mat-card-title>
								<mat-card-subtitle>
									<span>{{expert.phoneNumber}}</span>
								</mat-card-subtitle>
							</mat-card-header>
							<mat-card-content class="card-content">
								<span>{{expert.serviceTypes}}-{{expert.ageGroups}}-{{expert.languagePreferences}}-{{expert.gender}}</span>
							</mat-card-content>
							<mat-card-actions align="end">
								<button mat-button color="warn" color="warn" (click)="viewExpertDetails(expert.id)">View
									Details</button>
								<button *ngIf="!expert.verified" mat-button color="warn"
									(click)="markAsVerified(expert.id)">Mark As Verified</button>
							</mat-card-actions>
						</mat-card>
					</mat-card-content>
				</mat-card>
			</div>
		</mat-tab>
		<mat-tab label="Students">
			<div>
				<mat-card>
					<mat-card-content>
						<mat-card *ngFor="let student of students">
							<mat-card-header>
								<mat-card-title>
									<span>{{student.fullName}}</span>
								</mat-card-title>
							</mat-card-header>
							<mat-card-content class="card-content">
								{{student.status}}
							</mat-card-content>
							<mat-card-actions align="end">
								<button mat-button color="warn" (click)="showGoalSheet()">Goal Sheet</button>
							</mat-card-actions>
						</mat-card>
					</mat-card-content>
				</mat-card>
			</div>
		</mat-tab>
		<mat-tab label="Balance">
			<button mat-raised-button color="accent" (click)="activateBalanceForm = true" class="triggerBtn">Add
				Balance</button>
			<div *ngIf="activateBalanceForm">
				<form [formGroup]="balanceForm" (ngSubmit)="saveBalanceData()">
					<mat-card>
						<mat-card-header>
							<mat-card-title>Balance Form</mat-card-title>
						</mat-card-header>
						<mat-card-content>
							<mat-form-field class="full-width-field">
								<mat-label>User</mat-label>
								<mat-select #userIdCtrl formControlName="userId" required>
									<mat-option *ngFor="let user of users" value="{{user.id}}"
										(click)="selectedUserName = user.fullName">{{user.fullName}}</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field class="full-width-field">
								<mat-label>Balance Type</mat-label>
								<mat-select #balanceTypeCtrl formControlName="balanceType" required>
									<mat-option *ngFor="let balanceType of balanceTypes" value="{{balanceType.value}}">
										{{balanceType.label}}</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field class="full-width-field">
								<mat-label>Current Balance</mat-label>
								<input matInput autocomplete="off" type="number" formControlName="currentBalance">
							</mat-form-field>
						</mat-card-content>
						<mat-card-actions>
							<button mat-button type="button" color="warn"
								(click)="activateBalanceForm = false">Cancel</button>
							<button mat-button type="submit" color="warn" [disabled]="balanceForm.invalid">Save</button>
						</mat-card-actions>
					</mat-card>
				</form>
			</div>


			<div>
				<mat-card>
					<mat-card-content>
						<mat-card *ngFor="let balance of balances">
							<mat-card-header>
								<mat-card-title>
									<span>{{balance.fullName}}</span>
								</mat-card-title>
							</mat-card-header>

							<mat-card-content class="card-content">
								<div id="balanceInfo">
									<span id="balanceValue">Current Balance: {{balance.currentBalance}}</span>
									<span>Last Updated:  {{balance.lastUpdated.toDate() | date: 'yyyy/MM/dd h:mm:ss a'}}</span>
								</div>
							</mat-card-content>
						</mat-card>
					</mat-card-content>
				</mat-card>
			</div>

			<!-- interakt testing -->
			<!-- <button (click)="checkIt()">test kr rha</button> -->


		</mat-tab>
		<mat-tab label="Invitations">
			<button mat-raised-button color="accent" (click)="inviteUser()">New Invitation</button>

		</mat-tab>
	</mat-tab-group>
</div>
