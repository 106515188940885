<div class="paymentFailureComponent">
    <div class="failureWrapper">
        <div style="display: flex; justify-content: flex-start; width: 100%;">
            <a matButton routerLink="/parent/payment-page" id="back-button">
                <mat-icon>arrow_back</mat-icon>
            </a>
        </div>

        <div style="display: flex; align-items: center" class="fail failurecomponent"
            *ngIf="showFailureDetails == false">
            <div class="iconclass">
                <mat-icon class="iconcancel">cancel</mat-icon>
            </div>

            <div style="width: 90%;">
                <div class="upperblockforfailure">
                    <span class="failpaymenttext">Your payment failed</span>
                    <!-- <a type="button" (click)="openFailureDetails()" class="detailstext">Details</a> -->
                </div>

                <div style="display: flex; justify-content: space-between; width: 100%;">
                    <div class="reasonsandcontactsupportclass">
                        <span class="reasons">{{helper.renewalPaymentDetails.errorReason}}</span>

                        <div class="contactsupport">
                            <a href="https://wa.me/+919606719826" target="_blank"
                                style="color: black; font-weight:bold;">Contact Support</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- the details for failure page  -->
        <div class="failureDetailsWrapper">
            <div class="gapdiv"></div>
            <div class="paymentfailurepage">
                <div class="icondiv">
                    <mat-icon class="matcancel">cancel</mat-icon>
                </div>

                <div class="successpayment">
                    <h1 class="successtextpayment">Your Payment failed</h1>
                </div>

                <div class="userDetailsDiv"
                    style="display: flex; justify-content: space-between; margin-top: 40px; padding-left: 15px;">
                    <span class="userDetailName">Reasons</span>
                    <span class="userDetailValue">{{helper.renewalPaymentDetails.errorReason}}</span>
                </div>

                <div class="userDetailsDiv" style="display: flex; justify-content: space-between; padding-left: 15px;">
                    <span class="userDetailName">Name</span>
                    <span class="userDetailValue">{{helper.renewalPaymentDetails.name}}</span>
                </div>

                <div class="userDetailsDiv" style="display: flex; justify-content: space-between; padding-left: 15px;">
                    <span class="userDetailName">Total Sessions</span>
                    <span class="userDetailValue">{{helper.renewalPaymentDetails.totalSessions}}</span>
                </div>

                <div class="userDetailsDiv" style="display: flex; justify-content: space-between; padding-left: 15px;">
                    <span class="userDetailName">Amount</span>
                    <span class="userDetailValue">{{helper.renewalPaymentDetails.amount}}</span>
                </div>

                <div class="userDetailsDiv" style="display: flex; justify-content: space-between; padding-left: 15px;">
                    <span class="userDetailName timedetailsforfailurepage">Time and date</span>
                    <span class="userDetailValue timeeanddatevalueforfailurepage">{{helper.renewalPaymentDetails.date |
                        date:'h:mm a
                        dd/MM/YYYY'}}</span>
                </div>

                <div class="userDetailsDiv" style="display: flex; justify-content: space-between; padding-left: 15px;">
                    <span class="userDetailName">Transaction ID </span>
                    <span class="userDetailValue">{{helper.renewalPaymentDetails.transactionId}}</span>
                </div>

                <div class="userDetailsDiv" style="display: flex; justify-content: space-between; padding-left: 15px;">
                    <span class="userDetailName">Kinderpass Invoice Id</span>
                    <span class="userDetailValue">{{helper.renewalPaymentDetails.invoiceId}}</span>
                </div>

                <div class="userDetailsDiv" style="display: flex; justify-content: space-between; padding-left: 15px;">
                    <a href="https://wa.me/+919606719826" target="_blank"
                        style="color: black; font-weight:bold; margin-top: 20px ">Contact Support</a>
                </div>
            </div>

            <div style="display: flex; justify-content: flex-end; margin-top: 25px;">
                <button class="backbutton" (click)="backToPaymentPage()" mat-button>Retry</button>
            </div>

        </div>
    </div>
</div>