import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temporary-functions',
  templateUrl: './temporary-functions.component.html',
  styleUrls: ['./temporary-functions.component.css']
})
export class TemporaryFunctionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  // bulk upload operation start

  // new invitation function.
  // bulkUpload1() {
  //   if (data1 && (typeof data1 === "object")) {
  //     Object.keys(data1).forEach(docKey => {
  //       let inviteData = data1[docKey];

  //       let inviteDocId = this.helper.uuidv4();
  //       let interaktId = this.helper.uuidv4();
  //       let invitation: any = {};
  //       let expertIds = [];
  //       let educators = [];
  //       let services = []
  //       let educator = {};
  //       let verificationNumbers = [];
  //       const createdOn = firebase.firestore.FieldValue.serverTimestamp();

  //       // data manipulation
  //       expertIds.push(inviteData.educatorId);
  //       services.push(inviteData['service(s)']);

  //       let verificationNumber1 = inviteData.phoneNumberCountryCode + inviteData.phoneNumber;
  //       verificationNumbers.push(verificationNumber1);

  //       if (inviteData.phoneNumber != inviteData.whatsappNumber) {
  //         let verificationNumber2 = inviteData.whatsappNumberCountryCode + inviteData.whatsappNumber;
  //         verificationNumbers.push(verificationNumber2);
  //       }

  //       educator['educatorId'] = inviteData.educatorId
  //       educator['educatorName'] = inviteData.educatorName
  //       educator['service'] = services;

  //       educators.push(educator);

  //       // setting data for invitation
  //       invitation['createdOn'] = createdOn;
  //       invitation['registered'] = false;

  //       invitation['id'] = inviteDocId;
  //       invitation['userId'] = interaktId;

  //       invitation['myExpertsIds'] = expertIds;
  //       invitation['educators'] = educators;

  //       invitation['eMail'] = inviteData.eMail;
  //       invitation['name'] = inviteData.name;

  //       invitation['phoneNumberCountryCode'] = inviteData.phoneNumberCountryCode;
  //       invitation['phoneNumber'] = inviteData.phoneNumber;
  //       invitation['whatsappNumberCountryCode'] = inviteData.whatsappNumberCountryCode;
  //       invitation['whatsappNumber'] = inviteData.whatsappNumber;
  //       invitation['verificationNumbers'] = verificationNumbers;

  //       invitation['sessionPackValue'] = inviteData.sessionPackValue;
  //       invitation['numberOfSessions'] = inviteData.numberOfSessions;

  //       invitation['temporaryParentId'] = inviteData.bulkUploadAccountId;
  //       invitation['viaBulkUpload'] = true;

  //       // console.log('invitation  ',invitation)

  //       // storing the data to database
  //       this.firestore.collection('invitations').doc(inviteDocId).set(invitation).then((res) => {
  //         console.log("Document " + docKey + " successfully written!");
  //         this.onInvitationCreated(inviteDocId)
  //       }).catch((error) => {
  //         console.error("Error writing document: ", error);
  //       });
  //     });
  //   }
  // }

  // onInvitationCreated(id) {
  //   let docRef = this.firestore.collection("invitations").doc(id);
  //   docRef.get().subscribe(this.onInvitationResult);
  // }

  // onInvitationResult = (result) => {
  //   if (result.exists) {
  //     let invitation: any = result.data();

  //     // Creating new interakt user
  //     this.newInteraktUserService.createUser(invitation).then(() => {
  //       let phone: any;
  //       if (invitation.phoneNumber == invitation.whatsappNumber) {
  //         phone = invitation.phoneNumber;
  //       } else {
  //         phone = invitation.phoneNumber + ', ' + invitation.whatsappNumber;
  //       }

  //       let body1 = {
  //         "userId": invitation.userId,
  //         'event': 'Invite Notification Event - bulk upload',
  //         'traits': {
  //           'phone': phone,
  //           'link': 'https://connect.mykinderpass.com/'
  //         }
  //       }
  //       // sending notification to the new user.
  //       this.notificationService.sendWhatsappNotification(body1);

  //     }).catch(err => console.log('error creating interakt user  ', err));
  //   }
  // }


    // updateInteraktId() {
  //   let userRef = this.firestore.collection('users').doc('8LJbsWUHpXR7wUlyf0WWNs3SnUg2').get().subscribe((doc) => {
  //     let user: any = doc.data();
  //     let interaktUserURL = 'https://api.interakt.ai/v1/public/track/users/';

  //     let httpOptions = {
  //       headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic S196Z21ZZVBycnFWRldiaHh1WXdTZ1JmX2NOSVQxRHB3aHVYWGl3aFRsUTo=' })
  //     };

  //     let id = this.helper.uuidv4();
  //     console.log('new id ', id)
  //     let userBody = {
  //       'userId': id,
  //       'phoneNumber': '8403906236',
  //       'countryCode': '+91',
  //       'traits': {
  //         'name': 'JYOTHSNA UNNIKRISHNAN',
  //         'email': 'umakutee88@gmail.com',
  //         // 'Session Pack Value': result.sessionPackValue,
  //         // 'Number Of Sessions': result.numberOfSessions,
  //         // 'Contact Number': result.phoneNumber
  //       }
  //     }

  //     return this.http.post(interaktUserURL, userBody, httpOptions).toPromise().then(() => {
  //       console.log('user created');
  //       // let ref = this.firestore.collection('users').doc('8LJbsWUHpXR7wUlyf0WWNs3SnUg2').update({interaktId: id})
  //     }).catch(err => console.log(err));

  //   })
  // }


// session upload function.
// bulkUpload2() {
//   if (data1 && (typeof data1 === "object")) {
//     let admin: any = this.adminService.adminDoc;
//     Object.keys(data1).forEach(docKey => {
//       let sessionData = data1[docKey];

//       // getting invitation document of the parent
//       let docRef = this.firestore.collection("invitations", docRef => docRef.where('temporaryParentId', '==', sessionData.bulkUploadAccountId));
//       docRef.get().subscribe((result) => {
//         if (result.empty) {
//           console.log('not getting invitation doc of parent  ', sessionData.bulkUploadAccountId);
//         } else {
//           result.forEach(doc => {
//             let invitation: any = doc.data();
//             let parentInteraktId = invitation.userId;
//             let session: any = {};

//             let sessionDocId = this.helper.uuidv4();
//             const createdOn = firebase.firestore.FieldValue.serverTimestamp();

//             let attendedBy = {};
//             attendedBy['admin'] = false;
//             attendedBy['expert'] = false;
//             attendedBy['parent'] = false;

//             let bookedBy = [];
//             bookedBy.push(admin.id);

//             let serviceType = [];
//             serviceType.push(sessionData.serviceType)

//             // creating session date string
//             // let sessionDateString = sessionData.sessionDate + ' ' + '00:00:00';
//             let sessionDateString = "2021-11-25 00:00:00"

//             let sessionDate = new Date(sessionDateString);
//             let sessionEndDateTime = new Date(sessionDateString);

//             let startTime: any = sessionData.startTime
//             let sessionStartTime = startTime.split(':');

//             // to get session starting time in minutes
//             const startingHour = parseInt(sessionStartTime[0], 10);
//             const startingMinute = parseInt(sessionStartTime[1], 10);
//             let sessionStartTimeAccurate = startingHour * 60 + startingMinute;
//             let slotDuration = sessionData.slotDuration;

//             // this is session end time in minutes
//             let sessionEndTime = sessionStartTimeAccurate + slotDuration;

//             let endHour = Math.floor(sessionEndTime / 60);
//             let endMinute = sessionEndTime % 60;

//             // creating a session end timestamp for filter comparison
//             sessionEndDateTime.setHours(endHour, endMinute, 0, 0);


//             // setting data for session
//             session['expertPaid'] = false;
//             session['createdOn'] = createdOn;
//             session['id'] = sessionDocId;
//             session['attendedBy'] = attendedBy;
//             session['bookedBy'] = bookedBy;
//             session['createdBy'] = admin.id;
//             session['createdByName'] = admin.fullName;
//             session['createdWith'] = sessionData.sessionExpertId;
//             session['createdWithName'] = sessionData.sessionExpertName;
//             session['serviceType'] = serviceType;
//             session['sessionStudentId'] = sessionData.bulkUploadAccountId;
//             session['startTime'] = sessionData.startTime;
//             session['endTime'] = sessionData.endTime;
//             session['expertInteraktId'] = sessionData.expertInteraktId;
//             session['sessionExpertId'] = sessionData.sessionExpertId
//             session['sessionExpertName'] = sessionData.sessionExpertName;
//             session['sessionValue'] = sessionData.sessionValue;
//             session['slotDuration'] = sessionData.slotDuration;
//             session['status'] = sessionData.status;
//             session['sessionStudentName'] = '';
//             session['sessionParentName'] = '';
//             session['parentInteraktId'] = parentInteraktId;
//             session['sessionDate'] = sessionDate;
//             session['sessionEndDateTime'] = sessionEndDateTime;

//             // storing the data to database
//             this.firestore.collection('sessions').doc(sessionDocId).set(session).then((res) => {
//               console.log("Document " + docKey + " successfully written!");
//             }).catch((error) => {
//               console.error("Error writing document: ", error);
//             });

//           })
//         }
//       });

//     });
//   }
// }


// session upload function.
  // interaktAccountBulkUpload() {
  //   if (data1 && (typeof data1 === "object")) {
  //     let admin: any = this.adminService.adminDoc;
  //     Object.keys(data1).forEach(createdWhile => {
  //       let interaktData = data1[createdWhile];
  //       let docId = this.helper.uuidv4();
  //       const createdOn = firebase.firestore.FieldValue.serverTimestamp();
  //       let interaktAccount: any = {};

  //       interaktAccount['createdAt'] = createdOn;
  //       interaktAccount['createdBy'] = admin.id;
  //       interaktAccount['createdWhile'] = "Bulk Upload";
  //       interaktAccount['interaktAccountQueryNumber'] = interaktData.interaktAccountQueryNumber;
  //       interaktAccount['interaktId'] = interaktData.interaktId;
  //       interaktAccount['parentEmail'] = interaktData.parentEmail;
  //       interaktAccount['parentName'] = interaktData.parentName;
  //       interaktAccount['whatsappNumber'] = interaktData.whatsappNumber;
  //       interaktAccount['whatsappNumberCountryCode'] = interaktData.whatsappNumberCountryCode;

  //       // storing the data to database
  //       this.firestore.collection('interaktaccounts').doc(docId).set(interaktAccount).then((res) => {
  //         console.log("Document " + createdWhile + " successfully written!");
  //       }).catch((error) => {
  //         console.error("Error writing document: ", error);
  //       });
  //     });
  //   }
  // }

  // deleteInteraktBulkAccount(){
  //   let ref = this.firestore.collection('interaktaccounts', ref => ref.where('createdWhile', '==', 'Bulk Upload'));
  //   ref.get().subscribe((docs)=>{
  //     if(!docs.empty){
  //       docs.forEach((doc)=>{
  //         // let interaktData:any = doc.data();
  //         // let id = interaktData.interaktAccountQueryNumber;

  //         // let delRef = this.firestore.collection('interaktaccounts', ref => ref.where('interaktAccountQueryNumber', '==', id));
  //         doc.ref.delete().then(()=>{
  //           console.log('deleted')
  //         }).catch(err => console.log(err));
  //       })
  //     }
  //   })
  // }


  // // function to delete all bulk upload invite
  // bulkUpload3() {
  //   if (data1 && (typeof data1 === "object")) {
  //     Object.keys(data1).forEach(docKey => {
  //       let inviteData = data1[docKey];

  //       let ref = this.firestore.collection('invitations', ref => ref.where('temporaryParentId', '==', inviteData.bulkUploadAccountId));
  //       ref.get().subscribe((result) => {
  //         if (!result.empty) {
  //           result.forEach((doc) => {
  //             let invitation: any = doc.data();
  //             let delRef = this.firestore.collection('invitations').doc(invitation.id).delete()
  //               .then(() => console.log('invitation deleted for - ', invitation.name))
  //               .catch(err => console.log(`error deleting invitation for - ${invitation.name}`, err))
  //           })
  //         }
  //       })
  //     })
  //   }
  // }


  // // function to delete all bulk upload session
  // bulkUpload4() {
  //   if (data1 && (typeof data1 === "object")) {
  //     Object.keys(data1).forEach(docKey => {
  //       let inviteData = data1[docKey];

  //       let ref = this.firestore.collection('sessions', ref => ref.where('sessionStudentId', '==', inviteData.bulkUploadAccountId));
  //       ref.get().subscribe((result) => {
  //         if (!result.empty) {
  //           result.forEach((doc) => {
  //             let session: any = doc.data();
  //             let delRef = this.firestore.collection('sessions').doc(session.id).delete()
  //               .then(() => console.log('session deleted for - ', session.id))
  //               .catch(err => console.log(`error deleting invitation for - ${session.id}`, err))
  //           })
  //         }
  //       })
  //     })
  //   }
  // }

  // bulkUpload5() {
  // let ref = this.firestore.collection('sessions');
  // ref.get().subscribe((result) => {
  //   if (!result.empty) {
  //     result.forEach((doc) => {
  //       let session: any = doc.data();
  //       let sessionDate:Date = session.sessionDate.toDate();
  //       let hour = sessionDate.getHours();
  //       if(hour != 0){
  //         console.log('hour is ', hour, 'for the session ', session.id);
  //       }
  //     })
  //   }
  // })
  // }


  // bulk upload operation end



  // temporary bulk upload function to save bulk upload new parent start here
  // async saveNewBulkUploadParent(result) {
  //   this.invitationDoc = result;
  //   console.log('save new bulk upload parent is calling');
  //   var usersRef = this.firestore.collection("users");
  //   const id = await firebase.auth().currentUser.uid;
  //   const name = await firebase.auth().currentUser.displayName;
  //   const email = this.invitationDoc.eMail;
  //   const phoneNumber = await firebase.auth().currentUser.phoneNumber;
  //   const createdBy = firebase.auth().currentUser.uid;
  //   const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  //   const temporaryParentId = this.invitationDoc.temporaryParentId;
  //   usersRef.doc(id).set({
  //     temporaryParentId: temporaryParentId,
  //     viaBulkUpload: true,
  //     id: id,
  //     interaktId: result.userId,
  //     name: name,
  //     phoneNumber: phoneNumber,
  //     email: email,
  //     role: 'parent',
  //     myExpertsIds: result.myExpertsIds,
  //     myExperts: result.educators,
  //     sessionPackValue: result.sessionPackValue,
  //     numberOfSessions: result.numberOfSessions,
  //     countryCode: result.whatsappNumberCountryCode,
  //     whatsappNumber: result.whatsappNumber,
  //     verified: false,
  //     registered: false,
  //     createdOn: timestamp,
  //     authenticated: true,
  //     createdBy: createdBy
  //   }).then(this.onParentCreated)
  //     .catch(function (error) {
  //       console.error("Error creating User: ", error);
  //     });
  //   return;
  // }
  // temporary bulk upload function to save bulk upload new parent end here


  // // calling bulk upload function start.
  // if (!invitation.registered && invitation.viaBulkUpload) {
  //   this.saveNewBulkUploadParent(docs[0]);
  // // calling bulk upload function end
  // } else 


  
  // temporary bulk upload function start

  // async updateSessions() {
  //   let currentUser = await this.auth.currentUser;

  //   if (!currentUser) {
  //     this.router.navigateByUrl('parent/signin');
  //     return;
  //   }

  //   this.id = currentUser.uid;
  //   let docRef = this.firestore.collection('users').doc(currentUser.uid);
  //   docRef.get().subscribe(async (result) => {
  //     if (!result.exists) {
  //       console.log('not getting user document')
  //     } else {
  //       let parent: any = result.data();

  //       let sessionRef = this.firestore.collection('sessions', sessionRef => sessionRef.where('sessionStudentId', '==', parent?.temporaryParentId));
  //       sessionRef.get().subscribe((result) => {
  //         if (result.empty) {
  //           console.log('not getting session documents')
  //         } else {
  //           result.forEach(doc => {
  //             let session: any = doc.data();
  //             let additionalUpdate = {};
  //             additionalUpdate['sessionStudentId'] = parent.id;
  //             additionalUpdate['sessionStudentName'] = parent.childName;
  //             additionalUpdate['sessionParentName'] = parent.fullName;

  //             let sesRef = this.firestore.collection('sessions').doc(session.id).update(additionalUpdate).then(() => {
  //               console.log('session successfully updated')
  //             }).catch(err => console.log('error updating session', err));
  //           })
  //         }
  //       })
  //     }
  //   });
  //   await this.getSessions();
  // }

  // temporary bulk upload function end




  // previous function which was managing the session end states and zoom license.
// exports.onZoomMeetingEnded = functions.https.onRequest(async (req, res) => {
  // functions.logger.log("this is the id", JSON.stringify(req.body.payload.object));
  // let sessionIdentifierId;
  // let meetingHostId;
  // let responseObject;
  // let topicID;

  // if (req.body.payload.object) {
  //     responseObject = req.body.payload.object;
  //     functions.logger.log('response object   ', responseObject)
  // }

  // if (req.body.payload.object && req.body.payload.object.host_id) {
  //     meetingHostId = req.body.payload.object.host_id;
  //     functions.logger.log('host_id   ', meetingHostId)
  // }

  // if (req.body.payload.object && req.body.payload.object.uuid) {
  // 	sessionIdentifierId = req.body.payload.object.id;
  // 	functions.logger.log('id   ', sessionIdentifierId)
  // }

  // if (req.body.payload.object && req.body.payload.object.topic) {
  //     topicID = req.body.payload.object.topic;
  //     functions.logger.log('topic   ', topicID)
  // }

  // const ref = db.collection('sessions');
  // const queryRef = ref.where('topicID', '==', topicID).orderBy('sessionEndDateTime', 'asc');
  // const snapShot = await queryRef.get();
  // if (snapShot.empty) {
  //     functions.logger.log('No matching document found');
  //     return;
  // }

  // let session: any;
  // snapShot.forEach(doc => {
  //     session = doc.data();
  // })
  // functions.logger.log('session  ', session)

  // if (session.status == "Cancelled" || session.status == "Rescheduled") {
  //     functions.logger.log('already in the end state');
  //     return;
  // }

  // let slot = session.slotDuration;
  // slot *= 60;

  // let meetingEndTime = new Date(req.body.payload.object.end_time);
  // let meetingEndedAt = new Date(meetingEndTime);

  // let sessionEndTime = session.sessionEndDateTime;
  // let sessionEndSeconds = sessionEndTime.seconds;
  // let sessionStartingSeconds = sessionEndSeconds - slot;


  // let currentTimeStamp: any = admin.firestore.Timestamp.fromDate(meetingEndTime);
  // let sessionStartingTimeStamp: any = admin.firestore.Timestamp.fromMillis(sessionStartingSeconds * 1000);

  // let timeDifference = currentTimeStamp.seconds - sessionStartingTimeStamp.seconds;
  // timeDifference /= 60;
  // functions.logger.log('difference  ', timeDifference)

  // const checkPoint = session.slotDuration - 30;
  // const zoomLicenseCheckPoint = session.slotDuration - 10;
  // functions.logger.log('checkpoint  ', checkPoint);

  // if (timeDifference < 15) {
  //     if (session.attendedBy.parent && session.attendedBy.expert) {
  //         await db.collection('sessions').doc(session.id).update({ status: "Partially Attended", meetingEndedAt: meetingEndedAt });
  //     }
  // } else if (timeDifference >= 15 && timeDifference < checkPoint) {
  //     if (session.attendedBy.parent && !session.attendedBy.expert) {
  //         //releasing the zoom id
  //         if (!session.zoomLicenseReleased) {
  //             await db.collection('sessions').doc(session.id).update({ status: "noShow-Expert", meetingEndedAt: meetingEndedAt, zoomLicenseReleased: true })
  //             const docRef = db.collection('zoomids').doc(meetingHostId);
  //             await docRef.update({ beingUsed: false })
  //         }
  //     } else if (!session.attendedBy.parent && session.attendedBy.expert) {
  //         //releasing the zoom id
  //         if (!session.zoomLicenseReleased) {
  //             await db.collection('sessions').doc(session.id).update({ status: "noShow-Parent", meetingEndedAt: meetingEndedAt, zoomLicenseReleased: true })
  //             const docRef = db.collection('zoomids').doc(meetingHostId);
  //             await docRef.update({ beingUsed: false })
  //         }
  //     } else if (!session.attendedBy.parent && !session.attendedBy.expert) {
  //         await db.collection('sessions').doc(session.id).update({ status: "Skipped", meetingEndedAt: meetingEndedAt })
  //     } else if (session.attendedBy.parent && session.attendedBy.expert) {
  //         await db.collection('sessions').doc(session.id).update({ status: "Partially Attended", meetingEndedAt: meetingEndedAt })
  //     }
  // } else if (timeDifference > checkPoint && timeDifference < zoomLicenseCheckPoint) {
  //     if (session.attendedBy.parent && session.attendedBy.expert) {
  //         await db.collection('sessions').doc(session.id).update({ status: "Attended", meetingEndedAt: meetingEndedAt })
  //     }
  // } else if (timeDifference > zoomLicenseCheckPoint) {
  //     if (session.attendedBy.parent && session.attendedBy.expert) {
  //         //releasing the zoom id
  //         if (!session.zoomLicenseReleased) {
  //             await db.collection('sessions').doc(session.id).update({ status: "Attended", meetingEndedAt: meetingEndedAt, zoomLicenseReleased: true })
  //             const docRef = db.collection('zoomids').doc(meetingHostId);
  //             await docRef.update({ beingUsed: false })
  //         }
  //     }
  // }
// });


// async updateExpertInteraktId(){
  //   let user = await this.auth.currentUser;
  //   if (user) {
  //     let id = user.uid;
  //     if (id == "TxZAULFDg8dCy9KE98FWJyg2cDu2" || id == "vctFitF82fhWwyB5B4DWDTXkrC23" || id == "OHWj6beBLJf1o9eHQlJ1gytPFe53" || id == "FVrol27OopTfVr1sP4psBqBjFvW2") {
  //       let expertRef = this.firestore.collection('users', expertRef => expertRef.where('role', '==', 'expert'));
  //       expertRef.get().subscribe((results) => {
  //         if (!results.empty) {
  //           results.forEach((doc) => {
  //             let expert: any = doc.data();
  //             let interaktId = expert.interaktId;
  //             let phoneNumber = expert.phoneNumber;
  //             let countryCode = expert.countryCode;
  //             let now = firebase.firestore.FieldValue.serverTimestamp()

  //             let interaktUserURL =  'https://api.interakt.ai/v1/public/track/users/';
  //             let httpOptions = {
  //               headers: new HttpHeaders({ 'Content-Type': 'application/json' ,  'Authorization': 'Basic S196Z21ZZVBycnFWRldiaHh1WXdTZ1JmX2NOSVQxRHB3aHVYWGl3aFRsUTo=' })
  //             };

  //             let userBody = {
  //               'userId': interaktId,
  //               'phoneNumber': phoneNumber,
  //               'countryCode': countryCode,
  //               'traits': {
  //                 'idUpdatedOn': now,      
  //               }   
  //             }
          
  //             return this.http.post(interaktUserURL, userBody, httpOptions).toPromise().then(()=>{}).catch((err) => {
  //               console.log(expert.id, "=>", err);
  //             });
             
  //           })
  //         }
  //       })
  //     }
  //   }
  // }


  // async getParentDetails() {
  //   let user = await this.auth.currentUser;
  //   if (user) {
  //     let id = user.uid;
  //     if (id == "TxZAULFDg8dCy9KE98FWJyg2cDu2" || id == "vctFitF82fhWwyB5B4DWDTXkrC23" || id == "OHWj6beBLJf1o9eHQlJ1gytPFe53" || id == "FVrol27OopTfVr1sP4psBqBjFvW2") {
  //       let parentRef = this.firestore.collection('balances', expertRef => expertRef.where('role', '==', 'parent'));
  //       parentRef.get().subscribe((results) => {
  //         if (!results.empty) {
  //           results.forEach((doc) => {
  //             let parent: any = doc.data();
  //             let name = parent.fullName;
  //             let now = new Date();
  //             now.setHours(0, 0, 0, 0);
  //             let count = 0;

  //             if (parent.currentBalance > 0) {
  //               let sessionRef = this.firestore.collection('sessions', sessionRef => sessionRef.where('sessionStudentId', '==', parent.id).where('status', "==", "Scheduled"))
  //               sessionRef.get().subscribe((docs) => {
  //                 if (!docs.empty) {
  //                   docs.forEach((doc) => {
  //                     let session: any = doc.data();
  //                     let sessionDate = session.sessionDate.toDate();
  //                     let difference = sessionDate.getTime() - now.getTime();
  //                     difference /= 3600000;
  //                     difference = Math.floor(difference)
  //                     if (difference < 720){
  //                       count += 1;
  //                     }
  //                   })
  //                   if(count == 0){
  //                     // console.log('parent with session ahead of time range', parent.id);
  //                     let userRef = this.firestore.collection('users').doc(parent.id).get().subscribe((parentDoc)=>{
  //                       if(parentDoc.exists){
  //                         let parentData:any = parentDoc.data();
  //                         console.log('parent name: ', parentData.fullName, " ,", 'child name: ', parentData.childName," ,", 'parent id: ', parent.id," ,", 'current balance ', parent.currentBalance)
  //                       }
  //                     })
  //                   }
  //                 }else{
  //                   // console.log('parent who dont have any session ', parent.id);
  //                   let userRef = this.firestore.collection('users').doc(parent.id).get().subscribe((parentDoc)=>{
  //                     if(parentDoc.exists){
  //                       let parentData:any = parentDoc.data();
  //                       console.log('parent name: ', parentData.fullName, " ,", 'child name: ', parentData.childName," ,", 'parent id: ', parent.id," ,", 'current balance ', parent.currentBalance)
  //                     }
  //                   })
  //                 }
  //               })
  //             }
  //           })
  //         }
  //       })
  //     }
  //   }
  // }

  // function to fix the interakt id mismatch issue
  // correctInteraktIds(){
  //   let ref = this.firestore.collection('interaktaccounts').get().subscribe((results)=>{
  //     results.forEach(doc => {
  //       let interaktDoc:any = doc.data();
  //       let queryNumber = interaktDoc.interaktAccountQueryNumber;

  //       let inviteRef = this.firestore.collection('invitations', ref => ref.where('verificationNumbers', 'array-contains', queryNumber))
  //       inviteRef.get().subscribe((inviteResults)=>{
  //         if(!inviteResults.empty){
  //           inviteResults.forEach(document => {
  //             let invitation:any = document.data()
  //             let currentUserId = invitation.userId;

  //             if(interaktDoc.interaktId != invitation.userId){
  //               console.log('mismatch invite ', invitation.id)
  //               let docref = this.firestore.collection('invitations').doc(invitation.id).update(
  //                 {userId: interaktDoc.interaktId}
  //               ).then(()=>{
  //                 console.log('invitation updated for invitation ', invitation.id)
  //                 let userRef = this.firestore.collection('users', ref => ref.where('interaktId', '==', currentUserId));
  //                 userRef.get().subscribe((userResults)=>{
  //                   if(!userResults.empty){
  //                     userResults.forEach(userDoc => {
  //                       let parent:any = userDoc.data();

  //                       let userDocRef = this.firestore.collection('users').doc(parent.id).update(
  //                         {interaktId: interaktDoc.interaktId}
  //                       ).then(()=>{
  //                         console.log('user updated for invitation ', parent.id)
  //                         let sessionRef = this.firestore.collection('sessions', ref => ref.where('sessionStudentId', '==', parent.id).where('status', '==', 'Scheduled'));
  //                         sessionRef.get().subscribe((sessionResults)=>{
  //                           if(!sessionResults.empty){
  //                             sessionResults.forEach(sessionDoc => {
  //                               let session:any = sessionDoc.data();

  //                               let sessionDocRef = this.firestore.collection('sessions').doc(session.id).update(
  //                                 {parentInteraktId: interaktDoc.interaktId}
  //                               ).then(()=>{
  //                                 console.log('session updated for invitation ', session.id)
  //                               }).catch(err => console.log('error updating session ', session.id, err))
  //                             })
  //                           }
  //                         })
  //                       }).catch(err => console.log('error updating user ', parent.id, err))

  //                     })
  //                   }
  //                 })
  //               }).catch(err => console.log('error updating invitation ', invitation.id, err));
  //             }


  // updateParentStatus(){
  //   console.log('started')
  //   let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'parent'));
  //   ref.get().subscribe(docs => {
  //     if(docs.empty){
  //       console.log('no parent found');
  //       return;
  //     }

  //     console.log('total parents ', docs.size);
  //     let count = 0;

  //     docs.forEach(doc => {
  //       let parent:any = doc.data();
  //       let updateRef = this.firestore.collection('users').doc(parent.id).update({activityStatus:'Active'}).then(()=>{
  //         console.log('successfull for ', parent.id);
  //         count += 1;
  //         console.log(count);
  //       }).catch((err)=>{
  //         console.log('error for parent ', parent.id, err)
  //       })
  //     })
  //   })
  // }


   // bulkUpload2() {
  //   if (data1 && (typeof data1 === "object")) {
  //     let admin: any = this.adminService.adminDoc;
  //     Object.keys(data1).forEach(_key => {
  //       let expert = data1[_key];

  //       let ageGroups = [];
  //       let ageGroupValid:boolean = true;
  //       let ageGroupIndex = 0;
  //       while(ageGroupValid){
  //         if(expert[`ageGroups/${ageGroupIndex}`]){
  //           ageGroups.push(expert[`ageGroups/${ageGroupIndex}`]);
  //           ageGroupIndex += 1;
  //         }else{
  //           ageGroupValid = false;
  //         }
  //       }

  //       let dateOfBirth = firebase.firestore.Timestamp.fromMillis(expert[''])

  //       let document:any = {};
  //       document.countryCode = expert['countryCode'];
  //       document.email = expert['email'];
  //       document.fullName = expert['fullName'];
  //       document.endingAgeRange = expert['endingAgeRange']




  //       let ref = this.firestore.collection('users').add(expert).then(()=>{
  //         console.log('expert added for ', _key)
  //       }).catch(err => console.log('error adding expert for ', _key, err))

  //     })
  //   }
  // }
  // bulkUpload3() {
  //   if (data1 && (typeof data1 === "object")) {
  //     let admin: any = this.adminService.adminDoc;
  //     Object.keys(data1).forEach(_key => {
  //       let expert = data1[_key];

  //       let ref = this.firestore.collection('users', ref => ref.where('id', "==", _key));
  //       ref.get().subscribe((result) => {
  //         if (!result.empty) {
  //           result.forEach((doc) => {
  //             doc.ref.delete()
  //               .then(() => console.log('expert deleted for - ', _key))
  //               .catch(err => console.log(`error deleting expert for - ${_key}`, err))
  //           })
  //         }
  //       })
  //     })
  //   })
  // }



    // // function to export data from firestore into excel sheet.
  // async getSessionDetails() {
  //   this.monthlySessions = [];
  //   this.worksheet = this.workbook.addWorksheet('example-session')

  //   this.worksheet.columns = [
  //     { header: 'sessionDate', key: 'sessionDate' },
  //     { header: 'sessionParentName', key: 'sessionParentName' },
  //     { header: 'sessionStudentName', key: 'sessionStudentName' },
  //     { header: 'phoneNumber', key: 'phoneNumber' },
  //     { header: 'sessionStudentId', key: 'sessionStudentId' },
  //     { header: 'parentTransactionValue', key: 'parentTransactionValue' },
  //     { header: 'expertTransactionValue', key: 'expertTransactionValue' },
  //     { header: 'startTime', key: 'startTime' },
  //     { header: 'slotDuration', key: 'slotDuration' },
  //     { header: 'sessionExpertName', key: 'sessionExpertName' },
  //     { header: 'sessionId', key: 'sessionId' },
  //     { header: 'status', key: 'status' }
  //   ]
  //   this.worksheet.getRow(1).font = { bold: true }

  //   let startingDate = new Date(2022, 4, 1, 0, 0, 0, 0);
  //   let endingDate = new Date(2022, 4, 31, 0, 0, 0, 0);
  //   let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
  //   let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)

  //   const snapshot = this.firestore.collection('sessions', ref => ref.where('sessionDate', ">=", startingTimeStamp).where('sessionDate', "<=", endingTimeStamp).orderBy('sessionDate', 'asc').orderBy('sessionEndDateTime', 'asc'))
  //     .get().toPromise().then(async (results) => {
  //       console.log('total sessions ', results.size);
  //       let count = 1;
  //       results.forEach(async (doc) => {
  //         let session: any = doc.data();

  //         console.log(count, 'session ', session.id)
  //         this.monthlySessions.push(session);
  //         count++;
  //       })
  //     })

  // }

  // tempFunction1() {
  //   this.monthlySessionsWithPhone = [];
  //   let count = 1;
  //   this.monthlySessions.forEach(doc => {
  //     let session: any = doc;

  //     let userRef = this.firestore.collection('users').doc(session.sessionStudentId).get().toPromise().then((parentDoc) => {
  //       let parent: any = parentDoc.data();
  //       console.log(count, 'parent', parent)
  //       session.phoneNumber = parent.phoneNumber;
  //       this.monthlySessionsWithPhone.push(session);
  //       count++;

  //     })
  //   })
  // }

  // tempFunction2() {
  //   this.monthlySessionsWithAllData = [];
  //   let count = 1;
  //   this.monthlySessionsWithPhone.forEach(doc => {
  //     let session: any = doc;

  //     // getting the transaction related to the session.
  //     let transactionRef = this.firestore.collection('transactions', ref => ref.where('sessionId', '==', session.id)).get().toPromise().then(documents => {
  //       let parentTransactionBalance = 0;
  //       let expertTransactionBalance = 0;
  //       documents.forEach(document => {
  //         let transaction: any = document.data();
  //         if (transaction.userId == session.sessionStudentId) {
  //           parentTransactionBalance += transaction.transactionValue;
  //           parentTransactionBalance += transaction.returnedSessionValue;
  //         } else if (transaction.userId == session.sessionExpertId) {
  //           expertTransactionBalance += transaction.transactionValue;
  //         }
  //       })

  //       session.parentTransactionValue = parentTransactionBalance;
  //       session.expertTransactionValue = expertTransactionBalance;

  //       console.log(count);
  //       this.monthlySessionsWithAllData.push(session);
  //       count++;
  //     })
  //   })
  // }

  // tempFunction3() {
  //   let count = 1;
  //   this.monthlySessionsWithAllData.sort(function (x, y) {
  //     return x.sessionEndDateTime - y.sessionEndDateTime
  //   })
  //   this.monthlySessionsWithAllData.forEach(doc => {
  //     let session: any = doc;
      
  //     let excelData: any = {};
  //     let date = new Date(session.sessionDate.toDate())
  //     let correctDate = this.helper.istTime3(date, 0, 0);
  //     let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //     count++;
  //     excelData.sessionDate = localSessionDateString;
  //     excelData.sessionParentName = session.sessionParentName;
  //     excelData.sessionStudentName = session.sessionStudentName;
  //     excelData.sessionStudentId = session.sessionStudentId;
  //     excelData.startTime = session.startTime;
  //     excelData.slotDuration = session.slotDuration;
  //     excelData.sessionExpertName = session.sessionExpertName;
  //     excelData.sessionId = session.id;
  //     excelData.status = session.status;
  //     excelData.phoneNumber = session.phoneNumber;
  //     excelData.parentTransactionValue = session.parentTransactionValue;
  //     excelData.expertTransactionValue = session.expertTransactionValue;

  //     // adding the data as a row in excel sheet
  //     this.worksheet.addRow({
  //       ...excelData,
  //     })
  //   })
  // }

  // addFile() {
  //   let fName = "may-session-data"
  //   this.workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //     console.log('done successfully')
  //   });
  // }
  


    // // function to export data from firestore into excel sheet.
  // async getConsultationDetails() {
  //   this.worksheet = this.workbook.addWorksheet('example-consultation')

  //   this.worksheet.columns = [
  //     { header: 'sessionDate', key: 'sessionDate' },
  //     { header: 'sessionParentName', key: 'sessionParentName' },
  //     { header: 'sessionStudentName', key: 'sessionStudentName' },
  //     { header: 'phoneNumber', key: 'phoneNumber' },
  //     { header: 'expertTransactionValue', key: 'expertTransactionValue' },
  //     { header: 'startTime', key: 'startTime' },
  //     { header: 'sessionExpertName', key: 'sessionExpertName' },
  //     { header: 'sessionId', key: 'sessionId' },
  //     { header: 'status', key: 'status' },
  //     { header: 'POC', key: 'POC' },
  //     { header: 'expertPaidForNoShow', key: 'expertPaidForNoShow' },
  //     { header: 'bookedBy', key: 'bookedBy' }
  //   ]
  //   this.worksheet.getRow(1).font = { bold: true }

  //   let startingDate = new Date(2022, 4, 1, 0, 0, 0, 0);
  //   let endingDate = new Date(2022, 4, 31, 0, 0, 0, 0);
  //   let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
  //   let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)

  //   const snapshot = this.firestore.collection('consultations', ref => ref.where('sessionDate', ">=", startingTimeStamp).where('sessionDate', "<=", endingTimeStamp).orderBy('sessionDate', 'asc').orderBy('sessionEndDateTime', 'asc'))
  //     .get().toPromise().then(results => {
  //       console.log('total sessions ', results.size);
  //       let count = 0;
  //       results.forEach(doc => {
  //         let session: any = doc.data();
  //         console.log('session ', session.id)

  //         let excelData: any = {};
  //         let date = new Date(session.sessionDate.toDate())
  //         let correctDate = this.helper.istTime3(date, 0, 0);
  //         let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })

  //         excelData.sessionDate = localSessionDateString;
  //         excelData.sessionParentName = session.sessionParentName;
  //         excelData.sessionStudentName = session.sessionStudentName;
  //         excelData.phoneNumber = session.parentPhoneNumber;
  //         excelData.startTime = session.startTime;
  //         excelData.sessionExpertName = session.sessionExpertName;
  //         excelData.bookedBy = session.createdByName
  //         excelData.POC = session.salesPocId
  //         excelData.sessionId = session.id;
  //         excelData.status = session.status;
  //         if (session.status == "No-show-by-Parent") {
  //           excelData.expertPaidForNoShow = session.generateTransactionForExpert;
  //         } else {
  //           excelData.expertPaidForNoShow = '-';
  //         }

  //         // getting the transaction related to the session.
  //         let transactionRef = this.firestore.collection('transactions', ref => ref.where('consultationId', '==', session.id)).get().toPromise().then(documents => {
  //           let expertTransactionBalance = 0;

  //           if (documents.empty && session.status == "No-show-by-Parent") {
  //             excelData.expertPaidForNoShow = "No";
  //           }

  //           documents.forEach(document => {
  //             let transaction: any = document.data();
  //             if (transaction.userId == session.sessionExpertId) {
  //               expertTransactionBalance += transaction.transactionValue;
  //             }
  //           })

  //           excelData.expertTransactionValue = expertTransactionBalance;

  //           console.log(count, 'data ', excelData)
  //           // adding the data as a row in excel sheet
  //           this.worksheet.addRow({
  //             ...excelData,
  //           })

  //           count++

  //         })
  //       })

  //     })


  // }

  // addFile() {
  //   let fName = "may-consultation-data"
  //   this.workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //     console.log('done successfully')
  //   });
  // }


  // feedBackNotification() {
  //   this.worksheet = this.workbook.addWorksheet('parent-data');
  //   this.worksheet.columns = [
  //     { header: 'parentId', key: 'parentId' },
  //     { header: 'parentName', key: 'parentName' },
  //     { header: 'childName', key: 'childName' },
  //     { header: 'numberOfExperts', key: 'numberOfExperts' },
  //     { header: 'experts', key: 'experts' },
  //     { header: 'frequency', key: 'frequency' },
  //     { header: 'lastSessionDate', key: 'lastSessionDate' },
  //     { header: 'status', key: 'status' },
  //   ]
  //   this.worksheet.getRow(1).font = { bold: true }
  //   this.Parents = [];

  //   let date = new Date();
  //   date.setHours(0, 0, 0, 0);
  //   date.setDate(date.getDate() - 4)

  //   let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').orderBy('createdOn', 'asc'))
  //   ref.get().subscribe((results) => {
  //     console.log('total parents ', results.size);
  //     let count1 = 1;
  //     let count2 = 1;
  //     results.forEach(doc => {
  //       let parent: any = doc.data();
  //       this.Parents.push(parent);
  //       let educators = [];
  //       educators = parent.myExperts;
  //       let educator: any = parent.myExperts[0];
  //       console.log('count ', count1);
  //       count1++;
  //     })
  //   })
  // }

  // tempFunction1() {
  //   let count = 1;
  //   this.parentwithInvitations = []
  //   this.Parents.forEach(docs => {
  //     let parent: any = docs;

      // let body: any = {};
      // body = {
      //   'userId': parent.interaktId,
      //   'event': 'feedback notification - notify parent',
      //   'traits': {
      //     'Name': parent.fullName,
      //   }
      // }

      // this.notificationService.sendWhatsappNotification(body).then(() => {
      //   parent.receivedStatus = "Received"
      //   this.parentAfterMessage.push(parent);
      // }).catch(err => {
      //   parent.receivedStatus = "Not Received"
      //   this.parentAfterMessage.push(parent);
      // })

  //     let inviteref = this.firestore.collection('invitations', ref => ref.where('verificationNumbers', 'array-contains', parent.phoneNumber))
  //     inviteref.get().subscribe(results => {
  //       if (!results.empty) {
  //         console.log('count ', count);
  //         count++;

  //         let invitation: any;
  //         results.forEach(doc => {
  //           invitation = doc.data();
  //         })

          // let expertsNameString: any = "";
          // let experts = [];
          // experts = invitation.educators;
          // experts.forEach((expert) => {
          //   expertsNameString = expertsNameString + expert.educatorName + ","
          // })

  //         parent.expertsNameString = expertsNameString;
  //         console.log('frequency ', invitation.reccomendedFrequency)
  //         parent.frequencyNumber = invitation.reccomendedFrequency;

  //         this.parentwithInvitations.push(parent);
  //       }
  //     })
  //   })
  // }

  // tempFunctionExtra() {
  //   let count = 1;
  //   this.parentwithInvitationsAndSession = [];
  //   this.parentwithInvitations.forEach(doc => {
  //     let parent: any = doc;
  //     let sessionRef = this.firestore.collection('sessions', ref => ref.where('sessionStudentId', '==', parent.id).orderBy('sessionEndDateTime', 'desc'))
  //     sessionRef.get().subscribe(results => {
  //       console.log('count ', count)
  //       count++;
  //       if (!results.empty) {
  //         let sessions = [];
  //         results.forEach(doc => {
  //           let session: any = doc.data();
  //           sessions.push(session);
  //         })
  //         let lastSession: any = sessions[0];
  //         let lastSessionDate = lastSession.sessionDate.toDate();
  //         let localSessionDateString = lastSessionDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //         parent.lastSessionOn = localSessionDateString;
  //       }
  //       this.parentwithInvitationsAndSession.push(parent);
  //     })
  //   })
  // }


  // tempFunction2() {
  //   let count = 1;
  //   let excelData: any = {};
  //   this.parentwithInvitationsAndSession.forEach(async doc => {
  //     let parent: any = doc;

  //     let expertsNameString: any = "";
  //     let experts = [];
  //     experts = parent.myExperts;
  //     await experts.forEach((expert) => {
  //       expertsNameString = expertsNameString + expert.educatorName + ","
  //     })

  //     excelData.parentId = parent.id;
  //     excelData.parentName = parent.fullName;
  //     excelData.childName = parent.childName;
  //     excelData.numberOfExperts = parent.myExperts.length;
  //     excelData.experts = expertsNameString;
  //     excelData.frequency = parent.frequencyNumber;
  //     excelData.lastSessionDate = parent.lastSessionOn ? parent.lastSessionOn : '-';
  //     excelData.status = parent.activityStatus;



  //     this.worksheet.addRow({
  //       ...excelData,
  //     })

  //     console.log('count ', count);
  //     count++;
  //   })
  // }

  // // ** function to add data in excel file
  // addFile() {
  //   let fName = "parent-data-with-frequency"
  //   this.workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //     console.log('done successfully')
  //   });
  // }


  // createAccount(){
  //   let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'sales-admin'))
  //   ref.get().subscribe(async results => {
  //     results.forEach(async doc => {
  //       let admin:any = doc.data();
  //       await this.newInteraktUserService.createSupportAdminAccount(admin).then(()=>{
  //         console.log('successfull for ', admin.fullName)
  //       }).catch(err => {
  //         console.log('unsuccessfull for ', admin.id, err)
  //       })
  //     })
  //   })
  // }


  // frequency bulk upload
  // bulkUpload1() {
    // if (data1 && (typeof data1 === "object")) {
    //   let dataCount = 1;
    //   Object.keys(data1).forEach(docKey => {
    //     let frequencyData = data1[docKey]

    //     let parentRef = this.firestore.collection('users').doc(docKey);
    //     parentRef.get().subscribe(async result => {
    //       let parent:any = result.data();
          
    //       let educators = [];
    //       let newEducators = [];
    //       educators = parent.myExperts;
    //       let count = 1;

    //       // await educators.forEach(doc => 
    //       for(let i=0; i<educators.length; i++){
    //         let expert:any = educators[i];
    //         expert.reccomendedFrequency = frequencyData[count];
    //         newEducators.push(expert);
    //         count++;
    //       }
    //       // console.log('experts for parent ', frequencyData.parentName, ' are ', newEducators);
          
    //       await parentRef.update({myExperts : newEducators}).then(()=>{
    //         console.log('successfull for parent ', frequencyData.parentName)
    //         console.log('data count ',dataCount)
    //         dataCount++
    //       }).catch(err => {
    //         console.log('error for parent ', frequencyData.parentName, err)
    //         console.log('data count ',dataCount)
    //         dataCount++
    //       })
    //     })

       
    //   });
    // }
  // }



// previous function for getting renewal parents from frontend parent list
// for (let parent of this.parents) {
        //   let renewalCounter = 0;
        //   let scheduledSessions = 0;

        //   // getting the sessions of the parent.
        //   let ref = this.firestore.collection('sessions', ref => ref.where('sessionStudentId', '==', parent['id']).orderBy('sessionEndDateTime', 'asc'))
        //   ref.get().toPromise().then(results => {
        //     if (!results.empty) {
        //       let maxDate = new Date(8640000000000000);
        //       let minDate = new Date(-8640000000000000);
        //       let now = new Date();
        //       let previousSessionMinDate = new Date(-8640000000000000);
        //       const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        //       results.forEach(doc => {
        //         let session: any = doc.data();
        //         let endTime: Date = new Date(session.sessionEndDateTime.toDate());

        //         let sessionDate: Date = new Date(session.sessionDate.toDate());
        //         let dateString = sessionDate.getDate() + " " + monthNames[sessionDate.getMonth()] + ", " + sessionDate.getFullYear() + "; " + session.startTime + " IST";

        //         if(session.status == "Scheduled" && endTime > previousSessionMinDate){
        //           parent['lastScheduledSession'] = dateString;
        //           previousSessionMinDate = new Date(endTime);
        //         }

        //         if (session.status === "Scheduled" && this.isFutureSessionForRenewalList(session)) {
        //           renewalCounter += 1;
        //           scheduledSessions += 1;
        //         }

        //         if (endTime.getTime() <= now.getTime() && endTime.getTime() > minDate.getTime()) {
        //           if(session.status == "Attended"){
        //             parent['lastAttendedSession'] = dateString;
        //           }

        //           parent['lastSession'] = dateString;
        //           minDate = new Date(endTime);
        //         } else if (endTime.getTime() > now.getTime() && endTime.getTime() < maxDate.getTime()) {
        //           parent['nextSession'] = dateString;
        //           maxDate = new Date(endTime);
        //         }
        //       })

        //       parent['scheduledSessions'] = scheduledSessions;
        //     }

        //     this.firestore.doc(`balances/${parent['id']}`).valueChanges().pipe(take(1)).toPromise().then((result: AngularFirestoreDocument) => {
        //       if (result) {
        //         parent['currentBalance'] = result['currentBalance']; //storing parent balance
        //         renewalCounter += result['currentBalance'];;
        //       } else parent['currentBalance'] = "Not Found";

        //       // adding parent to renewal list.
        //       if(renewalCounter <= 3){
        //         this.renewalParents.push(parent);
        //         this.renewalParentsCopy.push(parent);
        //       }

        //     });
        //   })

        // }


      // // function to export data from firestore into excel sheet.
  // async getSessionDetails() {
  //   this.monthlySessions = [];
  //   this.worksheet = this.workbook.addWorksheet('example-session')

  //   this.worksheet.columns = [
  //     { header: 'sessionDate', key: 'sessionDate' },
  //     { header: 'sessionParentName', key: 'sessionParentName' },
  //     { header: 'sessionStudentName', key: 'sessionStudentName' },
  //     { header: 'phoneNumber', key: 'phoneNumber' },
  //     { header: 'sessionStudentId', key: 'sessionStudentId' },
  //     { header: 'parentTransactionValue', key: 'parentTransactionValue' },
  //     { header: 'expertTransactionValue', key: 'expertTransactionValue' },
  //     { header: 'startTime', key: 'startTime' },
  //     { header: 'slotDuration', key: 'slotDuration' },
  //     { header: 'sessionExpertName', key: 'sessionExpertName' },
  //     { header: 'sessionId', key: 'sessionId' },
  //     { header: 'status', key: 'status' }
  //   ]
  //   this.worksheet.getRow(1).font = { bold: true }

  //   let startingDate = new Date(2022, 4, 1, 0, 0, 0, 0);
  //   let endingDate = new Date(2022, 4, 31, 0, 0, 0, 0);
  //   let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
  //   let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)

  //   const snapshot = this.firestore.collection('sessions', ref => ref.where('sessionDate', ">=", startingTimeStamp).where('sessionDate', "<=", endingTimeStamp).orderBy('sessionDate', 'asc').orderBy('sessionEndDateTime', 'asc'))
  //     .get().toPromise().then(async (results) => {
  //       console.log('total sessions ', results.size);
  //       let count = 1;
  //       results.forEach(async (doc) => {
  //         let session: any = doc.data();

  //         console.log(count, 'session ', session.id)
  //         this.monthlySessions.push(session);
  //         count++;
  //       })
  //     })

  // }

  // tempFunction1() {
  //   this.monthlySessionsWithPhone = [];
  //   let count = 1;
  //   this.monthlySessions.forEach(doc => {
  //     let session: any = doc;

  //     let userRef = this.firestore.collection('users').doc(session.sessionStudentId).get().toPromise().then((parentDoc) => {
  //       let parent: any = parentDoc.data();
  //       console.log(count, 'parent', parent)
  //       session.phoneNumber = parent.phoneNumber;
  //       this.monthlySessionsWithPhone.push(session);
  //       count++;

  //     })
  //   })
  // }

  // tempFunction2() {
  //   this.monthlySessionsWithAllData = [];
  //   let count = 1;
  //   this.monthlySessionsWithPhone.forEach(doc => {
  //     let session: any = doc;

  //     // getting the transaction related to the session.
  //     let transactionRef = this.firestore.collection('transactions', ref => ref.where('sessionId', '==', session.id)).get().toPromise().then(documents => {
  //       let parentTransactionBalance = 0;
  //       let expertTransactionBalance = 0;
  //       documents.forEach(document => {
  //         let transaction: any = document.data();
  //         if (transaction.userId == session.sessionStudentId) {
  //           parentTransactionBalance += transaction.transactionValue;
  //           parentTransactionBalance += transaction.returnedSessionValue;
  //         } else if (transaction.userId == session.sessionExpertId) {
  //           expertTransactionBalance += transaction.transactionValue;
  //         }
  //       })

  //       session.parentTransactionValue = parentTransactionBalance;
  //       session.expertTransactionValue = expertTransactionBalance;

  //       console.log(count);
  //       this.monthlySessionsWithAllData.push(session);
  //       count++;
  //     })
  //   })
  // }

  // tempFunction3() {
  //   let count = 1;
  //   this.monthlySessionsWithAllData.sort(function (x, y) {
  //     return x.sessionEndDateTime - y.sessionEndDateTime
  //   })
  //   this.monthlySessionsWithAllData.forEach(doc => {
  //     let session: any = doc;
      
  //     let excelData: any = {};
  //     let date = new Date(session.sessionDate.toDate())
  //     let correctDate = this.helper.istTime3(date, 0, 0);
  //     let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //     count++;
  //     excelData.sessionDate = localSessionDateString;
  //     excelData.sessionParentName = session.sessionParentName;
  //     excelData.sessionStudentName = session.sessionStudentName;
  //     excelData.sessionStudentId = session.sessionStudentId;
  //     excelData.startTime = session.startTime;
  //     excelData.slotDuration = session.slotDuration;
  //     excelData.sessionExpertName = session.sessionExpertName;
  //     excelData.sessionId = session.id;
  //     excelData.status = session.status;
  //     excelData.phoneNumber = session.phoneNumber;
  //     excelData.parentTransactionValue = session.parentTransactionValue;
  //     excelData.expertTransactionValue = session.expertTransactionValue;

  //     // adding the data as a row in excel sheet
  //     this.worksheet.addRow({
  //       ...excelData,
  //     })
  //   })
  // }

  // addFile() {
  //   let fName = "may-session-data"
  //   this.workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //     console.log('done successfully')
  //   });
  // }
  


    // // function to export data from firestore into excel sheet.
  // async getConsultationDetails() {
  //   this.worksheet = this.workbook.addWorksheet('example-consultation')

  //   this.worksheet.columns = [
  //     { header: 'sessionDate', key: 'sessionDate' },
  //     { header: 'sessionParentName', key: 'sessionParentName' },
  //     { header: 'sessionStudentName', key: 'sessionStudentName' },
  //     { header: 'phoneNumber', key: 'phoneNumber' },
  //     { header: 'expertTransactionValue', key: 'expertTransactionValue' },
  //     { header: 'startTime', key: 'startTime' },
  //     { header: 'sessionExpertName', key: 'sessionExpertName' },
  //     { header: 'sessionId', key: 'sessionId' },
  //     { header: 'status', key: 'status' },
  //     { header: 'POC', key: 'POC' },
  //     { header: 'expertPaidForNoShow', key: 'expertPaidForNoShow' },
  //     { header: 'bookedBy', key: 'bookedBy' }
  //   ]
  //   this.worksheet.getRow(1).font = { bold: true }

  //   let startingDate = new Date(2022, 4, 1, 0, 0, 0, 0);
  //   let endingDate = new Date(2022, 4, 31, 0, 0, 0, 0);
  //   let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
  //   let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)

  //   const snapshot = this.firestore.collection('consultations', ref => ref.where('sessionDate', ">=", startingTimeStamp).where('sessionDate', "<=", endingTimeStamp).orderBy('sessionDate', 'asc').orderBy('sessionEndDateTime', 'asc'))
  //     .get().toPromise().then(results => {
  //       console.log('total sessions ', results.size);
  //       let count = 0;
  //       results.forEach(doc => {
  //         let session: any = doc.data();
  //         console.log('session ', session.id)

  //         let excelData: any = {};
  //         let date = new Date(session.sessionDate.toDate())
  //         let correctDate = this.helper.istTime3(date, 0, 0);
  //         let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })

  //         excelData.sessionDate = localSessionDateString;
  //         excelData.sessionParentName = session.sessionParentName;
  //         excelData.sessionStudentName = session.sessionStudentName;
  //         excelData.phoneNumber = session.parentPhoneNumber;
  //         excelData.startTime = session.startTime;
  //         excelData.sessionExpertName = session.sessionExpertName;
  //         excelData.bookedBy = session.createdByName
  //         excelData.POC = session.salesPocId
  //         excelData.sessionId = session.id;
  //         excelData.status = session.status;
  //         if (session.status == "No-show-by-Parent") {
  //           excelData.expertPaidForNoShow = session.generateTransactionForExpert;
  //         } else {
  //           excelData.expertPaidForNoShow = '-';
  //         }

  //         // getting the transaction related to the session.
  //         let transactionRef = this.firestore.collection('transactions', ref => ref.where('consultationId', '==', session.id)).get().toPromise().then(documents => {
  //           let expertTransactionBalance = 0;

  //           if (documents.empty && session.status == "No-show-by-Parent") {
  //             excelData.expertPaidForNoShow = "No";
  //           }

  //           documents.forEach(document => {
  //             let transaction: any = document.data();
  //             if (transaction.userId == session.sessionExpertId) {
  //               expertTransactionBalance += transaction.transactionValue;
  //             }
  //           })

  //           excelData.expertTransactionValue = expertTransactionBalance;

  //           console.log(count, 'data ', excelData)
  //           // adding the data as a row in excel sheet
  //           this.worksheet.addRow({
  //             ...excelData,
  //           })

  //           count++

  //         })
  //       })

  //     })


  // }

  // addFile() {
  //   let fName = "may-consultation-data"
  //   this.workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //     console.log('done successfully')
  //   });
  // }



  // async getParentDetails() {
  //     this.parents = [];
  //     this.worksheet = this.workbook.addWorksheet('parents-details')
  
  //     this.worksheet.columns = [
  //       { header: 'userId', key: 'userId' },
  //       { header: 'createdOn', key: 'createdOn' },
  //       { header: 'parentName', key: 'parentName' },
  //       { header: 'childName', key: 'childName' },
  //       { header: 'status', key: 'status' },
  //       { header: 'experts', key: 'experts' },
  //       { header: 'registered', key: 'registered' },
  //       { header: 'countryCode', key: 'countryCode' },
  //       { header: 'phoneNumber', key: 'phoneNumber' },
  //       { header: 'whatsappNumber', key: 'whatsappNumber' },
  //       { header: 'lastAttendedSession', key: 'lastAttendedSession' },
  //       { header: 'lastScheduledSession', key: 'lastScheduledSession' },
  //       { header: 'totalScheduledSessions', key: 'totalScheduledSessions' },
  //       { header: 'currentBalance', key: 'currentBalance' },
  //     ]
  //     this.worksheet.getRow(1).font = { bold: true }
  
  //     const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent"));
  //     parentRef.get().toPromise().then(async (results) => {
  //       console.log('total parents ', results.size);
  //       let count = 1;
  //       results.forEach(async (doc) => {
  //         let parent: any = doc.data();
  
  //         console.log(count, 'parent ', parent.id)
  //         this.parents.push(parent);
  //         count++;
  //       })
  //     })
  
  //   }
  
  //   tempFunction1() {
  //     this.parentsWithSessions = [];
  //     let count = 1;
  
  //     this.parents.forEach(doc => {
  //       let parent: any = doc;
  //       let totalScheduledSessions = 0;
  
  //       let sessionRef = this.firestore.collection('sessions', ref => ref.where('sessionStudentId', '==', parent.id).orderBy('sessionEndDateTime', 'asc'));
  //       sessionRef.get().toPromise().then(results => {
  
  //         if (!results.empty) {
  //           let maxDate = new Date(8640000000000000);
  //           let minDate = new Date(-8640000000000000);
  //           let now = new Date();
  //           let previousSessionMinDate = new Date(-8640000000000000);
  //           const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
  //           results.forEach(doc => {
  //             let session: any = doc.data();
  //             let endTime: Date = new Date(session.sessionEndDateTime.toDate());
  
  //             let sessionDate: Date = new Date(session.sessionDate.toDate());
  //             let correctDate = this.helper.istTime3(sessionDate, 0, 0);
  //             let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //             let dateStringInParts = localSessionDateString.split(',');
  //             let dateString = dateStringInParts[0];
  
  //             // let sessionDate: Date = new Date(session.sessionDate.toDate());
  //             // let dateString = sessionDate.getDate() + " " + monthNames[sessionDate.getMonth()] + ", " + sessionDate.getFullYear() + "; " + session.startTime + " IST";
  
  //             if (session.status == "Scheduled" && endTime > previousSessionMinDate) {
  //               parent['lastScheduledSession'] = dateString;
  //               previousSessionMinDate = new Date(endTime);
  //             }
  
  //             if (session.status === "Scheduled" && this.isFutureSessionForRenewalList(session)) {
  //               totalScheduledSessions += 1;
  //             }
  
  //             if (endTime.getTime() <= now.getTime() && endTime.getTime() > minDate.getTime()) {
  //               if (session.status == "Attended") {
  //                 parent['lastAttendedSession'] = dateString;
  //               }
  
  //               parent['lastSession'] = dateString;
  //               minDate = new Date(endTime);
  //             } else if (endTime.getTime() > now.getTime() && endTime.getTime() < maxDate.getTime()) {
  //               parent.nextSession = dateString;
  //               maxDate = new Date(endTime);
  //             }
  //           })
  
  //         }
  
  //         parent.totalScheduledSessions = totalScheduledSessions;
  //         console.log(count);
  //         this.parentsWithSessions.push(parent);
  //         count++;
  //       })
  //     })
  //   }
  
  //   // function to check if the session is a past session or future session than current time.
  //   isFutureSessionForRenewalList(session) {
  //     let sessionDate: Date = session.sessionDate.toDate();
  //     let exactSessionDate = this.helper.istTime2(sessionDate);
  
  //     // startTime is HH:MM
  //     let startTime = session.startTime.split(":");
  //     const startingHour = parseInt(startTime[0], 10);
  //     const startingMinute = parseInt(startTime[1], 10);
  
  //     //Convert start time to minutes and add to session date to get exact start date time.
  //     exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute);
  
  //     let currentTime = new Date();
  //     let now = this.helper.istTime(currentTime);
  
  //     // if the session start time is greater than the current time.
  //     if (exactSessionDate.getTime() > now.getTime()) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  
  //   tempFunction2() {
  //     this.parentsWithAllData = [];
  //     let count = 1;
  //     this.parentsWithSessions.forEach(doc => {
  //       let parent: any = doc;
  //       let currentBalance: any;
  
  //       let balRef = this.firestore.collection('balances').doc(parent.id);
  //       balRef.get().toPromise().then((balanceDoc) => {
  //         count++;
  //         if (balanceDoc.exists) {
  //           let balanceData: any = balanceDoc.data();
  //           console.log(count, 'parent', balanceData)
  
  //           parent.currentBalance = balanceData.currentBalance;
  //           this.parentsWithAllData.push(parent);
  //         } else {
  //           parent.currentBalance = "not found";
  //           this.parentsWithAllData.push(parent);
  //         }
  //       })
  
  //     })
  //   }
  
  //   tempFunction3() {
  //     let count = 1;
  //     this.parentsWithAllData.sort(function (x, y) {
  //       return x.fullName - y.fullName
  //     })
  
  //     this.parentsWithAllData.forEach(doc => {
  //       count++;
  //       let parent: any = doc;
  
  //       let excelData: any = {};
  //       let date = new Date(parent.createdOn.toDate())
  //       let correctDate = this.helper.istTime3(date, 0, 0);
  //       let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //       let dateStringInParts = localSessionDateString.split(',');
  //       let onlyDateString = dateStringInParts[0];
  
  //       let expertsNameString: any = "";
  //       let experts = [];
  //       experts = parent.myExperts;
  //       // console.log('experts ', experts)
  //       experts.forEach((expert) => {
  //         let teacher: any = expert;
  //         // console.log('teacher ', teacher)
  //         expertsNameString = expertsNameString + teacher.educatorName + ","
  //       })
  
  //       excelData.userId = parent.id;
  //       excelData.createdOn = onlyDateString;
  //       excelData.parentName = parent.fullName;
  //       excelData.childName = parent.childName;
  //       excelData.status = parent.activityStatus;
  //       excelData.experts = expertsNameString;
  //       excelData.registered = parent.registered;
  //       excelData.countryCode = parent.countryCode;
  //       excelData.phoneNumber = parent.phoneNumber;
  //       excelData.whatsappNumber = parent.whatsappNumber;
  //       excelData.lastAttendedSession = parent.lastAttendedSession;
  //       excelData.lastScheduledSession = parent.lastScheduledSession;
  //       excelData.totalScheduledSessions = parent.totalScheduledSessions;
  //       excelData.currentBalance = parent.currentBalance;
  
  
  //       // adding the data as a row in excel sheet
  //       this.worksheet.addRow({
  //         ...excelData,
  //       })
  //     })
  //   }
  
  //   addFile() {
  //     let fName = "parents-data"
  //     this.workbook.xlsx.writeBuffer().then((data) => {
  //       let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //       fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //       console.log('done successfully')
  //     });
  //   }




  // async getParentDetails() {
  //   this.parents = [];
  //   this.worksheet = this.workbook.addWorksheet('experts-details')

  //   this.worksheet.columns = [
  //     { header: 'userId', key: 'userId' },
  //     { header: 'createdOn', key: 'createdOn' },
  //     { header: 'expertName', key: 'expertName' },
  //     { header: 'status', key: 'status' },
  //     { header: 'registered', key: 'registered' },
  //     { header: 'countryCode', key: 'countryCode' },
  //     { header: 'phoneNumber', key: 'phoneNumber' },
  //     { header: 'lastAttendedSession', key: 'lastAttendedSession' },
  //     { header: 'lastScheduledSession', key: 'lastScheduledSession' },
  //     { header: 'totalScheduledSessions', key: 'totalScheduledSessions' },
  //   ]
  //   this.worksheet.getRow(1).font = { bold: true }

  //   const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "expert"));
  //   parentRef.get().toPromise().then(async (results) => {
  //     console.log('total parents ', results.size);
  //     let count = 1;
  //     results.forEach(async (doc) => {
  //       let parent: any = doc.data();

  //       console.log(count, 'parent ', parent.id)
  //       if(parent.verified == true){
  //         this.parents.push(parent);
  //       }
  //       count++;
  //     })
  //   })

  // }

  // tempFunction2() {
  //   this.parentsWithAllData = [];
  //   let count = 1;
    
  //   this.parents.forEach(doc => {
  //     let parent: any = doc;
  //     let totalScheduledSessions = 0;

  //     let sessionRef = this.firestore.collection('sessions', ref => ref.where('sessionExpertId', '==', parent.id));
  //     sessionRef.get().toPromise().then(results => {

  //       if (!results.empty) {
  //         let maxDate = new Date(8640000000000000);
  //         let minDate = new Date(-8640000000000000);
  //         let now = new Date();
  //         let previousSessionMinDate = new Date(-8640000000000000);
  //         const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  //         results.forEach(doc => {
  //           let session: any = doc.data();
  //           let endTime: Date = new Date(session.sessionEndDateTime.toDate());

  //           let sessionDate: Date = new Date(session.sessionDate.toDate());
  //           let correctDate = this.helper.istTime3(sessionDate, 0, 0);
  //           let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //           let dateStringInParts = localSessionDateString.split(',');
  //           let dateString = dateStringInParts[0];

  //           // let dateString = sessionDate.getDate() + " " + monthNames[sessionDate.getMonth()] + ", " + sessionDate.getFullYear() + "; " + session.startTime + " IST";

  //           if (session.status == "Scheduled" && endTime > previousSessionMinDate) {
  //             parent.lastScheduledSession = dateString;
  //             previousSessionMinDate = new Date(endTime);
  //           }

  //           if (session.status === "Scheduled" && this.isFutureSessionForRenewalList(session)) {
  //             totalScheduledSessions += 1;
  //           }

  //           if (endTime.getTime() <= now.getTime() && endTime.getTime() > minDate.getTime()) {
  //             if (session.status == "Attended") {
  //               parent.lastAttendedSession = dateString;
  //             }

  //             parent.lastSession = dateString;
  //             minDate = new Date(endTime);
  //           } else if (endTime.getTime() > now.getTime() && endTime.getTime() < maxDate.getTime()) {
  //             parent.nextSession = dateString;
  //             maxDate = new Date(endTime);
  //           }
  //         })

  //       } else {
  //         parent.lastAttendedSession = '-';
  //         parent.lastScheduledSession = '-';
  //       }

  //       parent.totalScheduledSessions = totalScheduledSessions;
  //       console.log(count);
  //       this.parentsWithAllData.push(parent);
  //       count++;
  //     }).catch(err => {
  //       console.log('error ', err);
  //       parent.lastAttendedSession = '-';
  //       parent.lastScheduledSession = '-';
  //       parent.totalScheduledSessions = totalScheduledSessions;
  //       console.log(count);
  //       this.parentsWithAllData.push(parent);
  //       count++;
  //     })
  //   })
  // }

  // // function to check if the session is a past session or future session than current time.
  // isFutureSessionForRenewalList(session) {
  //   let sessionDate: Date = session.sessionDate.toDate();
  //   let exactSessionDate = this.helper.istTime2(sessionDate);

  //   // startTime is HH:MM
  //   let startTime = session.startTime.split(":");
  //   const startingHour = parseInt(startTime[0], 10);
  //   const startingMinute = parseInt(startTime[1], 10);

  //   //Convert start time to minutes and add to session date to get exact start date time.
  //   exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute);

  //   let currentTime = new Date();
  //   let now = this.helper.istTime(currentTime);

  //   // if the session start time is greater than the current time.
  //   if (exactSessionDate.getTime() > now.getTime()) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // tempFunction3() {
  //   let count = 1;
  //   this.parentsWithAllData.sort(function (x, y) {
  //     return x.fullName - y.fullName
  //   })

  //   this.parentsWithAllData.forEach(doc => {
  //     count++;
  //     let parent: any = doc;

  //     let excelData: any = {};
  //     let date = new Date(parent.createdOn.toDate())
  //     let correctDate = this.helper.istTime3(date, 0, 0);
  //     let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //     let dateStringInParts = localSessionDateString.split(',');
  //     let onlyDateString = dateStringInParts[0];

  //     excelData.userId = parent.id;
  //     excelData.createdOn = onlyDateString;
  //     excelData.expertName = parent.fullName;
  //     excelData.status = parent.activityStatus;
  //     excelData.registered = parent.registered;
  //     excelData.countryCode = parent.countryCode;
  //     excelData.phoneNumber = parent.phoneNumber;
  //     excelData.lastAttendedSession = parent.lastAttendedSession;
  //     excelData.lastScheduledSession = parent.lastScheduledSession;
  //     excelData.totalScheduledSessions = parent.totalScheduledSessions;


  //     // adding the data as a row in excel sheet
  //     this.worksheet.addRow({
  //       ...excelData,
  //     })
  //   })
  // }

  // addFile() {
  //   let fName = "experts-data"
  //   this.workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //     console.log('done successfully')
  //   });
  // }



  // async getParentDetails() {
  //     this.parents = [];
  //     this.worksheet = this.workbook.addWorksheet('parents-details')
  
  //     this.worksheet.columns = [
  //       { header: 'userId', key: 'userId' },
  //       { header: 'role', key: 'role' },
  //       { header: 'activityStatus', key: 'activityStatus' },
  //       { header: 'ageRange', key: 'ageRange' },
  //       { header: 'authenticated', key: 'authenticated' },
  //       { header: 'registered', key: 'registered' },
  //       { header: 'verified', key: 'verified' },
  //       { header: 'childName', key: 'childName' },
  //       { header: 'conditions', key: 'conditions' },
  //       { header: 'otherConditions', key: 'otherConditions' },
  //       { header: 'countryCode', key: 'countryCode' },
  //       { header: 'phoneNumber', key: 'phoneNumber' },
  //       { header: 'whatsappNumber', key: 'whatsappNumber' },
  //       { header: 'createdOn', key: 'createdOn' },
  //       { header: 'email', key: 'email' },
  //       { header: 'interaktId', key: 'interaktId' },
  //       { header: 'languages', key: 'languages' },
  //       { header: 'location', key: 'location' },
  //       { header: 'myExperts', key: 'myExperts' },
  //       { header: 'numberOfSessions', key: 'numberOfSessions' },
  //       { header: 'sessionPackValue', key: 'sessionPackValue' },
  //       { header: 'parentName', key: 'parentName' },
  //     ]
  //     this.worksheet.getRow(1).font = { bold: true }
  
  //     const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent"));
  //     parentRef.get().toPromise().then(async (results) => {
  //       console.log('total parents ', results.size);
  //       let count = 1;
  //       results.forEach(async (doc) => {
  //         let parent: any = doc.data();
  
  //         console.log(count, 'parent ', parent.id)
  //         this.parents.push(parent);
  //         count++;
  //       })
  //     })
  
  //   }
  
  
  //   tempFunction3() {
  //     let count = 1;
  //     this.parents.sort(function (x, y) {
  //       return x.fullName - y.fullName
  //     })
  
  //     this.parents.forEach(doc => {
  //       count++;
  //       let parent: any = doc;
  
  //       let excelData: any = {};
  //       let date = new Date(parent.createdOn.toDate())
  //       let correctDate = this.helper.istTime3(date, 0, 0);
  //       let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  //       let dateStringInParts = localSessionDateString.split(',');
  //       let onlyDateString = dateStringInParts[0];
  
  //       let expertsDetailsString: any = "";
  //       let experts = [];
  //       experts = parent?.myExperts;
  //       let counts = 'Expert';
  //       let index = 0;
  //       let expertsDetailsContainer = [];
  
  //       experts.forEach((expert) => {
  //         expertsDetailsString = "";
  //         let correctIndex = index + 1;
  //         let expertCount = counts + correctIndex;
  //         index++;
  
  //         let teacher: any = expert;
  //         expertsDetailsString = expertCount + "=> " + teacher?.educatorId + ', ';
  
  //         let services = [];
  //         services = teacher?.service;
  
  //         for(let i=0; i<services.length; i++){
  //           let service = services[i];
  
  //           expertsDetailsString = expertsDetailsString + service + ', ';
  //         }
  
  //         expertsDetailsString = expertsDetailsString + teacher?.reccomendedFrequency + ', ' + teacher?.educatorName;
  
  //         expertsDetailsContainer.push(expertsDetailsString);
  //       })
  
  
  //       excelData.userId = parent?.id;
  //       excelData.role = parent?.role;
  //       excelData.activityStatus = parent?.activityStatus;
  //       excelData.ageRange = parent?.ageRange;
  //       excelData.authenticated = parent?.authenticated;
  //       excelData.registered = parent?.registered;
  //       excelData.verified = parent?.verified;
  //       excelData.childName = parent?.childName;
  //       excelData.conditions = parent?.conditions;
  //       excelData.otherConditions = parent?.otherConditions;
  //       excelData.countryCode = parent?.countryCode;
  //       excelData.phoneNumber = parent?.phoneNumber;
  //       excelData.whatsappNumber = parent?.whatsappNumber;
  //       excelData.createdOn = onlyDateString;
  //       excelData.email = parent?.email;
  //       excelData.interaktId = parent?.interaktId;
  //       excelData.languages = parent?.languages;
  //       excelData.location = parent?.location;
  //       excelData.numberOfSessions = parent?.numberOfSessions;
  //       excelData.sessionPackValue = parent?.sessionPackValue;
  //       excelData.myExperts = parent?.myExperts;
  //       // excelData.myExperts = expertsDetailsContainer;
  //       excelData.parentName = parent?.fullName;
  
  
  
  //       // adding the data as a row in excel sheet
  //       this.worksheet.addRow({
  //         ...excelData,
  //       })
  //     })
  //   }
  
  //   addFile() {
  //     let fName = "parents-data"
  //     this.workbook.xlsx.writeBuffer().then((data) => {
  //       let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //       fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
  //       console.log('done successfully')
  //     });
  //   }




    // async getParentDetails() {
    //     this.parents = [];
    //     this.worksheet = this.workbook.addWorksheet('parents-details')
    
    //     this.worksheet.columns = [
    //       { header: 'id', key: 'id' },
    //       { header: 'status', key: 'status' },
    //       { header: 'bookedWithMultipleSessions', key: 'bookedWithMultipleSessions' },
    //       { header: 'multiSessionBatchId', key: 'multiSessionBatchId' },
    //       { header: 'createdByName', key: 'createdByName' },
    //       { header: 'createdOn', key: 'createdOn' },
    //       { header: 'createdWithName', key: 'createdWithName' },
    //       { header: 'sessionDate', key: 'sessionDate' },
    //       { header: 'sessionEndDateTime', key: 'sessionEndDateTime' },
    //       { header: 'startTime', key: 'startTime' },
    //       { header: 'endTime', key: 'endTime' },
    //       { header: 'expertPaid', key: 'expertPaid' },
    //       { header: 'serviceType', key: 'serviceType' },
    //       { header: 'sessionExpertName', key: 'sessionExpertName' },
    //       { header: 'sessionParentName', key: 'sessionParentName' },
    //       { header: 'sessionStudentName', key: 'sessionStudentName' },
    //       { header: 'sessionStudentId', key: 'sessionStudentId' },
    //       { header: 'sessionValue', key: 'sessionValue' },
    //       { header: 'slotDuration', key: 'slotDuration' },
    //       { header: 'createdBy', key: 'createdBy' },
    //       { header: 'attendedBy', key: 'attendedBy' },
    //       { header: 'cancelledByName', key: 'cancelledByName' },
    //       { header: 'cancelledOn', key: 'cancelledOn' },
    //       { header: 'CancellingPerson', key: 'CancellingPerson' },
    //       { header: 'meetingEndedAt', key: 'meetingEndedAt' },
    //       { header: 'meetingHostEmail', key: 'meetingHostEmail' },
    
    //     ]
    //     this.worksheet.getRow(1).font = { bold: true }
    
    //     const parentRef = this.firestore.collection('sessions');
    //     parentRef.get().toPromise().then(async (results) => {
    //       console.log('total parents ', results.size);
    //       let count = 1;
    //       results.forEach(async (doc) => {
    //         let parent: any = doc.data();
    
    //         console.log(count, 'parent ', parent.id)
    //         this.parents.push(parent);
    //         count++;
    //       })
    //     })
    
    //   }
    
    
    //   tempFunction3() {
    //     let count = 1;
    //     this.parents.sort(function (x, y) {
    //       return x.fullName - y.fullName
    //     })
    
    //     this.parents.forEach(doc => {
    //       count++;
    //       let parent: any = doc;
    
    //       let excelData: any = {};
    
    //       let date = new Date(parent.createdOn.toDate())
    //       let correctDate = this.helper.istTime3(date, 0, 0);
    //       let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    //       let dateStringInParts = localSessionDateString.split(',');
    //       let onlyDateString = dateStringInParts[0];
    
    //       let onlyDateStringOfBirth = '';
    //       let dateOfBirthStamp: Timestamp;
    //       dateOfBirthStamp = parent?.sessionDate;
    //       if(dateOfBirthStamp){
    //         let dateOfBirth = new Date(dateOfBirthStamp.toDate())
    //         let correctDateOfBirth = this.helper.istTime3(dateOfBirth, 0, 0);
    //         let localSessionDateStringOfBirth = correctDateOfBirth.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    //         let dateStringInPartsOfBirth = localSessionDateStringOfBirth.split(',');
    //         onlyDateStringOfBirth = dateStringInPartsOfBirth[0];
    //       }
    
    //       let onlyDateStringOfBirth2 = '';
    //       let dateOfBirthStamp2: Timestamp;
    //       dateOfBirthStamp2 = parent?.sessionEndDateTime;
    //       if(dateOfBirthStamp2){
    //         let dateOfBirth2 = new Date(dateOfBirthStamp2.toDate())
    //         let correctDateOfBirth2 = this.helper.istTime3(dateOfBirth2, 0, 0);
    //         let localSessionDateStringOfBirth2 = correctDateOfBirth2.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    //         let dateStringInPartsOfBirth2 = localSessionDateStringOfBirth2.split(',');
    //         onlyDateStringOfBirth2 = dateStringInPartsOfBirth2[0];
    //       }
    
    
    //       excelData.id = parent?.id;
    //       excelData.status = parent?.status;
    //       excelData.bookedWithMultipleSessions = parent?.bookedWithMultipleSessions;
    //       excelData.multiSessionBatchId = parent?.multiSessionBatchId;
    //       excelData.createdByName = parent?.createdByName;
    //       excelData.createdOn = onlyDateString;
    //       excelData.createdWithName = parent?.createdWithName;
    //       excelData.sessionDate = onlyDateStringOfBirth;
    //       excelData.sessionEndDateTime = onlyDateStringOfBirth2;
    //       excelData.startTime = parent?.startTime;
    //       excelData.endTime = parent?.endTime;
    //       excelData.expertPaid = parent?.expertPaid;
    //       excelData.serviceType = parent?.serviceType;
    //       excelData.sessionExpertName = parent?.sessionExpertName;
    //       excelData.sessionParentName = parent?.sessionParentName;
    //       excelData.sessionStudentName = parent?.sessionStudentName;
    //       excelData.sessionStudentId = parent?.sessionStudentId;
    //       excelData.sessionValue = parent?.sessionValue;
    //       excelData.slotDuration = parent?.slotDuration;
    //       excelData.createdBy = parent?.createdBy,
    //       excelData.attendedBy = parent?.attendedBy;
    //       excelData.cancelledByName = parent?.cancelledByName;
    //       excelData.cancelledOn = parent?.cancelledOn;
    //       excelData.CancellingPerson = parent?.CancellingPerson;
    //       excelData.meetingEndedAt = parent?.meetingEndedAt;
    //       excelData.meetingHostEmail = parent?.meetingHostEmail;
    
    
    //       // adding the data as a row in excel sheet
    //       this.worksheet.addRow({
    //         ...excelData,
    //       })
    //     })
    //   }
    
    //   addFile() {
    //     let fName = "session-data"
    //     this.workbook.xlsx.writeBuffer().then((data) => {
    //       let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //       fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
    //       console.log('done successfully')
    //     });
    //   }




    // bulkUpload4() {
  //   if (data2 && (typeof data2 === "object")) {
  //     Object.keys(data2).forEach(docKey => {
  //       let inviteData = data2[docKey];
  //       let status = inviteData['New Status']
  //       // console.log('user ', docKey, status)

  //       let ref = this.firestore.collection('users').doc(docKey).update({activityStatus:status}).then(()=>{
  //         console.log('status updated for user ', docKey)
  //       }).catch(err => {
  //         console.log('error for user ', docKey, err)
  //       })
        
  //     })
  //   }
  // }



   // // send notification to active parents
  // sendNotification() {

  //   let hashTable: any = {};
  //   if (data1 && (typeof data1 === "object")) {
  //     let count=0;
  //     Object.keys(data1).forEach(docKey => {
  //       console.log(count);
  //       count++;
  //       let parentData: any = data1[docKey]
  //       // console.log(parentData['User Id'])
  //       if (!hashTable[parentData['User Id']]) {
  //         hashTable[parentData['User Id']] = true;
  //       }
  //     });
  //   }


  //   let ref = this.firestore.collection('users', ref => ref.where('activityStatus', '==', 'Active'));
  //   ref.get().subscribe(results => {
  //     console.log(results.size)
  //     results.forEach(async (doc) => {
  //       let parent: any = doc.data();
  //       let body: any = {};

  //       if (!hashTable[parent.interaktId]) {

  //         const myTimeOut = setTimeout(() => {
  //           body = {
  //             'userId': parent.interaktId,
  //             'event': 'tactopus merging notification for parent',
  //             'traits': {
  //               'Name': parent.fullName,
  //             }
  //           }

  //           this.notificationService.sendWhatsappNotification(body).then(() => {
  //             console.log(parent.fullName, ' received')
  //           }).catch(err => {
  //             console.log(parent.fullName, ' not received ', err)
  //           })
  //         }, 2000);
  //       }
  //     })
  //   })
  // }



  // sendNotification() {
    //   this.parents = [];
  
  
    //   let ref = this.firestore.collection('users', ref => ref.where('activityStatus', '==', 'Inactive'));
    //   ref.get().subscribe(results => {
    //     console.log(results.size)
    //     let count = 0;
    //     results.forEach(async (doc) => {
    //       console.log(count);
    //       count++;
    //       let parent: any = doc.data();
    //       this.parents.push(parent);
          
  
    //     })
    //   })
    // }
  
  
    // secondFunction() {
    //   let count = 0;
    //   this.parentsWithBalance = [];
    //   this.parents.forEach(doc => {
    //     let parent: any = doc;
    //     let ref = this.firestore.collection('balances').doc(parent.id);
    //     ref.get().subscribe(result => {
    //       console.log(count);
    //       count++;
    //       if (result.exists) {
    //         let balance: any = result.data();
    //         if(balance.currentBalance > 1){
    //           parent['currentBalance'] = balance.currentBalance;
    //           this.parentsWithBalance.push(parent);
    //         }
    //       } else {
    //         console.log('balance doc not found for parent ', parent.fullName)
    //       }
    //     })
    //   })
    // }
  
  
    // thirdFunction() {
    //   console.log('total parents ', this.parentsWithBalance.length)
    //   this.parentsWithBalance.forEach(doc => {
    //     let parent: any = doc;
  
    //     let hashTable: any = {};
    //     if (data1 && (typeof data1 === "object")) {
    //       let count = 0;
    //       Object.keys(data1).forEach(docKey => {
    //         console.log(count);
    //         count++;
    //         let parentData: any = data1[docKey]
    //         // console.log(parentData['User Id'])
    //         if (!hashTable[parentData['User Id']]) {
    //           hashTable[parentData['User Id']] = true;
    //         }
    //       });
    //     }
  
  
    //     if (!hashTable[parent.interaktId]) {
  
    //       let body: any = {};
    //       const myTimeOut = setTimeout(() => {
    //         body = {
    //           'userId': parent.interaktId,
    //           'event': 'tactopus merging notification for parent',
    //           'traits': {
    //             'Name': parent.fullName,
    //           }
    //         }
  
    //         this.notificationService.sendWhatsappNotification(body).then(() => {
    //           console.log(parent.fullName, ' received')
    //         }).catch(err => {
    //           console.log(parent.fullName, ' not received ', err)
    //         })
    //       }, 2000);
    //     }
    //   })
    // }

}