<mat-card style="width: 100%;" class="rescheduleCard">
    <div style="display: flex; justify-content: flex-end;">
        <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>
    
    <mat-card-title *ngIf="isRescheduleable">
        Reschedule Session
    </mat-card-title>
    <mat-card-title *ngIf="!isRescheduleable">
        Unable to reschedule your session as your balance does not cover the reschedule penalty
    </mat-card-title>

    <mat-card-subtitle style="color: #59AADD;" class="penaltyWarning">{{message}}</mat-card-subtitle>

    <!-- this will be shown when session is requested -->
    <!-- <mat-card-title *ngIf="data.session.status === 'Session Request'">
        <h3 *ngIf="isRescheduleable">Are you sure you want to reschedule this request ?</h3>
        <h3 *ngIf="!isRescheduleable">
            Unable to reschedule your session as your balance does not cover the reschedule penalty
        </h3>
    </mat-card-title> -->

    <mat-card-content *ngIf="data.session.status !== 'Session Request'">
        <a href="https://mykinderpass.com/terms-and-conditions" target="_blank" style="color: gray;"
            *ngIf="data.reschedulingPerson == 'Parent'">View our cancellation and rescheduling policy.</a>
        <a href="/assets/Tactopus Leave, Rescheduling & Cancellations Policy.pdf" target="_blank" style="color: gray;"
            *ngIf="data.reschedulingPerson == 'Expert'">View our cancellation and rescheduling policy.</a>
    </mat-card-content>

    <!-- this will be shown when session is requested -->
    <!-- <mat-card-subtitle *ngIf="data.session.status === 'Session Request'">
        <p style="margin-top: 10px;">Rescheduling this requested session will cancel it and create a new request.</p>
    </mat-card-subtitle> -->

    <form [formGroup]="reasonForm">
        <div class="formFieldHeading">Reason for reschedule*:</div>
        <mat-form-field class="full-width-field" appearance="outline">
            <mat-select disableOptionCentering formControlName="rescheduleReason" panelClass="dropdown" placeholder="Select a reason">
                <mat-option *ngFor="let rescheduleReason of getRescheduleReasons()" value="{{ rescheduleReason }}">
                    {{ rescheduleReason }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="formFieldHeading">Additional Details (Optional)</div>
        <mat-form-field class="full-width-field" floatLabel="never">
            <input matInput formControlName="additionalDetails" placeholder="Enter Here" autocomplete="off">
        </mat-form-field>
    </form>

    <mat-card-actions>
        <button [disabled]="reasonForm.invalid" *ngIf="isRescheduleable" class="right actionButtons2" mat-button color="warn"
            (click)="rescheduleSession()">Continue</button>
        <button *ngIf="isRescheduleable" class="right actionButtons" mat-button mat-dialog-close
            color="warn">Back</button>
        <button *ngIf="!isRescheduleable" class="left actionButtons" mat-button color="warn"
            (click)="cancelMeeting(data.session.id)">Cancel Session
        </button>
        <button *ngIf="!isRescheduleable" class="right actionButtons" mat-button color="warn"><a
                href="https://wa.me/+919606719826" target="_blank" id="getHelp">Contact
                Support</a>
        </button>
    </mat-card-actions>
</mat-card>