<div class="page-container">
	<div class="display-2" *ngIf="verified===false">
		<span>You have already registered and verification is ongoing. Kindly wait for the verification email.</span>
	</div>

	<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" >
  	<mat-card class="content-container" *ngIf="!verified">
		  <mat-card-header>
		    <div mat-card-avatar ></div>
		    <mat-card-title>Awaiting Verification</mat-card-title>
		  </mat-card-header>
		  <mat-card-content>
		  	You are already registered but awaiting verification.
		  </mat-card-content>
		</mat-card>
		<firebase-ui (signInSuccessWithAuthResult)="successCallback($event)" (signInFailure)="errorCallback($event)"></firebase-ui>
	</div>
</div>