import { Component, EventEmitter, Inject, NgZone, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { InteraktUserService } from '../services/interakt-user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../helper.service'
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-expert-profile',
  templateUrl: './expert-profile.component.html',
  styleUrls: ['./expert-profile.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ExpertProfileComponent implements OnInit {
  @Input('isEditing') isEditing: boolean = true;

  expert: any;
  leaves: any[];
  doesSessionsExists: boolean = false;
  upComingLeaves: any[];
  pastLeaves: any[];
  leaveForm: FormGroup;
  isLeaveFormEnabled: boolean;
  phoneNumberCountryCode = '+91';
  expertForm: any;
  _id: string;
  balance = "";
  consultationBalance = "";
  balanceSub: Subscription;
  formFirstPart: any;
  formSecondPart: any;
  formThirdPart: any;
  formFourthPart: any;

  notificationBody = {
    userId: '',
    event: '',
    traits: {
      name: '',
    }
  }

  weeklyWorkHours: any[];
  workHour: workingObject[];
  isOverLapped = false;
  showWorkingHours:boolean=true;

  @Output() completed = new EventEmitter<void>();
  @Output() atSupport = new EventEmitter<void>();
  @Output() leaveConfirmed = new EventEmitter<number>();

  @Input()
  set id(value) {
    this._id = value;
    this.getExpert();
  }

  @Input() fromAdmin = false;

  constructor(
    private ngZone: NgZone,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private newInteraktUserService: InteraktUserService,
    private notificationService: NotificationService,
    public firestore: AngularFirestore,
    public auth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private router: Router,
    public helper: HelperService) {
  }


  ngOnInit(): void {
    var dt = DateTime.now();
    this.leaveForm = this.fb.group(
      {
        leaveReason: '',
        leaveStartDateTime: '',
        leaveEndDateTime: ''
      }
    )
    this.expertForm = this.fb.group({
      profile: this.fb.array([
        this.fb.group({
          fullName: ['', Validators.required],
          dateOfBirth: ['', Validators.required],
          // age:['',Validators.required],
          languages: ['', Validators.required],
          email: ['', [Validators.email, Validators.required]],
          // gender:['',Validators.required],
          phoneNumber: ['', Validators.required],
        }),
        this.fb.group({
          serviceTypes: ['', Validators.required],
          // otherServiceTypes:[''],
          // ageGroups: ['', Validators.required],
          startingAgeRange: ['', Validators.required],
          endingAgeRange: ['', Validators.required],
          condition: ['', Validators.required],
          // languagePreferences:['',Validators.required],
          // otherLanguagePreferences:[''],
          yearsOfExperience: ['', Validators.required],
          // onlineExperience:['',Validators.required],
        }),
        this.fb.group({
          qualifications: this.fb.array([]),
          // conditions:['',Validators.required],
          // otherConditions:['']
        }),
        this.fb.group({
          timeZone: [dt.zoneName, Validators.required],
          workingHours: this.fb.array([])
        })
      ])
    });
    this.getLeaves();

    this.balanceSub = this.firestore.doc(`balances/${this._id}`).valueChanges().subscribe(
      (balanceDoc: AngularFirestoreDocument) => {
        this.balance = balanceDoc['currentBalance'];
        this.consultationBalance = balanceDoc['consultationBalance'];
      }
    );
  }


  getLeaves() {
    let ref = this.firestore.collection('leaves', ref => ref.where('leaveBy', '==', this._id));
    ref.get().subscribe(this.onLeaveResult)
  }

  onLeaveResult = (results) => {
    let now = new Date();
    let nowStamp = firebase.firestore.Timestamp.fromDate(now);
    this.pastLeaves = [];
    this.upComingLeaves = [];
    if (!results.empty) {
      this.leaves = [];
      results.forEach((doc) => {
        this.leaves.push(doc.data());
      });
    } else {
      this.leaves = null;
    }
    // created for past Leaves and upcuming Leaves
    this.leaves.forEach(leave => {
      if (leave.leaveStartDateTime <= nowStamp) {
        this.pastLeaves.push(leave);
      } else {
        this.upComingLeaves.push(leave);
      }
    });

  }


  backToSupport() {
    this.router.navigateByUrl('support/dashboard');
    this.atSupport.emit();
  }

  getExpert() {
    var userRef = this.firestore.collection('users').doc(this._id);
    userRef.get().subscribe(this.onUser)
  }

  public onUser = (result) => {
    if (!result.empty) {
      this.expert = result.data();
      this.weeklyWorkHours = this.expert.workingHours;
      this.getSortedWorkingHours();

      this.expert.qualifications.forEach((qualification) => {
        this.addQualification();
      })
      this.expert.workingHours.forEach((workingHours) => {
        this.addWorkingHour();
      })

      let startingAge = 0;
      let endingAge = 0;
      let dummyCondition = [];
      if (this.expert.startingAgeRange) {
        startingAge = this.expert.startingAgeRange;
        endingAge = this.expert.endingAgeRange;
      } else {
        startingAge = 0;
        endingAge = 0;
      }
      if (this.expert.condition) {
        dummyCondition = this.expert.condition;
      } else {
        dummyCondition = ["Others"];
      }

      var data = {
        profile: [
          {
            fullName: this.expert.fullName,
            dateOfBirth: this.expert.dateOfBirth.toDate(),
            // age:this.expert.age,
            languages: this.expert.languages,
            email: this.expert.email,
            // gender:this.expert.gender,
            phoneNumber: this.expert.phoneNumber
          },
          {
            serviceTypes: this.expert.serviceTypes,
            // otherServiceTypes:this.expert.otherServiceTypes,
            // ageGroups: this.expert.ageGroups,
            startingAgeRange: startingAge,
            endingAgeRange: endingAge,
            condition: dummyCondition,
            // languagePreferences:this.expert.languagePreferences,
            // otherLanguagePreferences:this.expert.otherLanguagePreferences,
            yearsOfExperience: this.expert.yearsOfExperience
            // onlineExperience:this.expert.onlineExperience
          },
          {
            qualifications: this.expert.qualifications
            // conditions:this.expert.conditions,
            // otherConditions:this.expert.otherConditions
          },
          {
            timeZone: this.expert.timeZone,
            workingHours: this.expert.workingHours
          }
        ]
      };

      this.expertForm.setValue(data);
    }
  }

  getSortedWorkingHours() {
    this.workHour = [];
    this.workHour = [
      { weekday: 'mon', workSlots: [] },
      { weekday: 'tue', workSlots: [] },
      { weekday: 'wed', workSlots: [] },
      { weekday: 'thu', workSlots: [] },
      { weekday: 'fri', workSlots: [] },
      { weekday: 'sat', workSlots: [] },
      { weekday: 'sun', workSlots: [] }
    ];

    const sorter = {
      "mon": 0,
      "tue": 1,
      "wed": 2,
      "thu": 3,
      "fri": 4,
      "sat": 5,
      "sun": 6
    }

    this.weeklyWorkHours.forEach(hour => {
      let day = hour.weekday;
      let i = sorter[day];
      this.workHour[i].workSlots.push(hour.startTime + "-" + hour.endTime);
    });

  }


  async cancelLeave(leaveId: any) {
    // getting this leave details
    let docref = this.firestore.collection('leaves').doc(leaveId).get().subscribe(this.deleteLeave);
  }

  deleteLeave = (result) => {
    let leave: any;
    if (!result.empty) {
      leave = result.data();
      console.log('id ', leave)

      const dialogRef = this.dialog.open(deleteLeaveDialog, {
        disableClose: true,
        panelClass: 'leave-delete-dialog',
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (!result) {
          return;
        } else if (result.event == 'Yes') {
          await this.firestore.collection('leaves').doc(leave.id).delete()
            .then(() => {
              let message = 'Your leave has been cancelled successfully'
              this.toastr.success(message, 'Leave Cancelled');
              this.getLeaves();
            })
            .catch(err => console.log('error deleting leave ', err));
        }
      });
    }

  }



  hasErrors(section, formControlName) {
    var result = false;
    if (formControlName in this.profile.get([section])['controls'])
      result = this.profile.get([section])['controls'][formControlName].invalid;
    else
      console.log(formControlName);
    return result;
  }

  get profile() {
    return this.expertForm.get('profile') as FormArray;
  }

  get qualifications() {
    return this.expertForm.get('profile').get([2])['controls']['qualifications'] as FormArray;
  }

  addQualification() {
    this.qualifications.push(
      this.fb.group({
        'degree': ['', Validators.required],
        'fieldOfStudy': ['', Validators.required],
        'certifyingOrganisation': ['', Validators.required],
      })
    );
  }

  deleteQualification(index: number) {
    this.qualifications.removeAt(index);
  }

  get workingHours() {
    return this.expertForm.get('profile').get([3])['controls']['workingHours'] as FormArray;
  }

  addWorkingHour() {
    this.workingHours.push(
      this.fb.group({
        'weekday': ['', Validators.required],
        'startTime': ['', Validators.required],
        'endTime': ['', Validators.required]
      })
    );
  }

  deleteWorkingHour(index: number) {
    this.workingHours.removeAt(index);
  }

  getLanguages() {
    return this.helper.languages;
  }

  getServices() {
    return this.helper.services;
  }

  getAgeGroups() {
    return this.helper.ageGroups;
  }

  getConditions() {
    return this.helper.expertConditions;
  }

  getTimeZones() {
    return this.helper.timeZones;
  }

  isOtherChecked(values) {
    var result = false;
    if (values) {
      result = values.includes("Other");
    }
    return result;
  }

  onPhoneNumberCountryChange(event) {
    this.phoneNumberCountryCode = '+' + event.dialCode;
  }

  async save() {
    this.isOverLapped = false;
    var user = {};
    var profile = this.expertForm.value.profile;
    let date = new Date(profile[0].dateOfBirth)
    profile[0].dateOfBirth = new Date(date)

    let workingHoursArray = [];
    workingHoursArray = this.createWorkingHourArray(profile[3].workingHours);
    console.log("Checking sorted workingHOurs: ", workingHoursArray);
    workingHoursArray.forEach((element) => {
      for (let i = 1; i < element.workSlots.length; i++) {
        let previous = element.workSlots[i - 1].split("-");
        let previousStart = parseInt(previous[0], 10);
        let previousEnd = parseInt(previous[1], 10);
        let current = element.workSlots[i].split("-");
        let currentStart = parseInt(current[0], 10);
        if ((currentStart >= previousStart) && (currentStart < previousEnd)) {
          console.log("Value of isOverLapped is changed");
          this.isOverLapped = true;
          break;
        }
      }
    });
    console.log(" Is the workingHours overlapping: ", this.isOverLapped);

    if (this.isOverLapped == false) {
      profile.forEach(data => {
        user = { ...user, ...data }
      })
      //Get the current user uid
      var docRef = this.firestore.collection("users").doc(this._id);
      const updatedOn = firebase.firestore.FieldValue.serverTimestamp();
      const updatedBy = firebase.auth().currentUser.uid;
      const updatedByName = firebase.auth().currentUser.displayName;
      user['updatedOn'] = updatedOn;
      user['registered'] = true;
      user['countryCode'] = this.phoneNumberCountryCode;
      docRef.update(user).then(this.onExpertUpdated).catch(function (error) {
        var message = "Oops! There was an error during registration. Try again. If problem persists, contact support."
        this.snackBar.open(message, "", {
          duration: 2000,
        });
      });
    }
  }

  onExpertUpdated = () => {
    let id = this._id;
    let ref = this.firestore.collection('users').doc(id);
    ref.get().subscribe(this.onExpertDetails)

    var message = "Profile has been updated successfully."
    this.snackBar.open(message, "", {
      duration: 2000,
    });
  }

  onExpertDetails = (result) => {
    let expert: any;
    if (!result.empty) {
      expert = result.data();
    }

    // this will be triggered for the first time registration by expert
    if (!expert.verified) {
      let balanceDoc: any = {};
      console.log('getting here..')

      balanceDoc['id'] = expert.id;
      balanceDoc['userId'] = expert.id;
      balanceDoc['role'] = 'expert';
      balanceDoc['consultationBalance'] = 0;
      balanceDoc['currentBalance'] = 0;
      balanceDoc['fullName'] = expert.fullName;
      balanceDoc['lastUpdated'] = firebase.firestore.FieldValue.serverTimestamp();

      let ref = this.firestore.collection('balances').doc(expert.id);
      ref.set(balanceDoc).then(() => {
        this.notificationBody.userId = expert.interaktId;
        this.notificationBody.event = 'new expert signed up';
        this.notificationBody.traits.name = expert.fullName;

        // creating expert's interakt account and sending the registration notification.
        this.newInteraktUserService.createExpertAccount(expert).then(this.onExpertCreatedOnInterakt);
      }).catch(err => console.log('error  ', err))
    } else {
      window.location.reload();
      this.isEditing = false;
      this.completed.emit();
    }
  }

  onExpertCreatedOnInterakt = () => {
    let body = {
      'userId': this.notificationBody.userId,
      'event': this.notificationBody.event,
      'traits': {
        'name': this.notificationBody.traits.name,
      }
    }
    this.notificationService.sendWhatsappNotification(body);

    window.location.reload();
    this.isEditing = false;
    this.completed.emit();
  }


  createWorkingHourArray(unsortedArray) {
    let sortedArray = [];
    sortedArray = [
      { weekday: 'mon', workSlots: [] },
      { weekday: 'tue', workSlots: [] },
      { weekday: 'wed', workSlots: [] },
      { weekday: 'thu', workSlots: [] },
      { weekday: 'fri', workSlots: [] },
      { weekday: 'sat', workSlots: [] },
      { weekday: 'sun', workSlots: [] }
    ];
    const sorter = {
      "mon": 0,
      "tue": 1,
      "wed": 2,
      "thu": 3,
      "fri": 4,
      "sat": 5,
      "sun": 6
    }

    unsortedArray.forEach(hour => {
      let day = hour.weekday;
      let i = sorter[day];

      let startTime = hour.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);
      startTime = (startingHour * 60) + startingMinute;

      let endTime = hour.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);
      endTime = (endingHour * 60) + endingMinute;
      sortedArray[i].workSlots.push(startTime + "-" + endTime);
    });
    sortedArray.forEach((element) => {
      element.workSlots.sort(this.compareArray);
    });

    return sortedArray;
  }
  compareArray(a, b) {
    let firstStart = a.split("-");
    firstStart = parseInt(firstStart[0], 10);
    let secondStart = b.split("-");
    secondStart = parseInt(secondStart[0], 10);
    if (firstStart > secondStart) {
      return 1;
    } else if (firstStart < secondStart) {
      return -1;
    } else {
      return 0;
    }
  }

  async checkForExistingSessions() {
    let doesSessionExists: boolean = false;
    let leave = {};
    let leaveData = this.leaveForm.value;
    leave = leaveData;

    // console.log('leave data ', leaveData)
    let leaveStartDate: Date = this.helper.istTime3(leave['leaveStartDateTime'], leave['leaveStartDateTime'].getHours(), leave['leaveStartDateTime'].getMinutes());
    let startingTimeStamp = firebase.firestore.Timestamp.fromDate(leaveStartDate);

    let leaveEndDate: Date = this.helper.istTime3(leave['leaveEndDateTime'], leave['leaveEndDateTime'].getHours(), leave['leaveEndDateTime'].getMinutes());
    leaveEndDate.setMinutes(leaveEndDate.getMinutes() + 30);
    let endingTimeStamp1 = firebase.firestore.Timestamp.fromDate(leaveEndDate);
    leaveEndDate.setMinutes(leaveEndDate.getMinutes() + 30);
    let endingTimeStamp2 = firebase.firestore.Timestamp.fromDate(leaveEndDate);

    await this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', this._id).where('sessionEndDateTime', ">", startingTimeStamp).where('sessionEndDateTime', '<', endingTimeStamp1).where('slotDuration', '==', 30).where('status', 'in', ['Scheduled', 'Session Request'])).get().toPromise().then((results) => {
      if (!results.empty) {
        console.log(results)
        doesSessionExists = true;
        return doesSessionExists;
      } else {
        console.log('calling 1')
        return this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', this._id).where('sessionEndDateTime', ">", startingTimeStamp).where('sessionEndDateTime', '<', endingTimeStamp2).where('slotDuration', '==', 60).where('status', 'in', ['Scheduled', 'Session Request'])).get().toPromise().then((results) => {
          if (!results.empty) {
            results.forEach(doc=>{

              console.log(doc.data())
            })
            doesSessionExists = true;
            return doesSessionExists;
          } else {
            console.log('calling 2')
            return this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', this._id).where('sessionEndDateTime', ">", startingTimeStamp).where('sessionEndDateTime', '<', endingTimeStamp1).where('status', '==', 'Scheduled')).get().toPromise().then((results) => {
              if (!results.empty) {
                console.log(results)
                doesSessionExists = true;
                return doesSessionExists;
              }

              return doesSessionExists;
            });
          }
        });
      }
    });

    return doesSessionExists;
  }

  applyForLeave = async () => {
    let leave = {};
    let leaveData = this.leaveForm.value;
    leave = leaveData;

    let leaveStartDate: Date = this.helper.istTime3(leave['leaveStartDateTime'], leave['leaveStartDateTime'].getHours(), leave['leaveStartDateTime'].getMinutes());
    let leaveEndDate: Date = this.helper.istTime3(leave['leaveEndDateTime'], leave['leaveEndDateTime'].getHours(), leave['leaveEndDateTime'].getMinutes());

    let id = this.helper.uuidv4();
    let docRef = this.firestore.collection("leaves").doc(id);
    const leaveBy = firebase.auth().currentUser.uid;

    leave['createdOn'] = firebase.firestore.FieldValue.serverTimestamp();
    leave['leaveBy'] = leaveBy;
    leave['id'] = id;
    leave['leaveStartDateTime'] = leaveStartDate;
    leave['leaveEndDateTime'] = leaveEndDate;

    this.doesSessionsExists = await this.checkForExistingSessions();
    console.log('doesSessionExists ', this.doesSessionsExists)

    const dialogRef = this.dialog.open(leaveConfirmationDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
      data: { leave: leave, doesSessionsExists: this.doesSessionsExists }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        console.log('confirmed')
        docRef.set(leave).then(async () => {
          this.leaveForm.reset();
          this.isLeaveFormEnabled = false;
          this.getLeaves();

          let startingDateString = leaveStartDate.getDate() + '/' + (leaveStartDate.getMonth() + 1) + '/' + leaveStartDate.getFullYear() + ', ' + leaveStartDate.getHours() + ':' + leaveStartDate.getMinutes();
          let endingDateString = leaveEndDate.getDate() + '/' + (leaveEndDate.getMonth() + 1) + '/' + leaveEndDate.getFullYear() + ', ' + leaveEndDate.getHours() + ':' + leaveEndDate.getMinutes();

          // notification to the experts
          let body1: any = {};
          body1 = {
            'userId': this.expert.interaktId,
            'event': 'leave applied by expert - notify expert',
            'traits': {
              'expertName': this.expert.fullName,
              'startingDateString': startingDateString,
              'endingDateString': endingDateString
            }
          }
          await this.notificationService.sendWhatsappNotification(body1);

          // notification to support/program
          let ref = this.firestore.collection('users', ref => ref.where('role', 'in', ['program', 'support', 'sales-admin']));
          ref.get().toPromise().then(results => {
            results.forEach(async (doc) => {
              let user: any = doc.data();

              let body2: any = {};
              body2 = {
                'userId': user.interaktId,
                'event': 'leave applied by expert - notify admins',
                'traits': {
                  'expertName': this.expert.fullName,
                  'startingDateString': startingDateString,
                  'endingDateString': endingDateString
                }
              }

              await this.notificationService.sendWhatsappNotification(body2);
            })

            var message = "Applied for leave successfully."
            this.snackBar.open(message, "", {
              duration: 2000,
            });

            if (this.doesSessionsExists == true) {
              this.leaveConfirmed.emit(0);
            }

          })
        }).catch(err => {
          console.log(err)
        })
      }
    })
  }

  getErrorMessage(section, formControlName) {
    var errors = this.profile.get([section])['controls'][formControlName].errors;
    var errorString;
    if (errors['required'])
      errorString = "Required";
    return errorString;
  }

  onNoClick(): void {
    this.completed.emit();
  }

  editProfile() {
    this.isEditing = true;
    this.showWorkingHours=false;
  }
}


@Component({
  selector: 'app-expert-profile-dialog',
  templateUrl: './expert-profile-dialog.component.html',
  styleUrls: ['./expert-profile-dialog.component.css']
})
export class ExpertProfileDialog {
  id: string;
  constructor(
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<ExpertProfileDialog>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: string) {
    this.id = data['id'];
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'delete-leave-dialog',
  template: `
  <mat-card style="width: 100%;" class="deleteLeaveDialog">
    <div style="display: flex; justify-content: flex-end;">
        <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>
    <mat-card-title class="penaltyWarning">
      Leave Cancellation
    </mat-card-title>
    <mat-card-content>
        <p style="color: #A5A5A5;">Are you sure you want to cancel this leave ?</p>
    </mat-card-content>
    <mat-card-actions>
      <button class="right actionButtons" mat-button color="warn" (click)="delete()">Confirm</button>
      <button class="right actionButtons"  mat-dialog-close mat-button color="warn">Cancel</button>
    </mat-card-actions>
  </mat-card>
  `,
  styleUrls: ['./expert-profile.component.css']
})
export class deleteLeaveDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<deleteLeaveDialog>) { }

  ngOnInit() { }

  delete() {
    this.dialogRef.close({ event: 'Yes' });
  }

  ngOnDestroy() { }
}

@Component({
  selector: 'leave-confirmation-dialog',
  template: `
  <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
    <div class="leaveConfirmationWrapper">
      <mat-card-title style="margin-bottom: 20px !important; font-size: 17px; display: flex; align-items: center; color: #F7524B;"><mat-icon style="margin-right: 5px;">info</mat-icon><p style="font-size:24px; margin-bottom: 0px; font-weight:600;">Confirm your details</p></mat-card-title>
      
      <p style="display: flex; align-items: center">
        <span style="font-weight: 500;">{{data.leave.leaveStartDateTime | date:'dd/MM/YYYY h:mm a'}}</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">{{data.leave.leaveEndDateTime | date:'dd/MM/YYYY h:mm a'}}</span>
      </p>

      <p style="color: #282828; font-size:18px; font-weight:600;" *ngIf="data.doesSessionsExists">You also have sessions or consultations during this time period.</p>
      <p style="color: #A5A5A5; font-size:12px; font-weight:500;" *ngIf="data.doesSessionsExists">Head to your session dashboard to reschedule or cancel them.</p>
      <p style="color: #282828; font-size:18px; font-weight:600;" *ngIf="!data.doesSessionsExists">You do not have sessions or consultations during this time period.</p>

      <button class="right" style="background: #FCFCFC; color: #F7524B" mat-button color="warn" (click)="confirm()">Yes</button>
      <button class="right" style="background: #F7524B !important; color: white !important; margin-right: 15px"  mat-button color="warn" mat-dialog-close>No</button>
    </div>
  `,
  styleUrls: ['./expert-profile.component.css']
})
export class leaveConfirmationDialog implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<leaveConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() { }

  confirm() {
    this.dialogRef.close({ event: 'Yes' });
  }

  ngOnDestroy() { }
}

interface workingObject {
  weekday: any;
  workSlots: any[];
}