<div class="wrapper">
  <mat-icon class="right" style="position: relative; top: -12px; left: 12px; cursor: pointer;" matDialogClose>close</mat-icon>
  <br>
  <div>
    <h1 *ngIf="data.dialogType === 'cancel'" style="font-weight: 600;">Which party is requesting this cancellation?</h1>
    <h1 *ngIf="data.dialogType === 'reschedule'" style="font-weight: 600;">Which party is requesting this reschedule?</h1>
    <div class="btnWrapper" style="display: flex; justify-content: center;">
      <div style="display: inline-flex; justify-content: space-evenly;">
        <button mat-button color="warn" class="dialogBtn" (click)="onClose('parent')">Parent</button>
        <button mat-button color="warn" class="dialogBtn" (click)="onClose('expert')">Expert</button>
        <button mat-button color="warn" class="dialogBtn" (click)="onClose('admin')">Admin</button>
      </div>
    </div>
  </div>
</div>
