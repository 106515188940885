<mat-dialog-content class="mat-typography dialog-content">
	<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" >
		<mat-card class="video-container">
    	<video class="video" #localVideo id="localVideo" muted autoplay playsinline></video>
    </mat-card>
    <mat-card class="video-container">
    	<video class="video" #remoteVideo id="remoteVideo" autoplay playsinline></video>
    </mat-card>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<!-- <button mat-button color="warn" class="left" (click)="onDeleteQuiz()">Delete</button> -->
  <button mat-button  class="right" (click)="onNoClick()">Hang Up</button>
</mat-dialog-actions>

