<div class="sales-admin-newSessionContainer">
  <div class="heading">
    <a matButton id="back-button" style="cursor: pointer;" (click)="backToSessionPage()">
      <mat-icon>arrow_back</mat-icon>
    </a>
    <h1 style="margin-left: 5px;">Schedule a consultation</h1>
  </div>

  <div *ngIf="hasTimerStarted" style="display: flex; padding-left: 24px; margin-top: 35px;">
    <mat-icon style="color: #7A7A7A; margin-right: 20px;">info</mat-icon>
    <p style="color: #7A7A7A; text-align: start;">Please confirm your slots within <span
        id="countDown">{{timerMinute}}:{{timerSecond}}</span> minutes. <br> Your selection will
      expire soon</p>
  </div>

  <div style="color: #F7524B; margin-top: 35px; font-weight: bold;" *ngIf="!currentUser?.id">You need to go back and refresh the dashboard first before proceeding</div>

  <br>
  <form [formGroup]="newSessionForm">
    <mat-horizontal-stepper formArrayName="session" linear class="consultationBookingStepper">
      <mat-step formGroupName="0" [stepControl]="formFirstPart" label="Filter">
        <mat-card class="page1Card">
          <mat-card-content>
            <!-- <p class="labels">Select Session Type(Duration):</p>
                        <mat-form-field class="full-width-field formFields" floatLabel="never">
                            <mat-select disableOptionCentering panelClass="dropdown" formControlName="sessionType"
                                #consultationType>
                                <mat-option *ngFor="let consultationType of getconsultationTypes()"
                                    value="{{consultationType}}">{{consultationType}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->


            <!-- child details section -->
            <mat-card-title>
              <h2 style="font-weight: 500; margin-bottom: 15px;">About the child:</h2>
            </mat-card-title>

            <!-- <p class="labels2">Select Condition / Area of Concern</p> dropdown2 formFields dropdownPg2 dropdownExtra-->
            <div class="formFieldHeading">Select Condition / Area of Concern</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-select disableOptionCentering panelClass="dropdownMultiple" formControlName="childCondition"
                placeholder="Select Options" multiple>
                <mat-option *ngFor="let condition of getConditions()" [value]="condition">
                  {{condition}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <p class="labels2">Date of Birth</p> -->
            <div class="formFieldHeading">Date of Birth</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <input matInput (dateChange)="onDateOfBirthSelected($event)" placeholder="dd/mm/yyyy"
                formControlName="dateOfBirth" autocomplete="off" (change)="onDateOfBirthSelected($event.target.value)">
            </mat-form-field>
            <!-- <mat-form-field class="full-width-field" appearance="outline">
              <input matInput (dateChange)="onDateOfBirthSelected($event)" [matDatepicker]="picker"
                formControlName="dateOfBirth" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field> -->


            <!-- <p class="labels2">Select Primary Language</p> -->
            <div class="formFieldHeading">Select Primary Language</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-select disableOptionCentering panelClass="dropdown" formControlName="primaryLanguage"
                placeholder="Select Options">
                <mat-option *ngFor="let language of getLanguages()" [value]="language">
                  {{ language }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <p class="labels2">Select Secondary Languages</p> -->
            <div class="formFieldHeading">Select Secondary Languages</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-select disableOptionCentering panelClass="dropdownMultiple2" formControlName="secondaryLanguages"
                placeholder="Select Options" multiple>
                <mat-option *ngFor="let language of getSecondaryLanguages()" [value]="language">
                  {{ language }}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <!-- consultation details section -->
            <mat-card-title>
              <h2 style="font-weight: 500; margin-bottom: 15px;">For the Consultation:</h2>
            </mat-card-title>

            <!-- <p class="labels">Select Service*</p> -->
            <div class="formFieldHeading">Select Service*</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-select disableOptionCentering panelClass="dropdown" formControlName="serviceType"
                placeholder="Service Type" #service>
                <mat-option *ngFor="let service of getServices()" [value]="service">
                  {{service}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <p class="labels2">Select your Preferred Date:</p> -->
            <div class="formFieldHeading">Select your Preferred Date:</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <input matInput (dateChange)="onPreferredDateSelected($event)" [matDatepicker]="pickerPreferred"
                formControlName="preferredDate" autocomplete="off" [min]="minDate" [(ngModel)]="tomorrow">
              <mat-datepicker-toggle matSuffix [for]="pickerPreferred"></mat-datepicker-toggle>
              <mat-datepicker #pickerPreferred></mat-datepicker>
            </mat-form-field>


            <!-- <p class="labels">Select Preferred Times:</p> -->
            <div class="formFieldHeading">Select Preferred Times:</div>
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-select disableOptionCentering panelClass="dropdown" formControlName="preferredTimes" multiple
                placeholder="Select Options">
                <mat-option *ngFor="let consultationPreferredTime of getConsultationPreferredTimes()"
                  value="{{ consultationPreferredTime }}">
                  {{ consultationPreferredTime }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <p class="labels">Select Age Range of Child:</p>
                        <mat-form-field class="full-width-field formFields">
                            <mat-select disableOptionCentering panelClass="dropdown" formControlName="ageRange">
                                <mat-option *ngFor="let ageGroup of getAgeGroups()" value="{{ageGroup}}">{{ageGroup}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
          </mat-card-content>
          <mat-card-actions>
            <button mat-button class="right" matStepperNext type="button" id="findExpertsBtn" color="warn"
              (click)="findExperts()" [disabled]="!formFirstPart.valid || !currentUser?.id">
              Book A Slot
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-step>

      <mat-step label=" Get a Slot">
        <mat-card class="page2Card">
          <mat-card-content>
            <div style="text-align: center;">
              <button mat-button (click)="decrementDate()" class="left actionBtn">
                <mat-icon color="warn">keyboard_arrow_left</mat-icon>
              </button>
              <mat-form-field appearance="none">
                <input style="font-size: 15px; font-weight: 600; text-align: right;" matInput [matDatepicker]="dp"
                  [formControl]="dateFormat" (dateChange)="onDateChange($event)" [min]="minDate">
                <mat-datepicker-toggle matSuffix [for]="dp">
                  <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </mat-form-field>
              <button mat-button (click)="incrementDate()" class="right actionBtn">
                <mat-icon color="warn">keyboard_arrow_right</mat-icon>
              </button>
            </div>

            <!-- loading screen starts here -->
            <div *ngIf="isLoading" class="text-center my-4" style="margin: 70px 0px 120px 0px;">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div>
                <h2 style="color: black;">Please wait... <br>Looking for appropriate experts and their available time
                  slots</h2>
              </div>
            </div>
            <!-- loading screen ends here -->

            <div *ngIf="!isLoading">
              <div style="font-size: 14px; font-weight: 600; margin: 30px 0px;">
                The following experts deal with {{formData.serviceType}} who speaks {{formData.primaryLanguage}}
              </div>

              <div>
                <div *ngFor="let expertData of finalExpertsList; let i=index">
                  <div>
                    <div class="expertName">
                      <span style="font-size: 16px; font-weight: 600;">{{expertData.fullName}}</span>
                      <span *ngIf="expertData.averageWeightage != 100"
                        style="margin-left: 15px; font-size: 14px; font-weight: 700; color: #EFC689;">
                        {{expertData.averageWeightage}}% Match
                      </span>
                      <span *ngIf="expertData.averageWeightage == 100"
                        style="margin-left: 15px; font-size: 14px; font-weight: 700; color: #C6E161;">
                        {{expertData.averageWeightage}}% Match
                      </span>
                    </div>
                    <div class="expertProperties"
                      style="margin: 10px 0px; display: flex; flex-wrap: wrap; justify-content: flex-start;">
                      <div class="ages" style="margin: 0px 6px;">
                        <div *ngIf="expertData.isAgeRangeMatched" style="display: flex;">
                          <div>
                            <mat-icon class="agesIcon">done</mat-icon>
                          </div>
                          <div>Ages {{expertData.startingAgeRange}}-{{expertData.endingAgeRange}}</div>
                        </div>
                        <div *ngIf="!expertData.isAgeRangeMatched" style="color: #F7524B; display: flex;">
                          <div>
                            <mat-icon class="agesIcon">clear</mat-icon>
                          </div>
                          <div>Ages {{expertData.startingAgeRange}}-{{expertData.endingAgeRange}}</div>
                        </div>
                      </div>
                      <div class="concerns" style="margin: 0px 6px;">
                        <div *ngIf="expertData.isConcernMatched" style="display: flex;">
                          <div>
                            <mat-icon class="agesIcon">done</mat-icon>
                          </div>
                          <div>Works with area of concern</div>
                        </div>
                        <div *ngIf="!expertData.isConcernMatched" style="color: #F7524B; display: flex;">
                          <div>
                            <mat-icon class="agesIcon">clear</mat-icon>
                          </div>
                          <div>Works with area of concern</div>
                        </div>
                      </div>
                      <div class="languages" *ngFor="let secondLanguage of selectedLanguages" style="margin: 0px 6px;">
                        <div *ngIf="expertData.matchingLanguages.includes(secondLanguage)" style="display: flex;">
                          <div>
                            <mat-icon class="agesIcon">done</mat-icon>
                          </div>
                          <div>{{secondLanguage}}</div>
                        </div>
                        <div *ngIf="!expertData.matchingLanguages.includes(secondLanguage)"
                          style="color: #F7524B; display: flex;">
                          <div>
                            <mat-icon class="agesIcon">clear</mat-icon>
                          </div>
                          <div>{{secondLanguage}}</div>
                        </div>
                      </div>
                      <div class="preferredTimes" style="margin: 0px 6px;">
                        <div *ngIf="expertData.isPreferredTimeMatched" style="display: flex;">
                          <div>
                            <mat-icon class="agesIcon">done</mat-icon>
                          </div>
                          <div>Preferred time</div>
                        </div>
                        <div *ngIf="!expertData.isPreferredTimeMatched" style="color: #F7524B; display: flex;">
                          <div>
                            <mat-icon class="agesIcon">clear</mat-icon>
                          </div>
                          <div>Working Hours in Preferred Time</div>
                        </div>
                      </div>
                      <div class="preferredTimes" style="margin: 0px 6px;">
                        <div *ngIf="multiExpertSlotsService.expertListWithSlots[expertData.id]['preferredSlots'].length"
                          style="display: flex;">
                          <div>
                            <mat-icon class="agesIcon">done</mat-icon>
                          </div>
                          <div>Available Slots in Preferred Time</div>
                        </div>
                        <div
                          *ngIf="!multiExpertSlotsService.expertListWithSlots[expertData.id]['preferredSlots'].length"
                          style="color: #F7524B; display: flex;">
                          <div>
                            <mat-icon class="agesIcon">clear</mat-icon>
                          </div>
                          <div>Available Slots in Preferred Time</div>
                        </div>
                      </div>
                    </div>
                    <div class="preferredSlots" style="margin: 10px 0px;">
                      <div class="slotsContainer">
                        <button id="slot" [disabled]="disableSlots"
                          *ngFor="let openSlot of multiExpertSlotsService.expertListWithSlots[expertData.id]['preferredSlots']"
                          (click)="onSlotSelected(openSlot.value,expertData)"
                          style="cursor: pointer;">{{openSlot.label}}</button>
                      </div>
                    </div>
                    <div class="allSlots" style="margin: 10px 0px;">
                      <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
                        <mat-expansion-panel-header style="max-height: auto;">
                          <div><u style="color: blue;font-size: 14px;">View All Slots</u></div>
                        </mat-expansion-panel-header>
                        <div class="slotsContainer">
                          <button id="slot" [disabled]="disableSlots"
                            *ngFor="let openSlot of multiExpertSlotsService.expertListWithSlots[expertData.id]['allSlots']"
                            (click)="onSlotSelected(openSlot.value,expertData)"
                            style="cursor: pointer;">{{openSlot.label}}</button>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </div>
                  <hr style="margin-top: 25px;margin-bottom: 25px;">
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button matStepperPrevious type="button" color="warn" class="left actionBtn"
              id="back1">Back</button>
            <button mat-button matStepperNext type="button" (click)="evaluateSelectedSlot()" color="warn" class="right actionBtn" id="enterParentDetails"
              [disabled]="!isSlotSelected">Enter Parent Details</button>
          </mat-card-actions>
        </mat-card>
      </mat-step>

      <mat-step formGroupName="1" stepControl="session.get([1])" label="Parent Details">
        <mat-card class="page3Card">
          <mat-card-content>

            <mat-card-title style="color: green; font-size: 20px; line-height: 23px; margin-bottom: 20px; font-weight: bold;"
              *ngIf="blockSlotService.availableSlots.length > 0 && blockSlotService.unavailableSlots.length == 0">
              The slot you have selected is available to be booked
            </mat-card-title>

            <mat-card-title style="color: #F7524B; font-size: 20px; line-height: 23px; margin-bottom: 20px; font-weight: bold;"
              *ngIf="blockSlotService.unavailableSlots.length > 0">
              The slot you have selected is not available to be booked
            </mat-card-title>

            <!-- meeting link section -->
            <mat-card-title>
              <h2 style="font-weight: 500; margin-bottom: -10px;">Enter Google Meet Link:</h2>
            </mat-card-title>
            <mat-form-field class="full-width-field" floatLabel="never">
              <!-- id="meetingLinkFormField" -->
              <input matInput formControlName="meetingLink" placeholder="Google Meeting Link" autocomplete="off">
              <!-- id="linkInput" -->
            </mat-form-field>


            <!-- parent details section -->
            <mat-card-title>
              <h2 style="font-weight: 500; margin-bottom: 0px; margin-top: 15px;">Parent Details</h2>
            </mat-card-title>

            <p class="labels2">Parent Name*</p>
            <mat-form-field class="full-width-field" floatLabel="never">
              <input matInput formControlName="sessionParentName" placeholder="Parent Name" autocomplete="off">
            </mat-form-field>

            <p class="labels2">Parent Phone Number*</p>
            <mat-form-field class="full-width-field" style="color: #A5A5A5 !important">
              <input matInput ng2TelInput (countryChange)="onPhoneNumberCountryChange($event)"
                [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
                formControlName='parentPhoneNumber' type="text"
                pattern="^([1-9]{1}[0-9]{7}|[1-9]{1}[0-9]{8}|[1-9]{1}[0-9]{9})$" autocomplete="off" #phoneNumberCtrl
                id="phoneNumber">
            </mat-form-field>

            <p class="labels2">Parent Whatsapp Number*</p>
            <mat-form-field class="full-width-field" style="color: #A5A5A5 !important">
              <input matInput ng2TelInput (countryChange)="onWhatsappNumberCountryChange($event)"
                [ng2TelInputOptions]="{initialCountry: 'in', utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
                formControlName='parentWhatsappNumber' type="text"
                pattern="^([1-9]{1}[0-9]{7}|[1-9]{1}[0-9]{8}|[1-9]{1}[0-9]{9})$" autocomplete="off" #whatsappNumberCtrl
                id="whatsappNumber">
            </mat-form-field>

            <p class="labels2">Parent Email Id</p>
            <mat-form-field class="full-width-field" floatLabel="never">
              <input matInput formControlName="parentEmail" placeholder="Parent Email Id" autocomplete="off">
            </mat-form-field>


            <!-- child details section -->
            <mat-card-title>
              <h2 style="font-weight: 500; margin-bottom: 0px; margin-top: 15px;">Child Case Study</h2>
            </mat-card-title>

            <p class="labels2">Enter Child's Name*</p>
            <mat-form-field class="full-width-field" floatLabel="never">
              <input matInput formControlName="sessionStudentName" placeholder="Child's Name" autocomplete="off">
            </mat-form-field>

            <p class="labels2">Child's Age*</p>
            <mat-form-field class="full-width-field" floatLabel="never">
              <input matInput [value]="ageOfChild" [readonly]="true" type="number" placeholder="Age" autocomplete="off">
            </mat-form-field>

            <p class="labels2">Gender*</p>
            <mat-form-field class="full-width-field formFields" floatLabel="never">
              <mat-select disableOptionCentering panelClass="dropdown" formControlName="childGender">
                <mat-option *ngFor="let genderType of getGendersTypes()" value="{{genderType}}">
                  {{genderType}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <p class="labels2">Concern*</p>
            <mat-form-field class="full-width-field" floatLabel="never">
              <input matInput formControlName="childConcern" placeholder="Concern" autocomplete="off">
            </mat-form-field>

            <p class="labels2">Diagnosis, if any:</p>
            <mat-form-field class="full-width-field" floatLabel="never">
              <input matInput formControlName="diagnosis" placeholder="Diagnosis" autocomplete="off">
            </mat-form-field>


            <!-- <mat-card-title>
                            <h2 style="font-weight: 500; margin-top: 30px; margin-bottom: 10px;">Consultation Overview:
                            </h2>
                        </mat-card-title>
                        <p style="font-weight: 500;" *ngIf="selectedSlotTimeFull">
                            {{ service.value }} {{ consultationType.value }} with {{ expertName?.value?.fullName }} on
                            {{ selectedSessionDate | date:'EEEE, dd MMM' }} From {{ selectedSlotTimeFull }}
                        </p> -->
          </mat-card-content>

          <div style="color: #F7524B; line-height: 14px; margin-bottom: 5px; font-weight: bold;" *ngIf="blockedByOtherAdmin == true">OOPS! Your selected slot was booked by someone else during your booking period.</div>
          <div style="color: #F7524B; line-height: 23px; margin-bottom: 20px; font-weight: bold;" *ngIf="blockedByOtherAdmin == true">Please select different slot for further booking.</div>

          <mat-card-actions>
            <button mat-button matStepperPrevious type="button" color="warn" class="left actionBtn"
              id="back2">Back</button>
            <button mat-button type="button" color="warn" (click)="openConsultationConfirmationDialog()" id="sendInvite"
              [disabled]="newSessionForm.invalid || blockSlotService.unavailableSlots.length > 0" class="right">Send
              Consultation Invite</button>
          </mat-card-actions>

        </mat-card>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>