import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-party-confirmation-dialog',
  templateUrl: './party-confirmation-dialog.component.html',
  styleUrls: ['./party-confirmation-dialog.component.css']
})
export class PartyConfirmationDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { dialogType: string }, private dialogRef: MatDialogRef<PartyConfirmationDialogComponent>) { }

  ngOnInit(): void {
  }

  onClose(cancelledBy: string) {
    this.dialogRef.close(cancelledBy);
  }
}
