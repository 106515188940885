<div *ngIf="!newExpert">
  <div class="optionHolder">
    <!-- <span class="sessionContainer">
      <button
        mat-button
        class="sessionButton"
        (click)="onFilterSelected('unverified')"
        [ngClass]="{ selectedSessionButton: filter === 'unverified' }"
      >
        Unverified
      </button>

      <button
        mat-button
        class="sessionButton"
        (click)="onFilterSelected('registered')"
        [ngClass]="{ selectedSessionButton: filter === 'registered' }"
      >
        Registered
      </button>
    </span> -->
    <h1><b>Sort by: </b></h1>
    <!-- <span>
      <a mat-button class="newSessionButton" (click)="addExpert()">
        <mat-icon class="addSymbol">add</mat-icon>
         New
      </a>
    </span> -->

    <button class="findExpert" (click)="findExperts()">Find an Expert</button>

  </div>

  <div class="registered" *ngIf="filter==='registered'">
    <div class="sortHolder">
      <mat-form-field style="margin-right: 10px">
        <mat-label>Balance</mat-label>
        <!-- <mat-select disableOptionCentering panelClass="dropdown">
          <mat-option>-</mat-option>
        </mat-select> -->
        <input type="number" matInput (change)="onBalanceChange(balanceVal.value);" #balanceVal [(ngModel)]="balance">
      </mat-form-field>
      <span>
        <mat-form-field>
          <mat-label>Service Type</mat-label>
          <mat-select panelClass="dropdown" disableOptionCentering multiple
            (selectionChange)="onServicesChange($event.value)">
            <mat-option>-</mat-option>
            <mat-option *ngFor="let service of services" [value]="service">{{ service }}</mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </div>

    <div>
      <mat-card class="details-container-1" *ngFor="let expert of experts; let i = index;">

        <div class="profileDetails">
          <span class="labelFields">Name</span>
          <button (click)="onView(expert.id)"
            style="font-weight: 600; color: #F7524B; border: none; background: none; padding: 0px;"
            class="detailFields"><a
              style="color: #F7524B; border-bottom: 2px solid #F7524B; cursor: pointer;">{{expert?.fullName ?
              expert?.fullName : "-"}}</a></button>
        </div>

        <div class="profileDetails">
          <span class="labelFields">Phone</span>
          <span class="detailFields">{{expert?.phoneNumber}}</span>
        </div>

        <div class="profileDetails">
          <span class="labelFields">Email id</span>
          <span class="detailFields">{{expert?.email}}</span>
        </div>

        <div class="profileDetails">
          <span class="labelFields">Id</span>
          <span class="detailFields">{{expert?.id}}</span>
        </div>

        <div class="profileDetails">
          <span class="labelFields">Balance</span>
          <span class="detailFields">{{expert?.currentBalance}}</span>
        </div>

        <div class="activityTagContainer" *ngIf="expert.activityStatus == 'Session & Consultation Disabled'">
          <span class="activityTag">Session Disabled</span>
          <span class="activityTag">Consultation Disabled</span>
        </div>

        <div class="activityTagContainer" *ngIf="expert.activityStatus == 'Session Disabled'">
          <span class="activityTag">Session Disabled</span>
        </div>

        <div class="activityTagContainer" *ngIf="expert.activityStatus == 'Consultation Disabled'">
          <span class="activityTag">Consultation Disabled</span>
        </div>

        <div class="activityTagContainer" *ngIf="!expert.verified">
          <span class="activityTag">Not Verified</span>
        </div>





        <!-- <mat-title>
          <h1 style="font-weight: 500;">(E) {{ expert.fullName }}</h1>
        </mat-title>
        <mat-card-content style="margin-top: 15px !important;">
          <p>{{ expert.phoneNumber }}</p>
          <p>{{ (expert.languagePreferences)?.toString() }}</p>
          <p>Balance: {{ expert['currentBalance'] }}</p>
          <p>Id: {{ expert['id'] }}</p>
          <p *ngIf="!expert.verified && currentUser?.role=='support'" style="color: #F7524B;">Not Verified</p>
        </mat-card-content> -->
        <!-- <mat-card-actions align="end">
          <button color="warn" mat-button class="actionButtons" (click)="onView(expert.id)"
            *ngIf="expert.verified">View</button>
          <button color="warn" mat-button class="actionButtons" (click)="verify(expert)"
            *ngIf="!expert.verified && currentUser?.role=='program'">Verify</button>
        </mat-card-actions> -->
      </mat-card>
    </div>
  </div>
</div>

<!-- <div *ngIf="newExpert">
  <app-expert-profile [id]="currentUser.uid" [fromAdmin]="true" (atSupport)="refresh()"></app-expert-profile>
</div> -->


