import { Component,OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parent-register',
  templateUrl: './parent-register.component.html',
  styleUrls: ['./parent-register.component.css']
})
export class ParentRegisterComponent implements OnInit {

  user:any;
  registered:boolean;
  verified:boolean;
  
  constructor(private router: Router,
              public firestore: AngularFirestore,
              public auth: AngularFireAuth) {    
  }

  ngOnInit(): void {

    this.auth.onAuthStateChanged(user=>{
      if(user){
        //Check if registration form already exists
        this.getUser(user);
       }
    })
  }
  getUser(user){
    var id = user.uid;
    var docRef = this.firestore.collection("users").doc(id);
    docRef.get().subscribe(this.onUserResult);
  }

  onUserResult = (result)=>{
    if(result.exists){
      let user:any = result.data();
      this.user = user;
      if('registered' in user){
        this.registered = user['registered'];
      }
    }      
    return this.user;
  }

  onCompleted(){    
    this.router.navigateByUrl("/parent/dashboard")
  }
}