import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { InteraktUserService } from '../services/interakt-user.service';
import { HelperService } from '../helper.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { NotificationService } from '../services/notification.service';
import firebase from 'firebase/app';
import { Subscription } from 'rxjs';
import { AdminService } from '../services/admin.service';
import { take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { ConfirmInvitationDialogComponent } from '../confirm-invitation-dialog/confirm-invitation-dialog.component';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteDialog implements OnInit, OnDestroy {
  id: any;
  isSendDisabled: boolean = false;
  adminDoc: any;
  myExpertsIds: any[];
  phoneNumberCountryCode = '+91';
  whatsappNumberCountryCode = '+91';
  experts: any[];
  services = [];
  serviceSub: Subscription;
  invitationForm: FormGroup;
  sessionTypes = [
    { label: 'Half Session', value: 'Half session' },
    { label: 'Full Session', value: 'Full session' }
  ]
  customSessionDetails:boolean=false;
  validity:any;

  constructor(private fb: FormBuilder,
    private newInteraktUserService: InteraktUserService,
    private auth: AngularFireAuth,
    private notificationService: NotificationService,
    private helper: HelperService,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InviteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdminService) {
    if (data) {
      this.experts = [];
      let expertList = [];
      expertList = data.experts;
      expertList.forEach(doc => {
        let expert:any = doc;
        if(expert.activityStatus != "Session & Consultation Disabled" && expert.activityStatus != "Consultation Disabled"){
          this.experts.push(expert);
        }
      })
    } else {
      this.experts = null;
    }
  }

  async ngOnInit() {
    this.invitationForm = this.fb.group({
      name: ['', Validators.required],
      eMail: ['', Validators.email],
      phoneNumber: ['', Validators.required],
      whatsappNumber: ['', Validators.required],
      sessionPackValue: ['', Validators.required],
      numberOfSessions: ['', Validators.required],
      validity:['', Validators.required],
      // reccomendedFrequency: <any>['', [Validators.required, Validators.min(1), Validators.max(7)]],
      educators: this.fb.array([]),
    })

    let currentUser = await this.auth.currentUser;
    let id: any;
    if (currentUser) {
      id = currentUser.uid;
    }
    console.log('id ', id)
    let ref = this.firestore.collection('users').doc(id);
    ref.get().subscribe((result) => {
      if (result.exists) {
        this.adminDoc = result.data();
      }
    })
  }

  getServices() {
    return this.helper.services;
  }

  get educators() {
    return this.invitationForm.controls["educators"] as FormArray;
  }

  addEducators() {
    this.educators.push(
      this.fb.group({
        'educatorId': ['', Validators.required],
        'educatorName': ['', Validators.required],
        'service': ['', Validators.required],
        'reccomendedFrequency': <any>['', [Validators.required, Validators.min(1), Validators.max(7)]]
      })
    )
  }

  deleteEducator(index: number) {
    this.educators.removeAt(index);
  }

  onExpertIdSelected(id, index: number) {
    this.educators.at(index).get('educatorName').patchValue(this.getExpertById(id).fullName);

    this.serviceSub = this.adminService.getExpert(id).pipe(take(1)).subscribe(
      (result: AngularFirestoreDocument) => {
        this.services[index] = [...((result[0])['serviceTypes'])];
      }
    );
  }

  getExpertById = (id: string) => this.data.experts.find(expert => expert.id === id);

  inviteConfirmation() {
    this.isSendDisabled = true;
    let invitation: any = {};
    let invitationData = this.invitationForm.value;
    invitation = invitationData;

    const dialogRef = this.dialog.open(ConfirmInvitationDialogComponent, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
      data: { invitation: invitation }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Yes') {
        this.saveInvitation();
      } else {
        this.isSendDisabled = false;
      }
    });
  }

  saveInvitation() {
    this.myExpertsIds = [];
    let invitation: any = {};
    let invitationData = this.invitationForm.value;
    invitation = invitationData;

    // first checking if parent has an existing interakt account or not.
    this.checkForInteraktAccount(invitation);
  }

  checkForInteraktAccount(invitation) {
    let interaktAccountQueryNumber = this.whatsappNumberCountryCode + invitation['whatsappNumber'];

    let interaktAccountRef = this.firestore.collection('interaktaccounts', ref => ref.where('interaktAccountQueryNumber', '==', interaktAccountQueryNumber));
    interaktAccountRef.get().toPromise().then((documents) => {
      // if the parent is new and has not been registered to interakt yet.
      if (documents.empty) {
        let interaktId = this.helper.uuidv4();
        let interaktBody = {
          interaktId: interaktId,
          whatsappNumber: invitation['whatsappNumber'],
          whatsappNumberCountryCode: this.whatsappNumberCountryCode,
          parentName: invitation['name'],
          parentEmail: invitation['eMail'],
        }

        let newInteraktAccountRef = this.firestore.collection('interaktaccounts')
          .add({
            interaktAccountQueryNumber: interaktAccountQueryNumber,
            interaktId: interaktId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: firebase.auth().currentUser.uid,
            createdWhile: 'Sending New Invitation',
            whatsappNumber: invitation['whatsappNumber'],
            whatsappNumberCountryCode: this.whatsappNumberCountryCode,
            parentName: invitation['name'],
            parentEmail: invitation['eMail'],
          }).then(() => {
            // Creating new interakt user
            this.newInteraktUserService.createUser(interaktBody).then(() => {
              // FUNCTION FOR SAVING THE CONSULTATION
              this.sendInvitation(invitation, interaktId)

            }).catch(err => console.log('error creating new interakt user ', err));
          }).catch(err => console.log('error adding interakt account document ', err))
      } else {
        let docs = [];
        documents.forEach((document) => {
          docs.push(document.data());
        });
        let interaktAccount: any = docs[0];

        // FUNCTION FOR SAVING THE CONSULTATION
        this.sendInvitation(invitation, interaktAccount.interaktId);
      }
    })
  }

  sendInvitation(invitation, interaktId){
    let userId = interaktId

    let newInvitationId = this.helper.uuidv4();
    this.id = newInvitationId;
    let docRef = this.firestore.collection("invitations").doc(newInvitationId);
    const createdOn = firebase.firestore.FieldValue.serverTimestamp();

    let verificationNumbers = [];
    let verificationNumber = this.phoneNumberCountryCode + invitation['phoneNumber'];
    verificationNumbers.push(verificationNumber);

    if (invitation['phoneNumber'] != invitation['whatsappNumber']) {
      let verificationNumber2 = this.whatsappNumberCountryCode + invitation['whatsappNumber'];
      verificationNumbers.push(verificationNumber2);
    }

    for (let i = 0; i < this.educators.length; i++) {
      this.myExpertsIds.push(invitation.educators[i]['educatorId']);
    }
    console.log(invitation,"invite",invitation.validity);
    invitation['registered'] = false;
    invitation['id'] = newInvitationId;
    invitation['myExpertsIds'] = this.myExpertsIds;
    invitation['phoneNumberCountryCode'] = this.phoneNumberCountryCode;
    invitation['whatsappNumberCountryCode'] = this.whatsappNumberCountryCode;
    invitation['userId'] = userId;
    invitation['createdOn'] = createdOn;
    invitation['verificationNumbers'] = verificationNumbers;
    // invitation['validity']=this.validity;

    docRef.set(invitation).then(async () => {
      await this.sendParentInvitationNotification(invitation, userId);
      await this.sendAdminInvitationNotification(invitation);

      this.dialogRef.close();
    }).catch(function (error) {
        console.error(error);
      });
  }

  async sendParentInvitationNotification(invitation, userId){
    let expertsNameString: any = "";
    let recommendedFrequency = 0;
    let selectedExperts = [];
    selectedExperts = invitation.educators;
    selectedExperts.forEach((expert) => {
      expertsNameString = expertsNameString + expert.educatorName + ",";
      recommendedFrequency += expert.reccomendedFrequency
    })

    let parentNotificationBody = {
      "userId": userId,
      // "b4149bd7-66e7-4853-bdb7-effef7fc8bee",   
      // 'event': 'New Invite Notification Event - notify parent',
      'event': 'sso_invite_user',
      'traits': {
        'no_of_sessions': invitation.numberOfSessions,
        "valid_till":invitation.validity+" Days",
        'link': "https://mykinderpass.com/auth/signup",
        'phone': invitation.phoneNumber,
        'whatsapp': invitation.whatsappNumber,
        'email': String(invitation.eMail),
        'experts': expertsNameString,
        'frequency': recommendedFrequency
      }
    }

    let response = await this.notificationService.sendWhatsappNotification(parentNotificationBody);
    return response;
  }

  async sendAdminInvitationNotification(invitation){
     // notification for admin.
     let body = {
      'userId': this.adminDoc.interaktId,
      'event': 'invitation sent to parent by admin - notify admin',
      'traits': {
        'parentName': invitation.name,
      }
    }
    let response = await this.notificationService.sendWhatsappNotification(body);
    return response;
  }

  onPhoneNumberCountryChange(event) {
    this.phoneNumberCountryCode = '';
    this.phoneNumberCountryCode = '+' + event.dialCode;
  }

  onWhatsappNumberCountryChange(event) {
    this.whatsappNumberCountryCode = '';
    this.whatsappNumberCountryCode = '+' + event.dialCode;
  }
  changePlan(e) {
    this.invitationForm.controls['numberOfSessions'].setValue(parseInt(e.target.value));
    let packValue;
    if(e.target.value==="24"){ 
      this.customSessionDetails=false;
      packValue=18000;
      this.validity=180; 
      this.invitationForm.controls['validity'].setValue(180);
    }else if(e.target.value==="36"){
      this.customSessionDetails=false;
      packValue=25500;
      this.validity=270;
      this.invitationForm.controls['validity'].setValue(270);
    }else if(e.target.value==="48"){
      this.customSessionDetails=false;
      packValue=32000;
      this.validity=360;
      this.invitationForm.controls['validity'].setValue(360);
    }else if(e.target.value==="custom"){
      this.customSessionDetails=true;
      // this.invitationForm.controls['validity'].setValue(360);
    }
    this.invitationForm.controls['sessionPackValue'].setValue(packValue);
    
  }

  ngOnDestroy() {
    if (this.serviceSub) this.serviceSub.unsubscribe();
  }
  
}
