import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-partially-attended-dialog',
  templateUrl: './partially-attended-dialog.component.html',
  styleUrls: ['./partially-attended-dialog.component.css']
})
export class PartiallyAttendedDialogComponent implements OnInit {
  partiallyAttendeForm:any
  selectedUser:any;
  compensationHeading:any;
  compensationSubHeading:any;
  admin:any;
  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private firestore: AngularFirestore,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PartiallyAttendedDialogComponent>,
    public helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  async ngOnInit(){
    this.partiallyAttendeForm = this.fb.group({
      formPartition : this.fb.array([
        this.fb.group({
          partiallyAttendedBy: ['', Validators.required],
          partiallyAttendedreason: ['', Validators.required]
        }),
        this.fb.group({
          expertCompensation: ['', Validators.required],
          parentCompensation: ['', Validators.required]
        }),
      ])
    });

    if(this.data.session.status == 'Ongoing'){
      this.compensationHeading = "Enter Compensation (if any) for expert and parent"
      this.compensationSubHeading = "EXPERT NOT YET COMPENSATED";
    }else if(this.data.session.status == 'Attended'){
      this.compensationHeading = "Enter correct Compensation for expert and parent"
      this.compensationSubHeading = `EXPERT COMPENSATED (+${this.data.session.sessionValue})`;
    }

    // getting the document of the admin.
    this.getAdminDetails();
  }

  async getAdminDetails() {
    let currentUser = await this.auth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('support/signin');
      return;
    }

    let ref = this.firestore.collection('users').doc(currentUser.uid)
    ref.get().subscribe(result => {
      if (result.exists) {
        this.admin = result.data()
      }
    })
  }

  save(){
    let session = {}
    let formPartition = this.partiallyAttendeForm.value.formPartition;
    formPartition.forEach(data => {
      session = { ...session, ...data }
    })

    session['status'] = "Partially Attended";
    session['adminIdToReportPartiallyAttended'] = this.admin.id;
    session['adminNameToReportPartiallyAttended'] = this.admin.fullName;
    session['partiallyAttendedReportedAt'] = new Date();

    const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.data.session.sessionDate.toDate());
    const ref = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(this.data.session.id);
    ref.update(session).then(()=>{
      this.dialogRef.close({event:'successfull'});
    }).catch(err => console.log('error updating partially attended ', err))
  }

}
