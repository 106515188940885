import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParentsService implements OnInit {

  currentUserDetails: any;
  currentUserId: any;
  myExperts = [];  // this is storing the experts documents which are mapped to this parent.
  myExpertsDetails: any[];  // this is storing the details regarding each expert mapped to this parent.
  balanceDetails: any = {};
  parentBalance: number = 0;
  // numberOfSession: any[];
  notionLink:SafeResourceUrl;

  constructor(private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  async getCurrentUserDetails() {
    let currentUser = await this.afAuth.currentUser;

    if (!currentUser) {
      this.router.navigateByUrl('parent/signin');
      return;
    }

    this.currentUserId = currentUser.uid;

    let docRef = this.afs.collection('users').doc(this.currentUserId).valueChanges().subscribe(result => {
      // console.log('parent ', result)
      this.currentUserDetails = result;
      // console.log('link ', this.currentUserDetails.notionLink);
      this.notionLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentUserDetails.notionLink);
      this.getMyExperts();
    });
  }

  async getMyExperts() {
    this.myExperts = [];
    let l = this.currentUserDetails.myExpertsIds.length;
    for (let i = 0; i < l; i++) {
      let id = this.currentUserDetails.myExpertsIds[i];
      let ref = this.afs.collection('users').doc(id);
      await ref.get().subscribe(this.onExpertResult)
    }
    this.myExpertsDetails = [];
    l = this.currentUserDetails.myExperts.length;
    for (let j = 0; j < l; j++) {
      this.myExpertsDetails.push(this.currentUserDetails.myExperts[j]);
    }

    // this.getNumberOfSessions();
  }

  onExpertResult = (result) => {
    let expert = result.data();
    this.myExperts.push(expert);
  }

  // async getNumberOfSessions() {
  //   let currentUser = await this.afAuth.currentUser;

  //   if (!currentUser) {
  //     this.router.navigateByUrl('parent/signin');
  //     return;
  //   }

  //   this.numberOfSession = [];
  //   for(let i = 0; i < this.currentUserDetails.myExpertsIds.length; i++) {
  //     let id = this.currentUserDetails.myExperts[i].educatorId;

  //     let ref = this.afs.collection('sessions', ref => ref.where('sessionStudentId', '==', currentUser.uid).where('sessionExpertId', '==', id).where('status', '==', 'Scheduled'))
  //     ref.get().subscribe(this.onNumberOfSessionsResult)
  //   }
  // }

  // onNumberOfSessionsResult = (results) => {
  //   let count = 0;
  //   if (!results.empty) {
  //     results.forEach((doc) => {
  //       console.log('session  ', doc.data())
  //       count += 1;
  //     })
  //     this.numberOfSession.push(count);
  //   }
  // }

  async getCurrentUsersBalanceDetails() {
    let currentUser = await this.afAuth.currentUser;
    if (!currentUser) {
      this.router.navigateByUrl('parent/signin');
      return;
    }
    let ref = this.afs.collection('balances').doc(currentUser.uid);
    ref.get().subscribe(result => {
      this.balanceDetails = result.data();
    });
  }

}
