import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.css']
})
export class PaymentFailureComponent implements OnInit {
  showFailureDetails:boolean = true;

  constructor(public helper: HelperService,
              private changeRef: ChangeDetectorRef,
              private ngZone: NgZone,
              private router: Router) { }

  ngOnInit(): void {
    console.log(this.helper.renewalPaymentDetails,"failed")
  }

  openFailureDetails(){
    this.showFailureDetails = true;
    this.changeRef.detectChanges();
  }

  backToPaymentPage(){
    this.ngZone.run(() => this.router.navigateByUrl("parent/payment-page"));
  }

}
