<mat-toolbar class="mainHeading">
  <div class="logo-image">
    <a routerLink="/"><img src="assets/kinderpass-logo.png"></a>
  </div>
  <span *ngIf="user" id="welcomeMessage">Welcome {{user.fullName}} ({{user.role}})</span>
  <span class="spacer"></span>
  <!-- <span>User:{{auth.user|json}}</span> -->
  <!-- <button *ngIf="!loggedIn" mat-button (click)="signIn()">
    Sign In
  </button> -->
  <!-- <button *ngIf="loggedIn" mat-button (click)="goToRoleHome()">
  	Home
  </button>   -->
  <!-- <button mat-button class="contactSupport"><a href="https://wa.me/+919606719826" target="_blank"
      rel="noreferrer noopener" id="getHelp">Get Help</a></button> -->

  <button mat-button class="help">
    <a href="https://wa.me/+919606719826" target="_blank"><img id="helpIcon" src="assets/get_help.svg"></a>
  </button>

  <!-- <button *ngIf="role == 'parent'" mat-button class="faqQuickLinks" (click)="onClickFAQ()"><img id="faq-logo"
      src="assets/FAQ_QuickLinks.svg"></button>
  <button *ngIf="role == 'expert'" mat-button class="faqQuickLinks" (click)="onClickFAQ()"><img id="faq-logo"
      src="assets/FAQ_QuickLinks.svg"></button> -->

  <button *ngIf="role == 'parent'" mat-button class="faqQuickLinks" (click)="onClickFAQ()">
    <mat-icon style="font-size: 17px; padding-top: 3px;">help_outline</mat-icon> <span>Help</span>
  </button>

  <button *ngIf="role == 'expert'" mat-button class="faqQuickLinks" (click)="onClickFAQ()">
    <mat-icon style="font-size: 17px; padding-top: 3px;">help_outline</mat-icon> <span>Help</span>
  </button>


  <button *ngIf="loggedIn" mat-mini-fab (click)="logout()" class="logoutButton">
    <mat-icon class="material-icons-outlined" id="logoutId">
      logout
    </mat-icon>
  </button>
</mat-toolbar>

<!-- class="example-container" -->


<!-- <router-outlet></router-outlet> -->

<!-- For FAQ and Quick Links Side-bar -->
<mat-sidenav-container Style="width: 100%; height: 90%; background-color: white;">
  <mat-sidenav #sidenav mode="over" position="end" style="width: 306px;">
    <div style="display: flex; justify-content: flex-end;">
      <button mat-button (click)="sidenav.toggle()">
        <mat-icon style="cursor: pointer;">close</mat-icon>
      </button>
    </div>
    <div>
      <h3 style="margin-left: 20px;"> <b>Helpful Resources:</b> </h3>
      <ul style="color: #59AADD; list-style-type: disc; margin-left: 40px;">
        <li *ngIf="role=='expert'"> <a class="faqLinkStyles" target="_blank"
            href="https://trusting-traffic-d0c.notion.site/Tactopus-Web-Portal-Guide-For-Experts-e71c09c70bff464eaf661614e798254b">
            KinderPass  Portal Guide</a> </li>
        <li *ngIf="role=='parent'"> <a class="faqLinkStyles" target="_blank"
            href="https://trusting-traffic-d0c.notion.site/Tactopus-Web-Portal-Guide-6d2f0e16172442a1bbe79a7434a06c05">
            KinderPass  Portal Guide</a> </li>
        <li *ngIf="role=='expert'"> <a class="faqLinkStyles" target="_blank"
            href="https://chat.whatsapp.com/F36JensNYlFKZ28Ka3oVBl">
            KinderPass  Experts Groups</a> </li>
        <li *ngIf="role=='expert'"> <a class="faqLinkStyles" target="_blank"
            href="https://drive.google.com/drive/folders/1I3kcIis05Ov63Pm7wBySnEw-98qE4wrp">
            KinderPass  TLMS</a></li>
        <!-- <li *ngIf="role=='parent'"> <a class="faqLinkStyles" href="">
          FAQs</a></li> -->
        <li *ngIf="role=='parent'"> <a class="faqLinkStyles" target="_blank" href="https://mykinderpass.com/parenting-tips">
          KinderPass  Blogs</a></li>
        <!-- <li *ngIf="role=='parent'"> <a class="faqLinkStyles" target="_blank"
            href="https://www.tactopus.com/early-childhood-developmental-screeners">
            Developmental Screeners</a></li> -->
        <li> <a class="faqLinkStyles" target="_blank" href="https://mykinderpass.com/terms-and-conditions">
          KinderPass  Terms and Conditions</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<footer class="footer">
  <!-- <a class="right" routerLink="/support/signin">Support Login</a> -->
</footer>