<!-- payment input view -->
<div class="paymentMainContainer">

   <!-- loading screen starts here -->
                <div *ngIf="processingPayment || isLoading" class="text-center my-4" style="margin-top: 35px; margin-bottom: 20px;">
                  <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div>
                    <h2 style="color: black;">Loading ....</h2>
                    <!-- <h3 style="color: #A5A5A5">Give us a second as we load all the available slots</h3> -->
                  </div>
                </div>
    <!-- loading screen ends here -->

  <div *ngIf="!processingPayment">
    <div class="heading" *ngIf="!isLoading">
      <a matButton routerLink="/parent/dashboard" id="back-button">
        <mat-icon>arrow_back</mat-icon>
      </a>
      <h2>Top up details</h2>
    </div>
    <p class="planHeding" *ngIf="!isLoading">
      Choose a Plan that is best for your Child
    </p>
    
    <div class="mainPlansDiv">
      <div class="pricingPlanCard" *ngFor="let plan of pricingPlans">
        <div class="discountDiv">Save {{plan.discountPercentage}}</div>
        <div class="priceText">₹{{plan.discountedPrice}}
          <span class="pricingDiscountText">₹{{plan.price}}</span>
        </div>
        <div class="pricingValidityDiv" >Validity: <span style="color: #141719; font-weight:600;">{{plan.validity}} Days</span></div>
        <div><div class="pricingDetailsText"><img src="../../assets/greenWhatsappIcon.svg"/>&nbsp; &nbsp;{{plan.sessionCount}} Sessions</div></div>
        <div class="pricingDetailsText"><img src="../../assets/greenWhatsappIcon.svg"/>&nbsp;&nbsp;₹{{plan.pricePerSession}} Per session</div>
        <div class="pricingDetailsText"><img src="../../assets/greenWhatsappIcon.svg"/>&nbsp;&nbsp;You save ₹{{plan.amountSaved}}</div>
        <button [ngClass]="{activeBuyPlanBtn:plan.isPopular}" class="buyPlanBtn" (click)="proceedToPay($event,plan)" [disabled]="processingPayment">Pay ₹{{plan.discountedPrice}}</button>
      </div>
    </div>
    

    <!-- <p style="margin-bottom: 4px; font-family: Poppins">
      Number of sessions per week
    </p>
    <input class="yourrecommended" matInput type="number" min="1" step="1"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
      placeholder="Your recommended sessions: {{ recommendedFrequency }}" [(ngModel)]="numberOfSessionsPerWeek"
      (ngModelChange)="changeNumberOfSessionsPerWeek($event)" autocomplete="off" />
    <br /> -->

    <!-- <p style="margin-bottom: 6px" class="duration">Plans</p> -->

    <!-- <div > -->
      <!-- <div fxLayout="col col-50" class="weekInputContainer" *ngFor="let plan of pricingPlans">
        <label class="setWeekBtn" (click)="setWeekDuration(4)">
          <div style="display: flex; align-items: center; justify-content: start">
            <span class="radioInput">
              <input style="margin-top: 4px" type="radio" name="weeks" />
            </span>
            <span style="margin-bottom: 2px"> {{plan.validity}} </span>
          </div>
          <span class="numberofsessions" *ngIf="'numberOfSessionPerWeek' && 'numberOfWeeks'">
            {{plan.sessionCount}} sessions</span>
        </label>
      </div> -->
    <!-- </div> -->
    
    <!-- <div class="weekInputContainer">
      <label class="setWeekBtn" (click)="setWeekDuration(4)">
        <div style="display: flex; align-items: center; justify-content: start">
          <span class="radioInput">
            <input style="margin-top: 4px" type="radio" name="weeks" />
          </span>
          <span style="margin-bottom: 2px"> 6 Months </span>
        </div>
        <span class="numberofsessions" *ngIf="'numberOfSessionPerWeek' && 'numberOfWeeks'">
          24 sessions</span>
      </label>

      <label class="setWeekBtn" (click)="setWeekDuration(8)">
        <div style="display: flex; align-items: center; justify-content: start">
          <span class="radioInput">
            <input style="margin-top: 4px" type="radio" name="weeks" />
          </span>
          <span style="margin-bottom: 2px"> 9 Months </span>
        </div>
        <span class="numberofsessions" *ngIf="'numberOfSessionPerWeek' && 'numberOfWeeks'">
          36 sessions</span>
      </label>
    </div>

    <div class="weekInputContainer" style="margin-bottom: 25px">
      <label class="setWeekBtn" (click)="setWeekDuration(12)">
        <div style="display: flex; align-items: center; justify-content: start">
          <span class="radioInput">
            <input style="margin-top: 4px" type="radio" name="weeks" />
          </span>
          <span style="margin-bottom: 2px"> 12 Months </span>
        </div>
        <span class="numberofsessions" *ngIf="'numberOfSessionPerWeek' && 'numberOfWeeks'">
           48 sessions</span>
      </label>

      <label class="setWeekBtn">
        <span class="manualRadioInput">
          <input type="radio" name="weeks" id="premium" />
        </span>
        <div style="width: 186px; margin-left: -13px">
          <input class="preferredweeks check-premium" type="number" [(ngModel)]="manuallyEnteredWeeks"
            (ngModelChange)="addManualWeekNumber($event)" autocomplete="off" placeholder="Or enter your preferred weeks"
            min="1" (focus)="onManualWeekInputFocus()" step="1"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
        </div>
        <span class="numberofsessions" *ngIf="'numberOfSessionPerWeek' && 'manuallyEnteredWeeks'">{{
          numberOfSessionsPerWeek * manuallyEnteredWeeks }} sessions</span>
      </label>
    </div> -->

  </div>

  <!-- order summary starts here -->
  <!-- <div class="order-summary" *ngIf="totalNumberOfSessions">
    <h2 class="text-order-summary">Order Summary</h2>

    <div class="total-sessions">
      <label>Total Sessions</label>
      <span class="total_sessions">{{ totalNumberOfSessions }}</span>
    </div>

    <div class="total-sessions">
      <label class="rate-applied">Rate applied</label>
      <div class="session-price">
        <span class="showDiscount" *ngIf="isDiscountApplied == true">799</span>
        {{ perSessionRate }}/session

        <div class="session-rate-card">
          <a role="button" style="cursor: pointer; text-decoration: underline" (click)="openRateCardDialog()">Session
            rate card
          </a>
          <mat-icon style="font-size: 12px; text-align: end; width: 18px">info</mat-icon>
        </div>
      </div>
    </div>

    <div class="total-sessions">
      <label>Total amount</label>
      <span class="total_sessions">{{ payableAmount }}</span>
    </div>
    <div class="save-amount" *ngIf="isDiscountApplied == true">
      You saved {{ discountApplied }} amount
    </div>
  </div> -->

  <!-- <div class="cancelpay">
    <button class="cancelpaymentbutton" (click)="cancelPayment()">Cancel</button>
    <button class="pay-btn-enabled" [ngClass]="
        enablePayButton === true ? 'pay-btn-enabled' : 'pay-btn-disabled'
      " (click)="proceedToPay($event)" [disabled]="!enablePayButton">
      Pay {{ payableAmount }}<span *ngIf="enablePayButton">/-</span>
    </button>
  </div> -->
</div>



<!-- After Successfull RazorPay Payment -->
<!-- <div *ngIf="isPaymentSuccess" class="paymentSuccessContainer"> -->
<!-- <app-multi-session [viaPaymentFlow]="true" [paymentData]="paymentResolvedData">
  </app-multi-session> -->

<!-- <div class="heading">
    <a matButton routerLink="/parent/dashboard" id="back-button">
      <mat-icon>arrow_back</mat-icon>
    </a>
  </div> -->

<!-- success message view -->
<!-- <div *ngIf="isPaymentProcessed && isPaymentSuccess" class="successMessageContainer">
    <div class="successIconContainer">
      <mat-icon class="successIcon">check_circle_outline</mat-icon>
    </div>

    <div style="width: 87%;">
      <div style="display: flex; justify-content: space-between; margin-bottom: 6px; align-items: center;">
        <span style="font-weight: bold; font-size: 17px;">Payment was successful!</span>
        <button class="paymentDetailsBtn" (click)="onPaymentDetailsClicked()"
          style="font-weight: bold; text-decoration: underline; font-size: 13px;">Details</button>
      </div>

      <div style="display: flex; justify-content: space-between;">
        <span style="font-weight: 400; font-size: 13px;">{{payableAmount}} INR</span>

        <div style="display: flex; justify-content: space-between;">
          <span style="font-weight: 400; font-size: 13px;">{{paymentTime | date:'h:mm a
            dd/MM/YYYY'}}</span>
        </div>

      </div>

    </div>
  </div> -->


<!-- success details view -->
<!-- <div *ngIf="showFullPaymentDetails == true" class="successDetailsContainer">
    <div>
      <div class="paymentsuccesspage">
        <div style="display: flex; justify-content: center; align-items:center; margin-bottom: 20px; margin-top: 20px;">
          <mat-icon class="successIcon">check_circle_outline</mat-icon>
        </div>

        <div style="font-weight: bold; font-size: 17px; text-align: center;">
          Payment was successful!
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between; margin-top: 40px">
          <span class="userDetailName">Name</span>
          <span class="userDetailValue">{{parent.fullName}}</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName">Session per week</span>
          <span class="userDetailValue">{{numberOfSessionsPerWeek}}</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName">Duration</span>
          <span class="userDetailValue">{{duration}} week</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName">Rate Applied</span>
          <span class="userDetailValue">{{perSessionRate}} INR</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName">Total Sessions</span>
          <span class="userDetailValue">{{totalNumberOfSessions}}</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName amount" style="font-weight: bold;">Amount</span>
          <span class="userDetailValue amount" style="font-weight: bold;">{{payableAmount}} INR</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName timeUserDetailsNamePaymentSuccess">Time and date</span>
          <span class="userDetailValue timeandDateDetailsValuePaymentSuccess">{{paymentTime | date:'h:mm a
            dd/MM/YYYY'}}</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName">Transaction ID </span>
          <span class="userDetailValue">{{paymentId}}</span>
        </div>

        <div class="paymentDetailsDiv" style="display: flex; justify-content: space-between">
          <span class="userDetailName">Tactopus Invoice Id</span>
          <span class="userDetailValue">{{invoiceId}}</span>
        </div>
      </div>

      <div style="display: flex; justify-content:flex-end; padding-left: 25px; margin: 20px;">
        <button matButton class="right backbutton" (click)="hidePaymentDetails()">Back</button>
      </div>

    </div>
  </div> -->

<!-- </div> -->