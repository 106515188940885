import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ToastrModule } from 'ngx-toastr';
import { MatExpansionModule } from '@angular/material/expansion';

import { SigninComponent } from './signin/signin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { ParentRegisterComponent } from './parent-register/parent-register.component';
import { ExpertRegisterComponent } from './expert-register/expert-register.component';
import { SupportDashboardComponent } from './support-dashboard/support-dashboard.component';
import { ExpertDashboardComponent } from './expert-dashboard/expert-dashboard.component';
import { ExpertProfileComponent, ExpertProfileDialog, deleteLeaveDialog, leaveConfirmationDialog } from './expert-dashboard/expert-profile.component';
import { ParentDashboardComponent, InsufficientBalanceDialog ,ValidityExpiredDialog} from './parent-dashboard/parent-dashboard.component';
import { ParentProfileComponent, ParentProfileDialog } from './parent-dashboard/parent-profile.component';

import { SessionDialog } from './expert-dashboard/session.component';
import { MeetingComponent } from './meeting/meeting.component';
import { StudentsComponent } from './students/students.component';
import { GoalsComponent } from './goals/goals.component';
import { CancelDialog } from './cancel-session/cancel/cancel.component';
import { RescheduleDialog } from './reschedule-session/reschedule/reschedule.component';
import { InviteDialog } from './invite/invite.component';
import { consultationConfirmationDialog, consultationTimerRunoutDialog } from './sales-admin-new-session-request/sales-admin-new-session-request.component'
import { NewSessionRequestComponent } from './new-session-request/new-session-request.component';
import { ExpertNewSessionComponent } from './expert-new-session/expert-new-session.component';
import { InvalidLoginComponent } from './invalid-login/invalid-login.component';
import { consultationDataDownloadDialog, SalesAdminDashboardComponent } from './sales-admin-dashboard/sales-admin-dashboard.component';
import { SalesAdminNewSessionRequestComponent } from './sales-admin-new-session-request/sales-admin-new-session-request.component';
import { AdminDashboardComponent, sessionDataDownloadDialog } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminCancelConfirmationComponent, AdminCancelSessionComponent } from './admin/admin-cancel-session/admin-cancel-session.component';
import { AdminNewSessionComponent } from './admin/admin-new-session/admin-new-session.component';
import { ActivityChangeDialog, AdminParentsTabComponent, SessionPackDialog2 } from './admin/admin-parents-tab/admin-parents-tab.component';
import { ActivityChangeDialog2, AdminSelectedParentComponent, SessionPackDialog } from './admin/admin-parents-tab/admin-selected-parent/admin-selected-parent.component';
import { AdminExpertTabComponent } from './admin/admin-expert-tab/admin-expert-tab.component';
import { PartyConfirmationDialogComponent } from './sales-admin-dialogs/party-confirmation-dialog/party-confirmation-dialog.component';
import { FinalConfirmationDialogComponent } from './sales-admin-dialogs/final-confirmation-dialog/final-confirmation-dialog.component';
import { SuccessDialogComponent } from './sales-admin-dialogs/success-dialog/success-dialog.component';
import { NoShowConfirmationDialogComponent, NoShowConfirmationDialogParentComponent } from './sales-admin-dialogs/no-show-confirmation-dialog/no-show-confirmation-dialog.component';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ParentSigninComponent } from './parent-signin/parent-signin.component';
import { ExpertSigninComponent } from './expert-signin/expert-signin.component';
import { SalesAdminSigninComponent } from './sales-admin-signin/sales-admin-signin.component';
import { SupportAdminSigninComponent } from './support-admin-signin/support-admin-signin.component';
import { AdminExpertSelectedComponent, SessionPackDialogExpert, cancelLeaveDialog, verifyStatusDialog, expertActivityStatusDialog } from './admin/admin-expert-tab/admin-expert-selected/admin-expert-selected.component';
import { timerRunoutDialog, sessionNotBookedDialog, loadingMessageDialog, stepChangeMessageDialog } from './multi-session/multi-session.component';
import { SalesAdminRegisterComponent } from './sales-admin-register/sales-admin-register.component';
import { SupportAdminRegisterComponent } from './support-admin-register/support-admin-register.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ZoomIdUnavailableDialogComponent } from './zoom-id-unavailable-dialog/zoom-id-unavailable-dialog.component';
import { ParentSignin2Component } from './parent-signin2/parent-signin2.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { TemporaryFunctionsComponent } from './temporary-functions/temporary-functions.component';
import { PartiallyAttendedDialogComponent } from './partially-attended-dialog/partially-attended-dialog.component';
import { PopUpsDisableDialogComponent } from './pop-ups-disable-dialog/pop-ups-disable-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ConfirmInvitationDialogComponent } from './confirm-invitation-dialog/confirm-invitation-dialog.component';
import { MultiSessionComponent } from './multi-session/multi-session.component';
import { ProgramAdminSigninComponent } from './program-admin-signin/program-admin-signin.component';
import { ProgramAdminRegisterComponent } from './program-admin-register/program-admin-register.component';
import { PaymentComponent, SessionRateDialog } from './payment/payment.component';
import { FindExpertsComponent } from './find-experts/find-experts.component';
import { ParentSsoLoginComponent } from './parent-sso-login/parent-sso-login.component';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { FeedbackCompletedComponent } from './feedback-completed/feedback-completed.component';
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';
import { JwPaginationComponentComponent } from './jw-pagination-component/jw-pagination-component.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
    },
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        size: 'invisible'
      },
      defaultCountry: 'IN'
    }
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    NotFoundComponent,
    HomeComponent,
    ParentRegisterComponent,
    ExpertRegisterComponent,
    SupportDashboardComponent,
    ExpertDashboardComponent,
    ExpertProfileComponent,
    ExpertProfileDialog,
    deleteLeaveDialog,
    leaveConfirmationDialog,
    ParentDashboardComponent,
    ParentProfileComponent,
    ParentProfileDialog,
    SessionDialog,
    MeetingComponent,
    StudentsComponent,
    GoalsComponent,
    CancelDialog,
    RescheduleDialog,
    InviteDialog,
    NewSessionRequestComponent,
    ExpertNewSessionComponent,
    InvalidLoginComponent,
    SalesAdminDashboardComponent,
    SalesAdminNewSessionRequestComponent,
    AdminDashboardComponent,
    AdminCancelSessionComponent,
    AdminCancelConfirmationComponent,
    AdminNewSessionComponent,
    AdminParentsTabComponent,
    AdminSelectedParentComponent,
    AdminExpertTabComponent,
    SessionPackDialog,
    SessionPackDialog2,
    SessionPackDialogExpert,
    InsufficientBalanceDialog,
    ValidityExpiredDialog,
    PartyConfirmationDialogComponent,
    FinalConfirmationDialogComponent,
    SuccessDialogComponent,
    NoShowConfirmationDialogComponent,
    NoShowConfirmationDialogParentComponent,
    consultationConfirmationDialog,
    ParentSigninComponent,
    ExpertSigninComponent,
    SalesAdminSigninComponent,
    SupportAdminSigninComponent,
    AdminExpertSelectedComponent,
    consultationTimerRunoutDialog,
    cancelLeaveDialog,
    verifyStatusDialog,
    expertActivityStatusDialog,
    sessionDataDownloadDialog,
    consultationDataDownloadDialog,
    timerRunoutDialog,
    sessionNotBookedDialog,
    loadingMessageDialog,
    stepChangeMessageDialog,
    SalesAdminRegisterComponent,
    SupportAdminRegisterComponent,
    ZoomIdUnavailableDialogComponent,
    ParentSignin2Component,
    TemporaryFunctionsComponent,
    PartiallyAttendedDialogComponent,
    PopUpsDisableDialogComponent,
    ConfirmInvitationDialogComponent,
    MultiSessionComponent,
    ActivityChangeDialog,
    ActivityChangeDialog2,
    ProgramAdminSigninComponent,
    ProgramAdminRegisterComponent,
    PaymentComponent,
    SessionRateDialog,
    FindExpertsComponent,
    ParentSsoLoginComponent,
    FeedbackDialogComponent,
    FeedbackCompletedComponent,
    FeedbackPageComponent,
    JwPaginationComponentComponent,
    PaymentFailureComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    JwPaginationModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatBadgeModule,
    MatTabsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatChipsModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpClientModule,
    Ng2TelInputModule,
    MatExpansionModule,
    NgOtpInputModule,
    ClipboardModule,
    Ng2SearchPipeModule,
    MatSidenavModule,
    NativeDateModule,
    ToastrModule.forRoot(),
    InfiniteScrollModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, TitleCasePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
  
}