import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HelperService } from '../helper.service';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class MultiExpertSlotsService {
  expertsWithSlots: any[];
  expertListWithSlots: any = {};

  constructor(private firestore: AngularFirestore, private helper: HelperService) { }

  getSlots(expertList, preferredTimes, selectedDate, slotDuration) {
    let experts = [];
    experts = expertList;
    this.expertsWithSlots = [];
    this.expertListWithSlots = {};

    let date = new Date(selectedDate);
    date.setHours(0,0,0,0)

    experts.forEach(expert => {
      this.getExpertBlockedSlots(expert, preferredTimes, date, slotDuration)
    })
  }

  // get blocked slots of the expert
  getExpertBlockedSlots(expert, preferredTimes, date, slotDuration) {
    let blockRef = this.firestore.collection('blockedslots', ref => ref.where('expertId', '==', expert.id));
    blockRef.get().subscribe(this.onBlockedSlotsResult.bind(this, expert, preferredTimes, date, slotDuration))
  }

  // After getting blocked slots documents.
  onBlockedSlotsResult = (expert, preferredTimes, date, slotDuration, results) => {
    let selectedExpertBlockedSlots = [];
    if (!results.empty) {
      results.forEach(result => {
        selectedExpertBlockedSlots.push(result.data())
      })
    }

    // getting expert's leaves.
    let docRef = this.firestore.collection('leaves', ref => ref.where('leaveBy', '==', expert.id));
    docRef.get().subscribe(this.onLeavesResult.bind(this, expert, preferredTimes, date, slotDuration, selectedExpertBlockedSlots))
  }

  // After getting leaves documents.
  onLeavesResult = (expert, preferredTimes, date, slotDuration, selectedExpertBlockedSlots, results) => {
    let selectedExpertLeaves = [];
    if (!results.empty) {
      results.forEach(result => {
        selectedExpertLeaves.push(result.data())
      })
    };

    // getting the expert's sessions
    let docRef = this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', expert.id).where('status', '==', 'Scheduled'));
    docRef.get().subscribe(this.onSessionResult.bind(this, expert, preferredTimes, date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves))
  }

  // After getting scheduled sessions documents
  onSessionResult = (expert, preferredTimes, date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves, results) => {
    let dateStamp = firebase.firestore.Timestamp.fromDate(date)
    let scheduledSessions = [];

    if (!results.empty) {
      results.forEach(result => {
        let session = result.data();

        let sessionDate: Date = session['sessionDate'].toDate();
        let exactSessionDate = this.helper.istTime2(sessionDate);

        let exactSessionTimeStamp = firebase.firestore.Timestamp.fromDate(exactSessionDate);

        if ((session.status == 'Scheduled' || session.status == "Session Request") && (exactSessionTimeStamp.seconds == dateStamp.seconds)) {
          let startTime = session.startTime.split(":");
          const startingHour = parseInt(startTime[0], 10);
          const startingMinute = parseInt(startTime[1], 10);
          startTime = (startingHour * 60) + startingMinute;

          let endTime = session.endTime.split(":");
          const endingHour = parseInt(endTime[0], 10);
          const endingMinute = parseInt(endTime[1], 10);
          endTime = (endingHour * 60) + endingMinute;
          scheduledSessions.push({ startTime, endTime });
        }
      })
    }

    // getting scheduled consultations of the teacher.
    let docRef = this.firestore.collectionGroup('consultations', ref => ref.where('sessionExpertId', '==', expert.id).where('status', '==', 'Scheduled'));
    docRef.get().subscribe(this.onConsultationsResult.bind(this, expert, preferredTimes, date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions));
  }

  // after getting scheduled consultations of the teacher.
  onConsultationsResult = (expert, preferredTimes, date, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, results) => {
    let scheduledConsultations = [];
    let dateStamp = firebase.firestore.Timestamp.fromDate(date)
    console.log('datestamp is ', dateStamp)

    if (!results.empty) {
      results.forEach(result => {
        let consultation = result.data();

        let sessionDate: Date = consultation['sessionDate'].toDate();
        let exactSessionDate = this.helper.istTime2(sessionDate);

        let exactSessionTimeStamp = firebase.firestore.Timestamp.fromDate(exactSessionDate);
        console.log('timestamp is ', exactSessionTimeStamp)

        if (exactSessionTimeStamp.seconds == dateStamp.seconds) {
          let startTime = consultation.startTime.split(":");
          const startingHour = parseInt(startTime[0], 10);
          const startingMinute = parseInt(startTime[1], 10);
          startTime = (startingHour * 60) + startingMinute;

          let endTime = consultation.endTime.split(":");
          const endingHour = parseInt(endTime[0], 10);
          const endingMinute = parseInt(endTime[1], 10);
          endTime = (endingHour * 60) + endingMinute;
          scheduledConsultations.push({ startTime, endTime })
        }
      })
    }

    let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    let day = days[date.getDay()];

    // Calling getOpenSlots function to get avaiable slots.
    this.getOpenSlots(expert, preferredTimes, date, day, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, scheduledConsultations);
  }

  // To generate open slots from given details
  async getOpenSlots(expert, preferredTimes, date, day, slotDuration, selectedExpertBlockedSlots, selectedExpertLeaves, scheduledSessions, scheduledConsultations) {
    let workingHoursOfThisDay = this.getWorkingHours(expert, day);
    let expertWithSlots: any = {};
    let allSlots = [];
    let preferredSlots = [];

    workingHoursOfThisDay.forEach(a => {
      for (let slot = a.startTime; slot <= a.endTime - slotDuration; slot += slotDuration) {
        // creating label to store as slots
        let slotStartTimeString = this.createTimeLabel(slot);
        let slotEndTimeString = this.createTimeLabel(slot + slotDuration);

        // creating the unique id for checking the blocked slots.
        const dateString = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
        let blockSlotId = dateString + slotStartTimeString + '-' + slotEndTimeString + expert.id;

        // check slot to be out of the blocked slots
        let outOfBlockedSlots = this.isOutOfBlockedSlots(blockSlotId, selectedExpertBlockedSlots);
        if (!outOfBlockedSlots) {
          continue;
        } else {
          // check slot to be out of leave hours range
          let outOfLeaveRange = this.isOutOfLeaveRange(date, slot, slotDuration, selectedExpertLeaves);
          if (!outOfLeaveRange) {
            continue;
          } else {
            // check slot to be out of pre scheduled sessions
            let outOfScheduledHours = this.isOutOfScheduledHours(slot, slotDuration, scheduledSessions);
            if (!outOfScheduledHours) {
              continue;
            } else {
              // Check slot to be out of pre scheduled consultations
              let outOfScheduledConsultation = this.isOutOfScheduledConsultations(slot, slotDuration, scheduledConsultations);
              if (!outOfScheduledConsultation) {
                continue;
              } else {
                // storing this slot in all slots array.
                allSlots.push({ label: slotStartTimeString + '-' + slotEndTimeString + " IST", value: slotStartTimeString });

                let isPreferredSlot = this.checkForPreferredSlot(slot, slotDuration, preferredTimes);
                if (isPreferredSlot) {
                  preferredSlots.push({ label: slotStartTimeString + '-' + slotEndTimeString + " IST", value: slotStartTimeString })
                }

              }
            }
          }
        }
      }

    });

    expertWithSlots.allSlots = allSlots;
    expertWithSlots.preferredSlots = preferredSlots;
    expertWithSlots.id = expert.id;
    expertWithSlots.date = date;

    if (!this.expertListWithSlots[expert.id]) {
      this.expertListWithSlots[expert.id] = expertWithSlots;
    }
    // console.log("Checking expertListWithSlots: ", this.expertListWithSlots);
  }

  // function to check if the slot is selected as preferred slot or not.
  checkForPreferredSlot(slot, slotDuration, preferredTimes) {
    let isPreferredSlot: boolean = false;
    let slotEndTime = slot + slotDuration;

    let selectedTimeRanges = [];
    selectedTimeRanges = preferredTimes;

    selectedTimeRanges.forEach((time) => {
      let selectedStartTime = time.split(":");
      // to get preferred time range's starting time and ending time in minutes
      const startingHour = parseInt(selectedStartTime[0], 10);
      selectedStartTime = (startingHour * 60);
      let selectedEndTime = selectedStartTime + 120;

      if (slot >= selectedStartTime && slotEndTime <= selectedEndTime) {
        isPreferredSlot = true;
        return isPreferredSlot
      }
    })

    return isPreferredSlot;
  }

  // function to create time label from minutes
  createTimeLabel(slot) {
    let slotTimeString = slot;
    let slotStartHour = Math.floor(slot / 60);
    let slotStartMinute = (slot % 60);
    if (slotStartMinute == 0) {
      slotTimeString = slotStartHour + ":" + slotStartMinute + '0';
    } else {
      slotTimeString = slotStartHour + ":" + slotStartMinute;
    }

    return slotTimeString;
  }

  // To store the working hours of the teacher in our array as minute.
  getWorkingHours = (teacher, day) => {
    let workingHoursOfTheWeek = teacher.workingHours;
    let workingHoursOfThisDay = [];
    workingHoursOfTheWeek.forEach((workingHourOfTheDay) => {
      if (workingHourOfTheDay.weekday == day) {
        let startTime = workingHourOfTheDay.startTime.split(":");
        const startingHour = parseInt(startTime[0], 10);
        const startingMinute = parseInt(startTime[1], 10);
        startTime = (startingHour * 60) + startingMinute;

        let endTime = workingHourOfTheDay.endTime.split(":");
        const endingHour = parseInt(endTime[0], 10);
        const endingMinute = parseInt(endTime[1], 10);
        endTime = (endingHour * 60) + endingMinute;
        workingHoursOfThisDay.push({ startTime: startTime, endTime: endTime });
      }
    })
    workingHoursOfThisDay.sort(this.compareTime)
    // this.workingHoursOfThisDay.sort(this.compareTime)
    return workingHoursOfThisDay;
  }

  // function to sort the workingHours array
  compareTime(a, b) {
    if (a.startTime < b.startTime) {
      return -1;
    } else if (a.startTime > b.startTime) {
      return 1;
    } else {
      return 0;
    }
  }

  // To check if slot is out of blocked slots.
  isOutOfBlockedSlots(blockSlotId, selectedExpertBlockedSlots) {
    let outOfBlockedSlots: Boolean = true;
    if (selectedExpertBlockedSlots.length == 0) {
      return outOfBlockedSlots;
    }

    for (let i = 0; i < selectedExpertBlockedSlots.length; i++) {
      let id = selectedExpertBlockedSlots[i].id;
      if (blockSlotId == id) {
        console.log(blockSlotId, 'is already blocked')
        outOfBlockedSlots = false;
        break;
      }
    }

    return outOfBlockedSlots;
  }

  // To check if the slot is out of leave range or not.
  isOutOfLeaveRange(date, slot, slotDuration, selectedExpertLeaves) {
    let outOfLeaveRange: Boolean = true;
    if (selectedExpertLeaves.length == 0) {
      return outOfLeaveRange;
    }

    let dateNumber = date.getDate();
    let dateMonth = date.getMonth();
    let dateYear = date.getFullYear();
    let dateHour = Math.floor(slot / 60);
    let dateMinute = slot % 60;
    dateMinute += 2;

    let slotStartTime = new Date(dateYear, dateMonth, dateNumber, dateHour, dateMinute);
    let slotStartTimeStamp = firebase.firestore.Timestamp.fromDate(slotStartTime);

    let slotEnd = slot + slotDuration;
    dateHour = Math.floor(slotEnd / 60);
    dateMinute = slotEnd % 60;

    let slotEndTime = new Date(dateYear, dateMonth, dateNumber, dateHour, dateMinute);
    let slotEndTimeStamp = firebase.firestore.Timestamp.fromDate(slotEndTime);


    for (let k = 0; k < selectedExpertLeaves.length; k++) {

      let leaveStartDateTime: any = this.helper.istTime(selectedExpertLeaves[k].leaveStartDateTime.toDate())
      let leaveStartDateTimeStamp = firebase.firestore.Timestamp.fromDate(leaveStartDateTime);

      let leaveEndDateTime: any = this.helper.istTime(selectedExpertLeaves[k].leaveEndDateTime.toDate())
      let leaveEndDateTimeStamp = firebase.firestore.Timestamp.fromDate(leaveEndDateTime);

      if ((slotEndTimeStamp <= leaveStartDateTimeStamp) || (slotStartTimeStamp >= leaveEndDateTimeStamp)) {
        outOfLeaveRange = true;
      } else {
        outOfLeaveRange = false;
        break;
      }
    }
    return outOfLeaveRange;
  }

  // To check if slot is out of pre scheduled hours or not.
  isOutOfScheduledHours(slot, slotDuration, scheduledSessions) {
    let outOfScheduledHours: boolean = true;
    let slotEnd = slot + slotDuration;

    for (let m = 0; m < scheduledSessions.length; m++) {
      if ((slotEnd <= scheduledSessions[m].startTime) || (slot >= scheduledSessions[m].endTime)) {
        outOfScheduledHours = true;
      } else {
        outOfScheduledHours = false;
        break;
      }
    }
    return outOfScheduledHours;
  }

  // To check if slot is out of pre scheduled consultations or not.
  isOutOfScheduledConsultations(slot, slotDuration, scheduledConsultations) {
    let outOfScheduledConsultation: boolean = true;
    let slotEnd = slot + slotDuration;

    for (let m = 0; m < scheduledConsultations.length; m++) {
      if ((slotEnd <= scheduledConsultations[m].startTime) || (slot >= scheduledConsultations[m].endTime)) {
        outOfScheduledConsultation = true;
      } else {
        outOfScheduledConsultation = false;
        break;
      }
    }
    return outOfScheduledConsultation;
  }

}
