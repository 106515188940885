import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-expert-register',
  templateUrl: './expert-register.component.html',
  styleUrls: ['./expert-register.component.css']
})
export class ExpertRegisterComponent implements OnInit {

  user:any;
  registered:boolean;
  verified:boolean;
  
  constructor(public firestore: AngularFirestore,
              public auth: AngularFireAuth) {    
  }

  ngOnInit(): void {

    // this.auth.onAuthStateChanged(user=>{
    //   if(user){
    //     //Check if registration form already exists
    //     this.getUser(user);
    //    }
    // })

     //redirecting to new thrive expert dashboard
    window.location.href = "https://thrive-staging.mykinderpass.com/expert/signin";
  }
  getUser(user){
    var id = user.uid;
    var docRef = this.firestore.collection("users").doc(id);
    docRef.get().subscribe(this.onUserResult);
  }

  onUserResult = (result)=>{
    if(result.exists){
      let user:any = result.data();
      this.user = user;
      if('registered' in user){
        this.registered = user['registered'];
      }
    }      
    return this.user;
  }

  onCompleted(){
    this.registered = true;
    this.verified = false;
  }
}
