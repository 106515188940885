import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/app';
import { HelperService } from 'src/app/helper.service';
import { CancelDialog } from 'src/app/cancel-session/cancel/cancel.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.css']
})
export class RescheduleDialog implements OnInit {
  message: string;
  cancelTimeLimit = 240;
  cancelTimeLimitExpert = 720;
  isRescheduleable: boolean = true;
  subMessage: string = '';
  reasonForm:any;

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private helper: HelperService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RescheduleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.reasonForm = this.fb.group({
      rescheduleReason: ['', Validators.required],
      additionalDetails: '',
    })

    this.timeComparison();
    this.reschedulePenalty();
  }

  getRescheduleReasons(){
    if(this.data.reschedulingPerson == "Parent"){
      return this.helper.parentCancellationAndReschedulingReason;
    } else if(this.data.reschedulingPerson == "Expert"){
      return this.helper.expertCancellationAndReschedulingReason;
    }
  }

  async rescheduleSession() {
    const rescheduledBy = await firebase.auth().currentUser.uid;
    let rescheduledByName;
    let rescheduledWith;
    let rescheduledWithName;
    let reschedulingPerson: string;

    // if parent is requesting to reschedule the session.
    if (rescheduledBy == this.data.session.sessionStudentId) {
      rescheduledByName = this.data.session.sessionParentName;
      rescheduledWith = this.data.session.sessionExpertId;
      rescheduledWithName = this.data.session.sessionExpertName;
      reschedulingPerson = "parent";
      // if expert is requesting to reschedule the session.
    } else if (rescheduledBy == this.data.session.sessionExpertId) {
      rescheduledByName = this.data.session.sessionExpertName;
      rescheduledWith = this.data.session.sessionStudentId;
      rescheduledWithName = this.data.session.sessionParentName;
      reschedulingPerson = "expert";
    }

    let reasonForm: any = {};
    reasonForm = this.reasonForm.value;
    let reason = reasonForm.rescheduleReason;
    let additionalDetails = reasonForm.additionalDetails;

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);
    this.onSessionRescheduled(reason, additionalDetails, rescheduledBy, now, rescheduledWith, rescheduledByName, rescheduledWithName, reschedulingPerson);
  }

  onSessionRescheduled = (reason, additionalDetails, rescheduledBy, rescheduledOn, rescheduledWith, rescheduledByName, rescheduledWithName, reschedulingPerson) => {
    this.dialogRef.close({
      event: 'Yes', session: this.data.session,
      reason: reason, additionalDetails: additionalDetails,
      rescheduledBy: rescheduledBy, rescheduledOn: rescheduledOn,
      rescheduledWith: rescheduledWith, rescheduledByName: rescheduledByName,
      rescheduledWithName: rescheduledWithName,
      reschedulingPerson: reschedulingPerson
    });
  }

  timeComparison() {
    const now: any = firebase.firestore.Timestamp.now();
    const sessionDate = this.data.session.sessionDate;

    // starting time of the session
    let startTime = this.data.session.startTime.split(":");
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);
    startTime = (startingHour * 60) + startingMinute;

    // This will give the difference in dates basically
    let timeDifference = sessionDate - now;
    timeDifference /= 60;

    // This will give the difference between session start time and cancellation time in minutes
    timeDifference += startTime;

    if (this.data.reschedulingPerson == 'Parent') {
      if (timeDifference > this.cancelTimeLimit || this.data.session.status == 'Session Request') {
        this.message = "No Penalty will be charged";
      } else if ((timeDifference < this.cancelTimeLimit) && (timeDifference > 0)) {
        this.message = "You will be charged 50% of the session fees AS FINE.";
        this.subMessage = "yes1";
      } else if (timeDifference < 0) {
        this.message = "You will be charged 100% of the session fees AS FINE.";
        this.subMessage = "yes2";
      }
    } else if (this.data.reschedulingPerson == 'Expert') {
      if (timeDifference > this.cancelTimeLimitExpert || this.data.session.status == 'Session Request') {
        this.message = "No Penalty will be charged";
      } else if ((timeDifference < this.cancelTimeLimitExpert) && (timeDifference > 0)) {
        this.message = "You will be charged 50% of the session fees AS FINE.";
        this.subMessage = "yes1";
      } else if (timeDifference < 0) {
        this.message = "You will be charged 100% of the session fees AS FINE.";
        this.subMessage = "yes2";
      }
    }

  }

  // balance comparison for reschedule penalty
  reschedulePenalty() {
    let user = firebase.auth().currentUser.uid;
    let balData: any;
    let balRef = this.firestore.collection('balances').doc(user);
    balRef.get().subscribe(balInfo => {
      balData = balInfo.data();

      if (this.subMessage == "") {
        this.isRescheduleable = true;
      }
      else if (this.subMessage == "yes1") {
        if (balData.currentBalance < (this.data.session.sessionValue) * (0.50)) {
          console.log("Session Value=>", this.data.session.sessionValue);
          console.log("Current Balance=>", balData.currentBalance);
          this.isRescheduleable = false;
        }
      }
      else if (this.subMessage == "yes2") {
        if (balData.currentBalance < this.data.session.sessionValue) {
          this.isRescheduleable = false;
        }
      }

    });


  }

  async cancelMeeting(sessionId: any) {
    // getting this session details
    let docref = await this.firestore.collectionGroup('sessions',ref=>ref.where("id","==",sessionId)).get().subscribe(this.cancelSession);
  }

  cancelSession = (result) => {
    let session: any;
    session = result.docs[0].data();

    const dialogRef = this.dialog.open(CancelDialog, {
      panelClass: 'cancel-session-dialog',
      disableClose: true,
      data: { session: session, cancellingPerson: 'Parent' }
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getSessions();
      if (!result) {
        return;
      }
    });
  }



}
