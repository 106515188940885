import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/helper.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-admin-expert-selected',
  templateUrl: './admin-expert-selected.component.html',
  styleUrls: ['./admin-expert-selected.component.css'],
})
export class AdminExpertSelectedComponent implements OnInit, OnDestroy {
  selectedExpert: any;
  expertOldStatus: any;
  expertNewStatus: any;
  routeSub: Subscription;
  degrees: string[] = [];
  filter = 'balance';
  transactionSub: Subscription;
  transactions: AngularFirestoreDocument[] = [];
  transactionsByDate = {};
  sortedDates: Date[] = [];
  balanceSub: Subscription;
  balance: string;
  consultationBalance: string;
  students: AngularFirestoreDocument[] = [];
  recentSessionSub: Subscription;
  recentSessions = {};
  newExpert = false;
  currentUser: any;
  leaves: any[];
  upComingLeaves: any[];
  pastLeaves: any[];
  chosenDate: Date;
  dateSelected = false;
  showSlots = false;

  weeklyWorkHours: any[];
  workHour: workingObject[];

  chosenStartDate: Date;
  chosenEndDate: Date;

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public helper: HelperService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    public sessionService: SessionService
  ) { }



  ngOnInit(): void {



    this.weeklyWorkHours = [];
    this.routeSub = this.route.params
      .pipe(
        map((params: Params) => params['id']),
        switchMap((id: string) => this.adminService.getExpert(id)),
        map((expertArray: AngularFirestoreDocument[]) => expertArray[0])
      )
      .subscribe((expert: AngularFirestoreDocument) => {
        this.degrees = [];
        this.selectedExpert = expert;

        if (this.selectedExpert.activityStatus) {
          this.expertNewStatus = this.selectedExpert.activityStatus;
          this.expertOldStatus = this.selectedExpert.activityStatus;
        } else {
          this.expertNewStatus = "Session & Consultation Disabled"
          this.expertOldStatus = "Session & Consultation Disabled"
        }

        this.weeklyWorkHours = this.selectedExpert.workingHours;
        this.getSortedWorkingHours();

        for (let qualification of this.selectedExpert.qualifications) {
          this.degrees.push(qualification.degree); //storing the selected experts qualifications separately
        }


        this.transactionAndBalanceSubs(); //getting transaction and balance details for expert
        this.getLeaves(); //getting leaves for expert

        this.adminService
          .getFilteredStudentList(this.selectedExpert.id)
          .pipe(take(1))
          .subscribe((result) => {  //showing each student and their next sessions
            this.students = result;
            this.getRecentSessions(); //getting next session of that student with selected expert
          });
      });

    this.auth.currentUser.then((user) => {
      console.log(user?.uid);
      this.firestore
        .doc(`users/${user?.uid}`)
        .valueChanges()
        .pipe(take(1))
        .subscribe((userDoc: AngularFirestoreDocument) => {
          this.currentUser = userDoc; //getting current admin user
        });
    });

  }

  //for geting sorted working hours
  getSortedWorkingHours() {
    this.workHour = [];
    this.workHour = [
      { weekday: 'mon', workSlots: [] },
      { weekday: 'tue', workSlots: [] },
      { weekday: 'wed', workSlots: [] },
      { weekday: 'thu', workSlots: [] },
      { weekday: 'fri', workSlots: [] },
      { weekday: 'sat', workSlots: [] },
      { weekday: 'sun', workSlots: [] }
    ];

    const sorter = {
      "mon": 0,
      "tue": 1,
      "wed": 2,
      "thu": 3,
      "fri": 4,
      "sat": 5,
      "sun": 6
    }

    this.weeklyWorkHours.forEach(hour => {
      let day = hour.weekday;
      let i = sorter[day];
      this.workHour[i].workSlots.push(hour.startTime + "-" + hour.endTime);
    });

    let workHourCopy = [];

    this.workHour.forEach(doc => {
      let slot: any = doc;
      if (slot.workSlots.length != 0) {
        workHourCopy.push(slot);
      }
    })

    this.workHour = workHourCopy;

  }


  async cancelLeave(leaveId: any) {
    // getting this leave details
    let docref = this.firestore.collection('leaves').doc(leaveId).get().subscribe((result) => {
      let leave: any = result.data();

      let expertRef = this.firestore.collection('users').doc(leave.leaveBy).get().subscribe(user => {
        let expert: any = user.data();
        this.deleteLeave(leave, expert)
      })
    });
  }




  deleteLeave(leave: any, expert: any) {

    const dialogRef = this.dialog.open(cancelLeaveDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
      data: leave
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        await this.firestore.collection('leaves').doc(leave.id).delete()
          .then(async () => {
            this.getLeaves();

            let startDate = new Date(leave.leaveStartDateTime.toDate())
            let endDate = new Date(leave.leaveEndDateTime.toDate())

            let leaveStartDate: Date = this.helper.istTime3(startDate, startDate.getHours(), startDate.getMinutes());
            let leaveEndDate: Date = this.helper.istTime3(endDate, endDate.getHours(), endDate.getMinutes());

            let startingDateString = leaveStartDate.getDate() + '/' + (leaveStartDate.getMonth() + 1) + '/' + leaveStartDate.getFullYear() + ', ' + leaveStartDate.getHours() + ':' + leaveStartDate.getMinutes();
            let endingDateString = leaveEndDate.getDate() + '/' + (leaveEndDate.getMonth() + 1) + '/' + leaveEndDate.getFullYear() + ', ' + leaveEndDate.getHours() + ':' + leaveEndDate.getMinutes();

            // notification to the experts
            let body1: any = {};
            body1 = {
              'userId': expert.interaktId,
              'event': 'expert leave deleted - notify expert',
              'traits': {
                'expertName': expert.fullName,
                'startingDateString': startingDateString,
                'endingDateString': endingDateString
              }
            }
            await this.notificationService.sendWhatsappNotification(body1);

            // notification to support/program
            let ref = this.firestore.collection('users', ref => ref.where('role', 'in', ['program', 'support', 'sales-admin']));
            ref.get().subscribe(results => {
              results.forEach(async (doc) => {
                let user: any = doc.data();

                let body2: any = {};
                body2 = {
                  'userId': user.interaktId,
                  'event': 'expert leave deleted - notify admins',
                  'traits': {
                    'expertName': expert.fullName,
                    'startingDateString': startingDateString,
                    'endingDateString': endingDateString
                  }
                }

                await this.notificationService.sendWhatsappNotification(body2);
              })
            })

            let message = 'Your leave has been cancelled successfully'
            this.toastr.success(message, 'Leave Cancelled');

          }).catch(err => console.log('error deleting leave ', err));
      }
    });


  }



  transactionAndBalanceSubs() {
    this.transactionSub = this.adminService
      .getTransactions(this.selectedExpert.id)
      .subscribe((transactions: AngularFirestoreDocument[]) => {
        this.transactions = transactions; //storing transactions
        this.sortTransactions(this.transactions);
      });

    this.balanceSub = this.firestore
      .doc(`balances/${this.selectedExpert.id}`)
      .valueChanges() //storing balance
      .subscribe((balanceDoc: AngularFirestoreDocument) => {
        this.balance = balanceDoc['currentBalance'];
        this.consultationBalance = balanceDoc['consultationBalance'];
      });
  }

  isArray(variable: any) {
    return variable?.constructor === Array; //array check
  }


  // for getting slots of multiple days.
  onStartDateSelected(event) {
    this.chosenStartDate = event.value;
    console.log("Start Date: ", this.chosenStartDate);
    this.onMultiDatesSelected();
  }
  onEndtDateSelected(event) {
    this.chosenEndDate = event.value;
    console.log("End Date: ", this.chosenEndDate);
    this.onMultiDatesSelected();
  }
  onMultiDatesSelected() {
    let id = this.selectedExpert['id'];
    let slotDuration = 60;
    if (this.chosenStartDate != null && this.chosenEndDate != null) {
      console.log("both date selected ", this.chosenStartDate, this.chosenEndDate)
      this.sessionService.onCheckingSlotsForMultidays(id, this.chosenStartDate, this.chosenEndDate, slotDuration);
      this.showSlots = true;
    }
  }

  // for getting slots of a single day.
  onDateSelected(event) {
    this.chosenDate = event;
    this.dateSelected = true;
    this.showSlots = false;
    this.checkSlots();
  }
  checkSlots() {
    this.dateSelected = false;
    let id = this.selectedExpert['id'];
    let date = this.chosenDate;
    let slotDuration = 60;
    this.sessionService.checkForSlots(id, date, slotDuration);
    this.showSlots = true;
  }

  onFilterSelected(filter: string) {
    this.filter = filter;
  }

  getLeaves() {
    let ref = this.firestore.collection('leaves', (ref) =>
      ref.where('leaveBy', '==', this.selectedExpert.id)
    );
    ref.get().subscribe(this.onLeaveResult);
  }

  onLeaveResult = (results) => {
    let now = new Date();
    let nowStamp = firebase.firestore.Timestamp.fromDate(now);
    this.pastLeaves = [];
    this.upComingLeaves = [];
    if (!results.empty) {
      this.leaves = [];
      results.forEach((doc) => {
        this.leaves.push(doc.data()); //storing leaves separately
      });
    } else {
      this.leaves = null;
    }

    this.leaves?.forEach(leave => {
      if (leave.leaveStartDateTime <= nowStamp) {
        this.pastLeaves.push(leave);
      } else {
        this.upComingLeaves.push(leave);
      }
    });

  };

  sessionActivationStatus() {
    return this.helper.sessionActivationStatus;
  }

  sortTransactions(unfilteredTransactions: AngularFirestoreDocument[]) {
    let transactionsByDate = {};
    let transactions: AngularFirestoreDocument[] = unfilteredTransactions.filter(
      (transaction: AngularFirestoreDocument) => !(transaction['transactionValue'] === 0)
    );

    for (let transaction of transactions) {
      let createdDate: Date = this.helper.istTime(transaction['createdAt'].toDate());
      //storing transactions in chronological order against their dates in key value pairs as date:transaction
      let createdAt = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate(), 0, 0, 0);
      if (transactionsByDate[createdAt.toString()])
        transactionsByDate[createdAt.toString()].push(transaction);
      else {
        transactionsByDate[createdAt.toString()] = [];
        transactionsByDate[createdAt.toString()].push(transaction);
      }
    }
    let dates: Date[] = [];
    for (let i in transactionsByDate) dates.push(new Date(i));

    this.transactionsByDate = transactionsByDate;
    this.sortedDates = dates;
  }

  getRecentSessions() {
    let nextSession: Date;
    let lastSession: Date;
    for (let student of this.students) {
      this.recentSessionSub = this.adminService
        .getFilteredSessionList(this.selectedExpert['id'], student['id'], 'upcoming')
        .pipe(
          take(1),
          tap((result) => {
            if (result.length != 0) {
              let timestamp: firebase.firestore.Timestamp =
                result[0]['sessionEndDateTime']; //considering 0th session to be most recent upcoming session
              nextSession = this.helper.istTime(timestamp.toDate());
            } else {
              nextSession = null;
            }
            this.recentSessions[student['id']] = {};
            this.recentSessions[student['id']]['totalScheduled'] = result  //storing upcoming sessions of student with selectedExpert against the student ID
              ? result.length
              : 0;
            this.recentSessions[student['id']]['nextSession'] = nextSession;
          }),
          switchMap(() =>
            this.adminService.getFilteredSessionList(
              undefined,
              student['id'],
              'past'
            )
          ),
          take(1),
          tap((result) => {
            if (result.length != 0) {
              let timestamp: firebase.firestore.Timestamp =
                result[result.length - 1]['sessionEndDateTime']; //considering last position session to most recent past session as sessions are arranged in chronological ascending order
              lastSession = this.helper.istTime(timestamp.toDate());
            } else {
              lastSession = null;
            }
            this.recentSessions[student['id']]['totalPast'] = result
              ? result.length
              : 0;
            this.recentSessions[student['id']]['lastSession'] = lastSession;
          })
        )
        .subscribe();
    }
  }

  dateFilter(d: Date) {
    let now = new Date();
    if (
      d.getDate() >= now.getDate() &&
      d.getMonth() == now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (
      d.getMonth() > now.getMonth() &&
      d.getFullYear() === now.getFullYear()           //hiding all dates before today on the calendar
    )
      return true;
    else if (d.getFullYear() > now.getFullYear()) return true;
    else if (d.getFullYear() < now.getFullYear()) return false;
  }

  ngOnDestroy() {
    if (this.routeSub) this.routeSub.unsubscribe();
    if (this.transactionSub) this.transactionSub.unsubscribe();
    if (this.balanceSub) this.balanceSub.unsubscribe();
    if (this.recentSessionSub) this.recentSessionSub.unsubscribe();
  }

  openSessionPackDialog() {
    const dialogRef = this.dialog.open(SessionPackDialogExpert, {
      disableClose: true,
      panelClass: 'sessionPackDialog'
    });

    dialogRef.afterClosed().subscribe(this.addBalance);
  }

  addBalance = (result) => {
    if (!result) {
      return;
    } else if (result.event == 'Yes') {
      let numberOfSessions = parseFloat(result.numberOfSessions);
      let reason = result.reason;
      let currentTimeStamp = firebase.firestore.Timestamp.now();


      // let balanceDoc: any = {};
      // balanceDoc['lastUpdationByAdminId'] = this.adminService.adminDoc.id;
      // balanceDoc['lastUpdationByAdminName'] = this.adminService.adminDoc.fullName;
      // balanceDoc['balanceToBeAdded'] = numberOfSessions;
      // balanceDoc['balanceUpdationReason'] = reason;
      // balanceDoc['lastUpdated'] = date;
      // balanceDoc['balanceUpdationByAdminStatus'] = 'Requested'

      // let ref = this.firestore.collection('balances').doc(this.selectedExpert.id);
      // ref.update(balanceDoc).then().catch(err => console.log('error ', err));

      // Generating transaction
      let transaction: any = {};
      transaction.userId = this.selectedExpert.id;
      transaction.status = 'Successfull';
      transaction.reason = `Balance Updation by admin -  ${this.currentUser.fullName}`;
      transaction.subReason = reason;
      transaction.transactionValue = numberOfSessions;
      transaction.transactionType = 'byAdmin';
      transaction.adminId = this.currentUser.id;
      transaction.adminName = this.currentUser.fullName;
      transaction.createdAt = currentTimeStamp;
      transaction.hideTranscation = result.hideTranscation;



      let ref = this.firestore.collection('transactions').add(transaction).then(() => {
        console.log('transaction added successfully')
      }).catch(err => console.log(err))
    }
  }


  verifyExpert() {
    const dialogRef = this.dialog.open(verifyStatusDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return;
      } else if (result.event == 'Yes') {
        await this.firestore.collection('users').doc(this.selectedExpert.id).update({ verified: true, activityStatus:"Session & Consultation Disabled"})
          .then(async () => {

            let message = 'You have successfully verified this expert'
            this.toastr.success(message, 'Verification Status');

          }).catch(err => console.log('error verifying the expert ', err));
      }
    });
  }


  onSessionActivationStatusSelected(value) {
    const dialogRef = this.dialog.open(expertActivityStatusDialog, {
      disableClose: true,
      panelClass: 'sessionPackDialog',
      data: { oldStatus: this.expertOldStatus, newStatus: value }
    });

    let oldStatusForNotification = this.expertOldStatus;
    let newStatusForNotification = value;

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        this.expertNewStatus = this.expertOldStatus;
        return;
      } else if (result.event == 'Yes') {
        this.expertNewStatus = value;
        this.expertOldStatus = value;
        await this.firestore.collection('users').doc(this.selectedExpert.id).update({ activityStatus: value })
          .then(async () => {

            let message = 'You have successfully updated the status of this expert'
            this.toastr.success(message, 'Activity Status');

            this.sendActivationStatusNotification(oldStatusForNotification, newStatusForNotification, this.selectedExpert);

          }).catch(err => console.log('error updating the activity of expert ', err));
      }
    });
  }


  sendActivationStatusNotification(oldStatusForNotification, newStatusForNotification, expert: any) {
    let ref = this.firestore.collection('users', ref => ref.where('role', 'in', ['program', 'sales-admin', 'support']));
    ref.get().subscribe(async results => {
      results.forEach(async doc => {
        let user:any = doc.data();

        // notification for admins
        let body1:any = {};
        if(newStatusForNotification == "Both Enabled"){
          body1 = {
            'userId': user.interaktId,
            'event': 'expert got activated - notify admins',
            'traits': {
              'expertName': expert.fullName,
            }
          }
        } else {
          body1 = {
            'userId': user.interaktId,
            'event': 'expert got deactivated - notify admins',
            'traits': {
              'expertName': expert.fullName,
              'oldStatus': oldStatusForNotification,
              'newStatus': newStatusForNotification
            }
          }
        }

        await this.activationNotificationSenderForAdmins(user, newStatusForNotification, body1);
      })


      // notification for experts
      let body2 = {
        'userId': expert.interaktId,
          'event': 'experts activity status changed - notify the expert',
          'traits': {
            'expertName': expert.fullName,
            'oldStatus': oldStatusForNotification,
            'newStatus': newStatusForNotification
          }
      }

      await this.notificationService.sendWhatsappNotification(body2);

    })
  }


  async activationNotificationSenderForAdmins(user:any, status, body){
    if(user.role == 'sales-admin' && status == 'Session Disabled'){
      return;
    }

    await this.notificationService.sendWhatsappNotification(body);
  }

}



@Component({
  selector: 'app-session-pack-dialog-expert',
  template: `
  <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important;">Update Balance</mat-card-title>
      <mat-form-field style="width: 100%">
        <mat-label>Reason</mat-label>
        <input type="text" matInput #reason>
      </mat-form-field>

      <mat-form-field style="width: 100%;">
        <mat-label>Number of Sessions</mat-label>
        <input type="number" matInput #numberOfSessions>
      </mat-form-field>

      <input
        type="checkbox"
        [checked]="hideTranscation"
        (change)="hideTranscation = !hideTranscation"
      />
      <label style="margin-left:7px; font-size:14px;">Hide transcations from user</label>

      <button mat-button id="addSessionPackBtn" class="right" (click)="addSessionPack(reason.value, numberOfSessions.value)">Add</button>
    </div>
  `,
  // styleUrls: ['./admin-expert-selected.component.css']
  styleUrls: ['../.././admin-parents-tab/admin-selected-parent/admin-selected-parent.component.css']
})
export class SessionPackDialogExpert implements OnInit, OnDestroy {
  hideTranscation: boolean = false;
  constructor(private dialogRef: MatDialogRef<SessionPackDialogExpert>) { }

  ngOnInit() { }

  addSessionPack(reason: string, numberOfSessions: number) {
    this.dialogRef.close({ event: 'Yes', reason: reason, numberOfSessions: numberOfSessions,  hideTranscation:this.hideTranscation});


  }

  ngOnDestroy() { }
}


@Component({
  selector: 'cancel-leave-dialog',
  template: `
  <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>
    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important; font-size: 17px; display: flex; align-items: center; color: #F7524B;"><mat-icon style="margin-right: 5px;">info</mat-icon>Do you want to delete this leave</mat-card-title>

      <p style="display: flex; align-items: center">
        <span style="font-weight: 500;">{{data.leaveStartDateTime.toDate() | date:'dd/MM/YYYY h:mm a'}}</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">{{data.leaveEndDateTime.toDate() | date:'dd/MM/YYYY h:mm a'}}</span>
      </p>

      <button class="right" style="background: #FCFCFC; color: #F7524B" mat-button color="warn" (click)="delete()">Yes</button>
      <button class="right" style="background: #F7524B !important; color: white !important; margin-right: 15px"  mat-button color="warn" mat-dialog-close>No</button>
    </div>
  `,
  styleUrls: ['./admin-expert-selected.component.css']
})

export class cancelLeaveDialog implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<cancelLeaveDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() { }

  delete() {
    this.dialogRef.close({ event: 'Yes' });
  }

  ngOnDestroy() { }
}


@Component({
  selector: 'verify-status-dialog',
  template: `
    <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>

    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important; color: #F7524B;">You're about to change the status of this user!</mat-card-title>

      <p style="display: flex; align-items: center">
        <span style="color: #F7524B; font-weight: 500;">Unverified</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">Verified</span>
      </p>

      <button mat-button id="addSessionPackBtn" style="background: #FCFCFC; color: #F7524B" class="right" (click)="verify()">Yes</button>
      <button mat-dialog-close mat-button id="addSessionPackBtn" style="background: #F7524B !important; color: white !important; margin-right: 15px" class="right" >No</button>
    </div>
  `,
  styleUrls: ['./admin-expert-selected.component.css']
})
export class verifyStatusDialog implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<verifyStatusDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() { }

  verify() {
    this.dialogRef.close({ event: 'Yes' });
  }

  ngOnDestroy() { }
}


@Component({
  selector: 'expert-activity-status-dialog',
  template: `
    <div style="float: right; z-index:1; cursor: pointer;"><mat-icon mat-dialog-close>close</mat-icon></div>

    <div class="sessionDialogWrapper">
      <mat-card-title style="margin-bottom: 20px !important; color: #F7524B;">You're about to change the status of this user!</mat-card-title>

      <p style="display: flex; align-items: center">
        <span style="color: #F7524B; font-weight: 500;">{{data.oldStatus}}</span>
        <mat-icon style="margin: 0px 15px">arrow_right_alt</mat-icon>
        <span style="font-weight: 500;">{{data.newStatus}}</span>
      </p>

      <button mat-button id="addSessionPackBtn" style="background: #FCFCFC; color: #F7524B" class="right" (click)="verify()">Yes</button>
      <button mat-dialog-close mat-button id="addSessionPackBtn" style="background: #F7524B !important; color: white !important; margin-right: 15px" class="right" >No</button>
    </div>
  `,
  styleUrls: ['./admin-expert-selected.component.css']
})
export class expertActivityStatusDialog implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<expertActivityStatusDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() { }

  verify() {
    this.dialogRef.close({ event: 'Yes' });
  }


  ngOnDestroy() { }
}


interface workingObject {
  weekday: any;
  workSlots: any[];
}





