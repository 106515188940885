import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExpertsService {
  currentUserDetails: any;
  currentUserId: any;

  constructor(private afAuth: AngularFireAuth,
              private firestore: AngularFirestore,
              private router: Router) { }

  async getCurrentUserDetails() {
    let currentUser = await this.afAuth.currentUser;

    if(!currentUser){
      this.router.navigateByUrl('expert/signin');
      return;
    }

    this.currentUserId = currentUser.uid;

    let docRef = this.firestore.collection('users').doc(this.currentUserId).valueChanges().subscribe(result => {
      this.currentUserDetails = result;
    });
  }
}
