<mat-dialog-content class="mat-typography dialog-content">
<div style="display: flex; justify-content: flex-end;">
	<mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
</div>
<h1 mat-dialog-title style="font-weight: bold; font-size: 18px;">Reschedule session</h1>
	<!-- <mat-horizontal-stepper formArrayName="session" linear="true" #stepper>
		<mat-step label="Search" formGroupName="0" stepControl="profile.get([0])" *ngIf="!checkForRescheduling">
			<mat-card>
				<mat-card-title>
					Specify what you are looking for
				</mat-card-title>
				<mat-form-field class="full-width-field">
					<mat-label>Condition</mat-label>
					<mat-select #conditionCtrl formControlName="condition" required>
						<mat-option *ngFor="let condition of getConditions()" value="{{condition}}">{{condition}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field  class="full-width-field">
					<mat-label>Age Groups</mat-label>
					<mat-select #ageGroupCtrl formControlName="ageGroup" required>
						<mat-option *ngFor="let ageGroup of getAgeGroups()" value="{{ageGroup}}">{{ageGroup}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field class="full-width-field">
					<mat-label>Service Type</mat-label>
					<mat-select #serviceTypeCtrl formControlName="serviceType" required>
						<mat-option *ngFor="let service of getServices()" value="{{service}}">{{service}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-card-actions align="end">
					<button mat-button matStepperNext color="warn" [disabled]="conditionCtrl.value=='' || ageGroupCtrl.value =='' && serviceTypeCtrl.value == '' ">Next</button>
				</mat-card-actions>
				</mat-card>
			</mat-step>
		<mat-step label="Select" formGroupName="1" stepControl="profile.get([1])" *ngIf="!checkForRescheduling">
			<mat-card>
				<mat-card-title>
					Select an expert
				</mat-card-title>
				<mat-form-field class="full-width-field">
					<mat-label>Experts</mat-label>
					<mat-select #sessionByCtrl formControlName="sessionBy" required>
						<mat-option *ngFor="let expert of experts" value="{{expert.id}}"
							(click)="selectedExpertName = expert.fullName">{{expert.fullName}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-card *ngIf="sessionByCtrl.value">
					<mat-card-title>
						{{getExpert(sessionByCtrl.value).fullName}}
					</mat-card-title>
					<mat-card-content>
						{{getExpert(sessionByCtrl.value).conditions}}
						{{getExpert(sessionByCtrl.value).ageGroups}}
						{{getExpert(sessionByCtrl.value).serviceTypes}}
						{{getExpert(sessionByCtrl.value).workingHours}}
					</mat-card-content>
				</mat-card>
				<mat-card-actions align="end">
					<button mat-button matStepperPrevious type="button" color="warn">Back</button>
					<button mat-button matStepperNext color="warn" [disabled]="sessionByCtrl.value==''">Next</button>
				</mat-card-actions>
			</mat-card>
		</mat-step>
		<mat-step label="Schedule" formGroupName="2" stepControl="profile.get([2])">

		</mat-step>
	</mat-horizontal-stepper> -->

	<form [formGroup]="sessionForm" *ngIf="sessionForm">
		<!-- <mat-card class='rescheduleSessionCard'> -->
			<mat-card-content>
				Select a date and slot for reschedule
			</mat-card-content>
			<mat-card-content>
				<mat-form-field class="full-width-field">
					<mat-label>Session Date</mat-label>
					<input matInput [matDatepicker]="picker" [for]="picker" formControlName="sessionDate" autocomplete="off"
						(click)="onDateSelection()" (dateChange)="enableSlotButton($event)" [min]="minDate" [max]="maxDate">
					<mat-datepicker-toggle matSuffix [for]="picker" (click)="onDateSelection()" ></mat-datepicker-toggle>
					<mat-datepicker #picker ></mat-datepicker>
				</mat-form-field>

				<p *ngIf="isLessThanSlotRange">Slots are available after 24 hours from now on.</p>

				<button mat-raised-button class="getSlotBtn" type="button" *ngIf="getSlotsEnabled" (click)="getFreeSlots()">Get Available slots</button>
				<br>

				<!-- available slots -->
				<!-- <mat-form-field class="full-width-field" *ngIf="showSlots">
					<mat-label>Available Slot</mat-label>
					<mat-select #selectedSlot formControlName="selectedSlot" required>
						<mat-option *ngFor="let openSlot of sessionService.openSlots" value="{{openSlot.value}}"
							(click)="setSessionTimings()">{{openSlot.label}}</mat-option>
					</mat-select>
				</mat-form-field> -->

				<div class="slotList" *ngIf="showSlots">
					<div *ngIf="!data.fromAdminAccount">
            <p *ngIf="sessionService.openSlotsAfter24Hours.length; else notInRange">Available time slots on {{selectedSessionDate | date:'EEEE, dd MMM'}} of {{data.rescheduledSession.sessionExpertName}}</p>
            <ng-template #notInRange>Slots are available after 24 hours from now on.</ng-template>
            <div class="slotsContainer">
              <button class="slot" *ngFor="let openSlot of sessionService.openSlotsAfter24Hours" (click)="onSlotSelected(openSlot.value)">{{openSlot.label}}</button>
            </div>
          </div>
					<div *ngIf="data.fromAdminAccount">
            <p *ngIf="sessionService.openSlots.length; else notInRange">Available time slots on {{selectedSessionDate | date:'EEEE, dd MMM'}} of {{data.rescheduledSession.sessionExpertName}}</p>
            <ng-template #notInRange>Slots are available after 24 hours from now on.</ng-template>
            <div class="slotsContainer">
              <button class="slot" *ngFor="let openSlot of sessionService.openSlots" (click)="onSlotSelected(openSlot.value)">{{openSlot.label}}</button>
            </div>
          </div>
				</div>



				<!--
			<mat-form-field class="full-width-field">
				<mat-label>Start Time</mat-label>
				<input matInput formControlName="startTime" type="time">
			</mat-form-field>
			<mat-form-field class="full-width-field">
				<mat-label>End Time</mat-label>
				<input matInput formControlName="endTime" type="time">
			</mat-form-field> -->

				<!-- <mat-form-field class="full-width-field">
				<mat-label>Repetition</mat-label>
				<mat-select formControlName="repeat">
					<mat-option value="0">Does not repeat</mat-option>
					<mat-option value="1">Every Day</mat-option>
					<mat-option value="7">Every Week</mat-option>
				</mat-select>
			</mat-form-field> -->
			</mat-card-content>
			<mat-card-content *ngIf="showSlots">
				Need a different slot? Contact support
			</mat-card-content>
			<mat-card-actions>
				<button mat-button id="sessionRequestBtn" type="submit" color="warn"
				(click)="saveSession()" [disabled]="!isSaveEnabled" class="right actionButtons2">Reschedule</button>
				<button mat-button mat-dialog-close type="button" color="warn" class="right actionButtons">Back</button>
			</mat-card-actions>
		<!-- </mat-card> -->
	</form>
</mat-dialog-content>
