<div class="container">
  <mat-card id="containerCard" style="padding-left: 0px; margin-bottom: 0px; margin-top: 0px; padding-bottom: 2px;">
    <div class="heading">
      <a matButton [routerLink]="'/'+currentUser?.role+'/dashboard'" id="back-button">
        <mat-icon>arrow_back</mat-icon>
      </a>
      <!-- <h1>(C){{ selectedParent?.childName }}:(P){{ selectedParent?.name ? selectedParent?.name :
        selectedParent?.fullName }}</h1> -->
    </div>
  </mat-card>
  <!-- <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
    <mat-expansion-panel-header style="max-height: auto">
      <mat-panel-title><b>{{ selectedParent?.phoneNumber }}</b></mat-panel-title>
      <mat-panel-title><b>{{ selectedParent?.email }}</b></mat-panel-title>
    </mat-expansion-panel-header>
    <h3>{{ isArray(selectedParent?.languages) ? (selectedParent?.languages).toString() : "-" }}</h3>
    <h3>{{ selectedParent?.location }}</h3>
    <h3>Child's Concern: {{ selectedParent?.otherConditions }}</h3>
    <h3>Child's Diagnosis: {{ isArray(selectedParent?.conditions) ? (selectedParent?.conditions).toString() : "-"}}</h3>
    <mat-panel-title><b>{{ selectedParent?.id }}</b></mat-panel-title>
    <button mat-button color="warn" class="right editButton">Edit</button>
  </mat-expansion-panel> -->

  <mat-card class="details-container-1" style="margin-top: 14px;">
    <div class="profileDetails">
      <span class="labelFields">Parent's Name</span>
      <span class="detailFields">{{selectedParent?.name ? selectedParent?.name : selectedParent?.fullName}}</span>
    </div>
    <div class="profileDetails">
      <span class="labelFields">Id</span>
      <span class="detailFields">{{selectedParent?.id}}</span>
    </div>
    <div class="profileDetails">
      <span class="labelFields">Location</span>
      <span class="detailFields">{{ selectedParent?.location }}</span>
    </div>
    <div class="profileDetails">
      <span class="labelFields">Language</span>
      <span class="detailFields">{{ isArray(selectedParent?.languages) ? (selectedParent?.languages).toString() : "-"
        }}</span>
    </div>
    <div class="profileDetails">
      <span class="labelFields">Pack Valid Till</span>
      <span class="detailFields">{{ selectedParent?.validTill?.toDate() | date: 'dd/MM/YYYY'}}</span>
    </div>
    <div class="profileDetails" style="display: flex; flex-direction: column; margin-top: 20px;">
      <span class="labelFields">Contact:</span>
      <div style="padding-left: 15px;">
        <div class="profileDetails">
          <span class="contact-labelFields">Whatsapp</span>
          <span class="contact-detailFields" *ngIf="selectedParent?.whatsappNumber">
            <button (click)="openChat()" style="border-bottom: 1px solid black;"
              class="contact-buttons">{{selectedParent.countryCode}}{{selectedParent.whatsappNumber}}</button>
            <button (click)="copyWhatsappNumber()"
              style="background: none; padding: 0px; height: 20px; border: none; margin-left: 10px; cursor: pointer;">
              <mat-icon style="height: 12px !important; width: 12px !important; font-size: 13px; margin-top: 2px;">
                content_copy</mat-icon>
            </button>
          </span>
          <span class="contact-detailFields" *ngIf="!selectedParent?.whatsappNumber">-</span>
        </div>
        <div class="profileDetails">
          <span class="contact-labelFields">Phone</span>
          <span class="contact-detailFields" *ngIf="selectedParent?.phoneNumber">
            <button style="cursor: initial;" class="contact-buttons">{{selectedParent.phoneNumber}}</button>
            <button (click)="copyPhoneNumber()"
              style="background: none; padding: 0px; height: 20px; border: none; margin-left: 14px; cursor: pointer;">
              <mat-icon style="height: 12px !important; width: 12px !important; font-size: 13px; margin-top: 2px;">
                content_copy</mat-icon>
            </button>
          </span>
          <span class="contact-detailFields" *ngIf="!selectedParent?.phoneNumber">-</span>
        </div>
        <div class="profileDetails">
          <span class="contact-labelFields">Email ID</span>
          <span class="contact-detailFields">{{selectedParent?.email ? selectedParent?.email : "-"}}</span>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="details-container-1">
    <div class="profileDetails">
      <span class="labelFields">Child's Name</span>
      <span class="detailFields">{{selectedParent?.childName ? selectedParent?.childName : "-"}}</span>
    </div>
    <div class="profileDetails">
      <span class="labelFields">Child's Date Of Birth</span>
      <span class="detailFields">{{selectedParent?.childDob?(selectedParent?.childDob.toDate() | date: 'dd/MM/YYYY'):"-"}}</span>
    </div>
    <!-- <div class="profileDetails">
      <span class="labelFields">Age</span>
      <span class="detailFields">{{selectedParent?.childAge ? selectedParent?.childAge : "-"}}</span>
    </div> -->
    <div class="profileDetails">
      <span class="labelFields">Child's Concern</span>
      <span class="detailFields">{{ isArray(selectedParent?.conditions) ? (selectedParent?.conditions).toString() : "-"}}</span>
    </div>
    <!-- <div class="profileDetails">
      <span class="labelFields">Diagnosis</span>
      <span class="detailFields">{{selectedParent?.diagnosis ? selectedParent?.diagnosis : "-"}}</span>
    </div> -->
  </mat-card>

  <p class="labels2" style="font-weight: 600; margin-bottom: 4px;">Status:</p>
  <mat-select disableOptionCentering [(value)]="parentStatus" (selectionChange)="onActivityStatusChanged($event)"
    panelClass="dropdown3" class="activitySelectBox" placeholder="Select">
    <mat-option *ngFor="let status of getParentStatus()" value="{{ status }}">
      {{status}}
    </mat-option>
  </mat-select>

  <div class="optionWrapper">
    <div class="optionHolder">
      <span class="sessionContainer">
        <button mat-button class="sessionButton" (click)="onFilterSelected('balance')"
          [ngClass]="{'selectedSessionButton': filter==='balance'}">Balance</button>
        <button mat-button class="sessionButton" (click)="onFilterSelected('experts')"
          [ngClass]="{'selectedSessionButton': filter==='experts'}">Experts</button>
      </span>
    </div>
  </div>
  <div class="balanceWrapper" *ngIf="filter==='balance'">
    <div class="currentBalance">
      <h1 style="margin: 10px;">Current Balance: {{ this.balanceDocument?this.balanceDocument.currentBalance:"Balance
        Document does not exist" }}</h1>
      <button mat-button class="addButton right" (click)="openSessionPackDialog()">+ Add</button>
    </div>
    <mat-card class="balanceCard">

      <div class="transactionFilterContainer" style="margin-top: 8px; margin-bottom: 20px; display: flex; justify-content: flex-end;">
        <mat-select placeholder="All Transactions" style="border: 1px solid #7A7A7A; color: black; width: 151px; border-radius: 5px; padding-left: 10px;" [(value)]="transactionFilter" (selectionChange)="onTransactionFilterSelected($event.value)">
            <mat-option value="allTransaction">All Transactions</mat-option>
            <mat-option value="renewalTransaction">Payments</mat-option>
        </mat-select>
      </div>

      <mat-card-content>
        <div class="transactionByDate" *ngFor="let date of sortedDates">
          <div class="transactionDate" style=" margin-bottom: 8px; margin-top: 18px;">
            <h3 style="margin-bottom: 0;">{{ date | date: 'd MMMM y'}}</h3>
          </div>
          <mat-list style="padding-top: 0;">
            <div class="transaction" *ngFor="let transaction of transactionsByDate[date]; let i=index">
              <div class="transactionWithHR">
                <mat-list-item class="fullTransaction">
                  <div [ngStyle]="{'color': getFinalTransactionValue(transaction)>0?'#B1CA57':'#F7524B'}"
                    class="transactionVal">

                    <b style="color: #B1CA57;"
                      *ngIf="transaction.transactionType == 'renewalTransaction' && transaction.paymentStatus == 'Successfull'; else nonRenewal">
                      +{{transaction.sessions}}
                    </b>

                    <ng-template #nonRenewal>
                      <b>
                        {{ getFinalTransactionValue(transaction)>0?"+":"" }}{{ getFinalTransactionValue(transaction) }}
                      </b>
                    </ng-template>
                  </div>

                  <div class="transactionDetailsWrapper">

                    <div style="margin-bottom: 8px;" class="transactionType" *ngIf="transaction.transactionType != 'renewalTransaction'">{{
                      transaction['reason'] }}</div>
                    <div style="margin-bottom: 8px;" *ngIf="transaction.transactionType == 'renewalTransaction'"
                      class="transactionType">Top up by parent</div>

                    <div *ngIf="transaction.transactionType == 'session' && !transaction.cretedViaMultipleBookings">
                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                        *ngIf="transaction.serviceType">
                        {{transaction['serviceType']}} with
                        {{transaction['sessionExpertName']}}
                      </p>

                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                        *ngIf="transaction.sessionDate">
                        {{transaction.session?.sessionDate.toDate() | date:'dd/MM'}}
                        {{transaction.sessionStartTime}}-{{transaction.sessionEndTime}} IST
                      </p>

                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: black;">
                        {{transaction.sessionId}}
                      </p>
                    </div>

                    <div *ngIf="transaction.transactionType == 'consultation'">
                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                        *ngIf="transaction.serviceType">
                        {{transaction['serviceType']}} with
                        {{transaction['sessionExpertName']}}
                      </p>

                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;"
                        *ngIf="transaction.sessionDate">
                        {{transaction.session?.sessionDate.toDate() | date:'dd/MM'}}
                        {{transaction.sessionStartTime}}-{{transaction.sessionEndTime}} IST
                      </p>

                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: black;">
                        {{transaction.consultationId}}
                      </p>

                    </div>

                    <div
                      *ngIf="transaction.transactionType == 'session' && (transaction.cretedViaMultipleBookings  || transaction.createdWithMultipleSessions)">
                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                        {{transaction['serviceType']}} with
                        {{transaction['sessionExpertName']}}
                      </p>

                      <button *ngIf="!transaction.showMultipleSessionDetails" class="expansionBtn"
                        (click)="transaction.showMultipleSessionDetails = true"><a style="text-decoration: underline;">
                          More Details </a>
                        <mat-icon>keyboard_arrow_down_icon</mat-icon>
                      </button>
                      <button *ngIf="transaction.showMultipleSessionDetails" class="expansionBtn"
                        (click)="transaction.showMultipleSessionDetails = false"><a style="text-decoration: underline;">
                          More Details </a>
                        <mat-icon>keyboard_arrow_up_icon</mat-icon>
                      </button>
                      <div class="multiSessionDetailsContainer" *ngIf="transaction.showMultipleSessionDetails">

                        <div style="display: flex; margin-bottom: 15px;"
                          *ngFor="let details of transaction.scheduledSessionsDetails; let i = index">

                          <div class="transactionVal" style="color: #F7524B; margin-right: 10px; margin-left: 5px;">
                            -01</div>
                          <div>
                            <div class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                              {{details.date.toDate() | date:'dd/MM'}} {{details.startTime}}-{{details.endTime}} IST
                            </div>

                            <div class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: black;">
                              {{transaction.sessionId[i]}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <!-- for renewal transactions -->
                    <div
                      *ngIf="transaction.transactionType == 'renewalTransaction' && transaction.paymentStatus == 'Successfull'">

                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                        Amount: {{transaction.amount}} INR
                      </p>

                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;">
                        Number of Sessions: {{transaction.sessions}}
                      </p>

                      <p class="sessionInfo" style="margin-bottom: 7px; font-weight: 500; color: black;">
                        Transaction ID: {{transaction.id}}
                      </p>
                    </div>

                    <div *ngIf="transaction.transactionType == 'byAdmin'">
                      <p style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;" class="sessionInfo" style="margin-bottom: 7px;" *ngIf="transaction.adminName">
                        Admin: {{transaction['adminName']}}
                      </p>

                      <p style="margin-bottom: 7px; font-weight: 500; color: #7A7A7A;" class="sessionInfo" style="margin-bottom: 7px;" *ngIf="transaction.subReason">
                        Reason: {{transaction.subReason}}
                      </p>
                    </div>
                  </div>

                  <div class="transactionTime">
                    {{transaction.createdAt.toDate() | date:"HH:mm"}} IST
                  </div>
                </mat-list-item>
              </div>

              <hr *ngIf="transactionsByDate[date].length >i+1" style=" margin-bottom: 10px; margin-top: 20px;">
            </div>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="expertWrapper" *ngIf="filter==='experts'">
    <div style="margin-bottom: 15px; height: 40px">
      <button mat-button id="editExpertPairsBtn" class="right" (click)="onEditPairings()" *ngIf="!isEditing">Edit Expert
        Pairings</button>
    </div>
    <div *ngIf="!isEditing">
      <mat-card class="expertsCards" *ngFor="let expert of experts">
        <mat-card-title>{{ expert.educatorName }}</mat-card-title>
        <mat-card-subtitle>{{ isArray(expert.service)?(expert.service).toString():expert.service }}</mat-card-subtitle>
        <mat-card-content>
          <p>Last Session: {{ (recentSessions[expert.educatorId])?.lastSession | date: 'dd MMMM y, h:mm a'}}</p>
          <p>Total Attended: {{ (recentSessions[expert.educatorId])?.totalPast }}</p>
          <br>
          <p>Next Session: {{ (recentSessions[expert.educatorId])?.nextSession | date: 'dd MMMM y, h:mm a'}}</p>
          <p>Scheduled: {{ (recentSessions[expert.educatorId])?.totalScheduled }}</p>
          <br>
          <p>Next Leave: {{ (leaves[expert.educatorId])?.startDate | date: 'dd MMMM y, h:mm a'}}(IST) - {{
            (leaves[expert.educatorId])?.endDate | date: 'dd MMMM y, h:mm a' }}(IST)</p>
        </mat-card-content>
      </mat-card>
    </div>


    <div class="expertPairingEdit" *ngIf="isEditing">
      <h1 style="padding: 16px; font-weight: 500;">Experts</h1>
      <div [formGroup]="expertForm">
        <div [formGroupName]="'educators'">
          <div *ngFor="let educator of educators?.controls; let i=index" formArrayName="{{i}}">
            <mat-card class="educatorCard">
              <mat-card-subtitle>
                <span class="left">Expert {{i + 1}}</span>
                <!-- <span class="right">
                          <button mat-button type="button" (click)="deleteEducator(i)" color="warn">Delete</button>
                      </span> -->
              </mat-card-subtitle>
              <mat-form-field class="full-width-field">
                <mat-label>Expert ID</mat-label>
                <mat-select matInput #educatorIdCtrl formControlName="educatorId" required>
                  <mat-option *ngFor="let expert of expertList" value="{{expert.id}}"
                    (click)="onExpertIdSelected(expert.id, i)">{{expert.fullName}}-{{expert.id}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width-field">
                <mat-label>Expert Name</mat-label>
                <!-- <mat-select matInput #educatorNameCtrl formControlName="educatorName" disabled>
                          <mat-option *ngFor="let expert of experts" value="{{expert.fullName}}">{{expert.fullName}}</mat-option>
                      </mat-select> -->
                <input type="text" matInput formControlName="educatorName" readonly>
              </mat-form-field>
              <mat-form-field class="full-width-field">
                <mat-label>Service</mat-label>
                <mat-select matInput #serviceCtrl formControlName="service" required multiple>
                  <mat-option *ngFor="let service of services[i]" value="{{service}}">{{service}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width-field">
                <mat-label>Reccomended Frequency (sessions/week)</mat-label>
                <input matInput type="number" formControlName='reccomendedFrequency' autocomplete="off" required
                  #reccomendedFrequencyCtrl>
              </mat-form-field>
              <button mat-button (click)="deleteEducator(i)" color="warn" style="height: 37px">- Remove Expert</button>
            </mat-card>
          </div>
          <div class="addEducatorBtnDiv" style="display: flex; justify-content: center;">
            <button mat-button type="button" (click)="addEducators('', '', [], '', i)" id="addEducatorBtn">Add Another
              Expert</button>
          </div>
        </div>
        <div style="display: inline;">
          <button mat-button class="redbtn left" (click)="isEditing = !isEditing">Cancel</button>
          <button mat-button class="redbtn right" (click)="onDone()">Done</button>
        </div>
      </div>
    </div>
  </div>
</div>