import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionDialog } from '../expert-dashboard/session.component';
import { ExpertProfileDialog } from '../expert-dashboard/expert-profile.component';
import { HelperService } from '../helper.service';
import firebase from 'firebase/app';
import { InteraktUserService } from '../services/interakt-user.service';
import { InviteDialog } from '../invite/invite.component';

@Component({
  selector: 'app-support-dashboard',
  templateUrl: './support-dashboard.component.html',
  styleUrls: ['./support-dashboard.component.css']
})
export class SupportDashboardComponent implements OnInit {
	sessions:any[];
	filter:string = "All";
	dateRange:any;
  selectedUserName:any;
  activateBalanceForm: boolean = false;
  balanceForm: FormGroup;
  balanceTypes = [
    {label: 'Half Session', value: 'Half session'},
    {label: 'Full Session', value: 'Full session'}
  ]


	experts:any[];
  balances:any[];
	students:any[];

  users:any[];

  constructor(private router:Router,
  						public auth:AngularFireAuth,
  						private firestore:AngularFirestore,
              private snackBar:MatSnackBar,
              public dialog: MatDialog,
              private fb: FormBuilder,
              private helper: HelperService,
              private interaktUser: InteraktUserService) { }

  ngOnInit(): void {
  	var start = new Date();
		start.setHours(0,0,0,0);

		var end = new Date();
		end.setHours(23,59,59,999);
  	this.dateRange = new FormGroup({
	    start: new FormControl(start),
	    end: new FormControl(end)
	  });

    this.getBalances();
	  this.getSessions();
	  this.getExperts();
    this.getUsers();


    this.balanceForm = this.fb.group({
      userId:'',
      balanceType:'',
      currentBalance:Number
    })


  }

  onFilterChanged(event){
  	this.filter = event.value;
  	this.getSessions();
  }

  onDateRangeChange(){
  	if(this.dateRange.value.start && this.dateRange.value.end){
	  	var start = new Date(this.dateRange.value.start);
			start.setHours(0,0,0,0);

			var end = new Date(this.dateRange.value.end);
			end.setHours(23,59,59,999);

	  	this.dateRange = new FormGroup({
		    start: new FormControl(start),
		    end: new FormControl(end)
		  });

  		this.getSessions();
  	}
  }

  back(){
  	var diff = Math.abs(this.dateRange.value.end.getTime() - this.dateRange.value.start.getTime());		

  	var start = new Date(this.dateRange.value.start.getTime()-diff);
  	start.setHours(0,0,0,0);

  	var end = new Date(this.dateRange.value.start.getTime()-diff);
  	end.setHours(23,59,59,999);

  	this.dateRange = new FormGroup({
	    start: new FormControl(start),
	    end: new FormControl(end)
	  });

	  this.getSessions();	
  }

  forward(){
  	var diff = Math.abs(this.dateRange.value.end.getTime() - this.dateRange.value.start.getTime());		

  	var start = new Date(this.dateRange.value.start.getTime()+ diff + 1);
  	start.setHours(0,0,0,0);

  	var end = new Date(this.dateRange.value.start.getTime() + diff + 1);
  	end.setHours(23,59,59,999);

  	this.dateRange = new FormGroup({
	    start: new FormControl(start),
	    end: new FormControl(end)
	  });

	  this.getSessions();	
  }

  viewSessionDetails(id){
    const dialogRef = this.dialog.open(SessionDialog, {
      disableClose: true,
      data:{id:id}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result){
        return;
      }
    });   
  }

  addSession(){
    const dialogRef = this.dialog.open(SessionDialog, {
      disableClose: true,
      data:{
        // sessionByName:user['displayName'],
        // sessionBy:user['uid'],
        // status:"Available"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result){
        return;
      }
    });    
  }

  async getSessions(){    
    var currentUser = await this.auth.currentUser;
    if(!currentUser){
      this.router.navigateByUrl('/support/signin');
      return;
    }
    
    var createdBy = currentUser.uid;    
    if(this.filter=="All"){
      let ref = this.firestore.collectionGroup('sessions', ref => ref.where('sessionDate', '>=', this.dateRange.value.start)
.where('sessionDate', '<=', this.dateRange.value.end).orderBy('sessionDate','asc'));
      ref.get().subscribe(this.onSessionResults);
    }
    else{
      let ref = this.firestore.collectionGroup('sessions', ref => ref.where('status', '==', this.filter).where('sessionDate', '>=', this.dateRange.value.start)
.where('sessionDate', '<=', this.dateRange.value.end).orderBy('sessionDate','asc'));
      ref.get().subscribe(this.onSessionResults);
    }
  }

  onSessionResults = (results) => {
    if(!results.empty){
      this.sessions = [];
      results.forEach((doc) => {
        this.sessions.push(doc.data());      
      });
    }else{
        this.sessions = null;
    }
  }

  async getExperts(){    
    var currentUser = await this.auth.currentUser;
    if(!currentUser){
      this.router.navigateByUrl('/support/signin');
      return;
    }
    
    let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'expert').orderBy('createdOn','desc'));
      ref.get().subscribe(this.onExpertResults);
  }

  onExpertResults = (results) => {
    if(!results.empty){
      this.experts = [];
      results.forEach((doc) => {
        this.experts.push(doc.data());      
      });
    }else{
        this.experts = null;
    }
  }


  async getUsers(){    
    var currentUser = await this.auth.currentUser;
    if(!currentUser){
      this.router.navigateByUrl('/support/signin');
      return;
    }
    
    let ref = this.firestore.collection('users', ref => ref.where('role', '!=', 'support'));
      ref.get().subscribe(this.onUsersResults);
  }

  onUsersResults = (results) => {
    if(!results.empty){
      this.users = [];
      results.forEach((doc) => {
        this.users.push(doc.data());      
      });
    }else{
        this.users = null;
    }
  }


  markAsVerified(id){
    var docRef = this.firestore.collection("users").doc(id);
    docRef.update({verified:true}).then(this.onUserVerified).catch(function(error) {
      var message = "Oops! There was an error during verification. Try again. If problem persists, contact support."
      this.snackBar.open(message, "", {
        duration: 2000,
      });
    });
  }

  onUserVerified = () =>{    
    this.getExperts();
    var message = "Expert has been verified."
    this.snackBar.open(message, "", {
      duration: 2000,
    });
  }

  viewExpertDetails(id){
    const dialogRef = this.dialog.open(ExpertProfileDialog, {
      disableClose: true,
      data:{id:id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSessions();
      return;      
    });    


  }


  async getStudents(){    
    var currentUser = await this.auth.currentUser;
    if(!currentUser){
      this.router.navigateByUrl('/support/signin');
      return;
    }
    
    let ref = this.firestore.collection('users', ref => ref.where('role', '==', 'parent').orderBy('createdOn','desc'));
      ref.get().subscribe(this.onStudentResults);
  }

  onStudentResults = (results) => {
    if(!results.empty){
      this.experts = [];
      results.forEach((doc) => {
        this.experts.push(doc.data());      
      });
    }else{
        this.experts = null;
    }
  }

  showGoalSheet(){

  }

  async saveBalanceData(){
    let currentUser = await this.auth.currentUser;
    if(!currentUser){
      this.router.navigateByUrl('/support/signin');
      return;
    }

    let balance = {};
    let balanceData = this.balanceForm.value;
    balance = balanceData;

    let id = balance['userId'];
    let docRef = this.firestore.collection("balances").doc(id);

    balance['id'] = id;
    balance['lastUpdated'] = firebase.firestore.FieldValue.serverTimestamp();
    balance['fullName'] = this.selectedUserName;

    const res = await docRef.set(balance, {merge:true}).then(this.onBalanceUpdated).catch(err => {
      console.log(err)
    })

    this.balanceForm.reset();
    this.activateBalanceForm =false;
  }

  onBalanceUpdated = () => {
    let message = 'Balance updated successfully';
    this.snackBar.open(message, "", {
      duration: 2000,
    });
    this.getBalances();
  }

  async getBalances(){    
    let currentUser = await this.auth.currentUser;
    if(!currentUser){
      this.router.navigateByUrl('/support/signin');
      return;
    }
    
    let ref = this.firestore.collection('balances');
      ref.get().subscribe(this.onBalanceResults);
  }

  onBalanceResults = (results) => {
    if(!results.empty){
      this.balances = [];
      results.forEach((doc) => {
        this.balances.push(doc.data());      
      });
    }else{
        this.balances = null;
    }
  }

  async inviteUser(){
    let currentUser = await this.auth.currentUser;
    if(!currentUser){
      this.router.navigateByUrl('/support/signin');
      return;
    }

    const dialogRef = this.dialog.open(InviteDialog, {
      disableClose: true,
      data:{
        invitedBy:currentUser['uid'],
        experts: this.experts,
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      // this.getInvitations();
      if(!result){
        return;
      }
    });  
  }


  // testing for interakt
  // checkIt(){
  //   let id = "b6b62dc8-751a-4213-bb86-e782149736cc"
  //   let data = this.firestore.collection('balances').doc(id).get().toPromise().then((result)=>{
  //     console.log(result.data());
  //   });
  // }

}
