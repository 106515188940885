<div class="newSessionMainContainer">
  <div class="heading">
    <a matButton routerLink="/support/dashboard" id="back-button" (click)="showBookSession = false">
      <mat-icon>arrow_back</mat-icon>
    </a>
    <h1>Schedule new session</h1>
  </div>
  <p>Select Expert</p>
  <div class="teachersList">
    <!-- <button mat-button *ngFor="let expert of experts" class="teacherListItem" (click)="onExpertSelected(expert)">{{expert.fullName}}</button> -->
    <mat-form-field color="warn">
      <mat-select (selectionChange)="onExpertSelected($event.value)" placeholder="Expert">
        <mat-option *ngFor="let expert of experts" [value]="expert">{{ expert.fullName }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <p *ngIf="!isExpertActive" style="color: #F7524B; text-align: start; margin-top: -14px; font-size: 14px;">Session booking is disabled for {{expertSelected.fullName}}</p>

  <div class="expertLeaves" *ngIf="isExpertOnLeave">
    <div class="headingContainer">
      <h2 style="margin-bottom: 0px !important;" class="messageHeading">Expert on Leave</h2>
      <button style="border: none; background-color: white;" *ngIf="!showLeaves" (click)="showLeaves = true">
        <mat-icon class="material-icons-filled">
          keyboard_arrow_down
        </mat-icon>
      </button>
      <button style="border: none; background-color: white;" *ngIf="showLeaves" (click)="showLeaves = false">
        <mat-icon class="material-icons-filled">
          keyboard_arrow_up
        </mat-icon>
      </button>
    </div>
    <div *ngIf="showLeaves">
      <div *ngFor="let leave of selectedExpertLeaves; let i = index">
        <h3 style="margin-bottom: -5px !important; margin-top: 6px !important;">Leave: {{i+1}}</h3>
        <div style="color: #A5A5A5;">From: {{helper.istTime(leave.leaveStartDateTime.toDate()) | date: 'MMM d, y, h:mm a'}} IST</div>
        <div style="color: #A5A5A5;">To: {{helper.istTime(leave.leaveEndDateTime.toDate()) | date: 'MMM d, y, h:mm a'}} IST</div>
      </div>
    </div>
  </div>

  <div *ngIf="isExpertSelected && isExpertActive">
    <p>Select Child and Parent</p>
    <div class="studentList">
      <mat-form-field color="warn">
        <mat-select placeholder="Child/Parent" (selectionChange)="onchildSelected($event.value)">
          <mat-option *ngFor="let parent of children" [value]="parent">{{ parent.fullName}}/{{ parent.childName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- changed for allowing admin to book session even thouth parent have insufficient balance -->
  <div class="expertLeaves" *ngIf="isInsufficientBalance" style="margin-bottom: 35px;">
    <div class="headingContainer">
      <h2 style="margin-bottom: 0px !important;" class="messageHeading">Parent Subscription Over</h2>
      <button style="border: none; background-color: white;" *ngIf="!parentSubscriptionDetails"
        (click)="parentSubscriptionDetails = true">
        <mat-icon class="material-icons-filled">
          keyboard_arrow_down
        </mat-icon>
      </button>
      <button style="border: none; background-color: white;" *ngIf="parentSubscriptionDetails"
        (click)="parentSubscriptionDetails = false">
        <mat-icon class="material-icons-filled">
          keyboard_arrow_up
        </mat-icon>
      </button>
    </div>
    <div *ngIf="parentSubscriptionDetails" style="color: #A5A5A5;">
      This parent currently has a balance of zero (0). You will be able to schedule a session once they renew.
    </div>
  </div>



  <div *ngIf="ischildSelected">
    <p>Select Session Type(Duration)</p>
    <div>
      <mat-form-field color="warn">
        <mat-select placeholder="Full Session Paid" (selectionChange)="onSessionDurationSelected($event)">
          <mat-option value="fullSessionPaid">Full Session Paid</mat-option>
          <mat-option value="halfSessionPaid">Half Session Paid</mat-option>
          <mat-option value="halfSessionUnpaid">Free Half Session</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="isSessionDurationtSelected">
    <p>Select Session Type(Service)</p>
    <div class="sessionTypeList">
      <mat-form-field color="warn">
        <mat-select placeholder="Session Type" (selectionChange)="onSessionTypeSelected($event.value)">
          <mat-option *ngFor="let sessionType of sessionTypes" [value]="sessionType">{{sessionType }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>


  <!-- <div class="numberOfSessionsContainer" *ngIf="isExpertSelected">
      <p style="text-align: start;margin: 8px 0;">Total number of sessions you want to schedule</p>
      <mat-select class="numberOfSessions" placeholder="Select">
          <mat-option *ngFor="let number of getNumbers()" value="{{number}}" (click)="selectedNumberOfSession(number)">{{number}}</mat-option>
      </mat-select>
  </div> -->

  <!-- Multiple session booking view -->
  <!-- <div class="multipleSessionContainer" *ngIf="isMultipleSession">
    <p>Number of sessions you want to take per week</p>
    <span class="sessionPerWeekContainer">
      <button class="sessionPerWeekBtn" (click)="onSessionPerWeekDec()">-</button>
      <h3 style="margin: 0px 15px; display: inherit;">{{sessionPerWeek}}</h3>
      <button class="sessionPerWeekBtn" (click)="onSessionPerWeekInc()">+</button>
    </span>
  </div> -->

  <!-- Single session booking view -->
  <div class="singleSessionContainer" *ngIf="isSessionTypeSelected">
    <p>Select your preferred day and slot for taking session</p>
    <mat-calendar #calendar [selected]="chosenDate" (selectedChange)="onDateSelected($event)" class="dateSelector"
      [dateFilter]="dateFilter" [dateClass] color="primary"></mat-calendar>

    <p *ngIf="isLessThanSlotRange">Slots are available after 24 hours from now on.</p>

    <!-- <button mat-raised-button class="getSlotBtn" *ngIf="getSlotsEnabled" (click)="getFreeSlots()">View Available slots</button> -->

    <div class="slotList" *ngIf="showSlots">
      <p *ngIf="sessionService.openSlots.length; else notInRange">Available time slots on {{chosenDate |
        date:'EEEE, dd MMM'}} of {{expertSelected.fullName}}</p>
      <div class="slotsContainer" *ngIf="sessionService.openSlots.length">
        <button id="slot" [disabled]="disableSlots" *ngFor="let openSlot of sessionService.openSlots"
          (click)="onSlotSelected(openSlot.value)" style="cursor: pointer;" 
          [ngClass]="{activeDays:selectedSlot==openSlot.value}"
          >{{openSlot.label}}</button>
      </div>
      <!--  -->
      <!-- changed for allowing manual slot booking even there is no 
        available slots (*ngIf="sessionService.openSlots.length") this is removed from below div -->
      <div>
        <p>Or</p>
        <p style="font-size: small; font-weight: 400;">Enter Start Time Manually:</p>
        <div class="manualTime">
          <mat-form-field id="time1">
            <input type="time" matInput placeholder="HH:MM" #time (change)="finalTime(time.value)">
          </mat-form-field>
          <p style="font-size: small; font-weight: 400; position: relative; top: 10px; margin: 10px;">to</p>
          <mat-form-field>
            <input type="text" disabled matInput [value]="manualTime">
          </mat-form-field>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <input
        type="checkbox"
        [checked]="isMultipleSession"
        (change)="isMultipleSession = !isMultipleSession"
      />
      <label  style="margin:7px; font-size:19px;">Book Multiple Sessions</label> 
      </div>
      <div class="multipleSessionContainer" *ngIf="isMultipleSession" style="margin-top: 20px;"> 
        <div>
          <p>Number of weeks</p>
          <mat-form-field>
            <input type="number" placeholder="Weeks" matInput [value]="noOfWeeks" min="1" (change)="onWeeksInputChange(weeksVal.value)"  #weeksVal  [required]="isMultipleSession"/>
          </mat-form-field>
          <mat-error *ngIf="noOfWeeksErrorMsg!=''" style="margin-bottom: 10px;">
            {{noOfWeeksErrorMsg}}</mat-error>
        </div>
        
        <p>Choose Days</p>
        <div class="slotsContainer">
          <div id="days" 
            *ngFor="let day of getDays()"
            style="cursor: pointer;"
            [ngClass]="{activeDays:selectedDays.includes(day)}"
            (click)="setSelectedDays(day)">{{day}}</div>
        </div>
        <mat-error *ngIf="selectedDaysErrorMsg!=''" style="margin-bottom: 10px;">
          {{selectedDaysErrorMsg}}</mat-error>
      </div>

    </div>

    <ng-template #notInRange>
      <div class="noSlotsAvailable" style="margin-bottom: 30px;">
        <h2 style="margin-bottom: 10px !important;" class="messageHeading">No Available Slots<br>Please select another
          date.</h2>
        <div style="color: #A5A5A5;">All of your expert's slots for this date have been booked OR your expert is on
          leave.</div>
      </div>
    </ng-template>
  </div>

  <div id="submitBtnContainer">
    <!-- <button mat-button id="sessionCancelBtn" routerLink="/parent/dashboard">
          CANCEL
      </button> -->
    <button mat-button id="sessionRequestBtn" *ngIf="showBookSession" (click)=saveSession()
      [disabled]="disableBookButton">{{isLoading?"Wait loading...":"Book Session"}}</button>
    <!-- <button mat-button id="sessionRequestBtn" disabled (click)=saveSession()>BOOK SESSION</button> -->
  </div>
</div>