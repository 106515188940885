<mat-icon class="right" style="position: relative; top: -12px; left: 12px; cursor: pointer;" matDialogClose>close</mat-icon>
<div style="display: inline-flex;" class="successHeader">
  <mat-icon id="checkCircle">check_circle</mat-icon>
  <h1 *ngIf="data === 'cancel'">Cancelled!</h1>
  <h1 *ngIf="data === 'reschedule'">Reschedule Requested!</h1>
  <h1 *ngIf="data === 'noShow'">No-show reported!</h1>
  <h1 *ngIf="data === 'attended'">Marked as Attended!</h1>
</div>
<mat-card-subtitle *ngIf="data === 'cancel'">
  This consultation has been cancelled successfully. Please take appropriate next steps based on the status details.
</mat-card-subtitle>
<mat-card-subtitle *ngIf="data === 'reschedule'">
  This consultation has been requested to be rescheduled. Please take appropriate next steps based on the status details.
</mat-card-subtitle>
<mat-card-subtitle *ngIf="data === 'noShow'">
  This no-show has been reported successfully.
</mat-card-subtitle>
<mat-card-subtitle *ngIf="data === 'attended'">
  This consultation has been marked as attended by all parties and completed. Please take required next steps.
</mat-card-subtitle>
