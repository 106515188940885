import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

@Component({
  selector: 'app-invalid-login',
  templateUrl: './invalid-login.component.html',
  styleUrls: ['./invalid-login.component.css']
})
export class InvalidLoginComponent implements OnInit {
  user = { name: "", email: "", profilePic: "" };


  constructor(private router: Router, private auth: AngularFireAuth) { }

  async ngOnInit() {
    let email = await firebase.auth().currentUser.email;
    let name = await firebase.auth().currentUser.displayName;
    let profilePic = await firebase.auth().currentUser.photoURL;
    this.user = { name: name, email: email, profilePic: profilePic };
    console.log('user  ',this.user)
  }

  async logout() {
    await this.auth.signOut();
    this.user = null;
    this.router.navigateByUrl("/");
  }
}
