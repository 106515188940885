import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { HelperService } from '../helper.service'
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../services/notification.service';
// import { pairwise, take, } from 'rxjs/operators';
// import { pairwise, retryWhen } from 'rxjs/operators';
import { ParentsService } from '../services/parents.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { runInThisContext } from 'vm';
import axios from 'axios';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-parent-profile',
  templateUrl: './parent-profile.component.html',
  styleUrls: ['./parent-profile.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ParentProfileComponent implements OnInit {

  @Input('isEditing') isEditing: boolean = true;
  @Input('isPremee') isPremee: boolean = true;

  balance: any = {};
  parent: any;
  parentDataBeforeEditing: any;
  parentForm: any;
  _id: string;
  childName: any;
  phoneNumber: any;
  email:any;
  whatsappNumber: any;
  childGender:any;
  childDob:Date;
  maxDate = new Date();
  preemie:boolean;
  bornWeeks:any;
  kpParentData:any;

  @Output() completed = new EventEmitter<void>();

  @Input()
  set id(value) {
    this._id = value;
    this.getParent();
  }

  @Input()
  set balanceDetails(value) {
    this.balance = value;
  }

  getParent() {
    var userRef = this.firestore.collection('users').doc(this._id);
    return userRef.get().toPromise().then(this.onUser).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  editProfile() {
    this.isEditing = true;
  }

  public onUser = (result): any => {
    if (result.exists) {
      this.parent = result.data();
      this.getInvitationDocument();
    }
  }
 
  constructor(
    private fb: FormBuilder,
    public parentService: ParentsService,
    private notificationService: NotificationService,
    public firestore: AngularFirestore,
    public auth: AngularFireAuth,
    private toastr: ToastrService,
    private helper: HelperService) {
  }
  ngOnInit(): void {
    var dt = DateTime.now();
    this.parentForm = this.fb.group({
      profile: this.fb.array([
        this.fb.group({
          fullName: ['', Validators.required],
          phoneNumber: ['', Validators.required],
          email:['',Validators.required],
          whatsappNumber: ['', Validators.required],
          relationWithChild: ['', Validators.required],
          childName: ['', Validators.required],
          location: ['', Validators.required],
          childGender:['',Validators.required],
          childDob:['',Validators.required],
          preemie:[false,Validators.required],
          bornWeeks:['']
        }),
        this.fb.group({
          conditions: ['', Validators.required],
          otherConditions: [''],
          // serviceTypes: ['', Validators.required],
          ageRange: ['', Validators.required],
          languages: ['', Validators.required]
        }),
      ])
    });
       this.fetchUserProfile();
    // this.getInvitationDocument();
  }

  async getInvitationDocument() {
    let phoneNumber = await firebase.auth().currentUser.phoneNumber;
    let eMail = await firebase.auth().currentUser.email;
    if (phoneNumber) {
      let inviteRef = await this.firestore.collection('invitations', inviteRef => inviteRef.where('verificationNumbers', 'array-contains', phoneNumber));
      await inviteRef.get().subscribe(this.onInvitationResult)
    } else if (eMail) {
      let inviteRef = await this.firestore.collection('invitations', inviteRef => inviteRef.where('eMail', '==', eMail));
      await inviteRef.get().subscribe(this.onInvitationResult)
    }
  }

  async fetchUserProfile () {
    let token=localStorage.getItem('token')
    const response = await axios.get("https://mykinderpass.com/api/userprofile/", {
      headers: {
        "content-type": "application/json",
        token,
      },
    });
    if (response.status === 200) {
      console.log(response.data.responseData.user);
      this.kpParentData=response.data.responseData.user;
    }else{
      console.log(response);
    }  
  };

  updateUserProfile (parentData) {
    let token=localStorage.getItem('token')

    const fullname =parentData.fullName.split(" ");
    const first_name = fullname[0];
    const last_name = fullname[1];

   const child = this.kpParentData.child.find(obj => obj.is_tactopus === true)

    let data = JSON.stringify({
      user: {
        first_name: first_name,
        last_name: last_name,
        gender: parentData.relationWithChild === "Mother" ? 1 : 2,
        location: parentData.location,
        expert_languages: parentData.languages,
      },
    });
    let childData = JSON.stringify({
      id:child.id,
      name: parentData.childName,
      dob: `${parentData.childDob.toDate().getFullYear()}-${parentData.childDob.toDate().getMonth()+1}-${parentData.childDob.toDate().getDate()}`,
      gender:parentData.childGender,
      type: "born",
      preemie: parentData.preemie,
      image_url: "",
      premature_by: parentData.bornWeeks || 0,
      is_tactopus: true,
      conditions: parentData.conditions,  
    });
   
    axios({
      method: 'put',
      url: 'https://mykinderpass.com/api/v2/userprofile/',
      headers: { "Content-Type": "application/json", "token":token},
      data: data
  }).then(response=>{
      this.updateChildDetails(token,childData);
    }).catch(error=>{
      console.log(error);
    });
  };

  updateChildDetails(token,data){
    axios({
      method: 'put',
      url: "https://mykinderpass.com/api/v2/child/",
      headers: { "Content-Type": "application/json", "token":token},
      data: data
  }).then(response=>{
     console.log(response);
    }).catch(error=>{
      console.log(error);
    });
  }

  onInvitationResult = (result) => {
    if (!result.empty) {
      let docs = [];
      result.forEach((doc) => {
        docs.push(doc.data());
      });

      let invitation: any = docs[0];

      this.phoneNumber = invitation.phoneNumberCountryCode + invitation.phoneNumber;
      this.whatsappNumber = invitation.whatsappNumberCountryCode + invitation.whatsappNumber;
     
      var data = {
        profile: [
          {
            fullName: this.parent.fullName ? this.parent.fullName : '',
            phoneNumber:this.phoneNumber,
            email:invitation.eMail,
            whatsappNumber:this.whatsappNumber,
            relationWithChild: this.parent.relationWithChild ? this.parent.relationWithChild : '',
            childName: this.parent.childName ? this.parent.childName : '',
            location: this.parent.location ? this.parent.location : '',
            childGender:this.parent.childGender?this.parent.childGender:'',
            childDob:this.parent.childDob?this.parent.childDob.toDate():'',
            preemie:this.parent.preemie,
            bornWeeks:this.parent.bornWeeks?this.parent.bornWeeks:''
          },
          {
            conditions: this.parent.conditions ? this.parent.conditions : '',
            otherConditions: this.parent.otherConditions ? this.parent.otherConditions : '',
            // serviceTypes: this.parent.serviceTypes ? this.parent.serviceTypes : '',
            ageRange: this.parent.ageRange ? this.parent.ageRange : '',
            languages: this.parent.languages ? this.parent.languages : '',
          }
        ]
      };

      this.parentDataBeforeEditing = data;
      this.parentForm.setValue(data);
    }
  }

  hasErrors(section, formControlName) {
    var result = false;
    if (formControlName in this.profile.get([section])['controls'])
      result = this.profile.get([section])['controls'][formControlName].invalid;
    else
      console.log(formControlName);
    return result;
  }

  get profile() {
    return this.parentForm.get('profile') as FormArray;
  }

  getServices() {
    return this.helper.services;
  }

  getAgeGroups() {
    return this.helper.ageGroups;
  }

  getConditions() {
    return this.helper.conditions;
  }

  getTimeZones() {
    return this.helper.timeZones;
  }

  getLanguages() {
    return this.helper.languages;
  }

  isOtherChecked(values) {
    var result = false;
    if (values) {
      result = values.includes("Other");
    }
    return result;
  }
  isPremieChecked(values) {
    // if(!values){
    //   this.bornWeeks=0;
    // }
    // console.log(values);
    return values;
  }

  async save() {
    var user = {};
    var profile = this.parentForm.value.profile;
    profile.forEach(data => {
      user = { ...user, ...data }
    })

    //Get the current user uid
    var docRef = this.firestore.collection("users").doc(this._id);
    const updatedOn = firebase.firestore.FieldValue.serverTimestamp();
    const updatedBy = firebase.auth().currentUser.uid;
    const updatedByName = firebase.auth().currentUser.displayName;
    user['updatedOn'] = updatedOn;
    user['registered'] = true;
   
    user['childDob']=this.childDob?this.childDob:this.parent.childDob;
   
    docRef.update(user).then(this.onParentUpdated).catch(function (error) {
      var message = "Oops! There was an error during registration. Try again. If problem persists, contact support."
      this.snackBar.open(message, "", {
        duration: 2000,
      });
    });
  }

  onParentUpdated = () => {
    // this.getParent().then(()=>{
    //   this.isEditing = false;
    // })
    let id = this._id;
    let ref = this.firestore.collection('users').doc(id);
    ref.get().subscribe(this.onParentDetails)

    this.isEditing = false;
    var message = "Profile edits have been successfully saved."
    this.toastr.success(message, "Changes Saved!")
    this.completed.emit();
  }

  onParentDetails = (result) => {
    let parent: any;
    if (!result.empty) {
      parent = result.data();
    }
     this.updateUserProfile(parent);
    
    // this will be trigggered for the first time signup by parent
    if (!parent.verified) {
      this.childName = parent.childName;
      let body = {
        'userId': parent.interaktId,
        'event': 'new parent sign up',
        'traits': {
          'name': parent.childName,
        }
      }
      this.notificationService.sendWhatsappNotification(body);

      let ref = this.firestore.collection('users').doc(parent.id);
      ref.update({ verified: true, activityStatus: 'Active', haveBookedASession: false })
      let now = firebase.firestore.Timestamp.now();

      // Generating initial transaction 
      let data = {}
      data['userId'] = parent.id;
      data['userName'] = parent.fullName;
      data['status'] = 'Successfull';
      data['reason'] = `Initial Transaction for Subscription`;
      data['transactionValue'] = parent.numberOfSessions;
      data['sessionPackValue'] = parent.sessionPackValue;
      data['transactionType'] = 'initialTransaction';
      data['createdAt'] = now;
      let transactionRef = this.firestore.collection('transactions').add(data).then(()=> console.log('initial transaction generated')).catch(err => console.log('error creating initial transaction ', err));

      let expertIds: any[] = parent.myExpertsIds;
      for (let i = 0; i < expertIds.length; i++) {
        let ref = this.firestore.collection('users').doc(expertIds[i]);
        ref.get().subscribe(this.onExpertDetails)
      }
    } else {
      let first = this.parentDataBeforeEditing.profile[1].conditions
      if (this.parentDataBeforeEditing.profile[1].conditions.length != parent.conditions.length) {
        this.parentService.myExperts.forEach(expert => {
          let body = {
            'userId': expert.interaktId,
            'event': 'child concern edited by parent - notify expert',
            'traits': {
              'name': this.parentService.currentUserDetails.fullName,
              'childName': this.parentService.currentUserDetails.childName
            }
          }

          this.notificationService.sendWhatsappNotification(body);
        })
      } else {
        let count = 0;
        for (let i = 0; i < parent.conditions.length; i++) {
          let previousValue = this.parentDataBeforeEditing.profile[1].conditions[i];
          for (let j = 0; j < parent.conditions.length; j++) {
            if (previousValue == parent.conditions[j]) {
              count += 1;
            }
          }
        }
        if (count != parent.conditions.length) {
          this.parentService.myExperts.forEach(expert => {
            let body = {
              'userId': expert.interaktId,
              'event': 'child concern edited by parent - notify expert',
              'traits': {
                'name': this.parentService.currentUserDetails.fullName,
                'childName': this.parentService.currentUserDetails.childName
              }
            }

            this.notificationService.sendWhatsappNotification(body);
          })
        }
      }
      this.getParent()
    }
  }

  onExpertDetails = (result) => {
    let expert: any;
    if (!result.empty) {
      expert = result.data();
    }

    let body = {
      'userId': expert.interaktId,
      'event': 'new student added for the expert',
      'traits': {
        'name': this.childName,
      }
    }
    this.notificationService.sendWhatsappNotification(body);
  }

  getErrorMessage(section, formControlName) {
    var errors = this.profile.get([section])['controls'][formControlName].errors;
    var errorString;
    if (errors['required'])
      errorString = "Required";
    return errorString;
  }

  onNoClick(): void {
    this.completed.emit();
  }
  onStartDateSelected(event) {
    this.childDob = new Date(event.value);
    // this.childDob =this.changeStringToDate(event.value);
    console.log('start date ', this.childDob)
  }


}


@Component({
  selector: 'app-parent-profile-dialog',
  templateUrl: './parent-profile-dialog.component.html',
  styleUrls: ['./parent-profile-dialog.component.css']
})
export class ParentProfileDialog {
  id: string;
  constructor(
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<ParentProfileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
    this.id = data['id'];
  }

  onNoClick() {
    this.dialogRef.close();
  }
}